import React, { memo } from 'react';
import { LongTermPropertyGroup } from '../../components';

import css from './LongTermRentalListingPage.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const SectionFeaturesMaybe = memo(
  props => {
    const { id, title, options, selectedOptions } = props;

    return (
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>Features</h2>
        <LongTermPropertyGroup
          id={id}
          options={options}
          selectedOptions={selectedOptions}
          twoColumns={true}
        />
      </div>
    );
  },
  (prev, next) => {
    return isEqual(
      pick(prev, ['options', 'selectedOptions']),
      pick(next, ['options', 'selectedOptions'])
    );
  }
);

export default SectionFeaturesMaybe;
