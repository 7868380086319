import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITION_AUTO_REFUND_INITIATED,
  TRANSITION_AUTO_REFUND_INITIATED_ON_HOLD,
  TRANSITION_AUTO_REFUNDED,
  TRANSITION_CONFIRM_DEPOSIT_REQUEST,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_DEPOSIT_ADMIN_REFUND_REQUESTED,
  TRANSITION_DEPOSIT_ADMIN_REVERTED,
  TRANSITION_DEPOSIT_DENIED,
  TRANSITION_DEPOSIT_FAIL,
  TRANSITION_DEPOSIT_ON_HOLD,
  TRANSITION_DEPOSIT_REFUND_REQUESTED,
  TRANSITION_DEPOSIT_REFUNDED,
  TRANSITION_DEPOSIT_STRIPE_REFUNDED,
  TRANSITION_EXPIRE_DEPOSIT_REQUEST,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_UNVERIFIED_DECLINED,
  TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE,
  TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_EXPIRE,
  TRANSITION_UNVERIFIED_EXPIRE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_DECLINE,
  TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN,
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST,
  TRANSITIONS,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { TRANSITIONS_LTF } from '../../util/transactionLongTermFirst';
import { TRANSITIONS_LTL } from '../../util/transactionLongTermLast';
import { TRANSITIONS_LTM } from '../../util/transactionLongTermMiddle';
import { drivelahApiPost } from '../../util/apiHelper';
import { getCurrentUser } from '../../util/browserStorageHelper';
import { TRANSITION_DEPOSIT_REQUEST } from '../RefundableDepositPage/RefundableDepositPage.duck';

export const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const ADD_LONG_TERM_TRANSACTIONS_WITH_CHILD =
  'app/InboxPage/ADD_LONG_TERM_TRANSACTIONS_WITH_CHILD';

// ================ Reducer ================ //

export const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  longTermTransactionsWithChild: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      console.log('<< Transactions', transactions);

      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
    case ADD_LONG_TERM_TRANSACTIONS_WITH_CHILD:
      return { ...state, longTermTransactionsWithChild: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  let excludedTransitions = [
    TRANSITION_EXPIRE_PAYMENT,
    TRANSITION_DEPOSIT_FAIL,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
    TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
    TRANSITION_DEPOSIT_REQUEST,
    TRANSITION_CONFIRM_DEPOSIT_REQUEST,
    TRANSITION_EXPIRE_DEPOSIT_REQUEST,
    TRANSITION_AUTO_REFUND_INITIATED,
    TRANSITION_DEPOSIT_REFUND_REQUESTED,
    TRANSITION_DEPOSIT_ADMIN_REFUND_REQUESTED,
    TRANSITION_AUTO_REFUNDED,
    TRANSITION_DEPOSIT_REFUNDED,
    TRANSITION_DEPOSIT_STRIPE_REFUNDED,
    TRANSITION_DEPOSIT_DENIED,
    TRANSITION_DEPOSIT_ON_HOLD,
    TRANSITION_AUTO_REFUND_INITIATED_ON_HOLD,
    TRANSITION_DEPOSIT_ADMIN_REVERTED,
  ];

  if (onlyFilter == 'sale') {
    excludedTransitions.push(
      TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
      TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
      TRANSITION_UNVERIFIED_DECLINED,
      TRANSITION_UNVERIFIED_EXPIRE,
      TRANSITION_UNVERIFIED_VERIFICATION_SENT,
      TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN,
      TRANSITION_UNVERIFIED_WITHDRAW,
      TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE,
      TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT,
      TRANSITION_UNVERIFIED_EXPIRE_INSTANT,
      TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT,
      TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN,
      TRANSITION_UNVERIFIED_VERIFICATION_DECLINE,
      TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE,
      TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
      TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE,
      TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE
    );
  }

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: TRANSITIONS.filter(transition => !excludedTransitions.includes(transition)),
    include: [
      'provider',
      'provider.profileImage',
      'provider.protectedData',
      'customer',
      'customer.profileImage',
      'customer.protectedData',
      'booking',
      'listing',
      'messages',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'processName',
      'lineItems',
      'protectedData',
      'metadata',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      console.log('responseeeeeeeeee');
      console.log(response);
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const loadDataLongTerm = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const excludedTransitions = [TRANSITION_EXPIRE_PAYMENT, TRANSITION_DEPOSIT_FAIL];

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: [...TRANSITIONS_LTF, ...TRANSITIONS_LTL, ...TRANSITIONS_LTM].filter(
      transition => !excludedTransitions.includes(transition)
    ),
    include: [
      'provider',
      'provider.profileImage',
      'provider.protectedData',
      'customer',
      'customer.profileImage',
      'customer.protectedData',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'processName',
      'lineItems',
      'protectedData',
      'metadata',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(async response => {
      const isLongTerm = true;
      if (isLongTerm) {
        const { currentUser } = getState().user;
        const cachedUser = getCurrentUser();
        const userId = (cachedUser && cachedUser.id.uuid) || (currentUser && currentUser.id.uuid);
        const transactionIds = response.data.data.map(transaction => transaction.id.uuid);
        const getBunchTransactionURL = 'transactions/retrieve-parent-transactions-with-children';
        const data = {
          userId,
          transactionIds,
        };
        const transactionsWithChildRes = await drivelahApiPost(getBunchTransactionURL, data);
        const transactionsWithChild = get(transactionsWithChildRes, 'data.data', []);
        dispatch({
          type: ADD_LONG_TERM_TRANSACTIONS_WITH_CHILD,
          payload: transactionsWithChild,
        });
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
