import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './PromoteTopPage.css';

class PromoteTopPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowContent: true,
    };
    this.scroll = null;
    this.countTime = null;
    this.watchShowPromoteTimeout = null;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScrollListener, true);
  }

  componentWillUnmount() {
    if (this.watchShowPromoteTimeout) {
      clearTimeout(this.watchShowPromoteTimeout);
    }
    window.removeEventListener('scroll', this.handleScrollListener);
  }

  handleScrollListener = () => {
    if (
      document.getElementById('sectionPartners') &&
      window.scrollY - document.getElementById('sectionPartners').offsetTop - 70 > 0
    ) {
      this.setState({
        isShowContent: true,
      });
      clearTimeout(this.watchShowPromoteTimeout);
    }
  };

  handleShowPromote = () => {
    this.watchShowPromoteTimeout = setTimeout(() => {
      this.setState({
        isShowContent: true,
      });
    }, 5000);
  };

  render() {
    const { rootClassName, className, currentUser, content, turnoffPromoteBar } = this.props;

    if (this.countTime === null && !currentUser) {
      this.countTime = true;
      this.setState({
        isShowContent: false,
      });
      this.handleShowPromote();
    }

    if (this.countTime === true && currentUser) {
      this.setState({
        isShowContent: true,
      });
      if (this.watchShowPromoteTimeout) {
        clearTimeout(this.watchShowPromoteTimeout);
      }
      this.countTime = false;
    }

    const classes = classNames(rootClassName || css.root, className);

    const defaultContent = (
      <div className={css.contentContainer}>
        <div className={css.text}>
          <FormattedMessage id="PromoteTopPage.title" />
        </div>
        <div className={css.button}>
          <NamedLink name="NewListingPage" className={css.defaultButton}>
            <FormattedMessage id="PromoteTopPage.buttonText" />
          </NamedLink>
        </div>
      </div>
    );

    const showContent = content || defaultContent;

    return turnoffPromoteBar && !turnoffPromoteBar.status ? (
      <div className={classes}>
        <div className={css.closeButton} onClick={() => this.props.onTurnoffPromoteBar(true)}>
          x
        </div>
        {this.state.isShowContent && showContent}
      </div>
    ) : null;
  }
}

PromoteTopPage.defaultProps = {
  rootClassName: null,
  className: null,
  content: null,
};

PromoteTopPage.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.object,
};

export default PromoteTopPage;
