import React, { useEffect, useState } from 'react';
import css from './SeoLandingPage.css';
import { ListingCardV3, NamedLink } from '../../components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import Slider from 'react-slick';
import { compose } from 'redux';
import { fetchCategorisedCar } from '../SearchPage/SearchPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { unionWith } from 'lodash';

const SectionCars = ({
  isMobileLayout,
  brandCategory,
  onFetchCategorisedCar,
  listings,
  fetchFeaturedCarInProgress,
  setSearchFilters,
}) => {
  const [filterText, setFilterText] = useState(null);
  useEffect(() => {
    const lowerTheString = brandCategory.toLowerCase();
    const validString = lowerTheString.split(' ');
    const validStringConjoined = validString.join('_');
    setFilterText(validStringConjoined);
    setSearchFilters(validStringConjoined);
    onFetchCategorisedCar(validStringConjoined);
  }, [brandCategory]);
  const sliderConfig = {
    // className: css.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionCars}>
      <div className={css.sectionCarsTitleWrapper}>
        <div className={css.sectionTitle}>Choose from the top rented {brandCategory}</div>
      </div>
      {!isMobileLayout ? (
        fetchFeaturedCarInProgress ? (
          <div className={css.sectionCarsLoading}>Loading car...</div>
        ) : (
          <div className={css.listingGrid}>
            {listings.map(l => (
              <div className={css.listingCardWrapper}>
                <ListingCardV3 listing={l} />
              </div>
            ))}
          </div>
        )
      ) : (
        ''
      )}
      {isMobileLayout ? (
        fetchFeaturedCarInProgress ? (
          <div className={css.sectionCarsLoading}>Loading car...</div>
        ) : (
          <div className={css.sliderWrapper}>
            <Slider {...sliderConfig}>
              {listings.map(l => (
                <div className={css.listingCardWrapper}>
                  <ListingCardV3 listing={l} />
                </div>
              ))}
            </Slider>
          </div>
        )
      ) : (
        ''
      )}
      <div className={css.btnWrapper}>
        <NamedLink
          name="SearchPage"
          to={{
            search: `?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999&pub_category=${filterText}`,
          }}
          className={css.viewAllCar}
        >
          <FormattedMessage id="LandingPage.viewAllCar" />
        </NamedLink>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    searchMapListingIds,
    featuredCarIds,
    featuredLocalizedCarIds,
    searchParams,
  } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));
  const featuredLocalizedCarRefs = featuredLocalizedCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  const locationListings = getMarketplaceEntities(state, featuredLocalizedCarRefs);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );
  return {
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    mapListings,
    searchParams,
    featuredLocalizedCarIds,
    listings,
    locationListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchCategorisedCar: searchParams => dispatch(fetchCategorisedCar(searchParams)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SectionCars);
