import React, { useMemo } from 'react';
import { LongTermPropertyGroup } from '../../components';
import config from '../../config';

import css from './LongTermRentalListingPage.css';
import { isFuelTypeIsPetrol } from '../../util/helpers';

const SectionDescriptionMaybe = props => {
  const {
    description,
    authorDisplayName,
    brandName,
    transmissions,
    fuelType,
    drivenKm,
    seats,
  } = props;
  //

  let data,
    transmissionDataUpdate = [],
    fuelTypesDataUpdate = [],
    drivenKmDataUpdate = [];
  for (let data in config.custom.transmissions) {
    const configData = config.custom.transmissions;
    const title = { title: 'Transmission' };
    let updatedData = { ...configData[data], ...title };
    transmissionDataUpdate.push(updatedData);
  }
  for (let data in config.custom.fuelTypes) {
    const configData = config.custom.fuelTypes;
    const title = { title: 'Fuel' };
    let updatedData = { ...configData[data], ...title };
    fuelTypesDataUpdate.push(updatedData);
  }
  for (let data in config.custom.drivenKm) {
    const configData = config.custom.drivenKm;
    const title = { title: 'Odometer' };
    let updatedData = { ...configData[data], ...title };
    drivenKmDataUpdate.push(updatedData);
  }
  const seatsLabel =
    typeof seats === 'string' || typeof seats === 'number'
      ? `${seats || '0'} Seats`
      : seats && seats.length > 0
      ? `${seats ? seats[0] : '0'} - ${seats ? seats[1] : '0'} Seats`
      : null;
  let options = [
    ...transmissionDataUpdate,
    ...fuelTypesDataUpdate,
    { key: 'numberSeats', label: seatsLabel },
    ...drivenKmDataUpdate,
  ];

  let selectedFuelType = fuelType;
  if (isFuelTypeIsPetrol(selectedFuelType)) {
    selectedFuelType = 'petrol';
  }

  const selectedOptions = [
    ...(transmissions || ''),
    selectedFuelType || '',
    !seatsLabel || 'numberSeats',
    drivenKm || '',
  ];
  options = useMemo(
    () =>
      options.filter(option => {
        return selectedOptions.filter(select => select === option.key).length > 0;
      }),
    [options, selectedOptions]
  );

  let optionsToDisplay = options;
  // Handle petrol type listings
  if (optionsToDisplay && optionsToDisplay.length) {
    optionsToDisplay = optionsToDisplay.map(item => {
      if (isFuelTypeIsPetrol(item && item.key ? item.key : '')) {
        return { key: 'petrol', label: 'Petrol' };
      } else {
        return item;
      }
    });
  }

  return description || optionsToDisplay.length > 0 ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>Car basics</h2>
      <LongTermPropertyGroup
        id="ListingPage.aboutCar"
        options={optionsToDisplay}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
