import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';
import { fetchFeaturedBCar, selectedPrediction } from '../SearchPage/SearchPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import SectionTestimonials from '../BLandingPage/SectionTestimonials';
import SectionHelpBLanding from '../BLandingPage/SectionHelpBLanding';
import BLandingCss from '../BLandingPage/BLandingPage.css';
import SectionSearchBanner from '../BLandingPage/SectionSearchBanner';
import SectionGrids from '../BLandingPage/SectionGrids';
// import SeoLandingRentalCategories from '../BLandingPage/SeoLandingRentalCategories';
import CarsListings from '../BLandingPage/CarsListings';
import ChannelsListings from '../BLandingPage/ChannelsListings';
import GrabBannerDesktop from '../BLandingPage/GrabBannerDesktop';
import imageSource from '../../util/imageSource';

const MAX_MOBILE_SCREEN_WIDTH = 768;

let EXPERIMENT_DATA = {};

let initialPageData = null;

export const LandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    listings,
    viewport,
    fetchFeaturedCarInProgress,
    onSelectedPrediction,
    currentUser,
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const [videoModalOpen, onVideoModalOpen] = useState(false);

  const onPushGTMEvent = (event, buttonId) => {
    pushGTMBrowseEvent({
      props,
      event,
      buttonId,
    });
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {
    console.log('Error', error);
  }

  return (
    <Page
      className={BLandingCss.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={'BLandingPage'} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={BLandingCss.contentWrapperBWrapper}>
          <h1 style={{ position: 'absolute', left: -999999, opacity: 0 }}>
            <FormattedMessage id="LandingPage.header1" />
          </h1>
          <SectionSearchBanner
            onSelectedPrediction={onSelectedPrediction}
            isMobileLayout={isMobileLayout}
          />
          <div
            style={{
              backgroundImage: !isMobileLayout && `url(${imageSource.bLanding.lightBg})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              zIndex: isMobileLayout && !videoModalOpen && 75,
            }}
          >
            <ChannelsListings isMobileLayout={isMobileLayout} />
            <GrabBannerDesktop
              currentUser={currentUser}
              isMobileLayout={isMobileLayout}
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <CarsListings isMobileLayout={isMobileLayout} />
            <SectionGrids
              onManageDisableScrolling={onManageDisableScrolling}
              onVideoModalOpen={onVideoModalOpen}
              onPushGTMSeeVideoEvent={onPushGTMEvent}
            />
            <SectionTestimonials />
            <SectionHelpBLanding />
            {/* <SeoLandingRentalCategories /> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onSelectedPrediction: val => dispatch(selectedPrediction(val)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = () => {
  return fetchFeaturedBCar();
};

export default LandingPage;
