import React from 'react';
import css from './LandingPageByod.css';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { InlineTextButton, NamedLink } from '../../components';
import imageSource from '../../util/imageSource';

const SectionHero = props => {
  const {
    ucsModalOpen,
    loingModalOpen,
    isAuthenticated,
    ucsHostDeclarationSigned,
    hasPublishedOrPendingApprovalListings,
  } = props;

  const nameOfLink = hasPublishedOrPendingApprovalListings
    ? 'ManageListingsPage'
    : 'NewListingPage';

  const btnText = hasPublishedOrPendingApprovalListings ? 'Go to your listings' : 'List your car';

  return (
    <div className={css.sectionHero}>
      <img
        srcSet={`${imageSource.byod.goLogo} 1x, ${imageSource.byod.goLogo} 2x`}
        className={css.goLogo}
      />
      <div
        className={css.sectionHeroBg}
        title="Drive mate’s BYOD (Bring your own device) program | Drive mate"
      >
        <h1 className={css.title}>
          {ucsHostDeclarationSigned ? (
            <div>
              <FormattedMessage id="LandingPageByod.SectionHero.title2" />
            </div>
          ) : (
            <>
              <div
                style={{
                  alignSelf: 'center',
                }}
              >
                <FormattedMessage id="LandingPageByod.SectionHero.title" />
              </div>
              <FormattedMessage id="LandingPageByod.SectionHero.title1" />
            </>
          )}
        </h1>
        <div className={classNames(css.description, css.sectionHeroDescription)}>
          <FormattedMessage
            id="LandingPageByod.SectionHero.description"
            values={{ newLine: <br /> }}
          />
        </div>
        {/* {ucsHostDeclarationSigned ?
            <div className={classNames(css.description, css.sectionHeroDescription)}>
              <FormattedMessage
                id="LandingPageByod.SectionHero.enrolled"
                values={{ newLine: <br /> }}
              />
            </div>: ''} */}

        {!isAuthenticated ? (
          <InlineTextButton
            rootClassName={classNames(css.desktop, css.goToSearchButton)}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              loingModalOpen();
            }}
          >
            <FormattedMessage id="LandingPageByod.loginForEnrol" />
          </InlineTextButton>
        ) : ucsHostDeclarationSigned ? (
          <NamedLink name={nameOfLink} className={classNames(css.desktop, css.goToSearchButton)}>
            {btnText}
          </NamedLink>
        ) : (
          <InlineTextButton
            rootClassName={classNames(css.desktop, css.goToSearchButton)}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              ucsModalOpen();
            }}
          >
            <FormattedMessage id="LandingPageByod.learnMore" />
          </InlineTextButton>
        )}
      </div>

      {!isAuthenticated ? (
        <InlineTextButton
          rootClassName={classNames(css.mobile, css.goToSearchButton)}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            loingModalOpen();
          }}
        >
          <FormattedMessage id="LandingPageByod.loginForEnrol" />
        </InlineTextButton>
      ) : ucsHostDeclarationSigned ? (
        <NamedLink name="NewListingPage" className={classNames(css.mobile, css.goToSearchButton)}>
          <FormattedMessage id="LandingPageByod.listyoucar" />
        </NamedLink>
      ) : (
        <InlineTextButton
          rootClassName={classNames(css.mobile, css.goToSearchButton)}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            ucsModalOpen();
          }}
        >
          <FormattedMessage id="LandingPageByod.learnMore" />
        </InlineTextButton>
      )}
    </div>
  );
};

export default SectionHero;
