import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { array, arrayOf, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { createSlug, parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { Button, IconSpinner, ModalInMobile, NamedLink, PrimaryButton } from '../../components';
import { BookingTimeForm } from '../../forms';
import moment from 'moment';
import css from './BookingPanel.css';
import { getDatesFromUrlParams, momentTimeOfDayFromLocalToTimeZone } from '../../util/dates';

const TODAY = new Date();
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    history,
    location,
    intl,
    currentUser,
    initialDate,
    onCheckingVoucher,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
    onResetCode,
    calculateMobileHeight,
    isLiveListing,
    onReadInsurance,
    requestButtonId,
    onFetchTimeSlots,
    onEstimateBreakdown,
    estimateBreakdownInProgress,
    estimatedTx,
    onOpenRentalAgreement,
    bookingConfig,
    estimateError,
    fetchTimeSlotsInProgress,
    localTimeZone,
    isLongTerm,
    isLongTermBooking,
    shouldShowPricePerMonth,
    formattedPrice,
    priceTitle,
    longTermRental,
    monthlyTimeSlots,
    transaction,
    updateBooking,
    onRequestToUpdateBooking,
    getChildTransactionData,
    setDifferenceInHours,
  } = props;

  const [voucherCodeData, setVoucherCodeData] = useState({});
  const [isReferalDataPresent, setIsReferalDataPresent] = useState(false);

  const { price } = listing.attributes;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm =
    hasListingState && !isClosed && isLiveListing && !fetchTimeSlotsInProgress;
  const showClosedListingHelpText = listing.id && isClosed;
  const isBook = !!parse(location.search).book;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

  const isInstantBooking = listing && listing.attributes.publicData.instantBooking;

  useEffect(() => {
    const referalCode = localStorage.getItem('code');
    if (referalCode) {
      setIsReferalDataPresent(true);
    }

    if (typeof window !== 'undefined') {
      const bookingPanelMobileElem = window.document.getElementsByClassName(
        css.openBookingFormWrapper
      );
      const bookingPanelMobileHeight =
        bookingPanelMobileElem &&
        bookingPanelMobileElem[0] &&
        bookingPanelMobileElem[0].clientHeight;
      calculateMobileHeight(bookingPanelMobileHeight);
    }
    //Read code and codeType from localStorage
    const codeType = window.localStorage.getItem('codeType');
    let code = '';
    if (codeType && codeType === 'referralCode') {
      code = window.localStorage.getItem('code');
    }
    setVoucherCodeData({ code, codeType });
  }, []);

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const unitTranslationKey =
    longTermRental && shouldShowPricePerMonth ? 'ListingPage.perMonth' : 'ListingPage.perDay';

  const classes = updateBooking
    ? classNames(rootClassName || css.updateBookingPanel)
    : classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const listingParams = {
    slug: createSlug(listing && listing.attributes.title),
    id: listing && listing.id.uuid,
  };

  const { startDate, endDate, pickupTime, dropoffTime } = initialDate;
  const bookingStartDateMaybe = startDate ? startDate : null;
  const bookingEndDateMaybe = endDate ? endDate : null;
  const pickupTimeToDate = pickupTime ? moment(pickupTime, 'hh:mm a').toDate() : null;

  const dropoffTimeToDate = dropoffTime ? moment(dropoffTime, 'hh:mm a').toDate() : null;
  let bookingStartTimeMaybe = null;
  let bookingEndTimeMaybe = null;
  if (pickupTimeToDate && bookingStartDateMaybe) {
    bookingStartTimeMaybe = bookingStartDateMaybe.setHours(
      pickupTimeToDate.getHours(),
      pickupTimeToDate.getMinutes()
    );
  }

  if (dropoffTimeToDate && bookingEndDateMaybe) {
    bookingEndTimeMaybe = bookingEndDateMaybe.setHours(
      dropoffTimeToDate.getHours(),
      dropoffTimeToDate.getMinutes()
    );
  }

  const initialValues = {
    bookingStartDate: bookingStartDateMaybe ? { date: bookingStartDateMaybe } : null,
    bookingEndDate: bookingEndDateMaybe ? { date: bookingEndDateMaybe } : null,
    bookingStartTime: bookingStartTimeMaybe
      ? momentTimeOfDayFromLocalToTimeZone(bookingStartTimeMaybe, localTimeZone).format('x')
      : null,
    bookingEndTime: bookingEndTimeMaybe
      ? momentTimeOfDayFromLocalToTimeZone(bookingEndTimeMaybe, localTimeZone).format('x')
      : null,
  };
  const isNewCar = get(listing, 'attributes.metadata.isNewCar');

  const _handleLocationStates = locationParams => {
    if (
      location.state &&
      location.state.startDate &&
      location.state.startDate &&
      !moment(location.state.startDate).isValid() &&
      !moment(location.state.endDate).isValid()
    ) {
      const queryMaybe = parse(location.search);
      const resp = getDatesFromUrlParams(queryMaybe.dates, queryMaybe.hours);
      let passLocationStates = locationParams.state;
      passLocationStates = {
        ...passLocationStates,
        startDate: resp.start instanceof Date ? resp.start : resp.start.toDate(),
        endDate: resp.end instanceof Date ? resp.end : resp.end.toDate(),
        params: listingParams,
        isFromListingPage: true,
      };
      return passLocationStates;
    } else {
      return {
        ...locationParams.state,
        params: listingParams,
        isFromListingPage: true,
      };
    }
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        {!updateBooking && (
          <div className={css.bookingHeading}>
            <h2 className={titleClasses}>{title}</h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        )}
        {updateBooking && (
          <div>
            <h3 className={css.updateBookingTitle}>
              <FormattedMessage id="BookingPanel.updateBookingTitle" />
            </h3>
            <p className={css.updateBookingSubTitle}>
              <FormattedMessage id="BookingPanel.updateBookingSubTitle" />
            </p>
          </div>
        )}
        {showBookingDatesForm && !updateBooking ? (
          <BookingTimeForm
            currentUser={currentUser}
            timeSlots={timeSlots}
            monthlyTimeSlots={monthlyTimeSlots}
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            isOwnListing={isOwnListing}
            listingId={listing.id}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            listing={listing}
            onResetCode={onResetCode}
            checkedCode={checkedCode}
            checkCodeErorr={checkCodeErorr}
            checkCodeInProgress={checkCodeInProgress}
            onCheckingVoucher={onCheckingVoucher}
            onEstimateBreakdown={onEstimateBreakdown}
            estimateBreakdownInProgress={estimateBreakdownInProgress}
            estimatedTx={estimatedTx}
            requestButtonId={requestButtonId}
            listingParams={listingParams}
            onManageDisableScrolling={onManageDisableScrolling}
            onReadInsurance={onReadInsurance}
            onOpenRentalAgreement={onOpenRentalAgreement}
            bookingConfig={bookingConfig}
            initialDate={initialDate}
            estimateError={estimateError}
            initialValues={initialValues}
            localTimeZone={localTimeZone}
            isNewCar={isNewCar}
            isLongTerm={isLongTerm}
            isLongTermBooking={isLongTermBooking || shouldShowPricePerMonth}
            transaction={transaction}
            updateBooking={updateBooking}
            onRequestToUpdateBooking={onRequestToUpdateBooking}
            getChildTransactionData={getChildTransactionData}
            location={location}
            voucherCodeData={voucherCodeData}
            setDifferenceInHours={setDifferenceInHours}
          />
        ) : fetchTimeSlotsInProgress ? (
          <div className={css.loadingTimeSlotWrapper}>
            <IconSpinner />
          </div>
        ) : null}
      </ModalInMobile>
      {showBookingDatesForm && updateBooking ? (
        <BookingTimeForm
          currentUser={currentUser}
          timeSlots={timeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
          className={css.bookingForm}
          formId="BookingPanel"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={unitType}
          onSubmit={onSubmit}
          price={price}
          isOwnListing={isOwnListing}
          listingId={listing.id}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          listing={listing}
          onResetCode={onResetCode}
          checkedCode={checkedCode}
          checkCodeErorr={checkCodeErorr}
          checkCodeInProgress={checkCodeInProgress}
          onCheckingVoucher={onCheckingVoucher}
          onEstimateBreakdown={onEstimateBreakdown}
          estimateBreakdownInProgress={estimateBreakdownInProgress}
          estimatedTx={estimatedTx}
          requestButtonId={requestButtonId}
          listingParams={listingParams}
          onManageDisableScrolling={onManageDisableScrolling}
          onReadInsurance={onReadInsurance}
          onOpenRentalAgreement={onOpenRentalAgreement}
          bookingConfig={bookingConfig}
          initialDate={initialDate}
          estimateError={estimateError}
          initialValues={initialValues}
          localTimeZone={localTimeZone}
          isNewCar={isNewCar}
          isLongTerm={isLongTerm}
          isLongTermBooking={isLongTermBooking || shouldShowPricePerMonth}
          transaction={transaction}
          updateBooking={updateBooking}
          onRequestToUpdateBooking={onRequestToUpdateBooking}
          getChildTransactionData={getChildTransactionData}
          location={location}
          voucherCodeData={voucherCodeData}
        />
      ) : fetchTimeSlotsInProgress ? (
        <div className={css.loadingTimeSlotWrapper}>
          <IconSpinner />
        </div>
      ) : null}
      {!updateBooking && (
        <div className={css.openBookingFormWrapper}>
          <div className={css.openBookingForm}>
            <div className={css.priceContainer}>
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div>

            {isLiveListing ? (
              <div>
                <p className={css.smallPrintForMember}>
                  <FormattedMessage id="BookingPanel.memberUseDrivelah" />
                </p>

                {currentUser && currentUser.id ? (
                  <Button
                    rootClassName={css.bookButton}
                    disabled={!currentUser.canRequestBooking}
                    onClick={() => {
                      openBookModal(isOwnListing, isClosed, history, location);
                    }}
                  >
                    <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                  </Button>
                ) : isReferalDataPresent ? (
                  <NamedLink
                    name="SignupPage"
                    to={{
                      state: _handleLocationStates(location),
                      // state: { ...location.state, params: listingParams, isFromListingPage: true },
                    }}
                  >
                    <PrimaryButton type="button">
                      <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                    </PrimaryButton>
                  </NamedLink>
                ) : (
                  <NamedLink
                    name="LoginPage"
                    to={{
                      state: _handleLocationStates(location),
                      // state: { ...location.state, params: listingParams, isFromListingPage: true },
                    }}
                  >
                    <PrimaryButton type="button">
                      <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                    </PrimaryButton>
                  </NamedLink>
                )}

                {!isInstantBooking && (
                  <p className={css.smallPrint}>
                    <FormattedMessage id="BookingPanel.noCharge" />
                  </p>
                )}
              </div>
            ) : (
              <div className={css.closedListingButton}>
                <FormattedMessage id="ListingPage.listingNotLive" />
              </div>
            )}
            {isClosed ? (
              <div className={css.closedListingButton}>
                <FormattedMessage id="BookingPanel.closedListingButtonText" />
              </div>
            ) : null}
          </div>
          {isOwnListing && (
            <p className={css.smallPrint}>
              <FormattedMessage id="BookingPanel.ownListing" />
            </p>
          )}
        </div>
      )}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  isLiveListing: false,
  monthlyTimeSlots: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: oneOfType([arrayOf(propTypes.timeSlot), array]),
  monthlyTimeSlots: object,
  fetchTimeSlotsError: propTypes.error,
  isLiveListing: bool,
  onReadInsurance: func,
  requestButtonId: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  isLongTerm: bool,
  isLongTermBooking: bool,
};

export default compose(withRouter, injectIntl)(BookingPanel);
