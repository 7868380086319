exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ExtraDurationCharges_extraDurationChargesRoot__qwyHi {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #00a3ad0d;\n  border-radius: 4px;\n  padding: 10px;\n  border: 1px solid #ebebeb;\n\n  .ExtraDurationCharges_extraDurationTitle__3nEuB {\n    color: #4a4a4a;\n    font-size: 18px;\n    font-weight: 500;\n  }\n\n  .ExtraDurationCharges_extraDurationText__qV1-G {\n    color: #7c7c7c;\n    font-size: 14px;\n    font-weight: 500;\n  }\n\n  .ExtraDurationCharges_extraDurationValue__M_T5- {\n    color: #026786;\n    font-size: 18px;\n    font-weight: 900;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"extraDurationChargesRoot": "ExtraDurationCharges_extraDurationChargesRoot__qwyHi",
	"extraDurationTitle": "ExtraDurationCharges_extraDurationTitle__3nEuB",
	"extraDurationText": "ExtraDurationCharges_extraDurationText__qV1-G",
	"extraDurationValue": "ExtraDurationCharges_extraDurationValue__M_T5-"
};