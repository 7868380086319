import { createSlug } from './urlHelpers';
// import { trackAmplitudeEvent } from './amplitude';

export const sendGAEvent = ({ eventCategory, eventAction, eventValue, eventLabel = {} }) => {
  try {
    if (
      typeof window === 'undefined' ||
      typeof window.ga === 'undefined' ||
      typeof window.fbq === 'undefined'
    ) {
      return;
    }

    window.ga('send', {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventValue,
      eventLabel,
    });

    window.ga(
      'send',
      'pageview',
      `/virtual/${createSlug(eventCategory)}/${createSlug(eventAction)}`
    );

    window.fbq('trackCustom', `${eventCategory} - ${eventAction}`);

    // trackAmplitudeEvent({
    //   eventName: `${eventCategory} - ${eventAction}`
    // })
  } catch (error) {
    // Handle the exception here
    console.error('An error occurred while sending GA event:', error);
  }
};

export const setGADimension = ({ dimensionName, dimensionValue }) => {
  if (typeof window === 'undefined' || typeof window.ga === 'undefined') {
    return console.log('GA is not ready');
  }

  return window.ga('set', dimensionName, dimensionValue);
};
