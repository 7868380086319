import React, { Component } from 'react';
import { Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BAHBenifitsSection.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/-fg9W0MZYuo"
      ></iframe>
    </div>
  );
};

class BAHBenifitsSection extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  render() {
    const { onManageDisableScrolling } = this.props;

    return (
      <section className={css.benifitSection}>
        <div className={css.box1}>
          <div className={css.textBox1}>
            <h3 className={css.textBoxTitle1}>
              <FormattedMessage id="BecomeAHostPage.benifitTitleDM1" values={{ newline: <br /> }} />
            </h3>
            <div className={css.textBoxDesc1}>
              <FormattedMessage id="BecomeAHostPage.benifitDescDM1" values={{ newline: <br /> }} />
            </div>
          </div>
          <div className={css.videoWrapper}>
            <div onClick={this.handleOpen} className={css.video}>
              <LazyImage className={css.videoLogo} src={imageSource.landingPage.videoLogo} />
            </div>
          </div>
          <Modal
            id="videoLandingpage"
            {...this.props}
            containerClassName={css.modalContainer}
            closeButtonClassName={css.modalCloseButton}
            contentClassName={css.contentModal}
            isOpen={this.state.isOpen}
            onClose={() => {
              const videoIframe = document.querySelector('#videoIframe');
              if (videoIframe) {
                const iframeSrc = videoIframe.src;
                videoIframe.src = iframeSrc;
              }
              this.setState({ isOpen: false });
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ModalVideo />
          </Modal>
        </div>
        <div className={css.seperateLine}></div>
        <div className={css.box2}>
          <h4 className={css.textBoxTitle1}>
            <FormattedMessage id="BecomeAHostPage.benifitTitleDM2" values={{ newline: <br /> }} />
          </h4>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.subBoxTitle}>
                <FormattedMessage
                  id="BecomeAHostPage.subBoxTitleDM1"
                  values={{ newline: <br /> }}
                />
              </div>
              <div className={css.subBoxDesc}>
                <FormattedMessage id="BecomeAHostPage.subBoxDescDM1" values={{ newline: <br /> }} />
              </div>
            </div>
            <div className={css.subBox}>
              <div className={css.subBoxTitle}>
                <FormattedMessage
                  id="BecomeAHostPage.subBoxTitleDM2"
                  values={{ newline: <br /> }}
                />
              </div>
              <div className={css.subBoxDesc}>
                <FormattedMessage id="BecomeAHostPage.subBoxDescDM2" values={{ newline: <br /> }} />
              </div>
            </div>
            <div className={css.subBox}>
              <div className={css.subBoxTitle}>
                <FormattedMessage
                  id="BecomeAHostPage.subBoxTitleDM3"
                  values={{ newline: <br /> }}
                />
              </div>
              <div className={css.subBoxDesc}>
                <FormattedMessage id="BecomeAHostPage.subBoxDescDM3" values={{ newline: <br /> }} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className={css.seperateLine}></div> */}
      </section>
    );
  }
}

export default BAHBenifitsSection;
