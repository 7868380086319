import React, { useState } from 'react';
import BLandingCss from './BLandingPage.css';
import { ListingCardV2, NamedLink } from '../../components';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import Slider from 'react-slick';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import { fetchFeaturedBCar } from '../SearchPage/SearchPage.duck';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';
import { UUID } from 'sharetribe-flex-sdk/src/types';

const CarsListings = ({ onfetchHourlyFeaturedCars, isMobileLayout }) => {
  const { featuredBCarIds, fetchFeaturedBCarInProgress, fetchFeaturedBCarError } = useSelector(
    state => state.SearchPage
  );
  const featuredBCarRefs = featuredBCarIds.map(id => ({ type: 'listing', id }));
  const [carListingType, setCarListingType] = useState('daily');
  const listings = useSelector(state => getMarketplaceEntities(state, featuredBCarRefs));

  const sliderConfig = {
    // className: BLandingCss.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const showDailyCarList = () => {
    setCarListingType('daily');
    onfetchHourlyFeaturedCars(null);
  };

  const showHourlyCarList = () => {
    setCarListingType('hourly');
    onfetchHourlyFeaturedCars({ isHourlyFeatured: true });
  };

  return (
    <div className={BLandingCss.carsListings}>
      <div className={BLandingCss.carsListingsTitleWrapper}>
        <div className={BLandingCss.carsListingsSectionTitle}>
          Choose from top rented cars in Australia
        </div>
      </div>
      <div className={BLandingCss.carListingTabs}>
        <div className={BLandingCss.carListingTabsOuter}>
          <span
            className={BLandingCss.activeSwitch}
            style={{ left: carListingType === 'daily' ? 0 : 90 }}
          ></span>
          <div
            className={
              carListingType === 'daily'
                ? `${BLandingCss.tabButtons} ${BLandingCss.active}`
                : `${BLandingCss.tabButtons}`
            }
            onClick={showDailyCarList}
          >
            Daily
          </div>
          <div
            className={
              carListingType === 'hourly'
                ? `${BLandingCss.tabButtons} ${BLandingCss.active}`
                : `${BLandingCss.tabButtons}`
            }
            onClick={showHourlyCarList}
          >
            Hourly
          </div>
        </div>
      </div>
      {!isMobileLayout ? (
        fetchFeaturedBCarInProgress ? (
          <div className={BLandingCss.carsListingsLoading}>Loading car...</div>
        ) : (
          <div className={BLandingCss.carsListingsGrid}>
            {listings.map(l => (
              <div
                key={l.id.uuid || new UUID('car-listings-uuid')}
                className={BLandingCss.carsListingsCardWrapper}
              >
                <ListingCardV2 carListingType={carListingType} listing={l} />
              </div>
            ))}
          </div>
        )
      ) : (
        ''
      )}
      {isMobileLayout ? (
        fetchFeaturedBCarInProgress ? (
          <div className={BLandingCss.sectionCarsLoading}>Loading car...</div>
        ) : (
          <div className={BLandingCss.sliderWrapper}>
            <Slider {...sliderConfig}>
              {listings.map(l => (
                <div className={BLandingCss.carsListingsCardWrapper}>
                  <ListingCardV2 carListingType={carListingType} listing={l} />
                </div>
              ))}
            </Slider>
          </div>
        )
      ) : (
        ''
      )}
      <div className={BLandingCss.btnWrapper}>
        <NamedLink
          name="SearchPage"
          to={{
            search:
              '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798',
          }}
          className={BLandingCss.viewAllCar}
          onClick={() => {
            initiateEventFromListing({
              props: {
                searchParams: {
                  // ...currentSearchParams,
                  bounds: {
                    _sdkType: 'LatLngBounds',
                    ne: {
                      _sdkType: 'LatLng',
                      lat: 1.4708809,
                      lng: 104.04157989999999,
                    },
                    sw: {
                      _sdkType: 'LatLng',
                      lat: 1.216611,
                      lng: 103.60650989999999,
                    },
                  },
                },
              },
              event: EVENT_SEARCH_PERFORMED,
            });
            gSend(GTAG_ACTIONS.ACTION_SEARCH, {
              // TODO: Assign proper details.
              search_location: `?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798`,
              search_pickupdate: `NA`,
              search_pickuptime: `NA`,
              search_dropoffdate: `NA`,
              search_dropofftime: `NA`,
            });
          }}
        >
          <FormattedMessage id="LandingPage.viewAllCar" />
        </NamedLink>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onfetchHourlyFeaturedCars: filterParams => dispatch(fetchFeaturedBCar(filterParams)),
});

export default connect(null, mapDispatchToProps)(CarsListings);
