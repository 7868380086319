import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchCurrentUser, updateUserData } from '../../ducks/user.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
  UserNav,
  UserPictureIDVerification,
  VerificationStatusIndication,
} from '../../components';
import { DrivingLicenseForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { pushGTMGuestVerify } from '../../util/gtm/gtmHelpers';
import { EVENT_GUEST_VERIFY_OPEN_DRIVER_FORM } from '../../util/gtm/gtmConstants';
import css from './AccountSettingDriverVerificationPage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import classNames from 'classnames';
import CardVerificationPage from '../CardVerificationPage/CardVerificationPage';
import { verifyIdentifyFromServer } from '../../util/identityVerificationHelpers';
import NewVerificationFlow from '../../components/NewVerificationFlow/NewVerificationFlow';
import VerificationSteps from '../../components/VerificationSteps/VerificationSteps';

// import 'onfido-sdk-ui/dist/style.css';

const { LatLng } = sdkTypes;

class AccountSettingDriverVerificationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldSubmitStatus: null,
      allowNavigateToHome: false,
      continueVerification: false,
      showSteps: false,
      step: {
        stepNum: 0,
        stepTitle: '',
      },

      isVerificationModalOpen: false,
    };
    this.watchRedirectTimeout = null;
    this.setIsVerificationModalOpen = this.setIsVerificationModalOpen.bind(this);
  }

  setIsVerificationModalOpen = flag => {
    this.setState({
      isVerificationModalOpen: flag,
    });
  };

  componentDidMount() {
    const { currentUser, fetchCalendarVisibility, onUpdateUserData } = this.props;
    fetchCalendarVisibility();
    if (currentUser) {
      pushGTMGuestVerify({
        props: this.props,
        event: EVENT_GUEST_VERIFY_OPEN_DRIVER_FORM,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.watchRedirectTimeout);
  }

  redirectToHomePage = () => {
    this.watchRedirectTimeout = setTimeout(() => {
      this.setState({
        allowNavigateToHome: true,
      });
    }, 5000);
  };

  render() {
    const {
      currentUser,
      scrollingDisabled,
      intl,
      connectCalendarTabVisibility,
      isFromTripPage,
      pageName,
      manageDisableSidebar,
    } = this.props;
    const userLocation = get(currentUser, 'attributes.profile.protectedData.location', null);
    const phoneNumberVerified = get(
      currentUser,
      'attributes.profile.protectedData.phoneNumberVerified',
      null
    );
    // const isEmailVerified = get(currentUser, 'attributes.emailVerified', null);
    const dateOfBirth = get(currentUser, 'attributes.profile.protectedData.dateOfBirth', null);
    const isGuestVerified = get(currentUser, 'identityStatus.guestVerified', false);
    const isHostVerified = get(currentUser, 'identityStatus.hostVerified', false);
    // const backgroundCheckConsent = get(currentUser, 'attributes.profile.protectedData.backgroundCheckConsent', {});
    // let isBackgroundCheckConsentAccepted = backgroundCheckConsent && backgroundCheckConsent.hasOwnProperty('status');
    // const timeTaken = backgroundCheckConsent.timeTaken || 0;
    const isCardVerified = get(
      currentUser,
      'attributes.profile.protectedData.isPaymentMethodVerified'
    )
      ? true
      : false;
    const isSubmittedOnfidoVerification = get(
      currentUser,
      'attributes.profile.publicData.onFidoCheckId'
    )
      ? true
      : false;

    const totalSteps = this.props.isHost ? 3 : 7;

    let tabs = [...config.custom.tabs];
    const user = ensureCurrentUser(currentUser);
    tabs = tabs
      .map(tab => ({ ...tab }))
      .filter(tab => {
        if (!(user && ensureHostVerified(user))) {
          if (tab.linkProps.name === 'MonthlyStatementPage') {
            return false;
          }
        }
        if (tab.linkProps.name === 'SyncCalenderPage') {
          return Boolean(connectCalendarTabVisibility);
        }
        return true;
      });
    const currentTab = tabs.find(
      tab => tab.linkProps.name === 'AccountSettingDriverVerificationPage'
    );
    if (currentTab) {
      currentTab.selected = true;
    }

    const protectedData = user.attributes.profile.protectedData || {};

    const DrivingLicenseInfoForm = user.id ? (
      <DrivingLicenseForm
        className={css.form}
        initialValues={{}}
        currentUser={currentUser}
        onSubmit={() => {}}
        isFromTripPage={isFromTripPage}
      />
    ) : null;

    const verficationstatus = this.props.isHost
      ? get(currentUser, 'attributes.profile.publicData.hostIdentityVerificationStatus')
      : get(currentUser, 'attributes.profile.publicData.guestIdentityVerificationStatus');

    const handleContinueVerification = (value = true) => {
      this.setState({ continueVerification: value });
    };

    const handleSteps = (stepNum, stepTitle) => {
      this.setState({
        showSteps: true,
        step: {
          stepNum,
          stepTitle,
        },
      });
    };

    // const isVerified = verficationstatus === 'confirmed' && isBackgroundCheckConsentAccepted ? true : false;
    const isVerified = verficationstatus === 'confirmed' ? true : false;
    const isVerificationApproved = verficationstatus === 'approved' || isVerified ? true : false;
    // isBackgroundCheckConsentAccepted = (isBackgroundCheckConsentAccepted || isVerificationApproved)  ? true : false;
    const isDeclined = verficationstatus === 'declined' ? true : false;

    const stepsCount = () => {
      let count = null;
      if (currentUser && currentUser.id) {
        if (phoneNumberVerified) count = count + 1;
        if (userLocation) count = count + 1;
        if (isSubmittedOnfidoVerification === true || isVerified) count = count + 1;
        if (!this.props.isHost) {
          // if (isEmailVerified) count = count + 1;
          if (isCardVerified) count = count + 1;
          // if (isBackgroundCheckConsentAccepted) count = count + 1;
          // if (dateOfBirth) count = count + 1;
        }
      }
      return count;
    };

    const isVerificationInProgress =
      !!currentUser &&
      !!currentUser.id &&
      !isVerified &&
      (!isSubmittedOnfidoVerification ||
        !phoneNumberVerified ||
        (!this.props.isHost
          ? !isCardVerified || !isSubmittedOnfidoVerification
          : !isSubmittedOnfidoVerification));

    const isVerificationInProgressNone =
      !!currentUser &&
      !!currentUser.id &&
      !isVerified &&
      !isSubmittedOnfidoVerification &&
      !phoneNumberVerified &&
      (!this.props.isHost
        ? !isCardVerified && !isSubmittedOnfidoVerification
        : !isSubmittedOnfidoVerification);
    const isPendingVerification = verficationstatus === 'pending' ? true : false;
    const isNotSubmittedVerification =
      !isVerified &&
      (isVerificationInProgressNone || !isVerificationInProgress) &&
      !isPendingVerification;

    if (
      !!currentUser &&
      !!currentUser.id &&
      !isVerified &&
      !isDeclined &&
      !isPendingVerification &&
      isSubmittedOnfidoVerification &&
      userLocation &&
      phoneNumberVerified &&
      (!this.props.isHost
        ? isCardVerified && isSubmittedOnfidoVerification
        : isSubmittedOnfidoVerification)
    ) {
      const publicData = this.props.isHost
        ? {
            hostIdentityVerificationStatus: 'pending',
            hostVerificationSent: new Date().toISOString(),
          }
        : { guestIdentityVerificationStatus: 'pending' };
      this.props
        .onUpdateUserData({
          publicData,
        })
        .then(() => {
          if (this.props.isHost) {
            verifyIdentifyFromServer({
              userId: currentUser.id.uuid,
              submitType: 'host',
            });
          } else {
            verifyIdentifyFromServer({
              userId: currentUser.id.uuid,
              submitType: 'guest',
            });
          }
        });
    }

    if (this.state.oldSubmitStatus === null && !!currentUser) {
      this.setState({
        oldSubmitStatus: isNotSubmittedVerification,
      });
    }

    const showThankYouPage =
      !!currentUser && !!currentUser.id && isSubmittedOnfidoVerification === true;

    // const showThankYouPage = isVerificationInProgress && this.state.oldSubmitStatus;

    const thankYouPage = (
      <div className={css.thankYouPage}>
        <h2>
          <FormattedMessage
            id="AccountSettingDriverVerificationPage.thankYouPageTitle"
            values={{ name: currentUser && `${currentUser.attributes.profile.firstName}` }}
          />
        </h2>
        <p>
          <FormattedMessage id="AccountSettingDriverVerificationPage.thankYouPageDescription" />
        </p>
        {this.state.allowNavigateToHome && <NamedRedirect name="LandingPage" />}
      </div>
    );

    // if (showThankYouPage) {
    //   this.redirectToHomePage();
    // }

    let status;
    let statusID;
    if (isDeclined) {
      status = <FormattedMessage id="AccountSettingDriverVerificationPage.declined" />;
      statusID = 'AccountSettingDriverVerificationPage.declined';
    } else if (isNotSubmittedVerification) {
      status = <FormattedMessage id="AccountSettingDriverVerificationPage.notSubmit" />;
      statusID = 'AccountSettingDriverVerificationPage.notSubmit';
    } else if (isPendingVerification) {
      status = <FormattedMessage id="AccountSettingDriverVerificationPage.pending" />;
      statusID = 'AccountSettingDriverVerificationPage.pending';
    } else if (isVerificationInProgress) {
      const remaining = totalSteps - stepsCount();
      const s = remaining > 1 ? 's' : '';
      status = (
        <FormattedMessage
          id="AccountSettingDriverVerificationPage.inProgress"
          values={{ remaining, s }}
        />
      );
      statusID = 'AccountSettingDriverVerificationPage.inProgress';
    } else if (isVerified) {
      status = <FormattedMessage id="AccountSettingDriverVerificationPage.submitted" />;
      statusID = 'AccountSettingDriverVerificationPage.submitted';
    } else {
      status = <FormattedMessage id="AccountSettingDriverVerificationPage.notSubmit" />;
      statusID = 'AccountSettingDriverVerificationPage.notSubmit';
    }

    console.log(
      'Statusdklfjdsklfjdslfkdsjfkl',
      isNotSubmittedVerification,
      isDeclined,
      isPendingVerification,
      isVerificationInProgress,
      isVerified
    );

    const statusCss = isNotSubmittedVerification
      ? css.statusBarNotVerified
      : isVerificationInProgress
      ? css.statusBarPending
      : isVerified
      ? css.statusBarVerified
      : css.statusBarNotVerified;
    const unverifiedLayout = (
      <div
        className={classNames(
          css.titleContainer,
          isFromTripPage ? css.fromTripPageContainer : null
        )}
      >
        <div
          className={css.cardTitle}
          style={
            {
              // borderBottom: !this.state.showSteps && '1px solid #E6E6E6',
              // margin: !this.state.showSteps && '0 0 24px',
              // padding: !this.state.showSteps && '0 0 20px',
            }
          }
        >
          <span className={css.title}>
            {this.props.isHost ? (
              'Host verification status: '
            ) : this.state.step.stepTitle === '' ? (
              <FormattedMessage id="AccountSettingDriverVerificationPage.status" />
            ) : (
              <FormattedMessage
                id="AccountSettingDriverVerificationPage.statusTitle"
                values={{ title: this.state.step.stepTitle }}
              />
            )}{' '}
          </span>
          <span
            className={`${css.submissionStatus} ${
              this.state.showSteps ? css.submissionStatusVisibility : ''
            }`}
          >
            {this.state.showSteps ? `Step ${this.state.step.stepNum} of ${totalSteps}` : status}
          </span>
        </div>
        {this.state.showSteps && !isDeclined && (
          <div className={`${css.stepsContainer}`}>
            <div
              className={`${css.submissionStatusMobile}`}
              style={totalSteps === this.state.step.stepNum ? { color: '#fff' } : {}}
            >
              {this.state.showSteps ? `${this.state.step.stepNum}/${totalSteps}` : status}
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}
              className={css.progressBar}
            >
              {Array.from(Array(totalSteps).keys()).map(i => (
                <div
                  key={i + '_key'}
                  className={`${css.progressBarItem} ${
                    i + 1 !== this.state.step.stepNum ? css.progressBarItemNotSelected : ''
                  } ${i + 1 < this.state.step.stepNum ? css.progressBarItemDone : ''}`}
                  style={{
                    border: '4px solid',
                    width: this.props.isHost ? '33.3%' : '16.6%',
                    marginRight: '1px',
                    // borderColor:
                    //   i + 1 < this.state.step.stepNum
                    //     ? '#fecd2a'
                    //     : i + 1 === this.state.step.stepNum
                    //       ? '#00a3ad'
                    //       : '#E6E6E6',
                  }}
                >
                  {' '}
                </div>
              ))}
            </div>
          </div>
        )}

        {!this.state.continueVerification && (
          <VerificationStatusIndication
            currentUser={currentUser}
            isDeclined={isDeclined}
            handleContinueVerification={handleContinueVerification}
            phoneNumberVerified={phoneNumberVerified}
            // isEmailVerified={isEmailVerified}
            userLocation={userLocation}
            dateOfBirth={dateOfBirth}
            isHost={this.props.isHost}
            isCardVerified={isCardVerified}
            // isBackgroundCheckConsentAccepted={isBackgroundCheckConsentAccepted}
            isVerified={isVerified}
            isSubmittedOnfidoVerification={isSubmittedOnfidoVerification}
            isPendingVerification={isPendingVerification}
            isVerificationInProgress={isVerificationInProgress}
            isNotSubmittedVerification={isNotSubmittedVerification}
          />
        )}

        {this.state.continueVerification && !isDeclined && <VerificationSteps />}

        {this.state.continueVerification && !isDeclined && (
          <UserPictureIDVerification
            onComplete={() => {}}
            onChange={() => {}}
            onResendVerificationEmail={() => {}}
            currentUser={currentUser}
            transactionId={this.props.transactionId}
            transaction={this.props.transaction}
            onTransit={this.props.onTransit}
            haveNextTransit={this.props.haveNextTransit}
            nextInteractableTransitions={this.props.nextInteractableTransitions}
            pageName={this.props.pageName}
            isHost={this.props.isHost}
            phoneNumberVerified={phoneNumberVerified}
            // isEmailVerified={isEmailVerified}
            userLocation={userLocation}
            dateOfBirth={dateOfBirth}
            handleSteps={handleSteps}
            isGuestVerified={isGuestVerified}
            isSubmittedOnfidoVerification={isSubmittedOnfidoVerification}
            isHostVerified={isHostVerified}
            mapDispatchToProps={mapDispatchToProps}
            setCardVerificationModal={this.setIsVerificationModalOpen}
            CardVerificationModal={this.state.isVerificationModalOpen}
            isCardVerified={isCardVerified}
            // isBackgroundCheckConsentAccepted={isBackgroundCheckConsentAccepted}
            handleContinueVerification={handleContinueVerification}
            showThankYouPage={showThankYouPage}
            thankYouPage={thankYouPage}
          />
        )}
      </div>
    );

    const verifiedorPendingLayout = (
      <div className={css.titleContainer}>
        {/* // const addressInfoForm = user.id ? (
    //   <AddressDetailsForm */}
        {/* //     className={css.form}
    //     currentUser={currentUser}
    //     onSubmit={() => {}}
    //     initialValues={addressDetailsInitialValues}
    //     shouldHideSubmitButton
    //     isFromTripPage={isFromTripPage}
    //   />
    // ) : null; */}

        {/* const submittedLayout = user.id ? (
      <div>
        {this.props.isFromTripPage && <div className={css.sectionTitle}>Driving Licence</div>}
        {DrivingLicenseInfoForm}
        {this.props.isFromTripPage && <div className={css.sectionTitle}>Address</div>}
        {addressInfoForm} */}
        <div className={css.cardTitle}>
          <span className={css.title}>
            <FormattedMessage id="AccountSettingDriverVerificationPage.status" />
            :&nbsp;
          </span>
          <span className={css.submissionStatus}>{status}</span>
        </div>
      </div>
    );
    const verificationStatusLayout = unverifiedLayout;

    const rightPageLayout = verificationStatusLayout;

    const transactionForNewVerification = this.props.transaction;
    const transactionIDForNewVerification = this.props.transactionId;
    const nextInteractableTransitionsNew = this.props.nextInteractableTransitions;
    const haveNextTransitNew = this.props.haveNextTransit;
    const onTransitNew = this.props.onTransit;
    const isHostNew = this.props.isHost;
    const onUpdateUserDataNew = this.props.onUpdateUserData;

    const statusPageProps = {
      status,
      statusID,
      phoneNumberVerified,
      isSubmittedOnfidoVerification,
      isCardVerified,
    };

    const stepsPageProps = {
      currentUser,
      dateOfBirth,
      transactionForNewVerification,
      transactionIDForNewVerification,
      nextInteractableTransitionsNew,
      haveNextTransitNew,
      onTransitNew,
      isHostNew,
      onUpdateUserDataNew,
      isCardVerified,
    };

    const title = intl.formatMessage({ id: 'AccountSettingDriverVerificationPage.title' });
    const tripPageClassName = this.props.isFromTripPage ? css.tripPageDocumentUploadFormRoot : '';
    if (this.props.manageDisableSidebar || this.props.isFromTripPage) {
      return (
        <Page
          title={title}
          scrollingDisabled={scrollingDisabled}
          className={classNames(
            css.verificationPage,
            isFromTripPage ? css.verificationPageMobile : null
          )}
          style={{ zIndex: -1 }}
        >
          {/* <LayoutSideNavigation> */}
          {/* <LayoutWrapperTopbar> */}
          {/* <TopbarContainer
                currentPage="AccountSettingDriverVerificationPage"
                desktopClassName={css.desktopTopbar}
                mobileClassName={css.mobileTopbar}
              /> */}
          {/* <UserNav selectedPageName="AccountSettingDriverVerificationPage" /> */}
          {/* </LayoutWrapperTopbar> */}
          {/* <LayoutWrapperSideNav tabs={tabs} user={user} /> */}
          <LayoutWrapperMain>
            <div className={css.content}>
              {/* {showThankYouPage && thankYouPage} */}
              {/* {!showThankYouPage && verificationStatusLayout} */}
              {/* {rightPageLayout} */}
              <NewVerificationFlow
                statusPageProps={statusPageProps}
                stepsPageProps={stepsPageProps}
              />
            </div>
            {this.state.isVerificationModalOpen && (
              <div className={css.insuranceListingWrapper}>
                <CardVerificationPage
                  scrollingDisabled={scrollingDisabled}
                  transactInModal={true}
                  currentUser={currentUser}
                  onUpdateUserData={this.props.onUpdateUserData}
                  handleGoBack={() => {
                    this.setIsVerificationModalOpen(false);
                  }}
                />
              </div>
            )}
          </LayoutWrapperMain>
          {/* <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter> */}
          {/* </LayoutSideNavigation> */}
        </Page>
      );
    }
    // const statusPageProps = {status, currentUser, isDeclined, phoneNumberVerified, isEmailVerified, userLocation, dateOfBirth, isCardVerified, isVerified, isSubmittedOnfidoVerification, isPendingVerification, isNotSubmittedVerification}

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled} className={tripPageClassName}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="AccountSettingDriverVerificationPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav selectedPageName="AccountSettingDriverVerificationPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav tabs={tabs} user={user} />
          <LayoutWrapperMain>
            {/* <h1>HEllo I am modified</h1> */}
            <NewVerificationFlow
              statusPageProps={statusPageProps}
              stepsPageProps={stepsPageProps}
            />
            {/* <div className={css.content}>
              {showThankYouPage && thankYouPage}
              {rightPageLayout}
            </div> */}
            {this.state.isVerificationModalOpen && (
              <div className={css.insuranceListingWrapper}>
                <CardVerificationPage
                  scrollingDisabled={scrollingDisabled}
                  transactInModal={true}
                  currentUser={currentUser}
                  onUpdateUserData={this.props.onUpdateUserData}
                  handleGoBack={() => {
                    this.setIsVerificationModalOpen(false);
                  }}
                />
              </div>
            )}
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

AccountSettingDriverVerificationPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

AccountSettingDriverVerificationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
  onUpdateUserData: data => dispatch(updateUserData(data)),
});

const AccountSettingDriverVerificationPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AccountSettingDriverVerificationPageComponent);

AccountSettingDriverVerificationPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default AccountSettingDriverVerificationPage;
