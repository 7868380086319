import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IntroPanelIcons from './IntroPanelIcons';
import css from './IntroPanel.css';

const IntroPanel = props => {
  const {
    rootClassName,
    className,
    iconTextStyle,
    questionTitle,
    answerTitle,
    subtitle,
    iconList,
    footer,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.questionTitle}>{questionTitle}</div>
      <div className={css.answerTitle}>{answerTitle}</div>
      {/* <div className={css.subtitle}>
        {subtitle}
      </div> */}
      <IntroPanelIcons iconTextStyle={iconTextStyle} iconList={iconList} />
      {footer}
    </div>
  );
};

IntroPanel.defaultProps = {
  rootClassName: null,
  className: null,
  iconTextStyle: null,
  questionTitle: null,
  answerTitle: null,
  subtitle: null,
  bodyPart: null,
  footer: null,
};

IntroPanel.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  iconTextStyle: PropTypes.string,
  questionTitle: PropTypes.node,
  answerTitle: PropTypes.node,
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bodyPart: PropTypes.element,
  footer: PropTypes.element,
};

export default IntroPanel;
