import React from 'react';
import css from './InstantBookingLandingPage.css';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from '../../components';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

export const SectionInstantBookingPolicies = () => {
  return (
    <div className={css.SectionInstantPolicies}>
      <div className={css.SectionInstantPoliciesWrapperContent}>
        <div className={css.sectionInstantPoliciesImageWrapper}>
          <LazyImage
            className={css.sectionInstantPoliciesImage}
            srcSet={`${imageSource.instantBookingLandingPage.instantPolicies} 1x, ${imageSource.instantBookingLandingPage.instantPolicies2x} 2x`}
            alt="Top rental cars in Australia are just a few clicks away with Drive Mate"
          />
        </div>
        <div className={css.sectionInstantPoliciesContentWrapper}>
          <div className={css.sectionInstantPoliciesContent}>
            <FormattedMessage id="InstantBookingLandingPage.SectionInstantBookingPolicies.description" />
          </div>
          <h4>
            <ExternalLink
              href={`https://help.${DOMAIN_HELP_URL}/kb/en`}
              target="_blank"
              className={classNames(css.button, css.learnmore)}
            >
              <span className={css.hiddenMobileText}>Learn more about </span>
              Instant booking policies
            </ExternalLink>
          </h4>
        </div>
      </div>
    </div>
  );
};
