import React from 'react';

const StarIcon = ({ isFilled = true, size = 10 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 22.446 21.322`}
    >
      <path
        id="Icon_material-star"
        data-name="Icon material-star"
        d="M13,18.27,19.18,22l-1.64-7.03L23,10.24l-7.19-.61L13,3,10.19,9.63,3,10.24l5.46,4.73L6.82,22Z"
        transform="translate(-1.777 -1.719)"
        fill={isFilled ? '#026786' : '#fff'}
        stroke="#026786"
        strokeWidth="1"
      />
    </svg>
  );
};

export default StarIcon;
