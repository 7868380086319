import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { Button, FieldCheckbox, FieldCheckboxGroup, Form } from '../../components';
import isEqual from 'lodash/isEqual';
import css from './ReferAHostPage.css';

const GroupOfGoogleContacts = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={fieldRenderProps => {
      const {
        emails,
        className,
        rootClassName,
        handleSubmit,
        invalid,
        inProgress,
        submittedValues,
        values,
        intl,
        form,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);

      const submittedOnce = Object.keys(submittedValues.current).length > 0;
      const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues.current);
      const submitDisabled = invalid || inProgress || pristineSinceLastSubmit;
      const submitReady = pristineSinceLastSubmit && !props.error;

      const subTitle = intl.formatMessage({ id: 'ReferAHostPage.groupOfGoogleContactsSubTitle' });

      // let testEmails = [];
      // for (let i = 0; i < 10; i++) {
      //   testEmails.push({
      //     address: `tri.nguyen+test${i}@journeyh.io`,
      //     name: `Tri Nguyen ${i}`,
      //   })
      // }

      const emailsObj = emails
        .filter(email => email.address)
        .map(email => ({
          key: email.address,
          label: email.name ? `${email.name} (${email.address})` : email.address,
        }));

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.googleContactsTitle}>
            {' '}
            {intl.formatMessage({ id: 'ReferAHostPage.googleContactsTitle' })}{' '}
          </div>
          <div className={css.googleContactsSubTitle}>{subTitle}</div>
          <div className={css.emailsList}>
            <FieldCheckboxGroup
              className={classes}
              options={emailsObj}
              itemClassName={css.googleContactsItem}
              id={`googleContacts`}
              name="googleContacts"
            />
          </div>
          <div className={css.googleContactsButtonWrapper}>
            <FieldCheckbox
              name="selectAll"
              id="selectAll"
              textClassName={css.googleContactsSelectAll}
              label={intl.formatMessage({ id: 'ReferAHostPage.selectAll' })}
              onChange={() => {
                form.batch(() => {
                  const updatedSelectAll = !values['selectAll'];
                  form.change('selectAll', updatedSelectAll);
                  form.change(
                    'googleContacts',
                    updatedSelectAll ? emailsObj.map(email => email.key) : []
                  );
                });
              }}
            />
            <Button
              className={css.googleContactsSendInvite}
              disabled={submitDisabled}
              inProgress={inProgress}
              ready={submitReady}
            >
              Send invite
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

export default GroupOfGoogleContacts;
