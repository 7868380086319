import React from 'react';
import css from './MasterCardLandingPage.css';
import { SectionHero } from './SectionHero';
import config from '../../config';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Footer,
  InsuranceDisclaimer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '..';
import { SectionOffers } from './SectionOffers';
import { SectionTermOfPromotion } from './SectionTermOfPromotion';

const MasterCardLandingPageComponent = ({ intl, scrollingDisabled }) => {
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero intl={intl} />
          <SectionOffers intl={intl} />
          <SectionTermOfPromotion intl={intl} />
          <InsuranceDisclaimer />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const MasterCardLandingPage = compose(injectIntl)(MasterCardLandingPageComponent);

export default MasterCardLandingPage;
