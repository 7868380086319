import React, { Component } from 'react';
import { array, bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { FilterPlain, FilterPopup } from '../../components';
import css from './ToggleSwitchFilter.css';
import FieldToggleSwitch from '../FieldToggleSwitch/FieldToggleSwitch';

class ToggleSwitchFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      urlParam,
      intl,
      showAsPopup,
      clearButtonClass,
      threeColumns,
      twoColumns,
      toggleSwitchClassName,
      canApply,
      plainClassName,
      labelClassName,
      customSliderClassName,
      customSwitchClassName,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const hasInitialValues = initialValues !== null;

    const labelForPopup = hasInitialValues
      ? intl.formatMessage({ id: 'SearchFilters.instantBooking' })
      : label;

    // const labelForPlain = hasInitialValues
    //   ? intl.formatMessage(
    //     { id: 'SearchFilters.instantBooking' }
    //   )
    //   : label;

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = {};
    namedInitialValues[`${name}`] = initialValues === null ? false : initialValues;

    const handleSubmit = (urlParam, values) => {
      const usedValue = values ? values[name] : values;
      onSubmit(urlParam, usedValue);
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        urlParam={urlParam}
        keepDirtyOnReinitialize
        canApply={canApply}
        {...rest}
      >
        <FieldToggleSwitch name={name} id={id} />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        clearButtonClass={clearButtonClass}
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        urlParam={urlParam}
        plainClassName={plainClassName}
        labelClassName={labelClassName}
        shouldHideClearButton
        {...rest}
      >
        <FieldToggleSwitch
          toggleSwitchClassName={toggleSwitchClassName}
          customSliderClassName={customSliderClassName}
          name={name}
          id={id}
        />
        {/* {
            hintText && <div className={css.hintText}>{hintText}</div>
          } */}
      </FilterPlain>
    );
  }
}

ToggleSwitchFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: false,
  contentPlacementOffset: 0,
  toggleSwitchClassName: null,
  customSliderClassName: null,
};

ToggleSwitchFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: bool.isRequired,
  contentPlacementOffset: number,
  canApply: bool,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ToggleSwitchFilter);
