import React from 'react';
import classNames from 'classnames';
import css from './LandingPageNew.css';
import BLandingCss from './LandingPageNew.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const ComparisonTable = () => {
  const tableContent = [
    // {
    //   tableRowTitle: 'Rent by hour, day, month',
    //   isIcon: true,
    //   isMinAge: false,
    //   isDriveMate: true,
    //   isTuro: false,
    //   turoComment: null,
    //   driveMateText: null,
    //   driveMateComment: 'Rent by the hour, day or month',
    //   isGoget: true,
    //   gogetComment: 'Rent by hour, day or month',
    //   gogetText: null,
    //   isCarNextDoor: true,
    //   carNextDoorComment: 'Rent by the hour, day or month',
    //   carNextText: null,
    //   isAvis: false,
    //   avisComment: 'Rent by the day or month',
    //   avisText: null,
    // },
    {
      tableRowTitle: 'Pricing',
      isIcon: false,
      isMinAge: false,
      isDriveMate: false,
      driveMateText: 79,
      driveMateComment: null,
      isGoget: false,
      gogetComment: 'Gostarter plan',
      gogetText: 94,
      isCarNextDoor: false,
      carNextDoorComment: null,
      carNextText: 91,
      isAvis: false,
      avisComment: false,
      avisText: 110,
    },
    {
      tableRowTitle: 'All inclusive pricing',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: null,
      driveMateComment: 'No subscription price, mileage included.',
      isGoget: false,
      gogetComment: 'Membership fees extra',
      gogetText: null,
      isCarNextDoor: false,
      carNextDoorComment: 'Mileage fees extra',
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
      tableRowTitle: 'Open car with app',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: null,
      driveMateComment: 'Keyless access technology',
      isGoget: true,
      gogetComment: 'Smart card',
      gogetText: null,
      isCarNextDoor: false,
      carNextDoorComment: 'Lockbox',
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
      tableRowTitle: 'Car Variety',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: null,
      driveMateComment: null,
      isGoget: false,
      isTuro: true,
      gogetComment: null,
      gogetText: null,
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
      tableRowTitle: '24x7 customer service',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: null,
      driveMateComment: null,
      isGoget: true,
      isTuro: true,
      gogetComment: null,
      gogetText: null,
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
      tableRowTitle: 'Min age criteria',
      isIcon: false,
      isMinAge: true,
      isDriveMate: true,
      driveMateText: null,
      driveMateComment: 'With full license',
      turoComment: 'With full license',
      isGoget: true,
      isTuro: true,
      gogetComment: null,
      gogetText: null,
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
  ];

  const handlePRNewswireClick = () =>
    window.open(
      'https://www.prnewswire.com/apac/news-releases/drive-mate-secures-strategic-investment-from-comfortdelgro-301971281.html',
      '_blank'
    );
  const handleNewsClick = () =>
    window.open(
      'https://www.news.com.au/technology/motoring/motoring-news/ubers-carshare-rival-booms-as-stranded-aussies-seek-options/news-story/cbf78c046cbcef641a1c3b38953130d6',
      '_blank'
    );

  return (
    <div className={css.tableSection}>
      <div className={BLandingCss.trustBox}>
        <div onClick={handlePRNewswireClick} className={BLandingCss.trustBoxWrapper}>
          <p>Featured on</p>
          <LazyImage objectFit={'contain'}
            srcSet={`${imageSource.landingPageNew.PRNewswire} 1x, ${imageSource.landingPageNew.PRNewswire2x} 2x`}
            alt="PR Newswire Icon"
          />
          <p> & </p>
          <LazyImage objectFit={'contain'}
            className={BLandingCss.trustBoxNewsIcon}
            onClick={handleNewsClick}
            src={imageSource.landingPageNew.News}
            alt="news icon"
          />
        </div>
        <div>
          <p>
            Rated{' '}
            <img
              className={BLandingCss.trustimg}
              src={imageSource.landingPageNew.Rating}
              alt="Rating"
            />{' '}
            on{' '}
            <img
              className={BLandingCss.trustimg}
              src={imageSource.landingPageNew.AppStoreIcon}
              alt="App Store Icon"
            />
          </p>
        </div>
      </div>
      <div className={css.tableBackground}>
        <div className={classNames(css.landingContainer)} style={{ position: 'relative' }}>
          <h2 className={css.tableHead}>
            Drive mate offers more value and convenience than others - it’s a no-brainer!
          </h2>
        </div>
        <div className={css.leftImg}>
          <img src={imageSource?.tableSectionImages?.tableSectionLeftBg} alt="" />
        </div>
        <div className={css.rightImg}>
          <img src={imageSource?.tableSectionImages?.tableSectionRightBg} alt="" />
        </div>
      </div>
      <div
        className={classNames(css.landingContainer)}
        style={{ position: 'relative' }}
        // style={{"border": "solid 2px red", "marginTop": "15%"}}
      >
        <div className={css.tableContent}>
          <table>
            <tr>
              <th>
                <div className={css.flexRow}></div>
              </th>
              <th>
                <div className={classNames(css.flexRow, css.rowHead)}>
                  <img loading='lazy' src={imageSource?.tableSectionImages?.driveMateLogo} alt="" />
                </div>
              </th>
              <th>
                <div className={classNames(css.flexRow, css.rowHead)}>
                  <img loading='lazy' src={imageSource?.tableSectionImages?.turo} alt="" />
                </div>
              </th>
              <th>
                <div className={classNames(css.flexRow, css.rowHead)}>
                  <img loading='lazy' src={imageSource?.tableSectionImages?.goget} alt="" />
                </div>
              </th>
              <th>
                <div className={classNames(css.flexRow, css.rowHead)}>
                  <img loading='lazy' src={imageSource?.tableSectionImages?.avis} alt="" />
                </div>
              </th>
            </tr>
            {tableContent &&
              tableContent.length > 0 &&
              tableContent.map((content, index) => (
                <tr>
                  <td className={css.rowTitle}>
                    {content.tableRowTitle === 'Pricing' ? (
                      <>
                        Pricing
                        {/*<a*/}
                        {/*  style={{ display: 'block', textDecoration: 'underline', fontSize: 14 }}*/}
                        {/*  href="https://help.drive-mate.com.au/kb/guide/en/frequently-asked-questions-nBU4CC7PL7/Steps/750993,750995,750994"*/}
                        {/*  target="_blank"*/}
                        {/*>*/}
                        {/*  See full comparison*/}
                        {/*</a>*/}
                      </>
                    ) : content.tableRowTitle === 'All inclusive pricing' ? (
                      <div
                        className={classNames(css.flexRow, css.headerPointers)}
                        style={{ textAlign: 'left' }}
                      >
                        <span className={css.isIconWrapper}>
                          {content.tableRowTitle}
                          <i className={css.infoIcon}>?</i>
                          <div className={css.info}>{content.driveMateComment}</div>
                        </span>
                      </div>
                    ) : (
                      content.tableRowTitle
                    )}
                  </td>
                  <td>
                    <div className={css.flexRow}>
                      <span className={css.isIconWrapper}>
                        {content.isMinAge ? (
                          <span className={css.literalText}>18</span>
                        ) : content.isIcon ? (
                          <LazyImage
                            src={
                              content.isDriveMate
                                ? imageSource?.tableSectionImages?.checkIcon
                                : imageSource?.tableSectionImages?.iconCancel
                            }
                            alt=""
                          />
                        ) : (
                          <span className={css.literalText}>$</span>
                        )}
                        {content.driveMateComment && <i className={css.infoIcon}>?</i>}
                        {content.driveMateComment && (
                          <div className={css.info}>{content.driveMateComment}</div>
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className={css.flexRow}>
                      <span className={css.isIconWrapper}>
                        {content.isMinAge ? (
                          <span className={css.literalText}>21</span>
                        ) : content.isIcon ? (
                          <LazyImage
                            src={
                              content.isTuro
                                ? imageSource?.tableSectionImages?.checkIcon
                                : imageSource?.tableSectionImages?.iconCancel
                            }
                            alt=""
                          />
                        ) : (
                          <span className={css.literalText}>$$</span>
                        )}
                        {content.turoComment && <i className={css.infoIcon}>?</i>}
                        {content.turoComment && (
                          <div className={css.info}>{content.turoComment}</div>
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className={css.flexRow}>
                      <span className={css.isIconWrapper}>
                        {content.isMinAge ? (
                          <span className={css.literalText}>18</span>
                        ) : content.isIcon ? (
                          <LazyImage
                            src={
                              content.isGoget
                                ? imageSource?.tableSectionImages?.checkIcon
                                : imageSource?.tableSectionImages?.iconCancel
                            }
                            alt=""
                          />
                        ) : (
                          <span className={css.literalText}>$$</span>
                        )}
                        {content.gogetComment && <i className={css.infoIcon}>?</i>}
                        {content.gogetComment && (
                          <div className={css.info}>{content.gogetComment}</div>
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className={css.flexRow}>
                      <div className={css.flexRow}>
                        <span className={css.isIconWrapper}>
                          {content.isMinAge ? (
                            <span className={css.literalText}>21</span>
                          ) : content.isIcon ? (
                            <LazyImage
                              src={
                                content.isAvis
                                  ? imageSource?.tableSectionImages?.checkIcon
                                  : imageSource?.tableSectionImages?.iconCancel
                              }
                              alt=""
                            />
                          ) : (
                            <span className={css.literalText}>$$$</span>
                          )}
                          {content.avisComment && <i className={css.infoIcon}>?</i>}
                          {content.avisComment && (
                            <div className={css.info}>{content.avisComment}</div>
                          )}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </table>
          <div className={css.lastUpdateText}>
            <p>*Last updated: 26/08/2024</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonTable;
