import React from 'react';
import classNames from 'classnames';
import css from './FilterButton.css';
import { bool, func, object, oneOfType, string } from 'prop-types';

const FilterButton = ({
  rootClassName,
  className,
  label,
  labelMaxWidth,
  value,
  onSubmit,
  urlParam,
  hintText,
}) => {
  const isSelected = !!value;
  const buttonClassNames = classNames(
    rootClassName || css.label,
    {
      [css.labelSelected]: isSelected,
      [css.labelEllipsis]: !!labelMaxWidth,
    },
    className
  );
  const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};

  const handleSubmit = () => {
    onSubmit(urlParam, !value);
  };

  return (
    <button className={buttonClassNames} style={labelMaxWidthMaybe} onClick={() => handleSubmit()}>
      {label}
      {hintText && <span className={css.hintText}>{hintText}</span>}
    </button>
  );
};

FilterButton.propTypes = {
  rootClassName: string,
  className: string,
  label: oneOfType([string, object]),
  onSubmit: func.isRequired,
  value: bool.isRequired,
  hintText: string,
};

export default FilterButton;
