import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import config from '../../config';
import css from './HostPromoLandingPage.css';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import HostSectionHeroPartners from './HostSectionHeroPartners';
import SectionHelp from '../LandingPage/SectionHelp';
import SectionChannels from './SectionChannels';
import { fetchFeaturedCar } from '../SearchPage/SearchPage.duck';
import { parse } from '../../util/urlHelpers';
import { checkSignUpCode } from '../../util/referralProgram';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import HostSectionStories from './HostSectionStories';
import HostEarningCalculator from './HostEarningCalculator';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import SectionInsurance from './SectionInsurance';
import SectionSharingCarsSimply from './SectionSharingCarsSimply';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useHistory, useLocation } from 'react-router';
import imageSource from '../../util/imageSource';

const { Money } = types;

const HostPromoLandingPartners = props => {
  const { scrollingDisabled, location } = props;
  const intl = useIntl();
  const isScoot = location.pathname === '/scoot-drivelah-signup';
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const siteTitle = config.siteTitle;
  const targetRef = useRef(null);
  const locationData = useLocation();
  const history = useHistory();
  const schemaTitle = intl.formatMessage(
    {
      id:
        !signUpCodeAmount || signUpCodeAmount === 0
          ? 'HostSignUpPromoLandingPage.schemaTitleMeta'
          : isScoot
          ? 'HostSignUpPromoLandingPage.scootSchemaTitleMeta'
          : 'HostSignUpPromoLandingPage.schemaTitleMeta',
    },
    { siteTitle, amount: formatMoney(intl, new Money(signUpCodeAmount, config.currency)) }
  );
  const description = intl.formatMessage({ id: 'HostSignUpPromoLandingPage.description' });

  const onUseRentalCalculator = () => {
    pushGTMBrowseEvent({
      props,
      event: EVENT_BROWSE_USEDRENTALCAL,
    });
  };

  const onListYourCar = () => {
    const { code } = parse(locationData.search);
    initiateListACarEventFromTab({
      props,
      eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    });
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
          }
        : {
            pathname: url,
          }
    );
  };

  useEffect(() => {
    const { code } = parse(location.search);
    if (code) {
      setLoading(true);
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
        setLoading(false);
      });
    }
  }, []);

  const handleGetToCalculatorSection = () => {
    window.scrollTo(0, targetRef.current.offsetTop - 72);
  };

  return (
    <Page
      title={schemaTitle}
      description={description}
      scrollingDisabled={scrollingDisabled}
      facebookImages={[
        {
          url: 'https://drivelah-public-files.s3-ap-southeast-1.amazonaws.com/Images/DL-image.png',
          width: 800,
          height: 450,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: description,
        image: 'https://drivelah-public-files.s3-ap-southeast-1.amazonaws.com/Images/DL-image.png',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div
            style={{
              backgroundImage: `url(${imageSource.hostLanding.bannerBg})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <HostSectionHeroPartners
              isScoot={isScoot}
              signUpCodeAmount={signUpCodeAmount}
              loading={loading}
              getToCalculatorSection={handleGetToCalculatorSection}
            />
            <SectionChannels />
            <SectionInsurance />
          </div>
          <div ref={targetRef}>
            <HostEarningCalculator
              onUseRentalCalculator={onUseRentalCalculator}
              onListYourCar={onListYourCar}
              listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
            />
          </div>
          <div
            style={{
              backgroundImage: `url(${imageSource.hostLanding.bannerBg2})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <SectionSharingCarsSimply />
            <div className={css.landingContainer}>
              <HostSectionStories />
            </div>
            <SectionHelp />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

HostPromoLandingPartners.loadData = () => {
  return fetchFeaturedCar();
};

export default HostPromoLandingPartners;
