import classNames from 'classnames';
import { get } from 'lodash';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { array, bool, func, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldABNInput,
  FieldCustomNumberInput,
  FieldSelect,
  FieldTextInput,
  FieldUploadFile,
  Form,
  IconSpinner,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';
import { AccountSettingDriverVerificationPage } from '../../containers';
import routeConfiguration from '../../routeConfiguration';
import {
  EVENT_HOST_VERIFY_UPLOADED_PIC,
  UPLOAD_BACK_INTL_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_FRONT_INTL_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_PROFILE_PHOTO_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { pathByRouteName } from '../../util/routes';
import setFieldTouched from '../../util/setFiledTouched';
import { propTypes } from '../../util/types';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  isValidABN,
  required,
} from '../../util/validators';
import css from './EditListingVerificationForm.css';

const ACCEPT_IMAGES = 'image/*';

export class EditListingVerificationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotUpload: true,
      isUploading: false,
      sentVerification: false,
      disableUploadInternationalLicense: false,
      nricFnFrontUrl: props.nricFnFrontUrl,
      nricFnBackUrl: props.nricFnFrontUrl,
      drivingLicenseFrontUrl: props.drivingLicenseFrontUrl,
      drivingLicenseBackUrl: props.drivingLicenseBackUrl,
      drivingLicenseStatusUrl: props.drivingLicenseStatusUrl,
      internationalDrivingLicenseFrontUrl: props.internationalDrivingLicenseFrontUrl,
      internationalDrivingLicenseBackUrl: props.internationalDrivingLicenseBackUrl,
      photo: props.photo,
      isEditLocation: false,
      drivingLicenseFrontUrl: props.drivingLicenseFrontUrl,
      hostHoldingDrivingLicenseUrl: props.hostHoldingDrivingLicenseUrl,
      passportFirstPageUrl: props.passportFirstPageUrl,
      passportLastPageUrl: props.passportLastPageUrl,
    };

    this.setUploadState = this.setUploadState.bind(this);
  }

  componentWillUnmount() {
    if (this.timeOutUploading) {
      clearTimeout(this.timeOutUploading);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      drivingLicenseFrontUrl,
      hostHoldingDrivingLicenseUrl,
      passportFirstPageUrl,
      passportLastPageUrl,
      drivingLicenseBackUrl,
    } = nextProps;
    this.setState(prev =>
      Object.assign(
        {},
        this.state,
        pickBy(
          {
            drivingLicenseFrontUrl,
            drivingLicenseBackUrl,
            hostHoldingDrivingLicenseUrl,
            passportLastPageUrl,
            passportLastPageUrl,
          },
          identity
        )
      )
    );
  }

  setUploadState(data) {
    this.setState({
      isUploading: data.isUploading,
      isNotUpload: data.isNotUpload,
    });
  }

  setStateUploadData = (url, fileType) => {
    const { setData } = this.props;
    if (fileType === 'front-driving-license') {
      setData('drivingLicenseFront', url);
      this.setState(prev => ({
        ...prev,
        drivingLicenseFrontUrl: url,
      }));
    }
    if (fileType === 'back-driving-license') {
      setData('drivingLicenseBack', url);
      this.setState(prev => ({
        ...prev,
        drivingLicenseBackUrl: url,
      }));
    }
    if (fileType === 'host-holding-driving-license') {
      setData('hostHoldingDrivingLicensePhoto', url);
      this.setState(prev => ({
        ...prev,
        hostHoldingDrivingLicenseUrl: url,
      }));
    }
    if (fileType === 'file-upload-passport-first') {
      setData('passportFirstPage', url);
      this.setState(prev => ({
        ...prev,
        passportFirstPageUrl: url,
      }));
    }
    if (fileType === 'file-upload-passport-last') {
      setData('passportLastPage', url);
      this.setState(prev => ({
        ...prev,
        passportLastPageUrl: url,
      }));
    }
    if (fileType === 'photo') {
      setData('photo', url);
      this.setState({
        photo: url,
      });
    }
    // const nricFnStartWithS =
    //   this.state.initialValues &&
    //   this.state.initialValues.nricFn &&
    //   (this.state.initialValues.nricFn.startsWith('s') ||
    //     this.state.initialValues.nricFn.startsWith('S'));
    // if (fileType === 'nric-fin-front') {
    //   this.props.setNricFnFrontUrl(url);
    //   this.setState({
    //     nricFnFrontUrl: url,
    //   });
    // } else if (fileType === 'nric-fin-back') {
    //   this.props.setNricFnBackUrl(url);
    //   this.setState({
    //     nricFnBackUrl: url,
    //   });
    // } else if (fileType === 'front-driving-license') {
    //   this.props.setDrivingLicenseFrontUrl(url);
    //   this.setState({
    //     drivingLicenseFrontUrl: url,
    //     disableUploadInternationalLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'back-driving-license') {
    //   this.props.setDrivingLicenseBackUrl(url);
    //   this.setState({
    //     drivingLicenseBackUrl: url,
    //     disableUploadInternationalLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'status-driving-license') {
    //   this.props.setDrivingLicenseStatusUrl(url);
    //   this.setState({
    //     drivingLicenseStatusUrl: url,
    //   });
    // } else if (fileType === 'international-front-driving-license') {
    //   this.props.setInternationalDrivingLicenseFrontUrl(url);
    //   this.setState({
    //     internationalDrivingLicenseFrontUrl: url,
    //     disableUploadDrivingLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'international-back-driving-license') {
    //   this.props.setInternationalDrivingLicenseBackUrl(url);
    //   this.setState({
    //     internationalDrivingLicenseBackUrl: url,
    //     disableUploadDrivingLicense: nricFnStartWithS,
    //   });
    // } else if (fileType === 'photo') {
    //   this.props.setPhoto(url);
    //   this.setState({
    //     photo: url,
    //   });
    // }
  };

  handleOpenEditLocation = status => {
    this.setState({
      isEditLocation: status,
    });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ setFieldTouched }}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={
          this.state.initialValues ? this.state.initialValues : this.props.initialValues
        }
        render={fieldRenderProps => {
          const {
            form,
            className,
            disabled,
            fetchErrors,
            handleSubmit,
            intl,
            invalid,
            currentUser,
            ready,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            values,
            pushEventGTM,
            submitButtonId,
            updateIdentityError,
            underVerified,
          } = fieldRenderProps;

          if (values.floorUnit) {
            values.floorUnit.replace(/[^\d|-]/g, '');
            if (values.floorUnit.length > 2 && values.floorUnit[2] !== '-') {
              let tempVal = values.floorUnit[0] + values.floorUnit[1] + '-';
              tempVal = tempVal + values.floorUnit.slice(2, values.floorUnit.length);
              values.floorUnit = tempVal;
            }
          }

          const isGuestVerified =
            !!currentUser &&
            !!currentUser.id &&
            currentUser.identityStatus &&
            currentUser.identityStatus.hostVerified;
          const underGuestVerified =
            !!currentUser &&
            !!currentUser.id &&
            currentUser.identityStatus &&
            currentUser.identityStatus.hostUnderVerify;
          const underHostVerification =
            !!currentUser &&
            !!currentUser.id &&
            get(currentUser, 'attributes.profile.publicData.hostIdentityVerificationStatus', '');
          const stripeConnected =
            !!currentUser && !!currentUser.id && currentUser.attributes.stripeConnected;

          const routes = routeConfiguration();
          const yourListingUrl =
            config.canonicalRootURL + pathByRouteName('ManageListingsPage', routes);

          const nricFnMessage = intl.formatMessage({ id: 'EditListingVerificationForm.nricFn' });
          const nricFnPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnPlaceholder',
          });
          const nricFnRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnRequired',
          });
          const nricFnNotValidMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnNotValid',
          });

          const accountNameMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountName',
          });
          const accountNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNamePlaceholder',
          });
          const accountNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNameRequired',
          });

          const bankNameMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankName',
          });
          const bankNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNamePlaceholder',
          });
          const bankNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNameRequired',
          });

          const bankAccountNumberMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumber',
          });
          const bankAccountNumberPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberPlaceholder',
          });
          const bankAccountNumberRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberRequired',
          });

          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const buildingMessage = intl.formatMessage({ id: 'EditListingLocationForm.building' });
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          const cityMessage = intl.formatMessage({ id: 'EditListingLocationForm.city' });
          const cityPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityPlaceholder',
          });

          const stateMessage = intl.formatMessage({ id: 'EditListingLocationForm.state' });
          const statePlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.statePlaceholder',
          });

          const suburbLabel = intl.formatMessage({ id: 'EditListingLocationForm.suburb' });
          const suburbPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.suburbPlaceholder',
          });
          const suburbRequired = intl.formatMessage({
            id: 'EditListingLocationForm.suburbRequired',
          });

          const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
          const streetPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.streetNamePlaceholder',
          });

          const blockMessage = intl.formatMessage({ id: 'EditListingLocationForm.blockNo' });
          const blockPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.blockNoPlaceholder',
          });

          const streetNumberLabel = intl.formatMessage({
            id: 'EditListingLocationForm.streetNumber',
          });
          const streetNumberPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.streetNumberPlaceholder',
          });
          const streetNumberRequired = intl.formatMessage({
            id: 'EditListingLocationForm.streetNumberRequired',
          });

          const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.unitNumber' });
          const floorPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.floorPlaceholder',
          });

          const cityRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityRequired',
          });

          const stateRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.stateRequired',
          });

          const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodePlaceholder',
          });
          const postalCodeRequired = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodeRequired',
          });

          const { publishListingError, showListingsError, updateListingError } = fetchErrors || {};

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingVerificationForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingVerificationForm.showListingFailed" />
            </p>
          ) : null;

          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress || this.state.isUploading;
          const hasProfilePhoto = currentUser && currentUser.profileImage;
          const initialPhoto = this.props.photo
            ? this.props.photo
            : hasProfilePhoto
            ? currentUser.profileImage.attributes.variants['square-small2x'].url
            : null;
          const disablesPhoto = !!this.props.photo;
          const needUploadPhoto = this.state.photo ? false : !hasProfilePhoto;
          const uploadedDrivingLicense =
            !!this.state.drivingLicenseFrontUrl || !!this.props.drivingLicenseBackUrl;
          const uploadedPassportFirstPage =
            !!this.state.passportFirstPageUrl || !!this.props.passportFirstPageUrl;
          const uploadedPassportLastPage =
            !!this.state.passportLastPageUrl || !!this.props.passportLastPageUrl;
          let needUploadFile;
          if (hasProfilePhoto) {
            needUploadFile = !uploadedDrivingLicense;
          } else {
            needUploadFile = !uploadedDrivingLicense || needUploadPhoto;
          }
          const submitDisabled =
            disabled || submitInProgress || underHostVerification === 'pending' || invalid;
          const classes = classNames(css.root, className);
          const uploadLayout = (
            <div className={css.uploadLayoutWrapper}>
              <div className={css.fieldSet}>
                <FieldUploadFile
                  id="file-upload-front-driving-license"
                  name="file-upload-front-driving-license"
                  typeFile="front-driving-license"
                  id2="file-upload-back-driving-license"
                  name2="file-upload-back-driving-license"
                  typeFile2="back-driving-license"
                  label={() => 'Driving licence'}
                  setStateUploadData={this.setStateUploadData}
                  twoColumns={true}
                  currentUser={currentUser}
                  setUploadState={this.setUploadState}
                  initialValues={[
                    this.props.drivingLicenseFrontUrl,
                    this.props.drivingLicenseBackUrl,
                  ]}
                  className={css.bottomField}
                  buttonId={UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID}
                  buttonId2={UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID}
                  pushEventGTM={this.pushEventGTM}
                />

                <FieldUploadFile
                  id="file-upload-host-holding-driving-license"
                  name="file-upload-host-holding-driving-license"
                  typeFile="host-holding-driving-license"
                  label={() => 'Selfie With Driving licence'}
                  setStateUploadData={this.setStateUploadData}
                  twoColumns={false}
                  currentUser={currentUser}
                  setUploadState={this.setUploadState}
                  initialValues={[this.props.hostHoldingDrivingLicenseUrl]}
                  className={css.bottomField}
                  buttonId={UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID}
                  buttonId2={UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID}
                  pushEventGTM={this.pushEventGTM}
                />

                <FieldUploadFile
                  id="file-upload-passport-first"
                  name="file-upload-passport-first"
                  typeFile="file-upload-passport-first"
                  id2="file-upload-passport-last"
                  name2="file-upload-passport-last"
                  typeFile2="file-upload-passport-last"
                  label={() => 'Passport (optional)'}
                  setStateUploadData={this.setStateUploadData}
                  twoColumns={true}
                  currentUser={currentUser}
                  className={css.bottomField}
                  setUploadState={this.setUploadState}
                  initialValues={[this.props.passportFirstPageUrl, this.props.passportLastPageUrl]}
                  buttonId={UPLOAD_FRONT_INTL_DRIVING_LICENSE_BUTTON_ID}
                  buttonId2={UPLOAD_BACK_INTL_DRIVING_LICENSE_BUTTON_ID}
                  pushEventGTM={this.pushEventGTM}
                  pushEventGTM2={this.pushEventGTM}
                />
              </div>
            </div>
          );

          const locationLayout = (
            <div className={css.fieldSet}>
              {this.props.initialValues &&
                this.props.initialValues.location &&
                !this.state.isEditLocation && (
                  <div
                    onClick={() => this.handleOpenEditLocation(true)}
                    className={css.editLocation}
                  >
                    <input className={css.editLocationBox} type="checkbox" />
                    <p className={css.editLocationText}>
                      {' '}
                      Do you want to enter a different address?{' '}
                    </p>
                  </div>
                )}
              <h4>Residential Address</h4>
              <div className={css.displayInlineContainer}>
                <div className={classNames(css.column, css.column12)}>
                  <LocationAutocompleteInputField
                    rootClassName={classNames(css.streetName, css.newInput)}
                    className={css.streetName}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="location"
                    disabled={
                      this.props.initialValues &&
                      this.props.initialValues.location &&
                      !this.state.isEditLocation
                    }
                    label={streetMessage}
                    placeholder={streetPlaceholderMessage}
                    useDefaultPredictions={false}
                    format={v => v}
                    valueFromForm={values.location}
                    validate={composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage),
                      autocompletePlaceSelected(addressNotRecognizedMessage)
                    )}
                  />
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldTextInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="city"
                    id="city"
                    disabled={
                      this.props.initialValues &&
                      this.props.initialValues.city &&
                      !this.state.isEditLocation
                    }
                    label={cityMessage}
                    placeholder={cityPlaceholderMessage}
                    validate={composeValidators(required(cityRequiredMessage))}
                  />
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldCustomNumberInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="streetNumber"
                    id="streetNumber"
                    disabled={
                      this.props.initialValues &&
                      this.props.initialValues.streetNumber &&
                      !this.state.isEditLocation
                    }
                    label={streetNumberLabel}
                    placeholder={streetNumberPlaceholder}
                    validate={composeValidators(required(streetNumberRequired))}
                  />
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldCustomNumberInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="unitNumber"
                    id="unitNumber"
                    disabled={
                      this.props.initialValues &&
                      this.props.initialValues.floorUnit &&
                      !this.state.isEditLocation
                    }
                    label={floorMessage}
                    placeholder={floorPlaceholderMessage}
                  />
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldTextInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="suburb"
                    id="suburb"
                    disabled={
                      this.props.initialValues &&
                      this.props.initialValues.suburb &&
                      !this.state.isEditLocation
                    }
                    label={suburbLabel}
                    placeholder={suburbPlaceholder}
                    readOnly={true}
                    validate={composeValidators(required(suburbRequired))}
                  />
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldTextInput
                    className={classNames(css.country, css.newInput)}
                    type="text"
                    name="state"
                    id="state"
                    disabled={
                      this.props.initialValues &&
                      this.props.initialValues.state &&
                      !this.state.isEditLocation
                    }
                    label={stateMessage}
                    placeholder={statePlaceholderMessage}
                    validate={composeValidators(required(stateRequiredMessage))}
                  />
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldTextInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    disabled={
                      this.props.initialValues &&
                      this.props.initialValues.postalCode &&
                      !this.state.isEditLocation
                    }
                    label={postalCodeLabel}
                    placeholder={postalCodePlaceholder}
                    validate={composeValidators(required(postalCodeRequired))}
                  />
                </div>
              </div>
            </div>
          );

          const businessInfo = (
            <div className={css.fieldSet}>
              <FieldSelect
                id="typeOfBusiness"
                name="typeOfBusiness"
                label="Type of Business"
                validate={required('This field is required')}
              >
                <option disabled value="">
                  Type of Business
                </option>
                {config.custom.typeOfBusiness.map(op => (
                  <option key={op.key} value={op.key}>
                    {op.label}
                  </option>
                ))}
              </FieldSelect>

              <Field
                name="typeOfBusiness"
                render={({ input }) => {
                  const value = input.value;
                  return value === 'company' ? (
                    <FieldABNInput
                      name="abnNumber"
                      id="abn"
                      className={css.abnNumber}
                      label="ABN number"
                      validate={composeValidators(
                        required('This field is required'),
                        isValidABN('ABN number is invalid')
                      )}
                    />
                  ) : null;
                }}
              />
            </div>
          );

          const imagesLayout = (
            <div className={css.fieldSet}>
              <p className={css.photoTip}>
                <FormattedMessage
                  id="EditListingVerificationForm.photoTip"
                  values={{ newline: <br /> }}
                />
              </p>
              <FieldUploadFile
                id="file-upload-photo"
                name="file-upload-photo"
                label={url => (
                  <div className={css.photoIdentify}>
                    {url && <img className={css.frontalPhoto} src={url} />}
                  </div>
                )}
                disablesPhoto={disablesPhoto}
                typeFile="photo"
                setStateUploadData={this.setStateUploadData}
                currentUser={currentUser}
                isPhoto={true}
                className={css.bottomField}
                setUploadState={this.setUploadState}
                acceptType={ACCEPT_IMAGES}
                initialValues={[initialPhoto]}
                buttonId={UPLOAD_PROFILE_PHOTO_BUTTON_ID}
                pushEventGTM={buttonId => pushEventGTM(buttonId, EVENT_HOST_VERIFY_UPLOADED_PIC)}
              />
            </div>
          );

          const underVerifyLayout = (
            <Form
              className={classes}
              onSubmit={e => {
                if (invalid) {
                  const formState = form.getState();
                  const { errors } = formState || {};
                  Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
                  e.preventDefault();
                  return;
                }

                this.setState({
                  isUploading: true,
                });
                handleSubmit(e);

                this.timeOutUploading = setTimeout(() => {
                  this.setState({
                    isUploading: false,
                  });
                }, 5000);
              }}
            >
              <FormSpy
                onChange={formState => {
                  this.setState({
                    initialValues: formState.values,
                  });
                }}
              />
              <AccountSettingDriverVerificationPage
                pageName="verificationPage"
                isHost={true}
                manageDisableSidebar={true}
              />
              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}>
                    {/* <span className={css.descIcon}>
                      <img src={clockRegular} alt="" />
                    </span>
                    <p>
                      <strong>Your data is secure: </strong>
                      All information is stored securely and not shared with any 3rd party.
                    </p> */}
                  </div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={updateIdentityError ? false : submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
              {updateIdentityError && (
                <div className={css.error}>
                  {typeof updateIdentityError === 'string'
                    ? updateIdentityError
                    : updateIdentityError.message}
                </div>
              )}
            </Form>
          );

          const alreadyVerifiedLayout = (
            <p className={css.thankYouTextFirstTime}>
              <FormattedMessage
                id="EditListingVerificationForm.thankYou"
                values={{ newline: <br /> }}
              />
            </p>
          );
          const pendingVerificationLayout = (
            <div className={css.thankYouTextFirstTime}>
              <FormattedMessage
                id="EditListingVerificationForm.thankYouTextFirstTimeVerified"
                values={{ newline: <br /> }}
              />
              {!stripeConnected ? (
                <div className={css.checkingStripeStatus}>
                  <IconSpinner />
                  <span className={css.checkingStripeStatusText}>
                    {intl.formatMessage({
                      id: 'EditListingVerificationForm.waitingForStripeChecking',
                    })}
                  </span>
                </div>
              ) : null}
            </div>
          );

          // const verifyLayout = isVerified || underVerify ? alreadyVerifiedLayout : underVerifyLayout;
          const finalLayout = this.state.sentVerification ? (
            pendingVerificationLayout
          ) : (
            <AccountSettingDriverVerificationPage
              pageName="verificationPage"
              isHost={true}
              manageDisableSidebar={true}
            />
          );
          // const finalLayout = pendingVerificationLayout;

          return <div className={css.chatContainerProvider}>{finalLayout}</div>;
        }}
      />
    );
  }
}

EditListingVerificationFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingVerificationFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  ready: bool.isRequired,
  stripeConnected: bool.isRequired,
  pushEventGTM: func,
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingVerificationFormComponent);
