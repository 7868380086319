import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { formatDateStringToUTC } from '../../util/dates';
import config from '../../config';
import { sendGAEvent } from '../../util/googleAnalytics';
import chunk from 'lodash/chunk';
import moment from 'moment-timezone';
import { drivelahApiPut } from '../../util/apiHelper';
import { getCurrentUser } from '../../util/browserStorageHelper';
import omit from 'lodash/omit';
import intersectionWith from 'lodash/intersectionWith';
import axios from 'axios';
import { requestSpeculatedTripPrices } from '../../util/api';
import { serialiseSdkTypes } from '../../util/urlHelpers';

const getDateKey = (start, end) => `${start}-${end}`;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const AB_TESTING_VARIANT = 'app/SearchPage/AB_TESTING_VARIANT';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SAVE_SEARCH_PARAMS_REQUEST = 'app/SearchPage/SAVE_SEARCH_PARAMS_REQUEST';
export const SAVE_SEARCH_PARAMS_SUCCESS = 'app/SearchPage/SAVE_SEARCH_PARAMS_SUCCESS';
export const SAVE_SEARCH_PARAMS_ERROR = 'app/SearchPage/SAVE_SEARCH_PARAMS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

const SEARCH_RESULTS_LIMIT = 300;

export const FETCH_FEATURED_CAR_REQUEST = 'app/SearchPage/FETCH_FEATURED_CAR_REQUEST';
export const FETCH_FEATURED_CAR_SUCCESS = 'app/SearchPage/FETCH_FEATURED_CAR_SUCCESS';
export const FETCH_FEATURED_CAR_ERROR = 'app/SearchPage/FETCH_FEATURED_CAR_ERROR';

export const FETCH_CATEGORISED_CAR_REQUEST = 'app/SearchPage/FETCH_CATEGORISED_CAR_REQUEST';
export const FETCH_CATEGORISED_CAR_SUCCESS = 'app/SearchPage/FETCH_CATEGORISED_CAR_SUCCESS';
export const FETCH_CATEGORISED_CAR_ERROR = 'app/SearchPage/FETCH_CATEGORISED_CAR_ERROR';

export const FETCH_TOP_BRAND_CAR_REQUEST = 'app/SearchPage/FETCH_TOP_BRAND_CAR_REQUEST';
export const FETCH_TOP_BRAND_CAR_SUCCESS = 'app/SearchPage/FETCH_TOP_BRAND_CAR_SUCCESS';
export const FETCH_TOP_BRAND_CAR_ERROR = 'app/SearchPage/FETCH_TOP_BRAND_CAR_ERROR';

export const FETCH_LOCALIZED_CAR_REQUEST = 'app/SearchPage/FETCH_LOCALIZED_CAR_REQUEST';
export const FETCH_LOCALIZED_CAR_SUCCESS = 'app/SearchPage/FETCH_LOCALIZED_CAR_SUCCESS';
export const FETCH_LOCALIZED_CAR_ERROR = 'app/SearchPage/FETCH_LOCALIZED_CAR_ERROR';
export const FETCH_FEATURED_B_CAR_REQUEST = 'app/SearchPage/FETCH_FEATURED_B_CAR_REQUEST';
export const FETCH_FEATURED_B_CAR_SUCCESS = 'app/SearchPage/FETCH_FEATURED_B_CAR_SUCCESS';
export const FETCH_FEATURED_B_CAR_ERROR = 'app/SearchPage/FETCH_FEATURED_B_CAR_ERROR';

export const FETCH_PHV_FEATURED_CAR_REQUEST = 'app/SearchPage/FETCH_PHV_FEATURED_CAR_REQUEST';
export const FETCH_PHV_FEATURED_CAR_SUCCESS = 'app/SearchPage/FETCH_PHV_FEATURED_CAR_SUCCESS';
export const FETCH_PHV_FEATURED_CAR_ERROR = 'app/SearchPage/FETCH_PHV_FEATURED_CAR_ERROR';

export const FETCH_FEATURED_FLEX_CAR_REQUEST = 'app/SearchPage/FETCH_FEATURED_FLEX_CAR_REQUEST';
export const FETCH_FEATURED_FLEX_CAR_SUCCESS = 'app/SearchPage/FETCH_FEATURED_FLEX_CAR_SUCCESS';
export const FETCH_FEATURED_FLEX_CAR_ERROR = 'app/SearchPage/FETCH_FEATURED_FLEX_CAR_ERROR';

export const SEARCH_SELECTION_PREDICTION = 'app/SearchPage/SEARCH_SELECTION_PREDICTION';
const SET_SEARCH_LONG_TERM = 'app/SearchPage/SET_SEARCH_LONG_TERM';

export const SPECULATED_TRIP_PRICES_REQUEST = 'app/SearchPage/SPECULATED_TRIP_PRICES_REQUEST';
export const SPECULATED_TRIP_PRICES_SUCCESS = 'app/SearchPage/SPECULATED_TRIP_PRICES_SUCCESS';
export const SPECULATED_TRIP_PRICES_ERROR = 'app/SearchPage/SPECULATED_TRIP_PRICES_ERROR';

export const CLEAR_SPECULATED_TRIP_PRICES = 'app/SearchPage/CLEAR_SPECULATED_TRIP_PRICES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  featuredCarIds: [],
  fetchFeaturedCarInProgress: false,
  fetchFeaturedCarError: null,

  saveSearchParamsInProgress: false,
  saveSearchParamsSuccess: false,
  saveSearchParamsError: false,

  fetchCategorisedCarInProgress: false,
  fetchCategorisedCarError: null,

  featuredLocalizedCarIds: [],
  fetchLocalizedCarInProgress: false,
  fetchLocalizedCarError: null,

  topBrandCarIds: [],
  fetchTopBrandCarInProgress: false,
  fetchTopBrandCarError: null,
  featuredBCarIds: [],
  fetchFeaturedBCarInProgress: false,
  fetchFeaturedBCarError: null,

  PHVFeaturedCarIds: [],
  fetchPHVFeaturedCarInProgress: false,
  fetchPHVFeaturedCarError: null,

  featuredFlexCarIds: [],
  fetchFeaturedFlexCarInProgress: false,
  fetchFeaturedFlexCarError: null,
  isSearchingLongTerm: false,

  searchSelectedPrediction: null,

  speculatedTripPricesInProgress: false,
  speculatedTripPricesError: null,
  speculatedTripPrices: null,
};

const resultIds = data => data.data.map(l => l.id);
const resultArrayId = data => data.data.map(l => l.id && l.id.uuid);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case AB_TESTING_VARIANT:
      return {
        ...state,
        variant: payload.data,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    case FETCH_FEATURED_CAR_REQUEST:
      return {
        ...state,
        fetchFeaturedCarInProgress: true,
        fetchFeaturedCarError: null,
      };
    case FETCH_FEATURED_CAR_SUCCESS:
      return {
        ...state,
        fetchFeaturedCarInProgress: false,
        featuredCarIds: resultIds(payload.data),
      };
    case FETCH_FEATURED_CAR_ERROR:
      return {
        ...state,
        fetchFeaturedCarInProgress: false,
        fetchFeaturedCarError: payload,
      };
    case FETCH_CATEGORISED_CAR_REQUEST:
      return {
        ...state,
        fetchCategorisedCarInProgress: true,
        fetchCategorisedCarError: null,
      };
    case FETCH_CATEGORISED_CAR_SUCCESS:
      return {
        ...state,
        fetchCategorisedCarInProgress: false,
        featuredCarIds: resultIds(payload.data),
      };
    case FETCH_CATEGORISED_CAR_ERROR:
      return {
        ...state,
        fetchCategorisedCarInProgress: false,
        fetchCategorisedCarError: payload,
      };
    case FETCH_TOP_BRAND_CAR_REQUEST:
      return {
        ...state,
        fetchTopBrandCarInProgress: true,
        fetchTopBrandCarError: null,
      };
    case FETCH_TOP_BRAND_CAR_SUCCESS:
      return {
        ...state,
        fetchTopBrandCarInProgress: false,
        topBrandCarIds: resultIds(payload.data),
      };
    case FETCH_TOP_BRAND_CAR_ERROR:
      return {
        ...state,
        fetchTopBrandCarInProgress: false,
        fetchTopBrandCarError: payload,
      };
    case FETCH_LOCALIZED_CAR_REQUEST:
      return {
        ...state,
        fetchLocalizedCarInProgress: true,
        fetchLocalizedCarError: null,
      };
    case FETCH_LOCALIZED_CAR_SUCCESS:
      return {
        ...state,
        fetchLocalizedCarInProgress: false,
        featuredLocalizedCarIds: resultIds(payload.data),
      };
    case FETCH_LOCALIZED_CAR_ERROR:
      return {
        ...state,
        fetchLocalizedCarInProgress: false,
        fetchLocalizedCarError: payload,
      };
    case FETCH_FEATURED_B_CAR_REQUEST:
      return {
        ...state,
        fetchFeaturedBCarInProgress: true,
        fetchFeaturedBCarError: null,
      };
    case FETCH_FEATURED_B_CAR_SUCCESS:
      return {
        ...state,
        fetchFeaturedBCarInProgress: false,
        featuredBCarIds: resultIds(payload.data),
      };
    case FETCH_FEATURED_B_CAR_ERROR:
      return {
        ...state,
        fetchFeaturedBCarInProgress: false,
        fetchFeaturedBCarError: payload,
      };

    case FETCH_PHV_FEATURED_CAR_REQUEST:
      return {
        ...state,
        fetchPHVFeaturedCarInProgress: true,
        fetchPHVFeaturedCarError: null,
      };
    case FETCH_PHV_FEATURED_CAR_SUCCESS:
      return {
        ...state,
        fetchPHVFeaturedCarInProgress: false,
        PHVFeaturedCarIds: resultIds(payload.data),
      };
    case FETCH_PHV_FEATURED_CAR_ERROR:
      return {
        ...state,
        fetchPHVFeaturedCarInProgress: false,
        fetchPHVFeaturedCarError: payload,
      };

    case FETCH_FEATURED_FLEX_CAR_REQUEST:
      return {
        ...state,
        fetchFeaturedFlexCarInProgress: true,
        fetchFeaturedFlexCarError: null,
      };
    case FETCH_FEATURED_FLEX_CAR_SUCCESS:
      return {
        ...state,
        fetchFeaturedFlexCarInProgress: false,
        featuredFlexCarIds: resultIds(payload.data),
      };
    case FETCH_FEATURED_FLEX_CAR_ERROR:
      return {
        ...state,
        fetchFeaturedFlexCarInProgress: false,
        fetchFeaturedFlexCarError: payload,
      };
    case SEARCH_SELECTION_PREDICTION:
      return {
        ...state,
        searchSelectedPrediction: payload,
      };
    case SET_SEARCH_LONG_TERM: {
      return {
        ...state,
        isSearchingLongTerm: payload,
      };
    }

    case SPECULATED_TRIP_PRICES_REQUEST:
      return {
        ...state,
        speculatedTripPricesInProgress: true,
        ...(payload ? { speculatedTripPrices: null } : {}),
      };
    case SPECULATED_TRIP_PRICES_SUCCESS:
      const { dateKey, prices } = payload;
      const speculatedTripPrices = state.speculatedTripPrices
        ? {
            ...state.speculatedTripPrices,
            prices: {
              ...state.speculatedTripPrices.prices,
              ...prices,
            },
          }
        : {
            dateKey,
            prices,
          };

      return {
        ...state,
        speculatedTripPricesInProgress: false,
        speculatedTripPrices,
      };
    case SPECULATED_TRIP_PRICES_ERROR:
      return {
        ...state,
        speculatedTripPricesInProgress: false,
      };
    case CLEAR_SPECULATED_TRIP_PRICES:
      return {
        ...state,
        speculatedTripPrices: null,
      };

    case SAVE_SEARCH_PARAMS_REQUEST: {
      return {
        ...state,
        saveSearchParamsInProgress: true,
        saveSearchParamsSuccess: false,
        saveSearchParamsError: false,
      };
    }

    case SAVE_SEARCH_PARAMS_SUCCESS: {
      return {
        ...state,
        saveSearchParamsInProgress: false,
        saveSearchParamsSuccess: true,
        saveSearchParamsError: false,
      };
    }

    case SAVE_SEARCH_PARAMS_ERROR: {
      return {
        ...state,
        saveSearchParamsInProgress: false,
        saveSearchParamsSuccess: false,
        saveSearchParamsError: payload,
      };
    }

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const setAbTestingVariant = response => ({
  type: AB_TESTING_VARIANT,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const speculatedTripPricesRequest = (shouldClear = false) => ({
  type: SPECULATED_TRIP_PRICES_REQUEST,
  payload: shouldClear,
});

export const speculatedTripPricesSuccess = payload => ({
  type: SPECULATED_TRIP_PRICES_SUCCESS,
  payload,
});

export const speculatedTripPricesError = () => ({
  type: SPECULATED_TRIP_PRICES_ERROR,
});

export const clearSpeculatedTripPrices = () => ({
  type: CLEAR_SPECULATED_TRIP_PRICES,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const saveSearchParamsRequest = () => ({ type: SAVE_SEARCH_PARAMS_REQUEST });
export const saveSearchParamsSuccess = () => ({ type: SAVE_SEARCH_PARAMS_SUCCESS });
export const saveSearchParamsError = e => ({ type: SAVE_SEARCH_PARAMS_ERROR, payload: e });

export const convertPriceParam = price => {
  if (!price) {
    return null;
  }
  const priceArray = price.split(',');
  const priceResult = [priceArray[0] * 80 || 0, priceArray[1] * 120 || 100000];
  return priceResult;
};

const handleSearchLargeThan90Days = (sdk, params) => {
  let currentStart = params.start;
  const currentEnd = params.end;
  const totalDays = moment(currentEnd).diff(moment(currentStart), 'days');
  let countDays = 0;
  let newParams = [];
  while (countDays < totalDays) {
    const start = moment(currentStart).toDate();
    const end = moment(currentStart)
      .add(Math.min(totalDays - countDays, 90), 'days')
      .toDate();
    countDays += 90;
    newParams.push({
      ...omit(params, ['start', 'end']),
      start,
      end,
    });
    currentStart = moment(end);
  }

  return Promise.all(newParams.map(p => sdk.listings.query(p))).then(responses => {
    const allData = responses.map(r => r.data.data);
    const included = responses.reduce((prev, cur) => {
      return prev.concat(cur.data.included);
    }, []);

    let listings = intersectionWith(...allData, (a, b) => {
      return a.id.uuid === b.id.uuid;
    });

    const newResponse = {
      statusText: '',
      status: 200,
      data: {
        data: listings,
        included,
        meta: {
          page: 1,
          perPage: 100,
          totalItems: listings.length,
          totalPage: Math.ceil(listings.length / 100),
        },
      },
    };

    return newResponse;
  });
};

const setSearchLongTerm = payload => ({ type: SET_SEARCH_LONG_TERM, payload });

const getSpeculatedTripPrices = searchParams => async (dispatch, getState, sdk) => {
  const { start, end } = searchParams;
  const dateKey = getDateKey(start, end);
  const { speculatedTripPrices } = getState().SearchPage;
  const shouldClearBeforeRequest = speculatedTripPrices && speculatedTripPrices.dateKey !== dateKey;
  dispatch(speculatedTripPricesRequest(shouldClearBeforeRequest));
  try {
    const response = await requestSpeculatedTripPrices({ query: serialiseSdkTypes(searchParams) });
    dispatch(speculatedTripPricesSuccess({ dateKey, prices: response.data }));
  } catch (e) {
    dispatch(speculatedTripPricesError());
  }
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    return priceParam
      ? {
          price: priceParam,
        }
      : {};
  };

  const filtersMapping = {
    small_car: 'small_car,light,premium_small',
    medium_car: 'medium_car,premium_mid,small_mid',
    large_car: 'large_car,mid_large,premium_large',
    suv: 'suv,compact_suv,mid_size_suv,full_size_suv',
    people_mover: 'people_mover',
    van: 'van,moving_van',
    ute: 'ute',
  };

  const datesSearchParams = (datesParam, hoursParams) => {
    const values = datesParam ? datesParam.split(',') : [];
    const hoursValues = hoursParams ? hoursParams.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const hasHoursValues = hoursParams && hoursValues.length === 2;
    const startDate = hasValues ? values[0] : null;
    const endDate = hasValues ? values[1] : null;

    const startTime = hasHoursValues ? hoursValues[0] : null;
    const endTime = hasHoursValues ? hoursValues[1] : null;
    if (hasValues) {
      const start = moment(startDate, 'YYYY-MM-DD').toDate();
      let end = moment(endDate, 'YYYY-MM-DD').toDate();
      if (startTime) {
        const [hours, minutes] = startTime.split(':');
        start.setHours(parseInt(hours), parseInt(minutes), 0, 0);
      }
      if (endTime) {
        const [hours, minutes] = endTime.split(':');
        end.setHours(parseInt(hours), parseInt(minutes), 0, 0);
      }

      if (start.getTime() > end.getTime()) {
        end = new Date(start.getTime() + 60 * 60 * 1000);
      }

      const diff = moment(end).diff(moment(start), 'days', true);

      const baseParams = {
        start: start instanceof Date ? start : formatDateStringToUTC(start),
        end: end instanceof Date ? end : formatDateStringToUTC(end),
        availability: 'time-full',
        per_page: 100,
        page: 1,
      };
      const now = moment();
      if (diff) {
        if (diff < 1) {
          baseParams.pub_minimumDailyDuration = `,2`;
        } else {
          baseParams.pub_minimumDailyDuration = `,${parseInt(diff) + 1}`;
        }
      }
      const bookingHours = moment(end).diff(moment(start), 'hours');
      const hoursToNow = Math.abs(now.diff(start, 'hours', true));

      if (bookingHours <= 12) {
        baseParams.pub_hourlyBooking = true;
      }
      if (parseInt(hoursToNow) <= 12) {
        baseParams.pub_paddingHours = `,${parseInt(hoursToNow) + 1}`;
      }

      return baseParams;
    }

    return {};
  };

  const {
    perPage,
    price,
    dates,
    pub_canDriveToMalaysia,
    hours,
    pub_keyFeatures,
    isLongTermSearch,
    ...rest
  } = searchParams;

  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates, hours);
  const params = {
    per_page: perPage,
    ...rest,
    ...priceMaybe,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small2x',
    ],
    'fields.listing': [
      'description',
      'geolocation',
      'price',
      'title',
      'publicData',
      'metadata',
      'state',
      'createdAt',
    ],
  };

  if (pub_canDriveToMalaysia) {
    params.pub_canDriveToMalaysia = true;
  }

  const finalCategoryFilter = array => {
    let result = [];
    for (let i = 0; i < array.length; i++) {
      const key = array[i];
      if (filtersMapping[key]) {
        result.push(filtersMapping[key]);
      }
    }
    return result.join(',');
  };

  if (params && params.pub_category) {
    const arrayOfFilters = params.pub_category.split(',');
    params.pub_category = finalCategoryFilter(arrayOfFilters);
  }

  // if (pub_keyFeatures && pub_keyFeatures.includes('pet_friendly')) {
  //   params.pub_isPetFriendly = true;
  //   const keyFeaturesMaybe = pub_keyFeatures
  //     .split(',')
  //     .filter(k => k !== 'pet_friendly')
  //     .join(',');
  //   if (!!keyFeaturesMaybe) {
  //     params.pub_keyFeatures = keyFeaturesMaybe;
  //   }
  // } else {
  //   params.pub_keyFeatures = pub_keyFeatures;
  // }

  // params.pub_peopleNumberMax = params.pub_peopleNumberMax
  //   ? `${params.pub_peopleNumberMax.split(',')[0]},`
  //   : null;

  params.mapSearch = false;

  let bounds = params.bounds;

  if (!bounds) {
    bounds = {
      _sdkType: 'LatLngBounds',
      ne: {
        _sdkType: 'LatLng',
        lat: 1.4708809,
        lng: 104.04157989999999,
      },
      sw: {
        _sdkType: 'LatLng',
        lat: 1.216611,
        lng: 103.60650989999999,
      },
    };
  }

  const center = {
    lat: (bounds.ne.lat + bounds.sw.lat) / 2,
    lng: (bounds.ne.lng + bounds.sw.lng) / 2,
  };
  if (!(params.keywords || params.sort) || (params.sort && params.sort.includes('location'))) {
    params.origin = `${center.lat},${center.lng}`;
    delete params.sort;
  }
  // if (!(params.keywords || params.sort) || !(params.sort && params.sort.includes('pub_attractiveScore') && params.sort.includes('location'))) {
  //   params.origin = `${center.lat},${center.lng}`;

  //   delete params.sort;
  //
  // }

  if (params.keywords) {
    delete params.sort;
  }

  params.meta_live = true;

  const diffHours = moment(params.end).diff(moment(params.start), 'hours');
  const shouldSpeculateTripPrices = diffHours && !(diffHours > 12 && diffHours <= 24);

  const diffDays = moment(params.end).diff(moment(params.start), 'days');
  const isLargeThan90Days = diffDays > 90;
  const isLargeThan60Days = diffDays > 60;
  if (isLargeThan60Days) {
    params.pub_longTermRental = true;
  }
  if (isLongTermSearch) {
    params.pub_longTermRental = true;
  }

  if (isLargeThan60Days || params.pub_longTermRental) {
    dispatch(setSearchLongTerm(true));
  } else {
    dispatch(setSearchLongTerm(false));
  }
  if (params.pub_longTermRental && ['price', '-price'].includes(params.sort)) {
    params.sort = params.sort.replace('price', 'pub_longTermPriceAmount');
  }

  const thunk = isLargeThan90Days
    ? handleSearchLargeThan90Days(sdk, params)
    : sdk.listings.query(params);

  return thunk
    .then(response => {
      if (shouldSpeculateTripPrices) {
        dispatch(getSpeculatedTripPrices(params));
      } else if (!shouldSpeculateTripPrices && !!getState().SearchPage.speculatedTripPrices) {
        dispatch(clearSpeculatedTripPrices());
      }

      const userInfo = getCurrentUser() || (getState && getState().user.currentUser);
      const userId = userInfo && userInfo.id && userInfo.id.uuid;
      if (userId) {
        const updateLastSearchURL = `search/update-last-search/${userId}`;
        const priceParam = convertPriceParam(price);
        drivelahApiPut(updateLastSearchURL, { price: priceParam });
      }

      const updateSearchResultCountURL = 'listings/update-listing-search-results';
      const bodyData = { listSearchResult: resultArrayId(response.data) };
      drivelahApiPut(updateSearchResultCountURL, bodyData);
      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Perform a search operation',
      });
      // const { totalItems, page, perPage: metaPerpage } = response.data.meta;
      // Search time
      const results_count = response.data.data.length || 0;

      if (params.availability) {
        response.data.meta = {
          ...response.data.meta,
          perPage: searchParams.perPage,
          totalPages: Math.ceil(response.data.data.length / searchParams.perPage),
          page: searchParams.page,
          totalItems: response.data.data.length,
        };

        response.data.data =
          chunk(response.data.data, searchParams.perPage)[searchParams.page - 1] || [];

        dispatch(saveSearchParamsToDataBase({ ...params, results_count }));
        dispatch(addMarketplaceEntities(response));
        dispatch(searchListingsSuccess(response));

        return response;
      }

      const { totalItems, page, perPage } = response.data.meta;
      if (totalItems > SEARCH_RESULTS_LIMIT) {
        response.data.meta = {
          totalItems: SEARCH_RESULTS_LIMIT,
          totalPages: Math.ceil(SEARCH_RESULTS_LIMIT / perPage),
          page,
          perPage,
        };
      }

      dispatch(saveSearchParamsToDataBase({ ...params, results_count }));
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: 200,
    pub_isDeposit: false,
    meta_live: true,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

const fetchFeaturedCarRequest = () => ({ type: FETCH_FEATURED_CAR_REQUEST });
const fetchFeaturedCarSuccess = payload => ({ type: FETCH_FEATURED_CAR_SUCCESS, payload });
const fetchFeaturedCarError = error => ({ type: FETCH_FEATURED_CAR_ERROR, payload: error });

export const fetchFeaturedCar = () => (dispatch, getState, sdk) => {
  dispatch(fetchFeaturedCarRequest());
  const params = {
    page: 1,
    meta_isFeatured: true,
    meta_live: true,
    pub_flexPlusEnabled: true,
    per_page: 25,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small2x',
    ],
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchFeaturedCarSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchFeaturedCarError(storableError(e)));
    });
};

const fetchCategorisedCarRequest = () => ({ type: FETCH_CATEGORISED_CAR_REQUEST });
const fetchCategorisedCarSuccess = payload => ({ type: FETCH_CATEGORISED_CAR_SUCCESS, payload });
const fetchCategorisedCarError = error => ({ type: FETCH_CATEGORISED_CAR_ERROR, payload: error });

export const fetchCategorisedCar = searchParams => (dispatch, getState, sdk) => {
  dispatch(fetchCategorisedCarRequest());
  const params = {
    page: 1,
    per_page: 6,
    pub_category: searchParams.toString(),
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small2x',
    ],
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCategorisedCarSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchCategorisedCarError(storableError(e)));
    });
};

const fetchLocalizedCarRequest = () => ({ type: FETCH_LOCALIZED_CAR_REQUEST });
const fetchLocalizedCarSuccess = payload => {
  return { type: FETCH_LOCALIZED_CAR_SUCCESS, payload };
};
const fetchLocalizedCarError = error => ({ type: FETCH_LOCALIZED_CAR_ERROR, payload: error });

export const fetchLocalizedCar = searchParams => (dispatch, getState, sdk) => {
  dispatch(fetchLocalizedCarRequest());
  let bounds;
  if (!searchParams.bounds) {
    bounds = {
      _sdkType: 'LatLngBounds',
      ne: {
        _sdkType: 'LatLng',
        lat: -33.833578,
        lng: 151.190872,
      },
      sw: {
        _sdkType: 'LatLng',
        lat: 1.216611,
        lng: 103.60650989999999,
      },
    };
  } else {
    bounds = searchParams.bounds;
  }

  const center = {
    lat: (bounds.ne.lat + bounds.sw.lat) / 2,
    lng: (bounds.ne.lng + bounds.sw.lng) / 2,
  };
  const origin = `${center.lat},${center.lng}`;
  const params = {
    page: 1,
    // origin,
    per_page: 8,
    // pub_category: "luxury_sedan",
    meta_live: true,
    pub_isDeposit: false,
    mapSearch: false,
    bounds: searchParams.bounds,
    // sort: 'pub_isSuperHostSorting,pub_instantBookingSorting,meta_isDrivelahGoSorting',
    include: ['author', 'images'],
    'fields.listing': [
      'description',
      'geolocation',
      'price',
      'title',
      'publicData',
      'metadata',
      'state',
      'createdAt',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small2x',
    ],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLocalizedCarSuccess(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchLocalizedCarError(storableError(e)));
    });
};

const fetchTopBrandCarRequest = () => ({ type: FETCH_TOP_BRAND_CAR_REQUEST });
const fetchTopBrandCarSuccess = payload => ({ type: FETCH_TOP_BRAND_CAR_SUCCESS, payload });
const fetchTopBrandCarError = error => ({ type: FETCH_TOP_BRAND_CAR_ERROR, payload: error });

export const fetchTopBrandCar = searchParams => (dispatch, getState, sdk) => {
  dispatch(fetchTopBrandCarRequest());
  const params = {
    page: 1,
    per_page: 6,
    pub_brandName: searchParams.toString(),
    sort: '-price',
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small2x',
    ],
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTopBrandCarSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTopBrandCarError(storableError(e)));
    });
};

const fetchFeaturedBCarRequest = () => ({ type: FETCH_FEATURED_B_CAR_REQUEST });
const fetchFeaturedBCarSuccess = payload => ({ type: FETCH_FEATURED_B_CAR_SUCCESS, payload });
const fetchFeaturedBCarError = error => ({ type: FETCH_FEATURED_B_CAR_ERROR, payload: error });

export const fetchFeaturedBCar = filterParams => (dispatch, getState, sdk) => {
  let meta_isFeatured = true;
  dispatch(fetchFeaturedBCarRequest());
  const meta_isHourlyFeatured = filterParams && filterParams.isHourlyFeatured;

  // const meta_isPHV = filterParams && filterParams.isHourlyFeatured;
  let params = {
    page: 1,
    meta_isHourlyFeatured,
    per_page: 6,
    meta_live: true,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small2x',
    ],
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
  };

  if (!meta_isHourlyFeatured) {
    params.meta_isFeatured = true;
  }

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchFeaturedBCarSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchFeaturedBCarError(storableError(e)));
    });
};

const fetchFeaturedFlexCarRequest = () => ({ type: FETCH_FEATURED_FLEX_CAR_REQUEST });
const fetchFeaturedFlexCarSuccess = payload => ({ type: FETCH_FEATURED_FLEX_CAR_SUCCESS, payload });
const fetchFeaturedFlexCarError = error => ({
  type: FETCH_FEATURED_FLEX_CAR_ERROR,
  payload: error,
});

export const fetchFeaturedFlexCar = () => async (dispatch, getState, sdk) => {
  dispatch(fetchFeaturedFlexCarRequest());
  try {
    const params = {
      page: 1,
      meta_isFeatured: true,
      meta_live: true,
      pub_flexPlusEnabled: true,
      // pub_longTermRental: true,
      per_page: 6,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small2x',
      ],
      'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
    };

    const response = await sdk.listings.query(params);
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchFeaturedFlexCarSuccess(response));
  } catch (error) {
    dispatch(fetchFeaturedFlexCarError(storableError(error)));
  }
};

const apiUrl = config.apiUrl;

const saveSearchParamsToDataBase = params => (dispatch, getState, sdk) => {
  const boundsNeLat =
    params.bounds && params.bounds.ne && params.bounds.ne.lat && params.bounds.ne.lat.toString();
  const boundsNeLng =
    params.bounds && params.bounds.ne && params.bounds.ne.lng && params.bounds.ne.lng.toString();
  const boundsSwLat =
    params.bounds && params.bounds.sw && params.bounds.sw.lat && params.bounds.sw.lat.toString();
  const boundsSwLng =
    params.bounds && params.bounds.sw && params.bounds.sw.lng && params.bounds.sw.lng.toString();

  const bounds = [boundsNeLat, boundsNeLng, boundsSwLat, boundsSwLng];

  const userInfo = getCurrentUser() || (getState && getState().user.currentUser);
  const userId = userInfo && userInfo.id && userInfo.id.uuid;

  params.bounds = bounds.join(',');
  params.user_id = userId;

  const url = new URL(apiUrl + `/api/parallel-db/save-search`);
  url.search = new URLSearchParams(params);

  dispatch(saveSearchParamsRequest());

  return axios
    .get(url)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then(response => {
      dispatch(saveSearchParamsSuccess());
    })
    .catch(error => {
      dispatch(saveSearchParamsError(error));
    });
};

const searchSelectedPrediction = payload => ({ type: SEARCH_SELECTION_PREDICTION, payload });
export const selectedPrediction = values => dispatch => {
  try {
    dispatch(searchSelectedPrediction(values));
  } catch (e) {}
};

const fetchPHVFeaturedCarRequest = () => ({ type: FETCH_PHV_FEATURED_CAR_REQUEST });
const fetchPHVFeaturedCarSuccess = payload => ({ type: FETCH_PHV_FEATURED_CAR_SUCCESS, payload });
const fetchPHVFeaturedCarError = error => ({ type: FETCH_PHV_FEATURED_CAR_ERROR, payload: error });

export const fetchPHVFeaturedCar = () => (dispatch, getState, sdk) => {
  dispatch(fetchPHVFeaturedCarRequest());
  const params = {
    page: 1,
    meta_isPHVfeatured: true,
    per_page: 3,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small2x',
    ],
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
  };
  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchPHVFeaturedCarSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchPHVFeaturedCarError(storableError(e)));
    });
};
