import config from '../config';
import { sendGAEvent } from './googleAnalytics';

const apiUrl = config.apiUrl;

export const fetchBrandlist = () => {
  return fetch(apiUrl + '/api/car-prices/available-car-brands', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const fetchModellist = brandName => {
  return fetch(apiUrl + '/api/car-prices/available-car-models?carBrand=' + brandName, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const fetchPricelist = (brandName, modelName) => {
  sendGAEvent({
    eventCategory: 'Others',
    eventAction: 'Use The Rental Calculator',
  });
  return fetch(apiUrl + '/api/car-prices?carBrand=' + brandName + '&carModel=' + modelName, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};
