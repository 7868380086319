export const sendApplyNotification = ({ email, userType }) => {
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/campaign/apply', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, userType }),
  });
};
