/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconSpinner, ImageFromFile, ResponsiveImage } from '../../components';

import css from './AddImages.css';
import RemoveImageButton from './RemoveImageButton';

const ThumbnailWrapper = props => {
  const {
    className,
    image,
    savedImageAltText,
    index,
    onRemoveImage,
    removeSelectedImage,
    isImageUploadInprogress,
  } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (image && image.id) {
      onRemoveImage(image.id);
      console.log('image.id', image.id);
      removeSelectedImage(image);
    }
  };
  if (image && image.file) {
    // Add remove button only when the image has been uploaded and can be removed

    const removeButton =
      image.imageId && index > 3 ? <RemoveImageButton onClick={handleRemoveClick} /> : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = isImageUploadInprogress ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
      >
        {removeButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    const uploadingOverlay = isImageUploadInprogress ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;
    return (
      <>
        <div className={classes}>
          <div className={css.threeToTwoWrapper}>
            <div className={css.aspectWrapper}>
              {image && (
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  image={image}
                  alt={savedImageAltText}
                  variants={['landscape-crop', 'landscape-crop2x']}
                />
              )}
              {uploadingOverlay}
            </div>
          </div>
        </div>
        {index > 3 && <RemoveImageButton onClick={handleRemoveClick} />}
      </>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    thumbnailClassName,
    image,
    savedImageAltText,
    onRemoveImage,
    index,
    placeHolderImage,
    removeSelectedImage,
    isImageUploadInprogress,
  } = props;
  const classes = classNames(css.root, className);
  return (
    <div className={classes}>
      <ThumbnailWrapper
        image={image}
        index={index}
        placeHolderImage={placeHolderImage}
        key={index}
        className={thumbnailClassName}
        savedImageAltText={savedImageAltText}
        onRemoveImage={onRemoveImage}
        removeSelectedImage={removeSelectedImage}
        isImageUploadInprogress={isImageUploadInprogress}
      />
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
