import classNames from 'classnames';
import React, { useState } from 'react';
import { Modal } from '../../components';
import { sendGAEvent } from '../../util/googleAnalytics';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import css from './NewGuestLandingPage.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const RentalCounter = ({
  onManageDisableScrolling,
  onPushGTMSeeVideoEvent,
  onVideoModalOpen,
  openSignUpModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenVideo = () => {
    onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
    sendGAEvent({
      eventCategory: 'Others',
      eventAction: 'See How To Video',
    });
    setIsOpen(true);
    onVideoModalOpen(true);
  };

  return (
    <div className={css.rentalCounterWrapper}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={classNames(css.column, css.column12, css.rentalCounterContainer)}>
            <h3 className={classNames(css.heading, css.whiteHeading)}>
              Skip the car rental counter. <br /> 30-40% cheaper cars on rent.
              {/* Skip the car rental counter. <br />Hit the Road */}
            </h3>
            <div className={css.points}>
              <div className={css.point}>
                <LazyImage src={imageSource.landingPageNew.Check} alt="" />
                <span className={css.whitePara}>100s of cars to choose from</span>
              </div>
              <div className={css.point}>
                <LazyImage src={imageSource.landingPageNew.Check} alt="" />
                <span className={css.whitePara}>Safe, sanitised and fully insured</span>
              </div>
              <div className={css.point}>
                <LazyImage src={imageSource.landingPageNew.Check} alt="" />
                <span className={css.whitePara}>Fully flexible. Anytime, anyday</span>
              </div>
              <div className={css.point}>
                <LazyImage src={imageSource.landingPageNew.Check} alt="" />
                <span className={css.whitePara}>24X7 customer support</span>
              </div>
              <div className={css.point}>
                <LazyImage src={imageSource.landingPageNew.Check} alt="" />
                <span className={css.whitePara}>30-40% more affordable</span>
              </div>
              <div className={css.point}>
                <LazyImage src={imageSource.landingPageNew.Check} alt="" />
                <span className={css.whitePara}>Comprehensive insurance</span>
              </div>
              <div className={css.actionBtn}>
                <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
                  Sign up now
                </button>
              </div>
            </div>
          </div>
          <div className={css.playVideo} onClick={handleOpenVideo}>
            <LazyImage src={imageSource.landingPageNew.PlayBtn} />
            <span>See How it works</span>
          </div>
          <Modal
            id="videoLandingpage"
            containerClassName={css.modalContainer}
            closeButtonClassName={css.modalCloseButton}
            contentClassName={css.contentModal}
            isOpen={isOpen}
            onClose={() => {
              const videoIframe = document.querySelector('#videoIframe');
              if (videoIframe) {
                const iframeSrc = videoIframe.src;
                videoIframe.src = iframeSrc;
              }
              setIsOpen(false);
              onVideoModalOpen(false);
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ModalVideo isOpen={isOpen} />
          </Modal>
        </div>
        <div className={classNames(css.bgImage, css.mobilePlay)}>
          <LazyImage src={imageSource.landingPageNew.RentCarMobile} alt="" />
          <div className={css.playVideo} onClick={handleOpenVideo}>
            <LazyImage src={imageSource.landingPageNew.PlayBtn} />
            <span>See How it works</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalVideo = ({ isOpen }) => {
  return (
    <div className={css.videoContentWrapper}>
      {isOpen && (
        <iframe
          id="videoIframe"
          className={css.iframeVideo}
          allowFullScreen={true}
          // src="https://www.youtube.com/embed/OaOzjZiVaws"
          src="https://www.youtube.com/embed/ayF8D-J7hUo"
        ></iframe>
      )}
    </div>
  );
};

export default RentalCounter;
