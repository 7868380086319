exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* .stepper {\n  border: solid 2px black;\n  width: 50%;\n  max-width: 600px;\n  position: relative;\n} */\n\n.Stepper_stepperHeader__1gSiE {\n  display: flex;\n  justify-content: space-between;\n  position: relative;\n  width: 100%;\n  min-width: 250px;\n}\n\n.Stepper_stepperProgress__1xaR8 {\n  position: absolute;\n  top: 50%;\n  left: 0;\n  height: 4px;\n  width: calc(100% - 30px); /* Adjust for padding */\n  background-color: #e0e0e0;\n  z-index: 0;\n  transform: translateY(-50%);\n}\n\n.Stepper_stepperProgressActive__2rUvw {\n  background-color: #026786;\n  height: 4px;\n  position: absolute;\n  top: 50%;\n  left: 0;\n  z-index: 1;\n  transform: translateY(-50%);\n}\n\n.Stepper_step__3UIf1 {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  background-color: #e0e0e0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  z-index: 2;\n  font-size: 12pt;\n  font-weight: bold;\n}\n\n.Stepper_step__3UIf1.Stepper_active__1MxBH {\n  background-color: #026786;\n  color: white;\n}\n\n.Stepper_step__3UIf1.Stepper_completed__19TN0 {\n  background-color: #026786;\n  color: white;\n}\n\n.Stepper_step__3UIf1::before {\n  content: '';\n  position: absolute;\n  top: 50%;\n  left: 100%;\n  width: calc(100% - 30px);\n  height: 2px;\n  background-color: transparent;\n  z-index: -1;\n  transform: translateY(-50%);\n}\n\n.Stepper_step__3UIf1:last-child::before {\n  display: none;\n}\n\n.Stepper_stepperContent__23aLQ {\n  padding: 20px;\n  border: 1px solid #ccc;\n  margin-bottom: 20px;\n}\n\n.Stepper_stepperActions__307GH {\n  display: flex;\n  justify-content: space-between;\n}\n\nbutton {\n  padding: 10px 20px;\n  border: none;\n  background-color: #007bff;\n  color: white;\n  cursor: pointer;\n}\n\nbutton:disabled {\n  background-color: #ccc;\n  cursor: not-allowed;\n}\n", ""]);

// Exports
exports.locals = {
	"stepperHeader": "Stepper_stepperHeader__1gSiE",
	"stepperProgress": "Stepper_stepperProgress__1xaR8",
	"stepperProgressActive": "Stepper_stepperProgressActive__2rUvw",
	"step": "Stepper_step__3UIf1",
	"active": "Stepper_active__1MxBH",
	"completed": "Stepper_completed__19TN0",
	"stepperContent": "Stepper_stepperContent__23aLQ",
	"stepperActions": "Stepper_stepperActions__307GH"
};