import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './LongTermRentalListingPage.css';
import { Form as FinalForm } from 'react-final-form';
import { default as RangeSlider } from 'react-rangeslider';
import { Form } from '../../components';
import LineItemBasePriceMaybe from './LongTermPriceBreakdown/LineItemBasePriceMaybe';
import LineItemInsuranceMaybe from './LongTermPriceBreakdown/LineItemInsuranceMaybe';
import LineAdditionalDriverMaybe from './LongTermPriceBreakdown/LineAdditionalDriverMaybe';
import LineItemTotalPrice from './LongTermPriceBreakdown/LineItemTotalPrice';
import { types as sdkTypes } from '../../util/sdkLoader';
import LineItemMileageMaybe from './LongTermPriceBreakdown/LineItemMileageMaybe';

const { Money } = sdkTypes;

const ConfigureRentModal = props => {
  const {
    rootClassName,
    className,
    getProtectionValue,
    getMileageValue,
    getAdditionaDriverValue,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [value1, setValue1] = useState(110);
  const [mileageValues, setMileageValues] = useState(0);
  const [protectionValue, setProtectionValue] = useState(0);
  const basicProtectionRef = useRef(null);

  const mileageSliderLabels = {
    0: '500kms',
    40: '700kms',
    75: '900kms',
    99: 'Unlimited',
  };

  useEffect(() => {
    if (basicProtectionRef.current) {
      basicProtectionRef.current.click();
    }
  }, []);

  const handle1Change = value1 => {
    setValue1(value1);
  };

  const handleValue2Change = mileage => {
    let mileageValue;
    let getMileage;
    switch (mileage) {
      case 35:
        mileageValue = 40;
        getMileage = 700;
        break;
      case 70:
        mileageValue = 75;
        getMileage = 900;
        break;
      case 99:
        mileageValue = 99;
        getMileage = 'Unlimited';
        break;
      default:
        mileageValue = 0;
        getMileage = 500;
        break;
    }
    setMileageValues(mileageValue);
    getMileageValue(getMileage);
  };

  const _handleProtectionChange = e => {
    setProtectionValue(e.target.value);
    const value =
      e.target.value === '0'
        ? 'Basic protection'
        : e.target.value === '120'
        ? 'Premium Protection'
        : e.target.value === '300'
        ? 'Maximum Protection'
        : '';

    getProtectionValue(value);
  };

  const _handleToggleChange = e => {
    getAdditionaDriverValue(e.target.checked ? 'Yes' : 'No');
  };

  // prettier-ignore
  return (
    <div className={classes}>
          <div className={css.rentalCard}>
      <h3 className={css.head}>Configure your rental</h3>
      <div className={css.border}></div>
      <FinalForm
        {...props}
        render={formRenderProps => {
          const {
            handleSubmit,
            listing,
            intl,
            values,
            setTotalPrice
          } = formRenderProps;
          const additionalDriverCost = values.additionalDriver1 ? 50 : 0;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
                <div className={css.selection}>
                    <h3>Choose your protection</h3>
                    <div className={classNames(css.customButtons, css.fields)}>

                    <div className={css.customRadio}>
                    <div className={css.flexRow}>
                      <div className={css.radioWrapper}>
                        <input
                          type="radio"
                          value={0}
                          onChange={_handleProtectionChange}
                          name="protection"
                          id="basic"
                          ref={basicProtectionRef}
                        />
                        <label htmlFor="basic">Basic Protection</label>
                      </div>
                      <span className={css.displayInDesktop}>Included</span>
                    </div>
                    <span className={css.protectionDescription}>$3000 excess + roadside assistance</span>
                    <span className={css.displayInMobile}>Included in price</span>
                  </div>
                  <div className={css.customRadio}>
                    <div className={css.flexRow}>
                      <div className={css.radioWrapper}>
                        <input
                          type="radio"
                          value={30}
                          onChange={_handleProtectionChange}
                          name="protection"
                          id="premium"
                        />
                        <label htmlFor="premium">Premium Protection</label>
                      </div>
                      <span className={css.displayInDesktop}>$30/week</span>
                    </div>
                    <span className={css.protectionDescription}>$1500 exces</span>
                    <span className={css.displayInMobile}>$30/week</span>
                  </div>
                  <div className={css.customRadio}>
                    <div className={css.flexRow}>
                      <div className={css.radioWrapper}>
                        <input
                          type="radio"
                          value={50}
                          onChange={_handleProtectionChange}
                          name="protection"
                          id="maximum"
                        />
                        <label htmlFor="maximum">Maximum Protection</label>
                      </div>
                      <span className={css.displayInDesktop}>$50/week</span>
                    </div>
                    <span className={css.protectionDescription}>$500 excess</span>
                    <span className={css.displayInMobile}>$50/week</span>
                  </div>
                        </div>
                        </div>
                        <div className={css.border}></div>
                        <div className={css.selection}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>Add Mileage </h3>
                           {mileageValues ? <span style={{floatRight: true}} className={css.displayInDesktop}>${mileageValues}/week</span> : ''}
                         </div>
                        <h3>Add Mileage</h3>
                        <div className={css.sliderfill}>
                          {' '}
                          <RangeSlider
                            min={0}
                            max={99}
                            step={35}
                            labels={mileageSliderLabels}
                            tooltip={false}
                            value={mileageValues}
                            onChange={handleValue2Change}
                            className={css.sliderCss}
                          />
                          <p style={{ fontSize: 12, lineHeight: 'normal' }}>
                            If you exceed your weekly mileage limit, then you will be charged $0.3/km for excess kms driven.
                          </p>
                        </div>
                        </div>
                        <div className={css.border}></div>
                        {/*  <div
                          className={css.selection}
                          style={{display: 'flex', justifyContent: 'space-between'}}
                        >
                          <h3 style={{marginBottom: 0}}>
                            Additional driver - <span>$50</span>
                          </h3>
                          <div className={css.sliderfill}>
                            <FieldToggleSwitch
                              name="additionalDriver1"
                              className={css.availabilityToggle}
                              onChange={_handleToggleChange}
                              id="additionalDriver1"
                            />
                          </div>
                        </div> */}
                        <div className={css.border}></div>

                        <div className={css.priceBreakdown}>
                        <h3>Price breakdown</h3>
                        <div className={css.prices}>
                          <LineItemBasePriceMaybe
                            listing={listing}
                            additionalCost={mileageValues}
                            intl={intl}
                          />
                          <LineItemMileageMaybe
                            listing={listing}
                            additionalCost={mileageValues}
                            intl={intl}
                          />
                          <LineItemInsuranceMaybe
                            listing={listing}
                            insuranceCost={protectionValue}
                            intl={intl}
                          />
                          <LineAdditionalDriverMaybe
                            listing={listing}
                            additionalDriverCost={additionalDriverCost}
                            intl={intl}
                          />
                          {/* <LineProcessingFeesMaybe listing={listing} intl={intl} /> */}
                        </div>
                        <div className={css.border}></div>
                        <LineItemTotalPrice
                          additionalCost={mileageValues}
                          insuranceCost={parseInt(protectionValue) || 0}
                          additionalDriverCost={additionalDriverCost}
                          listing={listing}
                          intl={intl}
                          setTotalPrice={setTotalPrice}
                        />
                      </div>
                    </Form>
                  )}}

      />
      </div>
    </div>
  );
};

ConfigureRentModal.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ConfigureRentModal.propTypes = {
  rootClassName: string,
  className: string,
};

export default memo(ConfigureRentModal);
