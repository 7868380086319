exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ModificationCharges_modificationChargesRoot__1DLnl {\n  margin-top: 20px;\n  background-color: #ffffff;\n  border: 1px solid #e6e6e6;\n  padding: 15px;\n  border-radius: 4px\n}\n\n@media (max-width: 767px) {\n\n  .ModificationCharges_modificationChargesRoot__1DLnl {\n    border-top: 0;\n    border-bottom: 1px solid #e6e6e6;\n    margin-top: 0;\n    border-radius: 0\n}\n  }\n\n.ModificationCharges_modificationChargesRoot__1DLnl {\n\n  .ModificationCharges_head__1UmZ2 {\n    display: block;\n    color: #7c7c7c;\n    margin-bottom: 10px;\n    font-weight: 500;\n    font-size: 16px;\n  }\n\n  .ModificationCharges_lineItemsContainer__1NCYU {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  .ModificationCharges_lineItemName__QxHgu {\n    display: block;\n    color: #1a1a1a;\n    font-weight: 500;\n    font-size: 18px;\n  }\n\n  .ModificationCharges_lineItemValue__3F-TY {\n    display: block;\n    color: #1a1a1a;\n    font-weight: 900;\n    font-size: 18px;\n  }\n\n  .ModificationCharges_plus__3xeXq {\n    color: #00a3ad;\n    font-size: 18px;\n    font-weight: 500;\n    cursor: pointer;\n    transition: 0.3s;\n    margin-top: 8px\n  }\n\n    .ModificationCharges_plus__3xeXq:hover {\n      text-decoration: underline;\n    }\n}\n", ""]);

// Exports
exports.locals = {
	"modificationChargesRoot": "ModificationCharges_modificationChargesRoot__1DLnl",
	"head": "ModificationCharges_head__1UmZ2",
	"lineItemsContainer": "ModificationCharges_lineItemsContainer__1NCYU",
	"lineItemName": "ModificationCharges_lineItemName__QxHgu",
	"lineItemValue": "ModificationCharges_lineItemValue__3F-TY",
	"plus": "ModificationCharges_plus__3xeXq"
};