import React, { useMemo, useRef, useState } from 'react';
import { array, arrayOf, bool, func, oneOfType, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { composeValidators, required } from '../../util/validators';
import { calculateBookingDays, checkValidEndDate, END_DATE, START_DATE } from '../../util/dates';
import { LISTING_STATE_PUBLISHED, propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldDateInput,
  FieldSelect,
  FieldTextInput,
  FieldTimeSelect,
  Form,
  InsurancePanel,
  Modal,
  NamedLink,
  PrimaryButton,
  RentalAgreement,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingDatesForm.css';
import {
  currentUserCanRequestToBooking,
  currentUserIdentityStatus,
  currentUserIsYoungDriver,
  listingIsCommercial,
  listingIsInstantBooking,
} from '../../util/data';
import memoize from 'lodash/memoize';
import isEqual from 'lodash/isEqual';
import { isEmpty } from 'lodash';
import AlertBox from '../../components/AlertBox/AlertBox';

const findTimeSet = memoize(timeKey => {
  if (!timeKey) return null;
  return config.custom.timeSetInput.find(time => time.key === timeKey);
});

const canSet8AM = (date, timeSlotsObj) => {
  if (!date) return false;
  const momentDate = moment(date);
  const key = momentDate.format('DD/MM/YYYY');
  const timeSlotOfDate = (timeSlotsObj[key] || [])[0];
  if (timeSlotOfDate) {
    return momentDate
      .hour(8)
      .minutes(0)
      .isBetween(
        moment(timeSlotOfDate.attributes.start),
        moment(timeSlotOfDate.attributes.end),
        'hour',
        '[]'
      );
  }
  return false;
};

export const BookingDatesFormComponent = props => {
  const {
    checkValidateBooking,
    checkedCode,
    onSubmit,
    rootClassName,
    className,
    price: unitPrice,
    initialDate,
    listingParams,
    currentUser,
    listing,
    onResetCode,
    timeSlotsObj,
    timeSlots,
    onReadInsurance,
    requestButtonId,
  } = props;

  const oldFormValues = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [estimating, setEstimation] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const prevBookingDatesTimes = useRef({});

  const prevFormValues = useRef({});

  const [state, setState] = useState({
    focusedInput: null,
    isModalOpen: false,
    timeRangeError: {},
    isOpenRentalAgreement: false,
  });

  const onFocusedInputChange = focusedInput => {
    setState(prev => ({
      ...prev,
      focusedInput,
    }));
  };

  const handleFormSubmit = e => {
    if (!checkValidateBooking()) {
      return;
    }

    let { startDate, endDate } = e.bookingDates || {};
    startDate = startDate ? startDate.date : null;
    endDate = endDate ? endDate.date : null;

    const { timePickup, timeDropoff } = e || {};
    const startTime = findTimeSet(timePickup);
    const endTime = findTimeSet(timeDropoff);

    if (startDate && startTime) {
      startDate.setHours(startTime.hour);
      startDate.setMinutes(startTime.minutes);
      startDate.setSeconds(0);
    }

    if (endDate && endTime) {
      endDate.setHours(endTime.hour);
      endDate.setMinutes(endTime.minutes);
      endDate.setSeconds(0);
    }

    e.bookingDates.startDate = new Date(startDate);
    e.bookingDates.endDate = new Date(endDate);
    delete e.timePickup;
    delete e.timeDropoff;

    e.voucherCode = checkedCode;

    if (!startDate) {
      e.preventDefault();
      onFocusedInputChange(START_DATE);
    } else if (!endDate) {
      e.preventDefault();
      onFocusedInputChange(END_DATE);
    } else {
      onSubmit(e);
    }
  };

  const classes = classNames(rootClassName || css.root, className);

  const listingIsPublished = useMemo(() => {
    if (!listing.id) return false;
    return listing.attributes.state === LISTING_STATE_PUBLISHED;
  }, [listing]);

  const commercialListing = useMemo(() => {
    return listingIsCommercial(listing);
  }, [listing]);

  const isInstantBooking = useMemo(() => {
    return listingIsInstantBooking(listing);
  }, [listing]);

  const youngDriver = useMemo(() => {
    return currentUserIsYoungDriver(currentUser);
  }, [currentUser]);

  const {
    guestVerified: verifiedGuest,
    guestUnderVerify: underVerifiedGuest,
  } = currentUserIdentityStatus(currentUser);

  const insuranceType = useMemo(() => {
    const { publicData = {} } = listing.attributes;
    return publicData.insurance;
  }, [listing]);

  if (!unitPrice) {
    return (
      <div className={classes}>
        <p className={css.error}>
          <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
        </p>
      </div>
    );
  }
  if (unitPrice.currency !== config.currency) {
    return (
      <div className={classes}>
        <p className={css.error}>
          <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
        </p>
      </div>
    );
  }

  const handleChangeDiscountChoice = (nextValues = {}, form) => {
    if (prevFormValues.current.discountChoice !== nextValues.discountChoice) {
      onResetCode();
      form.change('voucherCode', '');
      form.change('signupCredits', 0);
    }
  };

  const handleFormValuesChange = ({ values, form }) => {
    handleChangeDiscountChoice(values, form);
    if (!isEqual(prevFormValues.current, values)) {
      prevFormValues.current = values;
    }
  };

  const handleDatesTimesChange = (values, form) => {
    form.batch(() => {
      if (isEqual(values, prevBookingDatesTimes)) return;

      const { bookingDates = {}, timePickup, timeDropoff } = values;
      const startDate = bookingDates.startDate ? bookingDates.startDate.date : null;
      const endDate = bookingDates.endDate ? bookingDates.endDate.date : null;
      const startTime = timePickup ? findTimeSet(timePickup) : null;
      const endTime = timeDropoff ? findTimeSet(timeDropoff) : null;
      const canSet8AmForStartDate = canSet8AM(startDate, timeSlotsObj);

      if (!isEqual(startDateRef.current, startDate)) {
        const slots = timeSlotsObj[moment(startDate).format('DD/MM/YYYY')];
        let nextSlotMaybe = null;
        if (Array.isArray(slots) && slots.length === 1) {
          const { index, session } = slots[0];
          const endTimeSlots = timeSlots.filter(sl => sl.session === session && sl.index > index);
          setState(prev => ({
            ...prev,
            endTimeSlots,
          }));
          nextSlotMaybe = endTimeSlots[0];
        }
        if (canSet8AmForStartDate) {
          startDate.setHours(8);
          startDate.setMinutes(0);
          startDate.setSeconds(0);
          form.change('timePickup', '08:00 am');
        } else {
          form.change('timePickup', null);
        }
        form.change('bookingDates.startDate', {
          date: startDate,
        });
        if (!endDate) {
          if (nextSlotMaybe) {
            const newEndDate = moment(nextSlotMaybe.attributes.start).toDate();
            endDateRef.current = new Date(newEndDate);
            if (canSet8AM(newEndDate, timeSlotsObj)) {
              newEndDate.setHours(8);
              newEndDate.setMinutes(0);
              newEndDate.setSeconds(0);
              form.change('timeDropoff', '08:00 am');
            }
            form.change('bookingDates.endDate', {
              date: newEndDate,
            });
          } else {
            form.change('bookingDates.endDate', null);
            form.change('timeDropoff', null);
          }
        }
        startDateRef.current = startDate;
      }

      if (!isEqual(endDateRef.current, endDate)) {
        if (canSet8AM(endDate, timeSlotsObj)) {
          endDate.setHours(8);
          endDate.setMinutes(0);
          endDate.setSeconds(0);
          form.change('timeDropoff', '08:00 am');
        } else {
          form.change('timeDropoff', null);
        }
        endDateRef.current = endDate;
      }

      if (startDate && startTime) {
        startDate.setHours(startTime.hour);
        startDate.setMinutes(startTime.minutes);
        startDate.setSeconds(0);
      }
      if (endDate && endTime) {
        endDate.setHours(endTime.hour);
        endDate.setMinutes(endTime.minutes);
        endDate.setSeconds(0);
      }

      const timeRangeError = state.timeRangeError;
      if (startDate && (startTime || canSet8AmForStartDate)) {
        const momentStartDate = moment(startDate);
        const hoursToNow = momentStartDate.diff(moment(), 'hours');
        if (isInstantBooking) {
          timeRangeError[
            'Instant booking cars must be booked at least 4 hours prior to the trip start time'
          ] = hoursToNow < 4;
          const hoursFrom12am = momentStartDate.diff(
            momentStartDate.clone().startOf('day'),
            'minutes'
          );
          timeRangeError['Instant booking cars can not be picked up from 12am to 7am'] =
            hoursFrom12am >= 0 && hoursFrom12am <= 7 * 60;
        }

        timeRangeError['Pickup time must be after the current time'] = hoursToNow < 0;
      }
      if (startDate && endDate) {
        if (!checkValidEndDate(startDate, endDate)) {
          timeRangeError['Invalid duration time (Equal/longer than 12 hours)'] = true;
        }
      }

      if (startDate && endDate && startTime && endTime) {
        timeRangeError['Invalid duration time (Equal/longer than 12 hours)'] = !checkValidEndDate(
          startDate,
          endDate
        );
      }

      Object.keys(timeRangeError).forEach(key => {
        if (timeRangeError[key] === false) {
          delete timeRangeError[key];
        }
      });

      setState(prev => ({
        ...prev,
        timeRangeError: timeRangeError,
      }));
    });
  };

  return (
    <FinalForm
      {...props}
      unitPrice={unitPrice}
      onSubmit={handleFormSubmit}
      render={fieldRenderProps => {
        const {
          form,
          handleSubmit,
          intl,
          isOwnListing,
          submitButtonWrapperClassName,
          unitPrice,
          unitType,
          values,
          timeSlots,
          currentUser,
          fetchTimeSlotsError,
          onCheckingVoucher,
          checkedCode,
          checkCodeInProgress,
          checkCodeErorr,
          onResetCode,
          listing,
          timeSlotsObj,
          onManageDisableScrolling,
          isNewCar,
        } = fieldRenderProps;

        const { discountChoice } = values;

        if (
          oldFormValues.current &&
          oldFormValues.current.discountChoice !== values.discountChoice
        ) {
          onResetCode();
          form.change('voucherCode', '');
          form.change('signupCredits', 0);
        }

        oldFormValues.current = values;

        if (
          initialDate &&
          initialDate.startDate &&
          initialDate.endDate &&
          isEmpty(values.bookingDates)
        ) {
          form.batch(() => {
            form.change('bookingDates', {
              startDate: {
                date: new Date(initialDate.startDate),
              },
              endDate: {
                date: new Date(initialDate.endDate),
              },
            });
          });
        }

        let { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
        const { signupCredits } = values;
        startDate = startDate ? startDate.date : null;
        endDate = endDate ? endDate.date : null;

        const { timePickup, timeDropoff } = values;
        const startTime = timePickup
          ? config.custom.timeSetInput.find(time => time.key === timePickup)
          : null;
        const endTime = timeDropoff
          ? config.custom.timeSetInput.find(time => time.key === timeDropoff)
          : null;

        if (startDate && startTime) {
          startDate.setHours(startTime.hour);
          startDate.setMinutes(startTime.minutes);
          startDate.setSeconds(0);
        }

        if (endDate && endTime) {
          endDate.setHours(endTime.hour);
          endDate.setMinutes(endTime.minutes);
          endDate.setSeconds(0);
        }

        const requiredMessage = intl.formatMessage({ id: 'BookingDatesForm.requiredDate' });
        const timeSlotsError = fetchTimeSlotsError ? (
          <p className={css.timeSlotsError}>
            <FormattedMessage id="BookingDatesForm.timeSlotsError" />
          </p>
        ) : null;

        const validInput = !!startDate && !!endDate && !!timePickup && !!timeDropoff;
        const validTimeRange = calculateBookingDays(startDate, endDate) >= 1;

        const isInvalidTime = !validInput || !checkValidEndDate(startDate, endDate);
        const canRequestBooking = currentUserCanRequestToBooking(currentUser);

        const userCanRequestBooking =
          canRequestBooking &&
          validInput &&
          validTimeRange &&
          verifiedGuest &&
          !isInvalidTime &&
          !Object.keys(state.timeRangeError || {}).length;
        const requestToBookButtonMessage = isInstantBooking
          ? 'BookingDatesForm.requestToBookInstant'
          : 'BookingDatesForm.requestToBook';

        // This is the place to collect breakdown estimation data. See the
        // EstimatedBreakdownMaybe component to change the calculations
        // for customized payment processes.

        const quantity = calculateBookingDays(startDate, endDate);

        const bookingData =
          startDate &&
          endDate &&
          timePickup &&
          timeDropoff &&
          !isInvalidTime &&
          !Object.keys(state.timeRangeError || {}).length
            ? {
                unitType,
                unitPrice,
                startDate: startDate.toString(),
                endDate: endDate.toString(),
                quantity,
                discountChoice,
                credits: discountChoice === 'credits' ? signupCredits : 0,
                discount: discountChoice === 'promos' && checkedCode ? checkedCode.discount : 0,
                listing,
              }
            : null;

        const bookingInfo = bookingData ? (
          <div className={css.priceBreakdownContainer}>
            <h3 className={css.priceBreakdownTitle}>
              <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
            </h3>
            <EstimatedBreakdownMaybe
              discountChoice={discountChoice}
              bookingData={bookingData}
              currentUser={currentUser}
              checkedCode={checkedCode}
              setEstimation={setEstimation}
              listingParams={listingParams}
            />
          </div>
        ) : null;

        const submitButtonClasses = classNames(
          submitButtonWrapperClassName || css.submitButtonWrapper
        );

        if (!signupCredits) {
          if (
            discountChoice === 'credits' &&
            currentUser &&
            currentUser.attributes &&
            currentUser.attributes.credits
          ) {
            form.change('signupCredits', currentUser.attributes.credits);
          }
        }

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {timeSlotsError}
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values: formValues }) => {
                handleFormValuesChange({
                  values: formValues,
                  form,
                });
              }}
            />
            <FormSpy
              subscription={{ values: true }}
              onChange={formState => {
                const { bookingDates = {}, timePickup, timeDropoff } = formState.values;
                handleDatesTimesChange(
                  {
                    bookingDates,
                    timePickup,
                    timeDropoff,
                  },
                  form
                );
              }}
            />
            {listingIsPublished && (
              <div className={css.dateBookingWrapper}>
                <div className={css.dateTimeFields}>
                  <div className={classNames(css.fieldDateTimeLabel, css.labelField)}>
                    <FormattedMessage id="BookingDatesForm.bookingStartTitle" />
                  </div>
                  <div className={classNames(css.fieldDateTimeLabel, css.labelField)}>
                    <FormattedMessage id="BookingDatesForm.bookingStartTimeTitle" />
                  </div>
                  <FieldTextInput
                    className={css.signupCredits}
                    id="signupCredits"
                    name="signupCredits"
                    label="signupCredits"
                    type="number"
                  />
                  <FieldDateInput
                    className={classNames(css.fieldDateTimeInput, css.fieldStartDate)}
                    name="bookingDates.startDate"
                    useMobileMargins={false}
                    id="startBookingDate"
                    placeholderText={moment().format('DD/MM/YYYY')}
                    format={v => v}
                    disabled={estimating}
                    timeSlots={timeSlots}
                    validate={composeValidators(
                      required(requiredMessage)
                      // bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                    )}
                  />
                  <FieldTimeSelect
                    timeSlotsObj={timeSlotsObj}
                    className={css.fieldDateTimeInput}
                    nameInput="timePickup"
                    id="timePickup"
                    disabled={estimating}
                    selectedDate={
                      values.bookingDates &&
                      values.bookingDates.startDate &&
                      values.bookingDates.startDate.date
                    }
                  />
                </div>
                <div className={css.dateTimeFields}>
                  <div className={classNames(css.fieldDateTimeLabel, css.labelField)}>
                    <FormattedMessage id="BookingDatesForm.bookingEndTitle" />
                  </div>
                  <div className={classNames(css.fieldDateTimeLabel, css.labelField)}>
                    <FormattedMessage id="BookingDatesForm.bookingEndTimeTitle" />
                  </div>
                  <FieldDateInput
                    className={classNames(css.fieldDateTimeInput, css.fieldEndDate)}
                    name="bookingDates.endDate"
                    disabled={estimating}
                    useMobileMargins={false}
                    id="endBookingDate"
                    placeholderText={moment().format('DD/MM/YYYY')}
                    format={v => v}
                    timeSlots={state.endTimeSlots || []}
                    validate={composeValidators(
                      required(requiredMessage)
                      // bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                    )}
                  />
                  <FieldTimeSelect
                    timeSlotsObj={timeSlotsObj}
                    className={css.fieldDateTimeInput}
                    nameInput="timeDropoff"
                    id="timeDropoff"
                    disabled={estimating}
                    selectedDate={
                      values.bookingDates &&
                      values.bookingDates &&
                      values.bookingDates.endDate &&
                      values.bookingDates.endDate.date
                    }
                  />

                  <FieldSelect
                    id="discountChoice"
                    name="discountChoice"
                    label={`Promotions & credits`}
                    className={css.checkDiscount}
                    disabled={estimating || isNewCar}
                  >
                    <option value="none">None...</option>
                    <option value="credits">I want to use my available credits</option>
                    <option value="promos">I have a promotional voucher</option>
                  </FieldSelect>

                  {discountChoice === 'promos' && (
                    <React.Fragment>
                      <div className={css.voucherContainer}>
                        <FieldTextInput
                          id="voucherCode"
                          name="voucherCode"
                          className={css.voucherInput}
                          type="text"
                          label="Input your voucher"
                          placeholder="Type your voucher here..."
                          disabled={estimating}
                        />

                        {showToast ? (
                          <AlertBox
                            title="Oops!"
                            message="Please login/signup to make a booking"
                            type="info"
                          />
                        ) : null}

                        <Button
                          className={css.voucherButton}
                          type="button"
                          inProgress={checkCodeInProgress}
                          disabled={!values.voucherCode}
                          ready={!!checkedCode}
                          onClick={() => {
                            const { voucherCode, ...rest } = values;
                            if (currentUser) {
                              onCheckingVoucher({
                                code: voucherCode,
                                data: {
                                  ...rest,
                                },
                              }).then(() => {
                                if (
                                  oldFormValues.current &&
                                  oldFormValues.current.bookingDates.startDate &&
                                  oldFormValues.current.bookingDates.endDate
                                ) {
                                  form.change('bookingDates', {
                                    startDate: {
                                      date: oldFormValues.current.bookingDates.startDate,
                                    },
                                    endDate: {
                                      date: oldFormValues.current.bookingDates.endDate,
                                    },
                                  });
                                }
                              });
                            } else {
                              setShowToast(true);
                            }
                          }}
                        >
                          Check
                        </Button>
                      </div>
                      {checkCodeErorr && (
                        <p className={classNames(css.smallPrintForMember, css.error)}>
                          Invalid voucher code
                        </p>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <div className={css.error}>{Object.keys(state.timeRangeError).join('. \n')}</div>
                {bookingInfo}
                <div className={submitButtonClasses}>
                  <p className={css.smallPrintForMember}>
                    <FormattedMessage id="BookingPanel.memberUseDrivelah" />
                  </p>

                  {currentUser && currentUser.id ? (
                    <PrimaryButton
                      type="submit"
                      disabled={estimating || !userCanRequestBooking}
                      inProgress={estimating}
                      id={requestButtonId}
                    >
                      <FormattedMessage id={requestToBookButtonMessage} />
                    </PrimaryButton>
                  ) : (
                    <NamedLink
                      name="LoginPage"
                      to={{
                        state: {
                          startDate,
                          endDate,
                          startTime,
                          endTime,
                          params: listingParams,
                          isFromListingPage: true,
                        },
                      }}
                    >
                      <PrimaryButton type="button">
                        <FormattedMessage id={requestToBookButtonMessage} />
                      </PrimaryButton>
                    </NamedLink>
                  )}

                  {!isInstantBooking && (
                    <p className={css.smallPrint}>
                      <FormattedMessage id="BookingPanel.noCharge" />
                    </p>
                  )}
                </div>
                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>
              </div>
            )}
            <Modal
              id="rentalAgreementModal"
              isOpen={state.isOpenRentalAgreement}
              onClose={() => setState(prev => ({ ...prev, isOpenRentalAgreement: false }))}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <RentalAgreement />
            </Modal>
            <React.Fragment>
              <InsurancePanel
                listing={listing}
                showInsurance={true}
                insuranceType={insuranceType}
                onManageDisableScrolling={onManageDisableScrolling}
                onReadInsurance={onReadInsurance}
              />
              <div className={css.rentalAgreementLink}>
                <span onClick={() => setState(prev => ({ ...prev, isOpenRentalAgreement: true }))}>
                  <FormattedMessage id="BookingDatesForm.rentalAgreementLink" />
                </span>
              </div>
            </React.Fragment>
          </Form>
        );
      }}
    />
  );
};

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: [],
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: oneOfType([arrayOf(propTypes.timeSlot), array]),
  onReadInsurance: func,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  requestButtonId: string,
  isNewCar: bool,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
