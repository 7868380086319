import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';
import { intlShape } from '../../util/reactIntl';
import {
  dateIsAfter,
  findNextBoundary,
  getEndHours,
  getMonthStartInTimeZone,
  getStartHours,
  isDayMomentInsideRange,
  isInRange,
  isSameDate,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  timestampToDate,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput, FieldSelect } from '../../components';
import moment from 'moment';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import OutsideClickHandler from './OutsideClickHandler';
import css from './FieldDateAndTimeInput.css';
import { uniqBy } from 'lodash';

const MAX_TIME_SLOTS_RANGE = 360;
const TODAY = new Date();
let availableEndTimes = [];
const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const getAvailableStartTimes = (
  intl,
  timeZone,
  bookingStart,
  timeSlotsOnSelectedDate,
  localTimeZone
) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    console.log('returning Empty', timeSlotsOnSelectedDate, bookingStart);
    return [];
  }
  const bookingStartDate =
    bookingStart && localTimeZone && resetToStartOfDay(bookingStart, localTimeZone);

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, localTimeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    let startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;
    startLimit = new Date().getTime() > startLimit.getTime() ? new Date() : startLimit;
    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;
    const hours = getStartHours(intl, localTimeZone, startLimit, endLimit);
    return availableHours.concat(hours);
  }, []);
  return uniqBy(allHours, i => i.timestamp);
};

const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot,
  localTimeZone
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDate(bookingStartTime);
  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, localTimeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, localTimeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, localTimeZone);
  let startLimit;
  let endLimit;
  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, localTimeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }
  return uniqBy(getEndHours(intl, localTimeZone, startLimit, endLimit), i => i.timestamp);
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
    : [];
};

const getAllSelectedTimeSlots = (monthlyTimeSlots, firstSelectedSlot, timeZone, localTimeZone) => {
  if (!firstSelectedSlot) {
    return [];
  }

  const currentMonth = getMonthStartInTimeZone(TODAY, localTimeZone);
  console.log('<<currentMonth', monthlyTimeSlots);
  const timeSlots =
    monthlyTimeSlots && getMonthlyTimeSlots(monthlyTimeSlots, currentMonth, timeZone);
  let selectedSlots = [firstSelectedSlot];
  let currentEndSlot = moment(firstSelectedSlot.attributes.end)
    .add(1, 'millisecond')
    .toDate();

  let slot = undefined;
  do {
    slot = timeSlots.find(t => isInRange(currentEndSlot, t.attributes.start, t.attributes.end));
    if (slot) {
      selectedSlots = selectedSlots.concat(slot);
      currentEndSlot = moment(slot.attributes.end).add(1, 'millisecond');
    }
  } while (slot);

  return selectedSlots;
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlotsOnSelectedDate,
  startDate,
  localTimeZone,
  selectedStartTime,
  selectedEndDate,
  timeSlots
) => {
  console.log('Start Date incoming', startDate);
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlotsOnSelectedDate, startDate, timeZone),
        localTimeZone
      );

  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeSlot = timeSlotsOnSelectedDate.find(t =>
    isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
  );

  const selectedTimeSlots = getAllSelectedTimeSlots(
    timeSlots,
    selectedTimeSlot,
    timeZone,
    localTimeZone
  );
  const endTimes = getAvailableEndTimes(
    intl,
    timeZone,
    startTime,
    endDate,
    selectedTimeSlot,
    localTimeZone
  );
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp ? endTimes[0].timestamp : null;

  return { startTime, endDate, endTime, selectedTimeSlot, selectedTimeSlots };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = date && timeZone && monthIdStringInTimeZone(date, timeZone);
  console.log('monthid', monthId, date, timeZone);
  const data =
    !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
      ? []
      : monthId && monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
      ? monthlyTimeSlots[monthId].timeSlots
      : [];
  console.log('data going to return', data);
  return data;
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return nextMonthDate &&
    timeZone &&
    dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : (
    <NextMonthIcon />
  );
};
const Prev = props => {
  const { currentMonth, timeZone, localTimeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, localTimeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, localTimeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class EnquiryFieldStartDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.localTimeZone),
      ifDropOffChanged: true,
      startDate: null,
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.onBookingEndDateChange = this.onBookingEndDateChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
  }

  componentDidMount() {
    const { localTimeZone, location, initialValues, updateBooking } = this.props;
    const urlSearchParams = location && location.search && new URLSearchParams(location.search);
    const params = urlSearchParams && Object.fromEntries(urlSearchParams.entries());
    const startDate =
      params && params.dates
        ? moment(params.dates.substring(0, params.dates.length - 11)).toDate()
        : null;

    if (startDate) {
      this.setState({ currentMonth: getMonthStartInTimeZone(startDate, localTimeZone) });
      this.fetchMonthData(getMonthStartInTimeZone(startDate));
    }

    if (updateBooking) {
      this.setState({
        currentMonth: getMonthStartInTimeZone(initialValues.bookingStartDate.date, localTimeZone),
      });
      this.fetchMonthData(
        getMonthStartInTimeZone(initialValues.bookingStartDate.date, localTimeZone)
      );
      const dummyStartDate = new Date(moment(initialValues.bookingStartDate.date).add(9, 'months'));
      this.fetchMonthData(getMonthStartInTimeZone(dummyStartDate, localTimeZone));
    }
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone, localTimeZone, location } = this.props;
    console.log('<<<<<<', onMonthChanged, timeZone, localTimeZone, location);

    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, localTimeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);

        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        // if redirect from the serach page with preselected dates
        if (
          (currentMonthData && currentMonthData.fetchTimeSlotsError) ||
          (location && location.state)
        ) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = value => {
    const { monthlyTimeSlots, timeZone, intl, form, localTimeZone } = this.props;
    console.log('intl<<', intl);
    console.log('form<<', form);
    console.log('localTimeZone<<', localTimeZone);

    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingStartTime', null);
        form.change('bookingEndDate', null);
        form.change('bookingEndTime', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, localTimeZone) });

      return;
    }

    // this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, localTimeZone) });

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    // const { startTime, endDate, endTime } = getAllTimeValues(
    //   intl,
    //   timeZone,
    //   timeSlotsOnSelectedDate,
    //   startDate,
    //   localTimeZone
    // );

    form.batch(() => {
      // form.change('bookingStartTime', startTime);
      // form.change('bookingEndDate', { date: endDate });
      // form.change('bookingEndTime', endTime);
    });
    this.setState({
      ifDropOffChanged: false,
      startDate,
      currentMonth: getMonthStartInTimeZone(startDate, localTimeZone),
    });
  };

  onBookingStartDateBlur = (e, value) => {
    const { localTimeZone, location, initialValues, timeZone } = this.props;

    // Reset the currentMonth too if click outside the calendar
    // if((state && state.startDate && !this.state.startDate) || (startDate && !this.state.startDate)) {
    //   if(state && location){
    //     this.setState({ currentMonth: getMonthStartInTimeZone(location.state.startDate, localTimeZone) });
    //   } else {
    //     this.setState({ currentMonth: getMonthStartInTimeZone(startDate, localTimeZone) });
    //   }
    // }
    // if((!state || state && !state.startDate) && !this.state.startDate && !startDate && !initialValues.bookingStartDate){
    //   this.setState({ currentMonth: new Date()});
    // }
    if (this.state.startDate || (initialValues && initialValues.bookingStartDate)) {
      if (this.state.startDate) {
        this.setState({
          currentMonth: getMonthStartInTimeZone(this.state.startDate, localTimeZone),
        });
      }
      if (initialValues && initialValues.bookingStartDate && !this.state.startDate) {
        this.setState({
          currentMonth: getMonthStartInTimeZone(initialValues.bookingStartDate.date, localTimeZone),
        });
      }
    }
  };

  onBookingStartTimeChange = value => {
    const { form } = this.props;

    form.batch(() => {
      // form.change('bookingEndDate', null);
      // form.change('bookingEndTime', null);
    });
  };

  onBookingEndDateChange = value => {
    const { form, values } = this.props;
    const prevDropOff =
      values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;
    const newDropOff = value && value.date;
    const isSameDate = moment(newDropOff).isSame(prevDropOff, 'day');
    if (!isSameDate) {
      this.setState({
        ifDropOffChanged: true,
      });
    } else {
      this.setState({
        ifDropOffChanged: false,
      });
    }
    if (!value || !value.date) {
      // form.change('bookingEndTime', null);
      return;
    }

    // form.change('bookingEndTime', null);
  };

  isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone) {
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    return (
      !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay)) ||
      this.props.isOutsideRange(localizedDay)
    );
  }

  startDateIsOutsizeRange = (day, timeZone) => {
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);

    return this.props.isOutsideRange(localizedDay);
  };

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      values,
      timeZone,
      intl,
      timeSlots,
      monthlyTimeSlots,
      localTimeZone,
      updateBooking,
      initialValues,
      transaction,
    } = this.props;
    const { ifDropOffChanged } = this.state;
    console.log('<<<<VALUES', values);
    const classes = classNames(rootClassName || css.root, className);
    const bookingStartDate =
      values && values.bookingStartDate && values.bookingStartDate.date
        ? values.bookingStartDate.date
        : null;
    const bookingStartTime = values && values.bookingStartTime ? values.bookingStartTime : null;
    const bookingEndDate =
      values && values.bookingEndDate && values.bookingEndDate.date
        ? values.bookingEndDate.date
        : null;

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );
    if (monthlyTimeSlots && monthlyTimeSlots.length) {
      timeSlotsOnSelectedMonth.push();
    }

    console.log('monthlyTimeSlots', monthlyTimeSlots);
    console.log('timeSlotsOnSelectedMonth', timeSlotsOnSelectedMonth);
    const timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonth,
      bookingStartDate,
      localTimeZone
    );
    console.log('timeSlotsOnSelectedDate', timeSlotsOnSelectedDate);
    const availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedDate,
      localTimeZone
    );

    const firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    const { startTime, endDate, selectedTimeSlot, selectedTimeSlots } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      localTimeZone,
      bookingStartTime,
      bookingEndDate,
      monthlyTimeSlots
    );

    availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime,
      bookingEndDate,
      selectedTimeSlot,
      localTimeZone
    );

    const isDayBlocked = timeSlotsOnSelectedMonth
      ? day =>
          !timeSlotsOnSelectedMonth.find(timeSlot => {
            return isDayMomentInsideRange(
              day,
              timeSlot.attributes.start,
              timeSlot.attributes.end,
              timeZone
            );
          })
      : () => false;

    const placeholderTime = '00:00';
    const today = moment();
    const bookedStartDate =
      transaction && transaction.booking && transaction.booking.attributes
        ? transaction.booking.attributes.displayStart
        : null;

    const dayBeforeBookedStart = bookedStartDate ? moment(bookedStartDate, localTimeZone) : null;
    const diffHours = dayBeforeBookedStart ? dayBeforeBookedStart.diff(today, 'hours', true) : 0;
    const disableUpdate = diffHours < 24;
    const startDateDisabled = updateBooking && disableUpdate;
    const startTimeDisabled = !bookingStartDate || (updateBooking && disableUpdate);

    const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.startTime' });
    /**
     * NOTE: In this template the field for the end date is hidden by default.
     * If you want to enable longer booking periods, showing the end date in the form requires some code changes:
     * 1. Move the bookingStartTime field to the same formRow with the bookingStartDate field
     * 2. Remove the div containing the line between dates
     * 3. Remove the css related to hiding the booking end date from the bottom of the FieldDateAndTimeInput.css field
     */

    return (
      <div className={classes}>
        <OutsideClickHandler onClick={this.onBookingStartDateBlur}>
          <div
            className={
              updateBooking
                ? classNames(css.updateBookingFormRow, css.endDate)
                : classNames(css.formRow, css.endDate)
            }
          >
            <div className={classNames(css.field)} style={{ zIndex: 1 }}>
              <FieldDateInput
                labelClassName={css.label}
                className={css.fieldDateInput}
                name="bookingStartDate"
                id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
                label={startDateInputProps.label}
                placeholderText={startDateInputProps.placeholderText}
                format={v =>
                  v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
                }
                parse={v =>
                  v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
                }
                disabled={startDateDisabled}
                isDayBlocked={isDayBlocked}
                onChange={this.onBookingStartDateChange}
                onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
                onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
                navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                navPrev={
                  <Prev
                    localTimeZone={localTimeZone}
                    currentMonth={this.state.currentMonth}
                    timeZone={timeZone}
                  />
                }
                useMobileMargins
                showErrorMessage={false}
                validate={bookingDateRequired('Required')}
                // isOutsideRange={day => this.startDateIsOutsizeRange(day, timeZone)}
              />
            </div>
            <div
              className={
                updateBooking
                  ? classNames(css.lineBetween, css.updateBookingLineBetween)
                  : bookingStartDate
                  ? css.lineBetween
                  : css.lineBetweenDisabled
              }
            >
              -
            </div>
            <div className={updateBooking ? css.updateBookingField : css.field}>
              {console.log(
                '<<<bookingStartDate',
                bookingStartDate,
                initialValues,
                availableStartTimes
              )}
              <FieldSelect
                labelClassName={css.label}
                name="bookingStartTime"
                id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
                className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                selectClassName={bookingStartDate ? css.select : css.selectDisabled}
                label={startTimeLabel}
                disabled={startTimeDisabled}
                onChange={this.onBookingStartTimeChange}
              >
                <option value="">
                  {initialValues &&
                  initialValues.bookingStartDate &&
                  initialValues.bookingStartDate.date &&
                  !this.state.startDate
                    ? moment(initialValues.bookingStartDate.date).format('hh:mm a')
                    : '00:00'}
                </option>
                {bookingStartDate ? (
                  availableStartTimes.map(p => (
                    <option key={`bookingStartTime_${p.timeOfDay}`} value={p.timestamp}>
                      {p.timeOfDay}
                    </option>
                  ))
                ) : (
                  <option>{placeholderTime}</option>
                )}
              </FieldSelect>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    );
  }
}

EnquiryFieldStartDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  timeZone: null,
  isOutsideRange: () => false,
};

EnquiryFieldStartDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,
  isOutsideRange: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default EnquiryFieldStartDateAndTimeInput;
