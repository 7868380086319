import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import Slider from 'react-slick';
import classNames from 'classnames';
import { ListingCard, NamedLink } from '../../components';
import css from './CovidPage.css';

export const SectionBrowseCar = memo(
  ({ intl, listings = [] }) => {
    const sliderConfig = {
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      centerPadding: '24px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.25,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.25,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
      ],
    };
    return (
      <div className={css.sectionBrowseCar}>
        <div className={css.listings}>
          <Slider {...sliderConfig}>
            {listings.map(l => (
              <div>
                <ListingCard listing={l} intl={intl} />
              </div>
            ))}
          </Slider>
        </div>
        <div className={css.btnBookNow}>
          <NamedLink
            name="SearchPage"
            className={classNames(css.button, css.text)}
            to={{
              search: `?meta_isDisinfected=true&bounds=1.4708809,104.04157989999999,1.216611,103.60650989999999`,
            }}
          >
            Book now
          </NamedLink>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev.listings, next.listings)
);
