// import { loginAmplitude, logoutAmplitude } from './amplitude';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

export const loginIntercomUser = (currentUser, app_id) => {
  if (typeof window.Intercom === 'undefined') {
    console.log('Intercom is not ready');
    return;
  }

  if (!currentUser || !currentUser.id) {
    return;
  }

  const profile = currentUser.attributes.profile;

  const intercomSettings = window.intercomSettings || {
    app_id,
  };

  intercomSettings.user_id = currentUser.id.uuid;
  intercomSettings.email = currentUser.attributes.email;
  intercomSettings.phone = profile.protectedData.phoneNumber;
  intercomSettings.name = `${profile.firstName} ${profile.lastName}`;
  intercomSettings.domain = 'drive-mate.com.au';
  intercomSettings.tag = 'drivemate123';

  window.Intercom('shutdown');
  window.Intercom('boot', intercomSettings);

  // loginAmplitude({
  //   userId: `${currentUser.id.uuid} - ${intercomSettings.email}`,
  //   userData: {
  //     Email: intercomSettings.email,
  //     Phone: intercomSettings.phone,
  //     Name: intercomSettings.name,
  //   }
  // });

  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.setItem(
      'uvk',
      currentUser.attributes.profile.privateData.userVerificationKey
    );
  }
};

export const logoutIntercomUser = app_id => {
  if (typeof window.Intercom === 'undefined') {
    return;
  }

  const intercomSettings = {
    app_id,
    user_id: null,
    email: null,
    phone: null,
    name: null,
    role: null,
    domain: DOMAIN_URL,
  };

  window.Intercom('shutdown');
  window.Intercom('boot', intercomSettings);

  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.removeItem('uvk');
  }

  // logoutAmplitude();
};

/**
 * This method will toggle the visibility of intercom widget
 *
 * @param {boolean} [isVisible=true] visibility of intercom widget
 */
export const IntercomNotVisibility = (isNotVisible = false) => {
  if (window && window.Intercom && typeof Intercom === 'function') {
    window.Intercom('update', {
      hide_default_launcher: isNotVisible,
    });
  }
};
