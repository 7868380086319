import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';

import css from './BookingBreakdown.css';
import { Money } from 'sharetribe-flex-sdk/src/types';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROCESSING_FEE } from '../../util/types';

const LineItemUpdateBookingMaybe = props => {
  const { transaction, intl, isProvider } = props;

  const updateBookingLineItems =
    transaction &&
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.childTransaction &&
    transaction.attributes.protectedData.childTransaction.lineItems
      ? transaction.attributes.protectedData.childTransaction.lineItems
      : [];

  const customerCommissionLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
  const processingFeeLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_PROCESSING_FEE
  );

  const updatedBookingPayinTotal =
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.childTransaction &&
    transaction.attributes.protectedData.childTransaction.payinTotal
      ? transaction.attributes.protectedData.childTransaction.payinTotal
      : null;

  if (updatedBookingPayinTotal === null) return null;

  const finalUpdatedBookingPayinTotal = isProvider
    ? updatedBookingPayinTotal -
      processingFeeLineItem.unitPrice -
      customerCommissionLineItem.unitPrice
    : updatedBookingPayinTotal;

  const label = <FormattedMessage id="BookingBreakdown.updateBooking" />;
  const updateBookingPrice = new Money(finalUpdatedBookingPayinTotal * 100, 'SGD');
  const formattedPrice = formatMoney(intl, updateBookingPrice, 1);

  return (
    <div className={css.creditsLineItem}>
      <div className={css.totalLabel}>{label}</div>
      <div className={css.totalPrice}>{formattedPrice}</div>
    </div>
  );
};

export default LineItemUpdateBookingMaybe;
