import React from 'react';
import css from './BrandingPage.css';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const SectionHeroBranding = () => {
  return (
    <div className={css.sectionBrandingHero}>
      <div className={css.SectionHeroBrandingBg}>
        <div className={css.BrandingTitle}>
          <FormattedMessage id="SectionHeroBranding.SectionHero.title" />
        </div>
        <div className={classNames(css.brandingDescription, css.sectionHeroDescription)}>
          <FormattedMessage
            id="SectionHeroBranding.SectionHero.description"
            values={{ newLine: <br /> }}
          />
        </div>
      </div>
      <div className={css.cards}>
        <div className={css.card}>
          <LazyImage imgHeight={'content-fit'} src={imageSource?.carIcon} alt="" />
          <div className={css.content}>
            <h3 className={css.contentTitle}>Know Who’s Behind the Wheel</h3>
            <p className={css.contentPara}>
              Locals driving your car have all been subjected to Drive mate’s comprehensive
              screening process. This includes licensing and driver history checks ensuring only
              genuine borrowers with immaculate records can access your vehicle.
            </p>
          </div>
        </div>

        <div className={css.card}>
          <LazyImage imgHeight={'content-fit'} src={imageSource?.carIcon2} alt="" />
          <div className={css.content}>
            <h3 className={css.contentTitle}>Superior Security</h3>
            <p className={css.contentPara}>
              Our proprietary technology enhances the safety and convenience of sharing your car
              with guests. Our exclusive high-tech in-app security system provides keyless access,
              telemetry data monitoring and remote immobilisation features you won’t find offered
              anywhere else.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeroBranding;
