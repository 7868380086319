import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log('error:', error, ' errorInfo', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <div></div>;
      return null; // TODO: Replace null with proper component level message for failure
    }

    return this.props.children;
  }
}
