import axios from 'axios';
import config from '../config';
import { getToken } from './api/appApi';

const url = config.apiUrl;

export const sendNotification = async ({
  userId,
  transactionId,
  transition,
  uvk,
  metadata = {},
}) => {
  try {
    const res = await axios.post(`${url}/api/transaction-webhook`, {
      userId,
      transactionId: typeof transactionId === 'object' ? transactionId.uuid : transactionId,
      transition,
      uvk,
      metadata,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendMessageTimeNotification = async ({ userId, transactionId }) => {
  try {
    const res = await axios.post(`${url}/api/message-sync`, {
      userId,
      transactionId: typeof transactionId === 'object' ? transactionId.uuid : transactionId,
    });
  } catch (error) {
    console.log(error);
  }
};

// user_id: currentUser.attributes.publicData.shuUserId,
// booking_id:id,
// fleetNo: "SLK3368U"

export const sendShuCarPushConfig = async ({ user_id, booking_id, fleet_id }) => {
  try {
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const res = await axios.post(
      `${url}/api/partner/grab/pushConfig`,
      {
        user_id,
        booking_id,
        fleet_id,
      },
      axiosConfig
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const startShuCar = async ({ user_id, booking_id, fleet_id }) => {
  try {
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const res = await axios.post(
      `${url}/api/partner/grab/start`,
      {
        user_id,
        booking_id,
        fleet_id,
      },
      axiosConfig
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const lockShuCar = async ({ user_id, booking_id, fleet_id, listingId }) => {
  try {
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const res = await axios.post(
      `${url}/api/partner/grab/lock`,
      {
        user_id,
        booking_id,
        fleet_id,
        listingId,
      },
      axiosConfig
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const unlockShuCar = async ({ user_id, booking_id, fleet_id, listingId }) => {
  try {
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const res = await axios.post(
      `${url}/api/partner/grab/unlock`,
      {
        user_id,
        booking_id,
        fleet_id,
        listingId,
      },
      axiosConfig
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const stopShuCar = async ({ user_id, booking_id, fleet_id }) => {
  try {
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    const res = await axios.post(
      `${url}/api/partner/grab/stop`,
      {
        user_id,
        booking_id,
        fleet_id,
      },
      axiosConfig
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getLockboxCode = async (listingId, isForce) => {
  const token = getToken().access_token;

  try {
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = isForce ? { listingId, isForce } : { listingId };
    const res = await axios.post(
      `${url}/api/sentrilock/generate-code-by-listing`,
      payload,
      axiosConfig
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
