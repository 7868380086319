import React, { Component } from 'react';
import { HelpLineNumber } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ByodFaq.css';

class ByodFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: -1,
    };
  }

  openTab = index => {
    this.setState({
      tabOpen: index !== this.state.tabOpen ? index : -1,
    });
  };

  render() {
    return (
      <section className={css.faqSection}>
        <div className={css.innerWrapper}>
          <h6 className={css.title}>
            <FormattedMessage id="BecomeAHostPage.faqTitle" values={{ newline: <br /> }} />
          </h6>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(0)}>
                How will devices work directly in the Drive mate app and website?
              </div>
              {this.state.tabOpen === 0 && (
                <div className={css.answer}>
                  <ul
                    style={{
                      display: 'block',
                      listStyle: 'disc',
                      margin: '5px',
                    }}
                  >
                    <li>
                      Otoplugs (connected via OBD)
                      <ul
                        style={{
                          display: 'block',
                          listStyle: 'disc',
                          paddingLeft: '25px',
                        }}
                      >
                        <li>
                          Guests and hosts able to lock/unlock vehicles via Drive mate app/web.
                        </li>
                        <li>Location tracking available to hosts outside of Drive mate trips.</li>
                        <li>
                          All other features like remote immobilisation and bluetooth connectivity
                          will remain.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Lockbox (Sentrilock/Igloohome)
                      <ul
                        style={{
                          display: 'block',
                          listStyle: 'disc',
                          paddingLeft: '25px',
                        }}
                      >
                        <li>
                          Guests receive codes automatically when starting trips in Drive mate app.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Primary GPS (connected via OBD)
                      <ul
                        style={{
                          display: 'block',
                          listStyle: 'disc',
                          paddingLeft: '25px',
                        }}
                      >
                        <li>GPS tracking.</li>
                        <li>Data for excess kms calculations.</li>
                      </ul>
                    </li>
                    <li>
                      Secondary GPS
                      <ul
                        style={{
                          display: 'block',
                          listStyle: 'disc',
                          paddingLeft: '25px',
                        }}
                      >
                        <li>Backup security for (rare) repossessions.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(1)}>
                What happens to SIM cards in Otoplugs and other devices?
              </div>
              {this.state.tabOpen === 1 && (
                <div className={css.answer}>
                  Most existing SIM cards can be reactivated. In rare cases, we will provide new SIM
                  cards with simple changeover instructions.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(2)}>
                What about non UCS devices?
              </div>
              {this.state.tabOpen === 2 && (
                <div className={css.answer}>
                  Currently we are only providing BYOD for UCS devices.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(3)}>
                Why do I need to transfer device ownership to Drive mate?
              </div>
              {this.state.tabOpen === 3 && (
                <div className={css.answer}>
                  We need ownership to manage cloud connectivity so devices seamlessly integrate
                  with our app and website.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(4)}>
                Will Drive mate sell these devices?
              </div>
              {this.state.tabOpen === 4 && (
                <div className={css.answer}>
                  No, if hosts can’t wait for Drive mate Go 3.0, we’ll supply Otoplugs free of
                  charge and professionally install for $100, then $30/month subscription charge.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(10)}>
                Is Drive mate still developing Drive mate Go 3.0?
              </div>
              {this.state.tabOpen === 10 && (
                <div className={css.answer}>
                  Absolutely. BYOD simply gives Drive mate hosts already-tested remote access tech
                  via Otoplug, while Drive mate Go 3.0 finishes field trials.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(5)}>
                What about the UCS functionally issues with Otoplugs?
              </div>
              {this.state.tabOpen === 5 && (
                <div className={css.answer}>
                  Currently being addressed, and will be carefully monitored once these devices go
                  live on Drive mate, including battery drainage issues.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(6)}>
                When will these devices become active?
              </div>
              {this.state.tabOpen === 6 && (
                <div className={css.answer}>
                  We’re aiming for late September and will keep hosts updated on timelines. No
                  charges will be made until devices are activated.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(7)}>
                What does it cost?
              </div>
              {this.state.tabOpen === 7 && (
                <div className={css.answer}>
                  <ul
                    style={{
                      display: 'block',
                      listStyle: 'disc',
                      margin: '5px',
                    }}
                  >
                    <li>
                      We have a range of plans that allow BYOD, ranging from free to $30/month.
                    </li>
                    <li>There’s a $2 one-time transfer fee per device to BYOD to Drive mate.</li>
                  </ul>
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(8)}>
                Did Drive mate buy the hardware usage rights from UCS?
              </div>
              {this.state.tabOpen === 8 && (
                <div className={css.answer}>
                  <ul
                    style={{
                      display: 'block',
                      listStyle: 'disc',
                      margin: '5px',
                    }}
                  >
                    <li>
                      No, Drive mate is signing contracts directly with the equipment and tech
                      creators
                    </li>
                    <li>
                      UCS has instructed its hosts that they are to dispose of all hardware, and has
                      not asked for hardware to be returned. This plus the fact that UCS has
                      cancelled its services indicate that UCS has abandoned the hardware, so that
                      hosts can transfer the rights in the hardware to Drive mate.
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(9)}>
                Why do I need to pay Drive mate if I’m bringing my own device?
              </div>
              {this.state.tabOpen === 9 && (
                <div className={css.answer}>
                  Drive mate has to pay the devices’ cloud connectivity providers for their usage
                  and incurs costs running the advanced tech.
                </div>
              )}
            </div>
          </div>
          <HelpLineNumber className={css.helpline} isWhiteText={true} />
        </div>
        <div className={css.seperateLine}></div>
      </section>
    );
  }
}

export default ByodFaq;
