import React, { useState } from 'react';
import classNames from 'classnames';
import { FieldInteractiveTextInput } from '../../components';
import * as validators from '../../util/validators';
import css from './SignupFormThemed.css';
import EyeIcon from '../../assets/eye-solid.svg';

const SignUpField = props => {
  const [isShowPassword, setShowPassword] = useState(false);
  const { intl, formId, values, touched, errors } = props;

  const invalidPassword = touched.password && errors.password;
  let codeClasses = invalidPassword ? classNames(css.code, css.invalidPassword) : css.code;
  // password
  const passwordLabel = intl.formatMessage({
    id: 'SignupForm.passwordLabel',
  });
  const passwordPlaceholder = intl.formatMessage({
    id: 'SignupForm.passwordPlaceholder',
  });
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: validators.PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: validators.PASSWORD_MAX_LENGTH,
    }
  );
  const passwordMinLength = validators.minLength(
    passwordMinLengthMessage,
    validators.PASSWORD_MIN_LENGTH
  );
  const passwordMaxLength = validators.maxLength(
    passwordMaxLengthMessage,
    validators.PASSWORD_MAX_LENGTH
  );
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = validators.composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength
  );

  // Email address
  const emailLabel = intl.formatMessage({
    id: 'SignupForm.emailLabel',
  });
  const emailPlaceholder = intl.formatMessage({
    id: 'SignupForm.emailPlaceholder',
  });
  const emailRequiredMessage = intl.formatMessage({
    id: 'SignupForm.emailRequired',
  });
  const emailRequired = validators.required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);

  // firstName
  const firstNameLabel = intl.formatMessage({
    id: 'SignupForm.firstNameLabel',
  });
  const firstNamePlaceholder = intl.formatMessage({
    id: 'SignupForm.firstNamePlaceholder',
  });
  const firstNameRequiredMessage = intl.formatMessage({
    id: 'SignupForm.firstNameRequired',
  });
  const firstNameRequired = validators.required(firstNameRequiredMessage);

  // lastName
  const lastNameLabel = intl.formatMessage({
    id: 'SignupForm.lastNameLabel',
  });
  const lastNamePlaceholder = intl.formatMessage({
    id: 'SignupForm.lastNamePlaceholder',
  });
  const lastNameRequiredMessage = intl.formatMessage({
    id: 'SignupForm.lastNameRequired',
  });
  const genderRequiredMessage = intl.formatMessage({
    id: 'SignupForm.genderNameRequired',
  });

  //Code

  const codeLabel = intl.formatMessage({
    id: 'SignupForm.codeLabel',
  });
  const codePlaceholder = intl.formatMessage({
    id: 'SignupForm.codePlaceholder',
  });

  const lastNameRequired = validators.required(lastNameRequiredMessage);

  const genderRequired = validators.required(genderRequiredMessage);

  const required = validators.required(intl.formatMessage({ id: 'SignupForm.dobRequired' }));
  const minAge = 22;
  const minAgeMessage = intl.formatMessage({ id: 'SignupForm.minAgeRequired' }, { minAge });
  const minAgeRequired = validators.ageAtLeastSignUp(minAgeMessage, minAge);
  const firstNameValue = values.fname;
  const lastNameValue = values.lname;
  const emailValue = values.email;
  const passwordValue = values.password;
  const codeValue = values.code;
  return (
    <div>
      <div className={css.name}>
        <FieldInteractiveTextInput
          className={classNames(css.firstNameRoot, css.newInput)}
          type="text"
          id={formId ? `${formId}.fname` : 'fname'}
          name="fname"
          autoComplete="given-name"
          label={firstNameLabel}
          validate={firstNameRequired}
          countCharacters={{
            currentCount: (firstNameValue && firstNameValue.length) || 0,
          }}
        />
        <FieldInteractiveTextInput
          className={classNames(css.lastNameRoot, css.newInput)}
          type="text"
          id={formId ? `${formId}.lname` : 'lname'}
          name="lname"
          autoComplete="family-name"
          label={lastNameLabel}
          validate={lastNameRequired}
          countCharacters={{
            currentCount: (lastNameValue && lastNameValue.length) || 0,
          }}
        />
      </div>
      {/* <FieldInteractiveTextInput
        className={classNames(css.firstNameRoot, css.newInput)}
        type="text"
        id={formId ? `${formId}.fname` : 'fname'}
        name="fname"
        autoComplete="given-name"
        label="Full name"
        validate={firstNameRequired}
        countCharacters={{
          currentCount: (fullnameValue && fullnameValue.length) || 0,
        }}
      /> */}
      <FieldInteractiveTextInput
        type="email"
        id={formId ? `${formId}.email` : 'email'}
        className={css.newInput}
        name="email"
        autoComplete="email"
        disabled={!!props.initEmail}
        label={emailLabel}
        validate={validators.composeValidators(emailRequired, emailValid)}
        countCharacters={{
          currentCount: (emailValue && emailValue.length) || 0,
        }}
      />
      {/* <FieldSelect
        id="gender"
        name="gender"
        validate={genderRequired}
        className={classNames(css.password, css.newInput)}
      >
        <option value="" selected={true} disabled={true}>
          {intl.formatMessage({ id: 'SignupForm.genderPlaceholder' })}
        </option>
        {config.custom.genders.map(option => (
          <option value={option.key} key={option.key}>
            {option.label}
          </option>
        ))}
      </FieldSelect> */}
      {/* <FieldBirthdayInput
        className={classNames(css.password, css.newInput)}
        id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
        name='dateOfBirth'
        label='Date of birth*'
        format={v => v}
        valueFromForm={values.birthDate}
        validate={validators.composeValidators(required, minAgeRequired)}
      /> */}
      <div className={css.passwordContainer}>
        <span className={css.eyeIcon} onClick={() => setShowPassword(!isShowPassword)}>
          <img src={EyeIcon} alt="Alt text" />
        </span>
        <FieldInteractiveTextInput
          className={classNames(css.password, css.newInput)}
          type={isShowPassword ? 'text' : 'password'}
          id={formId ? `${formId}.password` : 'password'}
          name="password"
          autoComplete="new-password"
          label={passwordLabel}
          validate={passwordValidators}
          countCharacters={{
            currentCount: (passwordValue && passwordValue.length) || 0,
          }}
        />
      </div>
      {/* <FieldInteractiveTextInput
        className={classNames(css.password, css.newInput)}
        type="text"
        id={formId ? `${formId}.code` : 'code'}
        name="code"
        label={codeLabel}
        countCharacters={{
          currentCount: (codeValue && codeValue.length) || 0,
        }}
      /> */}
    </div>
  );
};

SignUpField.defaultProps = {};

SignUpField.propTypes = {};

export default SignUpField;
