import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { CalendlyEventListener, InlineWidget } from 'react-calendly';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { Button, Form } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './EditListingOnboardingCallForm.css';

export class EditListingOnboardingCallFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCallScheduled: false,
      userSetCall: props.isOnboardingCallScheduled ? props.isOnboardingCallScheduled : false,
    };
  }

  onEventScheduled = e => {
    const { onSubmit } = this.props;
    this.setState({
      isCallScheduled: true,
      userSetCall: true,
    });
    // onSubmit({isListingOnboardingCallScheduled: true});
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            invalid,
            pristine,
            rootClassName,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            submitButtonId,
            listing,
            currentUser,
            isOnboardingCallScheduled,
          } = formRenderProps;
          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress || !this.state.userSetCall;
          const { updateListingError, showListingsError } = fetchErrors || {};
          const calendlyUrl =
            process.env.REACT_APP_CALENDLY_BOOKING_URL ||
            'https://calendly.com/meetdrivelah/onboarding-for-drive-lah?hide_event_type_details=1&hide_gdpr_banner=1&text_color=0d6786&primary_color=fecd2a';
          let prefillData = {};
          if (currentUser && currentUser.attributes) {
            const userProfileData = currentUser.attributes;
            const { profile = {}, email, emailVerified } = userProfileData;
            const { firstName = null, lastName = null, displayName = null } = profile;
            prefillData.email = email && emailVerified ? email : null;
            prefillData.firstName = firstName;
            prefillData.lastName = lastName;
            prefillData.name = displayName;
          }
          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingOnboardingCallForm.updateFailed" />
                </p>
              ) : null}

              {!this.state.userSetCall && (
                <div className={css.moreDesc}>
                  <FormattedMessage id="EditListingOnboardingCallForm.moreDescription" />
                </div>
              )}

              {/* <div className={css.moreDescSmall}>
              <FormattedMessage id="EditListingOnboardingCallForm.moreDescriptionSmallerPrint" />
            </div> */}

              {this.props.isOnboardingCallScheduled && (
                <div className={css.moreDesc}>
                  <FormattedMessage id="EditListingOnboardingCallForm.alreadyMeetingScheduled" />
                </div>
              )}

              {this.state.isCallScheduled && (
                <p className={css.moreDesc}>
                  <FormattedMessage
                    id="EditListingOnboardingCallForm.meetingScheduled"
                    values={{ newline: <br /> }}
                  />
                </p>
              )}

              {!this.state.isCallScheduled && !this.props.isOnboardingCallScheduled ? (
                <div>
                  <InlineWidget
                    prefill={prefillData}
                    styles={{
                      position: 'relative',
                      minWidth: '320px',
                      height: '720px',
                    }}
                    pageSettings={{
                      backgroundColor: 'ffffff',
                      hideEventTypeDetails: true,
                      hideLandingPageDetails: false,
                      hideGdprBanner: true,
                      primaryColor: 'fecd2a',
                      textColor: '0d6786',
                    }}
                    url={calendlyUrl}
                  />

                  <CalendlyEventListener onEventScheduled={this.onEventScheduled} />
                </div>
              ) : (
                ''
              )}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
                id={submitButtonId}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingOnboardingCallFormComponent.defaultProps = {
  fetchErrors: null,
};

EditListingOnboardingCallFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
  onChangeInstantBooking: func,
};

export default compose(injectIntl)(EditListingOnboardingCallFormComponent);
