import React from 'react';
import css from './GrabRentACarLandingPage.css';
// import easyAccessIcon from '../../assets/grabBanner/.png';
import { IconSpinner } from '../../components';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import config from '../../config';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const { Money } = types;

const GrabIntroBanner = ({ isScoot, signUpCodeAmount, loading, isMobileLayout }) => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const redirectToSignup = () => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
          }
        : {
            pathname: url,
          }
    );
  };
  return (
    <div className={css.bannerIntroSection}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column6}>
            <div className={css.bannerIntroContent}>
              <div className={css.bannerLogoBlock}>
                {!isMobileLayout && <LazyImage src={imageSource.grabBanner.bannerLogo} alt="" />}
              </div>
              <h1>
                Driving for Grab? <br /> Rent flexibly with Drive mate
              </h1>
              <ul className={css.bannerListing}>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.approveBlueIconMobile} alt="" />
                    ) : (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.approveBlueIcon} alt="" />
                    )}
                  </span>
                  Approved by{' '}
                  <span className={css.contentIcon}>
                    {isMobileLayout ? (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.grabLogoWhite} alt="" />
                    ) : (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.grabLogo} alt="" />
                    )}
                  </span>
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.saveMoneyIconMobile} alt="" />
                    ) : (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.saveMoneyIcon} alt="" />
                    )}
                  </span>
                  From only $4/Hour or $29/Day
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.easyAccessIconMobile} alt="" />
                    ) : (
                      <LazyImage objectFit={'contain'} src={imageSource.grabBanner.easyAccessIcon} alt="" />
                    )}
                  </span>
                  Pay per use, no long lease contracts
                </li>
              </ul>
              <button onClick={() => redirectToSignup()} className={css.bannerButton}>
                {loading ? (
                  <div className={css.loadingWrapper}>
                    <IconSpinner className={css.loadingSpinner} />
                  </div>
                ) : currentUser && currentUser.id ? (
                  <>
                    <div className={css.signUpTitle}>
                      {signUpCodeAmount && signUpCodeAmount > 0 ? (
                        <span>
                          Sign up now to get{' '}
                          {formatMoney(intl, new Money(signUpCodeAmount, config.currency))} credits
                        </span>
                      ) : (
                        <span>Sign up now to get $30 credits</span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={css.signUpTitle}>
                      {signUpCodeAmount && signUpCodeAmount > 0 ? (
                        <span>
                          Sign up now to get{' '}
                          {formatMoney(intl, new Money(signUpCodeAmount, config.currency))} credits
                        </span>
                      ) : (
                        <span>Sign up now to get $30 credits</span>
                      )}
                    </div>
                  </>
                )}
              </button>
              <div className={css.mobileBannerLogo}>
                {isMobileLayout && <LazyImage src={imageSource.grabBanner.bannerLogoMobile} alt="" />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bannerIntroImageSection}>
        <LazyImage src={imageSource.grabBanner.bannerBackgroundRent} alt="" />
      </div>
    </div>
  );
};

export default GrabIntroBanner;
