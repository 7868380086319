/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { FieldTextInput, Form, StripePaymentAddress } from '../../components';
import css from './SubscriptionPaymentForm.css';
import { isEqual } from 'lodash';
import * as validators from '../../util/validators';

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'PaymentMethodsForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `PaymentMethodsForm.stripe.validation_error.${code}`
      : `PaymentMethodsForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src:
        'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const initialState = {
  error: null,
  cardValueValid: false,
  isStickySubmitVisible: false,
};

/**
 * Payment methods form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle card setup. `stripe.handleCardSetup`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class SubscriptionPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    //this.handleCardValueChange = this.handleCardValueChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.paymentForm = this.paymentForm.bind(this);
    this.finalFormAPI = null;
    //this.stripe = null;
    //this.stickySubmitButtonRef = React.createRef();
  }

  componentDidMount() {
    const { mountingCard } = this.props;
    mountingCard && mountingCard.mount && mountingCard.mount(this.cardContainer);
    this.initDefaultValues();
  }

  componentWillUnmount() {
    const { mountingCard } = this.props;
    mountingCard && mountingCard.unmount && mountingCard.unmount();
  }

  initDefaultValues = () => {
    const { addressLine1, city, country, name, postal } = this.props.initialValues;
    const fillUpValues = {
      addressLine1,
      city,
      country,
      name,
      postal,
    };

    this.props.form.initialize(values => ({
      ...values,
      ...fillUpValues,
    }));

    // Object.entries(fillUpValues).map((item) => {
    // const [key , value] = item
    // this.props.form.change(key , value)
    //})
  };

  componentDidUpdate(prevProps) {
    const result = isEqual(prevProps.initialValues, this.props.initialValues);
    if (!result) {
      this.initDefaultValues();
    }
    if (prevProps.card !== this.props.card) {
      const { mountingCard } = this.props;
      prevProps.mountingCard && prevProps.mountingCard.unmount && prevProps.mountingCard.unmount();
      mountingCard && mountingCard.mount && mountingCard.mount(this.cardContainer);
    }
  }

  render() {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      formId,
      intl,
      invalid,
      handleSubmit,
      addPaymentMethodError,
      deletePaymentMethodError,
      createStripeCustomerError,
      handleCardSetupError,
      form,
    } = this.props;

    this.finalFormAPI = form;
    const cardInputNeedsAttention = !this.state.cardValueValid;
    const submitDisabled = invalid || cardInputNeedsAttention || submitInProgress;
    const hasCardError = this.state.error && !submitInProgress;
    const classes = classNames(rootClassName || css.root, className);
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: this.state.cardValueValid,
      [css.cardError]: hasCardError,
    });

    const hasErrors =
      addPaymentMethodError ||
      deletePaymentMethodError ||
      createStripeCustomerError ||
      handleCardSetupError;

    const errorMessage = intl.formatMessage({ id: 'PaymentMethodsForm.genericError' });

    const billingDetailsNameLabel = intl.formatMessage({
      id: 'PaymentMethodsForm.billingDetailsNameLabel',
    });

    const billingDetailsNamePlaceholder = intl.formatMessage({
      id: 'PaymentMethodsForm.billingDetailsNamePlaceholder',
    });

    const infoText = intl.formatMessage({
      id: 'PaymentMethodsForm.infoText',
    });

    // Stripe recommends asking billing address.
    // In PaymentMethodsForm, we send name and email as billing details, but address only if it exists.

    const billingAddress = (
      <StripePaymentAddress
        intl={intl}
        form={form}
        fieldId={formId}
        card={this.props.card}
        initialValues={this.props.initialValues}
      />
    );

    const hasStripeKey = config.stripe.publishableKey;
    const { isStickySubmitVisible, initialValues } = this.props;
    const cardHolderName = (initialValues && initialValues.name) || undefined;

    const cardHolderNameRequired = validators.required(
      intl.formatMessage({
        id: 'StripePaymentAddress.cardHolderNameRequired',
      })
    );
    return hasStripeKey ? (
      <Form className={classes} onSubmit={handleSubmit}>
        <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
          <FormattedMessage id="PaymentMethodsForm.paymentCardDetails" />
        </label>

        <div
          className={cardClasses}
          id={`${formId}-card`}
          ref={el => {
            this.cardContainer = el;
          }}
        />
        <div className={css.infoText}>{infoText}</div>
        {hasCardError ? <span className={css.error}>{this.state.error}</span> : null}
        <div className={css.paymentAddressField}>
          <h3 className={css.billingHeading}>
            <FormattedMessage id="PaymentMethodsForm.billingDetails" />
          </h3>

          <FieldTextInput
            className={css.field}
            type="text"
            id="name"
            name="name"
            autoComplete="cc-name"
            validate={cardHolderNameRequired}
            label={billingDetailsNameLabel}
            placeholder={billingDetailsNamePlaceholder}
            initialValue={cardHolderName}
          />

          {billingAddress}
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="PaymentMethodsForm.missingStripeKey" />
      </div>
    );
  }
}

SubscriptionPaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
};

SubscriptionPaymentForm.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  addPaymentMethodError: object,
  deletePaymentMethodError: object,
  createStripeCustomerError: object,
  handleCardSetupError: object,
};

export default injectIntl(SubscriptionPaymentForm);
