import config from '../../config';

const apiUrl = config.apiUrl;

export const getTransactionPhoneNumbers = transactionId => {
  return fetch(`${apiUrl}/api/transactions/${transactionId}/phone-number`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};
