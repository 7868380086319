export const adrollRecordUser = id => {
  if (typeof window.__adroll === 'undefined') {
    return console.error('adroll is not ready');
  }

  try {
    window.__adroll.record_user({ adroll_segments: id });
  } catch (err) {
    console.error(err);
  }
};
