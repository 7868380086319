import classNames from 'classnames';
import moment from 'moment-timezone';
import { bool, func, object, string } from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { get } from 'lodash';
import {
  Button,
  Explanation,
  FieldSelect,
  FieldTextInput,
  Form,
  InsurancePanelNew,
  NamedLink,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import {
  currentUserCanRequestToBooking,
  currentUserIdentityStatus,
  isChargeDeposit,
  listingIsInstantBooking,
} from '../../util/data';
import { timestampToDate } from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { sendCheckoutSlackNotification } from '../../util/slackNotify';
import { propTypes } from '../../util/types';
import css from '../BookingTimeForm/BookingTimeForm.css';
import EstimatedBreakdownMaybe from '../BookingTimeForm/EstimatedBreakdownMaybe';
import FieldEndDateAndTimeInput from '../BookingTimeForm/FieldEndDateAndTimeInput';
import FieldStartDateAndTimeInput from '../BookingTimeForm/FieldStartDateAndTimeInput';

const IconInfo = ({ fill = '#DA6C6C' }) => {
  return (
    <svg
      className={css.infoIcon}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00014 0.25C11.3209 0.25 13.5467 1.17194 15.1878 2.813C16.8288 4.45406 17.7508 6.67981 17.7508 9.00062C17.7508 11.3214 16.8288 13.5472 15.1878 15.1883C13.5467 16.8293 11.3209 17.7513 9.00014 17.7513C6.67933 17.7513 4.45357 16.8293 2.81251 15.1883C1.17145 13.5472 0.249512 11.3214 0.249512 9.00062C0.249512 6.67981 1.17145 4.45406 2.81251 2.813C4.45357 1.17194 6.67933 0.25 9.00014 0.25ZM10.3126 5.6225C10.9626 5.6225 11.4901 5.17125 11.4901 4.5025C11.4901 3.83375 10.9614 3.3825 10.3126 3.3825C9.66264 3.3825 9.13764 3.83375 9.13764 4.5025C9.13764 5.17125 9.66264 5.6225 10.3126 5.6225ZM10.5414 12.6562C10.5414 12.5225 10.5876 12.175 10.5614 11.9775L9.53389 13.16C9.32139 13.3837 9.05514 13.5387 8.93014 13.4975C8.87343 13.4766 8.82603 13.4362 8.79647 13.3835C8.76691 13.3308 8.75713 13.2693 8.76889 13.21L10.4814 7.8C10.6214 7.11375 10.2364 6.4875 9.42014 6.4075C8.55889 6.4075 7.29139 7.28125 6.52014 8.39C6.52014 8.5225 6.49514 8.8525 6.52139 9.05L7.54764 7.86625C7.76014 7.645 8.00764 7.48875 8.13264 7.53125C8.19422 7.55335 8.24469 7.59872 8.2732 7.65762C8.30171 7.71651 8.306 7.78423 8.28514 7.84625L6.58764 13.23C6.39139 13.86 6.76264 14.4775 7.66264 14.6175C8.98764 14.6175 9.77014 13.765 10.5426 12.6562H10.5414Z"
        fill={fill}
      />
    </svg>
  );
};

export class BookingTimeFormComponent extends PureComponent {
  state = {
    loading: false,
    timeError: {},
  };

  // handleTimeRangeError = ({ values }) => {
  //   if (!isEqual(this.prevValues, values)) {
  //     const { listing, bookingConfig, updateBooking, transaction, localTimeZone } = this.props;
  //     const isInstantBooking = listingIsInstantBooking(listing);
  //     const timeZone =
  //       listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  //     const { bookingStartTime, bookingEndTime } = values;
  //     if (!bookingStartTime || !bookingEndTime) return;
  //     const currentTimeError = this.state.timeError;
  //     const now = moment();
  //     const momentStart = moment(timestampToDate(bookingStartTime), timeZone);
  //     const momentEnd = moment(timestampToDate(bookingEndTime), timeZone);
  //
  //     const diffHours = momentEnd.diff(momentStart, 'hours', true);
  //
  //     const diffDays = sameDay(timestampToDate(bookingStartTime), timestampToDate(bookingEndTime))
  //       ? momentEnd.diff(momentStart, 'days', true)
  //       : Math.ceil(momentEnd.diff(momentStart, 'days', true));
  //
  //
  //     if(updateBooking) {
  //       const bookedStart = transaction && transaction.booking && transaction.booking.attributes.displayStart ? moment(transaction.booking.attributes.displayStart, localTimeZone) : null;
  //       const bookedEnd = transaction && transaction.booking && transaction.booking.attributes.displayEnd ? moment(transaction.booking.attributes.displayEnd, localTimeZone) : null;
  //       const diffBookedHours = bookedEnd.diff(bookedStart, 'hours', true);
  //
  //       currentTimeError[
  //         `Updated time should be longer then booked`
  //         ] = diffHours <= diffBookedHours;
  //     }
  //
  //     if (isInstantBooking && !this.props.isLongTermBooking) {
  //       const hoursToNow = Math.abs(now.diff(momentStart, 'hours', true));
  //       currentTimeError[
  //         `This car must be booked at least ${bookingConfig.paddingHours ||
  //         4} hours prior to the trip start time`
  //         ] =
  //         hoursToNow <
  //         (typeof bookingConfig.paddingHours === 'undefined' ? 4 : parseInt(bookingConfig.paddingHours));
  //
  //       const bookingStartHour = momentStart
  //         .clone()
  //         .tz(timeZone)
  //         .diff(
  //           momentStart
  //             .clone()
  //             .tz(timeZone)
  //             .startOf('day'),
  //           'hours',
  //           true
  //         );
  //
  //       currentTimeError[
  //         `Instant booking cars can not be picked up from 12am to 7am (car's owner timezone)`
  //         ] = ((bookingStartHour >= 0 && bookingStartHour <= 7));
  //
  //       const bookingEndHour = momentEnd
  //         .clone()
  //         .tz(timeZone)
  //         .diff(
  //           momentEnd
  //             .clone()
  //             .tz(timeZone)
  //             .startOf('day'),
  //           'hours',
  //           true
  //         );
  //       currentTimeError[
  //         `Instant booking cars can not be dropped off from 12am to 7am (car's owner timezone)`
  //         ] = ((bookingEndHour >= 0 && bookingEndHour <= 7));
  //     } else {
  //       const hoursToNow = Math.abs(now.diff(momentStart, 'hours', true));
  //       currentTimeError[
  //         `This car must be booked at least ${bookingConfig.paddingHours ||
  //         2} hours prior to the trip start time`
  //         ] =
  //         hoursToNow <
  //         (typeof bookingConfig.paddingHours === 'undefined' ? 2 : parseInt(bookingConfig.paddingHours));
  //     }
  //
  //     if(!updateBooking) {
  //       currentTimeError['Pickup time must be after the current time'] = momentStart.isSameOrBefore(
  //         now
  //       );
  //     }
  //     currentTimeError[
  //       `This car can only be booked for a minimum of ${listing.attributes.publicData.minimumDailyDuration} days `
  //       ] = (listing.attributes.publicData.minimumDailyDuration !==1 && parseInt(diffDays) > 1 && parseInt(diffDays) < listing.attributes.publicData.minimumDailyDuration) ;
  //
  //     if (bookingConfig.type === bookingTypes.HOURLY) {
  //       if (diffHours < bookingConfig.minimum)
  //         currentTimeError[
  //           `Invalid duration time (Equal/longer than ${bookingConfig.minimum} hours)`
  //           ] = true;
  //       else
  //         currentTimeError[
  //           `Invalid duration time (Equal/longer than ${bookingConfig.minimum} hours)`
  //           ] = false;
  //     } else {
  //       if (diffDays < bookingConfig.minimum) {
  //         currentTimeError[
  //           `Invalid duration time (Equal/longer than ${bookingConfig.minimum} days)`
  //           ] = true;
  //       } else {
  //         currentTimeError[
  //           `Invalid duration time (Equal/longer than ${bookingConfig.minimum} days)`
  //           ] = false;
  //       }
  //     }
  //
  //     currentTimeError[`Booking start must be before booking end`] =
  //       bookingStartTime > bookingEndTime;
  //
  //     Object.keys(currentTimeError).forEach(key => {
  //       if (currentTimeError[key] === false) {
  //         delete currentTimeError[key];
  //       }
  //     });
  //
  //     this.prevValues = values;
  //
  //     this.setState(prev => ({
  //       ...prev,
  //       timeError: currentTimeError,
  //     }));
  //   }
  // };

  beyond6Months = day => {
    const limit = moment().add(6, 'months');
    return !limit.isSameOrAfter(day);
  };

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      timeSlots,
      currentUser,
      initialDate,
      initialValues: rawInitialValues,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        rawInitialValues={rawInitialValues}
        currentUser={currentUser}
        unitPrice={unitPrice}
        onSubmit={values => {
          const { discountChoice, ...rest } = values;
          if (!discountChoice || discountChoice !== 'credits') {
            rest.signupCredits = 0;
          }
          rest.voucherCode = this.props.checkedCode;
          if (this.props.listing && this.props.listing.id.uuid && currentUser.id.uuid) {
            sendCheckoutSlackNotification({
              listingId: this.props.listing.id.uuid,
              userId: currentUser.id.uuid,
            });
          }
          this.props.onSubmit(rest);
        }}
        render={fieldRenderProps => {
          const {
            tripDates,
            isSubmitButtonInProgress,
            isSubmitButtonDisable,
            isShowEstimatedBreakdown,
            updateBookingFormError,
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitPrice,
            unitType = 'line-item/units',
            values,
            onFetchTimeSlots,
            timeZone,
            currentUser,
            listing,
            checkCodeInProgress,
            checkedCode,
            onCheckingVoucher,
            checkCodeErorr,
            onResetCode,
            onEstimateBreakdown,
            estimateBreakdownInProgress,
            estimatedTx,
            requestButtonId,
            listingParams,
            onManageDisableScrolling,
            onReadInsurance,
            onOpenRentalAgreement,
            bookingConfig,
            estimateError,
            rawInitialValues,
            localTimeZone,
            isNewCar,
            isLongTerm,
            isLongTermBooking,
            monthlyTimeSlots,
            transaction,
            updateBooking,
            onRequestToUpdateBooking,
            getChildTransactionData,
            location,
            onInitiateSpeculativeUpdateBooking,
            onTripDatesSet,
          } = fieldRenderProps;

          if (!this.mounted) {
            form.batch(() => {
              onTripDatesSet();
              Object.entries(rawInitialValues).forEach(entry => form.change(entry[0], entry[1]));
            });
            this.mounted = true;
          }

          const isInstantBooking = listingIsInstantBooking(listing);

          const { publicData = {} } = listing.attributes;
          const hourlyAvailability = publicData && publicData.hourlyAvailability;

          const requestToBookButtonMessage = isInstantBooking
            ? 'BookingDatesForm.requestToBookInstant'
            : 'BookingDatesForm.requestToBook';

          const {
            guestVerified: verifiedGuest,
            guestUnderVerify: underVerifiedGuest,
          } = currentUserIdentityStatus(currentUser);

          const { discountChoice, signupCredits = 0 } = values;

          const canRequestBooking = currentUserCanRequestToBooking(currentUser);

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;
          const parentTransactionStartDate =
            transaction && transaction.booking && transaction.booking.attributes.displayStart;
          const parentTransactionEndDate = location && location.state && location.state.endDate;
          const currentDiffHours = moment(endDate).diff(moment(startDate), 'hours', true);
          const parentTransactionDiffHours = moment(parentTransactionEndDate).diff(
            moment(parentTransactionStartDate),
            'hours',
            true
          );
          const diffHours = currentDiffHours - parentTransactionDiffHours;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate && Object.entries(this.state.timeError).length === 0
              ? {
                  unitType,
                  unitPrice,
                  startDate,
                  endDate,
                  timeZone,
                  listing,
                  credits: discountChoice === 'credits' ? signupCredits : 0,
                  discount: discountChoice === 'promos' && checkedCode ? checkedCode.discount : 0,
                  isPaidAmount: false,
                  checkedCode,
                }
              : null;

          const userCanRequestBooking = canRequestBooking && startDate && endDate && verifiedGuest;
          // const bookingInfo =
          //   bookingData && currentUser && currentUser.id
          //     ? (() => {
          //       if (!isEqual(this.bookingData, bookingData)) {
          //         if (!isOwnListing) {
          //           const dummyStartDate = new Date(moment(bookingData.startDate).add(9, 'months'));
          //           const dummyEndDate = new Date(moment(bookingData.startDate).add(9, 'months').add(diffHours, 'hours'));
          //
          //           onEstimateBreakdown({
          //             processAlias: updateBooking ? config.updateBookingChargingProcessAlias : config.masterProcessAlias,
          //             bookingStart: updateBooking ?  dummyStartDate : bookingData.startDate,
          //             bookingEnd: updateBooking ?  dummyEndDate : bookingData.endDate,
          //             listing,
          //             currentUser,
          //             credits: bookingData.credits,
          //             discount: bookingData.discount,
          //             isPaidAmount: bookingData.isPaidAmount,
          //             diffHours: diffHours,
          //             checkedCode,
          //           }).then((result) => {
          //             if(result && result.attributes && updateBooking) {
          //               getChildTransactionData({
          //                 lineItems: result.attributes.lineItems,
          //                 bookingStart: bookingData.startDate,
          //                 bookingEnd: bookingData.endDate,
          //                 payinTotal: result.attributes.payinTotal,
          //                 transactionId: result.id.uuid,
          //                 transaction: JSON.stringify(result)
          //               })
          //             }
          //           });
          //         }
          //         this.bookingData = bookingData;
          //       }
          //
          //       return (
          //         <div className={css.priceBreakdownContainer}>
          //           <h3 className={css.priceBreakdownTitle}>
          //             <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
          //           </h3>
          //           <EstimatedBreakdownMaybe
          //             unitType={unitType}
          //             estimating={estimateBreakdownInProgress}
          //             estimatedTx={estimatedTx}
          //           />
          //         </div>
          //       );
          //     })()
          //     : null;

          // const bookingInfo = null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const hasTimeError = Object.keys(this.state.timeError).length > 0 || estimateError;

          const isDeposit = isChargeDeposit(estimatedTx);

          const disableUpdateTripButton =
            rawInitialValues &&
            values &&
            rawInitialValues.bookingEndTime === values.bookingEndTime &&
            rawInitialValues.bookingStartTime === values.bookingStartTime;

          const listingRegularPrice = get(listing, 'attributes.price');
          const listingPeakPrice = get(listing, 'attributes.publicData.pricing.peakPrice');
          const initialTransactionPrice = get(transaction, 'attributes.protectedData.pricing');

          const isPriceChanged =
            (listingPeakPrice &&
              initialTransactionPrice &&
              listingPeakPrice.amount !== initialTransactionPrice.peakPrice.amount * 100) ||
            listingRegularPrice.amount !== initialTransactionPrice.regularPrice.amount * 100;

          const isPriceDecrease =
            estimatedTx &&
            estimatedTx.attributes.payinTotal.amount <
              estimatedTx.attributes.protectedData.initialPayment;

          return (
            <Form onSubmit={handleSubmit} currentUser={currentUser} className={classes}>
              {monthlyTimeSlots && timeZone ? (
                <Fragment>
                  <FormSpy
                    subscription={{ values: true }}
                    currentUser={currentUser}
                    onChange={props => {
                      const { values } = props;
                      // onInitiateSpeculativeUpdateBooking(values);
                      // const { discountChoice, signupCredits } = values;
                      // if (this.prevDiscountChoice !== discountChoice) {
                      //   this.prevDiscountChoice = discountChoice;
                      //   onResetCode();
                      //   form.batch(() => {
                      //     form.change('voucherCode', null);
                      //     form.change('signupCredits', 0);
                      //   });
                      // }
                      // if (discountChoice === 'credits' && !signupCredits) {
                      //   form.batch(() => {
                      //     form.change(
                      //       'signupCredits',
                      //       this.props.currentUser && this.props.currentUser.id ? this.props.currentUser.attributes.credits : 0
                      //     );
                      //   });
                      // }
                    }}
                  />
                  <FieldStartDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : this.beyond6Months}
                    location={location}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                    onInitiateSpeculativeUpdateBooking={onInitiateSpeculativeUpdateBooking}
                  />
                  <FieldEndDateAndTimeInput
                    {...dateInputProps}
                    tripDates={tripDates}
                    className={css.bookingEndDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : this.beyond6Months}
                    location={location}
                    hourlyAvailability={hourlyAvailability}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                    onInitiateSpeculativeUpdateBooking={onInitiateSpeculativeUpdateBooking}
                  />
                  {!isLongTermBooking && !updateBooking && (
                    <div className={css.fieldsPromos}>
                      <FieldSelect
                        labelClassName={css.label}
                        id="discountChoice"
                        name="discountChoice"
                        label={`Promotions & credits`}
                        className={css.checkDiscount}
                        disabled={
                          isNewCar ||
                          estimateBreakdownInProgress ||
                          !startDate ||
                          !endDate ||
                          this.state.loading
                        }
                      >
                        <option value="none">None...</option>
                        <option value="credits">I want to use my available credits</option>
                        <option value="promos">I have a promotional voucher</option>
                      </FieldSelect>
                      {discountChoice && discountChoice === 'credits' && signupCredits <= 0 ? (
                        <div className={css.error}>{'NO Credit balance'}</div>
                      ) : (
                        ''
                      )}
                      {discountChoice === 'promos' && (
                        <React.Fragment>
                          <div className={css.voucherContainer}>
                            <FieldTextInput
                              id="voucherCode"
                              name="voucherCode"
                              className={css.voucherInput}
                              type="text"
                              label="Input your voucher"
                              placeholder="Type your voucher here..."
                              disabled={this.state.loading}
                            />

                            <Button
                              className={css.voucherButton}
                              type="button"
                              inProgress={checkCodeInProgress}
                              disabled={
                                isNewCar || estimateBreakdownInProgress || !values.voucherCode
                              }
                              ready={!!checkedCode}
                              onClick={() => {
                                const { voucherCode, ...rest } = values;
                                const startDate = timestampToDate(rest.bookingStartTime);
                                const endDate = timestampToDate(rest.bookingEndTime);
                                if (!isNewCar) {
                                  onCheckingVoucher({
                                    code: voucherCode,
                                    data: {
                                      startDate,
                                      endDate,
                                    },
                                  });
                                }
                              }}
                            >
                              Check
                            </Button>
                          </div>
                          {checkCodeErorr && (
                            <p className={classNames(css.smallPrintForMember, css.error)}>
                              Invalid voucher code
                            </p>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                  {isNewCar && !isLongTermBooking && (
                    <div className={css.newCarDiscountNotes}>
                      {' '}
                      <FormattedMessage id="BookingDatesForm.newCarDiscountNotes" />{' '}
                    </div>
                  )}

                  {isShowEstimatedBreakdown && !isSubmitButtonDisable && isPriceChanged && (
                    <p className={css.updateBookingSubTitle}>
                      <IconInfo />
                      <FormattedMessage id="BookingTimeForm.priceChangedMessage" />
                    </p>
                  )}

                  {isPriceDecrease && (
                    <p className={css.updateBookingSubTitle}>
                      <IconInfo />
                      <FormattedMessage id="BookingTimeForm.priceDecreasedMessage" />
                    </p>
                  )}

                  {isInstantBooking && (
                    <p className={css.updateBookingInstant}>
                      <IconInfo fill="#000000" />
                      <FormattedMessage id="BookingTimeForm.autoAcceptMessage" />
                    </p>
                  )}

                  {isShowEstimatedBreakdown && !isSubmitButtonDisable && (
                    <div className={css.priceBreakdownContainer}>
                      {/*<h3 className={css.priceBreakdownTitle}>*/}
                      {/*  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />*/}
                      {/*</h3>*/}

                      {/*<p className={css.updateBookingSubTitle2}>*/}
                      {/*  <span className={css.item}>*/}
                      {/*    <FormattedMessage id="BookingTimeForm.modifiedTripText" />*/}
                      {/*  </span>*/}
                      {/*{estimatedTx.attributes.protectedData.calculateAsNewBooking ? (*/}
                      {/*  <FormattedMessage*/}
                      {/*    id={*/}
                      {/*      estimatedTx.attributes.protectedData.hourlyBooking*/}
                      {/*        ? 'BookingTimeForm.tripDurationHourMessage'*/}
                      {/*        : 'BookingTimeForm.tripDurationDayMessage'*/}
                      {/*    }*/}
                      {/*    values={{*/}
                      {/*      count: estimatedTx.attributes.protectedData.hourDuration,*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*) : (*/}
                      {/*  <FormattedMessage*/}
                      {/*    id={*/}
                      {/*      isHourlyBooking*/}
                      {/*        ? 'BookingTimeForm.tripDurationHourMessage'*/}
                      {/*        : 'BookingTimeForm.tripDurationDayMessage'*/}
                      {/*    }*/}
                      {/*    values={{*/}
                      {/*      count: updatedTripDuration,*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*)}*/}
                      {/*</p>*/}

                      {/*{!estimatedTx.attributes.protectedData.calculateAsNewBooking ? (*/}
                      {/*  <p className={css.datesContainer}>*/}
                      {/*    <div className={css.modifyDatesLabel}>*/}
                      {/*      <FormattedMessage id="BookingTimeForm.modifiedTripDates" />*/}
                      {/*    </div>*/}
                      {/*    <div className={css.dates}>*/}
                      {/*      {formattedStart} - {formattedEnd}*/}
                      {/*    </div>*/}
                      {/*  </p>*/}
                      {/*) : null}*/}

                      {/*<TripModificationPeriod transaction={estimatedTx} />*/}

                      {/*<EstimatedBreakdownMaybe*/}
                      {/*  unitType={unitType}*/}
                      {/*  estimating={estimateBreakdownInProgress}*/}
                      {/*  estimatedTx={estimatedTx}*/}
                      {/*  isEditTripPage*/}
                      {/*  onManageDisableScrolling={onManageDisableScrolling}*/}
                      {/*/>*/}
                    </div>
                  )}

                  {Object.keys(this.state.timeError).length ? (
                    <div className={css.error}>
                      {Object.keys(this.state.timeError).join('. \n')}
                    </div>
                  ) : null}

                  {updateBookingFormError && (
                    <div className={css.error}>{updateBookingFormError}</div>
                  )}
                  {updateBooking && (
                    <div className={css.submitSection}>
                      <div className={css.submitSectionInner}>
                        {transaction ? (
                          <div className={css.submitSectionPrice}>
                            {/*<span className={css.totalAmount}>*/}
                            {/*  <LineItemTotalPriceNew*/}
                            {/*    currentUser={currentUser}*/}
                            {/*    transaction={transaction}*/}
                            {/*    isProvider={false}*/}
                            {/*    intl={intl}*/}
                            {/*  />*/}
                            {/*</span>*/}
                            {/*Total Amount*/}
                          </div>
                        ) : null}
                        <div className={css.submitSectionPrice}>
                          <PrimaryButton
                            type="submit"
                            disabled={isSubmitButtonDisable || disableUpdateTripButton}
                            inProgress={isSubmitButtonInProgress}
                            id={requestButtonId}
                          >
                            <FormattedMessage id={requestToBookButtonMessage} />
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  )}
                  {!updateBooking && (
                    <div className={submitButtonClasses}>
                      {isDeposit && !isLongTermBooking ? (
                        <p className={css.smallPrintForDeposit}>
                          A refundable security deposit of $250 is pre-authorized (not charged)
                          <Explanation
                            className={css.explain}
                            textClassName={css.explainText}
                            text="A refundable security deposit of $250 is preauthorized for all guests who have taken less than 3 trips. This is not a CHARGE but only a preauthorization. Once you have completed 3 trips, the security deposit will not be applicable"
                          ></Explanation>
                        </p>
                      ) : null}
                      {startDate && endDate && estimatedTx ? (
                        <p className={css.smallPrintForMember}>
                          <FormattedMessage id="BookingPanel.memberUseDrivelah" />
                        </p>
                      ) : null}

                      {currentUser && currentUser.id ? (
                        <PrimaryButton
                          type="submit"
                          disabled={isSubmitButtonDisable}
                          inProgress={isSubmitButtonInProgress}
                          id={requestButtonId}
                        >
                          <FormattedMessage id={requestToBookButtonMessage} />
                        </PrimaryButton>
                      ) : (
                        <NamedLink
                          name="LoginPage"
                          to={{
                            state: {
                              startDate,
                              endDate,
                              startTime,
                              endTime,
                              params: listingParams,
                              isFromListingPage: true,
                            },
                          }}
                        >
                          <PrimaryButton type="button">
                            <FormattedMessage id={requestToBookButtonMessage} />
                          </PrimaryButton>
                        </NamedLink>
                      )}

                      {!isInstantBooking && (
                        <p className={css.smallPrint}>
                          <FormattedMessage id="BookingPanel.noCharge" />
                        </p>
                      )}

                      {currentUser &&
                        currentUser.id &&
                        !underVerifiedGuest &&
                        !verifiedGuest &&
                        !isOwnListing && (
                          <div className={css.notVerifiedGuestWarning}>
                            <p>
                              <FormattedMessage id="BookingDatesForm.notVerifiedGuestWarning" />
                            </p>
                            <NamedLink name="AccountSettingDriverVerificationPage">
                              <FormattedMessage id="BookingDatesForm.notVerifiedGuestWarningLink" />
                            </NamedLink>
                          </div>
                        )}
                      {currentUser &&
                        currentUser.id &&
                        underVerifiedGuest &&
                        !verifiedGuest &&
                        !isOwnListing && (
                          <div className={css.notVerifiedGuestWarning}>
                            <p>
                              <FormattedMessage id="BookingDatesForm.underVerifiedGuestWarning" />
                            </p>
                            <NamedLink name="AccountSettingDriverVerificationPage">
                              <FormattedMessage id="BookingDatesForm.underVerifiedGuestWarningLink" />
                            </NamedLink>
                          </div>
                        )}
                    </div>
                  )}
                </Fragment>
              ) : null}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {!updateBooking && (
                <InsurancePanelNew
                  listing={listing}
                  showInsurance={true}
                  insuranceType={publicData.insurance}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onReadInsurance={onReadInsurance}
                />
              )}
              {!updateBooking && (
                <div className={css.rentalAgreementLink}>
                  <span onClick={onOpenRentalAgreement}>
                    <FormattedMessage id="BookingDatesForm.rentalAgreementLink" />
                  </span>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  monthlyTimeSlots: null,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  isNewCar: false,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  isLongTerm: bool,
  isLongTermBooking: bool,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
