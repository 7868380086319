exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AddImagesExample_addImageWrapper__2MYF9 {\n  float: left;\n  position: relative;\n  width: 100%;\n  margin: 5px 0;\n  overflow: hidden;\n}\n/* Firefox doesn't support image aspect ratio inside flexbox */\n.AddImagesExample_aspectRatioWrapper__3NIrH {\n  padding-bottom: 60%; /* 5:3 Aspect Ratio = 100% / (w / h) */\n}\n.AddImagesExample_addImage__3dWls {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  background-color: lightgrey;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  margin-top: 0;\n}\n.AddImagesExample_addImageInput__1T6_v {\n  display: none;\n}\n", ""]);

// Exports
exports.locals = {
	"addImageWrapper": "AddImagesExample_addImageWrapper__2MYF9",
	"aspectRatioWrapper": "AddImagesExample_aspectRatioWrapper__3NIrH",
	"addImage": "AddImagesExample_addImage__3dWls",
	"addImageInput": "AddImagesExample_addImageInput__1T6_v"
};