import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { formatMoney } from '../../../util/currency';
import css from '../BookingBreakdown.css';
import config from '../../../config';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemLongTermPaymentMade = ({ transaction, isCustomer, isCancelled, intl }) => {
  if (!isCustomer) {
    return null;
  }

  const payinTotal = get(transaction, 'attributes.payinTotal.amount', 0);
  const longTermPaymentMade = get(transaction, 'attributes.metadata.longTermPaymentMade');
  const commissionObj2Months = get(transaction, 'attributes.protectedData.commissionObj');

  const paymentTwoMonths = isCancelled
    ? commissionObj2Months.total + commissionObj2Months.note.baseTripPrice
    : payinTotal;
  let currentPaymentMade = longTermPaymentMade ? longTermPaymentMade : paymentTwoMonths;
  if (!transaction) {
    return null;
  }

  currentPaymentMade = new Money(currentPaymentMade, config.currency);
  currentPaymentMade = currentPaymentMade ? formatMoney(intl, currentPaymentMade) : null;

  return currentPaymentMade ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="TransactionBreakdownLongTerm.currentPaymentMade" />
      </span>
      <span className={css.itemValue}>{currentPaymentMade}</span>
    </div>
  ) : null;
};

export default LineItemLongTermPaymentMade;
