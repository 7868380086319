import React, { PureComponent } from 'react';
import { array, bool, func, number, object, objectOf, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import merge from 'lodash/merge';
import { propTypes } from '../../util/types';
import { isMobile } from 'react-device-detect';
import { get } from 'lodash';
import {
  BSearchFiltersMobile,
  SearchFilters,
  SearchFiltersPanel,
  SearchResultsPanel,
} from '../../components';
import { validFilterParams, validURLParamsForExtendedData } from './SearchPage.helpers';
import SearchDateTimeFilters from './SearchDateTimeFilters';
import { ensureUser } from '../../util/data';
import css from './SearchPage.css';
import bLandingCss from './SearchPageBLanding.css';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { parse } from '../../util/urlHelpers';

class MainPanel extends PureComponent {
  state = { isSearchFiltersPanelOpen: false };

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      center,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      primaryFilters,
      secondaryFilters,
      isMobileLayout,
      visibilityMap,
      onManageVisibilitySearchMap,
      searchDate,
      location,
      sort,
      onSelectCard,
      searchTimes,
      isSearchingLongTerm,
      isAuthenticated,
      currentUser,
      speculatedTripPricesInProgress,
      speculatedTripPrices,
    } = this.props;
    const { search } = location;
    const { mapSearch, ...searchInURL } = parse(search);

    const ensuredPrimaryFilters = pickBy(primaryFilters, identity);
    const ensuredSecondaryFilters = pickBy(secondaryFilters, identity);
    const isSearchFiltersPanelOpen = !!secondaryFilters && this.state.isSearchFiltersPanelOpen;
    const filters = merge(ensuredPrimaryFilters, ensuredSecondaryFilters);
    const selectedFilters = validFilterParams(urlQueryParams, filters);
    const selectedFiltersCount = Object.keys(selectedFilters).length;
    const isHomeDeliverySelected = 'pub_delivery' in selectedFilters;

    const selectedSecondaryFilters = ensuredSecondaryFilters
      ? validFilterParams(urlQueryParams, ensuredSecondaryFilters)
      : {};
    const searchFiltersPanelSelectedCount = Object.keys(selectedSecondaryFilters).length;

    const searchFiltersPanelProps = !!ensuredSecondaryFilters
      ? {
          isSearchFiltersPanelOpen: this.state.isSearchFiltersPanelOpen,
          searchFiltersPanelSelectedCount,
          toggleSearchFiltersPanel: isOpen => {
            this.setState({ isSearchFiltersPanelOpen: isOpen });
          },
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;
    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    const classes = classNames(
      rootClassName || css.searchResultContainer,
      className,
      (!visibilityMap || validQueryParams.pub_longTermRental) && css.fullScale
    );

    const filterParamNames = Object.values(filters).map(f => f.paramName);
    const secondaryFilterParamNames = ensuredSecondaryFilters
      ? Object.values(ensuredSecondaryFilters).map(f => f.paramName)
      : [];

    const user = ensureUser(currentUser);
    const userTrips = get(
      user,
      'attributes.profile.metadata.intercomUserStat.number_of_trips_success_as_guest'
    );
    return (
      <div className={classes}>
        <div className={css.filterSectionTop}>
          <div className={css.filterSectionLeft}>
            <div className={css.resultsNumbers}>
              {searchInProgress ? (
                <div className={css.loadingResults}>
                  <FormattedMessage id="SearchFilters.loadingResults" />
                </div>
              ) : listingsAreLoaded && totalItems > 0 ? (
                <></>
              ) : (
                `${totalItems} ${totalItems > 1 ? 'Cars' : 'Car'}`
              )}
            </div>

            <div className={css.resultsLocation}>
              {searchInProgress
                ? ''
                : `Cars for rent available in ${
                    mapSearch
                      ? 'Map Location'
                      : searchParamsForPagination && searchParamsForPagination.address
                  }`}
            </div>
          </div>
          <div className={css.filterSectionRight}>
            {(selectedFilters && selectedFilters.pub_longTermRental) ||
            isSearchingLongTerm ? null : (
              <SearchDateTimeFilters
                isMobileLayout={isMobile}
                visibilityMap={visibilityMap}
                onManageVisibilitySearchMap={onManageVisibilitySearchMap}
              />
            )}
          </div>
        </div>
        <SearchFilters
          className={css.searchFilters}
          urlQueryParams={urlQueryParams}
          sort={sort}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          onManageDisableScrolling={onManageDisableScrolling}
          currentUser={currentUser}
          {...searchFiltersPanelProps}
          {...ensuredPrimaryFilters}
        />

        <BSearchFiltersMobile
          className={classNames(
            bLandingCss.searchFiltersMobile,
            bLandingCss.searchFiltersMobileRoot
          )}
          urlQueryParams={urlQueryParams}
          sort={sort}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          filterParamNames={filterParamNames}
          selectedFiltersCount={selectedFiltersCount}
          currentUser={currentUser}
          {...primaryFilters}
          {...ensuredSecondaryFilters}
        />
        {!isMobile && isSearchFiltersPanelOpen && (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersPanel
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              onClosePanel={() => this.setState({ isSearchFiltersPanelOpen: false })}
              filterParamNames={secondaryFilterParamNames}
              {...secondaryFilters}
            />
          </div>
        )}
        {!isSearchFiltersPanelOpen && (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}

            {isHomeDeliverySelected ? (
              <p className={css.homeDeliveryText}>
                {' '}
                <FormattedMessage id="SearchPage.deliveryHomeText" />
              </p>
            ) : null}

            {totalItems ? (
              <div>
                <SearchResultsPanel
                  isMobileLayout={isMobile}
                  onSelectCard={onSelectCard}
                  searchDate={searchDate}
                  className={css.searchListingsPanel}
                  listings={listings}
                  center={center}
                  location={location}
                  resultsCount={totalItems}
                  urlQueryParams={urlQueryParams}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={searchParamsForPagination}
                  setActiveListing={onActivateListing}
                  searchTimes={searchTimes}
                  isSearchingLongTerm={isSearchingLongTerm}
                  visibilityMap={visibilityMap}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                  speculatedTripPricesInProgress={speculatedTripPricesInProgress}
                  speculatedTripPrices={speculatedTripPrices}
                />
              </div>
            ) : (
              !searchInProgress && (
                <div className={css.noSearchResults}>
                  <FormattedMessage id="SearchFilters.noResults" />
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
  onSelectCard: func,
};

export default MainPanel;
