import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionReadyToGo.css';

class SectionReadyToGo extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  render() {
    const { isAuthenticated, onLoginOrSignupClick } = this.props;

    return (
      <div className={css.root}>
        <div className={css.textWrapper}>
          <div className={css.title}>
            <FormattedMessage id="SectionReadyToGo.title" values={{ newline: <br /> }} />
          </div>
          {isAuthenticated && (
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798',
              }}
              className={classNames(css.buttonFindACar)}
            >
              <FormattedMessage id="SectionReadyToGo.findACar" />
            </NamedLink>
          )}
          {!isAuthenticated && (
            <NamedLink
              name="SignupPage"
              className={classNames(css.buttonJoinUs)}
              onClick={onLoginOrSignupClick}
            >
              <FormattedMessage id="SectionReadyToGo.joinUs" />
            </NamedLink>
          )}
        </div>
      </div>
    );
  }
}

SectionReadyToGo.defaultProps = {};

SectionReadyToGo.propTypes = {};

export default SectionReadyToGo;
