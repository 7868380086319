import React, { memo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { CopyToClipboard, SecondaryButton } from '../../components';
import StarPromotionIcon from '../../components/Icons/StarPromotionIcon';
import css from './NewGuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import { useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types } from '../../util/sdkLoader';
import BLandingCss from '../LandingPageNew/LandingPageNew.css';
import CopyClipboard from '../../assets/copy-clipboard.svg';
import LazyImage from '../../components/LazyImage/LazyImage';

const { Money } = types;

const BannerSection = memo(({ signUpCodeAmount, codeType, signUpCode, codeAuthor, ...props }) => {
  const intl = useIntl();
  const amount =
    signUpCodeAmount && formatMoney(intl, new Money(signUpCodeAmount, config.currency));

  const textBasedOnCodeType =
    codeType === 'signUpCode' ? (
      <span>
        Signup & get free credits worth <strong>{amount}</strong>{' '}
      </span>
    ) : codeType === 'flatOffCode' ? (
      amount &&
      signUpCode && (
        <span>
          Get flat <strong>{amount}</strong> off on your first booking! Use {signUpCode} during
          checkout.
        </span>
      )
    ) : codeType === 'percentOffCode' ? (
      amount &&
      signUpCode && (
        <span>
          Get <strong>{signUpCodeAmount / 100}</strong>% off on your first booking! Use {signUpCode}{' '}
          during checkout.
        </span>
      )
    ) : codeType === 'referralCode' ? (
      codeAuthor && (
        <span>
          Referral code from <strong>{codeAuthor}</strong>{' '}
        </span>
      )
    ) : (
      ''
    );

  const promoContainer = textBasedOnCodeType && (
    <div className={css.info}>
      <StarPromotionIcon className={css.startPercentage} />
      <span style={{ marginRight: '5px' }}>{textBasedOnCodeType}</span>
      <LazyImage src={CopyClipboard} alt={'copy'} style={{ marginRight: '5px' }} />
    </div>
  );

  const copyToClipboard =
    (promoContainer && (
      <CopyToClipboard
        text={promoContainer}
        textToCopy={signUpCode}
        hightlightText={'Asd'}
        className={BLandingCss.copyReferralCode}
      />
    )) ||
    '';

  return (
    <>
      <div className={css.bannerSection}>
        <Container>
          <Row>
            <Column large={6} small={12}>
              <div className={css.searchBoxContent}>
                <div className={css.bannerContent}>
                  <h1 className={css.bannerHead}>Travel, Work, Move, Explore</h1>
                  <p className={css.bannerDesc}>
                    Rent from the largest variety of nearby cars, 24x7 at great value.
                  </p>
                </div>
                <div className={css.signupForm}>
                  <FinalForm
                    {...props}
                    render={({ handleSubmit, values }) => {
                      const emailValue = values.email;
                      return (
                        <div className={css.root}>
                          <div>
                            <div>{copyToClipboard}</div>
                          </div>
                          <div className={css.buttonContainer}>
                            <SecondaryButton onClick={handleSubmit} className={css.submitBtn}>
                              Sign up now
                            </SecondaryButton>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </>
  );
});

export default BannerSection;
