import React, { Component } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { debounce, isEqual, unionWith } from 'lodash';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { getPUSHTELLResultPage, getPUSHTELLSearchExperimentPage } from '../../util/emailNotify';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageVisibilitySearchMap,
} from '../../ducks/UI.duck';
import { ModalInMobile, Page, SearchMap } from '../../components';
import { TopbarContainer } from '../../containers';
import {
  initiateEventFromListing,
  initiateEventViewedFromListing,
  initiateExperimentEventFromListing,
  pushGTMSearchUsedMap,
} from '../../util/gtm/gtmHelpers';
import {
  EVENT_SEARCH_EXPERIMENT_A,
  EVENT_SEARCH_EXPERIMENT_B,
  EVENT_SEARCH_PERFORMED,
  EVENT_SEARCH_USED_MAP,
  EVENT_SEARCH_VIEWED_LISTING_GUEST,
  EVENT_VIEWED_PRODUCT,
} from '../../util/gtm/gtmConstants';
import { ensureListing } from '../../util/data';
import {
  getFormattedDateByDates,
  getHourlyFormattedTimes,
  gSend,
  GTAG_ACTIONS,
  sendG4AEvent,
} from '../../util/gtag';

import { searchListings, searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  createSearchResultSchema,
  pickSearchParamsOnly,
  validFilterParams,
  validURLParamsForExtendedData,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';
import moment from 'moment';
import omit from 'lodash/omit';
import { emitter, Experiment, Variant } from '@marvelapp/react-ab-test';
import AlertBox from '../../components/AlertBox/AlertBox';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
// const SEARCH_WITH_MAP_DEBOUNCE = 0;

let EXPERIMENT_DATA = {};

let initialPageData = null;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  fetchListings = async searchParams => {
    await this.props.fetchListings(searchParams);
  };

  async fetchAllListings(distancePassed) {
    // Parsing query parameters
    const { location, history } = this.props;
    const queryParams = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const newVariant = getPUSHTELLResultPage();
    let { sort: sortParameter } = queryParams;
    // let sortQuery = 'pub_isSuperHostSorting,pub_instantBookingSorting,meta_isDrivelahGoSorting';
    // if(!sortParameter) {
    //   sortParameter = 'relevance';
    // }
    // if(sortParameter === 'relevance'){
    //   let exp = getPUSHTELLSearchExperimentPage();
    //   if(exp && exp === EVENT_SEARCH_EXPERIMENT_B) {
    //     sortQuery = '-price,location';
    //   }
    // } else {

    // }

    const {
      page = 1,
      // sort = sortQuery,
      address,
      origin,
      ...rest
    } = queryParams;
    const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
    // const sortRelevanceOption = sort === 'relevance' ? sortQuery : sort;
    const originIsEnabled = newVariant && newVariant === 'ATS-B' && origin ? { origin } : {};

    const distances = [15000];
    // const distances = [5000, 15000, 25000];
    console.log('Dsitance passed', distancePassed, distances, queryParams);
    if (distancePassed) {
      for (const distance of distances) {
        const updatedSearchParams = {
          ...rest,
          ...originMaybe,
          // ...originIsEnabled, Disabling as we can't use origin with sort params, we are already passing bounds for this.
          baseOrigin: origin,
          address,
          distance,
          page,
          // sort: sortRelevanceOption,
          perPage: RESULT_PAGE_SIZE,
          include: ['author', 'images'],
          'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
          'limit.images': 1,
        };
        await this.fetchListings(updatedSearchParams);
      }
    } else {
      const updatedSearchParams = {
        ...rest,
        ...originMaybe,
        ...originIsEnabled,
        baseOrigin: origin,
        address,
        page,
        // sort: sortRelevanceOption,
        perPage: RESULT_PAGE_SIZE,
        include: ['author', 'images'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
      };
      await this.fetchListings(updatedSearchParams);
    }
  }

  componentDidMount() {
    // if(exp && exp === EVENT_SEARCH_EXPERIMENT_A) {
    //   this.fetchAllListings(true);
    // }
    // else {
    //   this.fetchAllListings(false)
    // }
    this.fetchAllListings(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      // let exp = getPUSHTELLSearchExperimentPage();
      // if(exp && exp === EVENT_SEARCH_EXPERIMENT_A) {
      //   this.fetchAllListings(true);
      // }
      // else {
      //   this.fetchAllListings(false)
      // }
      this.fetchAllListings(true);
    }
    const {
      bounds: nextBounds,
      'fields.image': nextFieldsImage,
      'fields.user': nextFieldsUser,
      sort: nextSort,
      'limit.images': nextLimitImage,
      mapSearch: nextMapSearch,
      page: nextPage,
      perPage: nextPerPage,
      'fields.listing': nextFieldsListing,
      include: nextInclude,
      ...nextSearchParams
    } = this.props.searchParams || {};
    const {
      bounds: prevBounds,
      'fields.image': fieldsImage,
      'fields.user': prevFieldsUser,
      sort: prevSort,
      'limit.images': prevLimitImage,
      mapSearch: prevMapSearch,
      page: prevPage,
      perPage: prevPerPage,
      'fields.listing': prevFieldsListing,
      include: prevInclude,
      ...prevSearchParams
    } = prevProps.searchParams || {};
    if (!isEqual(nextSearchParams, prevSearchParams)) {
      initiateEventFromListing({
        props: this.props,
        event: EVENT_SEARCH_PERFORMED,
      });
      const searchParams = this.props.searchParams || {};
      const hours = getHourlyFormattedTimes(searchParams.hours);
      const dates = getFormattedDateByDates(searchParams.dates);
      gSend(GTAG_ACTIONS.ACTION_SEARCH, {
        // TODO: Assign proper details.
        search_location: `${searchParams.address || ''}`,
        search_pickupdate: `${dates.start ? dates.start : ''}`,
        search_pickuptime: `${hours.start ? hours.start : ''}`,
        search_dropoffdate: `${dates.start ? dates.start : ''}`,
        search_dropofftime: `${hours.end ? hours.end : ''}`,
      });
    }
  }

  filters() {
    const {
      categories,
      reviews,
      brandNames,
      carAge,
      carSeats,
      features,
      transmissions,
      typeOfFuel,
      canDriveToMalaysia,
      priceFilterConfig,
      distanceFilterConfig,
      homeDeliveryOptions,
      numberPassengers,
      dateRangeFilterConfig,
      keywordFilterConfig,
      isInstantBookingConfig,
      isPHVFilterOptions,
      drivelahGoOptions,
      superHostOptions,
      newCarOptions,
      disinfectedOptions,
      longTermOptions,
      isSearchingLongTerm,
    } = this.props;

    return {
      categoryFilter: {
        paramName: 'pub_category',
        options: categories,
      },
      reviewsFilter: {
        paramName: 'pub_reviews',
        options: reviews,
      },
      brandNamesFilter: {
        paramName: 'pub_brandName',
        options: brandNames,
      },
      featuresFilter: {
        paramName: 'pub_keyFeatures',
        options: features,
      },
      transmissionsFilter: {
        paramName: 'pub_transmissions',
        options: transmissions,
      },
      typeOfFuelFilter: {
        paramName: 'pub_fuelType',
        options: typeOfFuel,
      },
      carAgeFilter: {
        paramName: 'pub_yearOfManufacture',
        config: carAge,
      },

      carSeatsFilter: {
        paramName: 'pub_peopleNumberMax',
        config: carSeats,
      },
      // canDriveToMalaysiaFilter: {
      //   paramName: 'pub_canDriveToMalaysia',
      //   options: canDriveToMalaysia,
      // },
      distanceFilter: {
        paramName: 'distance',
        config: distanceFilterConfig,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      numberPassengerFilter: {
        paramName: 'pub_peopleNumberMax',
        config: numberPassengers,
      },
      dateRangeFilter: {
        paramName: 'dates',
        config: dateRangeFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
      instantBookingFilter: {
        paramName: 'pub_instantBooking',
        config: isInstantBookingConfig,
        isBoolean: true,
      },
      // phvFilter: {
      //   paramName: 'pub_isPHV',
      //   options: isPHVFilterOptions,
      // },
      drivelahGoFilter: {
        paramName: 'meta_isDrivelahGo',
        options: drivelahGoOptions,
        isBoolean: true,
      },
      homeDeliveryFilter: {
        paramName: 'pub_delivery',
        options: homeDeliveryOptions,
        isBoolean: true,
      },
      // superHostFilter: {
      //   paramName: 'pub_isSuperHost',
      //   options: superHostOptions,
      //   isBoolean: true,
      // },
      // longTermFilter: {
      //   paramName: 'pub_longTermRental',
      //   options: longTermOptions,
      //   isBoolean: true,
      // },
      newCarFilter: {
        paramName: 'meta_isNewCar',
        options: newCarOptions,
        isBoolean: true,
      },
      disinfectedFilter: {
        paramName: 'meta_isDisinfected',
        options: disinfectedOptions,
        isBoolean: true,
      },
      hoursFilter: {
        paramName: 'hours',
        isBoolean: true,
      },
    };
  }

  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, currentUser } = this.props;
      pushGTMSearchUsedMap(currentUser, EVENT_SEARCH_USED_MAP);
      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, hours, timezone, locationName, ...rest } = parse(
        location.search,
        {
          latlng: ['origin'],
          latlngBounds: ['bounds'],
        }
      );

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        origin: viewportCenter,
        hours,
        timezone,
        mapSearch: true,

        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onSelectCard = (selectedListing, isUsedMap) => {
    const currentListing = ensureListing(selectedListing);
    initiateEventFromListing({
      props: this.props,
      listing: currentListing,
      event: EVENT_SEARCH_VIEWED_LISTING_GUEST,
      isHost: false,
    });
    initiateEventFromListing({
      props: this.props,
      listing: currentListing,
      event: EVENT_SEARCH_VIEWED_LISTING_GUEST,
      isHost: false,
    });
    sendG4AEvent(currentListing, GTAG_ACTIONS.ACTION_VIEW_ITEM, this.props);
    initiateEventViewedFromListing({
      props: this.props,
      listing: currentListing,
      event: EVENT_VIEWED_PRODUCT,
      isHost: false,
    });
  };

  handleSearchDaysGreaterThan60Days = (date, isLongTerm = false) => {
    const { location, history } = this.props;
    const { mapSearch, page, sort, ...urlQueryParams } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    this.currentDates = date;
    const [startString, endString] = date.split(',');
    const diff =
      startString &&
      endString &&
      moment(endString, 'YYYY-MM-DD').diff(moment(startString, 'YYYY-MM-DD'), 'days', true);

    if (diff && diff > 60 && !isLongTerm) {
      const queryParams = { ...urlQueryParams, pub_longTermRental: true };

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
      );
    } else if (diff && diff <= 60 && isLongTerm) {
      const queryParams = omit({ ...urlQueryParams }, ['pub_longTermRental']);

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
      );
    }
  };

  render() {
    const {
      intl,
      listings,
      center,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      onManageVisibilitySearchMap,
      visibilityMap,
      isSearchingLongTerm,
      currentUser,
      speculatedTripPricesInProgress,
      speculatedTripPricesError,
      speculatedTripPrices,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    if (!location || !location.search) {
      location.search = '?address=Australia';
    }
    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);
    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const searchTimes = searchInURL && searchInURL.hours ? searchInURL.hours : null;
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    let mapAbSegment = getPUSHTELLResultPage();
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        className={css.page}
      >
        {' '}
        {!mapAbSegment ? (
          <Experiment name="Attractiveness Score Experiment Page">
            <Variant name="ATS-A">
              <TopbarContainer
                className={topbarClasses}
                currentPage="SearchPage"
                currentSearchParams={urlQueryParams}
                mobileClassName={css.mobileTopbarContainer}
                contactUsClassName={css.contactUs}
                isMobileLayout={isMobileLayout}
              />
            </Variant>
            <Variant name="ATS-B">
              <TopbarContainer
                className={topbarClasses}
                currentPage="SearchPage"
                currentSearchParams={urlQueryParams}
                mobileClassName={css.mobileTopbarContainer}
                contactUsClassName={css.contactUs}
                isMobileLayout={isMobileLayout}
              />
            </Variant>
            <Variant name="ATS-D">
              <TopbarContainer
                className={topbarClasses}
                currentPage="SearchPage"
                currentSearchParams={urlQueryParams}
                mobileClassName={css.mobileTopbarContainer}
                contactUsClassName={css.contactUs}
                isMobileLayout={isMobileLayout}
              />
            </Variant>
          </Experiment>
        ) : (
          <TopbarContainer
            className={topbarClasses}
            currentPage="SearchPage"
            currentSearchParams={urlQueryParams}
            mobileClassName={css.mobileTopbarContainer}
            contactUsClassName={css.contactUs}
            isMobileLayout={isMobileLayout}
          />
        )}
        <div className={css.container}>
          {searchInURL && searchInURL.showAlert ? (
            <AlertBox
              title="Location needed"
              message="Please enter the pickup location"
              type="info"
            />
          ) : null}
          <MainPanel
            isSearchingLongTerm={isSearchingLongTerm}
            currentUser={currentUser}
            searchDate={searchInURL && searchInURL.dates ? searchInURL.dates : null}
            searchTimes={searchTimes}
            urlQueryParams={validQueryParams}
            listings={listings}
            center={center}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            primaryFilters={{
              categoryFilter: filters.categoryFilter,
              // distanceFilter: filters.distanceFilter, // Hide addons functionality
              priceFilter: filters.priceFilter,
              // dateRangeFilter: filters.dateRangeFilter,
              drivelahGoFilter: filters.drivelahGoFilter,
              superHostFilter: filters.superHostFilter,
              instantBookingFilter: filters.instantBookingFilter,
              // homeDeliveryFilter: filters.homeDeliveryFilter, // Hide addons functionality
              // instantBookingFilter:
              //   isSearchingLongTerm || validQueryParams.pub_longTermRental
              //     ? null
              //     : filters.instantBookingFilter,
              newCarFilter: filters.newCarFilter,
              disinfectedFilter: filters.disinfectedFilter,
              isPHVFilter: filters.phvFilter,
            }}
            secondaryFilters={{
              categoryFilter: filters.categoryFilter,
              carAgeFilter: filters.carAgeFilter,
              carSeatsFilter: filters.carSeatsFilter,
              // distanceFilter: filters.distanceFilter,
              priceFilter: filters.priceFilter,
              reviewsFilter: filters.reviewsFilter,
              brandNamesFilter: filters.brandNamesFilter,
              featuresFilter: filters.featuresFilter,
              transmissionsFilter: filters.transmissionsFilter,
              typeOfFuelFilter: filters.typeOfFuelFilter,
              canDriveToMalaysiaFilter: filters.canDriveToMalaysiaFilter,
              numberPassengerFilter: filters.numberPassengerFilter,
              keywordFilter: filters.keywordFilter,
              instantBookingFilter: filters.instantBookingFilter,
              isPHVFilter: filters.phvFilter,
              drivelahGoFilter: filters.drivelahGoFilter,
              superHostFilter: filters.superHostFilter,
              // homeDeliveryFilter: filters.homeDeliveryFilter, // Hide addons functionality
            }}
            isMobileLayout={isMobileLayout}
            visibilityMap={visibilityMap}
            onManageVisibilitySearchMap={onManageVisibilitySearchMap}
            location={location}
            sort={sort}
            onSelectCard={this.onSelectCard}
            speculatedTripPricesInProgress={speculatedTripPricesInProgress}
            speculatedTripPrices={speculatedTripPrices}
          />
          {(visibilityMap || isMobileLayout) &&
            !(validQueryParams.pub_longTermRental || isSearchingLongTerm) && (
              <ModalInMobile
                className={css.mapPanel}
                id="SearchPage.map"
                isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.mapWrapper}>
                  {this.state.isSearchMapOpenOnMobile || shouldShowSearchMap ? (
                    <SearchMap
                      searchDate={searchInURL && searchInURL.dates ? searchInURL.dates : null}
                      searchTimes={searchTimes}
                      reusableContainerClassName={css.map}
                      activeListingId={activeListingId}
                      intl={intl}
                      bounds={bounds}
                      center={origin}
                      isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                      location={location}
                      listings={mapListings || []}
                      onMapMoveEnd={this.onMapMoveEnd}
                      onCloseAsModal={() => {
                        onManageDisableScrolling('SearchPage.map', false);
                      }}
                      messages={intl.messages}
                      onSelectCard={this.onSelectCard}
                      currentUser={currentUser}
                    />
                  ) : null}
                </div>
              </ModalInMobile>
            )}
        </div>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  categories: config.custom.categories,
  reviews: config.custom.reviews,
  brandNames: config.custom.brands,
  features: config.custom.keyFeatures.filter(
    f => f.key !== 'air_conditioning' && f.key !== 'sunroof'
  ),
  transmissions: config.custom.transmissions,
  typeOfFuel: config.custom.typeOfFuelFilter,
  canDriveToMalaysia: config.custom.canDriveToMalaysia,
  isInstantBookingConfig: config.custom.isInstantBooking,
  numberPassengers: config.custom.numberPassengerConfig,
  distanceFilterConfig: config.custom.distanceFilterConfig,
  priceFilterConfig: config.custom.priceFilterConfig,
  dateRangeFilterConfig: config.custom.dateRangeFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  isPHVFilterOptions: config.custom.isPHV,
  drivelahGoOptions: config.custom.yesNoFilterOptions,
  superHostOptions: config.custom.yesNoFilterOptions,
  homeDeliveryOptions: config.custom.yesNoFilterOptions,
  carAge: config.custom.carAge,
  carSeats: config.custom.carSeats,
  newCarOptions: config.custom.yesNoFilterOptions,
  disinfectedOptions: config.custom.yesNoFilterOptions,
  activeListingId: null,
  longTermOptions: config.custom.yesNoFilterOptions,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  categories: array,
  reviews: array,
  brandNames: array,
  features: array,
  transmissions: array,
  typeOfFuel: array,
  canDriveToMalaysia: array,
  isInstantBookingConfig: bool.isRequired,
  newCarOptions: bool.isRequired,
  disinfectedOptions: bool.isRequired,
  numberPassengers: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  distanceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeFilterConfig: shape({ active: bool.isRequired }),
  currentUser: propTypes.currentUser,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    isSearchingLongTerm,
    speculatedTripPricesInProgress,
    speculatedTripPricesError,
    speculatedTripPrices,
  } = state.SearchPage;
  const { currentUser } = state.user;

  //Using default bounds if bounds not present
  const bounds = searchParams && searchParams.bounds;

  let center;
  try {
    if (bounds && bounds.ne && bounds.sw) {
      center = {
        lat: (bounds.ne.lat + bounds.sw.lat) / 2,
        lng: (bounds.ne.lng + bounds.sw.lng) / 2,
      };
    }
  } catch (e) {
    console.log('Unable to set bound', e);
  }

  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    currentUser,
    visibilityMap: state.UI.visibilityMap,
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    center,
    activeListingId,
    isSearchingLongTerm,
    speculatedTripPricesInProgress,
    speculatedTripPricesError,
    speculatedTripPrices,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchListings: searchParams => dispatch(searchListings(searchParams)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onManageVisibilitySearchMap: () => dispatch(manageVisibilitySearchMap()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
