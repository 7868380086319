import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './SectionGuideToDisinfectDesktop.css';
import imageSource from '../../util/imageSource';

const GuideDisinfect = () => (
  <div className={css.listStepWrapper}>
    <div className={css.listStep}>
      <img src={imageSource.landingPageCovid.stepClean} className={css.listStepImage} />
      <div className={css.listStepTitle}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.listStepTitle1" />
      </div>
      <div className={css.listStepContent}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.listStepContent1" />
      </div>
    </div>
    <div className={css.listStep}>
      <img src={imageSource.landingPageCovid.stepDisinfect} className={css.listStepImage} />
      <div className={css.listStepTitle}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.listStepTitle2" />
      </div>
      <div className={css.listStepContent}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.listStepContent2" />
      </div>
    </div>
    <div className={css.listStep}>
      <img src={imageSource.landingPageCovid.stepFinish} className={css.listStepImage} />
      <div className={css.listStepTitle}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.listStepTitle3" />
      </div>
      <div className={css.listStepContent}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.listStepContent3" />
      </div>
    </div>
  </div>
);

const RecommendAndTouchpoints = () => (
  <div className={css.recommendAndTouchpoints}>
    <div className={css.recommendWrapper}>
      <div className={css.recommendTitle}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.recommendTitle" />
      </div>
      <div className={css.recommendList}>
        <img src={imageSource.landingPageCovid.iconClean} className={css.listIcon} />
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription1" />
      </div>
      <div className={css.recommendList}>
        <img src={imageSource.landingPageCovid.iconSoap} className={css.listIcon} />
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription2" />
      </div>
      <div className={css.recommendList}>
        <img src={imageSource.landingPageCovid.iconLeather} className={css.listIcon} />
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription3" />
      </div>
      <div className={css.recommendList}>
        <img src={imageSource.landingPageCovid.iconNontoxic} className={css.listIcon} />
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription4" />
      </div>
    </div>
    <div className={css.touchpointsWrapper}>
      <div className={css.touchpointTitle}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointTitle" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription1" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription2" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription3" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription4" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription5" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription6" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription7" />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription8" />
      </div>
    </div>
  </div>
);

const SectionGuideToDisinfectDesktop = () => {
  return (
    <div className={css.SectionGuideToDisinfectDesktop}>
      <div className={css.title}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfectDesktop.title" />
      </div>
      <GuideDisinfect />
      <RecommendAndTouchpoints />
    </div>
  );
};

export default SectionGuideToDisinfectDesktop;
