import React from 'react';
import { bool } from 'prop-types';
import get from 'lodash/get';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { txIsCancelledLTF, txIsDeclinedOrExpiredLTF } from '../../../util/transactionLongTermFirst';
import { txIsCompletedLTL } from '../../../util/transactionLongTermLast';

import css from '../BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemLongTermProviderMake = props => {
  const { transaction, intl } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsCompletedLTL(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclinedOrExpiredLTF(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCancelledLTF(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = <FormattedMessage id={providerTotalMessageId} />;

  const twoMonthsPrice = transaction.attributes.payoutTotal;
  const baseTripPrice = get(
    transaction,
    'attributes.protectedData.entireLongTermTripCommission.note.baseTripPrice',
    0
  );
  const finalTotalPrice = new Money(baseTripPrice, twoMonthsPrice.currency);
  const formattedTotalPrice = formatMoney(intl, finalTotalPrice);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{totalLabel}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemLongTermProviderMake.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemLongTermProviderMake;
