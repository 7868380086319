exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".VerificationStatus_headingContainer__mv1m8 {\n  /* border: solid 2px blue; */\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0px 20px;\n}\n\n.VerificationStatus_separatorHR__3R-9U {\n  border: 1px solid #e6e6e6;\n  width: calc(100% - 40px);\n}\n\n.VerificationStatus_headingContainer__mv1m8 > p {\n  /* border: solid 2px red; */\n  font-size: 18px;\n  color: #026786;\n  font-weight: 700 !important\n}\n\n@media (max-width: 767px) {\n\n.VerificationStatus_headingContainer__mv1m8 > p {\n    font-size: 16px\n}\n  }\n\n.VerificationStatus_headingContainer__mv1m8 > p:first-child {\n  font-size: 24px\n}\n\n@media (max-width: 767px) {\n\n.VerificationStatus_headingContainer__mv1m8 > p:first-child {\n    font-size: 18px\n}\n  }\n\n.VerificationStatus_headingContainer__mv1m8 > p:last-child {\n  background: #e6e6e6;\n  border-radius: 4px;\n  padding: 8px;\n}\n\n.VerificationStatus_detailsContainer__3-Mc5 {\n  padding: 0px 20px;\n}\n\n.VerificationStatus_detailsContainer__3-Mc5 > p {\n  color: #4a4a4a;\n  font-size: 18px;\n  font-weight: 500 !important\n}\n\n@media (max-width: 767px) {\n\n.VerificationStatus_detailsContainer__3-Mc5 > p {\n    font-size: 14px\n}\n  }\n\n.VerificationStatus_listOfVerifiations__2OLVR > p {\n  /* border: solid 2px black; */\n  width: 50%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin: 0;\n  color: #4a4a4a;\n  font-size: 18px;\n  font-weight: 500 !important\n}\n\n@media (max-width: 767px) {\n\n.VerificationStatus_listOfVerifiations__2OLVR > p {\n    font-size: 16px;\n    width: 80%\n}\n  }\n\n.VerificationStatus_listOfVerifiations__2OLVR > p > span:nth-child(2) {\n  margin-left: 5%;\n}\n\n.VerificationStatus_listOfVerifiations__2OLVR > p > span:nth-child(3) {\n  margin-left: auto;\n}\n", ""]);

// Exports
exports.locals = {
	"headingContainer": "VerificationStatus_headingContainer__mv1m8",
	"separatorHR": "VerificationStatus_separatorHR__3R-9U",
	"detailsContainer": "VerificationStatus_detailsContainer__3-Mc5",
	"listOfVerifiations": "VerificationStatus_listOfVerifiations__2OLVR"
};