import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  FieldTextInput,
  Form,
  InsurancePlusSign,
  NamedLink,
  PrimaryButton,
} from '../../components/index';
import { Form as FinalForm } from 'react-final-form';

import css from './SectionHeroSearchBox.css';
import imageSource from '../../util/imageSource';

class FormCampaignComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            titleId,
            subtitleId,
            applyInProgress,
            applyError,
            applySuccess,
            intl,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.heroSearchBox}>
                <h1 className={css.heroMainTitle}>
                  <FormattedMessage id={titleId} />
                </h1>
                <div className={css.heroSubtitle}>
                  <FormattedMessage id={subtitleId} values={{ plus: <InsurancePlusSign /> }} />
                </div>

                <FieldTextInput
                  className={css.emailField}
                  id="email"
                  name="email"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'FormCampaign.placeholderEmail' })}
                />

                <PrimaryButton
                  className={css.campaignSubmitBtn}
                  inProgress={applyInProgress}
                  ready={applySuccess}
                  disabled={applyInProgress}
                  type={'submit'}
                >
                  <FormattedMessage id="FormCampaign.joinButton" />
                </PrimaryButton>

                {applyError && (
                  <div className={css.submitWarningCenter}>
                    <FormattedMessage id={applyError} />
                  </div>
                )}

                <div className={css.insuranceParrner}>
                  <div className={css.insuranceText}>
                    <FormattedMessage id="FormCampaign.insurancePartner" />
                  </div>
                  <NamedLink name="TrustAndSafetyPage" className={css.insuranceLogo}>
                    <img className={css.insuranceLogoImg} src={imageSource.general.insuranceLogo} />
                  </NamedLink>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

FormCampaignComponent.defaultProps = { inProgress: false };

const { bool, string } = PropTypes;

FormCampaignComponent.propTypes = {
  inProgress: bool,
  intl: intlShape.isRequired,
  applyInProgress: bool,
  applyError: string,
};

const FormCampaign = compose(injectIntl)(FormCampaignComponent);
FormCampaign.displayName = 'FormCampaign';

export default FormCampaign;
