import React from 'react';
import { useInView } from 'react-intersection-observer';
import css from './LazyImage.css';

const LazyImage = ({ src, alt, srcSet, className, imgHeight, imgWidth, objectFit, containerHeight }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} style={{ height: containerHeight || '100%' }}>
      {inView ? (
        <img className={className} srcSet={srcSet} src={src} alt={alt} style={{ height: imgHeight || '100%', width: imgWidth || '100%', objectFit: objectFit || 'cover' }} />
      ) : (
        <div className={css.loaderContainer}>
          <div className={css.loader}></div>
        </div>
      )}
    </div>
  );
};

export default LazyImage;