import React, { useEffect, useRef, useState } from 'react';
import styles from './LandingPageTestimonials.module.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../LazyImage/LazyImage';

const LandingPageTestimonials = () => {

  const scrollContainerRef = useRef(null);
  const [scrollPostion, setScrollPosition] = useState('left');

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 0; // Scroll to the beginning
    }
  }, []);

  const onScrollHandler = () => {
    if(scrollContainerRef.current.scrollLeft === 0) setScrollPosition('left');

    if((scrollContainerRef.current.scrollLeft > 0) && (scrollContainerRef.current.scrollLeft < scrollContainerRef.current.scrollLeftMax)) setScrollPosition('');

    if(scrollContainerRef.current.scrollLeft === scrollContainerRef.current.scrollLeftMax) setScrollPosition('right');
  }

  const onRightArrowClick = () => {
    console.log('I am right arrow click');
    scrollContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    
  }

  const onLeftArrowClick = () => {
    console.log('I am left arrow click');
    scrollContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    
  }

  const testimonialCollection = [
    {
      customerName: 'Noah Harris',
      customerImg: imageSource.testimonialImages.noahHaris,
      rating: 5,
      review: "So, I tried this for the first time. I gotta admit, I was apprehensive at the start. But the folks on the chat were lightning fast with their help, Great experience will use again.",
    },
    {
      customerName: 'Olivia Johnson',
      customerImg: imageSource.testimonialImages.oliviaJohnson,
      rating: 5,
      review: "Highly recommend giving this app a shot, folks! It's a breeze to use. 👍👍",
    },
    {
      customerName: 'James A',
      customerImg: imageSource.testimonialImages.jamesA,
      rating: 5,
      review: "Great platform and great host, Charles. Super helpful. Huge shoutout and thanks for letting me borrow your wheels! ❤️❤️",
    },
    {
      customerName: 'Mia Smith',
      customerImg: imageSource.testimonialImages.miaSmith,
      rating: 5,
      review: "Easy platform for renting cars! Will recommend it, thanks.",
    },
    {
      customerName: 'Charlotte Walker',
      customerImg: imageSource.testimonialImages.charlotteWalker,
      rating: 5,
      review: "Super efficient, super reliable.",
    },
    {
      customerName: 'Michael Tan',
      customerImg: imageSource.testimonialImages.michaelTan,
      rating: 5,
      review: "Quick support,  Great platform - will recommend to my friends for sure",
    },
    {
      customerName: 'Emily  Taylor',
      customerImg: imageSource.testimonialImages.emilyTaylor,
      rating: 5,
      review: "Drive mate is really efficient. It's easy to rent a car even at the last minute.",
    },
    {
      customerName: 'Natasha Lim',
      customerImg: imageSource.testimonialImages.natashaLim,
      rating: 5,
      review: "Drive is my go-to for weekend car rentals! Their app makes booking super easy, and their customer service is always top-notch. Highly recommended!",
    }

  ];

  const testimonialList = testimonialCollection.map((ele, index) => (
    <div key={index} className={styles.testimonialBox} >
      <div className={styles.customerImage}>
        <LazyImage src={ele.customerImg} alt="customer image" />
      </div>
      <div className={styles.testimonialData}>
        <p>{ele.review}</p>
        <p>{ele.customerName}</p>
        <p>{'⭐'.repeat(ele.rating)}</p>
      </div>
    </div>
  ))

  const generateGridItems = (array, startIndex, endIndex) => {
    if (startIndex < 0 || endIndex >= array.length || startIndex > endIndex) {
      throw new Error('Invalid startIndex or endIndex');
    }
    const selectedArray = array.slice(startIndex, endIndex + 1);

    const selectedArrayList = selectedArray.map((ele, index) => (
      <div key={index} className={styles.gridItem}>
        <div className={styles.testimonialBox} >
          <div className={styles.customerImage}>
            <LazyImage src={ele.customerImg} alt="customer image" />
          </div>
          <div className={styles.testimonialData}>
            <p>{ele.review}</p>
            <p>{ele.customerName}</p>
            <p>{'⭐'.repeat(ele.rating)}</p>
          </div>
        </div>
      </div>
    ));

    return selectedArrayList;
  }

  return (
    <>
      <div className={styles.headingContainer}>
        <p>
          #LoveDrivemate
        </p>
        <p>Stories from our guest, they are sharing their car on Drive mate</p>
      </div>
      <section className={styles.slider}>
        <div className={styles.testimonialSlider}>
          <div>
            {testimonialList}
          </div>
          <div>
            {testimonialList}
          </div>
        </div>
      </section>
      <div className={styles.testimonialGridContainer}>
        <div className={styles.gridArrows}>
          <div onClick={onLeftArrowClick} className={`${scrollPostion === 'left' ? styles.hidden : ''}`}>&#171;</div>
          <div onClick={onRightArrowClick} className={`${scrollPostion === 'right' ? styles.hidden : ''}`}>&#187;</div>
        </div>
        <div className={styles.testimonialGrid} ref={scrollContainerRef} onScroll={onScrollHandler}>
          <div className={styles.gridContainer}>
            {generateGridItems(testimonialCollection, 0, 2)}
          </div>
          <div className={styles.gridContainer}>
            {generateGridItems(testimonialCollection, 3, 5)}
          </div>
          <div className={styles.gridContainer}>
            {generateGridItems(testimonialCollection, 6, 7)}
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageTestimonials;