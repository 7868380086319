/*
 * Marketplace specific configuration.
 */
import React from 'react';
import { FormattedMessage } from './util/reactIntl';

export const defaultTitle = 'Toyota Corolla - 2018';
export const defaultDescription = '';

export const yesNo = [
  {
    key: 'yes',
    label: 'Yes',
  },
  {
    key: 'no',
    label: 'No',
  },
];

export const privateInsurerList = [
  {
    key: 'tokio',
    label: 'TOKIO',
  },
  {
    key: 'ntuc',
    label: 'NTUC',
  },
];

export const insurances = [
  {
    key: 'private',
    label: 'Private',
  },
  {
    key: 'commercial',
    label: 'Commercial',
  },
];

export const keyRules = [
  {
    key: 'No Pets',
    label: 'No Pets',
  },
  {
    key: 'Not suitable for kids',
    label: 'Not suitable for kids',
  },
  {
    key: 'No smoking',
    label: 'No smoking',
  },
];

export const keyFeatures = [
  {
    key: 'air_conditioning',
    label: 'Air conditioning',
  },
  {
    key: 'all_wheel_drive',
    label: 'All wheel drive',
  },
  {
    key: 'android_auto',
    label: 'Android Auto',
  },
  {
    key: 'apply_carplay',
    label: 'Apple CarPlay',
  },
  {
    key: 'aux_input',
    label: 'Aux Input',
  },
  {
    key: 'bike_rack',
    label: 'Bike Rack',
  },
  {
    key: 'bluetooth',
    label: 'Bluetooth',
  },
  {
    key: 'child_seat',
    label: 'Child seat',
  },
  {
    key: 'cruise_control',
    label: 'Cruise control',
  },
  {
    key: 'foldable_backseat',
    label: 'Foldable Backseat',
  },
  {
    key: 'gps',
    label: 'GPS',
  },
  // {
  //   key: 'pet_friendly',
  //   label: 'Pet friendly',
  // },
  {
    key: 'rear_camera',
    label: 'Rear Camera',
  },
  {
    key: 'roof_rack',
    label: 'Roof Rack',
  },
  {
    key: 'sunroof',
    label: 'Sunroof',
  },
  {
    key: 'usb_charger',
    label: 'USB Charger',
  },
  {
    key: 'usb_input',
    label: 'USB Input',
  },
];

export const transmissions = [
  {
    key: 'automatic',
    label: 'Automatic',
  },
  {
    key: 'manual',
    label: 'Manual',
  },
];

export const typeOfFuel = [
  {
    key: 'diesel',
    label: 'Diesel',
  },
  {
    key: 'petrol',
    label: 'Petrol',
  },
  {
    key: 'electric',
    label: 'Electric',
  },
  {
    key: 'E10_UnleadedPetrol',
    label: 'E10 Unleaded Petrol',
  },
  {
    key: 'premiumUnleaded_95Petrol',
    label: 'Premium Unleaded 95 Petrol',
  },
  {
    key: 'premiumUnleaded_98Petrol',
    label: 'Premium Unleaded 98 Petrol',
  },
  {
    key: 'regularUnleadedPetrol',
    label: 'Regular Unleaded Petrol',
  },
  {
    key: 'lpg',
    label: 'LPG',
  },
  {
    key: 'hybrid',
    label: 'Hybrid',
  },
];

export const typeOfFuelFilter = [
  {
    key: 'diesel',
    label: 'Diesel',
  },
  {
    key: 'electric',
    label: 'Electric',
  },
  {
    key: 'petrol',
    label: 'Petrol',
  },
  {
    key: 'lpg',
    label: 'LPG',
  },
  {
    key: 'hybrid',
    label: 'Hybrid',
  },
];

export const petrolFuelTypes = typeOfFuel.slice(0, 3);

export const fuelTypes = typeOfFuel;

export const canDriveToMalaysia = [
  {
    key: 'true',
    label: 'Yes',
  },
];

export const isInstantBooking = [
  {
    key: 'true',
    label: 'Yes',
  },
];

export const isPHV = [
  {
    key: 'true',
    label: 'Yes',
  },
  {
    key: 'false',
    label: 'No',
  },
];

export const categories = [
  // { key: 'compact_suv', label: 'Compact SUV' },
  // { key: 'full_size_suv', label: 'Full-Size SUV' },
  // { key: 'light', label: 'Light' },
  // { key: 'mid_large', label: 'Mid-Large' },
  // { key: 'mid_size_suv', label: 'Mid-Size SUV' },
  // { key: 'moving_van', label: 'Moving Van' },
  // { key: 'people_mover', label: 'People Mover' },
  // { key: 'premium_large', label: 'Premium large' },
  // { key: 'premium_mid', label: 'Premium mid' },
  // { key: 'premium_small', label: 'Premium small' },
  // { key: 'small_mid', label: 'Small-Mid' },
  { key: 'small_car', label: 'Small Car' },
  { key: 'medium_car', label: 'Medium Car' },
  { key: 'large_car', label: 'Large Car' },
  { key: 'suv', label: 'SUV' },
  { key: 'people_mover', label: 'People Mover' },
  { key: 'van', label: 'Van' },
  { key: 'ute', label: 'UTE' },
  // { key: 'standard_sedan', label: 'Standard Sedan' },
  // { key: 'standard_suv', label: 'Standard SUV' },
  // { key: 'luxury_sedan', label: 'Luxury Sedan' },
  // { key: 'luxury_suv', label: 'Luxury SUV' },
  // { key: 'supercar', label: 'Supercar' },
  // { key: 'convertible', label: 'Convertible' },
  // { key: 'van', label: 'Van' },
  // { key: 'ute', label: 'Ute' },
];

export const cities = [
  {
    key: 'Australia/Sydney',
    label: 'Sydney',
    enabled: true,
    bounds: {
      northeast: { latitude: -33.8231409, longitude: 151.4930209 },
      southwest: { latitude: -34.0231409, longitude: 151.1930209 },
      string: `-33.8231409,151.4930209,-34.0231409,151.1930209`, // combined bounds
    },
  },
  {
    key: 'Australia/Melbourne',
    label: 'Melbourne',
    enabled: true,
    bounds: {
      northeast: { latitude: -37.8415, longitude: 145.2275 },
      southwest: { latitude: -38.0415, longitude: 144.9275 },
      string: `-37.8415,145.2275,-38.0415,144.9275`, // combined bounds
    },
  },
  {
    key: 'Australia/Brisbane',
    label: 'Brisbane',
    enabled: true,
    bounds: {
      northeast: { latitude: -27.357, longitude: 153.251 },
      southwest: { latitude: -27.557, longitude: 152.951 },
      string: `-27.357,153.251,-27.557,152.951`, // combined bounds
    },
  },
  {
    key: 'Australia/Cairns',
    label: 'Cairns',
    enabled: true,
    bounds: {
      northeast: { latitude: -16.821, longitude: 146.071 },
      southwest: { latitude: -17.021, longitude: 145.771 },
      string: `-16.821,146.071,-17.021,145.771`, // combined bounds
    },
  },
  {
    key: 'Australia/Perth',
    label: 'Perth',
    enabled: true,
    bounds: {
      northeast: { latitude: -31.889, longitude: 116.201 },
      southwest: { latitude: -32.089, longitude: 115.901 },
      string: `-31.889,116.201,-32.089,115.901`, // combined bounds
    },
  },
  {
    key: 'Australia/Adelaide',
    label: 'Adelaide',
    enabled: true,
    bounds: {
      northeast: { latitude: -34.722, longitude: 138.753 },
      southwest: { latitude: -34.922, longitude: 138.453 },
      string: `-34.722,138.753,-34.922,138.453`, // combined bounds
    },
  },
];

export const brands = [
  {
    label: 'Alfa Romeo',
    key: 'alfa_romeo',
  },
  {
    label: 'Audi',
    key: 'audi',
  },
  {
    label: 'BMW',
    key: 'bmw',
  },
  {
    label: 'Chery',
    key: 'chery',
  },
  {
    label: 'Chrysler',
    key: 'chrysler',
  },
  {
    label: 'Citroën',
    key: 'citroen',
  },
  {
    label: 'Daewoo',
    key: 'daewoo',
  },
  {
    label: 'Daihatsu',
    key: 'daihatsu',
  },
  {
    label: 'Dodge',
    key: 'dodge',
  },
  {
    label: 'Fiat',
    key: 'fiat',
  },
  {
    label: 'Ford',
    key: 'ford',
  },
  {
    label: 'Great Wall',
    key: 'great wall',
  },
  {
    label: 'Haval',
    key: 'haval',
  },
  {
    label: 'Holden',
    key: 'holden',
  },
  {
    label: 'Honda',
    key: 'honda',
  },
  {
    label: 'Hyundai',
    key: 'hyundai',
  },
  {
    label: 'Infiniti',
    key: 'infiniti',
  },
  {
    label: 'Isuzu',
    key: 'isuzu',
  },
  {
    label: 'Jaguar',
    key: 'jaguar',
  },
  {
    label: 'Jeep',
    key: 'jeep',
  },
  {
    label: 'Kia',
    key: 'kia',
  },
  {
    label: 'Land Rover',
    key: 'land_rover',
  },
  {
    label: 'Ldv',
    key: 'ldv',
  },
  {
    label: 'Lexus',
    key: 'lexus',
  },
  {
    label: 'Mazda',
    key: 'mazda',
  },
  {
    label: 'Mercedes Benz',
    key: 'mercedes-benz',
  },
  {
    label: 'Mg',
    key: 'mg',
  },
  {
    label: 'Mini',
    key: 'mini',
  },
  {
    label: 'Mitsubishi',
    key: 'mitsubishi',
  },
  {
    label: 'Nissan',
    key: 'nissan',
  },
  {
    label: 'Peugeot',
    key: 'peugeot',
  },
  {
    label: 'Renault',
    key: 'renault',
  },
  {
    label: 'Saab',
    key: 'saab',
  },
  {
    label: 'Skoda',
    key: 'skoda',
  },
  {
    label: 'Smart',
    key: 'smart',
  },
  {
    label: 'Ssangyong',
    key: 'ssanyong',
  },
  {
    label: 'Subaru',
    key: 'subaru',
  },
  {
    label: 'Suzuki',
    key: 'suzuki',
  },
  {
    label: 'Toyota',
    key: 'toyota',
  },
  {
    label: 'Volkswagen',
    key: 'volkswagen',
  },
  {
    label: 'Volvo',
    key: 'volvo',
  },
];

export const drivenKm = [
  { key: 'zero_to_ten', label: '0-10,000 kms' },
  { key: 'ten_to_thirty', label: '10 – 30,000 kms' },
  { key: 'thiry_to_sixty', label: '30 – 60,000 kms' },
  { key: 'sixty_to_ninety', label: '60 – 90,000 kms' },
  { key: 'ninety_one_thirty', label: '90 – 130,000 kms' },
  { key: 'more_than_thirty', label: '130 - 200,000 kms' },
  { key: 'more_than_two_hundred', label: '200,000 kms+' },
];

export const phoneCode = [
  { key: '+65', label: 'Singapore (+65)', cc: 'SG' }, // Already present
  { key: '+84', label: 'Vietnam (+84)', cc: 'VN' },
  { key: '+48', label: 'Poland (+48)', cc: 'PL' },
  { key: '+44', label: 'United Kingdom (+44)', cc: 'GB' }, // Updated from 'England' to 'United Kingdom'
  { key: '+1', label: 'United States (+1)', cc: 'US' }, // Added
  { key: '+64', label: 'New Zealand (+64)', cc: 'NZ' }, // Added
  { key: '+61', label: 'Australia (+61)', cc: 'AU' }, // Already present
];

export const roles = [
  { key: 'renter', label: 'Rent a car' },
  { key: 'host', label: 'Share your car' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 1,
};

export const flexPlusPriceFilterConfig = {
  min: 0,
  max: 1000,
  step: 1,
};

const timeSlots = Array.apply(null, { length: 48 })
  .map(Function.call, Number)
  .map(x => {
    let hour = parseInt(x / 2);
    let prefix = hour > 11 ? 'pm' : 'am';
    hour = hour > 12 ? hour - 12 : hour;
    let min = (x % 2) * 30;
    let value = `${(hour < 10 ? '0' : '') + hour}:${(min < 10 ? '0' : '') + min} ${prefix}`;
    return {
      key: value,
      label: value,
      hour: prefix === 'am' ? hour : hour === 12 ? hour : hour + 12,
      minutes: min,
    };
  });

export const timeSetInput = timeSlots;
// Distance filter configuration
export const distanceFilterConfig = {
  min: 0,
  max: 25,
  step: 1,
};

export const numberPassengerConfig = {
  min: 1,
  max: 8,
  step: 1,
};
export const carAge = {
  min: 2000,
  max: 2030,
};
export const carSeats = {
  min: 1,
  max: 20,
};
export const reviews = [
  { key: '2', label: '2 stars' },
  { key: '3', label: '3 stars' },
  { key: '4', label: '4 stars' },
  { key: '5', label: '5 stars' },
];

export const genders = [
  { key: 'male', label: 'Male' },
  { key: 'female', label: 'Female' },
];

export const tabs = [
  // {
  //   text: <FormattedMessage id="AccountSettingPage.contactDetailsTabTitle" />,
  //   selected: false,
  //   linkProps: {
  //     name: 'ContactDetailsPage',
  //   },
  // },
  // {
  //   text: <FormattedMessage id="AccountSettingPage.personalDetailsTabTitle" />,
  //   selected: false,
  //   linkProps: {
  //     name: 'PersonalDetailsPage',
  //   },
  // },
  {
    text: 'Personal Details',
    selected: false,
    linkProps: {
      name: 'AddressDetailsPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.passwordTabTitle" />,
    selected: false,
    linkProps: {
      name: 'PasswordChangePage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.driverVerificationTabTitle" />,
    selected: false,
    linkProps: {
      name: 'AccountSettingDriverVerificationPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.paymentsTabTitle" />,
    selected: false,
    linkProps: {
      name: 'PaymentMethodsPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.refundableDepositTitle" />,
    selected: false,
    linkProps: {
      name: 'RefundableDepositPage',
    },
  },
  {
    text: <FormattedMessage id="StripePayoutPage.heading" />,
    selected: false,
    linkProps: {
      name: 'StripePayoutPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.syncCalenderTabTitle" />,
    selected: false,
    linkProps: {
      name: 'SyncCalenderPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.credits" />,
    selected: false,
    linkProps: {
      name: 'CreditsDetailsPage',
    },
  },
  {
    text: <FormattedMessage id="AccountSettingPage.monthlyStatements" />,
    selected: false,
    linkProps: {
      name: 'MonthlyStatementPage',
    },
  },
];

export const bankCodeReferences = [
  // { label: 'POSB', key: '7171', description: '' },
  { label: 'Maybank', key: '7302', description: '' },
  { label: 'POSB / DBS', key: '7171', description: '' },
  { label: 'UOB', key: '7375', description: '' },
  // { label: 'UOB (for Van accounts only)', key: '7375', description: '' },
  { label: 'FEB', key: '7199', description: '' },
  { label: 'OCBC', key: '7339', description: '' },
  { label: 'HSBC Corp', key: '7232', description: '' },
  { label: 'HSBC Personal', key: '9548', description: '' },
  { label: 'SBI', key: '7991', description: '' },
  { label: 'Standard Chartered', key: '7144', description: '' },
  { label: 'Bangkok Bank Public Company Ltd.', key: '7047', description: '' },
  { label: 'P.T. Bank Negara Indonesia (Persero) Tbk', key: '7056', description: '' },
  { label: 'Bank of America, NA', key: '7065', description: '' },
  { label: 'Bank of China Limited', key: '7083', description: '' },
  { label: 'The Bank of East Asia Ltd', key: '7092', description: '' },
  { label: 'Bank of India Ltd.', key: '7108', description: '' },
  { label: 'Singapore Island Bank Ltd.', key: '7117', description: '' },
  { label: 'JP Morgan Chase', key: '7153', description: '' },
  { label: 'Citibank', key: '7214', description: '' },
  { label: 'Indian Bank', key: '7241', description: '' },
  { label: 'Indian Overseas Bank', key: '7250', description: '' },
  { label: 'Malayan Banking Berhad', key: '7302', description: '' },
  { label: 'CTBC Bank', key: '9353', description: '' },
  { label: 'ICICI Bank Ltd.', key: '9186', description: '' },
  { label: 'Industrial & Commercial Bank of China', key: '8712', description: '' },
  { label: 'Commerzbank AG', key: '8606', description: '' },
  { label: 'Skandinaviska Enskilda Banken AB (publ)', key: '8527', description: '' },
  { label: 'Nordea Bank Finland Plc', key: '8518', description: '' },
  { label: 'Svenska Handelsbanken AB (publ)', key: '8493', description: '' },
  { label: 'Intesa Sanpaolo S.P.A.', key: '8350', description: '' },
  { label: 'National Australia Bank Ltd', key: '8077', description: '' },
  { label: 'CIMB Bank Berhad', key: '7986', description: '' },
  { label: 'Australia & New Zealand Banking Group Ltd', key: '7931', description: '' },
  { label: 'UCO Bank', key: '7357', description: '' },
  { label: 'RHB Bank Berhad', key: '7366', description: '' },
  { label: 'BNP Paribas', key: '7418', description: '' },
  { label: 'Test', key: '1100', description: '' },
];

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

export const hostCancelReasons = [
  { key: 'need_car_on_book_day', label: 'I need the car on the day of the booking' },
  { key: 'not_interested_sharing', label: 'I am no longer interested in sharing my car' },
  { key: 'accept_by_accident', label: 'I accepted the booking by accident' },
  { key: 'uncomfortable_with_guest', label: 'I am uncomfortable with the guest' },
  { key: 'other', label: 'Other' },
];

export const guestCancelReasons = [
  { key: 'do_not_need_car_anymore', label: 'I no longer need a car for rental' },
  { key: 'change_travel_plan', label: 'My travel plans have changed' },
  { key: 'book_by_accident', label: 'I made the booking by accident' },
  { key: 'uncomfortable_with_host', label: 'I am uncomfortable with the host' },
  { key: 'other', label: 'Other' },
];

export const guestWithdrawnReasons = [
  { key: 'do_not_need_car_anymore', label: 'I no longer need a car for rental' },
  { key: 'change_travel_plan', label: 'My travel plans have changed' },
  { key: 'book_by_accident', label: 'I made the booking by accident' },
  { key: 'uncomfortable_with_host', label: 'I am uncomfortable with the host' },
  { key: 'my_host_too_long', label: 'I waited for too long for the host to confirm' },
];

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const daysOfWeek = [
  { key: 'sun', label: 'Sun' },
  { key: 'mon', label: 'Mon' },
  { key: 'tue', label: 'Tue' },
  { key: 'wed', label: 'Wed' },
  { key: 'thu', label: 'Thu' },
  { key: 'fri', label: 'Fri' },
  { key: 'sat', label: 'Sat' },
];

export const yesNoFilterOptions = [
  {
    key: true,
    label: 'Yes',
  },
  {
    key: false,
    label: 'No',
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Internal key for the relevance option, see notes below.
  // relevanceKey: 'relevance',

  options: [
    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'location', label: 'Distance to me' },
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // { key: 'createdAt', label: 'Newest' },
    // { key: '-createdAt', label: 'Oldest' },
    { key: 'pub_numberTripDone', label: 'Most rented' },
  ],
};

export const guestApplicableCredits = 2000; // in sub-unit

export const PROCESSING_FEE_PERCENTAGE = 0.04;

export const DEFAULT_MILEAGE_ALLOWED_PER_DAY = 150;

export const priceRange = [
  { key: '0,5000', label: '<$50' },
  { key: '4999,7501', label: '$50-$75' },
  { key: '7499,10001', label: '$75-$100' },
  { key: '9999,15001', label: '$100-$150' },
  { key: '14999,', label: '$150+' },
];

export const flexPlusPriceRange = [
  { key: '10000,20000', label: '$100-$200' },
  { key: '20001,30000', label: '$200-$300' },
  { key: '30001,40000', label: '$300-$400' },
  { key: '40001,', label: '$400+' },
];

export const states = [
  { key: 'newSouthWales', label: 'New South Wales' },
  { key: 'victoria', label: 'Victoria' },
  { key: 'northernTerritory', label: 'Northern Territory' },
  { key: 'queensland', label: 'Queensland' },
  { key: 'tasmania', label: 'Tasmania' },
  { key: 'westernAustralia', label: 'Western Australia' },
  { key: 'southAustralia', label: 'South Australia' },
  { key: 'australianCapitalTerritory', label: 'Australian Capital Territory' },
];

export const parkingType = [
  { key: 'street', label: 'Street parking' },
  { key: 'dedicated', label: 'Dedicated parking' },
];

export const engineStartType = [
  { key: 'crank', label: 'Crank start' },
  { key: 'push', label: 'Push start' },
];

export const millagePerDayOptions = [
  { key: '150km', label: '150km' },
  { key: '250km', label: '250km' },
  { key: 'Unlimited', label: 'Unlimited' },
];

export const typeOfBusiness = [
  { key: 'individual', label: 'Individual' },
  { key: 'company', label: 'Company' },
];

export const availableTimezones = [
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
];
