import React from 'react';
import { bool } from 'prop-types';
import get from 'lodash/get';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from '../BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemLongTermCancellationFineHost = props => {
  const { transaction, intl, isProvider } = props;
  let cancellationFine = get(transaction, 'attributes.metadata.penaltyData.penaltyAmount', 0);

  if (!isProvider || !cancellationFine) {
    return null;
  }

  const cancellationFineLabel = (
    <FormattedMessage id={'BookingBreakdownLongTerm.providerCancellationFineLabel'} />
  );

  const twoMonthsPrice = transaction.attributes.payoutTotal;
  cancellationFine = new Money(cancellationFine, twoMonthsPrice.currency);
  const formattedTotalPrice = formatMoney(intl, cancellationFine);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{cancellationFineLabel}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemLongTermCancellationFineHost.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemLongTermCancellationFineHost;
