import React, { useRef, useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import {
  Button,
  FieldDateInput,
  FieldSelect,
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import css from './SearchForm.css';
import moment from 'moment';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { autocompleteSearchRequired, required } from '../../util/validators';
import config from '../../config';
import { sameDay } from '../../util/dates';

const generateTimeOptions = (isToday, limitTime = null) => {
  const now = new Date();
  const currentMinutes = isToday ? now.getHours() * 60 + now.getMinutes() : -1;
  const limitMoment = limitTime ? moment(limitTime, 'hh:mm a') : null;
  const currentTime = limitMoment ? limitMoment.hours() * 60 + limitMoment.minutes() : -1;
  const mustBeLargerThanValue = Math.max(currentMinutes, currentTime);
  return config.custom.timeSetInput.filter(t => t.hour * 60 + t.minutes > mustBeLargerThanValue);
};

const checkValidSearchDate = ({ pickUp, pickupTime, dropOff, dropOffTime }) => {
  if (pickUp && pickupTime && dropOff && dropOffTime) {
    const momentPickup = moment(pickupTime, 'hh:mm a');
    const momentDropOff = moment(dropOffTime, 'hh:mm a');
    const start = moment(pickUp.date)
      .startOf('date')
      .hour(momentPickup.hour())
      .minutes(momentPickup.minutes)
      .toDate();
    const end = moment(dropOff.date)
      .startOf('date')
      .hour(momentDropOff.hours())
      .minutes(momentDropOff.minutes())
      .toDate();
    if (end.getTime() > start.getTime()) return undefined;

    return 'Return date must be after Pick-up date';
  }
  return undefined;
};

const SearchForm = props => {
  const [startTimeSet, setStartTimeSet] = useState([]);
  const [endTimeSet, setEndTimeSet] = useState([]);

  const prevValues = useRef(null);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { form, className, handleSubmit, values, valid, invalid } = formRenderProps;
        const error = valid ? checkValidSearchDate(values) : undefined;
        return (
          <Form className={classNames(css.root, className)} onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true, active: true }}
              onChange={({ values, active }) => {
                if (isEqual(prevValues.current, values)) return;
                prevValues.current = values;

                if (active === 'pickUp') {
                  const newEndDate = moment(values.pickUp.date)
                    .add(1, 'day')
                    .toDate();
                  if (!values.dropOff) {
                    form.batch(() => {
                      form.change('dropOff', {
                        date: newEndDate,
                      });
                    });
                  }
                  const _startTimeSet = generateTimeOptions(
                    sameDay(values.pickUp.date, new Date())
                  );
                  setStartTimeSet(_startTimeSet);
                  const _endTimeSet = generateTimeOptions(sameDay(newEndDate, new Date()));
                  setEndTimeSet(_endTimeSet);
                  if (!values.pickupTime) {
                    const canSet10AM = _startTimeSet.find(t => t.key === '10:00 am');
                    form.batch(() =>
                      form.change(
                        'pickupTime',
                        canSet10AM ? '10:00 am' : _startTimeSet[0] ? _startTimeSet[0].key : null
                      )
                    );
                  } else {
                    const currentPickupTimeIsInStartTimeSet = _startTimeSet.find(
                      item => item.key === values.pickupTime
                    );
                    if (!currentPickupTimeIsInStartTimeSet) {
                      const canSet10AM = _startTimeSet.find(t => t.key === '10:00 am');
                      form.batch(() =>
                        form.change(
                          'pickupTime',
                          canSet10AM ? '10:00 am' : _startTimeSet[0] ? _startTimeSet[0].key : null
                        )
                      );
                    }
                  }
                  if (!values.dropOffTime) {
                    const _endTimeSet = generateTimeOptions(sameDay(newEndDate, new Date()));
                    const canSet10AMDropOff =
                      _endTimeSet.find(t => t.key === '10:00 am') && !values.dropOffTime;
                    form.batch(() =>
                      form.change(
                        'dropOffTime',
                        canSet10AMDropOff ? '10:00 am' : _endTimeSet[0] ? _endTimeSet[0].key : null
                      )
                    );
                  }
                } else if (active === 'dropOff') {
                  const endLimit =
                    values.pickUp && sameDay(values.pickUp.date, values.dropOff.date)
                      ? values.pickupTime
                      : null;
                  const _endTimeSet = generateTimeOptions(
                    sameDay(values.dropOff.date, new Date()),
                    endLimit
                  );
                  setEndTimeSet(_endTimeSet);
                  const canSet10AMDropOff = _endTimeSet.find(t => t.key === '10:00 am');
                  form.batch(() =>
                    form.change(
                      'dropOffTime',
                      canSet10AMDropOff ? '10:00 am' : _endTimeSet[0] ? _endTimeSet[0].key : null
                    )
                  );
                }
              }}
            />

            <div className={css.wrapper}>
              <div className={css.desktopText}>
                {
                  'With Drive mate, you can rent from a huge variety of\nnearby cars at great value. Fully insured+.'
                }
              </div>
              <div className={css.fields}>
                <div className={css.fieldLocation}>
                  <LocationAutocompleteInputField
                    name="search"
                    label="Where"
                    className={css.locationSearch}
                    labelClassName={css.label}
                    iconClassName={css.icon}
                    inputClassName={css.inputClassName}
                    validationClassName={css.locationValidation}
                    placeholder="Nearby"
                    validate={autocompleteSearchRequired('Please select the location')}
                  />
                </div>
                <div className={css.row}>
                  <div className={css.fieldDates}>
                    <div className={css.field} style={{ zIndex: 6 }}>
                      <FieldDateInput
                        useMobileMargins={false}
                        className={classNames(css.dateInput, css.pickUp)}
                        name="pickUp"
                        label="Pick-up"
                        id="pickUp"
                        labelClassName={css.label}
                        placeholderText={moment().format('DD/MM/YYYY')}
                        validate={required('Please select date')}
                      />
                    </div>
                    <div className={css.field}>
                      <FieldSelect
                        useMobileMargins={false}
                        className={classNames(css.dateInput, css.dropOff)}
                        name="pickupTime"
                        label=""
                        id="pickupTime"
                        disabled={!values.pickUp}
                        labelClassName={css.label}
                        validate={required('Please select time')}
                        placeholderText={'12:00 am'}
                      >
                        <option disabled value="">
                          10:00 am
                        </option>
                        {startTimeSet.map(op => (
                          <option value={op.key} key={`pick_up_${op.key}`}>
                            {op.label}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  </div>
                  <div className={css.fieldDates}>
                    <div className={css.field} style={{ zIndex: 5 }}>
                      <FieldDateInput
                        useMobileMargins={false}
                        className={classNames(css.dateInput, css.pickUp)}
                        name="dropOff"
                        label="Return"
                        id="dropOff"
                        labelClassName={css.label}
                        placeholderText={moment().format('DD/MM/YYYY')}
                        validate={required('Please select date')}
                      />
                    </div>
                    <div className={css.field}>
                      <FieldSelect
                        disabled={!values.dropOff}
                        useMobileMargins={false}
                        className={classNames(css.dateInput, css.dropOff)}
                        name="dropOffTime"
                        label=""
                        id="dropOffTime"
                        labelClassName={css.label}
                        validate={required('Please select time')}
                        placeholder={'12:00 am'}
                      >
                        <option disabled value="">
                          10:00 am
                        </option>
                        {endTimeSet.map(op => (
                          <option value={op.key} key={`drop_op_${op.key}`}>
                            {op.label}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  </div>
                </div>
                {error ? <div className={css.errorDesktop}>{error}</div> : null}
              </div>
            </div>

            <div className={css.buttonWrapper}>
              <div className={css.help}>{'Need help?\nCall us at +61341602205'}</div>
              {error ? <div className={css.errorMobile}>{error}</div> : null}
              <Button className={css.findYourCar} type="submit" disabled={error || invalid}>
                Find your car
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default SearchForm;
