import React from 'react';
import css from './DonationPage.css';
import imageSource from '../../util/imageSource';

const DonationStorySection = () => {
  return (
    <div className={css.storiesPartnersSection}>
      <div className={css.storiesSection}>
        <div className={css.landingContainer}>
          <div className={css.landingRow}>
            <div className={css.column12}>
              <h3>Our story</h3>
            </div>
          </div>
          <div className={css.landingRow}>
            <div className={css.column6}>
              <p>
                We started Drive mate three years back with the vision of bringing real people
                together to help each other share cars. We strongly believe in the power of the
                community/ sharing and what it can do to solve some of the most acute problems
                around us. The spirit of togetherness is fortunately a driving force in our growing
                community in both Singapore and Australia
              </p>
            </div>
            <div className={css.column6}>
              <p>
                Today, our men, women and children in Ukraine face an unprecedented crisis. We at
                Drive mate have decided to bring together our community to help the people of
                Ukraine. We also have friends and team members working from Kyiv, and hearing what
                they go through, goes through marrow and bone. We are committed to helping out all
                Ukrainians in any possible way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={css.partnersSection}>
        <div className={css.landingContainer}>
          <div className={css.landingRow}>
            <div className={css.column12}>
              <h3>Our partners</h3>
            </div>
          </div>
          <div className={css.partnersList}>
            <div className={css.partnersLogo}>
              <img src={imageSource.donationPage.partner1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationStorySection;
