import React from 'react';
import { compose } from 'redux';
import ModalMissingPostalCodeHeader from '../Icons/ModalMissingPostalCodeHeader';
import css from './ModalMissingPostalCode.css';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Button, FieldNumberInput, Form } from '../../components';
import * as validators from '../../util/validators';

const ModalMissingPostalCodeContent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { handleSubmit, isExecutionInProgress } = formRenderProps;
        const inputLabel = props.intl.formatMessage({
          id: 'ModalMissingPostalCode.inputLabel',
        });
        const inputPlaceholder = props.intl.formatMessage({
          id: 'ModalMissingPostalCode.inputPlaceholder',
        });
        const required = validators.minLength(
          props.intl.formatMessage({ id: 'ModalMissingPostalCode.doRequired' }),
          validators.POSTAL_CODE_MIN_LENGTH
        );
        return (
          <>
            <div className={props.className}>
              <div className={css.header}>
                <ModalMissingPostalCodeHeader className={css.headerBackground} />
              </div>
              <div className={css.innerContainer}>
                <h1 className={css.title}>
                  <FormattedMessage id="ModalMissingPostalCode.title" />
                </h1>
                <p className={css.description}>
                  <FormattedMessage id="ModalMissingPostalCode.description" />
                </p>
                <Form onSubmit={handleSubmit} className={css.form}>
                  <label className={css.inputLabel}>{inputLabel}</label>
                  <div className={css.postalCodeWrapper}>
                    <FieldNumberInput
                      inputClassName={css.input}
                      name="postalCode"
                      id="postalCode"
                      placeholder={inputPlaceholder}
                      validate={required}
                    />
                  </div>

                  <div className={css.buttonContainer}>
                    <Button type="submit" disabled={isExecutionInProgress} className={css.button}>
                      {isExecutionInProgress ? (
                        <FormattedMessage id="ModalMissingPostalCode.submitting" />
                      ) : (
                        <FormattedMessage id="ModalMissingPostalCode.submit" />
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

ModalMissingPostalCodeContent.defaultProps = {};

ModalMissingPostalCodeContent.propTypes = {};

const ModalMissingPostalCodeContentComponent = compose(injectIntl)(ModalMissingPostalCodeContent);
export default ModalMissingPostalCodeContentComponent;
