import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../components';
import css from './CovidPage.css';
import imageSource from '../../util/imageSource';

export const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/PKvBBk5ChWY"
      ></iframe>
    </div>
  );
};

const SectionGuideToDisinfect = props => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={css.sectionGuideToDisinfect}>
      <div className={css.title}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfect.title" />
      </div>
      <div className={css.listFeature}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfect.step1" />
      </div>
      <div className={css.listFeature}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfect.step2" />
      </div>
      <div className={css.listFeature}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfect.step3" />
      </div>
      <div className={css.listFeature}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfect.step4" />
      </div>
      <div className={css.listFeature}>
        <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfect.step5" />
      </div>
      <div className={css.viroQProduct}>
        <div className={css.viroQProductTitle}>
          <FormattedMessage id="LandingPageCovid.SectionGuideToDisinfect.viroQProductTitle" />
        </div>
        <div className={css.videoWrapper}>
          <div onClick={() => setIsOpen(true)} className={css.video}>
            <img className={css.videoLogo} src={imageSource.landingPageCovid.viroQVideoThumbnail} />
          </div>
        </div>
      </div>
      <Modal
        id="videoLandingpage"
        {...props}
        containerClassName={css.modalContainer}
        closeButtonClassName={css.modalCloseButton}
        contentClassName={css.contentModal}
        isOpen={isOpen}
        onManageDisableScrolling={() => {}}
        onClose={() => {
          const videoIframe = document.querySelector('#videoIframe');
          if (videoIframe) {
            const iframeSrc = videoIframe.src;
            videoIframe.src = iframeSrc;
          }
          setIsOpen(false);
        }}
      >
        <ModalVideo />
      </Modal>
    </div>
  );
};

export default SectionGuideToDisinfect;
