import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import {
  FieldBirthdayMobileInput,
  FieldCustomNumberInput,
  FieldPhoneNumberInput,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
  PrimaryButton,
} from '../../components';
import {
  ageAtLeast,
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../util/validators';
import { onHandleAutocompleteExperiment } from '../../util/helpers';

import css from './AddressDetailsForm.css';
import ContactDetailsForm from '../ContactDetailsForm/ContactDetailsForm';

class AddressDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
    this.state = { isPlaceSelected: false };

    const locationValue =
      (props.initialValues &&
        props.initialValues.location &&
        props.initialValues.location.selectedPlace &&
        props.initialValues.location.selectedPlace.address) ||
      null;
    this.autoCompleteLocationRef = React.createRef(locationValue);
  }

  fillUpFormBasedOnLocation = (location, form) => {
    const keepLocation = this.autoCompleteLocationRef.current;
    const selectedPlace = get(location, 'selectedPlace');
    if (!selectedPlace) {
      return null;
    }

    const address = get(location, 'selectedPlace.address');
    if (!address) {
      return null;
    }
    this.setState(prevState => ({ isPlaceSelected: true }));
    const isChanged = !isEqual(keepLocation, address);
    if (isChanged) {
      const addressComponents = get(selectedPlace, 'place.address_components', []);
      const postalCode = addressComponents.find(c => c.types.includes('postal_code'));
      const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'));
      const city = addressComponents.find(
        c => c.types.includes('administrative_area_level_2') || c.types.includes('colloquial_area')
      );
      const suburb = addressComponents.find(c => c.types.includes('locality'));
      form.batch(() => {
        postalCode && form.change('postalCode', postalCode.short_name);
        state && form.change('state', camelCase(state.long_name));
        city && form.change('city', city.short_name);
        suburb && form.change('suburb', suburb.short_name);
      });
      this.autoCompleteLocationRef.current = address;
    }
    return null;
  };

  render() {
    const { currentUser } = this.props;
    const isPlaceSelected = this.state.isPlaceSelected;

    return (
      <FinalForm
        {...this.props}
        initialValues={{ ...(this.props.initialValues || {}), ...this.submittedValues }}
        render={fieldRenderProps => {
          const {
            form,
            rootClassName,
            className,
            handleSubmit,
            formId,
            intl,
            inProgress,
            invalid,
            values,
            initialValues,
            saveAddressDetailsError,
            ready,
            shouldHideSubmitButton,
            pristine,
            isFromTripPage,
            pageName,
            isVerification,
            modifyStep,
            handleSteps,
            step,
            isVerificationPage,
            setSubmitRef,
            contactDetailsFormSubmitRef,
            dispatchingContactForm,
          } = fieldRenderProps;
          const user = ensureCurrentUser(currentUser);

          const currentEmail = user.attributes.email || '';
          const protectedData = user.attributes.profile.protectedData || {};
          const currentPhoneNumber = protectedData.phoneObj ? protectedData.phoneObj.rawNumber : '';
          const currentPhoneCode = protectedData.phoneObj ? protectedData.phoneObj.phoneCode : '';

          const onContactdetailsChange = () => {
            console.log('I am contact details change >>');
          };

          if (!user.id) {
            return null;
          }

          React.useEffect(() => {
            modifyStep && handleSteps(step, 'Address');
          }, []);

          const classes = classNames(rootClassName || css.root, className, css.tripPageRoot);

          const buildingMessage = intl.formatMessage({ id: 'EditListingLocationForm.building' });
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          const cityMessage = intl.formatMessage({ id: 'EditListingLocationForm.city' });
          const postalCodeMessage = intl.formatMessage({
            id: 'EditListingLocationForm.postalCode',
          });
          const cityPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityPlaceholder',
          });
          const postalCodePlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.postalCodePlaceholder',
          });

          const countryMessage = intl.formatMessage({ id: 'EditListingLocationForm.country' });
          const countryPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.countryPlaceholder',
          });
          const stateMessage = intl.formatMessage({ id: 'EditListingLocationForm.state' });
          const statePlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.statePlaceholder',
          });

          const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
          const streetPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.streetNamePlaceholder',
          });

          const blockMessage = intl.formatMessage({ id: 'EditListingLocationForm.blockNo' });
          const blockPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.blockNoPlaceholder',
          });

          const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.unitNumber' });
          const floorPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.floorPlaceholder',
          });

          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const {
            blockNo,
            location: streetName,
            building,
            floorUnit,
            city,
            suburb,
            country,
            postalCode,
            dateOfBirth,
          } = values;

          const streetNumberLabel = (
            <div className={css.modifiedStreetLabel}>
              {intl.formatMessage({ id: 'EditListingLocationForm.streetNumber' })}
              {isPlaceSelected && <span>Filling this field is mandatory</span>}
            </div>
          );
          // const streetNumberLabel = intl.formatMessage({ id: 'EditListingLocationForm.streetNumber' });
          const streetNumberPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.streetNumberPlaceholder',
          });
          const streetNumberRequired = intl.formatMessage({
            id: 'EditListingLocationForm.streetNumberRequired',
          });

          const suburbLabel = intl.formatMessage({ id: 'EditListingLocationForm.suburb' });
          const suburbPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.suburbPlaceholder',
          });
          const suburbRequired = intl.formatMessage({
            id: 'EditListingLocationForm.suburbRequired',
          });

          const blockNoRequiredMsg = intl.formatMessage({
            id: 'AddressDetailsForm.blockNoRequired',
          });
          const buildingRequiredMsg = intl.formatMessage({
            id: 'AddressDetailsForm.buildingRequired',
          });
          const floorUnitRequiredMsg = intl.formatMessage({
            id: 'AddressDetailsForm.floorUnitRequired',
          });
          const cityRequiredMsg = intl.formatMessage({ id: 'AddressDetailsForm.cityRequired' });
          const stateRequiredMsg = intl.formatMessage({ id: 'AddressDetailsForm.stateRequired' });
          const postalCodeRequiredMsg = intl.formatMessage({
            id: 'AddressDetailsForm.postalCodeRequired',
          });
          const countryRequiredMsg = intl.formatMessage({
            id: 'AddressDetailsForm.countryRequired',
          });

          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled = invalid || pristineSinceLastSubmit || inProgress || pristine;

          const submitReady = ready;
          const submitInProgress = inProgress;

          const genericFailure = saveAddressDetailsError ? (
            <span className={css.error}>
              <FormattedMessage id="AddressDetailsForm.genericFailure" />
            </span>
          ) : null;

          const guidance = (
            <div className={css.verificationIndication}>
              <p>
                <FormattedMessage id="addressVerificationForm.guide" />
              </p>
            </div>
          );

          const required2 = required(intl.formatMessage({ id: 'SignupForm.dobRequired' }));

          const minAge = 21;

          const minAgeMessage = intl.formatMessage({ id: 'SignupForm.minAgeRequired' }, { minAge });

          const minAgeRequired = ageAtLeast(minAgeMessage, minAge);
          setSubmitRef.current = form.submit;

          return (
            <>
              {!isVerificationPage && (
                <ContactDetailsForm
                  initialValues={{
                    email: currentEmail,
                    phoneNumber: currentPhoneNumber,
                    phoneCode: currentPhoneCode,
                  }}
                  currentUser={currentUser}
                  contactDetailsFormSubmitRef={contactDetailsFormSubmitRef}
                  onSubmit={values => {
                    console.log('I am submit Contact Details Form');
                    dispatchingContactForm({
                      ...values,
                      currentEmail,
                      currentPhoneNumber,
                      currentPhoneCode,
                    });
                    // onSubmitContactDetails({ ...values, currentEmail, currentPhoneNumber, currentPhoneCode })
                  }}
                  onChange={onContactdetailsChange}
                />
              )}
              <Form
                className={classes}
                onSubmit={e => {
                  this.submittedValues = values;
                  handleSubmit(e);
                }}
              >
                {isVerification && guidance}
                <div className={css.displayInlineContainer}>
                  <Field
                    name="location"
                    render={({ input, meta }) => {
                      const { onChange, ...restInput } = input;

                      // Merge the standard onChange function with custom behaviur. A better solution would
                      // be to use the FormSpy component from Final Form and pass this.onChange to the
                      // onChange prop but that breaks due to insufficient subscription handling.
                      // See: https://github.com/final-form/react-final-form/issues/159
                      const searchOnChange = value => {
                        this.fillUpFormBasedOnLocation(value, form);
                        onChange(value);
                      };

                      const searchInput = { ...restInput, onChange: searchOnChange };
                      return (
                        <LocationAutocompleteInputField
                          input={searchInput}
                          rootClassName={css.streetName}
                          className={classNames(css.newInput, css.streetName)}
                          inputClassName={css.locationAutocompleteInput}
                          iconClassName={css.locationAutocompleteInputIcon}
                          predictionsClassName={css.predictionsRoot}
                          validClassName={css.validLocation}
                          validationClassName={css.validationClassName}
                          name="location"
                          label={streetMessage}
                          placeholder={streetPlaceholderMessage}
                          useDefaultPredictions={false}
                          format={v => v}
                          valueFromForm={values.location}
                          validate={composeValidators(
                            autocompleteSearchRequired(addressRequiredMessage),
                            autocompletePlaceSelected(addressNotRecognizedMessage)
                          )}
                          disabled={shouldHideSubmitButton}
                          meta={meta}
                          focusInput={onHandleAutocompleteExperiment}
                        />
                      );
                    }}
                  />
                </div>

                <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                  <FieldCustomNumberInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="streetNumber"
                    id="streetNumber"
                    label={streetNumberLabel}
                    placeholder={streetNumberPlaceholder}
                    disabled={shouldHideSubmitButton}
                    validate={composeValidators(required(streetNumberRequired))}
                  />
                  <FieldTextInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="suburb"
                    id="suburb"
                    label={suburbLabel}
                    placeholder={suburbPlaceholder}
                    readOnly={true}
                    validate={composeValidators(required(suburbRequired))}
                    disabled={shouldHideSubmitButton}
                  />
                </div>
                <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                  <FieldCustomNumberInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="unitNumber"
                    id="unitNumber"
                    label={floorMessage}
                    placeholder={floorPlaceholderMessage}
                    // disabled={shouldHideSubmitButton}
                  />
                  <FieldTextInput
                    className={classNames(css.floor, css.newInput)}
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    label={postalCodeMessage}
                    placeholder={postalCodePlaceholderMessage}
                    validate={required(postalCodeRequiredMsg)}
                    disabled={shouldHideSubmitButton}
                    errorClassName={css.errorClassName}
                  />
                </div>

                <div className={classNames(css.displayInlineContainer, css.halfInputs)}>
                  <FieldTextInput
                    className={classNames(css.city, css.newInput)}
                    type="text"
                    name="city"
                    id="city"
                    label={cityMessage}
                    placeholder={cityPlaceholderMessage}
                    validate={required(cityRequiredMsg)}
                    disabled={shouldHideSubmitButton}
                    errorClassName={css.errorClassName}
                  />
                  <FieldTextInput
                    className={classNames(css.country, css.newInput)}
                    type="text"
                    name="state"
                    id="state"
                    label={stateMessage}
                    placeholder={statePlaceholderMessage}
                    validate={required(stateRequiredMsg)}
                    disabled={shouldHideSubmitButton}
                    errorClassName={css.errorClassName}
                  />
                </div>
                <div className={css.dobLabelStyling}>
                  <FormattedMessage id="PersonalDetailsPage.dob" />
                </div>
                <FieldBirthdayMobileInput
                  className={css.birthDate}
                  initialBirthDate={
                    dateOfBirth && dateOfBirth.day && dateOfBirth.month && dateOfBirth.year
                      ? dateOfBirth
                      : null
                  }
                  id={`dateOfBirth`}
                  name="dateOfBirth"
                  disabled={
                    dateOfBirth && dateOfBirth.day && dateOfBirth.month && dateOfBirth.year
                      ? true
                      : false
                  }
                  label="Date of birth"
                  format={v => v}
                  externalCursor={
                    dateOfBirth && dateOfBirth.day && dateOfBirth.month && dateOfBirth.year
                      ? 'not-allowed'
                      : null
                  }
                  valueFromForm={dateOfBirth}
                  validate={composeValidators(required2, minAgeRequired)}
                />
                {!isFromTripPage && !isVerification ? (
                  <p className={css.readMore}>
                    <FormattedMessage id="AddressDetailsForm.readMore" />
                  </p>
                ) : null}
                {pageName === 'verificationPage' ? (
                  <div style={{ display: 'none' }} className={css.stickyButtons}>
                    <div className={css.stickButtonsContainer}>
                      <div className={css.stickButtonsDescription}>
                        {/* <span className={css.descIcon}>
                    <img src={meterIcon} alt="" />
                    <img
                      src={meterNeedle}
                      alt=""
                      style={{ transform: meterDisplay }}
                      className={css.needle}
                    />
                  </span>
                  <p>
                    <strong>Good Demand:</strong> Based on your settings you are on your way to
                    attract good level of demand.
                  </p> */}
                      </div>
                      <div className={css.stickButton}>
                        <PrimaryButton
                          className={css.submitButton}
                          type="submit"
                          inProgress={submitInProgress}
                          ready={submitReady}
                          disabled={submitDisabled}
                        >
                          <FormattedMessage id="AddressDetailsForm.saveAddress" />
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  !shouldHideSubmitButton && (
                    <div
                      style={{ display: 'none' }}
                      className={
                        isVerification ? css.submitVerificationButtonWrapper : css.bottomWrapper
                      }
                    >
                      {genericFailure}
                      <PrimaryButton
                        type="submit"
                        inProgress={submitInProgress}
                        ready={submitReady}
                        disabled={submitDisabled}
                      >
                        <FormattedMessage id="AddressDetailsForm.saveAddress" />
                      </PrimaryButton>
                    </div>
                  )
                )}
              </Form>
            </>
          );
        }}
      />
    );
  }
}

AddressDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

const { bool, func, string } = PropTypes;

AddressDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  intl: intlShape.isRequired,
};

const AddressDetailsForm = compose(injectIntl)(AddressDetailsFormComponent);

AddressDetailsForm.displayName = 'AddressDetailsForm';

export default AddressDetailsForm;
