import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { txIsCanceledByCustomer } from '../../util/transaction';
import { LINE_ITEM_IS_DELIVERY, LINE_ITEM_IS_DELIVERY_FEE } from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';

const LineItemDeliveryRefaundMaybe = ({ transaction, isProvider, isCustomer, intl }) => {
  let deliveryLineItem = null;

  if (isProvider) {
    deliveryLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_DELIVERY_FEE && item.reversal
    );
  } else if (isCustomer) {
    deliveryLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_DELIVERY && item.reversal
    );
  }

  if (!deliveryLineItem || deliveryLineItem.length === 0 || txIsCanceledByCustomer(transaction)) {
    return null;
  }

  const commission = deliveryLineItem.lineTotal;

  const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

  return formattedCommission ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.deliveryRefund" />
        <Explanation text="Refund Credit/debit card delivery." />
      </span>
      <span className={css.itemValue}>{formattedCommission}</span>
    </div>
  ) : null;
};

export default LineItemDeliveryRefaundMaybe;
