import React, { Component } from 'react';
import { FieldTextAutoFillInput, Form, NamedLink } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { fetchBrandlist, fetchModellist, fetchPricelist } from '../../util/carPriceHelper';

import css from './BrandingPage.css';

class PartnerBrandingCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandList: [],
      brandListOpen: false,
      modelList: [],
      modelListOpen: false,
      priceList: [],
    };
  }

  componentDidMount = () => {
    fetchBrandlist()
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          brandList: jsonData.map(x => ({ label: x.original_brand, key: x.normalized_brand })),
        });
      });
  };

  getModellist = brandName => {
    fetchModellist(brandName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          modelList: jsonData.map(x => ({ label: x.original_model, key: x.normalized_model })),
        });
      });
  };

  getPricelist = (brandName, modelName) => {
    const { onUseRentalCalculator } = this.props;
    onUseRentalCalculator();
    let normalized_brand = brandName
      .split(' ')
      .join('_')
      .toLowerCase();
    fetchPricelist(normalized_brand, modelName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          priceList: jsonData,
        });
      });
  };

  handleBrandList = state => {
    this.setState({
      brandListOpen: state,
    });
  };

  handleModelList = state => {
    this.setState({
      modelListOpen: state,
    });
  };

  handleHoverBug = e => {
    if (e.type === 'touchstart') {
      // Don't trigger mouseenter even if they hold
      if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
      }
      // If $item is a link (<a>), don't go to said link on mobile, show menu instead
      e.preventDefault();
    }
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={e => {}}
        render={fieldRenderProps => {
          const {
            className,
            handleSubmit,
            intl,
            form,
            values,
            listYourCarButtonId,
            onListYourCar,
          } = fieldRenderProps;

          if (
            form.getFieldState('brand') &&
            form.getFieldState('brand').active &&
            !this.state.brandListOpen
          ) {
            this.handleBrandList(true);
          } else if (
            form.getFieldState('brand') &&
            !form.getFieldState('brand').active &&
            this.state.brandListOpen
          ) {
            this.handleBrandList(false);
          }

          if (
            form.getFieldState('model') &&
            form.getFieldState('model').active &&
            !this.state.modelListOpen
          ) {
            this.handleModelList(true);
          } else if (
            form.getFieldState('model') &&
            !form.getFieldState('model').active &&
            this.state.modelListOpen
          ) {
            this.handleModelList(false);
          }

          const brandPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.brandPlaceholder',
          });
          const brandRequiredMessage = intl.formatMessage({
            id: 'BecomeAHostPage.brandRequired',
          });
          const brandRequired = validators.required(brandRequiredMessage);

          const modelPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.modelPlaceholder',
          });
          const modelRequiredMessage = intl.formatMessage({
            id: 'BecomeAHostPage.modelRequired',
          });
          const modelRequired = validators.required(modelRequiredMessage);

          const ageOfCarPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.ageOfCarPlaceholder',
          });
          const ageOfCarRequiredMessage = intl.formatMessage({
            id: 'BecomeAHostPage.ageOfCarRequired',
          });
          const ageOfCarRequired = validators.required(ageOfCarRequiredMessage);

          const milesPlaceholder = intl.formatMessage({
            id: 'BecomeAHostPage.milesPlaceholder',
          });

          return (
            <section className={css.heroSection}>
              <div className={css.landingContainer}>
                <div className={css.searchBox}>
                  <div className={css.searchBoxTitle}>Calculate your earnings</div>
                  <Form
                    className={css.form}
                    onSubmit={e => {
                      this.submittedValues = values;
                      handleSubmit(e);
                    }}
                  >
                    <div className={css.fieldsWrapper}>
                      <div className={css.landingRow}>
                        <div className={css.column12}>
                          <FieldTextAutoFillInput
                            inputClassName={css.input}
                            className={css.inputField}
                            type="text"
                            id="brandName"
                            name="brand"
                            label={'Car Brand'}
                            placeholder={'Select the brand of your car'}
                            optionsList={this.state.brandList.filter(x =>
                              values.brand
                                ? x.label
                                    .toString()
                                    .toLowerCase()
                                    .includes(values.brand.toLowerCase())
                                : true
                            )}
                            isOpenSuggest={this.state.brandListOpen}
                            chooseOption={option => {
                              form.change('brand', option.label);
                              form.change('model', null);
                              this.getModellist(option.key);
                              this.handleBrandList(false);
                            }}
                          />
                        </div>
                        <div className={css.column12}>
                          <FieldTextAutoFillInput
                            inputClassName={css.input}
                            className={classNames(css.inputField)}
                            type="text"
                            id="modelName"
                            name="model"
                            label={'Model'}
                            placeholder={'Select the model'}
                            optionsList={this.state.modelList.filter(x =>
                              values.model
                                ? x.label
                                    .toString()
                                    .toLowerCase()
                                    .includes(values.model.toLowerCase())
                                : true
                            )}
                            isOpenSuggest={this.state.modelListOpen}
                            chooseOption={option => {
                              form.change('model', option.label);
                              this.getPricelist(values.brand, option.key);
                              this.handleModelList(false);
                            }}
                          />
                        </div>
                        {/* <div className={css.column6}>
                          <FieldSelect
                            selectClassName={css.selectField}
                            className={classNames(css.inputField, css.yearField)}
                            id="ageOfCar"
                            name="ageOfCar"
                            label={'Age of the car'}
                          >
                            <option value="" disabled={true}>
                              {'How old is the car'}
                            </option>
                            <option value="under_5_years_old">0-5 year old</option>
                            <option value="under_10_years_old">6-10 year old</option>
                            <option value="more_than_10_years_old">10+ year old</option>
                          </FieldSelect>
                        </div> */}
                      </div>

                      {/* <FieldTextInput
                      inputClassName={css.input}
                      className={classNames(css.inputField, css.milesField)}
                      id="miles"
                      name="miles"
                      type="text"
                      placeholder={milesPlaceholder}

                    /> */}
                    </div>
                    <div className={css.footerBox}>
                      {values.brand && values.model && this.state.priceList && (
                        <div className={css.breakDown}>
                          {`$${this.state.priceList.price * 8}`}
                          <span className={css.perValue}>/ month</span>
                          <div className={css.valueInfo}>Your Estimate Earnings</div>
                        </div>
                      )}
                      {/* <span
                          className={css.explaination}
                          onTouchStart={this.handleHoverBug}
                          onMouseEnter={this.handleHoverBug}
                          onFocus={this.handleHoverBug}
                        >
                          <span className={css.explainationText}>
                            This estimate is based on the price recommended for your car by Drive
                            lah, for 8 days of rental per month.
                          </span>
                        </span> */}

                      {values.brand && values.model && this.state.priceList ? (
                        <NamedLink
                          name="NewListingPage"
                          className={css.listYourCar}
                          onClick={onListYourCar}
                          id={listYourCarButtonId}
                        >
                          Start earning now
                        </NamedLink>
                      ) : (
                        ''
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </section>
          );
        }}
      />
    );
  }
}

export default injectIntl(PartnerBrandingCalculator);
