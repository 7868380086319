import get from 'lodash/get';
import moment from 'moment';

const UPDATE_DATES_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const makeChildUpdateBookingDates = (start, end) => {
  const momentStart = moment(start);
  const momentEnd = moment(end);
  return {
    bookingStart: momentStart.format(UPDATE_DATES_FORMAT),
    bookingStartTimestamp: momentStart.valueOf(),
    bookingEnd: momentEnd.format(UPDATE_DATES_FORMAT),
    bookingEndTimestamp: momentEnd.valueOf(),
  };
};

export const getUpdateObjs = tx => {
  let txs = get(tx, 'attributes.protectedData.childTransaction', []);
  if (!Array.isArray(txs)) {
    txs = [
      {
        txId: txs.transactionId,
        bookingStart: txs.bookingStart,
        bookingEnd: txs.bookingEnd,
        bookingStartTimestamp: moment(txs.bookingStart, UPDATE_DATES_FORMAT).valueOf(),
        bookingEndTimestamp: moment(txs.bookingEnd, UPDATE_DATES_FORMAT).valueOf(),
      },
    ];
  }
  return txs;
};

export const getLastUpdateObj = tx => {
  const updateObjs = getUpdateObjs(tx);
  return updateObjs.length
    ? updateObjs[updateObjs.length - 1]
    : {
        bookingStart: null,
        bookingEnd: null,
      };
};

export const lastUpdateTransactionPayinTotal = tx => {
  return tx ? tx.attributes.payinTotal : null;
};

export const lastUpdateTransactionLineItems = tx => {
  return tx ? tx.attributes.lineItems : [];
};
