import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, NamedLink, PrimaryButton } from '../../components';

import css from './SignupForm.css';
import SignupStep2Form from './SignupStep2Form';
import { Experiment, Variant } from '@marvelapp/react-ab-test';

import { SignupPhoneExperiment } from '../../util/constants';

const KEY_CODE_ENTER = 13;

const SignupFormStep2Component = props => (
  <FinalForm
    {...props}
    // initialValues={{ email: props.initEmail, code: props.signUpCode, ...props.initialValues }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        values,
        intl,
        submitButtonId,
        touched,
        errors,
        handleChange,
      } = fieldRenderProps;
      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(rootClassName, className);
      const submitInProgress = inProgress;
      // const submitDisabled = invalid || submitInProgress;
      const submitDisabled = submitInProgress;

      const termsLink = (
        <NamedLink newTab={true} name="TermsOfServicePage">
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </NamedLink>
      );

      const policyLink = (
        <NamedLink newTab={true} name="PrivacyPolicyPage">
          <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </NamedLink>
      );

      return (
        <>
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <SignupStep2Form
                experiment={SignupPhoneExperiment.B}
                intl={intl}
                formId={formId}
                values={values}
                touched={touched}
                errors={errors}
              />
            </div>
            <div className={classNames(css.bottomWrapper, css.buttonWrapper)}>
              <PrimaryButton
                className={css.signInButton}
                id={submitButtonId}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="SignupForm.nextStep" />
              </PrimaryButton>
            </div>
          </Form>
        </>
      );
    }}
  />
);

SignupFormStep2Component.defaultProps = { inProgress: false };

const { bool, func, string } = PropTypes;

SignupFormStep2Component.propTypes = {
  inProgress: bool,
  submitButtonId: string,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupFormStep2 = compose(injectIntl)(SignupFormStep2Component);
SignupFormStep2.displayName = 'SignupFormStep2';

export default SignupFormStep2;
