/**
 * Slice a local number that is in the form
 * of 5550 123 456
 */

const sliceLocal = numbers => {
  if (numbers.length <= 4) {
    return numbers;
  }
  if (numbers.length <= 8) {
    return `${numbers.slice(0, 4)} ${numbers.slice(4)}`;
  }
  return `${numbers.slice(0, 4)} ${numbers.slice(4, 7)} ${numbers.slice(7, 10)}`;
};

/**
 * Slice an international phone number i.e.
 * the part that is followed after a '+' or '00'.
 */
const sliceInternational = numbers => {
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 5) {
    return `${numbers.slice(0, 3)} ${numbers.slice(3)}`;
  }
  return `${numbers.slice(0, 3)} ${numbers.slice(3, 5)} ${numbers.slice(5, 12)}`;
};

/**
 * Format a phone number in a Finnish
 * mobile phone number format.
 *
 * Uses one of the following formats:
 *
 * +123 55 1234567
 * 00123 55 1234567
 * 555 1234567
 */
export const format = value => {
  if (!value) {
    return '';
  }

  const leadingPlus = value.match(/^\+/g);
  const leadingZeros = value.match(/^00/g);
  const numbers = value.replace(/[^\d]/g, '');

  if (leadingPlus) {
    const plus = leadingPlus[0];
    const sliced = sliceInternational(numbers);
    return `${plus}${sliced}`;
  }

  if (leadingZeros && numbers.length > 2) {
    const zeros = leadingZeros[0];
    const trailingNumbers = numbers.substring(2, numbers.length);
    const sliced = sliceInternational(trailingNumbers);
    return `${zeros}${sliced}`;
  }

  return sliceLocal(numbers);
};

export const formatPhoneNumber = phoneNumber => {
  // Remove any non-digit characters from the phone number
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned number has the expected length
  // if (cleanedNumber.length !== 10) {
  //   throw new Error('Invalid phone number length. Please provide a 10-digit phone number.');
  // }

  // Extract the parts of the phone number
  const countryCode = cleanedNumber.slice(0, 2); // Assuming the country code is always 2 digits
  const firstPart = cleanedNumber.slice(2, 5);
  const secondPart = cleanedNumber.slice(5, 8);
  const lastPart = cleanedNumber.slice(8);

  // Format the phone number
  const formattedNumber = `+${countryCode} ${firstPart} ${secondPart} ${lastPart}`;

  return formattedNumber;
};

/**
 * Parser that strips whitespaces away from a phone number
 * string so that the plain number can be stored.
 */
export const parse = value => (value ? value.replace(/\s/g, '') : '');
