import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BAGBenifitsSection.css';
import imageSource from '../../util/imageSource';

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  // We are just checking the value for now
  console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
};

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/OaOzjZiVaws"
      ></iframe>
    </div>
  );
};

class BAGBenifitsSection extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <section className={css.benifitSection}>
        <div
          className={css.box1}
          title="Peer-to-peer car sharing comunity for short & long term car rental"
        />
        <div className={css.box2}>
          <div className={css.row}>
            <img
              src={imageSource.becomeAGuest.benifit_bg}
              className={css.benifit_bg}
              alt="Peer-to-peer car sharing comunity for short & long term car rental"
            />
            <h3 className={css.textBoxTitle1}>
              <FormattedMessage id="BecomeAGuestPage.benifitTitle2" values={{ newline: <br /> }} />
            </h3>
          </div>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.subBoxTitle}>
                <FormattedMessage id="BecomeAGuestPage.subBoxTitle1" values={{ newline: <br /> }} />
              </div>
              <div className={css.subBoxDesc}>
                <FormattedMessage
                  id="BecomeAGuestPage.subBoxDescDM1"
                  values={{ newline: <br /> }}
                />
              </div>
            </div>
            <div className={css.subBox}>
              <div className={css.subBoxTitle}>
                <FormattedMessage id="BecomeAGuestPage.subBoxTitle2" values={{ newline: <br /> }} />
              </div>
              <div className={css.subBoxDesc}>
                <FormattedMessage id="BecomeAGuestPage.subBoxDesc2" values={{ newline: <br /> }} />
              </div>
            </div>
            <div className={css.subBox}>
              <div className={css.subBoxTitle}>
                <FormattedMessage id="BecomeAGuestPage.subBoxTitle3" values={{ newline: <br /> }} />
              </div>
              <div className={css.subBoxDesc}>
                <FormattedMessage id="BecomeAGuestPage.subBoxDesc3" values={{ newline: <br /> }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BAGBenifitsSection;
