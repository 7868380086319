import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PrimaryButton } from '../../components';
import css from './SignupForm.css';

function jump(x) {
  var ml = ~~x.getAttribute('maxlength');

  if (ml && x.value.length >= ml) {
    do {
      x = x.nextSibling;
    } while (x && !/text/.test(x.type));
    if (x && /text/.test(x.type)) {
      x.focus();
    }
  } else {
    do {
      x = x.previousSibling;
    } while (x && !/text/.test(x.type));
    if (x && /text/.test(x.type)) {
      x.focus();
    }
  }
}

const initialState = {
  'otp-1': '',
  'otp-2': '',
  'otp-3': '',
  'otp-4': '',
  'otp-5': '',
  'otp-6': '',
};

const SignupStep3FormComponent = props => {
  const { resendCode, submitCode, onChangeNumber, inProgress } = props;
  const [codeData, setCodeData] = useState(initialState);
  const [code, setCode] = useState(null);

  const changeCode = e => {
    const { name, value } = e.target;
    const isNumber = Number(value);

    if (value && !isNumber && isNumber !== 0) {
      return;
    }

    jump(e.target);

    setCodeData(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    let codeStringify = '';

    if (Object.keys(codeData).length === 6) {
      for (let key in codeData) {
        codeStringify += codeData[key];
      }
    }

    setCode(codeStringify);
  }, [codeData]);

  const resetForm = () => {
    setCodeData(initialState);
  };

  const resendCodeAndResetForm = () => {
    resetForm();
    resendCode();
  };

  return (
    <div className={css.confirmModalRoot}>
      <div className={css.otp}>
        <input
          type="text"
          name="otp-1"
          value={codeData['otp-1']}
          onChange={e => changeCode(e)}
          maxLength={1}
          placeholder="1"
          className={css.numbers}
        />
        <input
          type="text"
          name="otp-2"
          value={codeData['otp-2']}
          onChange={e => changeCode(e)}
          maxLength={1}
          placeholder="2"
          className={css.numbers}
        />
        <input
          type="text"
          name="otp-3"
          value={codeData['otp-3']}
          onChange={e => changeCode(e)}
          maxLength={1}
          placeholder="3"
          className={css.numbers}
        />
        <input
          type="text"
          name="otp-4"
          value={codeData['otp-4']}
          onChange={e => changeCode(e)}
          maxLength={1}
          placeholder="4"
          className={css.numbers}
        />
        <input
          type="text"
          name="otp-5"
          value={codeData['otp-5']}
          onChange={e => changeCode(e)}
          maxLength={1}
          placeholder="5"
          className={css.numbers}
        />
        <input
          type="text"
          name="otp-6"
          value={codeData['otp-6']}
          onChange={e => changeCode(e)}
          maxLength={1}
          placeholder="6"
          className={css.numbers}
        />
      </div>
      <p className={css.resendPara}>
        Did not receive the OTP yet?
        <span onClick={() => resendCodeAndResetForm()} className={css.resend}>
          <FormattedMessage id="SignUpModal.resendLink" />
        </span>
      </p>
      <div className={css.buttonContainer}>
        <PrimaryButton rootClassName={css.primaryRoot} onClick={() => onChangeNumber()}>
          Edit
        </PrimaryButton>
        <PrimaryButton
          inProgress={inProgress}
          rootClassName={css.secondaryRoot}
          disabled={!code || code.length < 6}
          onClick={() => submitCode(code)}
        >
          Verify
        </PrimaryButton>
      </div>
    </div>
  );
};

SignupStep3FormComponent.defaultProps = {
  inProgress: false,
};

const { bool, func } = PropTypes;

SignupStep3FormComponent.propTypes = {
  resendCode: func.isRequired,
  submitCode: func.isRequired,
  onChangeNumber: func.isRequired,
  inProgress: bool,
};

const SignupStep3Form = compose(injectIntl)(SignupStep3FormComponent);
SignupStep3Form.displayName = 'SignupStep3Form';

export default SignupStep3Form;
