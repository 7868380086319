import React from 'react';
import css from './InsurancePlusSign.css';

const InsurancePlusSign = props => {
  return (
    <span className={css.root} style={{ marginTop: props.useMargin ? '-10%' : null }}>
      +
    </span>
  );
};

export default InsurancePlusSign;
