import React from 'react';
import { compose } from 'redux';
import { get } from 'lodash';
import { bool, func, number, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import config from '../../config';
import moment from 'moment-timezone';
import { locationRadiusBounds } from '../../util/googleMaps';

import {
  BookingDateRangeFilter,
  FilterButton,
  KeywordFilter,
  PriceFilter,
  SelectMultipleFilter,
  SortBy,
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './SearchFilters.css';
import isEmpty from 'lodash/isEmpty';

// Distance for home delivery in meters
const LIMIT_DISTANCE = 5200;

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;
const RADIX = 10;

// resolve initial value for a single value filter
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

// resolve initial values for a multi value filter
const initialValues = (queryParams, paramName) => {
  return !!queryParams[paramName] ? queryParams[paramName].split(',') : [];
};

const initialPriceRangeValue = (queryParams, paramName) => {
  const price = queryParams[paramName];
  const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

  return !!price && valuesFromParams.length === 2
    ? {
        minPrice: valuesFromParams[0],
        maxPrice: valuesFromParams[1],
        price,
      }
    : null;
};

const initialDateRangeValue = (queryParams, paramName) => {
  const dates = queryParams[paramName];
  const hours = queryParams['hours'];
  const rawHoursValues = hours ? hours.split(',') : [];
  const rawValuesFromParams = !!dates ? dates.split(',') : [];
  const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
  const initialValues =
    !!dates && valuesFromParams.length === 2
      ? {
          dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
        }
      : { dates: null };
  if (hours && rawHoursValues.length === 2) {
    initialValues.times = {
      pickupTime: moment(rawHoursValues[0], 'HH:mm').format('hh:mm a'),
      dropOffTime: moment(rawHoursValues[1], 'HH:mm').format('hh:mm a'),
    };
  } else {
    initialValues.times = null;
  }
  return initialValues;
};
const SearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    categoryFilter,
    amenitiesFilter,
    distanceFilter,
    priceFilter,
    dateRangeFilter,
    keywordFilter,
    isSearchFiltersPanelOpen,
    toggleSearchFiltersPanel,
    searchFiltersPanelSelectedCount,
    history,
    intl,
    drivelahGoFilter,
    superHostFilter,
    homeDeliveryFilter,
    instantBookingFilter,
    newCarFilter,
    disinfectedFilter,
    sort,
    longTermFilter,
    isPHVFilter,
    currentUser,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, { [css.longInfo]: hasNoResult }, className);

  const categoryLabel = intl.formatMessage({
    id: 'SearchFilters.categoryLabel',
  });

  const amenitiesLabel = intl.formatMessage({
    id: 'SearchFilters.amenitiesLabel',
  });

  const keywordLabel = intl.formatMessage({
    id: 'SearchFilters.keywordLabel',
  });

  const initialAmenities = amenitiesFilter
    ? initialValues(urlQueryParams, amenitiesFilter.paramName)
    : null;

  const initialCategory = categoryFilter
    ? initialValues(urlQueryParams, categoryFilter.paramName)
    : null;

  const initialDistanceRange = distanceFilter
    ? initialPriceRangeValue(urlQueryParams, distanceFilter.paramName)
    : null;

  const initialPriceRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
    : null;

  const initialDateRange = dateRangeFilter
    ? initialDateRangeValue(urlQueryParams, dateRangeFilter.paramName)
    : null;

  const initialKeyword = keywordFilter
    ? initialValue(urlQueryParams, keywordFilter.paramName)
    : null;

  const isKeywordFilterActive = !!initialKeyword;

  const handleSelectOptions = (urlParam, options) => {
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handleSelectOption = (urlParam, option) => {
    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    if (urlParam === 'pub_delivery') {
      const userLocation = get(
        currentUser,
        'attributes.profile.protectedData.location.selectedPlace.origin'
      );
      const newBounds = userLocation && locationRadiusBounds(userLocation, LIMIT_DISTANCE);
      newBounds && (queryParams.bounds = newBounds);
    }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handlePrice = (urlParam, range) => {
    const queryParams = range
      ? { ...urlQueryParams, [urlParam]: range }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handleDateRange = (urlParam, dateRange) => {
    const hasDates = dateRange && dateRange.dates;
    const hasHours = dateRange && !isEmpty(dateRange.times);
    const { startDate, endDate } = hasDates ? dateRange.dates : {};
    const { pickupTime, dropOffTime } = hasHours ? dateRange.times : {};
    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;
    const pickup = pickupTime ? moment(pickupTime, 'hh:mm a').format('HH:mm') : '10:00';
    const dropoff = dropOffTime ? moment(dropOffTime, 'hh:mm a').format('HH:mm') : '10:00';

    const diff = startDate && endDate && moment(end).diff(moment(start), 'days', true);
    const longTermRentalMaybe = diff && diff > 60 ? { pub_longTermAdminVerified: true } : {};

    let queryParams =
      start != null && end != null
        ? {
            ...urlQueryParams,
            [urlParam]: `${start},${end}`,
            hours: `${pickup},${dropoff}`,
            ...longTermRentalMaybe,
          }
        : omit(urlQueryParams, [urlParam, 'hours']);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handlePriceFilterRange = dateRange => {
    let diffHoursFlag = true;
    const hasDates = dateRange && dateRange.dates;
    const hasHours = dateRange && !isEmpty(dateRange.times);
    const { startDate, endDate } = hasDates ? dateRange.dates : {};
    const { pickupTime, dropOffTime } = hasHours ? dateRange.times : {};
    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;
    let startDateTimeMaybe, endDateTimeMaybe, diffHours;
    if (hasDates && hasHours && start && end && pickupTime && dropOffTime) {
      startDateTimeMaybe = new Date(moment(`${start} ${pickupTime}`).format('YYYY-MM-DD hh:mm a'));

      endDateTimeMaybe = new Date(moment(`${end} ${dropOffTime}`).format('YYYY-MM-DD hh:mm a'));
    }
    if (startDateTimeMaybe && endDateTimeMaybe) {
      diffHours = moment(endDateTimeMaybe).diff(moment(startDateTimeMaybe), 'hours', true);
    }
    // if (diffHours && diffHours < 12) {
    //   diffHoursFlag = false;
    // }
    return diffHoursFlag;
  };

  const canApply = !!urlQueryParams.dates;
  const handleKeyword = (urlParam, values) => {
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const categoryFilterElement = categoryFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.categoryFilter'}
      name="categories"
      urlParam={categoryFilter.paramName}
      label={categoryLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={categoryFilter.options}
      initialValues={initialCategory}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      canApply={canApply}
    />
  ) : null;

  const amenitiesFilterElement = amenitiesFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.amenitiesFilter'}
      name="amenities"
      urlParam={amenitiesFilter.paramName}
      label={amenitiesLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={amenitiesFilter.options}
      initialValues={initialAmenities}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;
  const distanceFilterElement = distanceFilter ? (
    <PriceFilter
      id="SearchFilters.distanceFilter"
      urlParam={distanceFilter.paramName}
      onSubmit={handlePrice}
      showAsPopup
      labelProps="SearchFilters.distanceFilterLabel"
      unitProps="kms"
      {...distanceFilter.config}
      initialValues={initialDistanceRange}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      canApply={canApply}
    />
  ) : null;
  const priceFilterElement =
    priceFilter && handlePriceFilterRange(initialDateRange) ? (
      <PriceFilter
        id="SearchFilters.priceFilter"
        urlParam={priceFilter.paramName}
        onSubmit={handlePrice}
        showAsPopup
        unitProps="$"
        {...priceFilter.config}
        initialValues={initialPriceRange}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        canApply={canApply}
      />
    ) : null;

  const dateRangeFilterElement =
    dateRangeFilter && dateRangeFilter.config.active ? (
      <BookingDateRangeFilter
        id="SearchFilters.dateRangeFilter"
        urlParam={dateRangeFilter.paramName}
        onSubmit={handleDateRange}
        showAsPopup
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        initialValues={initialDateRange}
        canApply={true}
      />
    ) : null;

  const keywordFilterElement =
    keywordFilter && keywordFilter.config.active ? (
      <KeywordFilter
        id={'SearchFilters.keywordFilter'}
        name="keyword"
        urlParam={keywordFilter.paramName}
        label={keywordLabel}
        onSubmit={handleKeyword}
        showAsPopup
        initialValues={initialKeyword}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const drivelahGoFilterElement = drivelahGoFilter ? (
    <FilterButton
      urlParam={drivelahGoFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.drivelahGoFilter' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, drivelahGoFilter.paramName)}
      hintText={<FormattedMessage id="DrivelahGO.hintText" />}
    />
  ) : null;

  const superHostFilterElement = superHostFilter ? (
    <FilterButton
      urlParam={superHostFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.superHostFilter' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, superHostFilter.paramName)}
      hintText={<FormattedMessage id="SuperHost.hintText" />}
    />
  ) : null;

  const homeDeliveryFilterElement = homeDeliveryFilter ? (
    <FilterButton
      urlParam={homeDeliveryFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.homeDeliveryFilter' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, homeDeliveryFilter.paramName)}
      hintText={<FormattedMessage id="HomeDElivery.hintText" />}
    />
  ) : null;

  const instantBookingFilterElement = instantBookingFilter ? (
    <FilterButton
      urlParam={instantBookingFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.instantBooking' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, instantBookingFilter.paramName)}
      hintText={<FormattedMessage id="InstantBooking.hintText" />}
    />
  ) : null;

  const newCarFilterElement = newCarFilter ? (
    <FilterButton
      urlParam={newCarFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.isNewCar' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, newCarFilter.paramName)}
      hintText={<FormattedMessage id="SearchFilterMobile.newCarLabelHelperText" />}
    />
  ) : null;

  const longTermFilterElement = longTermFilter ? (
    <FilterButton
      urlParam={longTermFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.longTermRental' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, longTermFilter.paramName)}
      hintText={
        'View cars available at a special monthly price when you book for long term (60+ Day booking)'
      }
    />
  ) : null;

  const allowGoodsFilterElement = isPHVFilter ? (
    <FilterButton
      urlParam={isPHVFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.allowGoods' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, isPHVFilter.paramName)}
      hintText={null}
    />
  ) : null;

  const disinfectedFilterElement = disinfectedFilter ? (
    <FilterButton
      urlParam={disinfectedFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.disinfectedFilter' })}
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, disinfectedFilter.paramName)}
      hintText={<FormattedMessage id="SearchFilter.disinfectedLabelHelperText" />}
    />
  ) : null;

  const toggleSearchFiltersPanelButtonClasses =
    isSearchFiltersPanelOpen || searchFiltersPanelSelectedCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSearchFiltersPanelButton = toggleSearchFiltersPanel ? (
    <button
      className={toggleSearchFiltersPanelButtonClasses}
      onClick={() => {
        toggleSearchFiltersPanel(!isSearchFiltersPanelOpen);
      }}
    >
      <FormattedMessage
        id="SearchFilters.moreFiltersButton"
        values={{ count: searchFiltersPanelSelectedCount }}
      />
    </button>
  ) : null;

  const handleSortBy = (urlParam, values) => {
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const sortBy = config.custom.sortConfig.active ? (
    <SortBy
      sort={sort}
      showAsPopup
      isKeywordFilterActive={isKeywordFilterActive}
      onSelect={handleSortBy}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {/* {listingsAreLoaded && resultsCount > 0 ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage id="SearchFilters.foundResults" values={{ count: resultsCount }} />
              {`${urlQueryParams.address ? 'in ' + urlQueryParams.address : ''}`}
            </span>
          </div>
        ) : null} */}
        {/* {sortBy} */}
        {/* </div> */}

        <div className={css.filters}>
          {/* {dateRangeFilterElement} */}
          {superHostFilterElement}
          {homeDeliveryFilterElement}
          {instantBookingFilterElement}
          {drivelahGoFilterElement}
          {/* {longTermFilterElement} */}
          {priceFilterElement}
          {keywordFilterElement}
          {/* {categoryFilterElement} */}
          {/* Top offers filter */}
          {/* {newCarFilterElement} */}
          {distanceFilterElement}
          {allowGoodsFilterElement}
          {/* {disinfectedFilterElement} */}
          {toggleSearchFiltersPanelButton}
        </div>

        {/* {hasNoResult ? (
          <div className={css.noSearchResults}>
            <FormattedMessage id="SearchFilters.noResults" />
          </div>
        ) : null} */}
        {sortBy}
      </div>
    </div>
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  categoryFilter: null,
  amenitiesFilter: null,
  priceFilter: null,
  dateRangeFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  categoriesFilter: propTypes.filterConfig,
  amenitiesFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  dateRangeFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchFilters = compose(withRouter, injectIntl)(SearchFiltersComponent);

export default SearchFilters;
