import classNames from 'classnames';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldSelect,
  FieldTextAutoFillInput,
  FieldTextInput,
  Form,
} from '../../components';
import config from '../../config';
import { fetchBrandlist, fetchModellist } from '../../util/carPriceHelper';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import setFieldTouched from '../../util/setFiledTouched';
import { propTypes } from '../../util/types';
import {
  composeValidators,
  maxLength,
  maxValue,
  minValue,
  notFoundValue,
  required,
} from '../../util/validators';
import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 1000;
const now = new Date();
const currentYear = now.getFullYear();

const MINIMUM_YEAR_OF_MANUFACTURE = 1990;
const MAXIMUM_YEAR_OF_MANUFACTURE = currentYear;

class EditListingDescriptionFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandList: [],
      brandListOpen: false,
      modelList: [],
      modelListOpen: false,
    };
    this.timeoutBrand = null;
    this.timeoutModel = null;
  }

  componentDidMount = () => {
    fetchBrandlist()
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        const brandListData = jsonData.map(x => {
          const getBrandOriginalName = (
            x.normalized_brand.charAt(0).toUpperCase() + x.normalized_brand.slice(1)
          )
            .split('_')
            .join(' ');
          return { label: getBrandOriginalName, key: x.normalized_brand };
        });
        this.setState({
          brandList: brandListData,
        });
      });

    if (this.props.initialValues.brandName) {
      this.getModellist(
        this.props.initialValues.brandName
          .split(' ')
          .join('_')
          .toLowerCase()
      );
    }
  };

  componentWillUnmount = () => {
    document.getElementById('EditListingWizard').removeAttribute('onclick');
  };

  getModellist = brandName => {
    fetchModellist(brandName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        try {
          const modelListData = jsonData.map(x => {
            const getModelOriginalName = isNaN(x.normalized_model)
              ? (x.normalized_model.charAt(0).toUpperCase() + x.normalized_model.slice(1))
                  .split('_')
                  .join(' ')
              : x.normalized_model;
            return {
              label: getModelOriginalName,
              key: x.normalized_model,
              category: x.normalized_category,
            };
          });
          this.setState({
            modelList: modelListData,
          });
        } catch (e) {
          console.error('e--------', e);
        }
      });
  };

  handleBrandList = state => {
    this.setState({
      brandListOpen: state,
    });
  };

  handleModelList = state => {
    this.setState({
      modelListOpen: state,
    });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ setFieldTouched }}
        render={fieldRenderProps => {
          const {
            className,
            disabled,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            values,
            form,
            submitButtonId,
          } = fieldRenderProps;

          if (
            form.getFieldState('brandName') &&
            form.getFieldState('brandName').active &&
            !this.state.brandListOpen
          ) {
            this.handleBrandList(true);
          } else if (
            form.getFieldState('brandName') &&
            !form.getFieldState('brandName').active &&
            this.state.brandListOpen
          ) {
            this.handleBrandList(false);
          }

          if (
            form.getFieldState('modelName') &&
            form.getFieldState('modelName').active &&
            !this.state.modelListOpen
          ) {
            this.handleModelList(true);
          } else if (
            form.getFieldState('modelName') &&
            !form.getFieldState('modelName').active &&
            this.state.modelListOpen
          ) {
            this.handleModelList(false);
          }

          const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
          const titlePlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.titlePlaceholder',
          });
          const titleRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.titleRequired',
          });
          const maxLengthMessage = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );

          const descriptionMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.description',
          });
          const descriptionPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.descriptionPlaceholder',
          });

          const licenseMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.license',
          });
          const licensePlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.licensePlaceholder',
          });

          const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.descriptionRequired',
          });
          const licenseRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.licenseRequired',
          });

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const brandPlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.categoryPlaceholder',
          });

          const brandRequired = required(
            intl.formatMessage({
              id: 'EditListingDescriptionForm.categoryRequired',
            })
          );

          const notFoundBrand = notFoundValue('Not found brand', this.state.brandList);

          const brandLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.categoryLabel',
          });

          const modelPlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.modelPlaceholder',
          });

          const modelRequired = required(
            intl.formatMessage({
              id: 'EditListingDescriptionForm.modelRequired',
            })
          );

          const notFoundModel = notFoundValue('Not found model', this.state.modelList);

          const modelLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.modelLabel',
          });

          const registeredStateLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredStateLabel',
            defaultMessage: 'Registered State*',
          });

          const registeredStatePlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredStatePlaceholder',
            defaultMessage: 'Select a state',
          });

          const registeredStateRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredStateRequiredMessage',
            defaultMessage: 'You need to select a state',
          });

          const yearOfManufactureLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearOfManufactureLabel',
            defaultMessage: 'Year of manufacture*',
          });

          const yearOfManufactureRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearOfManufactureRequiredMessage',
            defaultMessage: 'Year of manufacture is required',
          });
          // const yearOfManufactureMinMessage = intl.formatMessage({
          //   id: 'EditListingDescriptionForm.yearOfManufactureMinMessage',
          //   defaultMessage: `Year of manufacture must be greater than ${MINIMUM_YEAR_OF_MANUFACTURE}`,
          // });
          const yearOfManufactureMinMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearOfManufactureMinMessage',
            defaultMessage: `Car ineligible for listing`,
          });
          const yearOfManufactureMaxMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearOfManufactureMinMessage',
            defaultMessage: `Year of manufacture must be less than ${MAXIMUM_YEAR_OF_MANUFACTURE}`,
          });

          const classes = classNames(css.root, className);
          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress;
          const submitDisabled = disabled || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                const formState = form.getState();
                if (!invalid) {
                  const formValues = formState.values;
                  if (formValues.modelName && this.state.modelList && this.state.modelList.length) {
                    const model = this.state.modelList.filter(
                      item => item.label === formValues.modelName
                    );
                    if (model && model.length) {
                      formState.values.category = model[0].category;
                    }
                  }
                  handleSubmit(e);
                }
                const { errors } = formState || {};
                Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
              }}
            >
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              <div className={css.displayInlineContainer}>
                <div className={classNames(css.column, css.column12)}>
                  <div className={css.fieldWrapper}>
                    <div className={css.limitCounter}>
                      {(values.title && values.title.length) || 0}/{TITLE_MAX_LENGTH}
                    </div>
                    <FieldTextInput
                      id="title"
                      name="title"
                      className={classNames(css.title, css.newInput)}
                      type="text"
                      label={titleMessage}
                      placeholder={titlePlaceholderMessage}
                      maxLength={TITLE_MAX_LENGTH}
                      validate={composeValidators(
                        required(titleRequiredMessage),
                        maxLength60Message
                      )}
                      autoFocus
                    />
                  </div>
                </div>

                <div className={classNames(css.column, css.column12)}>
                  <div className={css.fieldWrapper}>
                    <div className={css.limitCounter}>
                      {(values.description && values.description.length) || 0}/
                      {DESCRIPTION_MAX_LENGTH}
                    </div>
                    <FieldTextInput
                      id="description"
                      name="description"
                      className={classNames(css.description, css.newInput)}
                      type="textarea"
                      maxLength={DESCRIPTION_MAX_LENGTH}
                      label={descriptionMessage}
                      placeholder={descriptionPlaceholderMessage}
                      validate={composeValidators(required(descriptionRequiredMessage))}
                    />
                  </div>
                </div>
                <div className={classNames(css.column, css.column12)}>
                  <FieldTextInput
                    id="license_plate_number"
                    name="license_plate_number"
                    className={classNames(css.description, css.newInput)}
                    type="textarea"
                    label={licenseMessage}
                    placeholder={licensePlaceholderMessage}
                    validate={composeValidators(required(licenseRequiredMessage))}
                  />
                </div>
                {/* <CustomCategorySelectFieldMaybe
                  id="brandName"
                  name="brandName"
                  categories={brands}
                  intl={intl}
              /> */}
                <div className={classNames(css.column, css.column6)}>
                  <FieldTextAutoFillInput
                    inputClassName={css.input}
                    className={classNames(css.description, css.newInput)}
                    type="text"
                    id="brandName"
                    name="brandName"
                    label={brandLabel}
                    placeholder={brandPlaceholder}
                    optionsList={this.state.brandList.filter(x =>
                      values.brandName
                        ? form.getFieldState('brandName').dirty
                          ? x.label
                              .toString()
                              .toLowerCase()
                              .includes(values.brandName.toString().toLowerCase())
                          : true
                        : true
                    )}
                    isOpenSuggest={this.state.brandListOpen}
                    chooseOption={option => {
                      form.change('brandName', option.label);
                      form.change('modelName', null);
                      this.getModellist(option.key);
                      this.handleBrandList(false);
                    }}
                    validate={composeValidators(brandRequired, notFoundBrand)}
                    editable={true}
                  />
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldTextAutoFillInput
                    inputClassName={css.input}
                    className={classNames(css.description, css.newInput)}
                    type="text"
                    id="modelName"
                    name="modelName"
                    label={modelLabel}
                    placeholder={modelPlaceholder}
                    optionsList={this.state.modelList.filter(x =>
                      values.modelName
                        ? form.getFieldState('modelName').dirty
                          ? x.label
                              .toString()
                              .toLowerCase()
                              .includes(values.modelName.toString().toLowerCase())
                          : true
                        : true
                    )}
                    isOpenSuggest={this.state.modelListOpen}
                    chooseOption={option => {
                      form.change('modelName', option.label);
                      form.blur('modelName');
                      this.handleModelList(false);
                    }}
                    validate={composeValidators(modelRequired, notFoundModel)}
                    editable={false}
                  />
                </div>

                <div className={classNames(css.column, css.column6)}>
                  <FieldSelect
                    id="registeredState"
                    name="registeredState"
                    label={registeredStateLabel}
                    className={classNames(css.newInput)}
                    validate={required(registeredStateRequiredMessage)}
                  >
                    <option disabled value="">
                      {registeredStatePlaceholder}
                    </option>
                    {config.custom.states.map(s => (
                      <option key={s.key} value={s.key}>
                        {s.label}
                      </option>
                    ))}
                  </FieldSelect>
                </div>
                <div className={classNames(css.column, css.column6)}>
                  <FieldTextInput
                    id="yearOfManufacture"
                    name="yearOfManufacture"
                    label={yearOfManufactureLabel}
                    className={classNames(css.newInput)}
                    type="number"
                    min={1990}
                    max={currentYear}
                    placeholder="2010"
                    validate={composeValidators(
                      required(yearOfManufactureRequiredMessage),
                      minValue(yearOfManufactureMinMessage, MINIMUM_YEAR_OF_MANUFACTURE),
                      maxValue(yearOfManufactureMaxMessage, MAXIMUM_YEAR_OF_MANUFACTURE)
                    )}
                  />
                </div>
                <div className={classNames(css.column, css.column12)}>
                  <FieldTextInput
                    id="parkingInfo"
                    name="parkingInfo"
                    className={classNames(css.description, css.newInput)}
                    type="textarea"
                    label="Parking Info*"
                    placeholder="Add your parking information so that the guests can easily find the car once the trip is booked"
                    validate={required('This field is required')}
                  />
                </div>
              </div>
              {/* <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
                id={submitButtonId}
              >
                {saveActionMsg}
              </Button> */}
              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}></div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  brands: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
