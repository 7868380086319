import React from 'react';
import { InsurancePolicy, Modal } from '../../components';
import { bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './ModalInsurancePolicy.css';

const ModalInsurancePolicy = props => {
  const { isOpen, onClose, onManageDisableScrolling } = props;
  return (
    <Modal
      id="staticPageModal"
      isOpen={isOpen}
      onClose={onClose}
      containerClassName={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
    >
      <InsurancePolicy />
    </Modal>
  );
};

ModalInsurancePolicy.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onManageDisableScrolling: () => {},
};

ModalInsurancePolicy.prototype = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default ModalInsurancePolicy;
