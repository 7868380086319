import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { Modal } from '../../components';
import classNames from 'classnames';
import css from './CitiesModal.css';
import { cities } from '../../marketplace-custom-config';
import { getRandomNumber } from '../../util/helpers';
import { Column, Container, Row } from '../../components/Grids/Grid';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const CITY_WITH_IMAGES = [
  { name: 'Sydney', img: imageSource.landingPageNew.cities.CitySydney },
  { name: 'Melbourne', img: imageSource.landingPageNew.cities.CityMelbourne },
  { name: 'Brisbane', img: imageSource.landingPageNew.cities.CityBrisbane },
  { name: 'Cairns', img: imageSource.landingPageNew.cities.CityCairns },
  { name: 'Perth', img: imageSource.landingPageNew.cities.CityPerth },
  { name: 'Adelaide', img: imageSource.landingPageNew.cities.CityAdelaide },
];

export const CitiesModalContentComponent = ({
  onCitySelect,
  title,
  subTitle,
  cityClassContainer,
  cityClassTitle,
  cityClassSubtitle,
  cityClassImages,
  isFromModal,
}) => {
  const getKeyByCity = _city_ => {
    const city = cities.filter(city => city.label === _city_);
    if (city && city.length && city[0].label === _city_) {
      return city[0].key;
    }
    return '';
  };

  /**
   * This will render the cards for normal component
   * @returns {ReactDOM}
   */
  const onRenderDataForDesktop = () => {
    return (
      <>
        {CITY_WITH_IMAGES &&
          CITY_WITH_IMAGES.length &&
          CITY_WITH_IMAGES.map(item => {
            return (
              <Column large={4} medium={4} small={12} key={getRandomNumber()} className={css.image}>
                <div onClick={() => onCitySelect(getKeyByCity(item.name))} className={css.card}>
                  <LazyImage src={item.img} alt="" />
                  <span>{item.name}</span>
                </div>
              </Column>
            );
          })}
      </>
    );
  };

  /**
   * This will render the cards for modal
   * @returns {ReactDOM}
   */
  const onRenderDataForModal = part => {
    const cards = part == 1 ? [...CITY_WITH_IMAGES].splice(0, 3) : [...CITY_WITH_IMAGES].splice(3);
    return (
      <>
        {cards &&
          cards.length &&
          cards.map(item => {
            return (
              <div
                key={getRandomNumber()}
                className={classNames(css.image, css.inModal)}
                onClick={() => onCitySelect(getKeyByCity(item.name))}
              >
                <div className={css.image}>
                  <LazyImage src={item.img} />
                  <span>{item.name}</span>
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const getForModal = () => {
    return (
      <div className={classNames(css.container, cityClassContainer)}>
        <div className={classNames(css.title, cityClassTitle, css.inModalTitle)}>
          <label>{title || 'Find the right car in your city'}</label>
        </div>
        <div className={classNames(css.subTitle, cityClassSubtitle)}>
          <label>{subTitle || 'Select the city to view the available cars'}</label>
        </div>
        <div className={classNames(css.images, cityClassImages)} style={{ marginTop: '10px' }}>
          {onRenderDataForModal(1)}
        </div>
        <div className={classNames(css.images, cityClassImages)} style={{ marginTop: '10px' }}>
          {onRenderDataForModal(2)}
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(css.container, cityClassContainer)}>
      {isFromModal && getForModal()}
      {!isFromModal && (
        <Container>
          <Row>
            <Column large={12}>
              <h2 className={css.heading}>{title || 'Find the right car in your city'}</h2>
            </Column>
          </Row>
          <Row className={classNames(css.subTitle, cityClassSubtitle)}>
            <Column large={12}>
              <h2>{subTitle || 'Select the city to view the available cars'}</h2>
            </Column>
          </Row>
          <Row className={css.inCenter}>{onRenderDataForDesktop()}</Row>
        </Container>
      )}
    </div>
  );
};

const CitiesModalComponent = ({
  isModalOpen,
  doIsModalOpen,
  onCitySelect,
  onManageDisableScrolling,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  return (
    <Modal
      id={'citiesModal'}
      customContainerClassName={css.customContainerClassName}
      closeButtonMessage={' '}
      isOpen={isOpen}
      onClose={() => {
        doIsModalOpen();
      }}
      showCloseText={false}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <Row>
        <Column large={12}>
          <CitiesModalContentComponent onCitySelect={onCitySelect} {...rest} isFromModal={true} />
        </Column>
      </Row>
    </Modal>
  );
};

CitiesModalComponent.propTypes = {
  onManageDisableScrolling: func.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CitiesModal = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CitiesModalComponent);

CitiesModal.displayName = 'CitiesModal';

export default CitiesModal;
