import React from 'react';
import css from './InstantBookingLandingPage.css';

export const SectionInstantBooking = ({ intl }) => {
  return (
    <div className={css.sectionInstantBooking}>
      <div className={css.sectionInstantBookingContent}>
        <h2 className={css.instantBooking}>
          {intl.formatMessage({
            id: 'InstantBookingLandingPage.SectionInstantBooking.instantBooking',
          })}
        </h2>
        <div className={css.sectionInstantBookingDescription}>
          {intl.formatMessage({
            id: 'InstantBookingLandingPage.SectionInstantBooking.description',
          })}
        </div>
        <div className={css.properties}>
          <div className={css.property}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="336"
              height="131.01"
              viewBox="0 0 336 131.01"
            >
              <g id="Group_1657" data-name="Group 1657" transform="translate(-199 -854)">
                <g id="Group_5" data-name="Group 5" transform="translate(0 10)">
                  <text
                    id="Instant_approval"
                    data-name="Instant approval"
                    transform="translate(199 932.01)"
                    fill="#fff"
                    font-size="24"
                    font-family="sofiapro,Helvetica,Arial,sans-serif"
                    font-weight="500"
                  >
                    <tspan x="80.379" y="23">
                      Instant approval
                    </tspan>
                  </text>
                  <g id="Group_17" data-name="Group 17" transform="translate(146.58 482.938)">
                    <g id="Group_112" data-name="Group 112" transform="translate(170.42 382.745)">
                      <g id="Group_17-2" data-name="Group 17">
                        <path
                          id="Path_17"
                          data-name="Path 17"
                          d="M193.519,374.533a36.828,36.828,0,0,0-6.463,4.233,38.339,38.339,0,0,0-9.344,11.474,44.332,44.332,0,0,0-2.086,4.629,18.889,18.889,0,0,1-.739,1.761l-.108.217-.392.3a8.449,8.449,0,0,0-2.607,3,25.26,25.26,0,0,0-1.326,7.649c-.13,3.5.022,6.977,2.174,8.8l.152.088a18.725,18.725,0,0,0,8.953,2.108c.7,0,1.413-.044,2.13-.108l.8-.088-.2-.629a18.445,18.445,0,0,1-.521-2.152l-.086-.5-.5.042q-.815.066-1.629.066a16.416,16.416,0,0,1-6.867-1.435l-.349-.174-.173-.347a13.058,13.058,0,0,1-.543-5.542,21.5,21.5,0,0,1,.956-6.258,4.987,4.987,0,0,1,1.651-1.847,4.257,4.257,0,0,0,1.282-1.347,21.557,21.557,0,0,0,1.042-2.39,34.281,34.281,0,0,1,1.913-4.238,35.762,35.762,0,0,1,8.626-10.562,33.576,33.576,0,0,1,5.6-3.666A19.954,19.954,0,0,1,193.519,374.533Z"
                          transform="translate(-170.42 -373.427)"
                          fill="#fff"
                          stroke="#fff"
                          stroke-miterlimit="10"
                          stroke-width="0.3"
                        />
                        <path
                          id="Path_18"
                          data-name="Path 18"
                          d="M240.747,395.951c-4.346-6.39-11.125-10.171-20.775-11.582-1.8-.262-2.956-.457-3.5-1.218a46.775,46.775,0,0,0-4.236-5.236,21.668,21.668,0,0,0-5.155-4.037,19.718,19.718,0,0,1-1.188,3.159,18.264,18.264,0,0,1,3.886,3.159,42.851,42.851,0,0,1,3.955,4.889,7.575,7.575,0,0,0,5.737,2.609c8.779,1.282,14.646,4.5,18.493,10.147a16.382,16.382,0,0,1,3.086,9.954,6.332,6.332,0,0,1-1.325,3.911,8.612,8.612,0,0,1-5.107,2.042h-9.127l-.022.521a14.718,14.718,0,0,1-.217,2.2l-.11.629h9.844a11.47,11.47,0,0,0,7.259-3.15,9.559,9.559,0,0,0,2.172-5.933A19.95,19.95,0,0,0,240.747,395.951Z"
                          transform="translate(-145.88 -373.879)"
                          fill="#fff"
                          stroke="#fff"
                          stroke-miterlimit="10"
                          stroke-width="0.3"
                        />
                      </g>
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M231.1,392.183a14.007,14.007,0,0,0-12.908-.239,21.258,21.258,0,0,0-5.975,4.911l-.3.347,2.13,2.609.413-.479c2.607-2.977,5.867-6.041,10.213-6.041a9.192,9.192,0,0,1,0,18.384,9.025,9.025,0,0,1-3.39-.651,25.8,25.8,0,0,1-9.756-8.127,60.054,60.054,0,0,0-9.213-9.432c-.371-.282-.805-.587-1.26-.87l-.371-.261a12.213,12.213,0,1,0-13.343,20.448,13.906,13.906,0,0,0,12.778.283,20.2,20.2,0,0,0,6.107-4.975l.3-.349-2.13-2.607-.413.477c-2.609,2.977-5.869,6.041-10.215,6.041a9.192,9.192,0,1,1,0-18.384h0a8.983,8.983,0,0,1,3.39.653,24.361,24.361,0,0,1,8.911,7.105c.457.543.934,1.086,1.435,1.673a74.038,74.038,0,0,0,6.584,7.063,22.25,22.25,0,0,0,3.671,2.847A12.2,12.2,0,1,0,231.1,392.183Z"
                        transform="translate(-162.749 -362.57)"
                        fill="#fff"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="0.3"
                      />
                    </g>
                    <path
                      id="Path_202"
                      data-name="Path 202"
                      d="M215.7,368.736,213.623,372a12.908,12.908,0,1,1-4.206-5.121l-7.733,12.155-5.28-5.632-2.472,2.316,8.271,8.806,11.648-18.32-1.061-.959a16.281,16.281,0,1,0,2.913,3.475Z"
                      transform="translate(10.507 0)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className={css.property}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="336"
              height="131.01"
              viewBox="0 0 336 131.01"
            >
              <g id="Group_1658" data-name="Group 1658" transform="translate(-515.785 -854)">
                <g id="Group_6" data-name="Group 6" transform="translate(0 10)">
                  <text
                    id="One-click_booking"
                    data-name="One-click booking"
                    transform="translate(515.785 932.01)"
                    fill="#fff"
                    font-size="24"
                    font-family="sofiapro,Helvetica,Arial,sans-serif"
                    font-weight="500"
                  >
                    <tspan x="66.339" y="23">
                      One-click booking
                    </tspan>
                  </text>
                  <g id="Group_18" data-name="Group 18" transform="translate(524.296 424.39)">
                    <path
                      id="Path_19"
                      data-name="Path 19"
                      d="M162.4,454.949l-9.3-1.158V441.463a5.2,5.2,0,1,0-10.394,0v8.612h0V459.9l-.431-.363a5.63,5.63,0,0,0-7.453.325,4.862,4.862,0,0,0,.316,7.239l7.568,6.351v6.121a1.732,1.732,0,0,0,1.732,1.732h20.786a1.732,1.732,0,0,0,1.732-1.732V460.1A5.2,5.2,0,0,0,162.4,454.949Zm1.089,22.889H146.17v-3.464h17.322Zm0-6.928H145.069l-7.706-6.466a1.4,1.4,0,0,1-.092-2.134,2.152,2.152,0,0,1,2.775-.122l3.278,2.751a1.733,1.733,0,0,0,2.845-1.327v-22.15a1.733,1.733,0,1,1,3.466,0v13.856a1.732,1.732,0,0,0,1.518,1.72l10.82,1.348a1.734,1.734,0,0,1,1.518,1.718Z"
                      transform="translate(7.912 12.308)"
                      fill="#fff"
                    />
                    <g id="Group_18-2" data-name="Group 18" transform="translate(122.651 438.064)">
                      <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M126.115,442.381a8.7,8.7,0,0,1,8.693-8.693h24.073a20.505,20.505,0,0,1-.6-3.466H134.808a12.159,12.159,0,0,0,0,24.318h12.768v-3.464H134.808A8.7,8.7,0,0,1,126.115,442.381Z"
                        transform="translate(-122.651 -430.222)"
                        fill="#fff"
                      />
                      <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M164.895,441.616a8.634,8.634,0,0,1-5.9,2.351h-12.55v3.464h12.55a12.175,12.175,0,0,0,11.26-7.589A20.429,20.429,0,0,1,164.895,441.616Z"
                        transform="translate(-105.069 -423.113)"
                        fill="#fff"
                      />
                    </g>
                    <path
                      id="Path_202"
                      data-name="Path 202"
                      d="M176.229,427.5l-2.137,3.36a13.268,13.268,0,1,1-4.323-5.264l-7.949,12.495-5.427-5.789-2.541,2.381,8.5,9.052L174.326,424.9l-1.09-.986a16.737,16.737,0,1,0,2.995,3.572Z"
                      transform="translate(16.735)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className={css.property}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="336"
              height="131.511"
              viewBox="0 0 336 131.511"
            >
              <g id="Group_1659" data-name="Group 1659" transform="translate(-833.785 -853.499)">
                <g id="Group_4" data-name="Group 4" transform="translate(0 10)">
                  <g id="Vector_Smart_Object" data-name="Vector Smart Object">
                    <g
                      id="Vector_Smart_Object-2"
                      data-name="Vector Smart Object"
                      transform="translate(674.775 120.902)"
                    >
                      <g id="Group_12" data-name="Group 12">
                        <g id="Group_11" data-name="Group 11">
                          <path
                            id="Path_11"
                            data-name="Path 11"
                            d="M359.9,739.5a2.876,2.876,0,0,0-2.565-1.561H346.614c.048.578.088,1.157.088,1.747s-.04,1.164-.088,1.737h9.575l-21.776,25.813a9.922,9.922,0,0,0-1.936,5.868V788.95l-11.5,4.386v-20.23a9.922,9.922,0,0,0-1.936-5.868l-21.776-25.813h8.712c-.048-.573-.088-1.15-.088-1.737s.04-1.169.088-1.747H296.11a2.875,2.875,0,0,0-2.564,1.561,2.92,2.92,0,0,0,.226,3.036L316.2,769.271l.024.031a6.421,6.421,0,0,1,1.258,3.8v21.11a2.865,2.865,0,0,0,2.864,2.882,2.95,2.95,0,0,0,1.13-.226l12.606-4.805a2.732,2.732,0,0,0,1.879-2.694V773.106a6.409,6.409,0,0,1,1.258-3.8l.022-.031,22.432-26.736A2.916,2.916,0,0,0,359.9,739.5Z"
                            fill="#fff"
                            stroke="#00a3ad"
                            stroke-width="1"
                          />
                          <g id="Group_10" data-name="Group 10">
                            <g id="Group_9" data-name="Group 9">
                              <path
                                id="Path_12"
                                data-name="Path 12"
                                d="M326.418,746.351a8.031,8.031,0,1,1,8.034-8.031A8.042,8.042,0,0,1,326.418,746.351Zm0-12.613A4.582,4.582,0,1,0,331,738.32,4.589,4.589,0,0,0,326.418,733.737Z"
                                fill="#fff"
                                stroke="#00a3ad"
                                stroke-width="1"
                              />
                              <rect
                                id="Rectangle_1"
                                data-name="Rectangle 1"
                                width="3.45"
                                height="9.539"
                                transform="matrix(0.707, -0.707, 0.707, 0.707, 329.225, 744.393)"
                                fill="#fff"
                                stroke="#00a3ad"
                                stroke-width="1"
                              />
                            </g>
                            <path
                              id="Path_13"
                              data-name="Path 13"
                              d="M331.747,751.8a13.246,13.246,0,1,1,3.055-1.825l2.455,2.454a16.667,16.667,0,1,0-2.928,1.951Z"
                              fill="#fff"
                              stroke="#00a3ad"
                              stroke-width="1"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <path
                      id="Color_Overlay"
                      data-name="Color Overlay"
                      d="M993.1,917.155a2.86,2.86,0,0,1-.833-2.037v-21.11A6.429,6.429,0,0,0,991,890.2l-.024-.031-22.432-26.734a2.92,2.92,0,0,1-.226-3.036,2.876,2.876,0,0,1,2.564-1.561h9.862c-.049.578-.088,1.157-.088,1.747s.04,1.165.088,1.737h-8.712l21.776,25.813a9.924,9.924,0,0,1,1.936,5.868v20.23l11.5-4.386V894.008a9.924,9.924,0,0,1,1.936-5.868l21.776-25.813h-9.574c.048-.572.087-1.15.087-1.737s-.04-1.169-.087-1.747h10.723a2.877,2.877,0,0,1,2.565,1.561,2.917,2.917,0,0,1-.226,3.034l-22.432,26.736-.022.031a6.417,6.417,0,0,0-1.257,3.8v16.267a2.733,2.733,0,0,1-1.878,2.695l-12.606,4.805a2.942,2.942,0,0,1-1.129.226A2.856,2.856,0,0,1,993.1,917.155Zm-8.269-56.563a16.594,16.594,0,1,1,27.206,12.74l-2.455-2.454,0,0L1005,866.293a8.044,8.044,0,1,1,2.622-2.257l4.36,4.361a13.1,13.1,0,1,0-5.458,4.3l2.583,2.58a16.575,16.575,0,0,1-24.278-14.69Zm11.783-1.37a4.584,4.584,0,1,0,4.584-4.583A4.588,4.588,0,0,0,996.609,859.222Z"
                      fill="#fff"
                      stroke="#00a3ad"
                      stroke-width="0.3"
                    />
                  </g>
                  <text
                    id="Filtered_search"
                    data-name="Filtered search"
                    transform="translate(833.785 932.01)"
                    fill="#fff"
                    font-size="24"
                    font-family="sofiapro,Helvetica,Arial,sans-serif"
                    font-weight="500"
                  >
                    <tspan x="88.827" y="23">
                      Filtered search
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
