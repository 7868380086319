import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { fetchBrandlist, fetchModellist, fetchPricelist } from '../../util/carPriceHelper';

import css from './BAGHeroSection.css';

class BAGHeroSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandList: [],
      brandListOpen: false,
      modelList: [],
      modelListOpen: false,
      priceList: [],
    };
  }

  componentDidMount = () => {
    fetchBrandlist()
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          brandList: jsonData.map(x => ({ label: x.original_brand, key: x.normalized_brand })),
        });
      });
  };

  getModellist = brandName => {
    fetchModellist(brandName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          modelList: jsonData.map(x => ({ label: x.original_model, key: x.normalized_model })),
        });
      });
  };

  getPricelist = (brandName, modelName) => {
    let normalized_brand = brandName
      .split(' ')
      .join('_')
      .toLowerCase();
    fetchPricelist(normalized_brand, modelName)
      .then(response => {
        return response.json();
      })
      .then(jsonData => {
        this.setState({
          priceList: jsonData,
        });
      });
  };

  render() {
    return (
      <section className={css.heroSection}>
        <div
          className={css.heroImage}
          title="Best car sharing for all your car rental needs | Drive Mate"
        ></div>
        <div className={css.joinUsNowContainer}>
          <div className={css.joinUsNow}>
            <h1 className={css.joinUsNowText}>
              <FormattedMessage
                id={'BecomeAGuestPage.joinUsNowText'}
                values={{
                  newlineMobile: (
                    <span className={css.newlineMobile}>
                      <br />
                    </span>
                  ),
                }}
              />
            </h1>
          </div>
        </div>
      </section>
    );
  }
}

export default injectIntl(BAGHeroSection);
