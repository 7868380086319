import config from '../config';

const apiUrl = config.apiUrl;

export const uploadFiles = data => {
  return fetch(apiUrl + '/api/upload', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      //   'Content-Type': 'application/json'
    },
    body: data,
  });
};

const objToQueryString = obj => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
};
