import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { string } from 'prop-types';
import React, { memo, useMemo } from 'react';
import { ExternalLink } from '../../components';
import config from '../../config';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import { Column, Container, Row } from '../Grids/Grid';
import drivemateLogo from '../../assets/logos/drive-mate-long-white.png';
import css from './ThemedFooter.css';
import imageSource from '../../util/imageSource';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <img src={imageSource?.facebook} alt="" />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <img src={twitter} alt="" />
  //   </ExternalLink>
  // ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <img src={imageSource?.instagram} alt="" />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink].filter(v => v != null);
};

const ThemedFooter = memo(
  props => {
    const { rootClassName, className, intl, bookingPanelMobileHeight = 0 } = props;
    const socialMediaLinks = useMemo(() => renderSocialMediaLinks(intl), [intl]);
    const classes = classNames(rootClassName || css.root, className);
    const pushGTMBrowse = event => {
      pushGTMBrowseEvent({
        props,
        event,
      });
    };
    const currentYear = moment(new Date()).format('YYYY');
    return (
      <div className={classes}>
        {/* <FooterCategoriesSection pushGTMBrowse={pushGTMBrowse} /> */}

        <div className={css.footerAppDownloadSection}>
          <Container>
            <Row>
              <Column large={6} small={12}>
                <h6>Download app on</h6>
                <ul className={css.badgeListing}>
                  <li className={css.heroContentText}>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.drivelah.drivemate"
                      className={css.appButton}
                    >
                      <img src={imageSource?.googlePlayBadge} alt="" />
                    </a>
                  </li>
                  <li className={css.heroContentText}>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/au/app/drive-mate-share-rent-cars/id1534972421"
                      className={css.appButton}
                    >
                      <img src={imageSource?.appStoreBadge} alt="" />
                    </a>
                  </li>
                </ul>
              </Column>
              <Column large={6} small={12}>
                <p>
                  Australia’s fastest growing car sharing and rental platform
                  <img
                    src={imageSource?.australiaFlag}
                    alt=""
                    style={{ width: '22px', borderRadius: '2px' }}
                  />
                </p>
              </Column>
            </Row>
          </Container>
        </div>
        <div className={css.bottomFooterSection}>
          <Container>
            <Row alignItems="center">
              <Column large={6}>
                <div className={css.footerLogo}>
                  <img src={drivemateLogo} alt="" />
                  <span>© Drive mate 2023</span>
                </div>
              </Column>
              <Column large={6}>
                <div className={css.footerSocialLinks}>{socialMediaLinks}</div>
              </Column>
            </Row>
          </Container>
          {/* <div className={css.content}>
            <div className={css.someLiksMobile}>{socialMediaLinks}</div>
            <div className={css.links}>
              <div className={css.organization} id="organization">
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo format="desktop" className={css.logo} logoSrc={LogoImage} />
                </NamedLink>
                <div className={css.organizationInfo}>
                  <p className={css.organizationDescription}>
                    <FormattedMessage id="Footer.organizationDescription" />
                  </p>
                  <p className={css.FooterAddress}>
                    <FormattedMessage id="Footer.Address" />
                  </p>
                  <p className={css.organizationCopyright}>
                    <NamedLink name="LandingPage" className={css.copyrightLink}>
                      <FormattedMessage id="Footer.copyright" values={{ currentYear }} />
                    </NamedLink>
                  </p>
                </div>
              </div>
              <div className={css.searches}>
                <ul className={css.list}>
                  <li className={css.listItem}></li>
                </ul>
              </div>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink
                      name="BecomeAGuestPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
                    >
                      <FormattedMessage id="Footer.rentACar" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="BecomeAHostPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
                    >
                      <FormattedMessage id="Footer.shareYourCar" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="HowItWorkPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}
                    >
                      <FormattedMessage id="Footer.howItWork" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="TrustAndSafetyPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
                    >
                      <FormattedMessage id="Footer.trustAndSafety" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="LandingPageGo"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
                    >
                      Drive mate Go™
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="InstantBookingLandingPage" className={css.link}>
                      Instant Booking
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesExtra}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink
                      name="AboutPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_ABOUTUS)}
                    >
                      <FormattedMessage id="Footer.aboutUs" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem} onClick={() => pushGTMBrowse(EVENT_BROWSE_POLICIES)}>
                    <ExternalLink
                      href={'https://help.drivelah.sg/our-policies'}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.policies" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem} onClick={() => pushGTMBrowse(EVENT_BROWSE_FAQ)}>
                    <ExternalLink href={'https://help.drivelah.sg/faq'} className={css.link}>
                      <FormattedMessage id="Footer.faq" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={'https://help.drivelah.sg'} className={css.link}>
                      <FormattedMessage id="Footer.help" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ContactUsPage" className={css.link}>
                      <FormattedMessage id="Footer.contactUs" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="Career" className={css.link}>
                      <FormattedMessage id="Footer.career" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink className={css.link} href="https://blog.drivelah.sg">
                      Blog
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesExtra}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <div className={css.ourPartners}>Our partners</div>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={'https://www.tokiomarine.com/'} className={css.link}>
                      Tokio Marine
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={'https://www.income.com.sg/'} className={css.link}>
                      NTUC Income
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                   <ExternalLink href={'https://ucars.sg/'} className={css.link}>
                     Ucars
                   </ExternalLink>
                  </li>
                </ul>
              </div>
              <div className={css.extraLinks}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    <li>
                      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className={css.copyrightAndTermsMobile}
              style={
                bookingPanelMobileHeight
                  ? { marginBottom: (bookingPanelMobileHeight * 40) / 100 }
                  : {}
              }
            >
              <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
                <FormattedMessage id="Footer.copyright" values={{ currentYear }} />
              </NamedLink>

              <div className={css.tosAndPrivacyMobile}>
                <NamedLink name="RentalAgreementPage" className={css.rental}>
                  <FormattedMessage id="Footer.rentalAgreement" />
                </NamedLink>
                <NamedLink name="TermsOfServicePage" className={css.terms}>
                  <FormattedMessage id="Footer.terms" />
                </NamedLink>
                <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </div>
            </div>
            <div>
              <p className={css.FooterAddressMobile}>
                <FormattedMessage id="Footer.Address" />
              </p>
            </div>
          </div> */}
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev.bookingPanelMobileHeight, next.bookingPanelMobileHeight)
);

ThemedFooter.defaultProps = {
  rootClassName: null,
  className: null,
};

ThemedFooter.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(ThemedFooter);
