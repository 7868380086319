import classNames from 'classnames';
import React, { memo } from 'react';
import { Column, Container, Row } from '../../components/Grids/Grid';
import css from './NewGuestLandingPage.css';
import { getRandomNumber } from '../../util/helpers';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const LoveDrivemate = memo(() => {
  const Ratings = () => {
    return (
      <span className={css.starReview}>
        <span>
          <LazyImage src={imageSource.landingPageNew.YellowStar} alt="" />
        </span>
        <span>
          <LazyImage src={imageSource.landingPageNew.YellowStar} alt="" />
        </span>
        <span>
          <LazyImage src={imageSource.landingPageNew.YellowStar} alt="" />
        </span>
        <span>
          <LazyImage src={imageSource.landingPageNew.YellowStar} alt="" />
        </span>
        <span>
          <LazyImage src={imageSource.landingPageNew.YellowStar} alt="" />
        </span>
      </span>
    );
  };

  const dataContent = [
    {
      id: 1,
      image: imageSource.landingPageNew.loveDrivemateSec1,
      testimonialQuote:
        'I love Drive mate! Their customer support is top-notch, and the affordability is unbeatable. Highly recommended!',
      testimonialBy: 'Michael L.',
      ratings: <Ratings />,
    },
    {
      id: 2,
      image: imageSource.landingPageNew.loveDrivemateSec2,
      testimonialQuote:
        'Drive mate made car sharing a breeze! Convenient booking, excellent support, and reliable cars. Saved me time and money!',
      testimonialBy: 'Sarah T.',
      ratings: <Ratings />,
    },
    {
      id: 3,
      image: imageSource.landingPageNew.loveDrivemateSec3,
      testimonialQuote:
        "I've tried other car sharing services, but Drive mate stands out with its convenience and great customer support.",
      testimonialBy: 'Andrew H.',
      ratings: <Ratings />,
    },
    {
      id: 4,
      image: imageSource.landingPageNew.loveDrivemateSec4,
      testimonialQuote:
        'Drive mate is my go-to car sharing platform. The convenience of booking and the reliable service keep me coming back.',
      testimonialBy: 'Emily S.',
      ratings: <Ratings />,
    },
  ];

  return (
    <div className={css.loveDrivelahSection}>
      <Container>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h4 className={css.heading}>
              #LoveDrivemate
              <p className={css.desc}>
                Read stories from our guest who are renting cars from Drive mate
              </p>
            </h4>
          </Column>
        </Row>
        <Row className={css.sectionTitle}>
          {dataContent &&
            dataContent.length > 0 &&
            dataContent.map(testimonial => {
              return (
                <Column
                  large={3}
                  medium={4}
                  small={12}
                  className={css.loveDriveLahCard}
                  key={getRandomNumber()}
                >
                  <div className={classNames(css.card, css.loveDriveLahCard)}>
                    <div className={css.cardThumb}>
                      <LazyImage src={testimonial.image} className={css.cardImg} />
                    </div>
                    <div className={css.cardContent}>
                      <p>“{testimonial.testimonialQuote}”</p>
                      <p>
                        <strong>{testimonial.testimonialBy}</strong>
                      </p>
                      {/* {testimonial.ratings} */}
                    </div>
                  </div>
                </Column>
              );
            })}
        </Row>
      </Container>
    </div>
  );
});

export default LoveDrivemate;
