import React, { memo } from 'react';
import { Column, Container, Row } from '../../components/Grids/Grid';
import css from './NewGuestLandingPage.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

export const Approved = () => (
  <div className={css.channelOuter}>
    <a href="#" className={css.channelItem} target="_blank">
      <LazyImage className={css.channelItemImage} src={imageSource.landingPageNew.Transport} />
    </a>
  </div>
);

export const Rated = () => (
  <div className={css.channelOuter}>
    <a href="#" target="_blank" className={css.channelItem}>
      <LazyImage className={css.channelItemImage} src={imageSource.landingPageNew.Trust} />
    </a>
  </div>
);

export const Insured = () => (
  <div className={css.channelOuter}>
    <a href="#" target="_blank" className={css.channelItem}>
      <LazyImage className={css.channelItemImage} src={imageSource.landingPageNew.Tokio} />
    </a>
    <a href="#" className={css.channelItem} target="_blank">
      <LazyImage className={css.channelItemImage} src={imageSource.landingPageNew.Income} />
    </a>
  </div>
);

const ChannelsListings = memo(() => {
  return (
    <div className={css.channelsListingWrapper}>
      <Container>
        <Row justifyContent="center">
          <Column large={10}>
            {/* <div className={css.channelsListingOuter}>
              <div className={css.channelsListing}>
                <p>Approved by</p>
                <Approved />
              </div>

              <div className={classNames(css.channelsListing, css.trustBox)}>
                <p>Rated 4.7/5.0 in</p>
                <Rated />
                {/* <TrustBox height={80} /> * /}
              </div>

              <div className={css.channelsListing}>
                <p>Insured by</p>
                <Insured />
              </div>
            </div> */}
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default ChannelsListings;
