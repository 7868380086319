import React from 'react';
import css from './DonationPage.css';
import { Button, NamedLink } from '../../components';
import { get } from 'lodash';
import imageSource from '../../util/imageSource';

const DonationBanner = ({ setIsDonateModalOpen, currentUser, isAuthenticated }) => {
  const isCurrentUser = get(currentUser, 'id.uuid', '');
  const donateButton = isAuthenticated ? (
    <Button onClick={() => setIsDonateModalOpen(true)} className={css.donateSubmit}>
      Donate
    </Button>
  ) : (
    <NamedLink
      name="LoginPage"
      to={{
        state: { isFromDonationPage: true },
      }}
      className={css.donateSubmit}
    >
      Login
    </NamedLink>
  );
  return (
    <div
      className={css.bannerWrapper}
      style={{ backgroundImage: `url(${imageSource.donationPage.donationBanner})` }}
    >
      <div className={css.headerSection}>
        <div className={css.landingContainer}>
          <div className={css.landingRow}>
            <div className={css.column12}>
              <div className={css.logoWrapper}>
                <img src={imageSource.donationPage.driveTogetherLogo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bannerContent}>
        <h1>Together we make a difference</h1>
        <div className={css.buttonWrap}>{donateButton}</div>
      </div>
    </div>
  );
};

export default DonationBanner;
