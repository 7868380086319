import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { createSlug, parse } from '../../util/urlHelpers';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Prompt, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import get from 'lodash/get';
import { getDefaultTimeZoneOnBrowser, minutesBetween } from '../../util/dates';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findRouteByRouteName, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
  restoreTransaction,
} from '../../util/data';
import {
  isTransactionChargeDisabledError,
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionInitiateInvalidVoucher,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import { TRANSITION_ENQUIRE, txIsPaymentExpired, txIsPaymentPending } from '../../util/transaction';
import {
  AvatarMedium,
  Button,
  CheckoutBreakdownLongTerm,
  IconSpinner,
  InsurancePanel,
  Logo,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
} from '../../components';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { handleCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { trackRelevanceEvent, triggerRelevanceSync } from '../SearchPage/Relevance.js';

import {
  confirmPayment,
  confirmPaymentDeposit,
  createPaymentIntent,
  fetchDepositTx,
  fetchTransaction,
  sendMessage,
  setInitialValues,
  stripeCustomer,
} from './CheckoutPage.duck';
import { clearData, storeData, storedData } from './CheckoutPageSessionHelpers';
import { checkCanUseMastercardPromo } from './CheckoutPage.helpers';
import { initiateEventFromTransaction, pushGTMBookEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST,
  EVENT_BOOK_CREATED_REQUEST_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_HOST,
  EVENT_BOOK_SAVED_CARD_DETAILS,
  EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST,
  EVENT_BOOK_USED_PRESAVED_DETAILS,
  SEND_REQUEST_BOOKING_BUTTON_ID,
  SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST,
  SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST,
} from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { calculateLongTermPriceWithFeeOneMonth } from '../../util/longTermRentalHelpers';
import css from './CheckoutPage.css';
import { sendSmoveUserBookingNotification } from '../../util/slackNotify';
import { GTAG_ACTIONS, sendG4AEvent } from '../../util/gtag';

const STORAGE_KEY = 'CheckoutLongTermPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
export const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
export const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      isRetryButtonClicked: false,
      shouldRedirectToSearchPage: false,
      isUsingMastercard: false,
      enteredCard: null,
      nextLocation: {},
      isOpenConfirmLeaveModal: false,
      isBlocking: true,
    };
    this.stripe = null;
    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.pushEventEnterFormGTM = this.pushEventEnterFormGTM.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  pushEventEnterFormGTM(event) {
    const { speculatedTransaction } = this.props;
    initiateEventFromTransaction({
      props: this.props,
      transaction: speculatedTransaction,
      event,
    });
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      currentUser,
      timeSlotsObj,
      params,
      parentTransaction,
    } = this.props;
    const { transactionId } = params;
    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps && currentUser) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, currentUser, STORAGE_KEY, {
        timeSlotsObj,
        parentTransaction,
      });
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, parentTransaction, transaction, timeSlotsObj }
      : storedData(STORAGE_KEY);

    fetchSpeculatedTransaction(transactionId);

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onConfirmPaymentDeposit,
      // onCheckBookingOverlap,
      fetchedDepositTransaction,
      onCreatePaymentIntent,
    } = this.props;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    const fnHandleDepositCardPayment = fnParams => {
      const order = ensureTransaction(fnParams);
      const depositTx = order.deposit || fetchedDepositTransaction;
      if (!depositTx) {
        return Promise.resolve({
          mainTx: order,
          deposit: false,
        });
      }
      const hasPaymentIntents =
        depositTx.attributes.protectedData &&
        depositTx.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? depositTx.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentDepositIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};
      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const hasPaymentIntentUserActionsDone =
        paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status);
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: depositTx.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order: depositTx,
        userId: this.props.currentUser.id.uuid,
        mainTx: order,
        deposit: true,
      };
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({
            orderId: depositTx.id,
            paymentIntent: paymentDepositIntent,
            order: depositTx,
            mainTx: order,
            deposit: true,
            userId: this.props.currentUser.id.uuid,
          })
        : onHandleCardPayment(params);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, currentUser, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order,
        userId: this.props.currentUser.id.uuid,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent, order })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPaymentDeposit = ({ deposit, transactionId, order, mainTx }) => {
      if (deposit) {
        return onConfirmPaymentDeposit({
          order,
        }).then(() => {
          return mainTx;
        });
      } else {
        return Promise.resolve(mainTx);
      }
    };
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      const { listing } = pageData;
      return onConfirmPayment({
        ...fnParams,
        savedListing: listing,
        userId: this.props.currentUser.id.uuid,
      });
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      onCreatePaymentIntent,
      fnHandleDepositCardPayment,
      fnConfirmPaymentDeposit,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const tx = speculatedTransaction ? speculatedTransaction : storedTx;
    const params = {
      tx,
      paymentParams: optionalPaymentParams,
    };

    return handlePaymentIntentCreation(params);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true, isBlocking: false });

    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
      listing,
      intl,
      paymentDepositIntent,
    } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      paymentDepositIntent,
    };

    const { listing: savedListing, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const ensuredSpeculatedTransaction = ensureTransaction(speculatedTransaction);
    const tx = existingTransaction.booking ? existingTransaction : ensuredSpeculatedTransaction;

    const listingGTM = ensureListing(listing || savedListing || (tx && tx.listing));
    const rawProperties = createRawPropertiesForGTM({
      props: this.props,
      button: {
        buttonId: SEND_REQUEST_BOOKING_BUTTON_ID,
        text: intl.formatMessage({ id: 'StripePaymentForm.submitPaymentInfo' }),
      },
      listing: listingGTM,
      transaction: tx,
    });
    pushGTMBookEvent(rawProperties, EVENT_BOOK_CREATED_REQUEST_GUEST);
    if (paymentMethod === 'defaultCard') {
      pushGTMBookEvent(rawProperties, EVENT_BOOK_USED_PRESAVED_DETAILS);
    }
    this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { orderId, messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });
        const parentTransactionId = get(
          speculatedTransaction,
          'attributes.metadata.parentTransactionId'
        );

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = parentTransactionId
          ? pathByRouteName('OrderDetailsPage', routes, { id: parentTransactionId })
          : pathByRouteName('LandingPage', routes);
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);

        const particularParams = { existTransaction: true };
        rawProperties.ui.button = null;
        rawProperties.transaction.id = orderId;
        if (!!saveAfterOnetimePayment) {
          pushGTMBookEvent(rawProperties, EVENT_BOOK_SAVED_CARD_DETAILS, particularParams);
        }
        pushGTMBookEvent(rawProperties, EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST, particularParams);
        sendG4AEvent(rawProperties.transaction, GTAG_ACTIONS.ACTION_PURCHASE, {});
        pushGTMBookEvent(rawProperties, SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST, {
          ...particularParams,
          userDiffActionTaker: true,
          isServerSideEvent: true,
        });
        const instantBooking = get(listingGTM, 'attributes.publicData.instantBooking');
        if (instantBooking) {
          pushGTMBookEvent(rawProperties, EVENT_BOOK_REQUEST_ACCEPTED_HOST, {
            ...particularParams,
            userDiffActionTaker: true,
          });
          pushGTMBookEvent(rawProperties, EVENT_BOOK_REQUEST_ACCEPTED_GUEST, particularParams);
        }
        clearData(STORAGE_KEY);
        listing && listing.id && listing.id.uuid && triggerRelevanceSync(listing.id.uuid);
        trackRelevanceEvent('Booking Created', 'conversion');
        console.log('pass');
        if (
          currentUser &&
          currentUser.attributes &&
          currentUser.attributes.profile &&
          currentUser.attributes.profile.metadata &&
          Object.keys(currentUser.attributes.profile.metadata).length &&
          currentUser.attributes.profile.metadata.smoveType3 &&
          currentUser.attributes.profile.metadata.smoveType3 === 'HF'
        ) {
          sendSmoveUserBookingNotification({
            transactionId: orderId.uuid,
            hostId: currentUser.id.uuid,
          });
        }
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
        rawProperties.ui.button = null;
        pushGTMBookEvent(rawProperties, EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST);
        pushGTMBookEvent(rawProperties, SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST, {
          userDiffActionTaker: true,
          isServerSideEvent: true,
        });
      });
  }

  onRetrievePaymentDepositIntent = ({ depositId, stripe }) => {
    const { onFetchDepositTx, onRetrievePaymentIntent } = this.props;
    onFetchDepositTx({ id: depositId }).then(deposit => {
      const { stripePaymentIntentClientSecret } =
        deposit.attributes.protectedData && deposit.attributes.protectedData.stripePaymentIntents
          ? deposit.attributes.protectedData.stripePaymentIntents.default
          : {};
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret, deposit: true });
    });
  };

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};
      const { depositTx } = tx.attributes.protectedData || {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
      if (depositTx) {
        this.onRetrievePaymentDepositIntent({ depositId: depositTx, stripe });
      }
    }
  }

  handleNavigation = nextLocation => {
    this.setState({ isOpenConfirmLeaveModal: true, nextLocation });
    if (!this.state.isBlocking) return true;
    return false;
  };

  onConfirmLeave = () => {
    const { nextLocation } = this.state;
    const { history } = this.props;
    this.setState(
      {
        isBlocking: false,
        isOpenConfirmLeaveModal: false,
      },
      () => {
        history && history.push(nextLocation);
      }
    );
  };

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      onManageDisableScrolling,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      location,
      bookingOverlapError,
      retrievePaymentDepositIntentError,
      paymentDepositIntent,
    } = this.props;

    const { isBlocking = true, isOpenConfirmLeaveModal } = this.state;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const voucherIsInvalid = isTransactionInitiateInvalidVoucher(initiateOrderError);
    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;
    const { listing, bookingDates, transaction, parentTransaction } = this.state.pageData;
    const isInstantBooking =
      listing && listing.attributes && listing.attributes.publicData.instantBooking;

    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentBooking = ensureBooking(currentTransaction.booking);
    const currentAuthor = ensureUser(currentListing.author);

    const timezone = currentTransaction
      ? currentTransaction.attributes &&
        currentTransaction.attributes.protectedData &&
        currentTransaction.attributes.protectedData.transactionTimezone
      : currentListing.attributes &&
        currentListing.attributes.publicData &&
        currentListing.attributes.publicData.listingTimezone;

    let insuranceType = currentListing.attributes.publicData.insurance;
    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });

    const pageProps = { title, scrollingDisabled, className: css.root };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return (
        <Page {...pageProps}>
          {topbar}
          <div className={css.loading}>
            <IconSpinner />
            <div>Please wait...</div>
          </div>
        </Page>
      );
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!currentListing.id;
    const hasRequiredData = hasListingAndAuthor;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;
    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    if (this.state.shouldRedirectToSearchPage) {
      const searchParams = parse(location.search);
      const searchPageParams = searchParams && searchParams['searchPageParams'];
      const defaultSearchPageParams =
        '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798';
      return (
        <NamedRedirect name="SearchPage" search={searchPageParams || defaultSearchPageParams} />
      );
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const { profile } = ensureCurrentUser(currentUser).attributes;
    const isNewCar = get(listing, 'attributes.metadata.isNewCar');
    const canUseMastercardPromo = isNewCar
      ? false
      : checkCanUseMastercardPromo(ensureCurrentUser(currentUser));
    const timeZone = getDefaultTimeZoneOnBrowser(timezone);
    const breakdownLongTerm = tx.id && txBooking.id && (
      <div className={css.remainBreakdownLongTerm}>
        <h3 className={css.priceBreakdownLongTermTitle}>
          <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
        </h3>
        <CheckoutBreakdownLongTerm
          timeZone={timeZone}
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={restoreTransaction(
            tx,
            this.state.isUsingMastercard && canUseMastercardPromo
          )}
          parentTransaction={parentTransaction}
          booking={txBooking}
          shouldShowMastercardPromoLineItem={this.state.isUsingMastercard}
          hideBookingPeriod={true}
          isCheckoutLTM
          hidePaymentMade
        />
      </div>
    );

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired &&
      !bookingOverlapError &&
      !retrievePaymentDepositIntentError
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError =
      isTransactionInitiateBookingTimeNotAvailableError(initiateOrderError) || bookingOverlapError;
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let retryButton = null;
    let listingNotFoundErrorMessage = null;

    const redirectToListingButton = (
      <Button
        className={css.retryButton}
        inProgress={this.state.isRetryButtonClicked}
        onClick={() => {
          if (typeof window !== 'undefined') {
            const checkoutStrPos = window.location.href.indexOf('/checkout');
            const currentListingUrl = window.location.href.substr(0, checkoutStrPos);
            this.setState({ isRetryButtonClicked: true });
            window.location.href = currentListingUrl;
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    const reloadButton = (
      <Button
        className={css.retryButton}
        inProgress={this.state.isRetryButtonClicked}
        onClick={() => {
          if (typeof window !== 'undefined') {
            this.setState({ isRetryButtonClicked: true });
            window.location.reload();
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    const redirectToSearchPageButton = (
      <Button
        className={css.retryButton}
        onClick={() => {
          if (typeof window !== 'undefined') {
            this.setState({ shouldRedirectToSearchPage: true });
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    if (voucherIsInvalid) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.invalidVoucher" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
      retryButton = redirectToSearchPageButton;
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
      retryButton = reloadButton;
    } else if (initiateOrderError) {
      // Generic initiate order error
      // console.log({ initiateOrderError });

      if (initiateOrderError.name === 'insufficient_funds') {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorInsufficientFunds"
              values={{ listingLink }}
            />
          </p>
        );
        retryButton = reloadButton;
      } else if (initiateOrderError.status === 'card_error') {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorCardError"
              values={{ listingLink, message: initiateOrderError.message }}
            />
          </p>
        );
        retryButton = reloadButton;
      } else if (initiateOrderError.message) {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorWithMessage"
              values={{ listingLink, message: initiateOrderError.message }}
            />
          </p>
        );
        retryButton = redirectToListingButton;
      } else {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
          </p>
        );
        retryButton = reloadButton;
      }
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
      retryButton = redirectToSearchPageButton;
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
      retryButton = reloadButton;
    }

    const unitType = config.bookingUnitType;

    const unitTranslationKey = 'ListingPage.perMonth';

    const price = calculateLongTermPriceWithFeeOneMonth(speculatedTransactionMaybe);
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({ id: unitTranslationKey })}`;

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      (paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status)) ||
      (paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status));

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };

    const leavingPopup = (
      <div className={css.confirmPopup}>
        <div className={css.message}>
          {' '}
          <FormattedMessage
            id="CheckoutPage.confirmLeaveMessage"
            values={{ firstName: profile && profile.firstName }}
          />{' '}
        </div>
        <div className={css.buttonWrapper}>
          <Button
            className={css.buttonConfirmLeave}
            onClick={() => this.setState({ isOpenConfirmLeaveModal: false })}
          >
            <FormattedMessage id="CheckoutPage.confirmStay" />
          </Button>
          <Button className={css.buttonConfirmLeave} onClick={this.onConfirmLeave}>
            <FormattedMessage id="CheckoutPage.confirmLeave" />
          </Button>
        </div>
      </div>
    );

    return (
      <>
        <Prompt when={isBlocking} message={this.handleNavigation} />
        <Modal
          isOpen={isOpenConfirmLeaveModal}
          onClose={() => {
            this.setState({ isOpenConfirmLeaveModal: false });
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {leavingPopup}
        </Modal>
        <Page {...pageProps}>
          {topbar}
          <div className={css.contentContainer}>
            <div className={css.aspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
              <AvatarMedium user={currentAuthor} disableProfileLink />
            </div>
            <div className={css.bookListingContainer}>
              <div className={css.heading}>
                <h1 className={css.title}>{title}</h1>
                <div className={css.author}>
                  <FormattedMessage
                    id="CheckoutPage.hostedBy"
                    values={{ name: currentAuthor.attributes.profile.displayName }}
                  />
                </div>
              </div>

              <div className={css.priceBreakdownContainer}>
                {speculateTransactionErrorMessage}
                {breakdownLongTerm}
                <InsurancePanel
                  listing={currentListing}
                  className={css.insuranceWrapper}
                  hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                  insuranceType={insuranceType}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              </div>

              <section className={css.paymentContainer}>
                {initiateOrderErrorMessage}
                {listingNotFoundErrorMessage}
                {speculateErrorMessage}
                {retrievePaymentIntentError || retrievePaymentDepositIntentError ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.retrievingStripePaymentIntentFailed"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
                {retryButton}
                {showPaymentForm ? (
                  <StripePaymentForm
                    className={css.paymentForm}
                    onSubmit={this.handleSubmit}
                    inProgress={this.state.submitting}
                    formId="CheckoutPagePaymentForm"
                    paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                    authorDisplayName={currentAuthor.attributes.profile.displayName}
                    showInitialMessageInput={showInitialMessageInput}
                    initialValues={initalValuesForStripePayment}
                    initiateOrderError={initiateOrderError}
                    handleCardPaymentError={handleCardPaymentError}
                    confirmPaymentError={confirmPaymentError}
                    hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                    loadingData={!stripeCustomerFetched}
                    defaultPaymentMethod={
                      hasDefaultPaymentMethod
                        ? currentUser.stripeCustomer.defaultPaymentMethod
                        : null
                    }
                    isUsingMastercard={this.state.isUsingMastercard}
                    setIsUsingMastercard={val => {
                      this.setState({ isUsingMastercard: val });
                    }}
                    paymentIntent={paymentIntent}
                    onStripeInitialized={this.onStripeInitialized}
                    isInstantBooking={isInstantBooking}
                    submitButtonId={SEND_REQUEST_BOOKING_BUTTON_ID}
                    pushEventEnterFormGTM={this.pushEventEnterFormGTM}
                  />
                ) : null}
                {isPaymentExpired ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.paymentExpiredMessage"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
              </section>
            </div>

            <div className={css.detailsContainerDesktop}>
              <div className={css.detailsAspectWrapper}>
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  alt={listingTitle}
                  image={firstImage}
                  variants={['landscape-crop', 'landscape-crop2x']}
                />
              </div>
              <div className={css.avatarWrapper}>
                <AvatarMedium user={currentAuthor} disableProfileLink />
              </div>
              <div className={css.detailsHeadings}>
                <h2 className={css.detailsTitle}>{listingTitle}</h2>
                <p className={css.detailsSubtitle}>{detailsSubTitle}</p>
              </div>
              {speculateTransactionErrorMessage}
              {breakdownLongTerm}
              <InsurancePanel
                listing={currentListing}
                className={css.insuranceWrapper}
                hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                insuranceType={insuranceType}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
          </div>
        </Page>
      </>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    bookingOverlapError,
    timeSlotsObj,
    depositTx,
    transactionId,
    parentTransaction,
  } = state.CheckoutPage;
  const { currentUser } = state.user;

  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
  } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    bookingOverlapError,
    timeSlotsObj,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    fetchedDepositTransaction: depositTx,
    transactionId,
    parentTransaction,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchSpeculatedTransaction: id => dispatch(fetchTransaction(id)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onConfirmPaymentDeposit: params => dispatch(confirmPaymentDeposit(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  // onCheckBookingOverlap: (listingId, start, end) =>
  // dispatch(checkBookingOverlap(listingId, start, end)),
  onFetchDepositTx: params => dispatch(fetchDepositTx(params)),
  onCreatePaymentIntent: params => dispatch(createPaymentIntent(params)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPage.displayName = 'CheckoutLongTermPage';

export default CheckoutPage;
