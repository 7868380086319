import classNames from 'classnames';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { object } from 'prop-types';
import React, { Component } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldCustomNumberInput,
  FieldTextInput,
  FieldUploadFile,
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import {
  EVENT_GUEST_VERIFY_SUBMIT_DRIVER_FORM,
  EVENT_GUEST_VERIFY_UPLOAD_DRIVING_LICENSE,
  EVENT_GUEST_VERIFY_UPLOAD_NRIC,
  GUEST_VERIFY_DRIVER_SUBMIT_BUTTON_ID,
  UPLOAD_BACK_INTL_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_FRONT_INTL_DRIVING_LICENSE_BUTTON_ID,
  UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { pushGTMGuestVerify } from '../../util/gtm/gtmHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import setFiledTouched, { getAllPaths } from '../../util/setFiledTouched';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../util/validators';
import css from './TransactionVerificationForm.css';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

const ACCEPT_IMAGES = 'image/*';

export class TransactionVerificationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotUpload: true,
      isUploading: false,
      sentVerification: false,
      disableUploadInternationalLicense: false,
      drivingLicenseFrontUrl: props.drivingLicenseFrontUrl,
      drivingLicenseBackUrl: props.drivingLicenseBackUrl,
      hostHoldingDrivingLicenseUrl: props.hostHoldingDrivingLicenseUrl,
      passportFirstPageUrl: props.passportFirstPageUrl,
      passportLastPageUrl: props.passportLastPageUrl,
      photo: props.photo,
      isEditLocation: false,
    };
    this.setStateUploadData = this.setStateUploadData.bind(this);
    this.setUploadState = this.setUploadState.bind(this);

    const locationValue =
      (props.initialValues &&
        props.initialValues.location &&
        props.initialValues.location.selectedPlace &&
        props.initialValues.location.selectedPlace.address) ||
      null;
    this.autoCompleteLocationRef = React.createRef(locationValue);
  }

  setUploadState(data) {
    this.setState({
      isUploading: data.isUploading,
      isNotUpload: data.isNotUpload,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      drivingLicenseFrontUrl,
      drivingLicenseBackUrl,
      hostHoldingDrivingLicenseUrl,
      passportFirstPageUrl,
      passportLastPageUrl,
    } = nextProps;
    this.setState(prev =>
      Object.assign(
        {},
        pickBy(
          {
            drivingLicenseFrontUrl,
            drivingLicenseBackUrl,
            hostHoldingDrivingLicenseUrl,
            passportLastPageUrl,
            passportLastPageUrl,
          },
          identity
        ),
        this.state
      )
    );
  }

  setStateUploadData = (url, fileType) => {
    const { setData } = this.props;
    if (fileType === 'front-driving-license') {
      setData('drivingLicenseFront', url);
      this.setState(prev => ({
        ...prev,
        drivingLicenseFrontUrl: url,
      }));
    }
    if (fileType === 'back-driving-license') {
      setData('drivingLicenseBack', url);
      this.setState(prev => ({
        ...prev,
        drivingLicenseBackUrl: url,
      }));
    }
    if (fileType === 'host-holding-driving-license') {
      setData('hostHoldingDrivingLicensePhoto', url);
      this.setState(prev => ({
        ...prev,
        hostHoldingDrivingLicenseUrl: url,
      }));
    }
    if (fileType === 'file-upload-passport-first') {
      setData('passportFirstPage', url);
      this.setState(prev => ({
        ...prev,
        passportFirstPageUrl: url,
      }));
    }
    if (fileType === 'file-upload-passport-last') {
      setData('passportLastPage', url);
      this.setState(prev => ({
        ...prev,
        passportLastPageUrl: url,
      }));
    }
    if (fileType === 'photo') {
      setData('photo', url);
      this.setState(prev => ({
        ...prev,
        photo: url,
      }));
    }
  };

  handleOpenEditLocation = status => {
    this.setState({
      isEditLocation: status,
    });
  };

  pushEventGTM = buttonId => {
    const isNRIC = buttonId && buttonId.includes('nric');
    const event = isNRIC
      ? EVENT_GUEST_VERIFY_UPLOAD_NRIC
      : EVENT_GUEST_VERIFY_UPLOAD_DRIVING_LICENSE;
    pushGTMGuestVerify({
      props: this.props,
      event,
      buttonId,
      pageURL: '/account/driving-verification',
    });
  };

  fillUpFormBasedOnLocation = (location, form) => {
    const keepLocation = this.autoCompleteLocationRef.current;
    const selectedPlace = get(location, 'selectedPlace');
    if (!selectedPlace) {
      return null;
    }
    const address = get(location, 'selectedPlace.address');
    if (!address) {
      return null;
    }
    const isChanged = !isEqual(keepLocation, address);
    if (isChanged) {
      const addressComponents = get(selectedPlace, 'place.address_components', []);
      const postalCode = addressComponents.find(c => c.types.includes('postal_code'));
      const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'));
      const city = addressComponents.find(
        c => c.types.includes('administrative_area_level_2') || c.types.includes('colloquial_area')
      );
      form.batch(() => {
        postalCode && form.change('postalCode', postalCode.short_name);
        state && form.change('state', camelCase(state.long_name));
        city && form.change('city', city.short_name);
      });
      this.autoCompleteLocationRef.current = address;
    }
    return null;
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ setFiledTouched }}
        initialValues={
          this.state.initialValues ? this.state.initialValues : this.props.initialValues
        }
        onSubmit={values => {
          this.setState(prev => ({
            ...prev,
            initialValues: values,
          }));
          this.props.onSubmit(values);
        }}
        render={fieldRenderProps => {
          const {
            form,
            className,
            disabled,
            handleSubmit,
            intl,
            invalid,
            currentUser,
            saveActionMsg,
            values,
            updateIdentityError,
          } = fieldRenderProps;

          const isVerified =
            !!currentUser &&
            !!currentUser.id &&
            currentUser.identityStatus &&
            currentUser.identityStatus.guestVerified;
          const underVerify =
            !!currentUser &&
            !!currentUser.id &&
            currentUser.identityStatus &&
            currentUser.identityStatus.guestUnderVerify;

          const nricFnMessage = intl.formatMessage({ id: 'EditListingVerificationForm.nricFn' });
          const nricFnPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnPlaceholder',
          });
          const nricFnRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnRequired',
          });
          const nricFnNotValidMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnNotValid',
          });

          const accountNameMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountName',
          });
          const accountNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNamePlaceholder',
          });
          const accountNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.accountNameRequired',
          });

          const bankNameMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankName',
          });
          const bankNamePlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNamePlaceholder',
          });
          const bankNameRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankNameRequired',
          });

          const bankAccountNumberMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumber',
          });
          const bankAccountNumberPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberPlaceholder',
          });
          const bankAccountNumberRequiredMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.bankAccountNumberRequired',
          });

          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const buildingMessage = intl.formatMessage({ id: 'EditListingLocationForm.building' });
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          const cityMessage = intl.formatMessage({ id: 'EditListingLocationForm.city' });
          const cityPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityPlaceholder',
          });
          const stateLabel = intl.formatMessage({ id: 'EditListingLocationForm.state' });
          const statePlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.statePlaceholder',
          });

          const countryMessage = intl.formatMessage({ id: 'EditListingLocationForm.country' });
          const countryPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.countryPlaceholder',
          });

          const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
          const streetPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.streetNamePlaceholder',
          });

          const blockMessage = intl.formatMessage({ id: 'EditListingLocationForm.blockNo' });
          const blockPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.blockNoPlaceholder',
          });

          const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.unitNumber' });
          const floorPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.floorPlaceholder',
          });

          const cityRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.cityRequired',
          });
          const stateRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.stateRequired',
          });

          const countryRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.countryRequired',
          });

          const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodePlaceholder',
          });
          const postalCodeRequired = intl.formatMessage({
            id: 'PayoutDetailsForm.postalCodeRequired',
          });

          const submitInProgress = this.state.isUploading;
          const uploadedDrivingLicense = !!this.state.drivingLicenseFrontUrl;
          const uploadedDrivingLicenseBack = !!this.state.drivingLicenseBackUrl;
          const uploadedPassportFirstPage = !!this.state.passportFirstPageUrl;
          const uploadedPassportLastPage = !!this.state.passportLastPageUrl;
          const uploadedPhoto = !!this.state.photo;

          const hasProfilePhoto = currentUser && currentUser.profileImage;
          const initialPhoto = this.props.photo
            ? this.props.photo
            : hasProfilePhoto
            ? currentUser.profileImage.attributes.variants['square-small2x'].url
            : null;
          const disablesPhoto = !!this.props.photo;
          const needUploadPhoto = !hasProfilePhoto && !this.props.photo;
          const needUploadAvatar = !this.state.photo || this.state.photo === '';

          let needUploadFile;
          if (hasProfilePhoto) {
            needUploadFile = !uploadedDrivingLicense || !uploadedDrivingLicenseBack;
          } else {
            needUploadFile =
              !uploadedDrivingLicense || !uploadedDrivingLicenseBack || !uploadedPhoto;
          }
          const submitDisabled = invalid || disabled || submitInProgress || needUploadFile;

          const classes = classNames(css.root, className);
          const uploadDrivingLicense = (
            <div className={css.fieldSet}>
              <FieldUploadFile
                id="file-upload-host-holding-driving-license"
                name="file-upload-host-holding-driving-license"
                typeFile="host-holding-driving-license"
                // label={() => 'Photo of host holding Driving licence'}
                label={() => 'Selfie holding Driving licence'}
                setStateUploadData={this.setStateUploadData}
                twoColumns={false}
                currentUser={currentUser}
                setUploadState={this.setUploadState}
                initialValues={[this.props.hostHoldingDrivingLicenseUrl]}
                className={css.bottomField}
                buttonId={UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID}
                buttonId2={UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID}
                pushEventGTM={this.pushEventGTM}
              />
              <FieldUploadFile
                id="file-upload-front-driving-license"
                name="file-upload-front-driving-license"
                typeFile="front-driving-license"
                id2="file-upload-back-driving-license"
                name2="file-upload-back-driving-license"
                typeFile2="back-driving-license"
                label={() => 'Driving Licence'}
                setStateUploadData={this.setStateUploadData}
                twoColumns={true}
                currentUser={currentUser}
                setUploadState={this.setUploadState}
                initialValues={[
                  this.props.drivingLicenseFrontUrl,
                  this.props.drivingLicenseBackUrl,
                ]}
                className={css.bottomField}
                buttonId={UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID}
                buttonId2={UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID}
                pushEventGTM={this.pushEventGTM}
              />

              <FieldUploadFile
                id="file-upload-passport-first"
                name="file-upload-passport-first"
                typeFile="file-upload-passport-first"
                id2="file-upload-passport-last"
                name2="file-upload-passport-last"
                typeFile2="file-upload-passport-last"
                label={() => 'Passport (optional)'}
                setStateUploadData={this.setStateUploadData}
                twoColumns={true}
                currentUser={currentUser}
                className={css.bottomField}
                setUploadState={this.setUploadState}
                initialValues={[this.props.passportFirstPageUrl, this.props.passportLastPageUrl]}
                buttonId={UPLOAD_FRONT_INTL_DRIVING_LICENSE_BUTTON_ID}
                buttonId2={UPLOAD_BACK_INTL_DRIVING_LICENSE_BUTTON_ID}
                pushEventGTM={this.pushEventGTM}
                pushEventGTM2={this.pushEventGTM}
              />
            </div>
          );

          const locationLayout = (
            <div className={css.fieldSet}>
              <div className={css.displayInlineContainer}>
                <Field
                  name="location"
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      this.fillUpFormBasedOnLocation(value, form);
                      onChange(value);
                    };

                    const searchInput = { ...restInput, onChange: searchOnChange };
                    return (
                      <LocationAutocompleteInputField
                        input={searchInput}
                        meta={meta}
                        rootClassName={css.streetName}
                        className={css.streetName}
                        inputClassName={css.locationAutocompleteInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        name="location"
                        disabled={
                          this.props.initialValues &&
                          this.props.initialValues.location &&
                          !this.state.isEditLocation
                        }
                        label={streetMessage}
                        placeholder={streetPlaceholderMessage}
                        useDefaultPredictions={false}
                        format={v => v}
                        valueFromForm={values.location}
                        validate={composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognizedMessage)
                        )}
                      />
                    );
                  }}
                />
                {this.props.initialValues &&
                  this.props.initialValues.location &&
                  !this.state.isEditLocation && (
                    <div
                      onClick={() => this.handleOpenEditLocation(true)}
                      className={css.editLocation}
                    >
                      Edit
                    </div>
                  )}
              </div>

              <div className={css.displayInlineContainer}>
                <FieldTextInput
                  className={css.city}
                  type="text"
                  name="city"
                  id="city"
                  disabled={this.props.initialValues && this.props.initialValues.city}
                  label={cityMessage}
                  placeholder={cityPlaceholderMessage}
                  validate={composeValidators(required(cityRequiredMessage))}
                />
                <FieldCustomNumberInput
                  className={css.floor}
                  type="text"
                  name="unitNumber"
                  id="unitNumber"
                  disabled={this.props.initialValues && this.props.initialValues.floorUnit}
                  label={floorMessage}
                  placeholder={floorPlaceholderMessage}
                />
              </div>

              <div className={css.displayInlineContainer}>
                <FieldTextInput
                  className={css.city}
                  type="text"
                  name="state"
                  id="state"
                  disabled={this.props.initialValues && this.props.initialValues.city}
                  label={stateLabel}
                  placeholder={statePlaceholderMessage}
                  validate={composeValidators(required(stateRequiredMessage))}
                />
                <FieldTextInput
                  className={css.floor}
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  disabled={this.props.initialValues && this.props.initialValues.postalCode}
                  label={postalCodeLabel}
                  placeholder={postalCodePlaceholder}
                  validate={composeValidators(required(postalCodeRequired))}
                />
              </div>
            </div>
          );

          const imagesLayout = (
            <div className={css.fieldSet}>
              <p className={css.photoTip}>
                <FormattedMessage
                  id="EditListingVerificationForm.photoTip"
                  values={{ newline: <br /> }}
                />
              </p>
              <FieldUploadFile
                id="file-upload-photo"
                name="file-upload-photo"
                label={url => (
                  <div className={css.photoIdentify}>
                    {url && <img className={css.frontalPhoto} src={url} />}
                  </div>
                )}
                disablesPhoto={disablesPhoto}
                typeFile="photo"
                setStateUploadData={this.setStateUploadData}
                currentUser={currentUser}
                isPhoto={true}
                className={css.bottomField}
                setUploadState={this.setUploadState}
                acceptType={ACCEPT_IMAGES}
                initialValues={[initialPhoto]}
              />
            </div>
          );

          // const needNationalDrivingLicense = !(this.props.drivingLicenseFrontUrl &&
          //   this.props.drivingLicenseBackUrl);
          // const needInternationalLicense = !(this.props.internationalDrivingLicenseFrontUrl &&
          //   this.props.internationalDrivingLicenseBackUrl);

          // const needDrivingLicense = !needNationalDrivingLicense ? false : needInternationalLicense ? true : false;
          const underVerifyLayout = (
            <Form
              className={classes}
              onSubmit={e => {
                if (!invalid) {
                  handleSubmit(e);
                  this.setUploadState({ isUploading: true });
                  this.setState({
                    sentVerification: true,
                  });
                  pushGTMGuestVerify({
                    props: this.props,
                    event: EVENT_GUEST_VERIFY_SUBMIT_DRIVER_FORM,
                    buttonId: GUEST_VERIFY_DRIVER_SUBMIT_BUTTON_ID,
                  });
                }
                const paths = getAllPaths(form.getState().errors);
                paths.forEach(path => form.mutators.setFiledTouched(path, true));
              }}
            >
              {/* <FormSpy
                onChange={formState => {
                  console.log(formState);
                  this.setState({
                    initialValues: formState.values,
                  });
                }}
              /> */}
              {/* <p className={css.thankYouTextFirstTime}>
                <FormattedMessage id="TransactionIdentityVerificationForm.thankYouTextFirstTime" values={{ newline: (<br />) }} />
              </p> */}

              <div className={css.uploadLayoutWrapper}>{uploadDrivingLicense}</div>

              {imagesLayout}

              {locationLayout}

              <p className={css.readMore}>
                <FormattedMessage id="AddressDetailsForm.readMore" />
              </p>

              <div className={css.submitWrapper}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={updateIdentityError ? false : submitInProgress}
                  disabled={submitDisabled}
                  id={GUEST_VERIFY_DRIVER_SUBMIT_BUTTON_ID}
                >
                  {saveActionMsg}
                </Button>
              </div>
              {updateIdentityError && (
                <div className={css.errorRequired}>
                  {typeof updateIdentityError === 'string'
                    ? updateIdentityError
                    : updateIdentityError.message}
                </div>
              )}
            </Form>
          );

          const alreadyVerifiedLayout = (
            <p className={css.thankYouTextFirstTime}>
              <FormattedMessage
                id="EditListingVerificationForm.thankYou"
                values={{ newline: <br /> }}
              />
            </p>
          );
          const pendingVerifactionLayout = (
            <p className={css.thankYouTextFirstTime}>
              <FormattedMessage
                id="TransactionIdentityVerificationForm.thankYouTextFirstTimeVerified"
                values={{ newline: <br />, username: 'Chuan Do Tan' }}
              />
            </p>
          );

          // const verifyLayout = isVerified ? alreadyVerifiedLayout : underVerify ? pendingVerifactionLayout : underVerifyLayout;
          const verifyLayout = underVerifyLayout;
          // const finalLayout = this.state.sentVerification ? pendingVerifactionLayout : verifyLayout

          return verifyLayout;
        }}
      />
    );
  }
}

TransactionVerificationFormComponent.defaultProps = {};

TransactionVerificationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  saveActionMsg: object.isRequired,
};

export default compose(injectIntl)(TransactionVerificationFormComponent);
