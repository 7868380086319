import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import mateLogo from '../../assets/logos/drive-mate-long-white.png';
import LogoImageMobile from '../../assets/logos/logoImageMobile.png';
import css from './Logo.css';

const Logo = props => {
  const { className, format, logoSrc, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);
  if (format === 'desktop') {
    return (
      <img className={mobileClasses} src={logoSrc || mateLogo} alt={config.siteTitle} {...rest} />
    );
  }
  return <IconLogo classNames={mobileClasses} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

const IconLogo = ({ classNames }) => <img src={LogoImageMobile} className={classNames} loading='lazy' />;

export default Logo;
