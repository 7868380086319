export const CITY_BOUNDS = {
  // Perth Bounds:
  //      northmost, southmost, eastmost, westmost:  (-31.033626, -32.383994, 116.174214, 115.531250)
  //      NE: (-31.516371, 116.194218)
  //      SE: (-32.619478, 115.431321)
  Perth: {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: -31.516371,
      lng: 116.194218,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: -32.619478,
      lng: 115.431321,
    },
    northmost: -31.033626,
    southmost: -32.383994,
    eastmost: 116.174214,
    westmost: 115.53125,
  },
  // Sydney Bounds:
  //      northmost, southmost, eastmost, westmost:  (-33.419139197040636, -34.318460802959365, 151.75086196507584, 150.66773803492418)
  //      NE: (-33.418235, 151.725226)
  //      SE: (-34.319365, 150.693374)
  // address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798
  Sydney: {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: -33.41966205794024,
      lng: 151.725226,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: -34.319365,
      lng: 150.693374,
    },
    northmost: -33.41966205794024,
    southmost: -34.31797734205976,
    eastmost: 151.750246034722,
    westmost: 150.66834496527798,
  },
  // Melbourne Bounds:
  //      northmost, southmost, eastmost, westmost: (-37.36393919704064, -38.263260802959365, 145.53229340201386, 144.39390659798616)
  //      NE: (-37.311925, 145.512979)
  //      SE: (-38.815275, 143.413221)
  Melbourne: {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: -37.311925,
      lng: 145.512979,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: -38.815275,
      lng: 143.413221,
    },
    northmost: -37.36393919704064,
    southmost: -38.263260802959365,
    eastmost: 145.53229340201386,
    westmost: 144.39390659798616,
  },
  // Brisbane Bounds:
  //      northmost, southmost, eastmost, westmost: (-27.020139197040635, -27.919460802959367, 153.53190709213743, 152.5182929078626)
  //      NE: (-26.969606, 153.696015)
  //      SE: (-28.013955, 151.354186)
  Brisbane: {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: -26.969606,
      lng: 153.696015,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: -28.013955,
      lng: 151.354186,
    },
    northmost: -27.020139197040635,
    southmost: -27.919460802959367,
    eastmost: 153.53190709213743,
    westmost: 152.5182929078626,
  },
  // Adelaide Bounds:
  //      northmost, southmost, eastmost, westmost: (-34.573366, -35.306769, 138.780157, 138.387462)
  //      NE: (-34.575218, 139.008798)
  //      SE: (-35.414458, 138.234538)
  Adelaide: {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: -34.575218,
      lng: 139.008798,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: -35.414458,
      lng: 138.234538,
    },
    northmost: -34.573366,
    southmost: -35.306769,
    eastmost: 138.780157,
    westmost: 138.387462,
  },
};

export const CITY_SEARCH_BOUNDS = {
  perth: {
    // pub_listingTimezone: `Sydney`,
    address: 'Perth',
    bounds: `${CITY_BOUNDS.Perth.northmost},${CITY_BOUNDS.Perth.eastmost},${CITY_BOUNDS.Perth.southmost},${CITY_BOUNDS.Perth.westmost}`,
  },
  sydney: {
    // pub_listingTimezone: `Sydney`,
    address: 'Sydney',
    bounds: `${CITY_BOUNDS.Sydney.northmost},${CITY_BOUNDS.Sydney.eastmost},${CITY_BOUNDS.Sydney.southmost},${CITY_BOUNDS.Sydney.westmost}`,
  },
  melbourne: {
    // pub_listingTimezone: 'Melbourne',
    address: 'Melbourne',
    bounds: `${CITY_BOUNDS.Melbourne.northmost},${CITY_BOUNDS.Melbourne.eastmost},${CITY_BOUNDS.Melbourne.southmost},${CITY_BOUNDS.Melbourne.westmost}`,
  },
  brisbane: {
    // pub_listingTimezone: 'Brisbane',
    address: 'Brisbane',
    bounds: `${CITY_BOUNDS.Brisbane.northmost},${CITY_BOUNDS.Brisbane.eastmost},${CITY_BOUNDS.Brisbane.southmost},${CITY_BOUNDS.Brisbane.westmost}`,
  },
  adelaide: {
    // pub_listingTimezone: 'Brisbane',
    address: 'Adelaide',
    bounds: `${CITY_BOUNDS.Adelaide.northmost},${CITY_BOUNDS.Adelaide.eastmost},${CITY_BOUNDS.Adelaide.southmost},${CITY_BOUNDS.Adelaide.westmost}`,
  },
};

export const STATE_WITH_SHORT_NAME = [
  { state: 'Australian Capital Territory', short: 'ACT' },
  { state: 'australianCapitalTerritory', short: 'ACT' },
  { state: 'New South Wales', short: 'NSW' },
  { state: 'newSouthWales', short: 'NSW' },
  { state: 'Northern Territory', short: 'NT' },
  { state: 'northernTerritory', short: 'NT' },
  { state: 'Queensland', short: 'QLD' },
  { state: 'queensland', short: 'QLD' },
  { state: 'South Australia', short: 'SA' },
  { state: 'southAustralia', short: 'SA' },
  { state: 'Victoria', short: 'VIC' },
  { state: 'victoria', short: 'VIC' },
  { state: 'Tasmania', short: 'TAS' },
  { state: 'tasmania', short: 'TAS' },
  { state: 'Western Australia', short: 'WA' },
  { state: 'westernAustralia', short: 'WA' },
];
