import React, { useState } from 'react';
import css from './UCSDeclarationModal.css';
import Modal from '../Modal/Modal';
import { FormattedMessage } from 'react-intl';
import { ChecboxSwitch, NamedLink, PrimaryButton } from '..';
import classNames from 'classnames';

const UCSDeclarationModal = props => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const {
    isOpen,
    onClose,
    onManageDisableScrolling,
    handleSubmit,
    saveUCSDeclarationError,
    saveUCSDeclarationInProgress,
    saveUCSDeclarationSuccess,
    ucsHostDeclarationSigned,
    hasPublishedOrPendingApprovalListings,
  } = props;
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const nameOfLink = hasPublishedOrPendingApprovalListings
    ? 'ManageListingsPage'
    : 'NewListingPage';

  const btnText = hasPublishedOrPendingApprovalListings ? 'Go to your listings' : 'List your car';

  return (
    <Modal
      isOpen={isOpen}
      closeButtonMessage=" "
      onClose={onClose}
      containerClassName={css.container}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      {saveUCSDeclarationSuccess || ucsHostDeclarationSigned ? (
        <div className={classes}>
          <h2>You are successfully enrolled now.</h2>

          <p>
            Our team will be in touch. Please continue to list your car. For any questions, please
            call us or email us at{' '}
            <a href={`mailto:byod@drivemate.au`} className={css.buttonLink}>
              byod@drivemate.au
            </a>
          </p>

          <div class={css.buttonContainer}>
            <NamedLink name={nameOfLink} className={css.listYourCar}>
              {btnText}
            </NamedLink>
          </div>
        </div>
      ) : (
        <div className={classes}>
          <div className={css.toggleMapButton}>
            <ChecboxSwitch
              className={css.toggleCss}
              status={isChecked}
              onChange={handleCheckboxChange}
            />
            <span>I acknowledge and agree that:</span>
          </div>
          <ol className={css.alphabetListing}>
            <li>
              I am in possession of and the owner of one or more of
              <ul>
                <li>(1) an Otoplug and associated equipment</li>
                <li>(2) lockbox and window bracket(s),</li>
                <li>(3) primary GPS and</li>
                <li>
                  (4) secondary GPS (Existing Equipment) which is connected to my vehicles; and
                </li>
              </ul>
            </li>
            <li>
              In consideration for the payment of $1.00 to me by Drive Lah Australia Pty Ltd
              (trading as 'Drive Mate'), I hereby assign to Drive Lah Australia Pty Ltd all right,
              title and interest I have in all Existing Equipment in all vehicles which I register
              on the Drive Mate platform.
            </li>
            <li>
              The Existing Equipment is Enabling Equipment for the purposes of clause 13 (Ownership,
              use and return of Enabling Equipment) of the Terms of Service as amended from time to
              time.
            </li>
          </ol>

          <div class={css.buttonContainer}>
            <PrimaryButton
              onClick={handleSubmit}
              className={css.cancelButton}
              inProgress={saveUCSDeclarationInProgress}
              disabled={!isChecked}
            >
              {'Agree'}
            </PrimaryButton>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UCSDeclarationModal;
