import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { drivelahApiPost } from '../../util/apiHelper';
import { getCurrentUser } from '../../util/browserStorageHelper';
import { storableError } from '../../util/errors';
import { convertMoneyToNumber } from '../../util/currency';
import {
  denormalisedEntities,
  denormalisedResponseEntities,
  ensureListing,
  updatedEntities,
} from '../../util/data';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  txIsEnquired,
} from '../../util/transaction';
import {
  TRANSITION_ADDITIONAL_CONFIRM,
  TRANSITION_ADDITIONAL_CONFIRM_PAYMENT,
  TRANSITION_ADDITIONAL_REQUEST,
} from '../../util/transactionAddons';
import { updateParentTransaction } from '../../ducks/Tansactions.duck';

const apiUrl = config.apiUrl;
const { UUID, Money } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/AddOnsPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/AddOnsPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/AddOnsPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/AddOnsPage/FETCH_TRANSACTION_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/AddOnsPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/AddOnsPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/AddOnsPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/AddOnsPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/AddOnsPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/AddOnsPage/DECLINE_SALE_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/AddOnsPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/AddOnsPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/AddOnsPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_TX_PHONE_NUMBER_REQUEST = 'app/AddOnsPage/FETCH_TX_PHONE_NUMBER_REQUEST';
export const FETCH_TX_PHONE_NUMBER_SUCCESS = 'app/AddOnsPage/FETCH_TX_PHONE_NUMBER_SUCCESS';
export const FETCH_TX_PHONE_NUMBER_FAIL = 'app/AddOnsPage/FETCH_TX_PHONE_NUMBER_FAIL';

export const ADD_CHILD_LONG_TERM_TRANSACTIONS = 'app/AddOnsPage/ADD_CHILD_LONG_TERM_TRANSACTIONS';
export const ADD_NEXT_CHILD_TRANSACTION = 'app/AddOnsPage/ADD_NEXT_CHILD_TRANSACTION';
export const ADD_CURRENT_CHILD_TRANSACTION = 'app/AddOnsPage/ADD_CURRENT_CHILD_TRANSACTION';

export const INITIATE_ORDER_REQUEST = 'app/AddOnsPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/AddOnsPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/AddOnsPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/AddOnsPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/AddOnsPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/AddOnsPage/CONFIRM_PAYMENT_ERROR';

export const ESTIMATE_BREAKDOWN_REQUEST = 'app/AddOnsPage/ESTIMATE_BREAKDOWN_REQUEST';
export const ESTIMATE_BREAKDOWN_SUCCESS = 'app/AddOnsPage/ESTIMATE_BREAKDOWN_SUCCESS';
export const ESTIMATE_BREAKDOWN_ERROR = 'app/AddOnsPage/ESTIMATE_BREAKDOWN_ERROR';

export const RESET_TRANSACTION = 'app/AddonsPage/RESET_TRANSACTION';

export const STRIPE_CUSTOMER_REQUEST = 'app/AddonsPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/AddonsPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/AddonsPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextLongTermTransaction: null,
  childLongTermTransactions: [],
  currentChildLongTermTransaction: null,
  estimateBreakdownInProgress: false,
  estimatedTx: null,
  estimateError: null,
  stripeCustomerFetched: false,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
};

export default function addonsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case ADD_CHILD_LONG_TERM_TRANSACTIONS:
      return { ...state, childLongTermTransactions: payload };
    case ADD_NEXT_CHILD_TRANSACTION:
      return { ...state, nextLongTermTransaction: payload };
    case ADD_CURRENT_CHILD_TRANSACTION:
      return { ...state, currentChildLongTermTransaction: payload };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case ESTIMATE_BREAKDOWN_REQUEST:
      return { ...state, estimateBreakdownInProgress: true, estimateError: false };
    case ESTIMATE_BREAKDOWN_SUCCESS:
      return { ...state, estimateBreakdownInProgress: false, estimatedTx: payload };
    case ESTIMATE_BREAKDOWN_ERROR:
      return { ...state, estimateBreakdownInProgress: false, estimateError: payload };

    case RESET_TRANSACTION:
      return { ...state, estimatedTx: null };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
const fetchTimeSlotsError = e => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: e,
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });
const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});
const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });
const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});
const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const addChildLongTermTransactions = childLongTermTransactions => ({
  type: ADD_CHILD_LONG_TERM_TRANSACTIONS,
  payload: childLongTermTransactions,
});
const addNextLongTermTransaction = nextLongTermTransaction => ({
  type: ADD_NEXT_CHILD_TRANSACTION,
  payload: nextLongTermTransaction,
});
const addCurrentChildLongTermTransaction = currentChildLongTermTransaction => ({
  type: ADD_CURRENT_CHILD_TRANSACTION,
  payload: currentChildLongTermTransaction,
});

const resetAdditionalTransaction = () => ({ type: RESET_TRANSACTION });

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const resetTransaction = () => (dispatch, getState, sdk) => {
  return dispatch(resetAdditionalTransaction());
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'customer.attributes.protectedData',
          'customer.attributes.profile.protectedData',
          'customer.attributes.profile.publicData',
          'provider',
          'provider.profileImage',
          'provider.attributes.protectedData',
          'provider.attributes.profile.protectedData',
          'provider.attributes.profile.publicData',
          'listing',
          'listing.images',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(async response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
      if (isLongTerm) {
        const { currentUser } = getState().user;
        const cachedUser = getCurrentUser();
        const userId = (cachedUser && cachedUser.id.uuid) || (currentUser && currentUser.id.uuid);
        const childTransactionIds = get(transaction, 'attributes.metadata.childTransactionIds');
        const getBunchTransactionURL = 'transactions/retrieve-bunch-transactions';
        const data = {
          userId,
          transactionIds: childTransactionIds,
        };
        const childTransactionsRes = await drivelahApiPost(getBunchTransactionURL, data);
        const transactionParent = get(txResponse, 'data.data');
        const childTransactions = get(childTransactionsRes, 'data.data');
        dispatch(addChildLongTermTransactions(childTransactions));
        const nextTransactionId = get(transactionParent, 'attributes.metadata.nextTransaction');
        const currentChildTransactionId = get(
          transactionParent,
          'attributes.metadata.currentChildTransaction'
        );
        const nextTransaction = childTransactions.find(
          child => child.id.uuid === nextTransactionId
        );
        const currentChildTransaction = childTransactions.find(
          child => child.id.uuid === currentChildTransactionId
        );
        dispatch(addNextLongTermTransaction(nextTransaction));
        dispatch(addCurrentChildLongTermTransaction(currentChildTransaction));
      }
      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        if (txRole === 'customer') {
          return sdk.listings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        } else {
          return sdk.ownListings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        }
      } else {
        return response;
      }
    })
    .then(response => {
      const finalTxResponse = txResponse;

      dispatch(addMarketplaceEntities(finalTxResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(finalTxResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().AddOnsPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([dispatch(fetchTransaction(txId, txRole))]);
};

export const estimateBreakdown = params => (dispatch, getState, sdk) => {
  const {
    bookingStart,
    bookingEnd,
    listing,
    isDelivery,
    isExcessReduction,
    isFuelInclusion,
    parentTransaction,
  } = params;

  let transition = TRANSITION_ADDITIONAL_REQUEST;

  const bodyParams = {
    processAlias: config.additionalTransactionProcess,
    transition,
    currentPage: 'AddOnsPage',
    addons: true,
    cardToken: 'AddOnsPage_speculative_card_token',
    params: {
      bookingStart,
      bookingEnd,
      bookingDisplayStart: bookingStart,
      bookingDisplayEnd: bookingEnd,
      listingId: listing.id,
      protectedData: {
        parentTransaction: parentTransaction,
      },
      isDelivery,
      isExcessReduction,
      isFuelInclusion,
    },
    isSecurityDeposit: true,
    platform: 'web',
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  dispatch({ type: ESTIMATE_BREAKDOWN_REQUEST });

  return sdk.jh.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(response => {
      const [tx] = denormalisedResponseEntities(response);
      if (!tx) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }

      dispatch({ type: ESTIMATE_BREAKDOWN_SUCCESS, payload: tx });
      return tx;
    })
    .catch(e => {
      dispatch({ type: ESTIMATE_BREAKDOWN_ERROR, payload: storableError(e) });
    });
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser(null, { include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      console.log('success', response);
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      console.log('error', e);
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  const finalBookingProcess = config.additionalTransactionProcess;
  const {
    transition,
    parentTransactionId,
    isDelivery,
    isExcessReduction,
    isFuelInclusion,
    addonsTransactions,
    ...rest
  } = orderParams;
  const { bookingStart, bookingEnd } = orderParams;
  const bodyParams = {
    processAlias: finalBookingProcess,
    transition: transition,
    params: {
      ...rest,
      isDelivery,
      isExcessReduction,
      isFuelInclusion,
      bookingStart: moment(bookingStart).toDate(),
      bookingEnd: moment(bookingEnd).toDate(),
    },
    addons: true,
    isSecurityDeposit: true,
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  const createOrder = sdk.jh.transactions.initiate;

  // const createOrder = transactionId ? sdk.jh.transactions.transition : sdk.jh.transactions.initiate;

  let manualErorr = null;
  let order = null;

  return sdk.currentUser
    .show()
    .then(user => {
      return user;
    })
    .then(user => {
      return createOrder(bodyParams, queryParams);
    })
    .then(orderData => {
      return orderData;
    })
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      order = entities[0];

      const mapedLineItems = order.attributes.lineItems.map(item => {
        return {
          ...item,
          lineTotal: convertMoneyToNumber(item.lineTotal),
          unitPrice: convertMoneyToNumber(item.unitPrice),
        };
      });

      const addonsTransaction = {
        transactionId: order.id.uuid,
        childTransaction: JSON.stringify(order),
        isDelivery: isDelivery,
        isExcessReduction: isExcessReduction,
        isFuelInclusion: isFuelInclusion,
      };

      await dispatch(
        updateParentTransaction(parentTransactionId, {
          isDeliveryAddons: isDelivery,
          isExcessReductionAddons: isExcessReduction,
          isFuelInclusionAddons: isFuelInclusion,
          addonsTransactions: addonsTransactions
            ? [...addonsTransactions, addonsTransaction]
            : [addonsTransaction],
        })
      );

      return null;
    })
    .then(depositResponse => {
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    })
    .catch(e => {
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        bookingStart: orderParams.bookingStart,
        bookingEnd: orderParams.bookingEnd,
      });
      const resultError = e && e.error ? e.error : e;
      return dispatch(initiateOrderError(manualErorr ? manualErorr : storableError(resultError)));
    });
};

export const confirmPayment = ({
  savedListing,
  order: orderFromParams,
  parentTransactionId,
  ...orderParams
}) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  const listing = getState().CheckoutPage.listing ? getState().CheckoutPage.listing : savedListing;
  const { publicData = {} } = ensureListing(listing).attributes;
  const transition = TRANSITION_ADDITIONAL_CONFIRM_PAYMENT;
  const bodyParams = {
    id: orderParams.transactionId,
    transition,
    params: {},
  };

  return sdk.transactions
    .show({
      id: parentTransactionId,
    })
    .then(res => {
      const lastTransition = res.data.data.attributes.lastTransition;

      if (
        lastTransition === TRANSITION_ACCEPT ||
        lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT
      ) {
        bodyParams.transition = TRANSITION_ADDITIONAL_CONFIRM;
      }

      return sdk.transactions.transition(bodyParams, { expand: true });
    })
    .then(response => {
      const order = denormalisedResponseEntities(response)[0]; // response.data.data;
      dispatch(confirmPaymentSuccess(order.id));

      // if (publicData.instantBooking) {
      //   captureTransaction(orderParams.transactionId.uuid).then(data => {
      //     console.log(`captured instant transaction ${orderParams.transactionId.uuid}`);
      //   });
      // }
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};
