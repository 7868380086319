import React from 'react';
import css from './LandingPageGo.css';
import classNames from 'classnames';

import goLogo from '../../assets/logos/GoLogo.png';
import goLogo2x from '../../assets/logos/GoLogo@2x.png';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';
import LazyImage from '../../components/LazyImage/LazyImage';

const SectionHero = () => {
  return (
    <div className={css.sectionHero}>
      <LazyImage imgHeight={'125px'} imgWidth={'280px'} srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogoDesktop} />
      <LazyImage imgHeight={'54px'} imgWidth={'125px'} srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogoMobile} />
      <div className={css.sectionHeroBg} title="Daily short term rental cars near you | Drive Mate">
        <h1 className={css.title}>
          <FormattedMessage id="LandingPageGo.SectionHero.title" />
        </h1>
        <div className={classNames(css.description, css.sectionHeroDescription)}>
          <FormattedMessage
            id="LandingPageGo.SectionHero.description"
            values={{ newLine: <br /> }}
          />
        </div>
        <NamedLink
          name="LandingPageGoHost"
          className={classNames(css.desktop, css.goToSearchButton)}
        >
          <FormattedMessage id="LandingPageGo.learnMore" />
        </NamedLink>
      </div>

      <NamedLink name="LandingPageGoHost" className={classNames(css.mobile, css.goToSearchButton)}>
        <FormattedMessage id="LandingPageGo.learnMore" />
      </NamedLink>
    </div>
  );
};

export default SectionHero;
