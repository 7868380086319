import config from '../config';

const apiUrl = config.apiUrl;

export const START_CHECK_ELIGIBILITY = 'DRIVELAH_GO_ELIGIBILITY_CHECK_REQUESTED';
export const REQUEST_APPOINTMENT = 'DRIVELAH_GO_APPOINTMENT_REQUESTED';
export const DRIVELAH_GO_ELIGIBILITY_CHECK_NOTIFY_REQUESTED =
  'DRIVELAH_GO_ELIGIBILITY_CHECK_NOTIFY_REQUESTED';

export const sendCheckEligibilityStatus = ({ userId, userEmail }, eventType) => {
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      userEmail,
      eventType,
    }),
  }).then(res => {
    if (res.ok) return res.json();
    throw res.json();
  });
};

export const checkPaidDeposit = ({ userId }) => {
  return fetch(apiUrl + `/api/queries/user-paid-drivelah-go-deposit/${userId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(res => {
    if (res.ok) return res.json();
    throw res.json();
  });
};
