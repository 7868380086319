import classNames from 'classnames';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './SocialSharing.css';
import config from '../../config';

const SocialSharing = props => {
  const { show, setOpenShareDialog, openShareDialog, socialSharingLinks } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });

  useEffect(() => {
    loadFbSdk();
  }, []);

  const loadFbSdk = () => {
    if (window) {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: config.facebookAppId,
          cookie: true, // enable cookies to allow the server to access
          // the session
          autoLogAppEvents: true,
          xfbml: true, // parse social plugins on this page
          version: 'v2.7',
        });
      };

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  return (
    <>
      <div className={classNames(css.shareDialog, openShareDialog ? css.isOpen : '')}>
        <div className={css.socialShareInner}>
          <header>
            <h3 className={css.dialogTitle}>Share</h3>
            <button className={css.closeButton} onClick={() => setOpenShareDialog(false)}>
              <svg>
                <use href="#close"></use>
              </svg>
            </button>
          </header>
          <div className={css.targets}>
            {socialSharingLinks &&
              socialSharingLinks.length > 0 &&
              socialSharingLinks.map(socials => {
                return (
                  <a
                    className={css.shareButton}
                    onClick={() => socials.callback(socials.sharingMsg, socials.sharingUrl) || {}}
                    href={socials.sharingUrl}
                    target="_blank"
                  >
                    <span className={css.logo}>
                      <img src={socials.siteLogo} />
                    </span>
                    <span>{socials.siteName}</span>
                  </a>
                );
              })}
          </div>
          {/* <div className={css.link}>
            <div className={css.penUrl}>https://codepen.io/ayoisaiah/pen/YbNazJ</div>
            <button className={css.copyLink}>Copy Link</button>
          </div> */}
          {/* <button className={css.shareButton} type="button" title="Share this article">
            <svg>
              <use href="#share-icon"></use>
            </svg>
            <span>Share</span>
          </button>
 */}
          <svg className={css.hidden}>
            <defs>
              <symbol
                id="share-icon"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className={css.feather}
              >
                <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                <polyline points="16 6 12 2 8 6"></polyline>
                <line x1="12" y1="2" x2="12" y2="15"></line>
              </symbol>

              <symbol
                id="facebook"
                viewBox="0 0 24 24"
                fill="#3b5998"
                stroke="#3b5998"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className={css.feather}
              >
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
              </symbol>

              <symbol
                id="twitter"
                viewBox="0 0 24 24"
                fill="#1da1f2"
                stroke="#1da1f2"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className={css.feather}
              >
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
              </symbol>

              <symbol
                id="email"
                viewBox="0 0 24 24"
                fill="#777"
                stroke="#fafafa"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className={css.feather}
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </symbol>

              <symbol
                id="linkedin"
                viewBox="0 0 24 24"
                fill="#0077B5"
                stroke="#0077B5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className={css.feather}
              >
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </symbol>

              <symbol
                id="close"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className={css.feather}
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="9" y1="9" x2="15" y2="15"></line>
                <line x1="15" y1="9" x2="9" y2="15"></line>
              </symbol>
            </defs>
          </svg>
        </div>
      </div>
    </>
  );
};

const { bool, func, node } = PropTypes;

SocialSharing.propTypes = {
  show: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
};

export default SocialSharing;
