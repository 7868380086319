import React from 'react';
import { bool } from 'prop-types';
import get from 'lodash/get';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from '../BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemLongTermRefundCustomer = props => {
  const { transaction, intl, isCustomer } = props;
  if (!isCustomer) {
    return null;
  }

  const refundLabel = <FormattedMessage id={'BookingBreakdownLongTerm.refundLongTermLabel'} />;

  const twoMonthsPrice = transaction.attributes.payinTotal;
  let refund = get(transaction, 'attributes.metadata.needRefund', 0);
  refund = new Money(refund, twoMonthsPrice.currency);
  const formattedTotalPrice = formatMoney(intl, refund);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{refundLabel}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemLongTermRefundCustomer.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemLongTermRefundCustomer;
