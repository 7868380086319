import React from 'react';
import classNames from 'classnames';
import css from './TrustAndSafetyPage.css';

const WhenYouDriveItem = props => {
  const { imageSource, content, title, width, height } = props;
  return (
    <div className={css.whenYouDriveItemContainer}>
      <div className={css.whenYouDriveItemTitleContainer}>
        {/* <img width={width} height={height} className={css.whenYouDriveItemImage} src={imageSource}/> */}
        <div className={classNames(css.whenYouDriveItemTitle, css.whenYouDriveItemTitleMobile)}>
          {title}
        </div>
      </div>

      <div className={css.whenYouDriveItemTextBox}>
        <p>
          <strong className={css.whenYouDriveItemTitle}>{title}</strong>
          {content}
        </p>
      </div>
    </div>
  );
};

export default WhenYouDriveItem;
