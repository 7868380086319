import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import LogoImage from './../../assets/logos/drive-mate-long-blue.png';
import css from './ThemedLogo.css';

const ThemedLogo = props => {
  const { className, format, logoSrc, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);
  if (format === 'desktop') {
    return (
      <img className={mobileClasses} src={logoSrc || LogoImage} alt={config.siteTitle} {...rest} />
    );
  }
  return <IconLogo classNames={mobileClasses} />;
};

const { oneOf, string } = PropTypes;

ThemedLogo.defaultProps = {
  className: null,
  format: 'desktop',
};

ThemedLogo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

const IconLogo = ({ classNames }) => <img src={LogoImage} className={classNames} />;

export default ThemedLogo;
