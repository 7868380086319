import React, { useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { PasswordChangeForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { changePassword, changePasswordClear } from './PasswordChangePage.duck';
import css from './PasswordChangePage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';

export const PasswordChangePageComponent = props => {
  const {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    onChange,
    onSubmitChangePassword,
    passwordChanged,
    scrollingDisabled,
    intl,

    connectCalendarTabVisibility,
    fetchCalendarVisibility,
  } = props;

  useEffect(() => {
    fetchCalendarVisibility();
  }, []);

  let tabs = [...config.custom.tabs];
  const user = ensureCurrentUser(currentUser);
  tabs = tabs
    .map(tab => ({ ...tab }))
    .filter(tab => {
      if (!(user && ensureHostVerified(user))) {
        if (tab.linkProps.name === 'MonthlyStatementPage') {
          return false;
        }
      }
      if (tab.linkProps.name === 'SyncCalenderPage') {
        return Boolean(connectCalendarTabVisibility);
      }
      return true;
    });
  const currentTab = tabs.find(tab => tab.linkProps.name === 'PasswordChangePage');
  if (currentTab) {
    currentTab.selected = true;
  }

  const isShowChangePasswordForm = get(
    currentUser,
    'attributes.profile.privateData.isCreatedViaForm',
    false
  );
  const isShowGoogleText =
    get(currentUser, 'attributes.identityProviders[0].idpId', null) === 'google';
  const isShowFacebookText =
    get(currentUser, 'attributes.identityProviders[0].idpId', null) === 'facebook';
  const isShowAppleText =
    get(currentUser, 'attributes.identityProviders[0].idpId', null) === 'applelogin';
  const providertest = get(currentUser, 'attributes.identityProviders[0]', null);
  console.log('ID Provider: ', providertest);
  const changePasswordForm = isShowGoogleText ? (
    <h2 className={css.title}>
      <FormattedMessage id="PasswordChangePage.registeredWithGoogleMessage" />
    </h2>
  ) : isShowFacebookText ? (
    <h2 className={css.title}>
      <FormattedMessage id="PasswordChangePage.registeredWithFacebookMessage" />
    </h2>
  ) : isShowAppleText ? (
    <h2 className={css.title}>
      <FormattedMessage id="PasswordChangePage.registeredWithAppleMessage" />
    </h2>
  ) : (
    <PasswordChangeForm
      className={css.form}
      changePasswordError={changePasswordError}
      currentUser={currentUser}
      onSubmit={onSubmitChangePassword}
      onChange={onChange}
      inProgress={changePasswordInProgress}
      ready={passwordChanged}
    />
  );

  const title = intl.formatMessage({ id: 'PasswordChangePage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PasswordChangePage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="PasswordChangePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={currentUser} />
        {/* <LayoutWrapperAccountSettingsSideNav currentTab="PasswordChangePage" /> */}
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PasswordChangePage.heading" />
            </h1>
            {changePasswordForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PasswordChangePageComponent.defaultProps = {
  changePasswordError: null,
  currentUser: null,
};

const { bool, func } = PropTypes;

PasswordChangePageComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  } = state.PasswordChangePage;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const { currentUser } = state.user;
  return {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    passwordChanged,
    scrollingDisabled: isScrollingDisabled(state),
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(changePasswordClear()),
  onSubmitChangePassword: values => dispatch(changePassword(values)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});

const PasswordChangePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PasswordChangePageComponent);

export default PasswordChangePage;
