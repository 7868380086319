import React from 'react';
import css from './ListingPageGo.css';
import { AvatarMedium, InlineTextButton, Modal, NamedLink, StarIcon } from '../../../components';
import { ensureCurrentUser, ensureUser } from '../../../util/data';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { EnquiryForm } from '../../../forms';

const getResponseTimeText = averageResponseTime => {
  if (!averageResponseTime || averageResponseTime === 0) return 'N/A';
  const convertedSecond = averageResponseTime / 1000;
  const convertedMinutes = convertedSecond / 60;
  const convertedHours = convertedMinutes / 60;
  const convertedDays = convertedHours / 24;
  const textResult = '';
  if (convertedDays > 30) {
    return `${textResult} months`;
  }
  if (convertedDays > 7) {
    return `${textResult} within weeks`;
  }
  if (convertedDays > 1) {
    return `${textResult} days`;
  }
  if (convertedHours >= 1) {
    return `${textResult} ${Math.ceil(convertedHours)} ${convertedHours === 1 ? `hour` : `hours`}`;
  }
  if (convertedMinutes >= 1) {
    return `${textResult} ${Math.ceil(convertedMinutes)} ${
      convertedMinutes === 1 ? `minute` : `minutes`
    }`;
  } else
    return `${textResult} ${Math.ceil(convertedSecond)} ${
      convertedSecond === 1 ? `second` : `seconds`
    }`;
};

const SectionHost = props => {
  const {
    title,
    listing,
    authorDisplayName,
    initialValues,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    monthlyTimeSlots,
    timeSlots,
    onManageDisableScrolling,
    buttonId,
    localTimeZone,
    onFetchTimeSlots,
    onViewProfile,
  } = props;
  const ensuredAuthor = ensureUser(listing.author);
  const { averageReviews, profile, createdAt, authorIntercomData = {} } = ensuredAuthor.attributes;
  const { displayName } = profile;
  const {
    average_booking_responded_time_as_host,
    number_of_booking_responded_as_host,
    booking_requests_received,
  } = authorIntercomData.attributes || {};

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredAuthor.id &&
    ensuredCurrentUser.id &&
    ensuredAuthor.id.uuid === ensuredCurrentUser.id.uuid;

  const responseTime = getResponseTimeText(average_booking_responded_time_as_host);

  const handleContactUserClick = () => {
    onContactUser(ensuredAuthor);
  };

  const separator = <span className={css.linkSeparator}>•</span>;

  const contact = (
    <InlineTextButton rootClassName={css.contact} onClick={handleContactUserClick}>
      <FormattedMessage id="ListingPageGo.SectionHost.contactUser" />
    </InlineTextButton>
  );

  const links = ensuredAuthor.id ? (
    <p className={css.links}>
      <NamedLink
        className={css.link}
        name="ProfilePage"
        params={{ id: ensuredAuthor.id.uuid }}
        onClick={onViewProfile}
      >
        <FormattedMessage id="ListingPageGo.SectionHost.viewProfileLink" />
      </NamedLink>
      {isCurrentUser ? null : separator}
      {isCurrentUser ? null : contact}
    </p>
  ) : null;

  return (
    <div className={css.section}>
      <div className={css.sectionTitle}>Hosted by</div>
      <div className={css.sectionHostContentWrapper}>
        <div className={css.avatarWrapper}>
          <AvatarMedium user={ensuredAuthor} />
        </div>
        <div className={css.sectionHostContent}>
          <div className={css.hostName}>{displayName}</div>
          <div className={css.avgReview}>
            <StarIcon size={10} isFilled />
            <span className={css.avgVal}>{averageReviews}</span>
          </div>
          <div className={css.createAt}>
            <FormattedMessage
              id="LandingPageGo.SectionHost.createAt"
              values={{ date: moment(createdAt).format('MMMM YYYY') }}
            />
          </div>
          {responseTime !== 'N/A' ? (
            <div className={css.responseTime}>
              <FormattedMessage
                id="LandingPageGo.SectionHost.respondTime"
                values={{ responseTime }}
              />
            </div>
          ) : null}
          {number_of_booking_responded_as_host && booking_requests_received ? (
            <div className={css.responseTime}>
              Response rate:&nbsp;
              {((number_of_booking_responded_as_host / booking_requests_received) * 100).toFixed(0)}
              %
            </div>
          ) : null}
          {links}
        </div>
      </div>
      <Modal
        id="ListingPageGo.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          timeSlots={timeSlots}
          initialValues={initialValues}
          currentUser={currentUser}
          listing={listing}
          localTimeZone={localTimeZone}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
          buttonId={buttonId}
        />
      </Modal>
    </div>
  );
};

export default SectionHost;
