import React, { memo, useState } from 'react';
import css from './SeoLandingPage.css';
import { sendGAEvent } from '../../util/googleAnalytics';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import imageSource from '../../util/imageSource';

export const Channels = () => (
  <div className={css.channelsListings}>
    <div className={css.channels}>
      <div className={css.channelsRow}>
        <a
          href="https://www.straitstimes.com/singapore/transport/car-not-used-much-rent-it-out-for-cash"
          className={css.channel}
          target="_blank"
        >
          <img
            className={css.channelImage}
            src={imageSource.seoLandingPage.theStraitsTime}
            alt="THE STRAITS TIMES"
          />
        </a>
        <a
          href="https://www.facebook.com/drivelah2018/videos/drive-lah-featured-on-channel-news-asia/991990264536564/"
          target="_blank"
          className={css.channel}
        >
          <img className={css.channelImage} src={imageSource.seoLandingPage.cna} alt="CNA" />
        </a>
        <a
          href="https://vulcanpost.com/679151/p2p-car-sharing-drive-lah-singapore/"
          target="_blank"
          className={css.channel}
        >
          <img
            className={css.channelImage}
            src={imageSource.seoLandingPage.vulcanPost}
            alt="Vulcan Post"
          />
        </a>
        <a
          href="https://www.zaobao.com.sg/news/singapore/story20191116-1005643"
          className={css.channel}
          target="_blank"
        >
          <img className={css.channelImage} src={imageSource.seoLandingPage.zaobao} alt="ZAOBAO" />
        </a>
      </div>
    </div>
  </div>
);

const SectionRealBenefits = memo(({ onManageDisableScrolling, onPushGTMSeeVideoEvent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenVideo = () => {
    onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
    sendGAEvent({
      eventCategory: 'Others',
      eventAction: 'See How To Video',
    });
    setIsOpen(true);
  };

  return (
    <div className={css.sectionRealBenefits}>
      <Channels />
    </div>
  );
});

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/OaOzjZiVaws"
      ></iframe>
    </div>
  );
};

export default SectionRealBenefits;
