exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TripModificationPeriod_modificationDatesRoot__527Tj {\n  margin-top: 20px;\n  background-color: #ffffff;\n  border: 1px solid #e6e6e6;\n  padding: 15px;\n  border-radius: 4px\n}\n\n@media (max-width: 767px) {\n\n.TripModificationPeriod_modificationDatesRoot__527Tj {\n    border-radius: 0\n}\n  }\n\n.TripModificationPeriod_head__zE5TG {\n  display: block;\n  color: #7c7c7c;\n  margin-bottom: 10px;\n  font-weight: 500;\n  font-size: 16px;\n}\n\n.TripModificationPeriod_datesContainer__7yCtv {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .TripModificationPeriod_item__de0sA {\n  }\n\n  .TripModificationPeriod_original__2sCFk {\n    color: #4a4a4a;\n    font-size: 18px;\n    font-weight: 700;\n  }\n\n  .TripModificationPeriod_updated__26GaA {\n    color: #026786;\n    font-size: 18px;\n    font-weight: 700;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"modificationDatesRoot": "TripModificationPeriod_modificationDatesRoot__527Tj",
	"head": "TripModificationPeriod_head__zE5TG",
	"datesContainer": "TripModificationPeriod_datesContainer__7yCtv",
	"item": "TripModificationPeriod_item__de0sA",
	"original": "TripModificationPeriod_original__2sCFk",
	"updated": "TripModificationPeriod_updated__26GaA"
};