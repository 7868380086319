export const getAllowedMileagePerDay = hours => {
  const hoursInt = parseInt(hours);
  let allowedMileagePerDay = 50;

  if (hoursInt >= 12) {
    allowedMileagePerDay = 125;
  } else if (hoursInt >= 8) {
    allowedMileagePerDay = 100;
  } else if (hoursInt >= 4) {
    allowedMileagePerDay = 75;
  }

  console.log('AllowedMileagePerDay:', allowedMileagePerDay, hoursInt);
  return allowedMileagePerDay;
};

export const getExtraMileagePrice = (hours, days, selectedMileage) => {
  const totalDays = days;
  const allowedMileagePerDay = getAllowedMileagePerDay(hours);
  const payableKmsPerDay = selectedMileage - allowedMileagePerDay;
  const pricePerKms = 0.29 * payableKmsPerDay * totalDays;
  return pricePerKms;
};

// export const sliderOptionsList = totalTripHours => {
//   console.log("total trip hours", totalTripHours)
//   let defaultValue = getAllowedMileagePerDay(totalTripHours);
//   console.log("Default value", defaultValue)
//   let sliderOptionsObj = {
//     min: 0,
//     defaultValue,
//   };
//   let max;
//   let sliderList;

//   if (totalTripHours <= 4) {
//     max = 125;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 50 },
//       { slideValue: 2, indexMileageValue: 75 },
//       { slideValue: 4, indexMileageValue: 100 },
//       { slideValue: 6, indexMileageValue: 125 }
//     ];
//   } else if (4 < totalTripHours <= 8) {
//     max = 150;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 75 },
//       { slideValue: 2, indexMileageValue: 100 },
//       { slideValue: 4, indexMileageValue: 125 },
//       { slideValue: 6, indexMileageValue: 150 }
//     ];
//   } else if (8 < totalTripHours <= 12) {
//     max = 250;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 100 },
//       { slideValue: 2, indexMileageValue: 150 },
//       { slideValue: 4, indexMileageValue: 200 },
//       { slideValue: 6, indexMileageValue: 250 }
//     ];
//   } else {
//     max = 300;
//     sliderList = [
//       { slideValue: 0, indexMileageValue: 125 },
//       { slideValue: 2, indexMileageValue: 200 },
//       { slideValue: 4, indexMileageValue: 250 },
//       { slideValue: 6, indexMileageValue: 300 }
//     ];
//   }

//   sliderOptionsObj.sliderList = sliderList;
//   sliderOptionsObj.max = max;

//   return sliderOptionsObj;
// };

export const sliderOptionsList = totalTripHours => {
  console.log('Total trip hours', totalTripHours);
  const sliderListByHours = [
    {
      maxHours: 4,
      max: 125,
      sliderList: [
        { slideValue: 0, indexMileageValue: 50 },
        { slideValue: 2, indexMileageValue: 75 },
        { slideValue: 4, indexMileageValue: 100 },
        { slideValue: 6, indexMileageValue: 125 },
      ],
    },
    {
      maxHours: 8,
      max: 150,
      sliderList: [
        { slideValue: 0, indexMileageValue: 75 },
        { slideValue: 2, indexMileageValue: 100 },
        { slideValue: 4, indexMileageValue: 125 },
        { slideValue: 6, indexMileageValue: 150 },
      ],
    },
    {
      maxHours: 12,
      max: 250,
      sliderList: [
        { slideValue: 0, indexMileageValue: 100 },
        { slideValue: 2, indexMileageValue: 150 },
        { slideValue: 4, indexMileageValue: 200 },
        { slideValue: 6, indexMileageValue: 250 },
      ],
    },
    {
      maxHours: 300000000000000000000,
      max: 300,
      sliderList: [
        { slideValue: 0, indexMileageValue: 125 },
        { slideValue: 2, indexMileageValue: 200 },
        { slideValue: 4, indexMileageValue: 250 },
        { slideValue: 6, indexMileageValue: 300 },
      ],
    },
  ];

  const { max, sliderList } = sliderListByHours.find(({ maxHours }) => totalTripHours <= maxHours);

  const defaultValue = getAllowedMileagePerDay(totalTripHours);

  console.log('Max value', defaultValue);
  return {
    min: 0,
    max,
    defaultValue,
    sliderList,
  };
};

export const getMileageValuefromSliderValue = (sliderValue, totalTripHours) => {
  const sliderOptionsObj = sliderOptionsList(totalTripHours);
  const sliderValuesList = sliderOptionsObj.sliderList;
  const sliderItem = sliderValuesList.find(item => item.slideValue === sliderValue);
  return sliderItem;
};

export const getMileageValuefromSliderMileageValue = (mileage, totalTripHours) => {
  const sliderOptionsObj = sliderOptionsList(totalTripHours);
  console.log('Slider value', sliderOptionsObj, mileage);
  const sliderValuesList = sliderOptionsObj.sliderList;

  const sliderItem = sliderValuesList.find(item => item.indexMileageValue === mileage);

  if (!sliderItem) {
    return sliderValuesList[0];
  }
  return sliderItem;
};
