import { appPost } from '../../util/api/appApi';

export const updateBookingRequest = (
  bookingTxId,
  updateStart,
  updateEnd,
  sdk,
  onlyUpdateParentTx
) => {
  return appPost(
    'update-booking/request',
    {
      bookingTxId,
      updateStart: new Date(updateStart).getTime(),
      updateEnd: new Date(updateEnd).getTime(),
      onlyUpdateParentTx,
    },
    sdk
  );
};

export const updateSpeculativeRequest = (bookingTxId, updateStart, updateEnd, sdk) => {
  return appPost(
    'update-booking/speculative',
    {
      bookingTxId,
      updateStart: new Date(updateStart).getTime(),
      updateEnd: new Date(updateEnd).getTime(),
    },
    sdk
  );
};

export const cancelUpdateBookingsRequest = (bookingTxId, sdk) => {
  return appPost(
    'update-booking/cancel',
    {
      bookingTxId,
    },
    sdk
  );
};

export const acceptUpdateBookingsRequest = (bookingTxId, sdk) => {
  return appPost(
    'update-booking/accept',
    {
      bookingTxId,
    },
    sdk
  );
};

export const cancelAllUpdateBookingsRequest = (bookingTxId, sdk) => {
  return appPost(
    'update-booking/cancel-all',
    {
      bookingTxId,
    },
    sdk
  );
};
