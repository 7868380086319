import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import React, { Component } from 'react';
import { MobileVerificationForm, Modal } from '../../components';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { EVENT_CONVERSION_CREATE_VERIFIED_ACCOUNT } from '../../util/conversions/conversionsConstants';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { ensureCurrentUser } from '../../util/data';
import { EVENT_SIGNUP_VERIFIED_PHONE } from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { pushGTMVerifiedEmailPhone } from '../../util/gtm/gtmHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import EmailReminder from './EmailReminder';
import MobileValidate from './MobileValidate';
import css from './ModalMissingInformation.css';
import StripeAccountReminder from './StripeAccountReminder';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'PasswordResetPage',
  'StripePayoutPage',
  'EmailVerificationPage',
  'SearchPage',
  'SearchFiltersPage',
  'SearchListingsPage',
];

const ALLOW_CLOSE_PAGE_LIST = [
  'AccountSettingDriverVerificationPage',
  'ListingPage',
  'ListingBasePage',
  'AboutPage',
  'ContactDetailsPage',
  'SearchMapPage',
  'LandingPage',
  // 'EmailVerificationPage',
];

const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT';
const MOBILE_VERIFICATION = 'MOBILE_VERIFICATION';
const MOBILE_INPUT = 'MOBILE_INPUT';

class ModalMissingInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resendCodeSuccess: false,
      resendCodeInprogress: false,
      resendCodeError: false,
      showMissingInformationReminder: null,
      hasSeenMissingInformationReminder: false,
      currentPhoneNumber: null,
      errorMessage: null,
      disableCloseBtn: true, //This property is not for close button, it is a part of show and hide modal when click "Request to book"
      verifyPhoneSuccess: false,
      loadingPhoneValidate: false,
      sendMobileCodeError: null,
      showCloseButton: false, //It is truly for handle close button. be carefull when using it
      sendMobileNumberInprogress: false,
      emailVerified: 0,
      isShowInValidateEmailPage: !this.props.location.pathname.includes('/verify-email'),
      forceClose: false,
      guestVerifyOpen: false,
      shortcutToVerify: false,
    };
    this.handleMissingInformationReminder = this.handleMissingInformationReminder.bind(this);
    this.handleChangeMobileNumber = this.handleChangeMobileNumber.bind(this);
  }

  componentDidUpdate(_, prevState) {
    if (prevState.showMissingInformationReminder != this.state.showMissingInformationReminder) {
      this.props.onMissingInformationModalToggle &&
        this.props.onMissingInformationModalToggle(this.state.showMissingInformationReminder);
    }
  }

  componentWillReceiveProps(nextProps) {
    //NOTICE: You can use modalMissingInfomationProps here by
    //call this.prop.modalMissingInfomationProps

    const { currentUser, currentUserHasListings, currentUserHasOrders, location } = nextProps;
    const { openMissingInfomationModal } = nextProps.modalMissingInfomationProps;

    if (nextProps.currentUser && !nextProps.currentUser.attributes.emailVerified) {
      this.setState({
        emailVerified: 1,
      });
    }
    if (
      this.state.emailVerified === 1 &&
      nextProps.currentUser &&
      nextProps.currentUser.attributes.emailVerified
    ) {
      this.setState({
        emailVerified: 2,
        isShowInValidateEmailPage: true,
      });
    }
    this.setState({
      currentPhoneNumber: !currentUser
        ? null
        : currentUser.attributes.profile.protectedData.phoneNumber,
    });
    const user = ensureCurrentUser(currentUser);
    if (openMissingInfomationModal) {
      this.setState({
        hasSeenMissingInformationReminder: false,
        forceOpenMissingModal: true,
      });
      this.props.modalMissingInfomationProps.onOpenMissingInfomationModal(false);
    }
    this.handleMissingInformationReminder(
      user,
      currentUserHasListings,
      currentUserHasOrders,
      location
    );
  }

  handleMissingInformationReminder(
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    newLocation
  ) {
    //NOTICE: You can use modalMissingInfomationProps here by
    //call this.prop.modalMissingInfomationProps

    const routes = routeConfiguration();

    const closeAllowedPaths = ALLOW_CLOSE_PAGE_LIST.map(page => {
      if (page === 'ListingPage') {
        const currentPath = newLocation.pathname;
        const firstSlug = currentPath.substring(0, 3);
        if (firstSlug === '/l/') {
          let currentString = currentPath.substring(2);
          if (currentString.match(/\/(.*?)\//g)) {
            let slug = currentString.match(/\/(.*?)\//g)[0];
            slug = slug.substring(1);
            slug = slug.substring(0, slug.length - 1);
            let afterId = currentString.substring(slug.length + 1);
            let isNotOnlyId = afterId.match(/\/(.*?)\//g);
            if (isNotOnlyId && isNotOnlyId.length > 0) {
              return 'ListingPage';
            } else {
              return pathByRouteName(page, routes, {
                slug: slug,
                id: currentString.substring(slug.length + 2),
              });
            }
          }
        } else {
          return 'ListingPage';
        }
      } else {
        return pathByRouteName(page, routes);
      }
    });

    //NOTICE: You can use modalMissingInfomationProps here by
    //call this.prop.modalMissingInfomationProps
    const whitelistedPaths = MISSING_INFORMATION_MODAL_WHITELIST.map(page =>
      pathByRouteName(page, routes)
    );
    // Is the current page whitelisted?
    const isPageWhitelisted = whitelistedPaths.includes(newLocation.pathname);

    // Is the current page allow closing?
    const isPageAllowedClose = true;

    // Track if path changes inside Page level component
    const pathChanged = newLocation.pathname !== this.props.location.pathname;
    if (pathChanged) {
      this.setState({
        hasSeenMissingInformationReminder: false,
      });
    }
    const notRemindedYet =
      !this.state.showMissingInformationReminder && !this.state.hasSeenMissingInformationReminder;

    // Is the reminder already shown on current page
    const showOnPathChange = notRemindedYet || pathChanged;

    if (!isPageWhitelisted) {
      // if (this.state.emailVerified === 2) {
      //   this.setState({
      //     emailVerified: 0
      //   })
      // }
      if (isPageAllowedClose) {
        this.setState({ disableCloseBtn: false });
      } else {
        this.setState({ allowCloseBtn: true });
      }

      // Emails are sent when order is initiated
      // Customer is likely to get email soon when she books something
      // Provider email should work - she should get an email when someone books a listing
      const emailVerificationNeeded = !!currentUser.id && !currentUser.attributes.emailVerified;

      const stripeAccountMissing = !!currentUser.id && !currentUser.attributes.stripeConnected;
      const stripeAccountNeeded = currentUserHasListings && stripeAccountMissing;

      const mobileInputNeeded = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
          !currentUser.attributes.profile.protectedData.phoneNumber
        ? true
        : false;
      const mobileUnverified = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
          !currentUser.attributes.profile.protectedData.phoneNumberVerified
        ? true
        : false;
      const mobileVerificationNeeded = mobileUnverified; //Show that mobile need verified or not
      // console.log({
      //   emailVerificationNeeded,
      //   stripeAccountNeeded,
      //   mobileInputNeeded,
      //   mobileVerificationNeeded,
      //   isPageAllowedClose,
      // });
      // Show reminder
      // if (emailVerificationNeeded) {
      //   this.setState({ showMissingInformationReminder: EMAIL_VERIFICATION });
      // } else
      if (stripeAccountNeeded) {
        this.setState({ showMissingInformationReminder: STRIPE_ACCOUNT });
      } else if (mobileInputNeeded && !this.state.hasSeenMissingInformationReminder) {
        this.setState({ showMissingInformationReminder: MOBILE_INPUT });
      } else if (mobileVerificationNeeded && !mobileInputNeeded) {
        this.setState({
          showMissingInformationReminder: MOBILE_VERIFICATION,
          disableCloseBtn: true,
          currentPhoneNumber:
            currentUser.attributes.profile &&
            currentUser.attributes.profile.protectedData &&
            currentUser.attributes.profile.protectedData.phoneNumber,
        });
      }
    }
  }

  handleChangeMobileNumber() {
    //NOTICE: You can use modalMissingInfomationProps here by
    //call this.prop.modalMissingInfomationProps
    this.setState({
      showMissingInformationReminder: MOBILE_INPUT,
    });
  }

  render() {
    //NOTICE: You can use modalMissingInfomationProps here by
    //call this.prop.modalMissingInfomationProps or just modalMissingInfomationProps
    const {
      rootClassName,
      className,
      history,
      containerClassName,
      currentUser,
      onManageDisableScrolling,
      waitingForUploadAva,
      onSetupWaitingForUploadAvaThenVerifyGuest,
      modalMissingInfomationProps, //Store all things that pass from TopBarContainer here.
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);

    let content = null;

    if (
      currentUser &&
      currentUser.profileImage &&
      waitingForUploadAva &&
      !this.state.guestVerifyOpen &&
      !this.state.showMissingInformationReminder
    ) {
      this.setState({
        guestVerifyOpen: true,
        showMissingInformationReminder: MOBILE_INPUT,
        showCloseButton: true,
      });
    }

    const currentUserLoaded = user && user.id;
    if (currentUserLoaded) {
      if (this.state.showMissingInformationReminder === EMAIL_VERIFICATION) {
        content = (
          <EmailReminder
            className={classes}
            user={user}
            onResendVerificationEmail={modalMissingInfomationProps.onResendVerificationEmail}
            sendVerificationEmailInProgress={
              modalMissingInfomationProps.sendVerificationEmailInProgress
            }
            sendVerificationEmailError={modalMissingInfomationProps.sendVerificationEmailError}
          />
        );
      } else if (this.state.showMissingInformationReminder === STRIPE_ACCOUNT) {
        content = <StripeAccountReminder className={classes} />;
      } else if (this.state.showMissingInformationReminder === MOBILE_VERIFICATION) {
        content = (
          <MobileValidate
            loadingPhoneValidate={this.state.loadingPhoneValidate}
            className={classes}
            user={user}
            verifyPhoneSuccess={this.state.verifyPhoneSuccess}
            phoneNumber={this.state.currentPhoneNumber}
            sendMobileCodeInProgress={modalMissingInfomationProps.sendMobileCodeInProgress}
            sendMobileCodeError={this.state.sendMobileCodeError}
            resendCodeSuccess={this.state.resendCodeSuccess}
            resendCodeInprogress={this.state.resendCodeInprogress}
            resendCodeError={this.state.resendCodeError}
            onSendMobileCodeEmail={() => {
              this.setState({
                resendCodeSuccess: false,
                resendCodeInprogress: true,
                resendCodeError: false,
                sendMobileCodeError: null,
              });
              modalMissingInfomationProps.onResendMobileCode(
                () => {
                  this.setState({
                    resendCodeSuccess: true,
                    resendCodeInprogress: false,
                    resendCodeError: false,
                  });
                },
                () => {
                  this.setState({
                    resendCodeSuccess: false,
                    resendCodeInprogress: false,
                    resendCodeError: true,
                  });
                }
              );
            }}
            callMobileCodeInProgress={modalMissingInfomationProps.callMobileCodeInProgress}
            callMobileCodeError={modalMissingInfomationProps.callMobileCodeError}
            onCallMobileCodeEmail={modalMissingInfomationProps.onCallMobileCode}
            checkValidCode={input => {
              this.setState({
                resendCodeSuccess: false,
                resendCodeInprogress: false,
                resendCodeError: false,
                loadingPhoneValidate: true,
                sendMobileCodeError: null,
              });
              modalMissingInfomationProps.checkValidCode(
                input,
                () => {
                  // // if (this.props.location.pathname === '/profile-settings') {
                  // this.setState({
                  //   showCloseButton: true,
                  // });
                  // // }
                  this.setState({
                    showCloseButton: true,
                    loadingPhoneValidate: false,
                    verifyPhoneSuccess: true,
                    showMissingInformationReminder: null,
                  });

                  const properties = createRawPropertiesForGTM({
                    props: this.props,
                  });
                  pushGTMVerifiedEmailPhone(properties, EVENT_SIGNUP_VERIFIED_PHONE);
                  createConversionEvents(
                    properties,
                    EVENT_CONVERSION_CREATE_VERIFIED_ACCOUNT,
                    'click'
                  );

                  const windowEvent = typeof window !== 'undefined' ? window : [];
                  if (windowEvent && config.googleTagId) {
                    windowEvent.gtag('event', 'conversion', {
                      send_to: `${config.googleTagId}/eS90CIiyxoADENHNtPAC`,
                    });
                  }
                },
                error => {
                  this.setState({
                    sendMobileCodeError: error,
                    loadingPhoneValidate: false,
                  });
                }
              );
            }}
            onChangeMobileNumber={this.handleChangeMobileNumber}
          />
        );
      } else if (this.state.showMissingInformationReminder === MOBILE_INPUT) {
        // console.log('here baeiiii', currentUser);
        content = currentUser ? (
          <MobileVerificationForm
            onSubmit={phoneObj => {
              this.setState({
                sendMobileNumberInprogress: true,
              });
              modalMissingInfomationProps.onSendMobileCode(
                phoneObj,
                () => {
                  this.setState({
                    loadingPhoneValidate: false,
                    sendMobileCodeError: null,
                    currentPhoneNumber: phoneObj.phoneCode + phoneObj.phoneNumber,
                    showMissingInformationReminder: MOBILE_VERIFICATION,
                    sendMobileNumberInprogress: false,
                  });
                },
                error => {
                  this.setState({
                    errorMessage: error.message,
                    sendMobileNumberInprogress: false,
                  });
                }
              );
            }}
            openGuestVerify={this.state.guestVerifyOpen}
            isStayHere={!this.props.location.pathname.includes('/verify-email')}
            currentPhoneNumber={this.state.currentPhoneNumber}
            currentUser={currentUser}
            inProgress={this.state.sendMobileNumberInprogress}
            verificationError={this.state.errorMessage}
            closeModal={() => {
              onSetupWaitingForUploadAvaThenVerifyGuest(false);
              this.setState({
                forceClose: true,
                guestVerifyOpen: true,
                // waitingForUploadAva: false,
              });
            }}
            closeModalForUploadAva={() => {
              onSetupWaitingForUploadAvaThenVerifyGuest(true);
              this.setState({
                guestVerifyOpen: false,
                showMissingInformationReminder: null,
                showCloseButton: false,
                verifyPhoneSuccess: false,
              });
            }}
            onOpenGuestVerify={() => {
              this.setState({
                verifyPhoneSuccess: true,
                guestVerifyOpen: true,
                shortcutToVerify: true,
              });
            }}
          />
        ) : null;
      }
    }

    const closeButtonMessage =
      this.state.verifyPhoneSuccess || this.state.guestVerifyOpen ? (
        <FormattedMessage id="ModalMissingInformation.skipVerifyEmailReminder" />
      ) : (
        <FormattedMessage id="ModalMissingInformation.closeVerifyEmailReminder" />
      );

    const verifiedPhoneValid =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.protectedData &&
      currentUser.attributes.profile.protectedData.phoneNumberVerified &&
      currentUser.attributes.profile.protectedData.phoneNumber;
    const verifiedImageAndGuest =
      currentUser &&
      currentUser.profileImage &&
      currentUser.identityStatus &&
      (currentUser.identityStatus.guestVerified || currentUser.identityStatus.guestUnderVerify);

    if (
      verifiedPhoneValid &&
      verifiedImageAndGuest &&
      this.state.showMissingInformationReminder === MOBILE_INPUT
    ) {
      this.setState({
        forceClose: true,
        showMissingInformationReminder: null,
        guestVerifyOpen: false,
      });
    }

    return (
      <Modal
        id="MissingInformationReminder"
        containerClassName={containerClassName}
        isOpen={!!this.state.showMissingInformationReminder}
        disableCloseBtn={this.state.disableCloseBtn && !this.state.showCloseButton}
        onClose={() => {
          if (
            this.state.guestVerifyOpen &&
            this.props.location.pathname.includes('/verify-email')
          ) {
            history.push(createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}));
          }
          this.setState({
            guestVerifyOpen: !!(this.state.verifyPhoneSuccess && !this.state.guestVerifyOpen),
            verifyPhoneSuccess: this.state.verifyPhoneSuccess
              ? false
              : this.state.verifyPhoneSuccess,
            loadingPhoneValidate: false,
            showMissingInformationReminder: null,
            hasSeenMissingInformationReminder: true,
            sendMobileCodeError: null,
            showCloseButton: !!this.state.verifyPhoneSuccess,
            resendCodeSuccess: false,
            resendCodeInprogress: false,
            resendCodeError: false,
            sendMobileNumberInprogress: false,
          });
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={this.state.forceClose}
      >
        {content}
      </Modal>
    );
  }
}

ModalMissingInformation.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
  forceClose: false,
};

ModalMissingInformation.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,

  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  forceClose: bool,
};

ModalMissingInformation.displayName = 'ModalMissingInformation';

export default ModalMissingInformation;
