import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './../../components/TripPanel/TripPanel.css';
import { initiateOrder } from './CardVerificationPage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import CardVerificationForm from '../../forms/CardVerificationForm/CardVerificationForm';
import { useModalState } from '../../util/useHook/useModalState';
import { updateUserData } from '../../ducks/user.duck';

export const CardVerificationPageComponent = props => {
  const {
    history,
    location,
    transaction,
    transactionId,
    currentUser,
    onUpdateUserData,
    intl,
    transactInModal = false,
    handleGoBack,
  } = props;

  const [isOpenModal, openModal, closeModal] = useModalState(false);
  const [isDonationPaymentSuccessful, setIsDonationPaymentSuccessful] = useState(false);
  const [CardError, setCardError] = useState(null);

  const dispatch = useDispatch();
  const onHandlePayment = params => dispatch(initiateOrder(params));
  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));

  const initiateOrderInProgress = useSelector(
    state => state.CardVerificationPage.initiateOrderInProgress
  );
  const initiateOrderError = useSelector(state => state.CardVerificationPage.initiateOrderError);
  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  const hasDefaultPaymentMethod = !!(
    ensureStripeCustomer(currentUser && currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser && currentUser.stripeCustomer.defaultPaymentMethod).id
  );
  let responseData;
  const onSubmit = values => {
    const paramsOrder = {
      deliveryAddress: values.deliveryAddress,
      name: values.name,
      totalAmount: values.totalAmount,
    };
    const selectedPaymentMethod =
      values.paymentMethod === 'defaultCard' && hasDefaultPaymentMethod
        ? currentUser.stripeCustomer.defaultPaymentMethod.attributes.stripePaymentMethodId
        : null;
    console.log('Default strip data', {
      paramsOrder,
      card: values.card,
      stripe: values.stripe,
      paymentMethod: selectedPaymentMethod,
      replaceCurrentCard: values.replaceCurrentCard,
    });
    return onHandlePayment({
      paramsOrder,
      card: values.card,
      stripe: values.stripe,
      paymentMethod: selectedPaymentMethod,
      replaceCurrentCard: values.replaceCurrentCard,
    }).then(async data => {
      console.log('Data: ', data);
      if (data && data.transaction) {
        console.log('Transaction: ', data.transaction);
        console.log('Transaction: ');
        // setOrderNumber(transaction && transaction.id && transaction.id.uuid);
        setIsDonationPaymentSuccessful(true);
        console.log('UPDATING');
        const protectedData = {
          isPaymentMethodVerified: true,
        };
        onUpdateUserData({
          protectedData,
        }).then(() => {
          console.log('UPDATED');
          window.location.reload();
        });
      } else if (data && data.responseCard) {
        console.log('Failed Error');
        responseData = data.responseCard;
        console.log('Failed Error Response: ', responseData);
        setCardError(responseData);

        setIsDonationPaymentSuccessful(false);
        console.log('Failed Card: ', CardError);
        console.log('progress: ', initiateOrderInProgress);
      }
    });
  };
  console.log('HEHEHJWDJ', CardError);
  return (
    <div className={css.donationTransaction}>
      <CardVerificationForm
        onOpenTermAndConditions={openModal}
        defaultPaymentMethod={
          hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
        }
        intl={intl}
        currentUser={currentUser}
        initiateOrderError={initiateOrderError}
        inProgress={initiateOrderInProgress}
        onSubmit={onSubmit}
        handleGoBack={handleGoBack}
        isDonationPaymentSuccessful={isDonationPaymentSuccessful}
        transactInModal={transactInModal}
        CardError={CardError}
        setCardError={setCardError}
      />
    </div>
  );
};

const { bool, object } = PropTypes;

CardVerificationPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onUpdateUserData: data => dispatch(updateUserData(data)),
});

const CardVerificationPage = compose(
  withViewport,
  withRouter,
  connect(null, mapDispatchToProps),
  injectIntl
)(CardVerificationPageComponent);

export default CardVerificationPage;
