import config from '../config';

const apiUrl = config.apiUrl;

export const sendUserDataToIntercom = ({
  currentUser,
  listingsData,
  transactionsAsProvider,
  transactionsAsCustomer,
}) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + '/api/intercom/update-user', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      currentUser,
      listingsData,
      transactionsAsProvider,
      transactionsAsCustomer,
    }),
  }).then(data => data.json());
};

export const addIntercomNote = ({ intercomUserId, note, userEmail }) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + '/api/intercom/add-user-note', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      intercomUserId,
      userEmail,
      note,
    }),
  }).then(data => data.json());
};

export const getUser = ({ email, id }) => {
  if (typeof window === undefined) return;
  return !id
    ? fetch(apiUrl + `/api/intercom/user?email=${email}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(res => {
        return res.json();
      })
    : fetch(apiUrl + `/api/intercom/user?id=${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(res => {
        return res.json();
      });
};

export const getListingDoneTrip = ({ id }) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + `/api/queries/listing-done-trips/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(res => {
    return res.json();
  });
};

export const updateCredit = ({ credits, email, flexUserId }) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + `/api/intercom/update-credits`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      credits,
      email,
      flexUserId,
    }),
  }).then(res => {
    return res.json();
  });
};
