import React from 'react';
import classNames from 'classnames';

import css from './InsurancePolicy.css';

const HostInsurancePolicy = props => {
  const { rootClassName, className, url } = props;

  const classes = classNames(rootClassName || css.root, className);

  const fileId = url.split('/').length > 2 ? url.split('/')[url.split('/').length - 2] : '';

  const iframeUrl = `https://drive.google.com/file/d/${fileId}/preview`;

  return (
    <div className={classes}>
      <embed className={css.iframeDoc} src={iframeUrl} />
    </div>
  );
};

export default HostInsurancePolicy;
