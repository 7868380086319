import React from 'react';
import css from './IconCheck.css';

export default function IconCheck() {
  return (
    <div className={css.behind}>
      <div class={css.check}></div>
    </div>
  );
}
