import React, { Component } from 'react';
import { Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAGHowItWork.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/-fg9W0MZYuo"
      ></iframe>
    </div>
  );
};

class BAGHowItWord extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  render() {
    const { onManageDisableScrolling } = this.props;

    return (
      <section className={css.howItWordSection}>
        <div className={css.innerWrapper}>
          <div className={css.textBox}>
            <h2 className={css.title}>
              <FormattedMessage id="BecomeAGuestPage.howItWork" values={{ newline: <br /> }} />
            </h2>
            <div className={css.description}>
              <FormattedMessage id="BecomeAGuestPage.howItWorkDesc" values={{ newline: <br /> }} />
            </div>
          </div>
          <div className={css.videoWrapper}>
            <div onClick={this.handleOpen} className={css.video}>
              <LazyImage
                className={css.videoLogo}
                src={imageSource.landingPage.videoLogo}
                alt="Car rental with more choice and less hassle | Drive Mate"
              />
            </div>
          </div>
          <Modal
            id="videoLandingpage"
            {...this.props}
            containerClassName={css.modalContainer}
            closeButtonClassName={css.modalCloseButton}
            contentClassName={css.contentModal}
            isOpen={this.state.isOpen}
            onClose={() => {
              const videoIframe = document.querySelector('#videoIframe');
              if (videoIframe) {
                const iframeSrc = videoIframe.src;
                videoIframe.src = iframeSrc;
              }
              this.setState({ isOpen: false });
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ModalVideo />
          </Modal>
        </div>
      </section>
    );
  }
}

export default BAGHowItWord;
