import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  InsuranceDisclaimer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import { connect } from 'react-redux';
import HowItWork from './BAGHowItWork';
import HeroSection from './BAGHeroSection';
import HowToRentSection from './BAGHowToRent';
import WhyDriveLahSection from './BAGWhyDriveLah';
import ReadyToRentSection from './BAGReadyToRent';
import BenifitsSection from './BAGBenifitsSection';
import FAQSection from './BAGFAQ';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';

import css from './BecomeAGuestPage.css';

const BecomeAGuestPage = props => {
  const { isAuthenticated, onManageDisableScrolling, currentUser, onLoginOrSignupClick } = props;

  // prettier-ignore
  return (
      <StaticPage
        title="Rent now. Explore top long term & Daily short term rental cars in your neighbourhood | Drive Mate"
        schema={{
          '@context': 'http://schema.org',
          '@type': 'BecomeAGuest',
          description: "Discover cars you can rent from neighbourhood hosts. . Rentals as low as $4/hour or $30/day. Australia wide fleet of vans, utes, SUVs & more available long term & daily short term rental cars | Drive Mate",
          name: 'How to rent a car',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.staticPageWrapper}>
            {/* <h1 style={{ position: 'absolute', left: -999999, opacity: 0 }}>
              <FormattedMessage id="HowToBookACar.header1" />
            </h1> */}
            <HeroSection />
            <HowToRentSection
              isAuthenticated={isAuthenticated}
              currentUser={currentUser}
              onLoginOrSignupClick={onLoginOrSignupClick}
            />
            <HowItWork onManageDisableScrolling={onManageDisableScrolling}/>
            <BenifitsSection />
            <WhyDriveLahSection isAuthenticated={isAuthenticated} onManageDisableScrolling={onManageDisableScrolling}/>
            <ReadyToRentSection
              isAuthenticated={isAuthenticated}
              onLoginOrSignupClick={onLoginOrSignupClick}
            />
            <FAQSection />
            <InsuranceDisclaimer/>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disabled) =>
    dispatch(manageDisableScrolling(componentId, disabled)),
  onLoginOrSignupClick: () => dispatch(loginOrSignupClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BecomeAGuestPage);
