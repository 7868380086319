/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import { bool, oneOf, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
// import { checkoutStepConfiguration } from '../../containers/CheckoutPage/CheckoutPage';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import 'react-rangeslider/lib/index.css';
import routeConfiguration from '../../routeConfiguration';
import {
  ADDON_NAME__MAXIMUM_PROTECTION,
  ADDON_NAME__MILEAGE_PACKAGE,
  ADDON_NAME__PREMIUM_PROTECTION,
} from '../../util/constants/addons';
import { EVENT_BOOK_PRESSED_CONTINUE_ADDONS } from '../../util/gtm/gtmConstants';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { pathByRouteName } from '../../util/routes';
import { LINE_ITEM_CUSTOM_REFUND, propTypes } from '../../util/types';
import { PrimaryButton } from '../Button/Button';
import css from './AddOnsSection.css';
import { getMileageValuefromSliderValue } from './AddonsUtils';
import { get } from 'lodash';
import imageSource from '../../util/imageSource';

export const checkoutStepConfiguration = {
  CHECKOUT_ADD_ONS: 'addOns',
  CHECKOUT_PAYMENT: 'payments',
};

export const AddOnsSectionComponent = ({
  addOnsContent,
  updateCheckoutSteps,
  currentUser,
  transaction,
  intl,
  isDelivery,
  isDeliveryState,
  isFuelInclusionState,
  isExcessReductionState,
  bookingDates,
  isExcessReduction2State,
  isMileagePackageIncludedState,
  handleChangeAddOn,
  handleSelectedMileageAddOn,
  selectedMileage,
  type,
  diffHours,
  customerSelectedMileage,
  additionalTransaction,
  onUpdateTransactionMetadata,
  history,
  sliderOptions,
  bookingDays,
  isCheckoutPage,
  listing,
  sliderDefaultValue,
  totalTripHours,
  mapAbSegment,
  viewport,
}) => {
  console.log('Customert selected mileage', sliderDefaultValue, customerSelectedMileage);
  const [isExpanded, setExpanded] = useState({});
  const [mileageValues, setMileageValues] = useState(customerSelectedMileage);
  const [sliderValue, setSliderValue] = useState(sliderDefaultValue);
  console.log('Mileage valyes in the start', mileageValues);

  useEffect(() => {
    setMileageValues(customerSelectedMileage);
  }, [customerSelectedMileage]);

  const isUseAdditionalTransaction = type === 'addOnsPage';

  const isDisabledButton = isUseAdditionalTransaction
    ? !(
        isDeliveryState ||
        isFuelInclusionState ||
        isExcessReductionState ||
        isExcessReduction2State ||
        isMileagePackageIncludedState
      )
    : false;

  const haveAddedAddons = addOnsContent.filter(i => i.isAdded).length > 0;

  const isAddonVisibleByExperimentValue = expValue => {
    console.log('Exper value', expValue);
    console.log('Experiment value final ', expValue);
    if (expValue && expValue === 'B') {
      return false;
    }
    return true;
  };

  const isAddonVisible = addonName => {
    if (
      transaction &&
      transaction.attributes &&
      transaction.attributes.transitions &&
      transaction.attributes.transitions.length === 1
    ) {
      return true;
    }
    const protectedData = transaction.attributes.protectedData;
    const metadata = transaction.attributes.metadata;
    switch (addonName) {
      case ADDON_NAME__PREMIUM_PROTECTION:
        return !(
          protectedData.isExcessReduction2 ||
          (metadata && metadata.isExcessReduction2Addons)
        );
      case ADDON_NAME__MAXIMUM_PROTECTION:
        return !(protectedData.isExcessReduction || (metadata && metadata.isExcessReductionAddons));
      case ADDON_NAME__MILEAGE_PACKAGE:
        return !protectedData.isMileagePackageIncluded;
      default:
        return true;
    }
  };

  if (!transaction) {
    return null;
  }

  const handleValue2Change = value => {
    // const mileageValue = mileage === 300 ? 250 : mileage;
    const mileageItem = getMileageValuefromSliderValue(value, totalTripHours);
    setMileageValues(mileageItem.indexMileageValue);
    setSliderValue(value);
    if (mileageValues > sliderOptions.defaultValue) {
      handleSelectedMileageAddOn(mileageValues, true);
    } else {
      handleSelectedMileageAddOn(mileageValues, false);
    }
  };

  const handleChangeComplete = () => {
    console.log('Change event completed');
  };

  const premiumProtectionData = addOnsContent.find(
    item => item.addonUniqueName === 'PREMIUM_PROTECTION'
  );
  const maximumProtectionData = addOnsContent.find(
    item => item.addonUniqueName === 'MAXIMUM_PROTECTION'
  );
  const mileagePackageData = addOnsContent.find(item => item.addonUniqueName === 'MILEAGE_PACKAGE');
  const homeDeliveryData = addOnsContent.find(item => item.addonUniqueName === 'HOME_DELIVERY');

  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking', false);

  console.log('I am is hourly booking >>', isHourlyBooking);

  return (
    <div className={css.addOnsWrapper}>
      <ul className={css.addOnsList}>
        {/* There will be four sections */}

        {/* this will section of protection plans */}
        {!maximumProtectionData.isAdded &&
        !premiumProtectionData.isAdded &&
        (isAddonVisible(premiumProtectionData.addOnName) ||
          isAddonVisible(maximumProtectionData.addOnName)) ? (
          <li
            key={premiumProtectionData.id}
            className={classNames(
              premiumProtectionData.id === 4 ? css.addOnHighlight : css.addOnsItemProtection
              // premiumProtectionData.isBanner ? css.addOnBannerWrapper : '',
              // css.addOnsItem
            )}
          >
            {premiumProtectionData.isBanner && (
              <div
                className={css.addonBanner}
                style={{
                  backgroundColor:
                    premiumProtectionData.bannerHighlightType === 'new' ||
                    premiumProtectionData.bannerHighlightType === 'popular'
                      ? '#FFCD05'
                      : '#026786',
                  color: !premiumProtectionData.bannerHighlightType ? '#ffffff' : '#292827',
                }}
              >
                {/* {isMobile ? (
                  <strong>{premiumProtectionData.bannerMobileText}</strong>
                ) : ( */}
                <strong>{premiumProtectionData.bannerText}</strong>
                {/* )} */}
              </div>
            )}
            <div className={classNames(css.protectionPlanContainer)}>
              <div className={css.protectionPlanDiv}>
                <span className={css.protectionPlanThumb}>
                  <img src={imageSource.newCheckoutPage.protectionIcon} alt="Addon preview" />
                </span>
                <span className={classNames(css.protectionPlanBlockContent)}>
                  <h4>Protection plans</h4>
                </span>
              </div>
            </div>
            {/* here actual premium protection starts  */}

            <div
              id={'1'}
              className={classNames(
                css.detailBlockAddon,
                css.premiumProtection,
                !isExcessReductionState ? css.addedAddons : css.removedAddons
              )}
            >
              <div className={css.detailsDataAddon}>
                <span
                  className={classNames(
                    css.detailBlockContent,
                    css.protectionPlansNames,
                    css.detailBlockContentProtectionPlan
                  )}
                >
                  <h4>{premiumProtectionData.addOnName}</h4>
                </span>
                <span className={css.priceUnitBlockContent}>
                  ${premiumProtectionData.addOnAmount}
                  {!isHourlyBooking && (
                    <span className={css.priceInfoText}>{premiumProtectionData.isUnit}</span>
                  )}
                </span>
              </div>
              <span className={css.addonButtonContent}>
                <input
                  type="button"
                  id={`addOn${premiumProtectionData.id}`}
                  name={`addOnSelector${premiumProtectionData.id}`}
                  value={!isExcessReductionState ? 'Add' : 'Remove'}
                  checked={isExcessReductionState}
                  className={
                    !isExcessReductionState ? css.addonAddItemClass : css.addonRemoveItemClass
                  }
                  onClick={e => handleChangeAddOn(e, premiumProtectionData)}
                />
              </span>
            </div>
            <div
              className={classNames(
                css.detailBlockAddon,
                css.premiumProtectionContentDiv,
                css.premiumProtectionEndBlock,
                !isExcessReductionState ? css.addedAddons : css.removedAddons
              )}
            >
              <p>{premiumProtectionData.description}</p>
            </div>

            {/* here actual maximum protection starts  */}
            <div
              id={'2'}
              className={classNames(
                css.detailBlockAddon,
                css.premiumProtection,
                !isExcessReduction2State ? css.addedAddons : css.removedAddons
              )}
            >
              <div className={css.detailsDataAddon}>
                <span
                  className={classNames(
                    css.detailBlockContent,
                    css.protectionPlansNames,
                    css.detailBlockContentProtectionPlan
                  )}
                >
                  <h4>{maximumProtectionData.addOnName}</h4>
                </span>
                <span className={css.priceUnitBlockContent}>
                  ${maximumProtectionData.addOnAmount}
                  {!isHourlyBooking && (
                    <span className={css.priceInfoText}>{maximumProtectionData.isUnit}</span>
                  )}
                </span>
              </div>
              <span className={css.addonButtonContent}>
                <input
                  type="button"
                  id={`addOn${maximumProtectionData.id}`}
                  name={`addOnSelector${maximumProtectionData.id}`}
                  value={!isExcessReduction2State ? 'Add' : 'Remove'}
                  checked={isExcessReduction2State}
                  className={
                    !isExcessReduction2State ? css.addonAddItemClass : css.addonRemoveItemClass
                  }
                  onClick={e => handleChangeAddOn(e, maximumProtectionData)}
                />
              </span>
            </div>
            <div
              className={classNames(
                css.detailBlockAddon,
                css.premiumProtectionContentDiv,
                !isExcessReduction2State ? css.addedAddons : css.removedAddons
              )}
            >
              <p>{maximumProtectionData.description}</p>
            </div>
          </li>
        ) : null}
        {/* this will be end of protection plans */}

        {/* this will section of mileage package */}
        {!isAddonVisibleByExperimentValue(mapAbSegment) ||
        (mileagePackageData && mileagePackageData.isAdded) ||
        !isAddonVisible(mileagePackageData.addOnName)
          ? null
          : null
        // <li
        //   key={mileagePackageData.id}
        //   className={classNames(
        //     mileagePackageData.id === 7 ? css.addOnHighlight : css.addOnsItem,
        //     mileagePackageData.isBanner ? css.addOnBannerWrapper : '',
        //     !isMileagePackageIncludedState ? css.addedAddons : css.removedAddons
        //   )}
        // >
        //   {mileagePackageData.isBanner && (
        //     <div
        //       className={css.addonBanner}
        //       style={{
        //         backgroundColor:
        //           mileagePackageData.bannerHighlightType === 'new' ||
        //           mileagePackageData.bannerHighlightType === 'popular'
        //             ? '#FFCD05'
        //             : '#FFFFFF',
        //       }}
        //     >
        //       {isMobile ? (
        //         <strong>{mileagePackageData.bannerMobileText}</strong>
        //       ) : (
        //         <strong>{mileagePackageData.bannerText}</strong>
        //       )}
        //     </div>
        //   )}
        //   <div className={css.detailBlockAddon}>
        //     <div className={css.detailsDataAddon}>
        //       <span className={css.detailBlockThumb}>
        //         <img src={mileagePackageData.addOnIcon} alt="Addon preview" />
        //       </span>
        //       <span className={css.detailBlockContent}>
        //         <h4>{mileagePackageData.addOnName}</h4>
        //       </span>
        //       <span className={css.priceUnitBlockContent}>
        //         ${mileagePackageData.addOnAmount}
        //         <span className={css.priceInfoText}>{mileagePackageData.isUnit}</span>
        //       </span>
        //     </div>
        //   </div>
        //   <div className={css.detailBlockAddon}>
        //     <p>
        //       {mileagePackageData.description}
        //       {/* Included mileage:{' '}
        //       {mileageValues >= sliderOptions.max ? 'Unlimted' : `${mileageValues}km/day`}. Drag
        //       the slider to add more mileage depending on your need. */}
        //     </p>
        //   </div>

        //   <div className={classNames(css.detailBlockAddon, css.mileageDetails)}>
        //     <div className={css.priceKmsCharge}>
        //       {mileageValues >= sliderOptions.max
        //         ? 'Unlimited'
        //         : `${mileageValues * bookingDays}km (${mileageValues} x ${bookingDays} days)`}
        //     </div>
        //     <div className={css.priceIncludeStatus}>
        //       {mileageValues <= sliderOptions.defaultValue
        //         ? `Price: included`
        //         : `Price: $${getExtraMileagePrice(totalTripHours, bookingDays, mileageValues)}`}
        //     </div>
        //   </div>

        //   <div className={classNames(css.sliderfill)}>
        //     <RangeSlider
        //       min={0}
        //       max={6}
        //       step={2}
        //       // labels={sliderOptions.sliderList}
        //       tooltip={false}
        //       value={sliderValue}
        //       onChange={handleValue2Change}
        //       className={css.sliderCss}
        //       onChangeComplete={handleChangeComplete}
        //     />
        //   </div>
        // </li>
        }

        {/* end of mileage package */}

        {/* this will be section for home delivery */}
        {homeDeliveryData &&
        !homeDeliveryData.isAdded &&
        isDelivery &&
        isAddonVisible(homeDeliveryData.addOnName) ? (
          <li
            key={homeDeliveryData.id}
            className={classNames(
              homeDeliveryData.id === 4 ? css.addOnHighlight : css.addOnsItem,
              homeDeliveryData.isBanner ? css.addOnBannerWrapper : '',
              !isDeliveryState ? css.addedAddons : css.removedAddons
            )}
          >
            {homeDeliveryData.isBanner && (
              <div
                className={css.addonBanner}
                style={{
                  backgroundColor:
                    homeDeliveryData.bannerHighlightType === 'new' ||
                    homeDeliveryData.bannerHighlightType === 'popular'
                      ? '#FFCD05'
                      : '#FFFFFF',
                }}
              >
                {isMobile ? (
                  <strong>{homeDeliveryData.bannerMobileText}</strong>
                ) : (
                  <strong>{homeDeliveryData.bannerText}</strong>
                )}
              </div>
            )}
            <div className={css.detailBlockAddon}>
              <div className={css.detailsDataAddon}>
                <span className={css.detailBlockThumb}>
                  <img src={homeDeliveryData.addOnIcon} alt="Addon preview" />
                </span>
                <span className={css.detailBlockContent}>
                  <h4>{homeDeliveryData.addOnName}</h4>
                </span>
                <span className={css.priceUnitBlockContent}>
                  ${homeDeliveryData.addOnAmount}
                  <span className={css.priceInfoText}>{homeDeliveryData.isUnit}</span>
                </span>
              </div>
              <span className={css.addonButtonContent}>
                <input
                  type="button"
                  id={`addOn${homeDeliveryData.id}`}
                  name={`addOnSelector${homeDeliveryData.id}`}
                  value={!isDeliveryState ? 'Add' : 'Remove'}
                  checked={isDeliveryState}
                  className={!isDeliveryState ? css.addonAddItemClass : css.addonRemoveItemClass}
                  onClick={e => handleChangeAddOn(e, homeDeliveryData)}
                />
              </span>
            </div>
            <div className={css.detailBlockAddon}>
              <p>{homeDeliveryData.description}</p>
            </div>
          </li>
        ) : null}
        {/* end of home delivery */}
      </ul>
      {isUseAdditionalTransaction && haveAddedAddons ? (
        <ul className={css.addOnsList}>
          <div className={css.addedTitleContainer}>
            <p className={css.addedTitle}>ALREADY ADDED</p>
          </div>
          {/* There will be four sections */}

          {/* this will section of protection plans */}
          {maximumProtectionData.isAdded || premiumProtectionData.isAdded ? (
            <li
              key={premiumProtectionData.id}
              className={classNames(
                css.addOnsItem,
                premiumProtectionData.id === 4 ? css.addOnHighlight : css.addOnsItemProtection,
                premiumProtectionData.isBanner ? css.addOnBannerWrapper : '',
                css.removedAddons
              )}
            >
              {premiumProtectionData.isBanner && (
                <div
                  className={css.addonBanner}
                  style={{
                    backgroundColor:
                      premiumProtectionData.bannerHighlightType === 'new' ||
                      premiumProtectionData.bannerHighlightType === 'popular'
                        ? '#FFCD05'
                        : '#00A3AD',
                    color: !premiumProtectionData.bannerHighlightType ? '#ffffff' : '#292827',
                  }}
                >
                  {isMobile ? (
                    <strong>{premiumProtectionData.bannerMobileText}</strong>
                  ) : (
                    <strong>{premiumProtectionData.bannerText}</strong>
                  )}
                </div>
              )}
              <div className={css.protectionPlanContainer}>
                <div className={css.protectionPlanDiv}>
                  <span className={css.protectionPlanThumb}>
                    <img src={imageSource.newCheckoutPage.protectionIcon} alt="Addon preview" />
                  </span>
                  <span className={classNames(css.protectionPlanBlockContent)}>
                    <h4>Protection plans</h4>
                  </span>
                </div>
              </div>
              {/* here actual premium protection starts  */}
              {premiumProtectionData && premiumProtectionData.isAdded ? (
                <div className={classNames(css.detailBlockAddon, css.premiumProtection)}>
                  <div className={css.detailsDataAddon}>
                    <span className={classNames(css.detailBlockContent, css.protectionPlansNames)}>
                      <h4>{premiumProtectionData.addOnName}</h4>
                    </span>
                    <span className={css.priceUnitBlockContent}>
                      ${premiumProtectionData.addOnAmount}
                      <span className={css.priceInfoText}>{premiumProtectionData.isUnit}</span>
                    </span>
                  </div>
                </div>
              ) : null}
              {premiumProtectionData && premiumProtectionData.isAdded ? (
                <div
                  className={classNames(
                    css.detailBlockAddon,
                    css.premiumProtectionContentDiv,
                    css.premiumProtectionEndBlock
                  )}
                >
                  <p>{premiumProtectionData.description}</p>
                </div>
              ) : null}

              {/* here actual maximum protection starts  */}
              {maximumProtectionData && maximumProtectionData.isAdded ? (
                <div className={classNames(css.detailBlockAddon, css.premiumProtection)}>
                  <div className={css.detailsDataAddon}>
                    <span className={classNames(css.detailBlockContent, css.protectionPlansNames)}>
                      <h4>{maximumProtectionData.addOnName}</h4>
                    </span>
                    <span className={css.priceUnitBlockContent}>
                      ${maximumProtectionData.addOnAmount}
                      <span className={css.priceInfoText}>{maximumProtectionData.isUnit}</span>
                    </span>
                  </div>
                </div>
              ) : null}

              {maximumProtectionData && maximumProtectionData.isAdded ? (
                <div className={classNames(css.detailBlockAddon, css.premiumProtectionContentDiv)}>
                  <p>{maximumProtectionData.description}</p>
                </div>
              ) : null}
            </li>
          ) : null}
          {/* this will be end of protection plans */}

          {/* this will section of mileage package */}
          {isAddonVisibleByExperimentValue(mapAbSegment) &&
          mileagePackageData &&
          mileagePackageData.isAdded
            ? // <li
              //   key={mileagePackageData.id}
              //   className={classNames(
              //     mileagePackageData.id === 7 ? css.addOnHighlight : css.addOnsItem,
              //     mileagePackageData.isBanner ? css.addOnBannerWrapper : '',
              //     css.removedAddons
              //   )}
              // >
              //   {mileagePackageData.isBanner && (
              //     <div
              //       className={css.addonBanner}
              //       style={{
              //         backgroundColor:
              //           mileagePackageData.bannerHighlightType === 'new' ||
              //           mileagePackageData.bannerHighlightType === 'popular'
              //             ? '#FFCD05'
              //             : '#FFFFFF',
              //       }}
              //     >
              //       {isMobile ? (
              //         <strong>{mileagePackageData.bannerMobileText}</strong>
              //       ) : (
              //         <strong>{mileagePackageData.bannerText}</strong>
              //       )}
              //     </div>
              //   )}
              //   <div className={css.detailBlockAddon}>
              //     <div className={css.detailsDataAddon}>
              //       <span className={css.detailBlockThumb}>
              //         <img src={mileagePackageData.addOnIcon} alt="Addon preview" />
              //       </span>
              //       <span className={css.detailBlockContent}>
              //         <h4>{mileagePackageData.addOnName}</h4>
              //       </span>
              //       <span className={css.priceUnitBlockContent}>
              //         ${mileagePackageData.addOnAmount}
              //         <span className={css.priceInfoText}>{mileagePackageData.isUnit}</span>
              //       </span>
              //     </div>
              //   </div>
              //   <div className={css.detailBlockAddon}>
              //     <p>
              //       {mileagePackageData.description}
              //       {/* Included mileage:{' '}
              //       {mileageValues >= sliderOptions.max ? 'Unlimted' : `${mileageValues}km/day`}. Drag
              //       the slider to add more mileage depending on your need. */}
              //     </p>
              //   </div>

              //   <div className={classNames(css.detailBlockAddon, css.mileageDetails)}>
              //     <div className={css.priceKmsCharge}>
              //       {mileageValues >= sliderOptions.max
              //         ? 'Unlimited'
              //         : `${mileageValues * bookingDays}km (${mileageValues} x ${bookingDays} days)`}
              //     </div>
              //     <div className={css.priceIncludeStatus}>
              //       {mileageValues <= sliderOptions.defaultValue
              //         ? `Price: included`
              //         : `Price: $${getExtraMileagePrice(totalTripHours, bookingDays, mileageValues)}`}
              //     </div>
              //   </div>

              //   <div className={classNames(css.sliderfill)}>
              //     <RangeSlider
              //       min={0}
              //       max={6}
              //       step={2}
              //       // labels={sliderOptions.sliderList}
              //       tooltip={false}
              //       value={sliderValue}
              //       onChange={handleValue2Change}
              //       className={css.sliderCss}
              //       onChangeComplete={handleChangeComplete}
              //     />
              //   </div>
              // </li>
              null
            : null}

          {/* end of mileage package */}

          {/* this will be section for home delivery */}
          {homeDeliveryData && homeDeliveryData.isAdded ? (
            <li
              key={homeDeliveryData.id}
              className={classNames(
                homeDeliveryData.id === 4 ? css.addOnHighlight : css.addOnsItem,
                homeDeliveryData.isBanner ? css.addOnBannerWrapper : '',
                css.removedAddons
              )}
            >
              {homeDeliveryData.isBanner && (
                <div
                  className={css.addonBanner}
                  style={{
                    backgroundColor:
                      homeDeliveryData.bannerHighlightType === 'new' ||
                      homeDeliveryData.bannerHighlightType === 'popular'
                        ? '#FFCD05'
                        : '#FFFFFF',
                  }}
                >
                  {isMobile ? (
                    <strong>{homeDeliveryData.bannerMobileText}</strong>
                  ) : (
                    <strong>{homeDeliveryData.bannerText}</strong>
                  )}
                </div>
              )}
              <div className={css.detailBlockAddon}>
                <div className={css.detailsDataAddon}>
                  <span className={css.detailBlockThumb}>
                    <img src={homeDeliveryData.addOnIcon} alt="Addon preview" />
                  </span>
                  <span className={css.detailBlockContent}>
                    <h4>{homeDeliveryData.addOnName}</h4>
                  </span>
                  <span className={css.priceUnitBlockContent}>
                    ${homeDeliveryData.addOnAmount}
                    <span className={css.priceInfoText}>{homeDeliveryData.isUnit}</span>
                  </span>
                </div>
              </div>
              <div className={css.detailBlockAddon}>
                <p>{homeDeliveryData.description}</p>
              </div>
            </li>
          ) : null}
          {/* end of home delivery */}
        </ul>
      ) : null}
      <div className={css.submitSection}>
        <div className={css.submitSectionInner}>
          {isUseAdditionalTransaction ? (
            <div className={css.submitSectionPrice}>
              <span className={css.totalAmount}>
                {/* <LineItemTotalPriceNew
                  currentUser={currentUser}
                  transaction={additionalTransaction}
                  isProvider={false}
                  intl={intl}
                /> */}
              </span>
              {/* Total Amount */}
            </div>
          ) : transaction ? (
            <div className={css.submitSectionPrice}>
              <span className={css.totalAmount}>View full price breakdown on the next step</span>
              {/* Total Amount */}
            </div>
          ) : null}
          <div className={css.submitSectionPrice}>
            <PrimaryButton
              className={css.submitButton}
              onClick={() => {
                if (
                  !isCheckoutPage &&
                  isFuelInclusionState &&
                  !isExcessReductionState &&
                  !isExcessReduction2State &&
                  !isMileagePackageIncludedState
                ) {
                  onUpdateTransactionMetadata({
                    id: transaction.id,
                    isFuelInclusion: isFuelInclusionState,
                  }).then(() => {
                    const routes = routeConfiguration();
                    const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
                      id: transaction.id.uuid,
                    });
                    history.push(orderDetailsPath);
                  });
                } else {
                  // Add event hen the user presses continue on the checkout add--ons step.
                  initiateEventFromListing({
                    props: {
                      currentUser,
                      transaction,
                      additionalTransaction,
                      history,
                    },
                    listing,
                    pageName: 'CheckoutPage',
                    event: EVENT_BOOK_PRESSED_CONTINUE_ADDONS,
                    isHost: false,
                  });
                  if (!isCheckoutPage) {
                    console.log('Hello we are good');
                  }
                  updateCheckoutSteps(checkoutStepConfiguration.CHECKOUT_PAYMENT);
                }
              }}
              type="submit"
              disabled={isDisabledButton}
            >
              {'Continue to Payment'}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

AddOnsSectionComponent.defaultProps = {
  rootClassName: null,
  className: null,
  doNotShowTotal: false,
  customRefundType: LINE_ITEM_CUSTOM_REFUND,
  hideUnitPrice: false,
  timeZone: 'Asia/Singapore',
};

AddOnsSectionComponent.propTypes = {
  rootClassName: string,
  className: string,
  transactionMode: bool,
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  doNotShowTotal: bool,
  hideUnitPrice: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const AddOnsSection = injectIntl(AddOnsSectionComponent);

AddOnsSection.displayName = 'AddOnsSection';

export default AddOnsSection;
