import React from 'react';
import css from '../index.css';
import PropTypes from 'prop-types';
import imageSource from '../../../../util/imageSource';

const SubscriptionsCardSection = ({
  subscriptionPlan,
  isSelected,
  onSelect,
  addsOn,
  setShowChangeError,
  isEligibleForFreeze = false,
}) => {
  const {
    label,
    type,
    gpsText,
    mileageText,
    lockText,
    isMonth,
    lockTextForLockbox,
  } = subscriptionPlan;

  const handleClick = () => {
    console.log('set should be called', isEligibleForFreeze);
    if (isEligibleForFreeze) {
      // If the plan is not eligible for change, show an error message
      setShowChangeError(true);
    } else {
      // If the plan is eligible for selection, proceed with onSelect
      setShowChangeError(false);
      onSelect();
    }
  };

  return (
    <div
      className={`${css.matesWrapper} ${isSelected ? css.selected : ''} ${
        isEligibleForFreeze ? css.disabled : ''
      }`}
      onClick={handleClick}
    >
      <h4>{label}</h4>
      <ul>
        <ListItem
          disabled={isEligibleForFreeze}
          src={imageSource.landingPageNew.gpsSvg}
          alt="gps"
          text={gpsText}
        />
        <ListItem
          disabled={isEligibleForFreeze}
          src={imageSource.landingPageNew.mileageSvg}
          alt="mileage"
          text={mileageText}
        />
        <ListItem
          disabled={isEligibleForFreeze}
          src={imageSource.landingPageNew.lockSvg}
          alt="lock"
          text={
            addsOn && addsOn.includes('good-mates-lockbox') ? lockTextForLockbox || '' : lockText
          }
        />
      </ul>
      <span>
        {type} {isMonth && <span>/month</span>}
      </span>
    </div>
  );
};

const ListItem = ({ src, alt, text, disabled = false }) => (
  <li className={disabled ? css.disabled : ''}>
    <img src={src} alt={alt} />
    {text}
  </li>
);

SubscriptionsCardSection.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  subscriptionPlan: PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    gpsText: PropTypes.string.isRequired,
    mileageText: PropTypes.string.isRequired,
    lockText: PropTypes.string.isRequired,
    isMonth: PropTypes.bool,
    lockTextForLockbox: PropTypes.string,
  }).isRequired,
  addsOn: PropTypes.array,
  setShowChangeError: PropTypes.func.isRequired, // Added PropType for the setShowChangeError function
  isEligibleForFreeze: PropTypes.bool,
};

ListItem.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SubscriptionsCardSection;
