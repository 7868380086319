import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import IntroPanel from '../IntroPanel/IntroPanel';
import { NamedLink } from '../../components';

import css from './SectionUsers.css';
import imageSource from '../../util/imageSource';

const SectionUsers = props => {
  const { intl } = props;

  const iconList = [
    {
      imgSrc: imageSource.landingPage.iconMoreChoices,
      text: 'more choice, no hassle',
    },
    {
      imgSrc: imageSource.landingPage.iconAffordable,
      text: (
        <span className={css.smallText}>
          affordable
          <br />
          30-40% cheaper
        </span>
      ),
    },
    {
      imgSrc: imageSource.landingPage.iconAlwaysNearby,
      text: 'always nearby',
    },
  ];

  const footer = (
    <div className={css.footer}>
      <NamedLink
        name="BecomeAGuestPage"
        className={classNames(css.findoutButton, css.footerButton)}
      >
        <FormattedMessage id="SectionUsers.findoutButton" />
      </NamedLink>
      <NamedLink
        name="SearchPage"
        to={{
          search:
            '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798',
        }}
        className={classNames(css.rentACarButton, css.footerButton)}
      >
        <FormattedMessage id="SectionUsers.rentACarButton" />
      </NamedLink>
    </div>
  );

  return (
    <IntroPanel
      className={css.root}
      iconTextStyle={css.iconTextStyle}
      questionTitle={intl.formatMessage({ id: 'SectionUsers.questionTitle' })}
      answerTitle={intl.formatMessage({ id: 'SectionUsers.answerTitle' })}
      subtitle={<FormattedMessage id="SectionUsers.subtitle" values={{ newline: <br /> }} />}
      iconList={iconList}
      footer={footer}
    />
  );
};

SectionUsers.defaultProps = {};

SectionUsers.propTypes = {
  intl: PropTypes.object,
};

export default SectionUsers;
