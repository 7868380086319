const CLOUDINARY_BASE_URL = 'https://dv0eqz2t0y9gj.cloudfront.net';

const imageSource = {
  bannerImage: {
    bannerImage: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-image.webp`,
    banner2Image: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2.webp`,
    bannerImage2x: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-image@2x.webp`,
    bannerImageMobile: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-mobile-image.webp`,
    bannerImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-mobile-image@2x.webp`,
    banner2Image2x: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2@2x.webp`,
    banner2ImageMobile: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2Mobile.webp`,
    banner2ImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2Mobile@2x.webp`,
    rentersImage: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/renters-img.webp`,
    videoThumb: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/video-thumb.webp`,
    flexThumb: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/flexDesk.webp`,
  },
  redirectBanner: {
    antiCompCar: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/redirect-banner-image.webp`,
    antiCompCarMobile: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/redirect-banner-image-mobile.webp`,
    dLbgLogo: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/drive-lah-bg-logo.webp`,
    dLbgLogoMobile: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/drive-lah-bg-logo-mobile.webp`,
  },
  grabBanner: {
    bannerBackground: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-background.webp`,
    bannerBackgroundRent: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/rent-banner.webp`,
    bannerLogo: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-logo.png`,
    bannerLogoMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-logo-mobile.png`,
    grabLogo: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/grab-rentals-desktop.png`,
    grabLogoWhite: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/grab-rentals-mobile.png`,
    approveBlueIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge-blue@2x.png`,
    saveMoneyIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_Save Money_776848@2x.png`,
    easyAccessIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_easy_3428320@2x.png`,
    approveBlueIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badgeM@2x.png`,
    saveMoneyIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_Save Money_776848M@2x.png`,
    easyAccessIconMobileWhite: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/1024px-Dollar_sign_in_circle.svg@2x.png`,
    checkRed: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge@2x1.png`,
    checkBlue: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge11@2x.png`,
    crossRed: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_cancel_1888477red@2x.png`,
    crossBlue: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_cancel_1888477@2x.png`,
    image1: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image1.png`,
    image2: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image2.png`,
    image3: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image3.png`,
    image4: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image4.png`,
    image5: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image5.png`,
    image6: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image6.png`,
    bgPattern: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/light-bg.svg`,
    userIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/userIcon.svg`,
    easyAccessIconMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/grabBanner/noun_easy_3428320M@2x.png`,
  },
  backgroundGo: {
    go: `${CLOUDINARY_BASE_URL}/drivelah/background-auth@1440.webp`,
  },
  landingPage: {
    avatars: {
      benChan: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/avatars/BenChan.jpeg`,
      evenLim: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/avatars/EvenLim.jpg`,
      jonathanLai: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/avatars/JonathanLai.jpg`,
      shareenTan: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/avatars/ShareenTan.jpeg`,
      shimeiHe: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/avatars/ShimeiHe.jpg`,
    },
    sectionHeroInstant: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/sectionHeroInstant.webp`,
    bannerImg: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/banner_rightImg.png`,
    dummy1: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/dummy1.jpeg`,
    hourlyImage2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/hourlyHero@2x.jpg`,
    normalImage2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/sectionHero@2x.jpg`,
    instantImage2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/sectionHeroInstant@2x.jpg`,
    iconAdditionalIncome: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/additional_income.svg`,
    iconAffordable: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/affordable.svg`,
    iconAlwaysNearby: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/always_nearby.svg`,
    appstore: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/appStore.png`,
    authorAvatar1: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/authorAvatar1.jpeg`,
    authorAvatar2: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/authorAvatar2.jpeg`,
    authorAvatar3: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/authorAvatar3.jpeg`,
    bannerImgMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/banner_ImgMobile1.png`,
    affordable: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/Benefit_Affordable.png`,
    alwaysNearby: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/Benefit_AlwaysNearby.png`,
    moreChoiceNoHassle: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/Benefit_MoreChoiceNoHassle.png`,
    safeAndPro: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/Benefit_SafeAndPro.png`,
    chplay: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/chplayStore.png`,
    iconComprehensively: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/comprehensively.svg`,
    dummy2: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/dummy2.jpeg`,
    dummy3: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/dummy3.jpeg`,
    iconFullyVerified: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/fully_verified.svg`,
    hourlyImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/hourlyHero.jpg`,
    insurance: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/insurance.png`,
    landTransport: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/landTransport.jpeg`,
    iconMoreChoices: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/more_choices.svg`,
    normalImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/sectionHero.jpg`,
    additionalIncome: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/SectionSharing_additionalIncome.png`,
    comprehensively: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/SectionSharing_comprehensivelyInsured.png`,
    fullyVerified: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/SectionSharing_fullyVerified.png`,
    gpsGuidance: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/SectionSharing_gpsGuidance.png`,
    storyIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/storyIcon.png`,
    theStraitsTime: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/theStraitsTimes.png`,
    videoLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/videoLogo.png`,
    vulcanPost: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/vulcanPost.png`,
    zaobao: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingpage/zaobao.png`,
  },
  antiCompetition: {
    arrowRight: `${CLOUDINARY_BASE_URL}/drivelah/antiCompetition/arrow-down-yellow.svg`,
    carsAvailable: `${CLOUDINARY_BASE_URL}/drivelah/antiCompetition/cars-available.svg`,
    bluesg: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/bluesg.webp`,
    drivelahSVG: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/drivelah.png`,
    smallCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/category-small.webp`,
    compactPremiumCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/compact-premium.webp`,
    compactSUVCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/compact-suv.webp`,
    deliveryBanner: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/delivery-banner-image.webp`,
    instagramImage: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/drivelah-anti-competition-meta-image.webp`,
  },
  antiCompetition1: {
    midLargeCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/mid-large.png`,
    fullSUVCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/full-suv.png`,
    mpvCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/mpv.png`,
    wagonCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/wagon.png`,
    getGo: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/getgo.png`,
    shariot: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/shariot.png`,
    tribecar: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/tribecar.png`,
    hotSaleIcon: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/hot-sale.png`,
    happyUsers: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/happy-users.svg`,
    successfulTrips: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/successful-trips.svg`,
    slide1Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-1-thumb.png`,
    slide2Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-2-thumb.png`,
    slide3Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-3-thumb.png`,
  },
  aboutUs: {
    driveMateLogo: `${CLOUDINARY_BASE_URL}/drivelah/aboutus/driveMateLogo.png`,
    heroImage: `${CLOUDINARY_BASE_URL}/drivelah/heroImage.webp`,
  },
  bLanding: {
    appStoreBadge: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/appstore-badge.svg`,
    flexColor: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/flex-color.svg`,
    googlePlayBadge: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/google-play-badge.png`,
    lightBg: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/light-bg.svg`,
    point1: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-1.svg`,
    point2: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-2.svg`,
    point3: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-3.svg`,
    flexPlusBanner2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/b-landing/flexPlusBanner2x.png`,
    bannerImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/b-landing/banner-image.jpg`,
    bannerImage2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/b-landing/banner-image@2x.jpg`,
    flexIconInText: `${CLOUDINARY_BASE_URL}/drivemate/assets/b-landing/flexIconInText.svg`,
    flexPlusBanner: `${CLOUDINARY_BASE_URL}/drivemate/assets/b-landing/flexPlusBanner.png`,
    flexPlusLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/b-landing/flexPlusLogo.png`,
    flexPlusLogo2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/b-landing/flexPlusLogo2x.png`,
  },
  grabBanner1: {
    arrowBlue: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/arrow-right-blue.svg`,
    arrowYellow: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/arrow-right-yellow.svg`,
    grabIcon: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/grab-icon.svg`,
    donationBG: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/Logo-back-svg.png`,
    driveToMalaysia: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/malaysia.svg`,
    deliveryCarImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/grab-banner/delivery-car-image.png`,
    donationBG: `${CLOUDINARY_BASE_URL}/drivemate/assets/grab-banner/donationBG.png`,
  },
  seoLandingPage: {
    cars: {
      audi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/audi.jpg`,
      bmw: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/bmw.jpg`,
      honda: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/honda.jpg`,
      hyundai: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/hyundai.jpg`,
      mazda: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mazda.jpg`,
      mercedez: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mercedez.jpg`,
      mini: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mini.jpg`,
      mitsubishi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mitsubishi.jpg`,
      porsche: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/porsche.jpg`,
      toyota: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/toyota.jpg`,
      volvo: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/volvo.jpg`,
    },
    location: {
      angMoKoi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/ang-mo-koi.jpg`,
      bukitBatok: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/bukit-batok.jpg`,
      bukitPanjang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/bukit-panjang.jpg`,
      choaChuKang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/choa-chu-kang.jpg`,
      hougang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/hougang.jpg`,
      jurong: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/jurong.jpg`,
      punggol: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/punggol.jpg`,
      sembawang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/sembawang.jpg`,
      sengkang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/sengkang.jpg`,
      serangoon: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/serangoon.jpg`,
      tampines: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/tampines.jpg`,
      woodlands: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/woodlands.jpg`,
      yishun: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/yishun.jpg`,
    },
    segment: {
      compact: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/compact.jpg`,
      luxurySedan: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/luxury-sedan.jpg`,
      luxurySuv: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/luxury-suv.jpg`,
      standardSedan: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/standard-sedan.jpg`,
      standardSuv: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/standard-suv.jpg`,
    },
    cna: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cna.png`,
    vulcanPost: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/vulcan.png`,
    theStraitsTime: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/straits.png`,
    zaobao: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/zao.png`,
    landingBannerImage: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/LandingBanner.png`,
    seoLandingVideoBanner: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/videoLogo.jpg`,
  },
  becomeAHost: {
    insuranceLogo: `${CLOUDINARY_BASE_URL}/drivelah/becomeAHost/insuranceLogo-min.png`,
  },
  contact: {
    heroImage: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact_background-min.jpg`,
    heroImageMobile1x: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact-background-mobile@1x.jpg`,
    heroImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact-background-mobile@2x.jpg`,
  },
  auth: {
    backgorundImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/auth/background-img.png`,
    phoneVerify: `${CLOUDINARY_BASE_URL}/drivemate/assets/auth/phone-verify.png`,
  },
  becomeAGuest: {
    benifit_bg: `${CLOUDINARY_BASE_URL}/drivemate/assets/becomeAGuest/shutterstock_1396507904-min.jpg`,
    carHowToRent: `${CLOUDINARY_BASE_URL}/drivemate/assets/becomeAGuest/car_how_to_rent.png`,
  },
  donationPage: {
    donationBanner: `${CLOUDINARY_BASE_URL}/drivemate/assets/donationPage/donation-banner-bg.jpg`,
    driveTogetherIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/donationPage/drive-together-icon.svg`,
    driveTogetherLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/donationPage/drive-together-logo.svg`,
    image1: `${CLOUDINARY_BASE_URL}/drivemate/assets/donationPage/image1.jpg`,
    partner1: `${CLOUDINARY_BASE_URL}/drivemate/assets/donationPage/partner1.png`,
  },
  drivemateSection: {
    leftImg: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/left.png`,
    rightImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/rightImg.png`,
    bankSectionImg: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/bankSectionImg.webp`,
    bgMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/bgMobile.png`,
    CheckIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/check-circle-solid.svg`,
    guest: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/guest.svg`,
    higherEarning: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/higherEarning.svg`,
    imageMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/imageMobile.png`,
    insurance: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/insurance.svg`,
    profileImg: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/profileImg.png`,
    tracking: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/tracking.svg`,
    videoIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/videoIcon.svg`,
  },
  landingPageCovid: {
    checkoutHeroImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/checkoutHero.png`,
    heroPictureDesktop: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/covidBanner.jpg`,
    covidBannerMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/covidBannerMobile.png`,
    iconChecked: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/iconChecked.svg`,
    iconClean: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/iconClean.png`,
    iconLeather: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/iconLeather.png`,
    iconNontoxic: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/iconNontoxic.png`,
    iconSoap: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/iconSoap.png`,
    viroQ: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/QuantumIon.png`,
    stepClean: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/stepClean.png`,
    stepDisinfect: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/stepDisinfect.png`,
    stepFinish: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/stepFinish.png`,
    viroQVideoThumbnail: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageCovid/viroQVideoThumbnail.png`,
  },
  landingPageNew: {
    cities: {
      CityAdelaide: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/cities/city_img_adelaide.png`,
      CityBrisbane: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/cities/city_img_brisbane.png`,
      CityCairns: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/cities/city_img_cairns.png`,
      CityMelbourne: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/cities/city_img_melbourne.png`,
      CityPerth: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/cities/city_img_perth.png`,
      CitySydney: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/cities/city_img_sydney.png`,
    },
    dc: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/daily-chores/two-women-shopping-cart.jpg`,
    outing: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/move-houses/young-family-moving-into-their-new-house.jpg`,
    loveDrivemateSec3: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/close-up-side-portrait-happy-man-driving-car.jpg`,
    loveDrivemateSec2: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/cute-family-taking-hot-drink-winter-field.jpg`,
    loveDrivemateSec1: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/medium-shot-happy-couple-having-fun.jpg`,
    loveDrivemateSec4: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/smiling-women-near-man-car.jpg`,
    car3: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/visit-friends/company-young-guys-welcoming-each-other-car.jpg`,
    car2: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/weekend-outing/family-road-trip.jpg`,
    bannerImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/banner-bg.jpg`,
    Discover1: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/discoverImg1.jpg`,
    Discover2: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/discoverImg2.jpg`,
    Discover3: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/discoverImg3.jpg`,
    Discover4: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/discoverImg4.jpg`,
    loveDrivelahSec3: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/ld-y.jpg`,
    loveDrivelahSec1: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/love-drivelah-1.jpg`,
    loveDrivelahSec2: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/love-drivelah-2.jpg`,
    RentCarMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/rental-counter-mobile-au.jpeg`,
    activeListingSvg: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/activeListingSvg.svg`,
    AllianzInsurenceIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/allianceInsurence.svg`,
    googlePlayBadge: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/android-play.svg`,
    appStoreBadge: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/app-store.svg`,
    AppStoreIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/appStore.svg`,
    Rating: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/appStoreStarRatings.svg`,
    upArrowIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/arrow-up-right.svg`,
    Check: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/check-mark.svg`,
    GTInsurenceIcon2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/gt-logo-insurance2x.png`,
    GTInsurenceIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/gtInsurence.svg`,
    loveDrivelahSec4: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/ld-x.jpg`,
    Trust: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/listingImg2.svg`,
    Transport: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/listingImgAuthority.svg`,
    Income: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/listingImgIncome.svg`,
    Tokio: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/listingImgTokio.svg`,
    News: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/news.svg`,
    personHoldingPhone: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/person-holding-phone-au-2.png`,
    PlayBtn: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/play-btn.svg`,
    PRNewswire: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/prnewswire.svg`,
    PRNewswire2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/prnewswire@2x.png`,
    Star: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/star.svg`,
    gpsSvg: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/sub-gps.svg`,
    lockSvg: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/sub-lock.svg`,
    mileageSvg: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/sub-mileage.svg`,
    YellowStar: `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/yellow-star.svg`,
  },
  newSignUpDesign: {
    leftImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/newSignUpDesign/newHero.jpg`,
  },
  searchpageContact: {
    modalLeftImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/searchpageContact/vaccine-program-image.png`,
    modalLeftImageMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/searchpageContact/vaccine-program-mobile.png`,
    contactIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/searchpageContact/contact-icon.svg`,
    modalImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/searchpageContact/contact-modal-image.png`,
  },
  testimonialImages: {
    charlotteWalker: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Charlotte Walker.webp`,
    emilyTaylor: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Emily  Taylor.webp`,
    jamesA: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/James A.webp`,
    miaSmith: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Mia Smith - 1.webp`,
    miaSmith2: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Mia Smith - 2.webp`,
    michaelTan: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Michael Tan.webp`,
    natashaLim: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Natasha Lim.webp`,
    noahHaris: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Noah Harris.webp`,
    oliviaJohnson: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Olivia Johnson.webp`,
    oliviaJohnson2: `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Olivia Johnson -2.webp`,
  },
  paymentIcons: {
    mastercard: `${CLOUDINARY_BASE_URL}/drivemate/assets/paymentIcons/mastercard.png`,
    visa: `${CLOUDINARY_BASE_URL}/drivemate/assets/paymentIcons/visa.png`,
    paypal: `${CLOUDINARY_BASE_URL}/drivemate/assets/paymentIcons/paypal.png`,
    americanexpress: `${CLOUDINARY_BASE_URL}/drivemate/assets/paymentIcons/americanexpress.png`,
  },
  payouts: {
    downloadIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/payouts/downloadIcon.svg`,
  },
  searchPage: {
    arrowDownWhite: `${CLOUDINARY_BASE_URL}/drivemate/assets/arrow-down-white.svg`,
    iconLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/searchPage/icon-logo.svg`,
    infoCircle: `${CLOUDINARY_BASE_URL}/drivemate/assets/searchPage/info-circle-solid.svg`,
    noTrips: `${CLOUDINARY_BASE_URL}/drivemate/assets/searchPage/no-trips.svg`,
  },
  tableSectionImages: {
    avis: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/avis.png`,
    checkIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/check-circle-solid.svg`,
    carNextDoor: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/cnd.png`,
    driveMateLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/DriveMateLogo.png`,
    goget: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/goget.png`,
    iconCancel: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/Icon-cancel.svg`,
    star: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/star.svg`,
    tableSectionLeftBg: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/tableSectionLeftBg.svg`,
    tableSectionRightBg: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/tableSectionRightBg.svg`,
    turo: `${CLOUDINARY_BASE_URL}/drivemate/assets/tableSectionImages/Turo.png`,
  },
  tripPage: {
    beginner: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/beginner.svg`,
    cancelTrip: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/cancel-trip.svg`,
    directionsIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/directions-solid.svg`,
    downloadIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/downloadIcon.svg`,
    editRegular: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/edit-regular.svg`,
    helpIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/help.svg`,
    mileageIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/mileageIcon.svg`,
    contractIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/policies.svg`,
    thumbIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/thumb.svg`,
    tickDisabled: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/tick-disabled.svg`,
    tick: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/tick.svg`,
    tripCost: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/trip-cost.svg`,
    premiumProtectionIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tripPage/Premium_Cover.png`,
  },
  trustAndSafety: {
    damage2: `${CLOUDINARY_BASE_URL}/drivemate/assets/trustAnfSafety/damage2.svg`,
  },
  twitter: {
    twitter: `${CLOUDINARY_BASE_URL}/drivemate/assets/twitter/twitter.png`,
    twitter2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/twitter/twitter@2x.png`,
  },
  updatelisting: {
    clockRegular: `${CLOUDINARY_BASE_URL}/drivemate/assets/updatelisting/clock-regular.svg`,
    frontView: `${CLOUDINARY_BASE_URL}/drivemate/assets/updatelisting/front-view.png`,
    sideViewLeft: `${CLOUDINARY_BASE_URL}/drivemate/assets/updatelisting/side-view-left.png`,
    sideViewRight: `${CLOUDINARY_BASE_URL}/drivemate/assets/updatelisting/side-view-right.png`,
    threeQuartersView: `${CLOUDINARY_BASE_URL}/drivemate/assets/updatelisting/three-quarters-view.png`,
  },
  byod: {
    goLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/byod/logo.png`,
  },
  cancelPolicy: {
    guestImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/cancelPolicy/guest.png`,
    hostImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/cancelPolicy/host.png`,
  },
  discountAndDelivery: {
    deliveryTruckIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/discountAndDelivery/delivery-truck-icon.svg`,
  },
  fbmessenger: {
    NewFBMessengerIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/fbmessenger/messenger(1)@2x.png`,
  },
  general: {
    insuranceLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/general/insuranceLogo-min.png`,
  },
  hostLanding: {
    bannerBg: `${CLOUDINARY_BASE_URL}/drivemate/assets/hostLanding/host-landing-bg.svg`,
    bannerBg2: `${CLOUDINARY_BASE_URL}/drivemate/assets/hostLanding/host-landing-bg2.svg`,
    motoristDrivelahLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/hostLanding/motorist-drivelah-logo.png`,
    arrowRight: `${CLOUDINARY_BASE_URL}/drivemate/assets/hostLanding/next.png`,
  },
  howitwork: {
    infinityImageNew: `${CLOUDINARY_BASE_URL}/drivemate/assets/howitwork/hiw-infinity-new.png`,
  },
  instantBookingLandingPage: {
    instantPolicies: `${CLOUDINARY_BASE_URL}/drivemate/assets/instantBookingLandingPage/instantPolicies.jpg`,
    instantPolicies2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/instantBookingLandingPage/instantPolicies@2x.jpg`,
  },
  insurancePanel: {
    allianz: `${CLOUDINARY_BASE_URL}/drivemate/assets/insurance-panel/allianz-logo.svg`,
    check: `${CLOUDINARY_BASE_URL}/drivemate/assets/insurance-panel/check.png`,
    drivelahSecurity: `${CLOUDINARY_BASE_URL}/drivemate/assets/insurance-panel/drivelah-security.svg`,
    gtLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/insurance-panel/gt-logo.png`,
    percent: `${CLOUDINARY_BASE_URL}/drivemate/assets/insurance-panel/percent.png`,
  },
  listingPage: {
    IconTrash: `${CLOUDINARY_BASE_URL}/drivemate/assets/listingPage/trash.svg`,
  },
  logos: {
    safeFirst: `${CLOUDINARY_BASE_URL}/drivemate/assets/logos/insuret-logo.svg`,
    insurance: `${CLOUDINARY_BASE_URL}/drivemate/assets/logos/insurnet.svg`,
    insuranceLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/logos/Tokio_Marine_logo.png`,
  },
  longTermLandingPage: {
    approved: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermLandingPage/approvedImage.png`,
    becomeHostImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermLandingPage/becomeFlexHost.jpg`,
  },
  longTermListing: {
    AuxInput: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/AuxInput.svg`,
    Bluetooth: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/Bluetooth.svg`,
    CruiseControl: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/Cruisecontrol.svg`,
    GPS: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/GPS.svg`,
    ChildSeat: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/Path2199.svg`,
    RearCamera: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/RearCamera.svg`,
    Sunroof: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/Sunroof.svg`,
    Automatic: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/transmission.svg`,
    Allwheel: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/Tyre.svg`,
    USBCharger: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/USBCharger.svg`,
    USBInput: `${CLOUDINARY_BASE_URL}/drivemate/assets/longTermListing/USBInput.svg`,
  },
  masterCardLandingPage: {
    drivelahMasterCardLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/masterCardLandingPage/DrivelahMasterCardLogo.png`,
    masterCardLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/masterCardLandingPage/masterCardLogo.png`,
  },
  newCheckoutPage: {
    deliveryIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/newCheckoutPage/deliveryIcon.svg`,
    protectionIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/newCheckoutPage/protectionIcon.svg`,
  },
  verificationPage: {
    Calender: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/Calender.svg`,
    Card: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/Card.svg`,
    CurrentLocation: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/CurrentLocation.svg`,
    Disable: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/Disable.svg`,
    Email: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/Email.svg`,
    IdentityVerification: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/IdentityVerification.svg`,
    PhoneNumber: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/PhoneNumber.svg`,
    Search: `${CLOUDINARY_BASE_URL}/drivemate/assets/verificationPage/Search.svg`,
  },
  whatsapp: {
    NewWhatsappIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/whatsapp/whatsapp@2x.png`,
  },
  exitModalImage2: `${CLOUDINARY_BASE_URL}/drivemate/assets/exit-modal-flex-plus-2x.png`,
  exitModalMobile2x: `${CLOUDINARY_BASE_URL}/drivemate/assets/exitModalMobile-2x.png`,
  arrowDown: `${CLOUDINARY_BASE_URL}/drivemate/assets/arrow-down-black.svg`,
  Speed: `${CLOUDINARY_BASE_URL}/drivemate/assets/speed.svg`,
  shareIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/share-green.svg`,
  Seats: `${CLOUDINARY_BASE_URL}/drivemate/assets/seats.svg`,
  ntucLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/ntuc-logo-nobg.png`,
  not_suitable_for_kids: `${CLOUDINARY_BASE_URL}/drivemate/assets/not_suitable_for_kids.png`,
  not_permitted: `${CLOUDINARY_BASE_URL}/drivemate/assets/not_permitted.png`,
  no_smoking: `${CLOUDINARY_BASE_URL}/drivemate/assets/no_smoking.png`,
  lockImg: `${CLOUDINARY_BASE_URL}/drivemate/assets/Lock.svg`,
  Fuel: `${CLOUDINARY_BASE_URL}/drivemate/assets/fuel.svg`,
  flexCarLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/flexCarLogo.png`,
  exitModalMobile: `${CLOUDINARY_BASE_URL}/drivemate/assets/exitModalMobile.png`,
  exitModalImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/exit-modal-flex-plus.png`,
  DownloadingArrow: `${CLOUDINARY_BASE_URL}/drivemate/assets/DownloadingArrow.svg`,
  checkMark: `${CLOUDINARY_BASE_URL}/drivemate/assets/checkMark.svg`,
  badge: `${CLOUDINARY_BASE_URL}/drivemate/assets/badge.png`,
  australiaFlag: `${CLOUDINARY_BASE_URL}/drivemate/assets/australia-flag-icon.png`,
  carIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/carIcon.svg`,
  carIcon2: `${CLOUDINARY_BASE_URL}/drivemate/assets/carIcon2.svg`,
  appleLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/apple-logo.svg`,
  whatsAppSquareLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/whatsapp-square-brands.svg`,
  whatsAppLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/whatsApp.svg`,
  insurance: `${CLOUDINARY_BASE_URL}/drivemate/assets/tokyo-marine-logo.png`,
  technology: `${CLOUDINARY_BASE_URL}/drivemate/assets/technology.svg`,
  support: `${CLOUDINARY_BASE_URL}/drivemate/assets/support.svg`,
  stripeIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/stripeIcon.svg`,
  leftImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/step_2.jpg`,
  shareIcon3: `${CLOUDINARY_BASE_URL}/drivemate/assets/share3.svg`,
  shareIcon2: `${CLOUDINARY_BASE_URL}/drivemate/assets/share2.svg`,
  shareIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/share1.svg`,
  phoneIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/phone-solid.svg`,
  notepadIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/notepad.svg`,
  messengerLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/messenger.svg`,
  privacyIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/lock-solid.svg`,
  keepMore: `${CLOUDINARY_BASE_URL}/drivemate/assets/keepMore.svg`,
  inviteFriends: `${CLOUDINARY_BASE_URL}/drivemate/assets/invite-friends.svg`,
  fullyCovered: `${CLOUDINARY_BASE_URL}/drivemate/assets/fullyCovered.svg`,
  insuranceLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/insurance-logo.svg`,
  earn: `${CLOUDINARY_BASE_URL}/drivemate/assets/earn.svg`,
  copyIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/copy.png`,
  contactUsImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/contactUsModalLogo.png`,
  arrowDownBlack: `${CLOUDINARY_BASE_URL}/drivemate/assets/arrow-down-black.svg`,
  chatIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/icon_chat.svg`,
  image: `${CLOUDINARY_BASE_URL}/drivemate/assets/default.png`,
  googlePlayBadge: `${CLOUDINARY_BASE_URL}/drivemate/assets/android-play.svg`,
  appStoreBadge: `${CLOUDINARY_BASE_URL}/drivemate/assets/app-store.svg`,
  facebook: `${CLOUDINARY_BASE_URL}/drivemate/assets/facebook.svg`,
  instagram: `${CLOUDINARY_BASE_URL}/drivemate/assets/instagram.svg`,
  ShareIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/share.svg`,
  crashCarImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/crashcar.png`,
  infinityImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/infinity.png`,
  liftCarImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/liftcar.png`,
  moneyImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/money.png`,
  ncdImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/ncd.png`,
  theftImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/thieft.png`,
  truckCarImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/truckcar.png`,
  verifyImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/verify.png`,
  DrivelahCreditsIconWhite: `${CLOUDINARY_BASE_URL}/drivemate/assets/drivelah-credits-icon-white.png`,
  FBMessengerIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/FBMessenger.png`,
  ShareYourLinkIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/share-your-link.png`,
  TellFriendsIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/tell-friends.png`,
  TwitterIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/Twitter.png`,
  WhatsAppIcon: `${CLOUDINARY_BASE_URL}/drivemate/assets/WhatsApp.png`,
  MailLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/close-envelope.svg`,
  FacebookLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/facebook-letter-logo.svg`,
  CopyLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/link.svg`,
  TwitterLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/twitter-logo-silhouette.svg`,
  WhatsappLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/whatsapp.svg`,
  MessengerLogo: `${CLOUDINARY_BASE_URL}/drivemate/assets/Messenger.svg`
};

export default imageSource;
