import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.css';
import IconCheck from '../IconCheck/IconCheck';

const Tab = props => {
  const {
    className,
    id,
    disabled,
    text,
    selected,
    linkProps,
    linkClass,
    selectedClass,
    customLinkClass,
    customSelectedLinkClass,
    isCompleted,
  } = props;
  const linkClasses = classNames(css.link, linkClass, customLinkClass, {
    [css.selectedLink]: selected,
    [selectedClass]: selected,
    [customSelectedLinkClass]: selected,
    [css.disabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
      {isCompleted ? <IconCheck /> : null}
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    linkClass,
    selectedClass,
    customLinkClass,
    customSelectedLinkClass,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return (
          <Tab
            key={id}
            id={id}
            className={tabClasses}
            linkClass={linkClass}
            selectedClass={selectedClass}
            {...tab}
            customLinkClass={customLinkClass}
            customSelectedLinkClass={customSelectedLinkClass}
          />
        );
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
