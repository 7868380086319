import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { Form, NamedLink, PrimaryButton } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import SignUpField from './SignUpField';
import googleLogo from '../../assets/google_logo.png';
import AppleSignin from 'react-apple-signin-auth';
import config from '../../config';
import { pushGTMSignupForm } from '../../util/gtm/gtmHelpers';
import { EVENT_SIGNUP_SOCIAL_APPLE } from '../../util/gtm/gtmConstants';
import facebookLogo from '../../assets/facebook.png';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import appleLogo from '../../assets/apple-logo.svg';
import { gSend, GTAG_ACTIONS, GTAG_METHODS } from '../../util/gtag';

import css from './SignupFormThemed.css';

const KEY_CODE_ENTER = 13;

const checkIfReferralCode = code => {
  return !!code.match(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  );
};

const SignupFormThemedComponent = props => {
  const referalValueString =
    'We are revamping the referral program and have paused it for while. Please contact our support team for more info';
  const [referalValue, setReferalValue] = useState(props.signUpCode ? referalValueString : '');
  const [referalCodeFormValue, setReferalCodeFormValue] = useState(
    props.signUpCode ? props.signUpCode : ''
  );
  return (
    <FinalForm
      {...props}
      initialValues={{ email: props.initEmail, code: props.signUpCode, ...props.initialValues }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          values,
          intl,
          onOpenTermsOfService,
          submitButtonId,
          touched,
          errors,
          google,
          facebook,
          from,
        } = fieldRenderProps;

        // email
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        // const submitDisabled = invalid || submitInProgress;
        const submitDisabled = submitInProgress;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };

        const termsLink = (
          <NamedLink newTab={true} name="TermsOfServicePage">
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </NamedLink>
        );

        const handleValueChange = ({ values, active }) => {
          if (values.code) {
            // setReferalValue(referalValueString);
            setReferalCodeFormValue(values.code);
          } else {
            setReferalValue('');
            setReferalCodeFormValue('');
          }
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true, active: true }} onChange={handleValueChange} />
            <div>
              <div className={css.socialContainer}>
                {/* Google Signup */}
                <div className={classNames(css.loginUsing, css.google, css.googleBorder)}>
                  <a onClick={google}>
                    <span>
                      <img src={googleLogo} alt={'google logo'} />
                    </span>
                    <span className={classNames(css.btnText, css.google)}>Google</span>
                  </a>
                </div>
                <AppleSignin
                  authOptions={{ ...config.appleAuthOptions, state: from }}
                  uiType="dark"
                  className="apple-auth-btn"
                  noDefaultStyle={false}
                  buttonExtraChildren="Continue with Apple"
                  skipScript={false}
                  onSuccess={response => {
                    const properties = createRawPropertiesForGTM({
                      props: this.props,
                      button: {
                        buttonId: 'countinue-with-apple',
                      },
                    });
                    pushGTMSignupForm(properties, EVENT_SIGNUP_SOCIAL_APPLE);
                    gSend(GTAG_ACTIONS.ACTION_SIGNUP, { method: GTAG_METHODS.APPLE_SIGNUP });
                    // localStorage.setItem('step2', true);
                  }}
                  render={props => (
                    <div className={classNames(css.loginUsing, css.apple)} {...props}>
                      <a>
                        <span>
                          <img src={appleLogo} alt={'apple logo'} />
                        </span>
                        <span className={classNames(css.btnText, css.apple)}>Apple</span>
                      </a>
                    </div>
                  )}
                />
                <div className={classNames(css.loginUsing, css.facebook)}>
                  <a onClick={facebook}>
                    <span>
                      <img src={facebookLogo} alt={'facebook logo'} />
                    </span>
                    <span className={classNames(css.btnText, css.facebook)}>Facebook</span>
                  </a>
                </div>
              </div>
              <div className={css.dividerContainer}>
                <div className={css.divider}></div>
                <div className={css.dividerText}>Or</div>
                <div className={css.divider}></div>
              </div>
              <SignUpField
                intl={intl}
                formId={formId}
                values={values}
                touched={touched}
                errors={errors}
              />
              {/* {referalValue && checkIfReferralCode(referalCodeFormValue) ? <div className={css.validationReferralError}>{referalValue}</div>: ''} */}
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                {/* <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                /> */}
                By selecting Signup now, I agree to Drive mate’s{' '}
                <NamedLink newTab={true} name="TermsOfServicePage">
                  Terms of Service
                </NamedLink>
                ,{' '}
                <NamedLink newTab={true} name="RentalAgreementPage">
                  Payments Terms of Service
                </NamedLink>
                , and acknowledge the{' '}
                <NamedLink newTab={true} name="PrivacyPolicyPage">
                  Privacy Policy
                </NamedLink>
                .
              </p>
              <PrimaryButton
                id={submitButtonId}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.submitButton}
                // style={{ textTransform: 'initial'}}
              >
                Signup now
              </PrimaryButton>
              {/* <div className={classNames(css.newsletterCheck, css.checkboxWrapper)}>
              <input type='checkbox' name='newsletter' id='newsletter' />
              <label htmlFor='newsletter'>
                I don’t want to receive marketing messages from Drive mate.
              </label>
            </div> */}
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormThemedComponent.defaultProps = { inProgress: false };

const { bool, func, string } = PropTypes;

SignupFormThemedComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func,

  signUpCode: string,

  initEmail: string,
  submitButtonId: string,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupFormThemed = compose(injectIntl)(SignupFormThemedComponent);
SignupFormThemed.displayName = 'SignupFormThemed';

export default SignupFormThemed;
