import pick from 'lodash/pick';

import { initialState } from './EditTripPage.state';

export const SET_INITAL_VALUES = 'app/TripPageDetails/SET_INITIAL_VALUES';
export const FETCH_TRANSACTION_REQUEST = 'app/TripPageDetails/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TripPageDetails/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TripPageDetails/FETCH_TRANSACTION_ERROR';
export const FETCH_TIME_SLOTS_REQUEST = 'app/TripPageDetails/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TripPageDetails/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TripPageDetails/FETCH_TIME_SLOTS_ERROR';
export const FETCH_TX_PHONE_NUMBER_REQUEST = 'app/TripPageDetails/FETCH_TX_PHONE_NUMBER_REQUEST';
export const FETCH_TX_PHONE_NUMBER_SUCCESS = 'app/TripPageDetails/FETCH_TX_PHONE_NUMBER_SUCCESS';
export const FETCH_TX_PHONE_NUMBER_FAIL = 'app/TripPageDetails/FETCH_TX_PHONE_NUMBER_FAIL';
export const ADD_CHILD_LONG_TERM_TRANSACTIONS =
  'app/TripPageDetails/ADD_CHILD_LONG_TERM_TRANSACTIONS';
export const ADD_NEXT_CHILD_TRANSACTION = 'app/TripPageDetails/ADD_NEXT_CHILD_TRANSACTION';
export const ADD_CURRENT_CHILD_TRANSACTION = 'app/TripPageDetails/ADD_CURRENT_CHILD_TRANSACTION';
export const TRIP_DATES_SET = 'app/TripPageDetails/TRIP_DATES_SET';
export const UPDATE_BOOKING_DATES_SET = 'app/TripPageDetails/UPDATE_BOOKING_DATES_SET';
export const ESTIMATED_TX_REQUEST_PENDING = 'app/TripPageDetails/ESTIMATED_TX_REQUEST_PENDING';
export const ESTIMATED_TX_REQUEST_SUCCESS = 'app/TripPageDetails/ESTIMATED_TX_REQUEST_SUCCESS';
export const ESTIMATED_TX_REQUEST_ERROR = 'app/TripPageDetails/ESTIMATED_TX_REQUEST_ERROR';
export const UPDATE_BOOKING_INITIATE_REQUEST =
  'app/TripPageDetails/UPDATE_BOOKING_INITIATE_REQUEST';
export const UPDATE_BOOKING_INITIATE_SUCCESS =
  'app/TripPageDetails/UPDATE_BOOKING_INITIATE_SUCCESS';
export const UPDATE_BOOKING_INITIATE_ERROR = 'app/TripPageDetails/UPDATE_BOOKING_INITIATE_ERROR';
export const ACCEPT_UPDATE_BOOKING_REQUEST = 'app/TripPageDetails/ACCEPT_UPDATE_BOOKING_REQUEST';
export const ACCEPT_UPDATE_BOOKING_SUCCESS = 'app/TripPageDetails/ACCEPT_UPDATE_BOOKING_SUCCESS';
export const ACCEPT_UPDATE_BOOKING_ERROR = 'app/TripPageDetails/ACCEPT_UPDATE_BOOKING_ERROR';
export const CANCEL_UPDATE_BOOKING_REQUEST = 'app/TripPageDetails/CANCEL_UPDATE_BOOKING_REQUEST';
export const CANCEL_UPDATE_BOOKING_SUCCESS = 'app/TripPageDetails/CANCEL_UPDATE_BOOKING_SUCCESS';
export const CANCEL_UPDATE_BOOKING_ERROR = 'app/TripPageDetails/CANCEL_UPDATE_BOOKING_ERROR';
export const FETCH_UPDATE_BOOKING_TXS_REQUEST =
  'app/TripPageDetails/FETCH_UPDATE_BOOKING_TXS_REQUEST';
export const FETCH_UPDATE_BOOKING_TXS_SUCCESS =
  'app/TripPageDetails/FETCH_UPDATE_BOOKING_TXS_SUCCESS';
export const FETCH_UPDATE_BOOKING_TXS_ERROR = 'app/TripPageDetails/FETCH_UPDATE_BOOKING_TXS_ERROR';
export const CANCEL_ALL_UPDATE_BOOKING_TXS_REQUEST =
  'app/TripPageDetails/CANCEL_ALL_UPDATE_BOOKING_TXS_REQUEST';
export const CANCEL_ALL_UPDATE_BOOKING_TXS_SUCCESS =
  'app/TripPageDetails/CANCEL_ALL_UPDATE_BOOKING_TXS_SUCCESS';
export const CANCEL_ALL_UPDATE_BOOKING_TXS_ERROR =
  'app/TripPageDetails/CANCEL_ALL_UPDATE_BOOKING_TXS_ERROR';
export const FETCH_LAST_UPDATE_BOOKING_TX_REQUEST =
  'app/TripPageDetails/FETCH_LAST_UPDATE_BOOKING_TX_REQUEST';
export const FETCH_LAST_UPDATE_BOOKING_TX_SUCCESS =
  'app/TripPageDetails/FETCH_LAST_UPDATE_BOOKING_TX_SUCCESS';
export const FETCH_LAST_UPDATE_BOOKING_TX_ERROR =
  'app/TripPageDetails/FETCH_LAST_UPDATE_BOOKING_TX_ERROR';

/**
 * Set update booking dates object.
 * @param {{start?: Date, end?: Date}} value
 * @returns {{payload, type: string}}
 */
export const updateBookingDatesSet = value => ({
  type: UPDATE_BOOKING_DATES_SET,
  payload: value,
});

/**
 * Set trip booking dates object.
 * @param {{start?: Date, end?: Date}} value
 * @returns {{payload, type: string}}
 */
export const tripDatesSet = value => ({
  type: TRIP_DATES_SET,
  payload: value,
});

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchTransactionRequest = () => ({
  type: FETCH_TRANSACTION_REQUEST,
});

export const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});

export const fetchTransactionError = e => ({
  type: FETCH_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const fetchTimeSlotsRequest = () => ({
  type: FETCH_TIME_SLOTS_REQUEST,
});

export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});

export const fetchTimeSlotsError = e => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: e,
});

export const addChildLongTermTransactions = childLongTermTransactions => ({
  type: ADD_CHILD_LONG_TERM_TRANSACTIONS,
  payload: childLongTermTransactions,
});

export const addNextLongTermTransaction = nextLongTermTransaction => ({
  type: ADD_NEXT_CHILD_TRANSACTION,
  payload: nextLongTermTransaction,
});

export const addCurrentChildLongTermTransaction = currentChildLongTermTransaction => ({
  type: ADD_CURRENT_CHILD_TRANSACTION,
  payload: currentChildLongTermTransaction,
});

export const estimatedTxRequestPending = () => ({
  type: ESTIMATED_TX_REQUEST_PENDING,
});

export const estimatedTxRequestSuccess = tx => ({
  type: ESTIMATED_TX_REQUEST_SUCCESS,
  payload: tx,
});

export const estimatedTxRequestError = error => ({
  type: ESTIMATED_TX_REQUEST_ERROR,
  payload: error,
});

export const updateBookingInitiateRequest = () => ({
  type: UPDATE_BOOKING_INITIATE_REQUEST,
});

export const updateBookingInitiateSuccess = () => ({
  type: UPDATE_BOOKING_INITIATE_SUCCESS,
});

export const updateBookingInitiateError = error => ({
  type: UPDATE_BOOKING_INITIATE_ERROR,
  payload: error,
});

export const acceptUpdateBookingRequest = () => ({
  type: ACCEPT_UPDATE_BOOKING_REQUEST,
});

export const acceptUpdateBookingSuccess = () => ({
  type: ACCEPT_UPDATE_BOOKING_SUCCESS,
});

export const acceptUpdateBookingError = error => ({
  type: ACCEPT_UPDATE_BOOKING_ERROR,
  payload: error,
});

export const cancelUpdateBookingRequest = () => ({
  type: CANCEL_UPDATE_BOOKING_REQUEST,
});

export const cancelUpdateBookingSuccess = () => ({
  type: CANCEL_UPDATE_BOOKING_SUCCESS,
});

export const cancelUpdateBookingError = error => ({
  type: CANCEL_UPDATE_BOOKING_ERROR,
  payload: error,
});

export const fetchUpdateBookingTxsRequest = () => ({
  type: FETCH_UPDATE_BOOKING_TXS_REQUEST,
});

export const fetchUpdateBookingTxsSuccess = () => ({
  type: FETCH_UPDATE_BOOKING_TXS_SUCCESS,
});

export const fetchUpdateBookingTxsError = () => ({
  type: FETCH_UPDATE_BOOKING_TXS_ERROR,
});

export const cancelAllUpdateBookingTxsRequest = () => ({
  type: CANCEL_ALL_UPDATE_BOOKING_TXS_REQUEST,
});

export const cancelAllUpdateBookingTxsSuccess = () => ({
  type: CANCEL_ALL_UPDATE_BOOKING_TXS_SUCCESS,
});

export const cancelAllUpdateBookingTxsError = error => ({
  type: CANCEL_ALL_UPDATE_BOOKING_TXS_ERROR,
  payload: error,
});

export const fetchLastUpdateBookingTxRequest = () => ({
  type: FETCH_LAST_UPDATE_BOOKING_TX_REQUEST,
});

export const fetchLastUpdateBookingTxSuccess = () => ({
  type: FETCH_LAST_UPDATE_BOOKING_TX_SUCCESS,
});

export const fetchLastUpdateBookingTxError = () => ({
  type: FETCH_LAST_UPDATE_BOOKING_TX_ERROR,
});
