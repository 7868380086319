import React from 'react';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink } from '../../components';
import config from '../../config';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { FormattedMessage } from '../../util/reactIntl';
import DetailCardImage from './DetailCardImage';
import BookingPeriodComponent from './BookingPeriodComponent';

import css from './BookingInfoSection.css';
import { connect } from 'react-redux';

const BookingInfoSection = props => {
  const {
    className,
    rootClassName,
    transaction,
    isCustomer,
    showAvatar,
    listing,
    currentProvider,
    intl,
  } = props;

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPanel.deletedListingTitle',
  });

  const listingId = listing.id.uuid;
  const listingTitle = listing.attributes.deleted ? deletedListingTitle : listing.attributes.title;
  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;
  const listingLoaded = !!listing.id;
  const listingDeleted = listingLoaded && listing.attributes.deleted;
  let listingTimezone =
    listing &&
    listing.attributes &&
    listing.attributes.publicData &&
    listing.attributes.publicData.listingTimezone;
  const timeZone = getDefaultTimeZoneOnBrowser(listingTimezone);

  const classes = classNames(rootClassName || css.bookingInfoContainer, className);

  const createListingLink = (
    listingId,
    label,
    listingDeleted,
    searchParams = {},
    className = ''
  ) => {
    if (!listingDeleted) {
      const params = { id: listingId, slug: createSlug(label) };
      const to = { search: stringify(searchParams) };
      return (
        <NamedLink className={css.bookingListingTitle} name="ListingPage" params={params} to={to}>
          {label}
        </NamedLink>
      );
    } else {
      return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
    }
  };

  return (
    <div className={classes}>
      <DetailCardImage
        avatarWrapperClassName={css.avatarWrapperDesktop}
        listingTitle={listingTitle}
        image={firstImage}
        provider={currentProvider}
        isCustomer={isCustomer}
        showAvatar={showAvatar}
        rootClassName={css.detailCardImage}
        wrapperClassName={css.detailCardWrapper}
        imageClassName={css.detailCardImageRoot}
      />
      <div className={css.infoContainer}>
        {createListingLink(listingId, listingTitle, listingDeleted)}
        <BookingPeriodComponent
          booking={transaction.booking}
          unitType={config.bookingUnitType}
          timezone={timeZone}
          transaction={transaction}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingInfoSection);
