import React from 'react';
import { bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { txGetPromoLineItem, txIsEnquired, txIsRequested } from '../../util/transaction';
import { formatMoney } from '../../util/currency';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { ensureCurrentUser, userDisplayName } from '../../util/data';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { resetCode } from '../ListingPage/ListingPage.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  Footer,
  IconSpinner,
  InboxNav,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  UserDisplayName,
} from '../../components';
import { LocationSearchForm } from '../../forms';
import { NotFoundPage, TopbarContainer } from '../../containers';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

import { loadDataLongTerm as loadData } from './InboxPage.duck';
import css from './InboxPage.css';
import {
  PROCESS_NAME_LTR_FIRST,
  txIsAcceptedLTF,
  txIsBeingPickedUpLTF,
  txIsCancelledLTF,
  txIsCompletedLTF,
  txIsDeclinedLTF,
  txIsEnquiredLTF,
  txIsPaymentExpiredLTF,
  txIsPaymentPendingLTF,
  txIsPickedUpLTF,
  txIsRefundedLTF,
  txIsRequestedLTF,
  txIsWaitingForRefundLTF,
  txIsWithdrawnLTF,
} from '../../util/transactionLongTermFirst';
import {
  PROCESS_NAME_LTR_MIDDLE,
  txIsCompletedLTM,
  txIsCreatedPaymentIntentLTM,
  txIsPaymentExpiredLTM,
  txIsPaymentPendingLTM,
  txIsRefundedLTM,
  txIsRequestedLTM,
  txIsWaitingForRefundLTM,
} from '../../util/transactionLongTermMiddle';
import {
  PROCESS_NAME_LTR_LAST,
  txHasBeenCompletedLTL,
  txIsBeingDropOffLTL,
  txIsCancelledLTL,
  txIsCreatedPaymentIntentLTL,
  txIsPaymentExpiredLTL,
  txIsPaymentPendingLTL,
  txIsRefundedLTL,
  txIsRequestedLTL,
  txIsWaitingForRefundLTL,
} from '../../util/transactionLongTermLast';
import { LONG_TERM_STATUS_FINISH } from '../../components/TransactionPanel/DropOffButtonsMaybe';

const { Money } = sdkTypes;

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

// Translated name of the state of the given transaction
export const txState = (intl, rawTx, type) => {
  let tx = rawTx;
  const isOrder = type === 'order';
  const isFinishPaymentLongTerm =
    get(tx, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
  if (isFinishPaymentLongTerm) {
    tx = rawTx.currentChildTransaction || rawTx;
  }
  if (txIsEnquiredLTF(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsRequestedLTF(tx)) {
    const requested = isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.stateRequested',
          }),
        }
      : {
          nameClassName: css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };

    return requested;
  } else if (
    txIsPaymentPendingLTF(tx) ||
    txIsPaymentPendingLTL(tx) ||
    txIsPaymentPendingLTM(tx) ||
    txIsCreatedPaymentIntentLTL(tx) ||
    txIsCreatedPaymentIntentLTM(tx)
  ) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpiredLTF(tx) || txIsPaymentExpiredLTM(tx) || txIsPaymentExpiredLTL(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclinedLTF(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsWithdrawnLTF(tx)) {
    return {
      nameClassName: css.nameDeclined,
      bookingClassName: css.bookingDeclined,
      lastTransitionedAtClassName: css.lastTransitionedAtDeclined,
      stateClassName: css.stateDeclined,
      state: intl.formatMessage({
        id: 'InboxPage.stateWithdraw',
      }),
    };
  } else if (txIsBeingPickedUpLTF(tx)) {
    return {
      nameClassName: css.nameRequested,
      bookingClassName: css.bookingRequested,
      lastTransitionedAtClassName: css.lastTransitionedAtRequested,
      stateClassName: css.stateRequested,
      state: intl.formatMessage({
        id: 'InboxPage.stateBeingPickedUp',
      }),
    };
  } else if (txIsPickedUpLTF(tx)) {
    return {
      nameClassName: css.nameAccepted,
      bookingClassName: css.bookingAccepted,
      lastTransitionedAtClassName: css.lastTransitionedAtAccepted,
      stateClassName: css.stateAccepted,
      state: intl.formatMessage({
        id: 'InboxPage.statePickedup',
      }),
    };
  } else if (txIsBeingDropOffLTL(tx)) {
    return {
      nameClassName: css.nameRequested,
      bookingClassName: css.bookingRequested,
      lastTransitionedAtClassName: css.lastTransitionedAtRequested,
      stateClassName: css.stateRequested,
      state: intl.formatMessage({
        id: 'InboxPage.stateBeingDropoff',
      }),
    };
    // } else if (txIWaitingRequestDropoffLTL(tx)) {
    //   return {
    //     nameClassName: css.nameRequested,
    //     bookingClassName: css.bookingRequested,
    //     lastTransitionedAtClassName: css.lastTransitionedAtRequested,
    //     stateClassName: css.stateRequested,
    //     state: intl.formatMessage({
    //       id: 'InboxPage.stateDropoffPending',
    //     }),
    //   };
    // } else if (txIWaitingRequestPickupLTF(tx)) {
    //   return {
    //     nameClassName: css.nameRequested,
    //     bookingClassName: css.bookingRequested,
    //     lastTransitionedAtClassName: css.lastTransitionedAtRequested,
    //     stateClassName: css.stateRequested,
    //     state: intl.formatMessage({
    //       id: 'InboxPage.statePickupPending',
    //     }),
    //   };
  } else if (txIsAcceptedLTF(tx) || txIsRequestedLTL(tx) || txIsRequestedLTM(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsCancelledLTF(tx) || txIsCancelledLTL(tx) || txIsCompletedLTM(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txHasBeenCompletedLTL(tx) || txIsCompletedLTM(tx) || txIsCompletedLTF(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else if (
    txIsWaitingForRefundLTF(tx) ||
    txIsWaitingForRefundLTM(tx) ||
    txIsWaitingForRefundLTL(tx)
  ) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.waitingForRefund',
      }),
    };
  } else if (txIsRefundedLTF(tx) || txIsRefundedLTM(tx) || txIsRefundedLTL(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.refunded',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx && tx.attributes.lastTransition);
    return null;
  }
};

const bookingData = (tx, isOrder, intl) => {
  if (isOrder) {
    // for guest
    const bookingPrice = tx.attributes.payinTotal;
    const price =
      bookingPrice === null
        ? formatMoney(intl, new Money(0, 'AUD'))
        : formatMoney(intl, bookingPrice);
    return { price };
  }

  const promoLineItem = txGetPromoLineItem(tx);

  const promoAmount = promoLineItem ? promoLineItem.lineTotal.amount : 0;

  const totalPrice = tx.attributes.payoutTotal || { amount: 0 };

  const finalTotalPrice = new Money(totalPrice.amount - (promoAmount || 0), 'AUD');

  return { price: formatMoney(intl, finalTotalPrice) };
};

const isDateToday = time => {
  const now = new Date();
  return (
    now.getDate() === time.getDate() &&
    now.getMonth() === time.getMonth() &&
    now.getFullYear() === time.getFullYear()
  );
};

const isDateInThisWeek = time => {
  const now = new Date();
  const diff = now.getTime() - time.getTime();
  const timeConst = 1000 * 60 * 60 * 24;
  const remainNow =
    (now.getHours() * 3600 * 1000 +
      now.getMinutes() * 60 * 1000 +
      now.getSeconds() * 1000 +
      now.getMilliseconds()) /
    timeConst;
  return now.getDay() + remainNow + diff / timeConst < 7;
};

const isDateInThisYear = time => {
  const now = new Date();
  return time.getFullYear() === now.getFullYear();
};

const getMonthShort = time => {
  return new Intl.DateTimeFormat('en-US', { month: 'short' }).format(time);
};

const getWeekDateShort = time => {
  return new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(time);
};

const getTimeHour12 = time => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
    .format(time)
    .toLowerCase();
};

const convetTXTime = tx => {
  const date = new Date(tx.attributes.lastTransitionedAt);
  const now = new Date();
  let displayTime = '';
  if (isDateToday(date)) {
    displayTime = 'Today';
  } else if (isDateInThisWeek(date)) {
    displayTime = getWeekDateShort(date);
  } else if (isDateInThisYear(date)) {
    displayTime = `${getMonthShort(date)} ${date.getDate()}`;
  } else {
    displayTime = `${getMonthShort(date)} ${date.getDate()} ${date.getFullYear()}`;
  }
  displayTime = `${displayTime}, ${getTimeHour12(date)}`;
  return displayTime;
};

const getBookingDateRange = tx => {
  const startDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  }).format(tx.attributes.protectedData.bookingDisplayStart);
  const endDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  }).format(tx.attributes.protectedData.bookingDisplayEnd);
  return `${startDate} - ${endDate}`;
};

const checkPassedConfirmPayment = tx => {
  const transitions = get(tx, 'attributes.transitions', []);
  let result = false;
  let isExpire = false;
  transitions.forEach(transition => {
    const transitionName = transition && transition.transition;
    if (transitionName.includes('expire-payment')) {
      isExpire = true;
    }
    if (transitionName.includes('confirm-payment')) {
      result = true;
    }
  });
  return isExpire ? true : result;
};
// Functional component as internal helper to print BookingInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType, type } = props;
  const isEnquiry = txIsEnquired(tx);

  const { customer, provider } = tx;

  const otherUser = isOrder ? provider : customer;
  const bannedUserDisplayName = intl.formatMessage({
    id: 'InboxPage.bannedUserDisplayName',
  });
  const otherUserDisplayName = userDisplayName(otherUser, bannedUserDisplayName);

  if (isEnquiry) {
    return null;
  }

  return (
    <div className={classNames(css.bookingInfo, bookingClassName)}>
      {convetTXTime(tx)}
      <span className={css.itemPrice}>
        {!isOrder && !!tx.listing && tx.listing.attributes.title}
        {isOrder && otherUserDisplayName} ({getBookingDateRange(tx)})
      </span>
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  // tx: propTypes.transaction,
  unitType: propTypes.bookingUnitType.isRequired,
};

export const InboxItem = props => {
  const { unitType, type, tx, intl, stateData } = props;
  const { customer, provider } = tx;
  const isOrder = type === 'order';

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const isSaleNotification = !isOrder && txIsRequested(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const { price } = bookingData(tx, isOrder, intl);
  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  const isPassPaymentTransition = checkPassedConfirmPayment(tx);
  const isLinkedTransaction = isPassPaymentTransition || !isOrder;

  const content = (
    <>
      <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
      <div className={css.itemInfo}>
        <div className={classNames(css.itemUsername, stateData.nameClassName)}>
          {isOrder ? (!!tx.listing ? tx.listing.attributes.title : null) : otherUserDisplayName}
        </div>
        <BookingInfoMaybe
          bookingClassName={stateData.bookingClassName}
          intl={intl}
          isOrder={isOrder}
          tx={tx}
          unitType={unitType}
          type={type}
        />
      </div>
      <div className={css.itemState}>
        <div className={classNames(css.stateName, stateData.stateClassName)}>{stateData.state}</div>
        <div
          className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
          // title={lastTransitionedAt.long}
        >
          {price}
        </div>
      </div>
    </>
  );

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        <Avatar user={otherUser} />
      </div>
      {isLinkedTransaction ? (
        <NamedLink
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          {content}
        </NamedLink>
      ) : (
        <div className={`${linkClasses} ${css.longTermMiddleRental}`}>{content}</div>
      )}
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  // tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    currentUserHasListings,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    history,
    location,
    callSetInitialValues,
    onInitializeCardPaymentData,
    onResetCode,
  } = props;
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;

  const toTxItem = tx => {
    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);
    const exclusionaryTransaction = [
      config.bookingProcessAliasDrivelahGoDeposit,
      PROCESS_NAME_LTR_MIDDLE,
      PROCESS_NAME_LTR_LAST,
    ];
    if (exclusionaryTransaction.includes(tx.attributes.processName)) {
      return null;
    }

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          location={location}
          history={history}
          callSetInitialValues={callSetInitialValues}
          onInitializeCardPaymentData={onInitializeCardPaymentData}
          onResetCode={onResetCode}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const handleSearchSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const searchParams = { address: search, origin, bounds, timezone: selectedPlace.timezone };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResultsWrapper}>
        <div className={css.noResultsTitle}>
          <FormattedMessage
            id={
              isOrders
                ? 'InboxPage.noOrdersFound'
                : currentUserHasListings
                ? 'InboxPage.noSalesFound'
                : 'InboxPage.noSalesnoListingFound'
            }
          />
        </div>
        <p className={css.noResultsDescription}>
          <FormattedMessage
            id={
              isOrders
                ? 'InboxPage.noOrdersFoundDesc'
                : currentUserHasListings
                ? 'InboxPage.noSalesFoundDesc'
                : 'InboxPage.noSalesnoListingFoundDesc'
            }
            values={{ newline: <br /> }}
          />
        </p>
        {!isOrders ? (
          <NamedLink
            className={css.listYourCarLink}
            name={currentUserHasListings ? 'ManageListingsPage' : 'NewListingPage'}
          >
            <FormattedMessage
              id={currentUserHasListings ? 'InboxPage.manageYourListing' : 'InboxPage.listYourCar'}
            />
          </NamedLink>
        ) : (
          <LocationSearchForm className={css.searchForm} onSubmit={handleSearchSubmit} />
        )}
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxLongTermPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;
  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.ordersTabTitle" />
        </span>
      ),
      selected: isOrders,
      linkProps: {
        name: 'InboxLongTermPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.salesTabTitle" />
          {providerNotificationBadge}
        </span>
      ),
      selected: !isOrders,
      linkProps: {
        name: 'InboxLongTermPage',
        params: { tab: 'sales' },
      },
    },
  ];
  const nav = (
    <TabNav
      selectedClass={css.customSelectedTabLink}
      linkClass={css.customTabLink}
      rootClassName={css.tabs}
      tabRootClassName={css.tab}
      tabs={tabs}
    />
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled} className={css.page}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
            contactUsClassName={css.contactUs}
          />
          <InboxNav selectedPageName="InboxLongTermBasePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={`${css.navigation} ${css.mainBodySideNav}`}>
          <h1 className={css.title}>
            <FormattedMessage id="InboxPage.title" />
          </h1>
          {nav}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain className={css.mainBody}>
          {error}
          <ul className={css.itemList}>
            {!fetchInProgress ? (
              transactions.map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {noResults}
          </ul>
          {pagingLinks}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  // transactions: arrayOf(propTypes.transaction),

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    longTermTransactionsWithChild,
  } = state.InboxPage;
  const {
    currentUser,
    currentUserNotificationCount: providerNotificationCount,
    currentUserHasListings,
  } = state.user;
  const transactions = getMarketplaceEntities(state, transactionRefs).filter(tx =>
    [PROCESS_NAME_LTR_FIRST, PROCESS_NAME_LTR_LAST, PROCESS_NAME_LTR_MIDDLE].includes(
      tx.attributes.processName
    )
  );
  longTermTransactionsWithChild.forEach(transactionWithChild => {
    const matchTransaction = transactions.find(tx => tx.id.uuid === transactionWithChild.id.uuid);
    if (matchTransaction) {
      matchTransaction.currentChildTransaction = transactionWithChild.currentChildTransaction;
    }
  });
  return {
    currentUser,
    currentUserHasListings,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions,
  };
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onResetCode: () => dispatch(resetCode()),
});

const InboxPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
