import { storableError } from '../util/errors';
import { fetchCurrentUser, openMissingInfomationModal } from './user.duck';
import { addIntercomNote } from '../util/intercomHelper';
import { get } from 'lodash';
import { sendGAEvent } from '../util/googleAnalytics';
import { adrollRecordUser } from '../util/adroll';

// ================ Action types ================ //

export const VERIFICATION_REQUEST = 'app/EmailVerification/VERIFICATION_REQUEST';
export const VERIFICATION_SUCCESS = 'app/EmailVerification/VERIFICATION_SUCCESS';
export const VERIFICATION_ERROR = 'app/EmailVerification/VERIFICATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  isVerified: false,

  // verification
  verificationError: null,
  verificationInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VERIFICATION_REQUEST:
      return {
        ...state,
        verificationInProgress: true,
        verificationError: null,
      };
    case VERIFICATION_SUCCESS:
      return { ...state, verificationInProgress: false, isVerified: true };
    case VERIFICATION_ERROR:
      return { ...state, verificationInProgress: false, verificationError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const verificationInProgress = state => {
  return state.EmailVerification.verificationInProgress;
};

// ================ Action creators ================ //

export const verificationRequest = () => ({ type: VERIFICATION_REQUEST });
export const verificationSuccess = () => ({ type: VERIFICATION_SUCCESS });
export const verificationError = error => ({
  type: VERIFICATION_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const verify = (verificationToken, mobileVerificationNeeded) => (
  dispatch,
  getState,
  sdk
) => {
  if (verificationInProgress(getState())) {
    return Promise.reject(new Error('Email verification already in progress'));
  }
  dispatch(verificationRequest());

  // Note that the thunk does not reject when the verification fails, it
  // just dispatches the login error action.
  return sdk.currentUser
    .verifyEmail({ verificationToken })
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => {
      const { currentUser } = getState().user;
      sendGAEvent({
        eventCategory: 'SignUp',
        eventAction: 'Verify Email',
        eventValue: 1,
      });

      adrollRecordUser('53c1a316');

      const intercomUserId = get(currentUser, 'attributes.profile.privateData.intercomUserId');

      addIntercomNote({
        intercomUserId,
        userEmail: currentUser.attributes.email,
        note: 'Verified email',
      }).then(() => {
        console.log('sent intercom event');
      });
      dispatch(verificationSuccess());
    })

    .then(() => dispatch(openMissingInfomationModal(mobileVerificationNeeded)))
    .catch(e => {
      console.error(e);
      dispatch(verificationError(storableError(e)));
    });
};
