import { apiBaseUrl } from '../../util/api';
import cookie from 'cookie_js';

// That's where we redirect user from IDP registration to home
// passing all needed info

const getAuthCookieName = () => {
  let cookieName = null;
  Object.keys(cookie.all()).forEach(key => {
    if (/^st-.+-token$/.test(key)) {
      cookieName = key;
    }
  });
  return cookieName;
};

export const formRedirectUrl = data => {
  const redirectURL = `/?access_token=${data.access_token}
                        &scope=${data.scope}
                        &token_type=${data.token_type}
                        &expires_in=${data.expires_in}
                        &refresh_token=${data.refresh_token}`;
  return redirectURL;
};

// When we get response from Idp auth, we are obliged to receive tokens in query
// But Sharetribe is actually also sending set-cookie header in it's response
// In dev env set-cookie header works fine, but in staging or prod env we can't use that
// That's why we need to check if we have tokens passed in query, and if we do,
// we need to create a new cookie

export const replaceAuthCookie = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  Object.keys(queryParams).forEach(k => (queryParams[k] = queryParams[k].trim()));
  if (queryParams && queryParams.access_token) {
    const cookieName = getAuthCookieName();
    let date = new Date();
    date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000);
    const expires = '; expires=' + date.toUTCString();
    deleteAuthCookie();
    if (cookieName) {
      document.cookie = `${cookieName}=${JSON.stringify(queryParams)}; expires=${expires}; path=/`;
    }
  }
};

// Delete auth cookie when needed

export const deleteAuthCookie = () => {
  const cookieName = getAuthCookieName();
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.

export const createUserWithIdp = body => {
  const url = `${apiBaseUrl()}/api/auth/create-user-with-idp`;
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
};
