import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { uploadFiles } from '../../util/fileUpload';
import { Button, IconCheckmark } from '../../components';
import {
  clearUploadData,
  uploadImage,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './FieldUploadFile.css';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

class UploadFileComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileUrl: null,
      fileType: null,
      isUploading: false,
      isNotUpload: props.initialValue ? false : true,
    };

    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.setStateUploadData = this.setStateUploadData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue && !this.state.fileUrl) {
      this.setState({
        fileUrl: nextProps.initialValue,
        isNotUpload: false,
      });
    }
  }

  componentWillMount() {
    if (this.props.isPhoto) {
      this.props.clearUploadData();
    }
  }

  setStateUploadData(url, fileType) {
    this.setState({
      fileUrl: url,
    });
    if (this.props.isPhoto) {
      this.props.handleShowImg(url);
    }
    this.props.setStateUploadData(url, fileType);
  }

  handleselectedFile(event, fileType) {
    if (!event.target.files || !event.target.files.length || event.target.files.length === 0)
      return;
    this.handleUpload(event.target.files, fileType);
  }

  handleUpload(files, fileType) {
    const { currentUser, initialValue, isPhoto, disablesPhoto, onImageUpload } = this.props;
    const user = currentUser;

    if (initialValue && ((isPhoto && disablesPhoto) || (!isPhoto && !disablesPhoto))) {
      return;
    }

    if (!user.id) {
      return;
    }
    this.props.handleError(null);

    this.setStateUploadData(null, fileType);

    for (let file of files) {
      let data = new FormData();
      data.append('file', file, file.name);
      data.append('userId', user.id.uuid);
      data.append('userEmail', user.attributes.email);
      if (this.props.isPhoto) {
        data.append('tripPhotos', 'true');
      }
      this.setState({
        isUploading: true,
        isNotUpload: true,
      });
      this.props.setUploadState({
        isUploading: true,
        isNotUpload: true,
      });
      if (isPhoto) {
        const tempId = `${file.name}_${Date.now()}`;
        onImageUploadHandler({ id: tempId, file }, onImageUpload);
      }
      uploadFiles(data)
        .then(response => {
          const code = response.status;
          if (code !== 200) {
            return null;
          }
          return response.json();
        })
        .then(jsonResponse => {
          if (!jsonResponse.url) {
            const error = {
              id: 'EditListingVerificationForm.genericUploadError',
              fileType: fileType,
            };
            this.setState({
              isUploading: false,
              isNotUpload: true,
            });
            this.props.handleError(error);
            this.props.setUploadState({
              isUploading: false,
              isNotUpload: true,
            });
          } else {
            this.setState({
              isUploading: false,
              isNotUpload: false,
            });
            this.props.setUploadState({
              isUploading: false,
              isNotUpload: false,
            });
            if (this.props.pushEventGTM) {
              this.props.pushEventGTM(this.props.buttonId);
            }
            this.props.setPhotoUrl(jsonResponse.previewLink);
            this.setStateUploadData(jsonResponse.url, fileType);
          }
        })
        .catch(e => {
          const error = {
            id: 'EditListingVerificationForm.genericUploadError',
            fileType: fileType,
          };
          this.setState({
            isUploading: false,
            isNotUpload: true,
          });
          this.props.handleError(error);
          this.props.setUploadState({
            isUploading: false,
            isNotUpload: true,
          });
        });
    }
  }

  render() {
    const {
      id,
      name,
      acceptType,
      typeFile,
      isPhoto,
      buttonClassName,
      buttonLabel,
      previewClassName,
      previewLabel,
      initialValue,
      hideUpload,
      disable,
      imageUpload,
      disablesPhoto,
      buttonId,
      showPreview = false,
    } = this.props;
    const previewUrl = this.props && this.props.initialValue;
    return (
      <div className={css.inputWrapper}>
        {!hideUpload && (
          <Button
            type="button"
            disabled={
              !!disable ||
              !!(initialValue && ((isPhoto && disablesPhoto) || (!isPhoto && !disablesPhoto)))
            }
            className={buttonClassName}
            children={
              <div className={css.buttonContents}>
                {buttonLabel}
                &nbsp;&nbsp;
                {!this.state.isNotUpload && <IconCheckmark className={css.checkmark} />}
              </div>
            }
            inProgress={this.state.isUploading}
            onClick={() => {
              this.inputElement.click();
            }}
            id={buttonId}
          />
        )}
        {!hideUpload && (
          <input
            accept={acceptType ? acceptType : '*'}
            className={css.inputField}
            type="file"
            name={name}
            id={id}
            onChange={e => {
              this.handleselectedFile(e, typeFile);
            }}
            ref={input => (this.inputElement = input)}
          />
        )}
        {hideUpload && !isPhoto && this.state.fileUrl && (
          <div
            className={previewClassName}
            // href={this.state.fileUrl}
            target="_blank"
          >
            {previewLabel}
            <IconCheckmark className={css.checkmarkSmall} />
          </div>
        )}
        {!isPhoto && !this.state.fileUrl && hideUpload && (
          <a className={previewClassName} target="_blank">
            No file
          </a>
        )}
        {showPreview && previewUrl && <img className={css.previewUrl} src={previewUrl} alt />}
      </div>
    );
  }
}

UploadFileComp.defaultProps = {
  buttonClassName: null,
  id: null,
  name: null,
  acceptType: null,
  label: null,
  disable: false,
  typeFile: null,
  pushEventGTM: false,
};

UploadFileComp.propTypes = {
  buttonClassName: string,
  id: string,
  name: string,
  acceptType: string,
  typeFile: string,
  disable: bool,
  buttonId: string,
  pushEventGTM: func,
};

const mapStateToProps = state => {
  const { image } = state.ProfileSettingsPage;

  return {
    imageUpload: image,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  clearUploadData: () => dispatch(clearUploadData()),
});

const UploadFile = connect(mapStateToProps, mapDispatchToProps)(UploadFileComp);

export default UploadFile;
