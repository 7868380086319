import config from '../config';

const apiUrl = config.apiUrl;
const creditProgramId = config.creditProgramId;
const { guestMinimumApplicableCredits } = config.custom;

export const getUserCreditsAndPromos = ({ contactId }) => {
  return fetch(apiUrl + `/api/v2/lightrail/contacts/${contactId}/promos`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(data => {
      const promosData = data.data;
      // const creditItem = promos.filter(p => {
      //   return p.programId === creditProgramId;
      // })[0];
      // const otherPromos = promos.filter(p => {
      //   return p.programId !== creditProgramId;
      // });
      return { promosData };
    })
    .then(({ promosData }) => {
      let credits = 0;
      const { summary } = promosData;
      if (summary) {
        const { redemptions } = summary;
        if (redemptions) {
          const { gift, loyalty_card } = redemptions;
          if (gift) {
            const { amount_to_go } = gift;
            credits = amount_to_go / 100;
          }
        }
      }
      return {
        promos: promosData,
        credits: credits,
      };
      // if (!creditItem || creditItem.frozen ||
      //   creditItem.canceled || creditItem.usesRemaining === 0 || creditItem.balance < 500) {
      //   console.log("UUUUUUUU", {
      //     promos: otherPromos,
      //     credits: 0,
      //     creditItem
      //   });
      //   return {
      //     promos: otherPromos,
      //     credits: 0,
      //     creditItem
      //   };
      // } else {
      //   console.log("GGGGGGGG", {
      //     promos: otherPromos,
      //     credits: creditItem.balance / 100,
      //     creditItem
      //   });
      //   return {
      //     promos: otherPromos,
      //     credits: creditItem.balance / 100,
      //     creditItem
      //   }
      // }
    });
};

export const getPromosValue = ({ code, valueId, userId }) => {
  const url =
    apiUrl +
    '/api/v2/lightrail/promos/' +
    (code ? `${code}?isCode=true&userId=${userId}` : `${valueId}?userId=${userId}`);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(data => {
      let promo = data.data;
      promo.message = data.message;
      return promo;
    });
};

export const checkoutCredits = data => {
  return fetch(apiUrl + '/api/v2/lightrail/checkout/credits', {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const simulateCheckoutCredits = data => {
  return fetch(apiUrl + '/api/v2/lightrail/checkout/credits/simulate', {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const simulateCheckoutPromos = data => {
  return fetch(apiUrl + '/api/v2/lightrail/checkout/promos/simulate', {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const checkoutPromos = data => {
  return fetch(apiUrl + '/api/v2/lightrail/checkout/promos', {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const captureTransaction = transactionId => {
  return fetch(apiUrl + `/api/v2/lightrail/checkout/${transactionId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const cancelTransaction = transactionId => {
  return fetch(apiUrl + `/api/v2/lightrail/checkout/${transactionId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const createContact = ({ flexId, email, code, credits }) => {
  return fetch(apiUrl + '/api/v2/lightrail/contacts', {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      flexId,
      email,
      code,
      credits,
    }),
  });
};
