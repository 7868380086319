import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './ListingPageGo.css';
import ActionBarMaybe from '../ActionBarMaybe';
import { Carousel, ImageCarousel, Modal, ResponsiveImage } from '../../../components';
import imageSource from '../../../util/imageSource';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    onOpenSharingModal,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    viewPhotoButtonId,
  } = props;

  const { images = [] } = listing;

  const hasImages = images.length > 0;

  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const shareButton = listing.id ? (
    <button className={css.shareButton} onClick={onOpenSharingModal}>
      <img className={css.logo} src={imageSource.ShareIcon} />
      <FormattedMessage id="ListingPage.shareButton" />
    </button>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button
      id={viewPhotoButtonId}
      className={css.viewPhotos}
      onClick={e => handleViewPhotosClick(e, true)}
    >
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      {actionBar}
      {hasImages ? (
        <Carousel
          images={images}
          title={title}
          onOpenSharingModal={onOpenSharingModal}
          handleViewPhotosClick={handleViewPhotosClick}
          listing={listing}
        />
      ) : (
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={null}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
          </div>
        </div>
      )}
      <div className={css.bottomActions}>
        {shareButton}
        {viewPhotosButton}
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
