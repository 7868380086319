import React from 'react';
import BLandingCss from './SectionCars.css';
import { ListingCardV2, NamedLink } from '../../components';
import { useSelector } from 'react-redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import Slider from 'react-slick';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';

const SectionCars = ({ isMobileLayout }) => {
  const { PHVFeaturedCarIds, fetchPHVFeaturedCarInProgress } = useSelector(
    state => state.SearchPage
  );
  const PHVFeaturedCarRefs = PHVFeaturedCarIds.map(id => ({ type: 'listing', id }));
  const listings = useSelector(state => getMarketplaceEntities(state, PHVFeaturedCarRefs));
  const sliderConfig = {
    // className: BLandingCss.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={BLandingCss.carsListings}>
      <div className={BLandingCss.carsListingsTitleWrapper}>
        <div className={BLandingCss.carsListingsSectionTitle}>Choose from most rented cars</div>
      </div>
      {!isMobileLayout ? (
        fetchPHVFeaturedCarInProgress ? (
          <div className={BLandingCss.carsListingsLoading}>Loading car...</div>
        ) : (
          <div className={BLandingCss.carsListingsGrid}>
            {listings.map(l => (
              <div className={BLandingCss.carsListingsCardWrapper}>
                <ListingCardV2 hideFlexLabel={true} listing={l} />
              </div>
            ))}
          </div>
        )
      ) : (
        ''
      )}
      {isMobileLayout ? (
        fetchPHVFeaturedCarInProgress ? (
          <div className={BLandingCss.sectionCarsLoading}>Loading car...</div>
        ) : (
          <div className={BLandingCss.sliderWrapper}>
            <Slider {...sliderConfig}>
              {listings.map(l => (
                <div className={BLandingCss.carsListingsCardWrapper}>
                  <ListingCardV2 hideFlexLabel={true} listing={l} />
                </div>
              ))}
            </Slider>
          </div>
        )
      ) : (
        ''
      )}
      <div className={BLandingCss.btnWrapper}>
        <NamedLink
          name="SearchPage"
          to={{
            search:
              '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798&pub_isPHV=true',
          }}
          className={BLandingCss.viewAllCar}
          onClick={() => {
            initiateEventFromListing({
              props: {
                searchParams: {
                  // ...currentSearchParams,
                  bounds: {
                    _sdkType: 'LatLngBounds',
                    ne: {
                      _sdkType: 'LatLng',
                      lat: 1.4708809,
                      lng: 104.04157989999999,
                    },
                    sw: {
                      _sdkType: 'LatLng',
                      lat: 1.216611,
                      lng: 103.60650989999999,
                    },
                  },
                },
              },
              event: EVENT_SEARCH_PERFORMED,
            });
            gSend(GTAG_ACTIONS.ACTION_SEARCH, {
              // TODO: Assign proper details.
              search_location: `?address=Australia&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999&pub_isPHV=true`,
              search_pickupdate: `NA`,
              search_pickuptime: `NA`,
              search_dropoffdate: `NA`,
              search_dropofftime: `NA`,
            });
          }}
        >
          View more cars
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionCars;
