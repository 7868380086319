import isEmpty from 'lodash/isEmpty';
import {
  createBookingProperties,
  createExperimentDataProperties,
  createHostAndGuestWithoutTransaction,
  createListingProperties,
  createRawPropertiesForGTM,
  createSearchProperties,
  createTripProperties,
  createUIProperties,
  createUIPropertiesForDuplicatedButton,
  createUserHostGuestProperties,
  createUserProperties,
  getEventAndButtonFromTab,
} from './gtmCreateProperties';
import { drivelahApiPost } from '../apiHelper';
import { ensureListing } from '../data';
import { getPUSHTELLResultPage } from '../emailNotify';

export const pushDataLayer = data => {
  const dataLayer = typeof window !== 'undefined' ? window.dataLayer : [];
  dataLayer && dataLayer.push(data);
};

export const pushGTMVerifiedEmailPhone = async (properties, event, callback) => {
  const { ui, currentUser } = properties || {};
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const newVariant = getPUSHTELLResultPage();
  const userData = await createUserProperties(currentUser);
  const { user } = userData;
  const eventCallback = callback ? { eventCallback: callback } : {};
  if (user) {
    let experimentName = 'AttractivenessScoreExperiment' + newVariant;
    user[experimentName] = newVariant;
  }
  const data = {
    event: event,
    properties: {
      ui: {
        ...UIData,
      },
      user: {
        ...user,
      },
      dateTime,
    },
    ...eventCallback,
  };
  pushDataLayer(data);
};

export const pushGTMSignupForm = (properties, event) => {
  const { ui } = properties || {};
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const data = {
    event: event,
    properties: {
      ui: {
        ...UIData,
      },
      dateTime,
    },
  };
  pushDataLayer(data);
};

export const pushGTMBookEventAfterOrderCompleted = async (properties, event, particularParams) => {
  const { ui, currentUser, listing, transaction, trip } = properties || {};
  const newVariant = getPUSHTELLResultPage();
  const {
    isMultipleButton = false,
    existTransaction = false,
    userDiffActionTaker,
    isServerSideEvent,
  } = particularParams || {};
  const { author } = listing || {};
  const { provider } = transaction || {};
  const listingData = createListingProperties(listing);
  const bookingData = createBookingProperties(transaction, listing);
  const UIData = isMultipleButton
    ? createUIPropertiesForDuplicatedButton(ui)
    : createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData = existTransaction
    ? await createUserHostGuestProperties(currentUser, transaction, userDiffActionTaker)
    : await createHostAndGuestWithoutTransaction(currentUser, author || provider);
  const { user, host, guest, otherUser } = userData;
  const tripProperties = trip ? { trip: createTripProperties(transaction) } : {};
  if (user) {
    let experimentNameUser = 'AttractivenessScoreExperiment' + newVariant;
    user[experimentNameUser] = newVariant;
  }
  if (guest) {
    let experimentNameuest = 'AttractivenessScoreExperiment' + newVariant;
    guest[experimentNameuest] = newVariant;
  }
  const data = {
    event: event,
    properties: {
      revenue: bookingData.totalprice,
      currency: 'AUD',
      orderId: bookingData.trip_id,
      quantity: bookingData.trip_days,
      ui: { ...UIData },
      user: !existTransaction && userDiffActionTaker ? otherUser : user,
      host: existTransaction ? host : otherUser,
      guest: existTransaction ? guest : user,
      listing: { ...listingData },
      booking: { ...bookingData },
      ...tripProperties,
      dateTime,
    },
  };
  if (isServerSideEvent) {
    pushGTMEventToServer(data);
  } else {
    pushDataLayer(data);
  }
};

export const pushGTMBookEvent = async (properties, event, particularParams) => {
  const { ui, currentUser, listing, transaction, trip } = properties || {};
  const {
    isMultipleButton = false,
    existTransaction = false,
    userDiffActionTaker,
    isServerSideEvent,
  } = particularParams || {};
  const { author } = listing || {};
  const { provider } = transaction || {};
  const listingData = createListingProperties(listing);
  const bookingData = createBookingProperties(transaction, listing);
  const UIData = isMultipleButton
    ? createUIPropertiesForDuplicatedButton(ui)
    : createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData = existTransaction
    ? await createUserHostGuestProperties(currentUser, transaction, userDiffActionTaker)
    : await createHostAndGuestWithoutTransaction(currentUser, author || provider);
  const { user, host, guest, otherUser } = userData;
  const tripProperties = trip ? { trip: createTripProperties(transaction) } : {};

  const data = {
    event: event,
    properties: {
      ui: { ...UIData },
      user: !existTransaction && userDiffActionTaker ? otherUser : user,
      host: existTransaction ? host : otherUser,
      guest: existTransaction ? guest : user,
      listing: { ...listingData },
      booking: { ...bookingData },
      ...tripProperties,
      dateTime,
    },
  };
  if (isServerSideEvent) {
    pushGTMEventToServer(data);
  } else {
    pushDataLayer(data);
  }
};

export const initiateEventFromTransaction = ({
  props,
  transaction,
  buttonId,
  buttonText,
  event,
  isMultipleButton,
  existTransaction,
  userDiffActionTaker,
  isServerSideEvent,
}) => {
  const listing = ensureListing(transaction && transaction.listing);
  const rawProperties = createRawPropertiesForGTM({
    props: props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
    listing,
    transaction,
  });

  const particularParams = {
    isMultipleButton,
    existTransaction,
    userDiffActionTaker,
    isServerSideEvent,
  };

  pushGTMBookEvent(rawProperties, event, particularParams);
};

export const pushGTMSearchUsedMap = async (currentUser, event) => {
  const dateTime = new Date().toISOString();
  const userData = await createUserProperties(currentUser);
  const { user } = userData;
  const data = {
    event: event,
    properties: {
      user: {
        ...user,
      },
      dateTime,
    },
  };
  pushDataLayer(data);
};

export const pushGTMExpermentEvent = async (
  properties,
  event,
  particularParams,
  experimentData
) => {
  const { ui, currentUser, listing } = properties || {};
  const newVariant = getPUSHTELLResultPage();
  const { isHost, userDiffActionTaker, isServerSideEvent } = particularParams;
  const { author } = listing || {};
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const experiment = experimentData ? await createExperimentDataProperties(experimentData) : null;
  const userData =
    (isHost && !userDiffActionTaker) || !author
      ? await createUserProperties(currentUser)
      : await createHostAndGuestWithoutTransaction(currentUser, author);
  const { user, otherUser = null } = userData;
  if (user) {
    let experimentNameUser = 'AttractivenessScoreExperiment' + newVariant;
    user[experimentNameUser] = newVariant;
  }
  if (otherUser) {
    let experimentNameGuest = 'AttractivenessScoreExperiment' + newVariant;
    otherUser[experimentNameGuest] = newVariant;
  }
  const data = {
    event: event,
    properties: {
      ui: { ...UIData },
      experiment: { ...experiment },
      user: userDiffActionTaker ? otherUser : user,
      dateTime,
    },
  };
  if (isServerSideEvent) {
    pushGTMEventToServer(data);
  } else {
    pushDataLayer(data);
  }
};

export const pushGTMSearchViewedEvent = async (properties, event, particularParams) => {
  const { ui, currentUser, listing, search, transaction } = properties || {};
  const { isHost, userDiffActionTaker, isServerSideEvent } = particularParams;
  const { author } = listing || {};
  const listingData = createListingProperties(listing);
  const searchData = await createSearchProperties(search.searchParams, search.pagination);
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData =
    (isHost && !userDiffActionTaker) || !author
      ? await createUserProperties(currentUser)
      : await createHostAndGuestWithoutTransaction(currentUser, author);
  const { user, otherUser = null } = userData;
  const bookingData = !isEmpty(transaction) ? createBookingProperties(transaction, listing) : null;
  const data = {
    event: event,
    properties: {
      id: listingData.id,
      ui: { ...UIData },
      user: userDiffActionTaker ? otherUser : user,
      host: isHost && !userDiffActionTaker ? user : otherUser,
      guest: isHost && !userDiffActionTaker ? otherUser : user,
      listing: { ...listingData },
      search: { ...searchData },
      booking: bookingData,
      dateTime,
    },
  };
  if (isServerSideEvent) {
    pushGTMEventToServer(data);
  } else {
    pushDataLayer(data);
  }
};

export const pushGTMSExpertSearchEvent = async (properties, event, particularParams) => {
  const { ui, currentUser, listing, search, transaction } = properties || {};
  const { isHost, userDiffActionTaker, isServerSideEvent } = particularParams;
  const { author } = listing || {};
  const newVariant = getPUSHTELLResultPage();
  const listingData = createListingProperties(listing);
  const searchData = await createSearchProperties(search.searchParams, search.pagination);
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData =
    (isHost && !userDiffActionTaker) || !author
      ? await createUserProperties(currentUser)
      : await createHostAndGuestWithoutTransaction(currentUser, author);
  const { user, otherUser = null } = userData;
  const bookingData = !isEmpty(transaction) ? createBookingProperties(transaction, listing) : null;
  if (user) {
    let experimentNameUser = 'AttractivenessScoreExperiment' + newVariant;
    user[experimentNameUser] = newVariant;
  }
  if (otherUser) {
    let experimentNameOtherUser = 'AttractivenessScoreExperiment' + newVariant;
    otherUser[experimentNameOtherUser] = newVariant;
  }
  const data = {
    event: event,
    properties: {
      ui: { ...UIData },
      user: userDiffActionTaker ? otherUser : user,
      host: isHost && !userDiffActionTaker ? user : otherUser,
      guest: isHost && !userDiffActionTaker ? otherUser : user,
      listing: { ...listingData },
      search: { ...searchData },
      booking: bookingData,
      dateTime,
      category: 'search',
    },
  };
  pushGTMEventToServer(data);
};

export const pushGTMSearchEvent = async (properties, event, particularParams) => {
  const { ui, currentUser, listing, search, transaction } = properties || {};
  const newVariant = getPUSHTELLResultPage();
  const { isHost, userDiffActionTaker, isServerSideEvent, bookingError } = particularParams;
  const { author } = listing || {};
  const listingData = createListingProperties(listing);
  const searchData = await createSearchProperties(search.searchParams, search.pagination);
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData =
    (isHost && !userDiffActionTaker) || !author
      ? await createUserProperties(currentUser)
      : await createHostAndGuestWithoutTransaction(currentUser, author);
  const { user, otherUser = null } = userData;
  const bookingData = !isEmpty(transaction) ? createBookingProperties(transaction, listing) : null;
  if (user) {
    let experimentNameUser = 'AttractivenessScoreExperiment' + newVariant;
    user[experimentNameUser] = newVariant;
  }
  if (otherUser) {
    let experimentNameOtherUser = 'AttractivenessScoreExperiment' + newVariant;
    otherUser[experimentNameOtherUser] = newVariant;
  }
  const data = {
    event: event,
    properties: {
      ui: { ...UIData },
      user: userDiffActionTaker ? otherUser : user,
      host: isHost && !userDiffActionTaker ? user : otherUser,
      guest: isHost && !userDiffActionTaker ? otherUser : user,
      listing: { ...listingData },
      search: { ...searchData },
      booking: bookingData,
      dateTime,
      bookingError,
    },
  };
  if (isServerSideEvent) {
    pushGTMEventToServer(data);
  } else {
    pushDataLayer(data);
  }
};

export const initiateEventViewedFromLandingPage = async ({
  location,
  props,
  event,
  buttonId,
  searchParams,
}) => {
  // const { searchParams, pagination } = props;
  // const rawProperties = createRawPropertiesForGTMPromoLandingPage({
  //   props,
  //   // search: {
  //   //   searchParams,
  //   //   pagination,
  //   // }
  // });
  // const properties = createRawPropertiesForGTM({
  //   props: props,
  // });
  // console.log("RRRRRRRRRRRRRRRRRRR", properties);
  // const { ui, currentUser } = properties || {};
  // const { ui } = properties || {};
  // const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  // const userData = await createUserProperties(currentUser);
  // const { user } = userData;

  const data = {
    event: event,
    properties: {
      category: 'browse',
      // ui: { ...UIData },
      // user: user,
      // host: user,
      dateTime,
    },
  };
  pushDataLayer(data);
};

export const initiateEventFromExpertPage = ({
  props,
  listing,
  buttonId,
  buttonText,
  event,
  isHost,
  transaction,
  userDiffActionTaker,
  isServerSideEvent,
}) => {
  const { searchParams, pagination } = props;
  const rawProperties = createRawPropertiesForGTM({
    props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
    listing,
    search: {
      searchParams,
      pagination,
    },
    transaction,
  });
  const particularParams = {
    isHost,
    userDiffActionTaker,
    isServerSideEvent,
  };
  pushGTMSExpertSearchEvent(rawProperties, event, particularParams);
};

export const initiateEventFromListing = ({
  props,
  listing,
  buttonId,
  buttonText,
  event,
  isHost,
  transaction,
  userDiffActionTaker,
  isServerSideEvent,
  bookingError,
}) => {
  const { searchParams, pagination } = props;
  const rawProperties = createRawPropertiesForGTM({
    props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
    listing,
    search: {
      searchParams,
      pagination,
    },
    transaction,
  });
  const particularParams = {
    isHost,
    userDiffActionTaker,
    isServerSideEvent,
    bookingError,
  };
  pushGTMSearchEvent(rawProperties, event, particularParams);
};

export const initiateEventViewedFromListing = ({
  props,
  listing,
  buttonId,
  buttonText,
  event,
  isHost,
  transaction,
  userDiffActionTaker,
  isServerSideEvent,
}) => {
  const { searchParams, pagination } = props;
  const rawProperties = createRawPropertiesForGTM({
    props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
    listing,
    search: {
      searchParams,
      pagination,
    },
    transaction,
  });
  const particularParams = {
    isHost,
    userDiffActionTaker,
    isServerSideEvent,
  };

  pushGTMSearchViewedEvent(rawProperties, event, particularParams);
};

export const initiateExperimentEventFromListing = ({
  props,
  listing,
  buttonId,
  buttonText,
  event,
  isHost,
  transaction,
  userDiffActionTaker,
  isServerSideEvent,
  experimentData,
}) => {
  const { searchParams, pagination } = props;
  const rawProperties = createRawPropertiesForGTM({
    props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
    listing,
    search: {
      searchParams,
      pagination,
    },
    transaction,
  });
  const particularParams = {
    isHost,
    userDiffActionTaker,
    isServerSideEvent,
  };
  pushGTMExpermentEvent(rawProperties, event, particularParams, experimentData);
};

export const pushGTMListACarEvent = async (properties, event) => {
  const { ui, currentUser } = properties || {};
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData = await createUserProperties(currentUser);
  const { user } = userData;
  const data = {
    event: event,
    properties: {
      ui: { ...UIData },
      user,
      host: user,
      dateTime,
    },
  };
  pushDataLayer(data);
};

export const initiateListACarEventFromTab = ({ tab, props, eventButtonParam = [] }) => {
  const [event, buttonId, buttonText] = tab ? getEventAndButtonFromTab(tab) : eventButtonParam;
  const rawProperties = createRawPropertiesForGTM({
    props: props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
  });
  pushGTMListACarEvent(rawProperties, event);
};

export const pushGTMGuestVerify = ({
  props,
  event,
  buttonId,
  buttonText,
  pageURL,
  eventCallback,
}) => {
  const rawProperties = createRawPropertiesForGTM({
    props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
    pageURL,
  });
  pushGTMVerifiedEmailPhone(rawProperties, event, eventCallback);
};

/**
 *
 * @param {Object} arg
 * @param {Object} arg.props
 * @param {string} arg.buttonId
 * @param {string} arg.buttonText
 * @param {string} arg.event
 */
export const pushGTMHostVerify = async ({ props, event, buttonId, buttonText }) => {
  const properties = createRawPropertiesForGTM({
    props: props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
  });
  const { ui, currentUser } = properties || {};
  const UIData = createUIProperties(ui);
  const dateTime = new Date().toISOString();
  const userData = await createUserProperties(currentUser);
  const { user } = userData;

  const data = {
    event: event,
    properties: {
      ui: { ...UIData },
      user: user,
      host: user,
      dateTime,
    },
  };
  pushDataLayer(data);
};

/**
 *
 * @param {Object} arg
 * @param {Object} arg.props
 * @param {Object} arg.transaction
 * @param {string} arg.buttonId
 * @param {string} arg.buttonText
 * @param {string} arg.event
 * @param {boolean} arg.isMultipleButton
 * @param {boolean} arg.existTransaction
 * @param {boolean} arg.userDiffActionTaker
 * @param {boolean} arg.isServerSideEvent
 */
export const pushGTMTripEvent = ({
  props,
  transaction,
  buttonId,
  buttonText,
  event,
  isMultipleButton,
  existTransaction,
  userDiffActionTaker,
  isServerSideEvent,
}) => {
  const listing = ensureListing(transaction && transaction.listing);
  const rawProperties = createRawPropertiesForGTM({
    props,
    button: !buttonId
      ? null
      : {
          buttonId: buttonId,
          text: buttonText,
        },
    listing,
    transaction,
  });

  const particularParams = {
    isMultipleButton,
    existTransaction,
    userDiffActionTaker,
    isServerSideEvent,
  };

  pushGTMBookEvent(rawProperties, event, particularParams);
};

/**
 * @param {Object} arg
 * @param {Object} props
 * @param {String} event
 * @param {String} buttonId
 * @param {String} buttonText
 * @param {String} pageURL
 * @param {Function} eventCallback
 */
export const pushGTMBrowseEvent = ({
  props,
  event,
  buttonId,
  buttonText,
  pageURL,
  eventCallback,
}) => {
  pushGTMGuestVerify({
    props,
    event,
    buttonId,
    buttonText,
    pageURL,
    eventCallback,
  });
};

const pushGTMEventToServer = data => {
  const urlGTM = `gtm/server-side-event`;
  return drivelahApiPost(urlGTM, data);
};
