import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findRouteByRouteName, pathByRouteName } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { distanceCountHelper } from '../../util/distanceCountHelper';
import { checkCanUseMastercardPromo } from '../CheckoutPage/CheckoutPage.helpers';
import { StripePaymentForm } from '../../forms';
import { SEND_REQUEST_BOOKING_BUTTON_ID } from '../../util/gtm/gtmConstants';
import { calculateBookingDays, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardPayment } from '../../ducks/stripe.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { triggerRelevanceSync } from '../SearchPage/Relevance.js';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import {
  ensureBooking,
  ensureCurrentUser,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
  restoreTransaction,
} from '../../util/data';
import {
  AddOnsSection,
  BookingInfoSection,
  Button,
  IconSpinner,
  Logo,
  NamedLink,
  Page,
  PartnerInfo,
} from '../../components';
import { TRANSITION_ADDITIONAL_REQUEST } from '../../util/transactionAddons';
import {
  isTransactionChargeDisabledError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import {
  confirmPayment,
  estimateBreakdown,
  initiateOrder,
  loadData,
  resetTransaction,
  stripeCustomer,
} from './AddOnsPage.duck';

import css from './AddOnsPage.css';
import imageSource from '../../util/imageSource.js';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;
const LIMIT_DISTANCE = 6;
const LIMIT_START_HOUR = 9;
const LIMIT_END_HOUR = 21;
// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
export const USE_SAVED_CARD = 'USE_SAVED_CARD';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
export const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

export const checkoutStepConfiguration = {
  CHECKOUT_ADD_ONS: 'addOns',
  CHECKOUT_PAYMENT: 'payments',
};

const { LatLng } = sdkTypes;

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const AddOnsPageComponent = props => {
  const {
    scrollingDisabled,
    transaction: parentTransaction,
    intl,
    speculateTransactionInProgress,
    transactionRole,
    viewport,
    currentUser,
    onEstimateBreakdown,
    estimateBreakdownInProgress,
    estimatedTx,
    estimateError,
    onResetTransaction,
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    history,
    dispatch,
    stripeCustomerFetched,
    onInitiateOrder,
    onHandleCardPayment,
    initiateOrderError,
    confirmPaymentError,
    onConfirmPayment,
    onSavePaymentMethod,
    fetchStripeCustomer,
  } = props;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState(checkoutStepConfiguration.CHECKOUT_ADD_ONS);
  const [isDeliveryState, setIsDeliveryState] = useState(false);
  const [isExcessReductionState, setIsExcessReductionState] = useState(false);
  const [isFuelInclusionState, setIsFuelInclusionState] = useState(false);
  const [isChangeAddon, setIsChangeAddon] = useState(false);
  const [isRetryButtonClicked, setIsRetryButtonClicked] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isUsingMastercard, setIsUsingMastercard] = useState(false);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    fetchStripeCustomer();
  }, []);

  useEffect(() => {
    if (parentTransaction && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [parentTransaction, dataLoaded]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentListing = parentTransaction && parentTransaction.listing;
  const currentProvider = parentTransaction && ensureUser(parentTransaction.provider);
  const currentCustomer = parentTransaction && ensureUser(parentTransaction.customer);
  const addonsTransactions = get(parentTransaction, 'attributes.metadata.addonsTransactions');
  const listingTitle = currentListing && currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'AddOnsPage.title' }, { title: listingTitle });
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;
  const isLoading = !dataLoaded || speculateTransactionInProgress || estimateBreakdownInProgress;
  const isInstantBooking =
    currentListing &&
    currentListing.attributes &&
    currentListing.attributes.publicData.instantBooking;
  const isPayForFuel = get(parentTransaction, 'attributes.protectedData.payForFuel');
  const isDelivery = get(currentListing, 'attributes.publicData.delivery');
  const isDeliveryAddons = get(parentTransaction, 'attributes.metadata.isDeliveryAddons');
  const isExcessReductionAddons = get(
    parentTransaction,
    'attributes.metadata.isExcessReductionAddons'
  );
  const isFuelInclusionAddons = get(parentTransaction, 'attributes.metadata.isFuelInclusionAddons');
  const isDeliveryTransaction = get(parentTransaction, 'attributes.protectedData.isDelivery');
  const isExcessReductionTransaction = get(
    parentTransaction,
    'attributes.protectedData.isExcessReduction'
  );
  const isFuelInclusionTransaction = get(
    parentTransaction,
    'attributes.protectedData.isFuelInclusion'
  );
  const currentUserLocation = get(
    currentUser,
    'attributes.profile.protectedData.location.selectedPlace.origin'
  );
  const listingLocation = get(currentListing, 'attributes.geolocation');
  const distance =
    currentUserLocation &&
    listingLocation &&
    distanceCountHelper(currentUserLocation, listingLocation);
  const bookingDisplayStart =
    parentTransaction && parentTransaction.booking.attributes.displayStart;
  const bookingDisplayEnd = parentTransaction && parentTransaction.booking.attributes.displayEnd;
  const startTransaction =
    parentTransaction && parentTransaction.attributes.protectedData.bookingDisplayStart;
  const endTransaction =
    parentTransaction && parentTransaction.attributes.protectedData.bookingDisplayEnd;
  const startHour = startTransaction && moment(startTransaction).format('HH');
  const endHour = endTransaction && moment(endTransaction).format('HH');
  const endMinutes = endTransaction && moment(endTransaction).format('mm');
  const currentAuthor = currentListing && ensureUser(currentListing.author);

  useEffect(() => {
    if (isDeliveryState || isExcessReductionState || isFuelInclusionState) {
      onEstimateBreakdown({
        bookingStart: bookingDisplayStart,
        bookingEnd: bookingDisplayEnd,
        listing: currentListing,
        isDelivery: isDeliveryState,
        isExcessReduction: isExcessReductionState,
        isFuelInclusion: isFuelInclusionState,
        parentTransaction: parentTransaction.id.uuid,
      });
      setIsChangeAddon(false);
    } else if (isChangeAddon) {
      onResetTransaction();
      setIsChangeAddon(false);
    }
  }, [isDeliveryState, isExcessReductionState, isFuelInclusionState, isChangeAddon]);

  let isLessEndTime = false;
  if (endHour < LIMIT_END_HOUR) isLessEndTime = true;
  if (endHour == LIMIT_END_HOUR) isLessEndTime = endMinutes && parseInt(endMinutes) == 0;
  const showDelivery =
    isDelivery && distance <= LIMIT_DISTANCE && startHour >= LIMIT_START_HOUR && isLessEndTime;

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (isLoading) {
    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.loading}>
          <IconSpinner />
          <div>Please wait...</div>
        </div>
      </Page>
    );
  }

  const addOnsContent = [
    {
      id: 1,
      addOnName: 'Home Delivery & Pickup',
      addOnIcon: imageSource.newCheckoutPage.deliveryIcon,
      description:
        'Get the car delivered right to your home. Ensure maximum comfort and safety by getting the car delivered and picked up to your home by the host',
      brief: 'Get the car delivered right to your home.',
      isAdded: isDeliveryTransaction || isDeliveryAddons,
      addOnAmount: 20,
      isExpanded: false,
    },
    {
      id: 2,
      addOnName: 'Premium Protection',
      addOnIcon: imageSource?.tripPage?.premiumProtectionIcon,
      description:
        'For just $10, save up to $4280 on your liability. Without Premium protection you will be liable for $6420 (in own & 3rd party damage) which can be reduced to only $2140 with Premium protection',
      brief: 'For just $10, save up to $4280 on your liability',
      isAdded: isExcessReductionTransaction || isExcessReductionAddons,
      addOnAmount: 10,
      isExpanded: false,
    },
    // {
    //   id: 3,
    //   addOnName: 'Fuel Inclusion',
    //   addOnIcon: addOnImage,
    //   description:
    //     "Avoid the hassle of re-fueling and just enjoy the drive. Use the fuel from host's car and pay directly via Drive Mate. You will be charged once the trip is completed.",
    //   brief: 'Avoid the hassle of re-fueling and just enjoy the drive.',
    //   isAdded: isFuelInclusionTransaction || isFuelInclusionAddons,
    //   addOnAmount: 0.29,
    //   isExpanded: false,
    // },
  ];

  const handleChangeAddOn = e => {
    setIsChangeAddon(!isChangeAddon);
    if (e.currentTarget.id === 'addOn1') setIsDeliveryState(!isDeliveryState);
    if (e.currentTarget.id === 'addOn2') setIsExcessReductionState(!isExcessReductionState);
    if (e.currentTarget.id === 'addOn3') setIsFuelInclusionState(!isFuelInclusionState);
  };

  const reloadButton = (
    <Button
      className={css.retryButton}
      inProgress={isRetryButtonClicked}
      onClick={() => {
        if (typeof window !== 'undefined') {
          setIsRetryButtonClicked(true);
          window.location.reload();
        }
      }}
    >
      <FormattedMessage id={'CheckoutPage.retryButton'} />
    </Button>
  );

  const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
  const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

  let initiateOrderErrorMessage = null;
  let retryButton = null;

  if (isChargeDisabledError) {
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
      </p>
    );
    retryButton = reloadButton;
  } else if (stripeErrors && stripeErrors.length > 0) {
    // NOTE: Error messages from Stripes are not part of translations.
    // By default they are in English.
    const stripeErrorsAsString = stripeErrors.join(', ');
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage
          id="CheckoutPage.initiateOrderStripeError"
          values={{ stripeErrors: stripeErrorsAsString }}
        />
      </p>
    );
    retryButton = reloadButton;
  } else if (initiateOrderError) {
    if (initiateOrderError.name === 'insufficient_funds') {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderErrorInsufficientFunds" />
        </p>
      );
      retryButton = reloadButton;
    } else if (initiateOrderError.status === 'card_error') {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderErrorCardError"
            values={{ message: initiateOrderError.message }}
          />
        </p>
      );
      retryButton = reloadButton;
    } else if (initiateOrderError.message) {
      if (initiateOrderError.status === 411) {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.initiateOrderOverlapMessage" />
          </p>
        );
      } else {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorWithMessage"
              values={{ message: initiateOrderError.message }}
            />
          </p>
        );
      }
      retryButton = reloadButton;
    } else {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" />
        </p>
      );
      retryButton = reloadButton;
    }
  }

  // Allow showing page when currentUser is still being downloaded,
  // but show payment form only when user info is loaded.
  const showPaymentForm = !!(
    currentUser &&
    !initiateOrderError &&
    !retrievePaymentIntentError &&
    !retrievePaymentDepositIntentError
  );

  const initializeOrderPage = (initialValues, routes, dispatch) => {
    const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

    // Transaction is already created, but if the initial message
    // sending failed, we tell it to the OrderDetailsPage.
    dispatch(OrderPage.setInitialValues(initialValues));
  };

  const handlePaymentIntent = handlePaymentParams => {
    const {
      pageData,
      speculatedTransaction,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(speculatedTransaction);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );
    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const { bookingProcess, isMinimumPrice, listing, ...fnRestParams } = fnParams;
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // // If paymentIntent exists, order has been initiated previously.
      // return hasPaymentIntents
      //   ? Promise.resolve(storedTx)
      //   : onInitiateOrder(fnRestParams, storedTx.id);

      return onInitiateOrder(fnRestParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : {};

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order,
        userId: currentUser.id.uuid,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({
            transactionId: order.id,
            paymentIntent,
            order,
          })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      const { listing } = pageData;
      return onConfirmPayment({
        ...fnParams,
        savedListing: listing,
        parentTransactionId: parentTransaction.id.uuid,
        userId: currentUser.id.uuid,
      });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return {
                ...fnParams,
                paymentMethodSaved: false,
              };
            }
            return {
              ...fnParams,
              paymentMethodSaved: true,
            };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return {
              ...fnParams,
              paymentMethodSaved: false,
            };
          });
      } else {
        return Promise.resolve({
          ...fnParams,
          paymentMethodSaved: true,
        });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSavePaymentMethod
    );

    let bookingProcess = config.additionalTransactionProcess;
    let transition = TRANSITION_ADDITIONAL_REQUEST;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const { hasMastercardPromoBeenUsed } = currentUser.attributes.profile.metadata;
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const shouldUseMastercardPromoLineItem =
      isUsingMastercard && checkCanUseMastercardPromo(currentUser);

    const orderParams = {
      transition,
      listingId: currentListing.id,
      bookingStart: storedTx.booking.attributes.start,
      bookingEnd: storedTx.booking.attributes.end,
      bookingDisplayStart: storedTx.booking.attributes.displayStart,
      bookingDisplayEnd: storedTx.booking.attributes.displayEnd,
      protectedData: storedTx.attributes.protectedData,
      preauthenListingId: config.preauthenListingId,
      depositListingId: config.depositListingId,
      quantity: calculateBookingDays(
        storedTx.booking.attributes.displayStart,
        storedTx.booking.attributes.displayEnd
      ),
      bookingProcess,
      listing: currentListing,
      userId: currentUser.id.uuid,
      shouldUseMastercardPromoLineItem,
      parentTransactionId: parentTransaction.id.uuid,
      addonsTransactions: addonsTransactions,
      ...optionalPaymentParams,
    };

    if (isDeliveryState) orderParams.isDelivery = isDeliveryState;
    if (isExcessReductionState) orderParams.isExcessReduction = isExcessReductionState;
    if (isFuelInclusionState) orderParams.isFuelInclusion = isFuelInclusionState;

    return handlePaymentIntentCreation(orderParams);
  };

  const handleSubmit = values => {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: {
        bookingData: {},
        bookingDates: {},
        listing: currentListing,
        transaction: estimatedTx,
        timeSlotsObj: {},
      },
      speculatedTransaction: estimatedTx,
      stripe: stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      paymentDepositIntent,
    };

    const existingTransaction = ensureTransaction(estimatedTx);
    const tx = existingTransaction;

    handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { id, messageSuccess, paymentMethodSaved } = res;
        setSubmitting(false);

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : id;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
          id: parentTransaction.id.uuid,
        });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);

        currentListing &&
          currentListing.id &&
          currentListing.id.uuid &&
          triggerRelevanceSync(currentListing.id.uuid);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
      });
  };

  const hasDefaultPaymentMethod = !!(
    stripeCustomerFetched &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
  );

  // If paymentIntent status is not waiting user action,
  // handleCardPayment has been called previously.
  const hasPaymentIntentUserActionsDone =
    (paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status)) ||
    (paymentDepositIntent &&
      STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status));

  const userName =
    currentUser && currentUser.attributes
      ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
      : null;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
  const {
    blockNo,
    building,
    floorUnit,
    city,
    country,
    postalCode,
    transactionTimezone,
  } = protectedData;
  const { selectedPlace } = protectedData.location || {};
  const { address, origin } = selectedPlace || {};
  const initalValuesForStripePayment = {
    name: userName,
    blockNo,
    addressLine1: blockNo + ' ' + address,
    addressLine2: floorUnit,
    location: {
      search: address,
      selectedPlace: {
        address,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    building,
    floorUnit,
    city,
    country,
    postalCode,
    postal: postalCode,
  };

  const onStripeInitialized = stripe => {
    setStripe(stripe);

    // const { paymentIntent, onRetrievePaymentIntent } = props;
    // const tx = estimatedTx;

    // // We need to get up to date PI, if booking is created but payment is not expired.
    // const shouldFetchPaymentIntent =
    //   stripe &&
    //   !paymentIntent &&
    //   tx &&
    //   tx.id &&
    //   tx.booking &&
    //   tx.booking.id &&
    //   txIsPaymentPending(tx);

    // if (shouldFetchPaymentIntent) {
    //   const { stripePaymentIntentClientSecret } =
    //     tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
    //       ? tx.attributes.protectedData.stripePaymentIntents.default
    //       : {};

    //   // Fetch up to date PaymentIntent from Stripe
    //   onRetrievePaymentIntent({
    //     stripe,
    //     stripePaymentIntentClientSecret,
    //   });
    // }
  };

  const timeZone = getDefaultTimeZoneOnBrowser(transactionTimezone);
  const txBooking = estimatedTx && ensureBooking(estimatedTx.booking);

  const breakdown = estimatedTx && estimatedTx.id && txBooking.id && (
    <BookingBreakdownNew
      timeZone={timeZone}
      className={css.bookingBreakdown}
      userRole="customer"
      unitType={config.bookingUnitType}
      transaction={restoreTransaction(estimatedTx, isUsingMastercard)}
      booking={txBooking}
      shouldShowMastercardPromoLineItem={isUsingMastercard}
      checkoutStep={checkoutStep}
      changeCheckoutStep={step => setCheckoutStep(step)}
      isAddons={true}
    />
  );

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {parentTransaction && (
                <NamedLink
                  className={css.arrowBack}
                  name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: parentTransaction.id.uuid }}
                >
                  <span className={css.arrowBackText}>&#8249;</span>

                  {listingTitle}
                </NamedLink>
              )}
            </div>
            <div className={css.stepTitle}>
              {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS
                ? 'Add-ons for your trip'
                : 'Payment - Addons'}
            </div>
          </div>
        </div>
        <div className={css.contentRow}>
          <div className={css.addonsSectionLeft}>
            {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS ? (
              <div className={css.bookListingContainer}>
                <AddOnsSection
                  currentUser={currentUser}
                  addOnsContent={addOnsContent}
                  transaction={parentTransaction}
                  isProvider={false}
                  intl={intl}
                  updateCheckoutSteps={stepNumber => setCheckoutStep(stepNumber)}
                  isDelivery={showDelivery}
                  isDeliveryState={isDeliveryState}
                  isExcessReductionState={isExcessReductionState}
                  isFuelInclusionState={isFuelInclusionState}
                  handleChangeAddOn={handleChangeAddOn}
                  type={'addOnsPage'}
                  additionalTransaction={estimatedTx}
                />
              </div>
            ) : (
              <div className={css.bookListingContainer}>
                <section className={css.paymentContainer}>
                  {initiateOrderErrorMessage}
                  {retrievePaymentIntentError || retrievePaymentDepositIntentError ? (
                    <p className={css.orderError}>
                      <FormattedMessage id="CheckoutPage.retrievingStripePaymentIntentFailed" />
                    </p>
                  ) : null}
                  {retryButton}
                  {showPaymentForm ? (
                    <StripePaymentForm
                      className={css.paymentForm}
                      onSubmit={handleSubmit}
                      inProgress={submitting}
                      formId="AddOnsPagePaymentForm"
                      paymentInfo={intl.formatMessage({
                        id: 'CheckoutPage.paymentInfo',
                      })}
                      authorDisplayName={currentAuthor.attributes.profile.displayName}
                      showInitialMessageInput={false}
                      initialValues={initalValuesForStripePayment}
                      initiateOrderError={initiateOrderError}
                      handleCardPaymentError={handleCardPaymentError}
                      confirmPaymentError={confirmPaymentError}
                      currentUser={currentUser}
                      transaction={estimatedTx}
                      isProvider={false}
                      intl={intl}
                      hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                      loadingData={!stripeCustomerFetched}
                      defaultPaymentMethod={
                        hasDefaultPaymentMethod
                          ? currentUser.stripeCustomer.defaultPaymentMethod
                          : null
                      }
                      isUsingMastercard={isUsingMastercard}
                      setIsUsingMastercard={val => setIsUsingMastercard(val)}
                      paymentIntent={paymentIntent}
                      onStripeInitialized={onStripeInitialized}
                      isInstantBooking={isInstantBooking}
                      submitButtonId={SEND_REQUEST_BOOKING_BUTTON_ID}
                      isPayForFuel={isPayForFuel}
                      pushEventEnterFormGTM={() => {}}
                      isAddons={true}
                    />
                  ) : null}
                </section>
              </div>
            )}
          </div>
          {isMobileLayout ? null : (
            <div className={css.addonsSectionRight}>
              {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS ? (
                <>
                  {parentTransaction && currentListing && (
                    <BookingInfoSection
                      transaction={parentTransaction}
                      isCustomer={isCustomerRole}
                      showAvatar={false}
                      listing={currentListing}
                      currentProvider={currentProvider}
                      intl={intl}
                    />
                  )}
                  <PartnerInfo
                    currentUserRole={transactionRole}
                    transaction={parentTransaction}
                    otherUser={isProviderRole ? currentCustomer : currentProvider}
                    show={true}
                    isMobileLayout={isMobileLayout}
                    userTypeText={intl.formatMessage({
                      id: 'AddOnsPage.userTypeText',
                    })}
                  />
                </>
              ) : (
                <div className={css.detailsContainerMain}>
                  <h3>Price breakdown</h3>
                  {breakdown}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

AddOnsPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
  parentTransaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

AddOnsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  parentTransaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    transactionRef,
    nextLongTermTransaction,
    childLongTermTransactions,
    currentChildLongTermTransaction,
    estimateBreakdownInProgress,
    estimatedTx,
    estimateError,
    stripeCustomerFetched,
    initiateOrderError,
  } = state.AddOnsPage;
  const { currentUser } = state.user;
  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
  } = state.stripe;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }
  let ownListing = null;
  if (transaction) {
    const ownListingRef = {
      id: transaction.listing.id,
      type: 'ownListing',
    };
    const ownListings = getMarketplaceEntities(state, [ownListingRef]);
    ownListing = ownListings.length > 0 ? ownListings[0] : null;
  }

  return {
    currentUser,
    transaction,
    ownListing,
    estimateBreakdownInProgress,
    estimatedTx,
    estimateError,
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    stripeCustomerFetched,
    initiateOrderError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
    onResetTransaction: () => dispatch(resetTransaction()),
    fetchStripeCustomer: () => dispatch(stripeCustomer()),
    onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
    onHandleCardPayment: params => dispatch(handleCardPayment(params)),
    onConfirmPayment: params => dispatch(confirmPayment(params)),
    onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
      dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  };
};

const AddOnsPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AddOnsPageComponent);

AddOnsPage.loadData = loadData;

export default AddOnsPage;
