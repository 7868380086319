import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import goLogo from '../../assets/logos/GoLogo.png';
import goLogo2x from '../../assets/logos/GoLogo@2x.png';
import {
  Button,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedLink,
  Page,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { fetchCurrentUser, updateUserData } from '../../ducks/user.duck';
import { PaymentDepositForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import {
  checkPaidDeposit,
  DRIVELAH_GO_ELIGIBILITY_CHECK_NOTIFY_REQUESTED,
  REQUEST_APPOINTMENT as REQUEST_APPOINTMENT_EVENT_TYPE,
  sendCheckEligibilityStatus,
  START_CHECK_ELIGIBILITY,
} from '../../util/checkEligibility';
import { ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './CheckEligibilityPage.css';
import { initiateOrder } from './CheckEligibilityPage.duck';
import Loading from './Loading';
import LazyImage from '../../components/LazyImage/LazyImage';

const CHECK = 'check';
const REQUEST_APPOINTMENT = 'appointment';
const DEPOSIT = 'deposit';

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

const pageTitle = (tab, intl) => {
  let title = '';

  return title;
};

const CheckEligibilityPageComponent = props => {
  const {
    intl,
    params,
    scrollingDisabled,
    currentUser,
    onUpdateUserData,
    onHandlePayment,
    initiateOrderInProgress,
    history,
    initiateOrderError,
    onManageDisableScrolling,
    location,
  } = props;

  const { quantity = 1 } = location.search ? parse(location.search || '') : {};
  const { tab } = params;
  const [acceptTerm, setAcceptTerm] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [paidDeposit, setPaidDeposit] = useState(false);
  const [loading, setLoading] = useState(false);
  const title = pageTitle(tab, intl);
  const stripeRef = useRef();

  const setupStripe = () => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey && !stripeRef.current) {
      stripeRef.current = window.Stripe(config.stripe.publishableKey);
    }
  };

  useEffect(() => {
    if (tab === DEPOSIT && currentUser && currentUser.id) {
      setLoading(true);
      checkPaidDeposit({ userId: currentUser.id.uuid }).then(response => {
        const { depositDone } = response;
        setPaidDeposit(depositDone);
        setLoading(false);
      });
    }
    setupStripe();
  }, [currentUser]);

  const handleStartCheck = () => {
    const { id, attributes } = currentUser;
    const { email } = attributes;
    setLoading(true);
    return sendCheckEligibilityStatus(
      { userId: id.uuid, userEmail: email },
      START_CHECK_ELIGIBILITY
    )
      .then(() => {
        return props.fetchCurrentUser().then(() => {
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleNotifyMe = () => {
    const { id, attributes } = currentUser;
    const { email } = attributes;
    setLoading(true);
    return sendCheckEligibilityStatus(
      { userId: id.uuid, userEmail: email },
      DRIVELAH_GO_ELIGIBILITY_CHECK_NOTIFY_REQUESTED
    )
      .then(() => {
        setLoading(false);
        setSubmited(true);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
      });
  };

  const handleRequestAppointment = () => {
    const { id, attributes } = currentUser;
    const { email } = attributes;

    return sendCheckEligibilityStatus(
      { userId: id.uuid, userEmail: email },
      REQUEST_APPOINTMENT_EVENT_TYPE
    ).then(() => {
      onUpdateUserData({
        protectedData: {
          checkEligibleStatus: 'request-appointment',
        },
      }).catch(e => console.error(e));
    });
  };

  const pageContent = () => {
    if (!currentUser || !currentUser.id) return null;
    let content = null;
    const { displayName, protectedData, metadata = {} } = currentUser.attributes.profile;
    const { checkEligibleStatus } = protectedData || {};
    const { drivelahGoEligibilityCheckStatus, drivelahGoEligibilityNotifyMePressed } = metadata;
    switch (tab) {
      case CHECK: {
        const isInProgress = !!(
          drivelahGoEligibilityCheckStatus && drivelahGoEligibilityCheckStatus === 'pending'
        );
        const isDecline = !!(
          drivelahGoEligibilityCheckStatus && drivelahGoEligibilityCheckStatus === 'declined'
        );

        content = isDecline ? (
          <Fragment>
            <div className={css.title}>
              <FormattedMessage
                id="CheckEligibilityPage.declined.title1"
                values={{ displayName }}
              />
              <br />
              <FormattedMessage id="CheckEligibilityPage.declined.title2" />
            </div>
            <div className={css.description}>
              <FormattedMessage id="CheckEligibilityPage.declined.description1" />
            </div>

            <Button
              className={classNames(css.button, css.yellowButton)}
              onClick={drivelahGoEligibilityNotifyMePressed ? () => {} : handleNotifyMe}
              inProgress={loading}
            >
              {submited || drivelahGoEligibilityNotifyMePressed ? (
                <FormattedMessage id="CheckEligibilityPage.declined.receiveYourRequest" />
              ) : (
                <FormattedMessage id="CheckEligibilityPage.declined.pleaseNotifyMe" />
              )}
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            {isInProgress ? (
              <Fragment>
                <div className={css.title}>
                  <FormattedMessage
                    id="CheckEligibilityPage.checkInProgress.title1"
                    values={{ displayName }}
                  />
                  <br />
                  <FormattedMessage id="CheckEligibilityPage.checkInProgress.title2" />
                </div>
                <div className={css.loadingWrapper}>
                  <Loading />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className={css.title}>
                  <FormattedMessage
                    id="CheckEligibilityPage.check.title1"
                    values={{ displayName }}
                  />
                  <br />
                  <FormattedMessage id="CheckEligibilityPage.check.title2" />
                </div>
                <div className={css.description}>
                  <FormattedMessage id="CheckEligibilityPage.check.description1" />
                  <br />
                  <br />
                  <FormattedMessage id="CheckEligibilityPage.check.description2" />
                </div>

                <Button className={css.button} onClick={handleStartCheck} inProgress={loading}>
                  <FormattedMessage id="CheckEligibilityPage.check.startCheck" />
                </Button>
              </Fragment>
            )}
          </Fragment>
        );
        break;
      }
      case REQUEST_APPOINTMENT: {
        const requested = !!(checkEligibleStatus && checkEligibleStatus === 'request-appointment');
        content = (
          <Fragment>
            {requested ? (
              <Fragment>
                <div className={css.title}>
                  <FormattedMessage id="CheckEligibilityPage.requestedAppointment.title" />
                </div>
                <div className={css.description}>
                  <FormattedMessage id="CheckEligibilityPage.requestedAppointment.description" />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className={css.title}>
                  <FormattedMessage
                    id="CheckEligibilityPage.requestAppointment.title1"
                    values={{ displayName }}
                  />
                  <br />
                  <FormattedMessage id="CheckEligibilityPage.requestAppointment.title2" />
                </div>
                <div className={css.description}>
                  <FormattedMessage id="CheckEligibilityPage.requestAppointment.description" />
                </div>
                <div className={css.button} onClick={handleRequestAppointment}>
                  <FormattedMessage id="CheckEligibilityPage.requestAppointment" />
                </div>
              </Fragment>
            )}
          </Fragment>
        );
        break;
      }
      case DEPOSIT: {
        const hasDefaultPaymentMethod = !!(
          ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
          ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
        );
        content = loading ? (
          <Fragment>
            <div className={css.title}>Checking...</div>
          </Fragment>
        ) : submited || paidDeposit ? (
          <Fragment>
            <div className={css.title}>
              <FormattedMessage
                id="CheckEligibilityPage.submitedDeposit.title1"
                values={{ displayName }}
              />
              <br />
              <FormattedMessage id="CheckEligibilityPage.submitedDeposit.title2" />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Modal
              onManageDisableScrolling={onManageDisableScrolling}
              isOpen={acceptTerm}
              disableCloseBtn
            >
              <h2 className={css.title}>
                Terms and Conditions of Drive mate Go Keyless Access Service
              </h2>
              <div className={css.term}>
                <p className={css.description}>
                  All eligible hosts are required to make a refundable deposit of $125 with Drive
                  mate. This deposit is applicable for each listing with Drive mate Go device
                  enabled.
                </p>
                <p className={css.description}>
                  The monthly subscription fee for usage of the Drive mate Go device and service is
                  $30.
                </p>
                <p className={css.description}>
                  First two months of the service is free and there are no charges for installation
                  either. During this trial period, the host agrees to maintain a minimum booking
                  acceptance rate of 50%. If the booking acceptance rate falls below 50%, then the
                  full monthly subscription fee of $30 will be levied on the host in this period.
                  (Hosts who are part of the Drive mate Go pilot trial program are not eligible for
                  the free two months period)
                </p>
                <p className={css.description}>
                  A Drive mate Go host needs to maintain a minimum booking acceptance rate of 50%
                  every month. If the booking acceptance rate falls below 50% for any month, then an
                  additional charge of $10 will be levied for that month.
                </p>
                <p className={css.description}>
                  Termination of service:
                  {'\n\t'}• Removal is free if host has completed at least 10 trip days after
                  installation of device, otherwise a removal charge of $125 shall be borne by the
                  host to uninstall the device. The same will be adjusted in the deposit.
                  {'\n\t'}• If Drive mate decides to remove the host from the platform for violation
                  of any of its policies, then the cost of device removal of $125 will be borne by
                  the host.
                  {'\n\t'}• If the host decides to sell the car, then upon proof of sale, the
                  removal charge will be refunded to host.
                  {'\n\t'}• In the event of termination, the host shall return the rental units to
                  Drive mate within 14 days of termination. The host will be charged the fee stated
                  in clause 6 if the unit is not returned or lost within the stated period.
                  {'\n\t'}• Upon successful uninstallation of device, the security deposit of $125,
                  or the balance, if any, after deduction of any relevant charges stated above or in
                  clause 6, shall be refunded to the same credit/debit card hosts utilised for
                  furnishing security deposit within 14 business days.
                </p>
                <p className={css.description}>
                  If the device is not returned, lost, damaged or vandalized, the host shall be
                  charged $125 to cover the cost of the device. This is inclusive of damages
                  occurred during road accidents, fire, flood, theft, riot or any other natural
                  disasters.{' '}
                </p>
                <p className={css.description}>
                  The host agrees not to modify, tamper, dismantle, disassemble, reverse engineer or
                  decompile any of the hardware components of the device. The host further agrees to
                  use the hardware components only for the primary purpose of the Drive mate Go
                  service.{' '}
                </p>
                <p className={css.description}>
                  Data and all other information generated as a result of the host using the device
                  constitute Customer Information. Drive mate shall treat such Customer Information
                  in the strictest confidence. The host agrees that Drive mate may collect and use
                  such Customer Information gathered from the device as part of Drive mate’s ongoing
                  measures to improve Drive mate’s service offerings and products. Customer
                  Information may also be aggregated or anonymized and used by Drive mate and its
                  partners or affiliates for the study, research, analysis or development of other
                  products and services as the case may be. However, personally identifiable
                  Customer Information will not be resold, disclosed or provided to third parties
                  without the explicit written consent of the host unless required by statutory
                  requirements, regulatory requirements or through a discovery process in legal
                  proceedings initiated in a court of competent jurisdiction.{' '}
                </p>
                <p className={css.description}>
                  This agreement is governed and construed in accordance with the laws of the
                  Australia and unless otherwise agreed upon mutually, both Drive mate and the host
                  hereby agree to submit to the exclusive jurisdiction of the Australia Courts.
                </p>
                <p className={css.lastDescription}>
                  The monthly subscription fee shall be charged to the same credit/debit card hosts
                  utilised for furnishing security deposit. All costs mentioned are subjected to a
                  4% processing fee.
                </p>
              </div>

              <PrimaryButton className={css.accept} onClick={() => setAcceptTerm(false)}>
                confirm
              </PrimaryButton>
              <div className={css.declineWrapper}>
                <NamedLink name="LandingPage" className={css.decline}>
                  decline
                </NamedLink>
              </div>
            </Modal>
            <div className={css.title}>
              <FormattedMessage id="CheckEligibilityPage.deposit.title" />
            </div>
            <div className={css.description}>
              <FormattedMessage
                id="CheckEligibilityPage.deposit.description"
                values={{ amount: 125 * quantity }}
              />
            </div>
            <PaymentDepositForm
              defaultPaymentMethod={
                hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
              }
              quantity={quantity}
              intl={intl}
              currentUser={currentUser}
              initiateOrderError={initiateOrderError}
              inProgress={initiateOrderInProgress}
              onSubmit={values => {
                const selectedPaymentMethod =
                  values.paymentMethod === 'defaultCard' && hasDefaultPaymentMethod
                    ? currentUser.stripeCustomer.defaultPaymentMethod.attributes
                        .stripePaymentMethodId
                    : null;
                return onHandlePayment(
                  values.card,
                  values.stripe || stripeRef.current,
                  selectedPaymentMethod,
                  values.replaceCurrentCard,
                  quantity
                ).then(() => {
                  setSubmited(true);
                  onUpdateUserData({
                    protectedData: {
                      checkEligibleStatus: null,
                    },
                    privateData: {
                      preventRemoveCard: true,
                    },
                  });
                  setTimeout(() => {
                    const routes = routeConfiguration();
                    const url = createResourceLocatorString('LandingPage', routes);
                    history.replace(url);
                  }, 5000);
                });
              }}
            />
          </Fragment>
        );
        break;
      }
    }

    return content;
  };

  const content = pageContent();
  const currentUserLoaded = currentUser && currentUser.id;
  const { metadata = {} } = currentUser ? currentUser.attributes.profile : {};
  const { drivelahGoEligibilityCheckStatus, drivelahGoEligibilityNotifyMePressed } = metadata;
  const isConfirmed = !!(
    drivelahGoEligibilityCheckStatus && drivelahGoEligibilityCheckStatus === 'confirmed'
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.background}>
          <div className={css.contentWrapper}>
            <LazyImage containerHeight={'fit-content'} objectFit={'contain'} imgHeight={'revert-layer'} imgWidth={'revert-layer'} srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogo} />
            {content}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { initiateOrderInProgress, initiateOrderError } = state.CheckEligibilityPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    initiateOrderInProgress,
    initiateOrderError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUserData: data => dispatch(updateUserData(data)),
    onHandlePayment: (card, stripe, paymentMethod, replaceCurrentCard, quantity) =>
      dispatch(initiateOrder(card, stripe, paymentMethod, replaceCurrentCard, quantity)),
    fetchCurrentUser: () => dispatch(fetchCurrentUser()),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

const CheckEligibilityPage = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(CheckEligibilityPageComponent);

export default CheckEligibilityPage;
