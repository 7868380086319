import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import css from './CopyToClipboard.css';
import { array, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';

const SMALL_MOBILE_SCREEN_WIDTH = 550;
const MEDIUM_SCREEN_WIDTH = 768;
const LARGE_SCREEN_WIDTH = 1025;

const CopyToClipboard = props => {
  const { text, className, rootClassName, highlightText = 'Code copied', textToCopy } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleCopyClipboard = copyText => {
    if (copyText !== '') {
      navigator.clipboard.writeText(copyText);
      setShowTooltip(true);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  }, [showTooltip]);

  return (
    <div className={classes}>
      <span className={classes} onClick={() => handleCopyClipboard(textToCopy)}>
        {text}
      </span>
      {showTooltip && <div className={css.toolTip}>{highlightText}</div>}
    </div>
  );
};

CopyToClipboard.defaultProps = {
  className: null,
  rootClassName: null,
};

CopyToClipboard.propTypes = {
  intl: intlShape.isRequired,
  allListings: array.isRequired,
  currentListingId: string.isRequired,
};

export default CopyToClipboard;
