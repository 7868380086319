import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import css from './EditListingDevicePanel.css';
import { Button, ToggleSwitch } from '../../components';
import { uploadFiles } from '../../util/fileUpload';
import { get } from 'lodash';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_PUBLISHED } from '../../util/types';
const HELP_LINK = process.env.REACT_APP_SUBSCRIPTION_PLAN_HELP;

const EditListingDevicePanel = memo(
  props => {
    const findDeviceByKey = (deviceData, deviceKey) => {
      return deviceData[deviceKey] || null; // Return the object if found, otherwise return null
    };

    const findDeviceByType = (existingDeviceData, deviceType, deviceEditDisabled) => {
      let devicesData = {};

      Object.keys(existingDeviceData).forEach(deviceKey => {
        const device = existingDeviceData[deviceKey];
        console.log('Existing device ', existingDeviceData);

        // If the deviceType matches, add it to devicesData without overwriting properties
        if (device.deviceType === deviceType) {
          devicesData[deviceKey] = {
            ...device, // Copy all existing properties
            deviceEditDisabled: device.byod ? deviceEditDisabled : false, // Conditional deviceEditDisabled based on byod
            serial: deviceType === 'dlGo' ? device.imei : device.serial,
            imei: deviceType === 'dlGo' ? device.imei : device.imei || '', // Ensure imei is set for dlGo
            isVerified: device.isVerified !== undefined ? device.isVerified : false, // Set default to false if not present
          };
        }
      });

      console.log('Final devicesData', devicesData);
      return devicesData;
    };

    const findNextAvailableDeviceKey = devicesData => {
      let deviceIndex = 1;

      // Keep incrementing deviceIndex until an available key is found
      while (devicesData[`device${deviceIndex}`]) {
        deviceIndex++;
      }

      return `device${deviceIndex}`;
    };

    const {
      className,
      rootClassName,
      submitButtonId,
      onSubmit,
      currentUser,
      listing,
      updateInProgress,
      ready,
      intl,
    } = props;
    console.log('Getting the props', props);

    const existingDeviceData = get(listing, 'attributes.publicData.deviceManagement', {});
    const subscriptionPlan = get(listing, 'attributes.publicData.subscriptionPlan', 'just-mates');
    const subscriptionPlanAddOn = get(listing, 'attributes.publicData.subscriptionPlanAddOn', {});

    const live = get(listing, 'attributes.metadata.live', false);
    const listingState = listing.attributes.state;
    const isEligibleForFreeze =
      (listingState &&
        (listingState === LISTING_STATE_PENDING_APPROVAL ||
          listingState === LISTING_STATE_PUBLISHED) &&
        subscriptionPlan &&
        (subscriptionPlan === 'good-mates' || subscriptionPlan === 'best-mates')) ||
      false;
    const deviceEditDisabled = Object.keys(existingDeviceData).length > 0 && isEligibleForFreeze;
    const [errors, setErrors] = useState({});

    let devicesData = {};

    // Create new device object with the additional fields
    const createNewDevice = deviceType => {
      return {
        deviceType: deviceType,
        id: `${Date.now()}${deviceType}`, // Unique identifier
        byod: deviceType === 'lockbox', // Default value for BYOD
        serial: deviceType === 'dlGo' ? null : '', // Set serial to null for dlGo
        image: '', // Placeholder for image
        model: deviceType === 'dlGo' ? 'Otoplug' : '', // Model for Otoplug
        imei: '',
      };
    };

    if (subscriptionPlan === 'just-mates') {
      if (subscriptionPlanAddOn) {
        if (subscriptionPlanAddOn.byoSecondaryGps === true) {
          const existingPlanData = findDeviceByType(
            existingDeviceData,
            'secondaryGps',
            deviceEditDisabled
          );
          if (existingPlanData && Object.keys(existingPlanData).length > 0) {
            devicesData = {
              ...devicesData,
              ...existingPlanData,
            };
          } else {
            devicesData['secondaryGps'] = createNewDevice('secondaryGps');
          }
        }
      }
    }

    if (subscriptionPlan === 'good-mates') {
      const existingPrimaryPlanData = findDeviceByType(
        existingDeviceData,
        'primaryGps',
        deviceEditDisabled
      );
      if (existingPrimaryPlanData && Object.keys(existingPrimaryPlanData).length > 0) {
        devicesData = {
          ...devicesData,
          ...existingPrimaryPlanData,
        };
      } else {
        devicesData['primaryGps'] = createNewDevice('primaryGps');
      }
      if (subscriptionPlanAddOn) {
        if (subscriptionPlanAddOn.byoSecondaryGps === true) {
          const existingPlanData = findDeviceByType(
            existingDeviceData,
            'secondaryGps',
            deviceEditDisabled
          );
          if (existingPlanData && Object.keys(existingPlanData).length > 0) {
            devicesData = {
              ...devicesData,
              ...existingPlanData,
            };
          } else {
            devicesData['secondaryGps'] = createNewDevice('secondaryGps');
          }
        }

        if (subscriptionPlanAddOn.byoLockbox === true) {
          const existingPlanData = findDeviceByType(
            existingDeviceData,
            'lockbox',
            deviceEditDisabled
          );
          if (existingPlanData && Object.keys(existingPlanData).length > 0) {
            devicesData = {
              ...devicesData,
              ...existingPlanData,
            };
          } else {
            devicesData['lockbox'] = createNewDevice('lockbox');
          }
        }
      }
    }

    if (subscriptionPlan === 'best-mates') {
      const existingPlanGoData = findDeviceByType(existingDeviceData, 'dlGo', deviceEditDisabled);
      if (existingPlanGoData && Object.keys(existingPlanGoData).length > 0) {
        devicesData = {
          ...devicesData,
          ...existingPlanGoData,
        };
      } else {
        devicesData['dlGo'] = createNewDevice('dlGo');
      }
      if (subscriptionPlanAddOn) {
        if (subscriptionPlanAddOn.byoSecondaryGps === true) {
          const existingPlanData = findDeviceByType(
            existingDeviceData,
            'secondaryGps',
            deviceEditDisabled
          );
          if (existingPlanData && Object.keys(existingPlanData).length > 0) {
            devicesData = {
              ...devicesData,
              ...existingPlanData,
            };
          } else {
            devicesData['secondaryGps'] = createNewDevice('secondaryGps');
          }
        }
      }
    }

    console.log('Final Devices Data:', devicesData);

    // const initialDevices = Object.keys(existingDeviceData).length
    //   ? Object.keys(existingDeviceData).map((key, index) => ({
    //     ...existingDeviceData[key],
    //     id: Date.now() + index,
    //   }))
    //   : [{id: Date.now(), byod: false, deviceType: '', image: '', serial: ''}];

    const [deviceData, setDeviceData] = useState(devicesData);
    const inputRefs = useRef({});

    // const handleAddDevice = () => {
    //   if (deviceData.length < 4) {
    //     setDeviceData([
    //       ...deviceData,
    //       {id: Date.now(), byod: false, deviceType: '', image: '', serial: ''},
    //     ]);
    //   }
    // };

    // const handleRemoveDevice = (index) => {
    //   setDeviceData(deviceData.filter((_, i) => i !== index));
    // };

    const handleFieldChange = (deviceKey, field, value) => {
      // Create a shallow copy of deviceData
      const updatedDeviceData = { ...deviceData };
      // if (deviceEditDisabled) return; // Prevent editing if deviceEditDisabled is true

      // Check if device type is 'dlGo' and handle the imei/serial accordingly
      if (updatedDeviceData[deviceKey].deviceType === 'dlGo') {
        if (field === 'serial') {
          updatedDeviceData[deviceKey].imei = value; // Save the value in imei for dlGo
          updatedDeviceData[deviceKey].serial = value; // Set serial to null for dlGo
        } else {
          updatedDeviceData[deviceKey][field] = value;
        }
      } else {
        // For other device types, update the field normally
        updatedDeviceData[deviceKey][field] = value;
      }

      // Validate image upload
      if (!updatedDeviceData[deviceKey].image) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [deviceKey]: 'Please upload an image of the device.',
        }));
      } else {
        // Clear error if image is uploaded
        setErrors(prevErrors => ({
          ...prevErrors,
          [deviceKey]: '',
        }));
      }

      // Update the state with the new device data
      setDeviceData(updatedDeviceData);
    };

    const handleClick = (id, handleClickFlag) => {
      if (handleClickFlag) return;
      if (inputRefs.current[id]) {
        inputRefs.current[id].click();
      }
    };

    const changesFile = (index, deviceFileEditFlag) => e => {
      if (deviceFileEditFlag) return;
      const file = e.target.files[0];
      if (file) {
        const files = [{ file, index: 0 }];
        handleUploadFiles(files, currentUser).then(uploadedPhotos => {
          const uploadedPhoto = uploadedPhotos[0];
          if (uploadedPhoto) {
            handleFieldChange(index, 'image', uploadedPhoto.fileUrl);
          }
        });
      }
    };

    const handleUploadFiles = (files, currentUser) => {
      if (files.length) {
        return uploadOtherFiles(files, currentUser)
          .then(response => {
            const uploadedPhotos = response.map(item => ({
              fileUrl: item.url,
              timestamp: new Date().getSeconds(),
              isJustUploaded: true,
            }));
            return uploadedPhotos;
          })
          .catch(e => {
            console.error('Error uploading files:', e);
          });
      }
      return Promise.resolve([]);
    };

    const uploadOtherFiles = (files, currentUser) => {
      const promises = files.map(({ file, index }) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('userId', currentUser.id.uuid);
        formData.append('tripPhotos', true);

        return uploadFiles(formData)
          .then(response => response.json())
          .catch(error => console.error('File upload error:', error));
      });
      return Promise.all(promises);
    };

    const handleChangeImage = deviceKey => {
      handleFieldChange(deviceKey, 'image', '');
      if (inputRefs.current[deviceKey]) {
        inputRefs.current[deviceKey].click();
      }
    };

    const onChangeBYOD = deviceKey => {
      const deviceKeyData = findDeviceByKey(deviceData, deviceKey);
      const newState = !deviceKeyData.byod;
      handleFieldChange(deviceKey, 'byod', newState);
    };

    const isFormValid = Object.keys(deviceData).every(deviceKey => {
      const device = deviceData[deviceKey]; // Get the device object using the key

      if (device.byod) {
        return device.deviceType && device.serial && device.image;
      }
      return device.deviceType;
    });

    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={`${classes} ${css.listingWrapper}`}>
        <div className={css.titleSection}>
          <h1 className={css.title}>Device management</h1>
          <p>
            Add details of the device, if any already installed on your car. If none, then continue
            to next step.
          </p>
        </div>
        {Object.keys(deviceData).map((deviceKey, index) => (
          <div
            key={deviceKey}
            className={css.deviceSection}
            disabled={deviceData[deviceKey] && (deviceData[deviceKey].deviceEditDisabled || false)}
          >
            <h3 className={css.deviceHeading}>Device {index + 1}</h3>
            <div className={css.displayFlex}>
              <div className={classNames(css.column, css.column6)}>
                <div className={classNames(css.building, css.newInput)}>
                  <label>Device type</label>
                  <select
                    id={`device-type-${deviceKey}`}
                    onChange={e => handleFieldChange(deviceKey, 'deviceType', e.target.value)}
                    required
                    disabled={true}
                    value={deviceData[deviceKey].deviceType || ''}
                  >
                    <option value="" disabled>
                      Select your device type
                    </option>
                    <option value="primaryGps">Primary GPS</option>
                    <option value="secondaryGps">Secondary GPS</option>
                    <option value="dlGo">Drive mate Go / Otoplug</option>
                    <option value="lockbox">Lockbox</option>
                  </select>
                </div>
              </div>
              {deviceData[deviceKey].deviceType !== 'lockbox' && (
                <div
                  className={classNames(css.column, css.column6)}
                  disabled={deviceData[deviceKey].deviceEditDisabled || false}
                >
                  <div
                    className={classNames(css.isBYODToggle)}
                    disabled={deviceData[deviceKey].deviceEditDisabled || false}
                  >
                    <label>
                      {deviceData[deviceKey].deviceType === 'dlGo'
                        ? 'Bringing your own Otoplug device?'
                        : 'Bringing your own device?'}
                    </label>
                    <ToggleSwitch
                      id={`isDevice-${deviceKey}-BYOD`}
                      onChange={() => onChangeBYOD(deviceKey)}
                      status={deviceData[deviceKey].byod || false}
                      disabled={deviceData[deviceKey].deviceEditDisabled || false} // Disable toggle for Lockbox
                    />
                  </div>
                  <div className={classNames(css.isBYODToggle)}>
                    <p>
                      Toggle this on if you're bringing your own device. Leave it off if Drive mate
                      is to provide the device.
                    </p>
                  </div>
                </div>
              )}
            </div>

            {deviceData[deviceKey].byod && (
              <div
                className={css.imgUploadWrapper}
                disabled={deviceData[deviceKey].deviceEditDisabled || false}
              >
                <div className={classNames(css.column, css.column6)}>
                  <div className={classNames(css.building, css.newInput)}>
                    <label>
                      {deviceData[deviceKey].deviceType === 'dlGo'
                        ? 'Please add IMEI and photo of the device.'
                        : 'Please add serial number and photo of the device.'}
                    </label>
                    <input
                      id={`serial-number-${deviceKey}`}
                      placeholder={
                        deviceData[deviceKey].deviceType === 'dlGo' ? 'IMEI' : 'Serial number'
                      }
                      onChange={e => handleFieldChange(deviceKey, 'serial', e.target.value)}
                      value={deviceData[deviceKey].serial || ''}
                      required={deviceData[deviceKey].byod}
                      disabled={deviceData[deviceKey].deviceEditDisabled || false}
                    />
                  </div>
                </div>
                <div
                  className={classNames(css.column, css.column6)}
                  disabled={deviceData[deviceKey].deviceEditDisabled || false}
                >
                  <p className={css.UploadTitle}>Upload an image of the device</p>
                  {deviceData[deviceKey].image ? (
                    <div className={css.imageWrapper}>
                      <img
                        src={deviceData[deviceKey].image}
                        alt="Uploaded"
                        className={css.uploadButton}
                      />
                      <span
                        className={css.chooseImageText}
                        onClick={() =>
                          !(deviceData[deviceKey].deviceEditDisabled || false) &&
                          handleChangeImage(deviceKey)
                        }
                      >
                        <span className={css.chooseImage}>Change image</span>
                      </span>
                    </div>
                  ) : (
                    <>
                      <Button
                        type="button"
                        className={css.uploadButton}
                        onClick={() =>
                          handleClick(deviceKey, deviceData[deviceKey].deviceEditDisabled || false)
                        }
                        disabled={deviceData[deviceKey].deviceEditDisabled || false}
                      >
                        <span className={css.uploadText}>Click to upload</span>
                      </Button>
                      <input
                        className={css.uploadInput}
                        type="file"
                        id={`file-upload-${deviceKey}`}
                        onChange={changesFile(
                          deviceKey,
                          deviceData[deviceKey].deviceEditDisabled || false
                        )}
                        ref={el => (inputRefs.current[deviceKey] = el)}
                        disabled={deviceData[deviceKey].deviceEditDisabled || false}
                      />
                    </>
                  )}
                  {errors[deviceKey] ? <p className={css.errorText}>{errors[deviceKey]}</p> : ''}
                </div>
              </div>
            )}

            {/* {deviceData.length > 1 && (
              <button className={`${css.addAnotherDeviceBtn} ${css.mb15}`}
                      onClick={() => handleRemoveDevice(deviceKey)}>
                Remove this device
              </button>
            )} */}
          </div>
        ))}
        {/* {deviceData.length < 4 && (
          <button className={`${css.addAnotherDeviceBtn} ${css.mb15}`} onClick={handleAddDevice}>
            Add another device
          </button>
        )} */}
        <div className={classNames(css.column, css.column12, css.infoSection)}>
          {Object.keys(deviceData).length === 0 && (
            <div
              className={css.learnMore}
              style={{
                marginBottom: '10px',
              }}
            >
              {intl.formatMessage({
                id: 'EditListingDevicePanel.noTabs',
              })}
            </div>
          )}
          <div className={css.learnMore}>
            {intl.formatMessage({
              id: 'EditListingDevicePanel.learnMore',
            })}{' '}
            <a href={HELP_LINK} target="_blank">
              {intl.formatMessage({
                id: 'EditListingDevicePanel.learnMoreLink',
              })}
            </a>
          </div>
        </div>
        <div className={css.stickyButtons}>
          <div className={css.stickButtonsContainer}>
            <div className={css.stickButtonsDescription}></div>
            <div className={css.stickButton}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={updateInProgress}
                ready={ready}
                onClick={() => {
                  // Merge existingDeviceData with updated deviceData
                  const updatedDeviceManagement = Object.keys(existingDeviceData).reduce(
                    (acc, key) => {
                      // If the key exists in the updated deviceData, use the updated value
                      if (deviceData[key]) {
                        acc[key] = { ...existingDeviceData[key], ...deviceData[key] };
                      } else {
                        // Otherwise, keep the original value from existingDeviceData
                        acc[key] = existingDeviceData[key];
                      }
                      return acc;
                    },
                    {}
                  );

                  // Add any new keys from deviceData that are not present in existingDeviceData
                  Object.keys(deviceData).forEach(key => {
                    if (!updatedDeviceManagement[key]) {
                      updatedDeviceManagement[key] = deviceData[key];
                    }
                  });

                  // Remove 'deviceEditDisabled' field from each device object
                  const sanitizedDeviceManagement = Object.keys(updatedDeviceManagement).reduce(
                    (acc, key) => {
                      const { deviceEditDisabled, ...rest } = updatedDeviceManagement[key];
                      acc[key] = rest;
                      return acc;
                    },
                    {}
                  );

                  console.log(
                    'Update Existing Device Management',
                    updatedDeviceManagement,
                    sanitizedDeviceManagement
                  );

                  const updatedValues = {
                    publicData: {
                      deviceManagement: sanitizedDeviceManagement,
                      // lockbox: Object.values(deviceData).some(({deviceType}) => deviceType === "lockbox")
                    },
                  };
                  onSubmit(updatedValues);
                }}
                id={submitButtonId}
                disabled={!isFormValid}
              >
                {live ? 'Save' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

EditListingDevicePanel.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  submitButtonId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const EditListingDevicePanelModified = compose(injectIntl)(EditListingDevicePanel);

export default EditListingDevicePanelModified;
