exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ResponsiveImageExample_root__3uR9c {\n  display: block;\n  position: relative;\n  width: 200px;\n  margin-bottom: 70px;\n}\n\n/* Firefox doesn't support image aspect ratio inside flexbox */\n\n.ResponsiveImageExample_aspectWrapper__3jPV1 {\n  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */\n}\n\n.ResponsiveImageExample_rootForImageWithAspectRatio__ckohn {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n}\n\n.ResponsiveImageExample_rootForSizes__7kPmR {\n  display: block;\n  position: relative;\n  width: 200px;\n  margin-bottom: 70px\n}\n\n@media (min-width: 601px) {\n\n.ResponsiveImageExample_rootForSizes__7kPmR {\n    width: 400px\n}\n  }\n", ""]);

// Exports
exports.locals = {
	"root": "ResponsiveImageExample_root__3uR9c",
	"aspectWrapper": "ResponsiveImageExample_aspectWrapper__3jPV1",
	"rootForImageWithAspectRatio": "ResponsiveImageExample_rootForImageWithAspectRatio__ckohn",
	"rootForSizes": "ResponsiveImageExample_rootForSizes__7kPmR"
};