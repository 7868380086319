import React, { memo } from 'react';
import css from './HostPromoLandingPage.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

export const Channels = () => (
  <div className={css.channels}>
    <div className={css.channelsRow}>
      <a
        href="https://www.straitstimes.com/singapore/transport/car-not-used-much-rent-it-out-for-cash"
        className={css.channel}
        target="_blank"
      >
        <LazyImage
          containerHeight={'fit-content'}
          className={css.channelImage}
          src={imageSource.seoLandingPage.theStraitsTime}
          alt="THE STRAITS TIMES"
        />
      </a>
      <a
        href="https://www.facebook.com/drivemate20/videos/drive-lah-featured-on-channel-news-asia/991990264536564/"
        target="_blank"
        className={css.channel}
      >
        <LazyImage containerHeight={'fit-content'} className={css.channelImage} src={imageSource.seoLandingPage.cna} alt="CNA" />
      </a>
      <a
        href="https://vulcanpost.com/679151/p2p-car-sharing-drive-lah-singapore/"
        target="_blank"
        className={css.channel}
      >
        <LazyImage containerHeight={'fit-content'}
          className={css.channelImage}
          src={imageSource.seoLandingPage.vulcanPost}
          alt="Vulcan Post"
        />
      </a>
      <a
        href="https://www.zaobao.com.sg/news/singapore/story20191116-1005643"
        className={css.channel}
        target="_blank"
      >
        <LazyImage containerHeight={'fit-content'} className={css.channelImage} src={imageSource.seoLandingPage.zaobao} alt="ZAOBAO" />
      </a>
    </div>
  </div>
);

const SectionRealBenefits = memo(() => {
  return (
    <div className={css.sectionRealBenefits}>
      <Channels />
    </div>
  );
});

export default SectionRealBenefits;
