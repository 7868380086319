import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  txIsBeingDropOff,
  txIWaitingRequestDropoff,
} from '../../util/transaction';
import {
  TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END,
  TRANSITION_LTL_CONFIRM_DROP_OFF,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_TRIP_END,
  txIsBeingDropOffLTL,
  txIWaitingRequestDropoffLTL,
} from '../../util/transactionLongTermLast';
import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { PrimaryButton, SecondaryButton } from '../../components';
import { TRIP_PRIMARY_BUTTON_TRANSIT_NAME } from '../../util/gtm/gtmConstants';
import css from './TransactionPanel.css';
import { displayDateForUser } from '../../util/dates';
import { getTransitButtonLabelId, getTransitErrorLabelId } from './transitionHelper';
import { txLastTransition } from '../../util/transactionLongTermFirst';

export const dropoffTransitions = [
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_CONFIRM_DROP_OFF,
];
export const LONG_TERM_STATUS_FINISH = 'FINISH';

export const checkDropOffTimeAfterPickUpOneHour = transaction => {
  const transitions = get(transaction, 'attributes.transitions', []);
  const pickUpTransition = transitions.find(transition =>
    [
      TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
      TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
      TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
    ].includes(transition.transition)
  );

  const createdAt = pickUpTransition.createdAt;

  let dateShowDropOff = new Date(createdAt);
  dateShowDropOff = dateShowDropOff.setMinutes(createdAt.getMinutes() + 60);
  const timeToShowDropOff = new Date(dateShowDropOff);

  const isTimeToShowTransitionDropoff = new Date() > timeToShowDropOff;
  return true;
};

// Functional component as a helper to build ActionButtons for
// provider/customer

export const checkTimeToShowDropOff = transaction => {
  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking');
  const displayEnd = get(transaction, 'booking.attributes.displayEnd');

  const dislayEndDateForUser = new Date(displayDateForUser(displayEnd));

  // const dateShowDropOff = dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 60);
  const dateShowDropOff = true;
  // const dateShowDropOff = isHourlyBooking ?
  //   dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 15) :
  //   dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 30)
  const timeToShowDropOff = new Date(dateShowDropOff);
  const isTimeToShowTransitionDropoff = new Date() >= timeToShowDropOff;
  return true;
  return isTimeToShowTransitionDropoff;
};

const getTransitions = transaction => {
  const lastTransition = txLastTransition(transaction);
  switch (lastTransition) {
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_REQUEST_DROP_OFF],
      };
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_REQUEST_DROP_OFF],
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU],
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU],
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU],
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU],
      };
    case TRANSITION_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE],
      };
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE],
      };
    case TRANSITION_LTL_TRIP_END:
    case TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_LTL_REQUEST_DROP_OFF],
      };
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_LTL_CONFIRM_DROP_OFF],
      };
    default:
      return {};
  }
};

const transitionRequiredUploadPhotos = [
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
];

export const DropOffButtonMaybe = props => {
  const {
    className,
    rootClassName,
    canShowButtons,
    transaction: rawTransaction,
    transitInProgress,
    transitError,
    onTransit,
    transitionNames,
    ownListing,
    onPushEventGTM,
    inputOdometerInvalid,
    inputNumberOdometer,
    isLongTerm,
    currentUserRole,
    currentUser,
  } = props;

  const transaction = isLongTerm
    ? rawTransaction && rawTransaction.currentChildTransaction
    : rawTransaction;

  const transitionsMaybe = get(getTransitions(transaction), currentUserRole, []);
  if (transitionsMaybe && transitionsMaybe.length === 0) return null;
  const longTermStatus = get(rawTransaction, 'attributes.metadata.longTermStatus');
  if (!transaction || (longTermStatus !== LONG_TERM_STATUS_FINISH && isLongTerm)) return null;

  const validTransaction = transaction && transaction.customer;
  const allowUserTransit =
    validTransaction &&
    (transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
      transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
        VERIFICATION_STATUS_VERIFIED);

  const guestIdentityIsNotVerify = !allowUserTransit ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const transitErrorMessage = transitError ? (
    <p className={css.actionError}>
      <FormattedMessage id={getTransitErrorLabelId(transitionsMaybe[0])} />
    </p>
  ) : null;

  // const isDropoff = transitionNames.includes(TRANSITION_REQUEST_DROP_OFF);
  // const isNotTimeToShowTransitionDropoff =
  //   !isDropoff ? false : timeToShowDropOff > new Date();

  const isShowDropoffBasOnDropOffTime = checkTimeToShowDropOff(transaction);

  let isTimeToShowTransitionDropoff;
  if (transitionsMaybe == TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU) {
    if (isShowDropoffBasOnDropOffTime) {
      isTimeToShowTransitionDropoff = true;
    } else {
      isTimeToShowTransitionDropoff = false;
    }
  } else {
    const isShowDropOffBaseOnPickUpTime = checkDropOffTimeAfterPickUpOneHour(transaction);
    isTimeToShowTransitionDropoff = isShowDropoffBasOnDropOffTime || isShowDropOffBaseOnPickUpTime;
    // isTimeToShowTransitionDropoff = true;
  }
  // const isTimeToShowTransitionDropoff =
  //   isShowDropoffBasOnDropOffTime || isShowDropOffBaseOnPickUpTime;

  const classes = classNames(rootClassName || css.actionButtons, className);

  const providerTripPhoto = txIsBeingDropOff(transaction);
  const allowTripPhotos =
    txIWaitingRequestDropoff(transaction) || txIWaitingRequestDropoffLTL(transaction);
  const isDrivelahGo =
    transaction.listing &&
    transaction.listing.attributes.metadata &&
    transaction.listing.attributes.metadata.isDrivelahGo;
  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;
  const emptyPhoto = allowTripPhotos && props.tripPhotos && props.tripPhotos.length === 0;

  const handlePrimaryButtonClick = (transitAllow, transaction, ownListing, transition) => {
    console.log('DROP OFFF BUTTON TRANSIT');
    let isStopCar = false;
    const isShuCar =
      transaction.listing &&
      transaction.listing.attributes.publicData &&
      transaction.listing.attributes.metadata.isDrivelahGo;
    if (
      TRANSITION_CONFIRM_DROP_OFF == transition &&
      TRANSITION_REQUEST_DROP_OFF_DLGO == transition &&
      isShuCar &&
      isHost
    ) {
      isStopCar = true;
    }
    if (inputOdometerInvalid) return;
    const dataObject = { dropOffOdometer: inputNumberOdometer, currentTransaction: transaction };
    onPushEventGTM(transaction, transition);
    if (transitAllow) {
      if (isStopCar) {
        onTransit(
          transaction.id,
          transition,
          dataObject,
          transitionRequiredUploadPhotos.includes(transition) || providerTripPhoto
            ? [...props.tripPhotos, ...props.allTripPhotos]
            : null,
          null,
          null,
          null,
          null,
          null,
          isStopCar
        );
      } else {
        onTransit(
          transaction.id,
          transition,
          dataObject,
          transitionRequiredUploadPhotos.includes(transition) || providerTripPhoto
            ? [...props.tripPhotos, ...props.allTripPhotos]
            : null,
          null
        );
      }
    }
  };

  const renderButton = transition => {
    switch (transition) {
      case TRANSITION_DISPUTE:
        return (
          <SecondaryButton
            className={css.secondButton}
            inProgress={transitInProgress}
            disabled={transitInProgress || !allowUserTransit || inputOdometerInvalid}
            onClick={() => {
              props.onOpenDisputeModal();
            }}
          >
            <FormattedMessage id="TransactionPanel.dispute" />
          </SecondaryButton>
        );
      case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      case TRANSITION_CONFIRM_DROP_OFF:
      case TRANSITION_REQUEST_DROP_OFF_DLGO:
      case TRANSITION_REQUEST_DROP_OFF:
      case TRANSITION_LTL_REQUEST_DROP_OFF:
      case TRANSITION_LTL_CONFIRM_DROP_OFF:
        // const needUploadedPhotos =
        //   transitionRequiredUploadPhotos.includes(transition) &&
        //   props.tripPhotos &&
        //   props.tripPhotos.length === 0;

        const needUploadedPhotos = false;

        return isTimeToShowTransitionDropoff ? (
          <PrimaryButton
            id={'actionTransitButton'}
            inProgress={transitInProgress}
            disabled={
              transitInProgress || !allowUserTransit || needUploadedPhotos || inputOdometerInvalid
            }
            name={TRIP_PRIMARY_BUTTON_TRANSIT_NAME}
            onClick={() => {
              handlePrimaryButtonClick(transaction, transaction, ownListing, transition);
            }}
          >
            <FormattedMessage id={getTransitButtonLabelId(transition)} />
          </PrimaryButton>
        ) : null;
      default:
        return null;
    }
  };
  return (
    <div className={classes}>
      <div className={css.actionErrors}>
        {guestIdentityIsNotVerify}
        {transitErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {transitionsMaybe.reverse().map(t => renderButton(t))}
      </div>
      <div className={css.noCarStatusPhotoUpload}>
        <div
          onClick={() => {
            props.handleOpenStatusModal('openCarStatusModal');
          }}
        >
          {(txIWaitingRequestDropoff(transaction) || txIWaitingRequestDropoffLTL(transaction)) && (
            <FormattedMessage id="TransactionPanel.noCarPickupStatusPhotoUpload" />
          )}
          {(txIsBeingDropOff(transaction) || txIsBeingDropOffLTL(transaction)) && (
            <FormattedMessage id="TransactionPanel.noCarPickupStatusPhotoUpload2" />
          )}
        </div>
      </div>
    </div>
  );
};
