import React, { Component } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { pushGTMHostVerify } from '../../util/gtm/gtmHelpers';
import {
  EVENT_HOST_VERIFY_STRIPE_FAILURE,
  EVENT_HOST_VERIFY_STRIPE_SUCCESS,
} from '../../util/gtm/gtmConstants';
import { HelpLineNumber, NamedRedirect, Tabs } from '../../components';
import { get } from 'lodash';

import EditListingWizardTab, {
  AVAILABILITY,
  DESCRIPTION,
  FEATURES,
  LOCATION,
  NEXT_LEVEL,
  ONBOARDING_CALL,
  PAYOUTS,
  PHOTOS,
  POLICY,
  PRICING,
  SUBSCRIPTION,
  DEVICE,
  INSURANCE,
  TRACKING_DEVICE,
  VERIFICATION,
  INSTRUCTIONS,
} from './EditListingWizardTab';
import css from './EditListingWizard.css';

// All panels can be reordered.
export const TABS = [
  LOCATION,
  DESCRIPTION,
  FEATURES,
  POLICY,
  INSTRUCTIONS,
  PRICING,
  PHOTOS,
  AVAILABILITY,
  // ONBOARDING_CALL,
  SUBSCRIPTION,
  DEVICE,
  INSURANCE,
  NEXT_LEVEL,
  PAYOUTS,
];
export const VERIFIED_TABS = [
  LOCATION,
  DESCRIPTION,
  FEATURES,
  POLICY,
  INSTRUCTIONS,
  PRICING,
  PHOTOS,
  // ONBOARDING_CALL,
  AVAILABILITY,
  SUBSCRIPTION,
  DEVICE,
  INSURANCE,
  NEXT_LEVEL,
];
export const NEED_VERIFY_TABS = [
  LOCATION,
  DESCRIPTION,
  FEATURES,
  POLICY,
  INSTRUCTIONS,
  PRICING,
  PHOTOS,
  AVAILABILITY,
  // ONBOARDING_CALL,
  SUBSCRIPTION,
  DEVICE,
  INSURANCE,
  NEXT_LEVEL,
  VERIFICATION,
  PAYOUTS,
];

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;
const MAX_MOBILE_WIDTH = 768;
const TOPBAR_HEIGHT_DESKTOP = 72;
const TOPBAR_HEIGHT_TABLET = 120;

const tabLabel = (intl, tab) => {
  let key = null;
  if (tab === DESCRIPTION) {
    key = 'EditListingWizard.tabLabelDescription';
  } else if (tab === FEATURES) {
    key = 'EditListingWizard.tabLabelFeatures';
  } else if (tab === POLICY) {
    key = 'EditListingWizard.tabLabelPolicy';
  } else if (tab === LOCATION) {
    key = 'EditListingWizard.tabLabelLocation';
  } else if (tab === PRICING) {
    key = 'EditListingWizard.tabLabelPricing';
  } else if (tab === AVAILABILITY) {
    key = 'EditListingWizard.tabLabelAvailability';
  } else if (tab === PHOTOS) {
    key = 'EditListingWizard.tabLabelPhotos';
  } else if (tab === INSURANCE) {
    key = 'EditListingWizard.tabLabelInsurance';
  } else if (tab === ONBOARDING_CALL) {
    key = 'EditListingWizard.tabLabelOnboardingCall';
  } else if (tab === VERIFICATION) {
    key = 'EditListingWizard.tabLabelVerification';
  } else if (tab === NEXT_LEVEL) {
    key = 'EditListingWizard.tabLabelNextLevel';
  } else if (tab === TRACKING_DEVICE) {
    key = 'EditListingWizard.tabLabelTrackingDevice';
  } else if (tab === SUBSCRIPTION) {
    key = 'EditListingWizard.tabLabelSubscription';
  } else if (tab === DEVICE) {
    key = 'EditListingWizard.tabLabelDevice';
  } else if (tab === PAYOUTS) {
    key = 'EditListingWizard.payouts';
  } else if (tab === INSTRUCTIONS) {
    key = 'EditListingWizard.instructions';
  }
  return intl.formatMessage({ id: key });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing) => {
  const {
    availabilityPlan,
    description,
    geolocation,
    price,
    title,
    publicData,
    privateData,
  } = listing.attributes;
  const images = listing.images;
  switch (tab) {
    case DESCRIPTION:
      return !!title;
    case FEATURES:
      return !!(publicData && publicData.brandName && publicData.modelName);
    case POLICY:
      return !!(publicData && publicData.peopleNumber);
    case LOCATION:
      return !!(geolocation && publicData && publicData.location && publicData.location.address);
    case PRICING:
      return !!(
        publicData &&
        publicData.pickupDropoffInstructions &&
        publicData.parkingInstructions &&
        publicData.carGuide
      );
    case AVAILABILITY:
      return !!(privateData && privateData.imagesArray && !!title);
    case SUBSCRIPTION:
      return !!availabilityPlan;
    case DEVICE:
      return !!(publicData && publicData.subscriptionPlan);
    case PHOTOS:
      return !!price;
    case INSURANCE:
      return !!(publicData && publicData.subscriptionPlan);
    case NEXT_LEVEL:
      return !!(
        publicData &&
        publicData.location &&
        publicData.location.address &&
        publicData.subscriptionPlan &&
        publicData.insurance
      );
    case VERIFICATION:
      return !!(publicData && publicData.subscriptionPlan);
    case INSTRUCTIONS:
      return !!(publicData && publicData.peopleNumber && publicData.keyRules);
    case TRACKING_DEVICE:
      return true;
    case PAYOUTS:
      return !!(publicData && title);
    default:
      return false;
  }
};

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path =
    pathParams && pathParams.tab === VERIFICATION
      ? createResourceLocatorString(
          'EditListingStripeOnboardingPage',
          routes,
          { ...pathParams, returnURLType, tab: NEXT_LEVEL },
          { shouldSubmit: true }
        )
      : createResourceLocatorString(
          'EditListingStripeOnboardingPage',
          routes,
          { ...pathParams, returnURLType, tab: NEXT_LEVEL },
          { shouldSubmit: true }
        );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
      mobileToggle: false,
      showDeviceTab: false,
      filteredTabs: props.tabs,
      hideDeviceTab: false,
    };
    this.didLocationHelpLineNumber = false;
    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
    // this.handlePayoutSubmit = this.handlePayoutSubmit.bind(this);
    this.tabsActive = this.tabsActive.bind(this);
    this.handlePositionHelpLineNumber = this.handlePositionHelpLineNumber.bind(this);
    this.mobileMenuToggle = React.createRef();
  }

  // Function to update state
  updateShowDeviceTab = () => {
    this.setState({
      showDeviceTab: !this.state.showDeviceTab,
    });
  };

  componentDidMount() {
    document.addEventListener('scroll', this.handlePositionHelpLineNumber);
    document.addEventListener('click', this.handleOutsideClick);
    const { stripeOnboardingReturnURL } = this.props;
    if (stripeOnboardingReturnURL != null) {
      if (stripeOnboardingReturnURL === 'failure') {
        pushGTMHostVerify({
          props: this.props,
          event: EVENT_HOST_VERIFY_STRIPE_FAILURE,
        });
      }
      this.setState({ showPayoutDetails: true });
    }
    this.updateFilteredTabs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listing !== this.props.listing) {
      this.updateFilteredTabs();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handlePositionHelpLineNumber);
    document.removeEventListener('click', this.handleOutsideClick);
  }

  updateFilteredTabs = () => {
    const { listing, tabs } = this.props;
    const currentListing = ensureListing(listing);
    const subscriptionPlan = get(currentListing, 'attributes.publicData.subscriptionPlan', null);
    const byoLockbox = get(
      currentListing,
      'attributes.publicData.subscriptionPlanAddOn.byoLockbox',
      null
    );
    const byoSecondaryGps = get(
      currentListing,
      'attributes.publicData.subscriptionPlanAddOn.byoSecondaryGps',
      null
    );

    if (
      subscriptionPlan &&
      subscriptionPlan === 'just-mates' &&
      byoLockbox === false &&
      byoSecondaryGps === false
    ) {
      console.log('in plan');
      this.setState({
        filteredTabs: tabs,
        hideDeviceTab: true,
      });
    } else {
      this.setState({ filteredTabs: tabs });
    }
  };

  isTabCompleted = (tab, listing, currentUser = {}) => {
    if (!listing || !tab) {
      return false;
    }

    const getUserData = user => {
      return (user && user.attributes && user.attributes.profile) || {};
    };

    const { stripeAccount } = currentUser || {};
    const userProfile = getUserData(currentUser);
    const { protectedData: userProtectedData } = userProfile || {};
    const { id: { uuid: stripeUuid } = {} } = stripeAccount || {};
    const { residencyNumber, postalCode } = userProtectedData || {};

    const { images = [] } = this.props;
    const { attributes = {} } = listing || {};
    const { publicData = {}, price = {}, title = '', description = '', availabilityPlan = {} } =
      attributes || {};
    const {
      location = {},
      transmissions = [],
      brandName = '',
      modelName = '',
      license_plate_number = '',
      drivenKm = '',
      nearestStop = '',
      peopleNumber = '',
      fuelType = '',
      millagePerDay = '',
      rules = '',
      longTermRental = '',
      longTermPrice = {},
      isPublicHolidayPeakDays = '',
      pricing = {},
      isThreeBookingDiscount = false,
      threeBookingDiscount = '',
      insurance = '',
      paddingHours = undefined,
      parkingInfo = '',
      registeredState = '',
      yearOfManufacture = '',
      deviceManagement,
      pickupDropoffInstructions = '',
      parkingInstructions = '',
      carGuide = '',
    } = publicData || {};

    const isThreeBookDiscFieldAvailable = (publicData || {}).hasOwnProperty(
      'isThreeBookingDiscount'
    );
    const isInstantBookFieldAvailable = (publicData || {}).hasOwnProperty('instantBooking');
    const isDeliveryFieldAvailable = (publicData || {}).hasOwnProperty('delivery');

    switch (tab) {
      case LOCATION: {
        const { address, postalCode } = location || {};
        if (location && address && postalCode) {
          return true;
        }
        return false;
      }

      case DESCRIPTION: {
        if (
          title &&
          description &&
          brandName &&
          modelName &&
          license_plate_number &&
          parkingInfo &&
          registeredState &&
          yearOfManufacture
        ) {
          return true;
        }
        return false;
      }

      case FEATURES: {
        if (peopleNumber && fuelType && transmissions && transmissions.length) {
          return true;
        }
        return false;
      }

      case POLICY: {
        if (rules && millagePerDay) {
          return true;
        }
        return false;
      }

      case INSTRUCTIONS: {
        if (pickupDropoffInstructions && parkingInstructions && carGuide) {
          return true;
        }
        return false;
      }

      case PRICING: {
        const { amount, currency } = price || {};
        const { peakPrice } = pricing || {};
        if (amount && currency) {
          if (
            longTermRental &&
            !(longTermPrice && !isNaN(longTermPrice.amount) && longTermPrice.currency)
          ) {
            return false;
          }

          if (
            isPublicHolidayPeakDays &&
            !(peakPrice && !isNaN(peakPrice.amount) && peakPrice.currency)
          ) {
            return false;
          }

          return true;
        } else {
          return false;
        }
      }

      // case PROMOTIONS: {
      //   if (isThreeBookDiscFieldAvailable){
      //     if(isThreeBookingDiscount && isNaN(threeBookingDiscount)){
      //       return false;
      //     }
      //     return true
      //   }
      //   return false;
      // }

      case PHOTOS: {
        if (images && (images.length || 0) >= 4) {
          return true;
        }
        return false;
      }

      case INSURANCE: {
        if (insurance) {
          return true;
        }
        return false;
      }

      case AVAILABILITY: {
        if (!isNaN(paddingHours) && availabilityPlan && availabilityPlan.entries) {
          return true;
        }
        return false;
      }

      case NEXT_LEVEL: {
        if (
          (isDeliveryFieldAvailable && publicData && publicData.subscriptionPlan && insurance) ||
          (isInstantBookFieldAvailable && publicData && publicData.subscriptionPlan && insurance)
        ) {
          return true;
        }
        return false;
      }

      case PAYOUTS: {
        if (stripeUuid) {
          return true;
        }
        return false;
      }

      case SUBSCRIPTION: {
        if (publicData && publicData.subscriptionPlan) return true;
      }

      case DEVICE: {
        if (publicData && publicData.deviceManagement) return true;
      }

      case TRACKING_DEVICE:
      default:
        return false;
    }
  };

  handlePositionHelpLineNumber() {
    const { viewport } = this.props;

    const isMobileLayout = viewport.width < MAX_MOBILE_WIDTH;
    const isDesktopLayout = viewport.width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const isSmallTabletLayout =
      viewport.width >= MAX_MOBILE_WIDTH && viewport.width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    if (!isMobileLayout) {
      if (
        ((window.scrollY > TOPBAR_HEIGHT_DESKTOP && isDesktopLayout) ||
          (window.scrollY > TOPBAR_HEIGHT_TABLET && isSmallTabletLayout)) &&
        !this.didLocationHelpLineNumber
      ) {
        this.didLocationHelpLineNumber = true;
        document.querySelector('#helpLineNumberBox').style.position = 'fixed';
        document.querySelector('#helpLineNumberBox').style.top = '5px';
      }
      if (window.scrollY <= TOPBAR_HEIGHT_DESKTOP && this.didLocationHelpLineNumber) {
        this.didLocationHelpLineNumber = false;
        document.querySelector('#helpLineNumberBox').style.position = '';
        document.querySelector('#helpLineNumberBox').style.top = '';
      }
    }
  }

  /**
   * Check which wizard tabs are active and which are not yet available. Tab is active if previous
   * tab is completed. In edit mode all tabs are active.
   *
   * @param isNew flag if a new listing is being created or an old one being edited
   * @param listing data to be checked
   *
   * @return object containing activity / editability of different tabs of this wizard
   */
  tabsActive(isNew, listing, tabs) {
    return tabs.reduce((acc, tab) => {
      let previousTabIndex;
      if (tab == 'location') {
        previousTabIndex = -1;
      } else {
        previousTabIndex = tabs.findIndex(t => t === tab);
      }
      const isActive =
        previousTabIndex >= 0 ? !isNew || tabCompleted(tabs[previousTabIndex], listing) : true;
      return { ...acc, [tab]: isActive };
    }, {});
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  handlePublishListing(id) {
    const { onPublishListingDraft, currentUser, stripeAccount } = this.props;

    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));
    // if (stripeConnected && !requirementsMissing) {
    if (stripeConnected) {
      onPublishListingDraft(id);
    } else {
      this.setState({
        draftId: id,
        showPayoutDetails: true,
      });
    }
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  handleOutsideClick = e => {
    const { mobileToggle } = this.state;
    if (
      this.mobileMenuToggle &&
      this.mobileMenuToggle.current &&
      !this.mobileMenuToggle.current.contains(e.target)
    ) {
      this.setState({
        mobileToggle: false,
      });
    } else {
      this.setState({
        mobileToggle: !mobileToggle,
      });
    }
  };

  handleOutsideClick = e => {
    const { mobileToggle } = this.state;
    if (
      this.mobileMenuToggle &&
      this.mobileMenuToggle.current &&
      !this.mobileMenuToggle.current.contains(e.target)
    ) {
      this.setState({
        mobileToggle: false,
      });
    } else {
      this.setState({
        mobileToggle: !mobileToggle,
      });
    }
  };

  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      onManageDisableScrolling,
      onPayoutDetailsFormChange,
      tabs,
      onGetStripeConnectAccountLink,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      currentUser,
      onFetchTimeSlots,
      timeSlots,
      ...rest
    } = this.props;
    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);
    const tabsStatus = this.tabsActive(isNewListingFlow, currentListing, tabs);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = tabs.indexOf(selectedTab);
      const nearestActiveTab = tabs
        .slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);

      return <NamedRedirect name="EditListingPage" params={{ ...params, tab: nearestActiveTab }} />;
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = tab => {
      return { name: 'EditListingPage', params: { ...params, tab } };
    };

    const formDisabled = getAccountLinkInProgress;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const { returnURLType, ...pathParams } = params;
    const successURL = createReturnURL('success', rootURL, routes, pathParams);
    const failureURL = createReturnURL('failure', rootURL, routes, pathParams);

    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

    const returnedNormallyFromStripe = returnURLType === 'success';
    const showVerificationError = false; // returnURLType === 'failure';
    const showVerificationNeeded = false; // stripeConnected && requirementsMissing;

    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
      pushGTMHostVerify({
        props: this.props,
        event: EVENT_HOST_VERIFY_STRIPE_SUCCESS,
      });
      return (
        <NamedRedirect
          name="EditListingPage"
          params={pathParams}
          search={this.props.history ? this.props.history.location.search : ''}
        />
      );
    }

    const navClass = classNames(css.nav, {
      [css.navOpen]: this.state.mobileToggle,
    });
    return (
      <div className={classes} id={id}>
        <HelpLineNumber className={css.callUs} />
        <div className={css.mobileToggleMenuOuter}>
          <div className={css.mobileToggleMenu} ref={this.mobileMenuToggle}>
            {selectedTab && tabLabel(intl, selectedTab)}
          </div>
        </div>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={navClass}
          tabRootClassName={css.tab}
          customLinkClass={css.tabLink}
          customSelectedLinkClass={css.tabSelectedLink}
        >
          {this.state.filteredTabs &&
            this.state.filteredTabs.map(tab => {
              return (
                <EditListingWizardTab
                  {...rest}
                  key={tab}
                  showPayoutDetails={this.state.showPayoutDetails}
                  timeSlots={timeSlots}
                  location={this.props.history && this.props.history.location}
                  tabId={`${id}_${tab}`}
                  tabLabel={tabLabel(intl, tab)}
                  tabLinkProps={tabLink(tab)}
                  selected={selectedTab === tab}
                  disabled={isNewListingFlow && !tabsStatus[tab]}
                  tab={tab}
                  hideDeviceTab={this.state.hideDeviceTab}
                  intl={intl}
                  params={params}
                  listing={listing}
                  marketplaceTabs={this.state.filteredTabs}
                  errors={errors}
                  handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                  handlePublishListing={this.handlePublishListing}
                  fetchInProgress={fetchInProgress}
                  currentUser={this.props.currentUser}
                  initialImageUrls={
                    currentListing && currentListing.images.map(image => image.id.uuid)
                  }
                  onManageDisableScrolling={onManageDisableScrolling}
                  stripeConnected={stripeConnected}
                  isCompleted={this.isTabCompleted(tab, listing, currentUser)}
                  stripeProps={{
                    getAccountLinkInProgress,
                    payoutDetailsSaveInProgress,
                    payoutDetailsSaved,
                    stripeAccountData,
                    createStripeAccountError,
                    updateStripeAccountError,
                    fetchStripeAccountError,
                    stripeAccountFetched,
                    onPayoutDetailsFormChange,
                    onGetStripeConnectAccountLink,
                    onPayoutDetailsSubmit: rest.onPayoutDetailsSubmit,
                    stripeAccount,
                    stripeConnected,
                    sParams: params,
                    requirementsMissing,
                  }}
                  updateShowDeviceTab={this.updateShowDeviceTab}
                  showDeviceTab={this.state.showDeviceTab}
                />
              );
            })}
        </Tabs>
        {/* <Modal
          id="EditListingWizard.payoutModal"
          isOpen={this.state.showPayoutDetails}
          onClose={this.handlePayoutModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          disableCloseBtn={true}
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              <FormattedMessage id="EditListingPhotosPanel.payoutModalTitleOneMoreThing" />
              <br />
              <FormattedMessage id="EditListingPhotosPanel.payoutModalTitlePayoutPreferences" />
            </h1>
            <p className={css.modalMessage}>
              <FormattedMessage id="EditListingPhotosPanel.payoutModalInfo" />
            </p>
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : (
              <StripeConnectAccountForm
                disabled={formDisabled}
                inProgress={payoutDetailsSaveInProgress}
                ready={payoutDetailsSaved}
                stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                savedCountry={savedCountry}
                submitButtonText={intl.formatMessage({
                  id: 'StripePayoutPage.submitButtonText',
                })}
                stripeAccountError={
                  createStripeAccountError || updateStripeAccountError || fetchStripeAccountError
                }
                stripeAccountFetched={stripeAccountFetched}
                onChange={onPayoutDetailsFormChange}
                onSubmit={rest.onPayoutDetailsSubmit}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                stripeConnected={stripeConnected}
              >
                {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
                  <StripeConnectAccountStatusBox
                    type={showVerificationError ? 'verificationError' : 'verificationNeeded'}
                    inProgress={getAccountLinkInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_verification'
                    )}
                  />
                ) : stripeConnected && savedCountry ? (
                  <StripeConnectAccountStatusBox
                    type="verificationSuccess"
                    inProgress={getAccountLinkInProgress}
                    disabled={payoutDetailsSaveInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_update'
                    )}
                  />
                ) : null}
              </StripeConnectAccountForm>
            )}
          </div>
        </Modal> */}
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  updateInProgress: false,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  tabs: array.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(TABS).isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  location: object,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
    createStripeAccountError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  updateInProgress: bool,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(EditListingWizard);
