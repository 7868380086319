import React, { Component } from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import get from 'lodash/get';
import classNames from 'classnames';
import { Avatar, InlineTextButton, ReviewRating, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureListing, ensureTransaction, ensureUser } from '../../util/data';
import { propTypes } from '../../util/types';
import {
  getUserTxRole,
  isCustomerReview,
  isProviderReview,
  isRelevantPastTransition,
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF_INSTANT,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP_INSTANT,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_ADMIN_CANCEL_NON_REFUND,
  TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_REFUND,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ADMIN_REFUND,
  TRANSITION_ADMIN_WITHDRAW,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
  TRANSITION_DECLINE,
  TRANSITION_DISPUTE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_EXPIRE_CONFIRM_PICK_UP,
  TRANSITION_EXPIRE_REQUEST_DROP_OFF,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_FAKE_WAITING_FOR_REFUND,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  TRANSITION_WITHDRAW,
  transitionIsReviewed,
  TX_TRANSITION_ACTOR_PROVIDER,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  txRoleIsCustomer,
  txRoleIsProvider,
} from '../../util/transaction';
import * as log from '../../util/log';
import {
  isRelevantPastTransitionLTF,
  TRANSITION_LTF_ACCEPT,
  TRANSITION_LTF_ADMIN_CANCEL,
  TRANSITION_LTF_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_REQUESTED,
  TRANSITION_LTF_ADMIN_CANCEL_NON_REFUND,
  TRANSITION_LTF_ADMIN_REFUND,
  TRANSITION_LTF_COMPLETE,
  TRANSITION_LTF_CONFIRM_PAYMENT,
  TRANSITION_LTF_CONFIRM_PICK_UP,
  TRANSITION_LTF_CUSTOMER_CANCEL,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED,
  TRANSITION_LTF_DECLINE,
  TRANSITION_LTF_EXPIRE,
  TRANSITION_LTF_PROVIDER_CANCEL,
  TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
  TRANSITION_LTF_WITHDRAW,
} from '../../util/transactionLongTermFirst';
import {
  isRelevantPastTransitionLTL,
  TRANSITION_LTL_ADMIN_CANCEL_AFTER_DROP_OFF_REQUESTED,
  TRANSITION_LTL_ADMIN_CANCEL_BEFORE_DROP_OFF_REQUESTED,
  TRANSITION_LTL_ADMIN_CANCEL_NON_REFUND,
  TRANSITION_LTL_ADMIN_NEED_CANCEL,
  TRANSITION_LTL_ADMIN_REFUND,
  TRANSITION_LTL_AUTO_COMPLETE,
  TRANSITION_LTL_CONFIRM_DROP_OFF,
  TRANSITION_LTL_CONFIRM_PAYMENT,
  TRANSITION_LTL_CUSTOMER_CANCEL,
  TRANSITION_LTL_EXPIRE_PAYMENT,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_REVIEW_1_BY_CUSTOMER,
  TRANSITION_LTL_REVIEW_1_BY_PROVIDER,
  TRANSITION_LTL_REVIEW_2_BY_CUSTOMER,
  TRANSITION_LTL_REVIEW_2_BY_PROVIDER,
  txIsCompletedLTL,
} from '../../util/transactionLongTermLast';
import {
  TRANSITION_LTM_ADMIN_CANCEL_NON_REFUND,
  TRANSITION_LTM_ADMIN_NEED_CANCEL,
  TRANSITION_LTM_ADMIN_REFUND,
  TRANSITION_LTM_CONFIRM_PAYMENT,
  TRANSITION_LTM_CUSTOMER_CANCEL,
  TRANSITION_LTM_EXPIRE_PAYMENT,
} from '../../util/transactionLongTermMiddle';
import css from './ActivityFeed.css';
import { addWaitingForRefundTransition } from './activityFeedHelpers';

const Message = props => {
  const { message, intl, onOpenShowImgModal } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} />
      <div>
        {isReasonDispute(message.attributes.content) ? (
          <div className={css.reasonOfDispute}>
            <p className={css.messageContent}>
              <span className={css.reasonOfDisputeTitle}>Reason of Dispute</span>
              <span>
                {combineImgToMessage(
                  message.attributes.content
                    .replace('[Reason of Dispute]', '')
                    .replace('[/Reason of Dispute]', ''),
                  onOpenShowImgModal
                )}
              </span>
            </p>
          </div>
        ) : (
          <p className={css.messageContent}>
            {combineImgToMessage(message.attributes.content, onOpenShowImgModal)}
          </p>
        )}
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl, onOpenShowImgModal } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        {isReasonDispute(message.attributes.content) ? (
          <div className={css.reasonOfDispute}>
            <p className={css.ownMessageContent}>
              <span className={css.reasonOfDisputeTitle}>Reason of Dispute</span>
              {combineImgToMessage(
                message.attributes.content
                  .replace('[Reason of Dispute]', '')
                  .replace('[/Reason of Dispute]', ''),
                onOpenShowImgModal
              )}
            </p>
          </div>
        ) : (
          <p className={css.ownMessageContent}>
            {combineImgToMessage(message.attributes.content, onOpenShowImgModal)}
          </p>
        )}
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const isReasonDispute = content => {
  const patternReasonDispute = /\[Reason of Dispute\][^]*\[\/Reason of Dispute\]/;
  return patternReasonDispute.test(content);
};

const combineImgToMessage = (content, onOpenShowImgModal) => {
  const imgListCode = getImgListCode(content) ? getImgListCode(content)[0] : null;
  const imgCode = getImgCode(content) ? getImgCode(content)[0] : null;
  let newContent = content;
  let messComp = <span>{content}</span>;

  if (imgCode) {
    newContent = newContent.replace(imgCode, '');
    const imgUrl = getImgFromImgCode(imgCode);
    messComp = (
      <span className={css.messageContentContainer}>
        <span className={css.messageContentText}>{newContent}</span>
        <span className={css.messageContentImg} onClick={() => onOpenShowImgModal(imgUrl)}>
          <img src={imgUrl} />
        </span>
      </span>
    );
  }

  if (imgListCode) {
    newContent = newContent.replace(imgListCode, '');
    const imgListUrl = getImgListFromImgListCode(imgListCode);
    messComp = (
      <span className={css.messageContentContainer}>
        <span className={css.messageContentText}>{newContent}</span>
        <span className={css.messageContentImgs}>
          {imgListUrl.map((url, index) => {
            return (
              <span
                key={index}
                className={css.messageContentImg}
                onClick={() => onOpenShowImgModal(url)}
              >
                <img src={url} />
              </span>
            );
          })}
        </span>
      </span>
    );
  }

  return messComp;
};

const getImgListCode = content => {
  const patternImgList = /\[imgs\][^]*\[\/imgs\]/;
  return content.match(patternImgList) ? content.match(patternImgList) : null;
};

const getImgListFromImgListCode = imgListCode => {
  return imgListCode
    .replace('[imgs]', '')
    .replace('[/imgs]', '')
    .split(', ');
};

const getImgCode = content => {
  const patternImgList = /\[img\][^]*\[\/img\]/;
  return content.match(patternImgList) ? content.match(patternImgList) : null;
};

const getImgFromImgCode = imgCode => {
  return imgCode.replace('[img]', '').replace('[/img]', '');
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      <ReviewRating
        reviewStarClassName={css.reviewStar}
        className={css.reviewStars}
        rating={rating}
      />
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  lastTransition,
  onOpenReviewModal
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const deliveredState =
    lastTransition === TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_EXPIRE_CONFIRM_PICK_UP ||
    lastTransition === TRANSITION_AUTO_COMPLETE ||
    lastTransition === TRANSITION_ADMIN_CONFIRM_DROP_OFF ||
    lastTransition === TRANSITION_CONFIRM_DROP_OFF ||
    lastTransition === TRANSITION_REQUEST_DROP_OFF_DLGO;

  switch (currentTransition) {
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_LTF_CONFIRM_PAYMENT:
    case TRANSITION_LTL_CONFIRM_PAYMENT:
    case TRANSITION_LTM_CONFIRM_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequest" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequest"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
    case TRANSITION_LTF_ACCEPT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionAccept" values={{ displayName }} />
      );
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequestUpdateBooking" />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequestUpdateBooking"
          values={{ displayName }}
        />
      );
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
      return <FormattedMessage id="ActivityFeed.adminCancelUpdateBooking" />;
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP:
      return <FormattedMessage id="ActivityFeed.adminAcceptUpdateBooking" />;
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_LTF_CUSTOMER_CANCEL:
    case TRANSITION_LTF_PROVIDER_CANCEL:
    case TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_LTL_CUSTOMER_CANCEL:
    case TRANSITION_LTM_CUSTOMER_CANCEL:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.cancelBookingOwn" />
      ) : (
        <FormattedMessage id="ActivityFeed.cancelBooking" values={{ displayName }} />
      );

    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_LTF_WITHDRAW:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.withdrawOwn" />
      ) : (
        <FormattedMessage id="ActivityFeed.withdrawn" values={{ displayName }} />
      );
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.pickupRequestOwn" />
      ) : (
        <FormattedMessage id="ActivityFeed.pickupRequest" values={{ displayName }} />
      );
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_LTF_CONFIRM_PICK_UP:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.confirmPickupOwn" />
      ) : (
        <FormattedMessage id="ActivityFeed.confirmPickup" values={{ displayName }} />
      );

    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.dropoffRequestOwn" />
      ) : (
        <FormattedMessage id="ActivityFeed.dropoffRequest" values={{ displayName }} />
      );

    case TRANSITION_DISPUTE:
      return (
        <span className={css.disputeLine}>
          {isOwnTransition ? (
            <FormattedMessage id="ActivityFeed.disputeOwn" />
          ) : (
            <FormattedMessage id="ActivityFeed.dispute" values={{ displayName }} />
          )}
        </span>
      );
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_LTL_CONFIRM_DROP_OFF:
      const reviewLink1 =
        (deliveredState || !hasUserLeftAReviewFirst(ownRole, lastTransition)) &&
        !transitionIsReviewed(lastTransition) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
          </InlineTextButton>
        ) : null;

      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.confirmDropoffOwn"
          values={{ reviewLink: reviewLink1 }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.confirmDropoff"
          values={{ displayName, reviewLink: reviewLink1 }}
        />
      );

    case TRANSITION_EXPIRE_REQUEST_DROP_OFF:
      return ownRole === TX_TRANSITION_ACTOR_PROVIDER ? (
        <FormattedMessage id="ActivityFeed.customerDidNotReturnProvider" />
      ) : (
        <FormattedMessage id="ActivityFeed.customerDidNotReturn" values={{ displayName }} />
      );

    case TRANSITION_DECLINE:
    case TRANSITION_LTF_DECLINE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
      );
    case TRANSITION_EXPIRE:
    case TRANSITION_LTF_EXPIRE:
    case TRANSITION_LTL_EXPIRE_PAYMENT:
    case TRANSITION_LTM_EXPIRE_PAYMENT:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExpire" values={{ displayName }} />
      );
    case TRANSITION_CANCEL:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDeclineUpdateBooking" />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionDeclinedUpdateBooking"
          values={{ displayName }}
        />
      );
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP_INSTANT:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF_INSTANT:
    case TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage
          id="ActivityFeed.transitionAcceptedUpdateBooking"
          values={{ displayName }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.ownTransitionAcceptUpdateBooking"
          values={{ displayName }}
        />
      );
    case TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_EXPIRE_CONFIRM_PICK_UP:
    case TRANSITION_AUTO_COMPLETE:
    case TRANSITION_LTL_AUTO_COMPLETE:
      // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
      const reviewPeriodJustStarted = txIsDelivered(transaction) || txIsCompletedLTL(transaction);

      const reviewAsFirstLink = reviewPeriodJustStarted ? (
        <InlineTextButton onClick={onOpenReviewModal}>
          <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
        </InlineTextButton>
      ) : null;

      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLink }}
        />
      );

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_LTL_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_LTL_REVIEW_1_BY_PROVIDER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_LTL_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_LTL_REVIEW_2_BY_PROVIDER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_LTF_ADMIN_CANCEL:
    case TRANSITION_LTF_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE:
    case TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_LTM_ADMIN_NEED_CANCEL:
    case TRANSITION_LTL_ADMIN_NEED_CANCEL:
    case TRANSITION_LTL_ADMIN_CANCEL_BEFORE_DROP_OFF_REQUESTED:
    case TRANSITION_LTL_ADMIN_CANCEL_AFTER_DROP_OFF_REQUESTED:
      return <FormattedMessage id="ActivityFeed.tripCancelledByTheAdmin" />;
    case TRANSITION_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_LTF_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_LTM_ADMIN_CANCEL_NON_REFUND:
    case TRANSITION_LTL_ADMIN_CANCEL_NON_REFUND:
      return <FormattedMessage id="ActivityFeed.cancelBookingNonRefund" />;
    case TRANSITION_FAKE_WAITING_FOR_REFUND:
    case TRANSITION_ADMIN_CANCEL_REFUND:
      return <FormattedMessage id="ActivityFeed.waitingForRefund" />;
    case TRANSITION_ADMIN_REFUND:
    case TRANSITION_LTF_ADMIN_REFUND:
    case TRANSITION_LTM_ADMIN_REFUND:
    case TRANSITION_LTL_ADMIN_REFUND:
      return <FormattedMessage id="ActivityFeed.refunded" />;
    case TRANSITION_LTF_COMPLETE:
      return <FormattedMessage id="ActivityFeed.completedTwoFirstMonths" />;
    case TRANSITION_ADMIN_WITHDRAW:
      return <FormattedMessage id="ActivityFeed.adminWithdrawn" />;
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      return <FormattedMessage id="ActivityFeed.expiredUpdateTripBeforePickUp" />;
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
      return <FormattedMessage id="ActivityFeed.expiredUpdateTripBeforeDropOff" />;
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
      return txRoleIsCustomer(ownRole) ? (
        <FormattedMessage id="ActivityFeed.unverifiedVerificationAccepted" />
      ) : (
        <FormattedMessage id="ActivityFeed.unverifiedVerificationAcceptedHost" />
      );
    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(r => r.author.id.uuid === userId.uuid)[0];
};

const Transition = props => {
  const { transition, transaction, currentUser, intl, onOpenReviewModal } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    lastTransition,
    onOpenReviewModal
  );
  const currentTransition = transition.transition;

  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  if (
    currentTransition === TRANSITION_CONFIRM_PAYMENT_UNVERIFIED ||
    currentTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED
  )
    return '';

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

class ActivityFeedComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // if (true || window.location.search.includes('scroll-to-last-message=true')) {
    //   const messageForm = window.document.querySelector('#messageForm');
    //   setTimeout(() => {
    //     messageForm.scrollIntoView(false);
    //   });
    // }
  }

  render() {
    const {
      rootClassName,
      className,
      messages,
      transaction,
      currentUser,
      hasOlderMessages,
      onOpenReviewModal,
      onShowOlderMessages,
      fetchMessagesInProgress,
      onOpenShowImgModal,
      intl,
      isFinishLongTerm,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const currentTransaction = ensureTransaction(transaction);
    let transitions = currentTransaction.attributes.transitions
      ? currentTransaction.attributes.transitions
      : [];
    if (isFinishLongTerm) {
      const longTermLastTransitions = get(
        currentTransaction,
        'currentChildTransaction.attributes.transitions',
        []
      );
      transitions = [...transitions, ...longTermLastTransitions];
    }
    const currentCustomer = ensureUser(currentTransaction.customer);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentListing = ensureListing(currentTransaction.listing);
    const ownRole = getUserTxRole(currentUser.id, currentTransaction);
    const isProvider = ownRole === 'provider';

    transitions = addWaitingForRefundTransition(transitions, isProvider);

    const transitionsAvailable = !!(
      currentUser &&
      currentUser.id &&
      currentCustomer.id &&
      currentProvider.id &&
      currentListing.id
    );

    // combine messages and transaction transitions
    const items = organizedItems(
      messages,
      transitions,
      hasOlderMessages || fetchMessagesInProgress
    );

    const transitionComponent = transition => {
      if (transitionsAvailable) {
        return (
          <Transition
            transition={transition}
            transaction={transaction}
            currentUser={currentUser}
            intl={intl}
            onOpenReviewModal={onOpenReviewModal}
          />
        );
      } else {
        return <EmptyTransition />;
      }
    };

    const messageComponent = message => {
      const isOwnMessage =
        message.sender &&
        message.sender.id &&
        currentUser &&
        currentUser.id &&
        message.sender.id.uuid === currentUser.id.uuid;
      if (isOwnMessage) {
        return <OwnMessage onOpenShowImgModal={onOpenShowImgModal} message={message} intl={intl} />;
      }
      return <Message onOpenShowImgModal={onOpenShowImgModal} message={message} intl={intl} />;
    };

    const messageListItem = message => {
      return (
        <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
          {messageComponent(message)}
        </li>
      );
    };

    const transitionListItem = transition => {
      const hasRelevantPastTransition =
        isRelevantPastTransition(transition.transition) ||
        isRelevantPastTransitionLTL(transition.transition) ||
        isRelevantPastTransitionLTF(transition.transition);

      if (
        isProvider &&
        [TRANSITION_ADMIN_REFUND, TRANSITION_ADMIN_CANCEL_REFUND].includes(transition.transition)
      ) {
        return null;
      }

      if (hasRelevantPastTransition) {
        return (
          <li
            key={`${transition.transition}_${new Date(transition.createdAt).getTime()}`}
            className={css.transitionItem}
          >
            {transitionComponent(transition)}
          </li>
        );
      } else {
        return null;
      }
    };

    return (
      <ul className={classes} id="activityFeedList">
        {hasOlderMessages ? (
          <li className={css.showOlderWrapper} key="show-older-messages">
            <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
              <FormattedMessage id="ActivityFeed.showOlderMessages" />
            </InlineTextButton>
          </li>
        ) : null}
        {items.map((item, index) => {
          const key = new Date(item.createdAt).getTime().toString();
          if (isMessage(item)) {
            return index === items.length - 1 ? (
              <div key={key} id="lastActivity">
                {messageListItem(item)}
              </div>
            ) : (
              messageListItem(item)
            );
          } else {
            return index === items.length - 1 ? (
              <div key={key} id="lastActivity">
                {transitionListItem(item)}
              </div>
            ) : (
              transitionListItem(item)
            );
          }
        })}
      </ul>
    );
  }
}

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
