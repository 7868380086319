import { drivelahApiGet } from '../../util/apiHelper';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
export const FETCH_DEPOSIT_REQUEST = 'app/refundableDeposit/FETCH_DEPOSIT_REQUEST';
export const FETCH_DEPOSIT_SUCCESS = 'app/refundableDeposit/FETCH_DEPOSIT_SUCCESS';
export const FETCH_DEPOSIT_ERROR = 'app/refundableDeposit/FETCH_DEPOSIT_ERROR';

export const TRANSITION_DEPOSIT_REQUEST = 'app/refundableDeposit/TRANSITION_DEPOSIT_REQUEST';
export const TRANSITION_DEPOSIT_SUCCESS = 'app/refundableDeposit/TRANSITION_DEPOSIT_SUCCESS';
export const TRANSITION_DEPOSIT_ERROR = 'app/refundableDeposit/TRANSITION_DEPOSIT_ERROR';

// ================ Reducer ================ //

const initialState = {
  isDepositCharged: false,
  canRefundRequest: false,
  depositChargeStatus: '',
  depositChildTx: '',
  activeRefundRequest: false,
  depositedAt: '',
  isLoading: true,
  isError: false,
  depositDataArchive: [],
  showModalState: true,
  isTransitioned: false,
  isProcessing: false,
  isTransitionError: false,
  willChargeInNextTx: true,
};

export default function refundableDepositPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DEPOSIT_REQUEST:
      return { ...state, ...payload, isError: false, isLoading: true };
    case FETCH_DEPOSIT_ERROR:
      console.error(payload);
      return { ...state, ...payload, isError: true, isLoading: false };
    case FETCH_DEPOSIT_SUCCESS:
      return { ...state, ...payload, isError: false, isLoading: false };
    case TRANSITION_DEPOSIT_REQUEST:
      return { ...state, showModalState: true, isProcessing: true };
    case TRANSITION_DEPOSIT_SUCCESS:
      return { ...state, showModalState: false, isProcessing: false, isTransitioned: true };
    case TRANSITION_DEPOSIT_ERROR:
      return {
        ...state,
        showModalState: false,
        isProcessing: false,
        isTransitioned: false,
        isTransitionError: true,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const fetchDepositRequest = data => ({ type: FETCH_DEPOSIT_REQUEST, payload: data });
export const fetchDepositSuccess = data => ({ type: FETCH_DEPOSIT_SUCCESS, payload: data });
export const fetchDepositError = data => ({ type: FETCH_DEPOSIT_ERROR, payload: data });

export const transitionDepositRequest = () => ({ type: TRANSITION_DEPOSIT_REQUEST });
export const transitionDepositSuccess = () => ({ type: TRANSITION_DEPOSIT_SUCCESS });
export const transitionDepositError = () => ({ type: TRANSITION_DEPOSIT_ERROR });

// ================ Thunks ================ //

export const fetchCurrentUserDepositStatusPage = userId => (dispatch, getState, sdk) => {
  dispatch(fetchDepositRequest());

  return drivelahApiGet(`queries/deposit-status/` + userId)
    .then(response => {
      const data = response.data.data;
      console.log('depositData', data);
      dispatch(fetchDepositSuccess(data));
    })
    .catch(e => {
      console.log('Error in fetchCurrentUserDepositStatusPage', e);
      const error = storableError(e);
      dispatch(fetchDepositError(error));
    });
};

export const fetchNextTransitionsRefundableDepositPage = id => (dispatch, getState, sdk) => {
  dispatch(transitionDepositRequest());
  console.log('txId', id);

  const bodyParams = {
    id: id,
    transition: 'transition/deposit-refund-requested',
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      console.log('DepositTransition Success');
      dispatch(transitionDepositSuccess());
      return response;
    })
    .catch(e => {
      console.log('DepositTransition Error');
      dispatch(transitionDepositError());
      throw e;
    });
};
