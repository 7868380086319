import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import IntroPanel from '../IntroPanel/IntroPanel';
import { NamedLink } from '../../components';

import css from './SectionPartners.css';
import imageSource from '../../util/imageSource';

const SectionPartners = props => {
  const { intl } = props;

  const iconList = [
    {
      imgSrc: imageSource.landingPage.iconAdditionalIncome,
      text: 'additional income',
    },
    {
      imgSrc: imageSource.landingPage.iconFullyVerified,
      text: 'fully verified guests',
    },
    {
      imgSrc: imageSource.landingPage.iconComprehensively,
      text: 'comprehensively insured',
    },
  ];

  const footer = (
    <div className={css.footer}>
      <NamedLink name="BecomeAHostPage" className={classNames(css.findoutButton, css.footerButton)}>
        <FormattedMessage id="SectionPartners.findoutButton" />
      </NamedLink>
      <NamedLink name="NewListingPage" className={classNames(css.listYourCar, css.footerButton)}>
        <FormattedMessage id="SectionPartners.listYourCar" />
      </NamedLink>
    </div>
  );

  return (
    <IntroPanel
      className={css.root}
      iconTextStyle={css.iconTextStyle}
      questionTitle={
        <FormattedMessage
          id="SectionPartners.questionTitle"
          values={{
            mobileText: (
              <span className={css.mobileText}>
                <FormattedMessage id="SectionPartners.answerTitle" />
              </span>
            ),
          }}
        />
      }
      answerTitle={
        <span className={css.desktopText}>
          <FormattedMessage id="SectionPartners.answerTitle" />
        </span>
      }
      subtitle={<FormattedMessage id="SectionPartners.subtitle" values={{ newline: <br /> }} />}
      iconList={iconList}
      footer={footer}
    />
  );
};

SectionPartners.defaultProps = {};

SectionPartners.propTypes = {
  intl: PropTypes.object,
};

export default SectionPartners;
