import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  FieldPhoneNumberInput,
  FieldSelect,
  FieldTextInput,
  Form,
  NamedLink,
  PrimaryButton,
} from '../../components';

import css from './ConfirmSingupForm.css';
import config from '../../config';
import range from 'lodash/range';

import { SignupPhoneExperiment } from '../../util/constants';
import { EVENT_SIGNUP_PHONE_CASE_A, EVENT_SIGNUP_PHONE_CASE_B } from '../../util/gtm/gtmConstants';
import { pushGTMSignupForm } from '../../util/gtm/gtmHelpers';

const KEY_CODE_ENTER = 13;

const days = range(1, 32);
const months = range(1, 13);

// Show a certain number of years up to the current year
const currentYear = new Date().getFullYear();
const yearsToShow = 80;
const years = range(currentYear, currentYear - yearsToShow, -1);

const ConfirmSingupFormComponent = props => {
  const { experiment } = props;
  const [selected, setSelected] = useState('');
  const [isPhoneCodeSelectReadOnly, setIsPhoneCodeSelectReadOnly] = useState(false);

  useEffect(() => {
    if (config && config.custom && config.custom.phoneCode.length) {
      let country =
        config.maps &&
        config.maps.search &&
        config.maps.search.countryLimit &&
        config.maps.search.countryLimit.length &&
        config.maps.search.countryLimit[0];
      if (country) {
        config.custom.phoneCode.every(function(element) {
          if (element.cc === country) {
            setSelected(element.key);
            return false;
          } else {
            return true;
          }
        });
      }
    }

    // // Send phone experiment event based on case A or B
    // if (experiment && experiment === SignupPhoneExperiment.A) {
    //   pushGTMSignupForm({}, EVENT_SIGNUP_PHONE_CASE_A);
    //   console.log('EVENT_SIGNUP_PHONE_CASE _A send...');
    // } else if (experiment && experiment === SignupPhoneExperiment.B) {
    //   pushGTMSignupForm({}, EVENT_SIGNUP_PHONE_CASE_B);
    //   console.log('EVENT_SIGNUP_PHONE_CASE _B send...');
    // }
    // Check if it's prod, disable phone code change if it is
    if (config.isProd) {
      setIsPhoneCodeSelectReadOnly(true);
    }
  }, []);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          isReferralInvalid,
          onOpenTermsOfService,
          authInfo,
          idp,
          values,
          errors,
          touched,
        } = formRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameRequired',
        });
        const codeLabel = intl.formatMessage({
          id: 'SignupForm.codeLabel',
        });
        const codePlaceholder = intl.formatMessage({
          id: 'SignupForm.codePlaceholder',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const urlSearchParams = new URLSearchParams(props.location.search);
        const queryParams = Object.fromEntries(urlSearchParams.entries());

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

        const dateError = () => {
          const requiredFields = ['day', 'month', 'year'];
          let error = '';
          for (let key in errors) {
            if (touched[key] && errors[key] && requiredFields.includes(key)) {
              return (error = errors[key]);
            }
          }
          return error;
        };

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };

        const phonePlaceholder = intl.formatMessage({
          id: 'ContactDetailsForm.phonePlaceholder',
        });
        const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

        const termsLink = (
          <NamedLink newTab={true} name="TermsOfServicePage">
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </NamedLink>
        );

        const policyLink = (
          <NamedLink newTab={true} name="PrivacyPolicyPage">
            <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
          </NamedLink>
        );

        // If authInfo is not available we should not show the ConfirmForm
        if (!queryParams) {
          return;
        }

        // Initial values from idp provider
        const required = validators.required(intl.formatMessage({ id: 'SignupForm.dobRequired' }));
        const phoneRequired = validators.required(
          intl.formatMessage({ id: 'ConfirmSignupForm.phoneRequired' })
        );
        const genderRequired = validators.required(
          intl.formatMessage({ id: 'ConfirmSignupForm.genderRequired' })
        );
        const roleRequired = validators.required(
          intl.formatMessage({ id: 'ConfirmSignupForm.roleRequired' })
        );
        const minAge = 21;
        const minAgeMessage = intl.formatMessage({ id: 'SignupForm.minAgeRequired' }, { minAge });
        const minAgeRequired = validators.ageAtLeast(
          minAgeMessage,
          minAge,
          values.day,
          values.month
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.email}>
              <label className={css.fieldLabel}>Email</label>
              <FieldTextInput
                type="email"
                className={css.formInput}
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                disabled={false}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
            </div>
            <div className={css.name}>
              <div className={css.fieldWrapper}>
                <label className={css.fieldLabel}>First name</label>
                <FieldTextInput
                  className={css.formInput}
                  type="text"
                  id={formId ? `${formId}.firstName` : 'firstName'}
                  name="firstName"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
              </div>
              <div className={css.centerBlock}></div>
              <div className={css.fieldWrapper}>
                <label className={css.fieldLabel}>Last name</label>
                <FieldTextInput
                  className={css.formInput}
                  type="text"
                  id={formId ? `${formId}.lastName` : 'lastName'}
                  name="lastName"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
            </div>
            {/* <label className={css.fieldLabel}>Date of Birth</label>
          <div className={css.birthdayWrapper}>
            <FieldSelect
              id={formId ? `${formId}.day` : 'day'}
              name="day"
              className={classNames(css.birthdaySelect, css.day)}
              validate={required}
              hideError={true}
            >
              <option value="" selected={true} disabled={true}>
                Day
              </option>
              {days.map(day => (
                <option value={day} key={day}>
                  {day}
                </option>
              ))}
            </FieldSelect>
            <FieldSelect
              id={formId ? `${formId}.month` : 'month'}
              name="month"
              className={classNames(css.birthdaySelect, css.month)}
              validate={required}
              hideError={true}
            >
              <option value="" selected={true} disabled={true}>
                Month
              </option>
              {months.map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </FieldSelect>
            <FieldSelect
              id={formId ? `${formId}.year` : 'year'}
              name="year"
              className={classNames(css.birthdaySelect, css.year)}
              validate={validators.composeValidators(required, minAgeRequired)}
              hideError={true}
            >
              <option value="" selected={true} disabled={true}>
                Year
              </option>
              {years.map(year => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </FieldSelect>
          </div>

          <div className={css.minYearError}>{dateError()}</div> */}
            {/* {
              experiment === SignupPhoneExperiment.A && <div className={css.phoneNumberFieldWrapper}>
                <label className={css.fieldLabel}>{phoneLabel}</label>
                <div className={css.phoneNumberWrapper}>
                  <div>
                    <FieldSelect
                      id="phoneCode"
                      name="phoneCode"
                      className={css.phoneCodeSelect}
                      defaultValue={selected}
                      validate={required}
                      disabled={isPhoneCodeSelectReadOnly}
                    >
                      <option value={''} key={'_default_'}>+00</option>
                      {config.custom.phoneCode.map((option, index) => (
                        <option value={option.key} key={option.key}>
                          {option.key}
                        </option>
                      ))}
                    </FieldSelect>
                  </div>
                  <FieldPhoneNumberInput
                    className={classNames(css.formInput, css.noMargin, css.phoneNumberField)}
                    rootClassName={css.phoneNumberField}
                    name="phoneNumber"
                    id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                    placeholder={phonePlaceholder}
                    disabled={!values.phoneCode}
                    validate={phoneRequired}
                  />
                </div>
              </div>
            } */}
            {/* <div>
            <label className={css.fieldLabel}>
              {intl.formatMessage({ id: 'SignupForm.genderLabel' })}
            </label>
            <FieldSelect id="gender" name="gender" validate={genderRequired} className={css.roleSelect}>
              <option value="" selected={true} disabled={true}>
                {intl.formatMessage({ id: 'SignupForm.genderPlaceholder' })}
              </option>
              {config.custom.genders.map(option => (
                <option value={option.key} key={option.key}>
                  {option.label}
                </option>
              ))}
            </FieldSelect>
          </div> */}
            <label className={css.fieldLabel}>
              What will you be using Drive mate primarily for
            </label>
            <FieldSelect
              id="role"
              name="role"
              className={css.roleSelect}
              validate={roleRequired}
              defaultValue="renter"
            >
              <option value="" selected={true} disabled={true}>
                {intl.formatMessage({ id: 'SignupForm.rolePlaceholder' })}
              </option>
              {config.custom.roles.map((option, index) => (
                <option value={option.key} key={option.key}>
                  {option.label}
                </option>
              ))}
            </FieldSelect>

            <label className={css.fieldLabel}>{codeLabel}</label>
            <FieldTextInput
              className={isReferralInvalid ? css.codeInvalid : css.formInput}
              type="text"
              id={formId ? `${formId}.code` : 'code'}
              name="code"
              placeholder={codePlaceholder}
            />

            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink, policyLink }}
                />
              </span>
            </p>

            <div className={classNames(css.bottomWrapper, css.buttonWrapper)}>
              <PrimaryButton
                className={css.signInButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="SignupForm.nextStep" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

ConfirmSingupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSingupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSingupForm = compose(injectIntl)(ConfirmSingupFormComponent);
ConfirmSingupForm.displayName = 'ConfirmSingupFormForm';

export default ConfirmSingupForm;
