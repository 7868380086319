import React from 'react';
import css from './HostPromoLandingPage.css';
import Slider from 'react-slick';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const datas = [
  {
    content:
      'Drive mate strives to be the Best - Second to None! It is innovative, dares to create new pathways, people-centric and always delivers on its service! Drive mate is committed on building trust within the community it serves.',
    name: 'Even Lim',
    avatar: imageSource.landingPage.avatars.evenLim,
  },
  {
    content:
      'Drive mate has a great and very responsive customer service team. It is an easy and hassle-free platform for me to rent out my car for extra income rather than keeping my car idle when not in use. The instant booking feature is great! No confusion and is clear-cut.',
    name: 'Shareen Tan',
    avatar: imageSource.landingPage.avatars.shareenTan,
  },
  {
    content:
      'The Drive mate team makes the experience of car-sharing relatively stress-free. They are an awesome group of people to work with - quick to respond, always ready to help, positive and open to suggestions for improvements. Keep up the good work! 👍',
    name: 'Shimei He',
    avatar: imageSource.landingPage.avatars.shimeiHe,
  },
  {
    content: 'Convenient way to Rent out your vehicle with insurance covered. Hassle free!',
    name: 'Jonathan Lai',
    avatar: imageSource.landingPage.avatars.jonathanLai,
  },
  {
    content:
      'Drive mate is a safe, easy and convenient platform for car owners to lease their unused car 24-7 for extra income to offset their car maintenance in Australia. Thanks Drive mate!',
    name: 'Ben Chan',
    avatar: imageSource.landingPage.avatars.benChan,
  },
];

const Story = ({ content, name, avatar }) => {
  return (
    <div className={css.story}>
      <div className={css.storyContentWrapper}>
        <LazyImage src={imageSource.landingPage.storyIcon} height={100} />
        <div className={css.storyContent}>{content}</div>
      </div>
      <div className={css.avatarWrapper}>
        <LazyImage containerHeight={'min-content'} objectFit={'fill'} imgHeight={'revert-layer'} imgWidth={'revert-layer'} src={avatar} className={css.avatar} />
        <div className={css.storyName}>{name}</div>
      </div>
    </div>
  );
};

const HostSectionStories = () => {
  const sliderConfig = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 2.25,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionStories}>
      <div className={css.sectionStoriesTitleWrapper}>
        <div className={css.sectionTitle}>{'Stories from\nour hosts'}</div>
        <div className={css.sectionStoriesDescription}>
          They are sharing their car on Drive mate
        </div>
      </div>
      <div className={css.sliderWrapper}>
        <Slider {...sliderConfig}>
          {datas.map(s => (
            <div className={css.storyWrapper}>
              <Story {...s} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HostSectionStories;
