import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendProfileImageChangeSlackNoti } from '../../util/slackNotify';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  UserNav,
} from '../../components';
import { ProfileSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_OWNPROFILE } from '../../util/gtm/gtmConstants';

import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.css';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export class ProfileSettingsPageComponent extends Component {
  constructor() {
    super();
    this.haveAlreadyHadReferralCode = false;
    this.validateReferralCodeErrorMsg = null;
  }

  onViewOwnProfile = () => {
    pushGTMBrowseEvent({
      props: this.props,
      event: EVENT_BROWSE_OWNPROFILE,
    });
  };

  render() {
    const {
      currentUser,
      image,
      onImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      intl,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    const handleSubmit = values => {
      const { firstName, lastName, bio: rawBio } = values;

      // Ensure that the optional bio is a string
      const bio = rawBio || '';

      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        bio,
      };
      const uploadedImage = this.props.image;

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? {
              ...profile,
              profileImageId: uploadedImage.imageId,
            }
          : profile;

      onUpdateProfile(updatedValues).then(() => {
        const {
          guestIdentityVerificationStatus,
          hostIdentityVerificationStatus,
        } = user.attributes.profile.publicData;

        if (
          updatedValues.profileImageId &&
          (guestIdentityVerificationStatus === 'confirmed' ||
            hostIdentityVerificationStatus === 'confirmed')
        ) {
          const userId = user.id.uuid;
          const { displayName: userName } = user.attributes.profile;
          sendProfileImageChangeSlackNoti({ userName, userId });
        }
      });
    };

    const { firstName, lastName, bio } = user.attributes.profile;
    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };
    const { metadata } = user.attributes && user.attributes.profile;
    const { guestReferralCodeData } = metadata || {};

    let referrerName, referralCode;
    if (guestReferralCodeData && guestReferralCodeData.codeValid) {
      referralCode = guestReferralCodeData.referralCode;
      referrerName = guestReferralCodeData.referrerName;
    }

    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{
          firstName,
          lastName,
          bio,
          profileImage: user.profileImage,
          referrerName,
          referralCode,
        }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    ) : null;

    const getVerificationStatusMessage = currentUser => {
      return '';
      // if (!currentUser) {
      //   return '';
      // }
      // switch (currentUser.attributes.profile.publicData.identityVerificationStatus) {
      //   case VERIFICATION_STATUS_PENDING: {
      //     return intl.formatMessage({ id: 'ProfileSettingsPage.verificationPending' })
      //   }
      //   case VERIFICATION_STATUS_VERIFIED: {
      //     return intl.formatMessage({ id: 'ProfileSettingsPage.verificationVerified' })
      //   }
      //   default: {
      //     return intl.formatMessage({ id: 'ProfileSettingsPage.verificationNotSubmitted' })
      //   }
      // }
    };

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    const verificationStatusMessage = getVerificationStatusMessage(currentUser);

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
            <UserNav selectedPageName="ProfileSettingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="ProfileSettingsPage.heading" />
                </h1>
                {user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                    onClick={this.onViewOwnProfile()}
                  >
                    <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                  </NamedLink>
                ) : null}
              </div>
              <h3 className={css.heading}>{verificationStatusMessage}</h3>
              {profileSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;

  return {
    currentUser,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
