import React from 'react';

const ModalMissingPostalCodeHeaderIcon = ({ _width = 600, _height = 150, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={width}
      // height={height}
      className={className}
      viewBox="0 0 600 150"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_4515"
            data-name="Path 4515"
            d="M36.543,46.787h600v150h-600Z"
            transform="translate(0)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Rectangle_4428"
            data-name="Rectangle 4428"
            d="M8,0H592a8,8,0,0,1,8,8V150a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
            transform="translate(47.543 104.787)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_5775"
        data-name="Group 5775"
        transform="translate(-36.543 -46.787)"
        clipPath="url(#clip-path)"
      >
        <g
          id="Mask_Group_5275"
          data-name="Mask Group 5275"
          transform="translate(-11 -58)"
          clipPath="url(#clip-path-2)"
        >
          <g id="Group_5774" data-name="Group 5774" transform="translate(-325.457 -315.394)">
            <g id="Group_5759" data-name="Group 5759">
              <ellipse
                id="Ellipse_1303"
                data-name="Ellipse 1303"
                cx="494.809"
                cy="495.181"
                rx="494.809"
                ry="495.181"
                fill="#00a3ad"
              />
            </g>
            <g id="Group_5760" data-name="Group 5760" transform="translate(161.879 162.001)">
              <ellipse
                id="Ellipse_1304"
                data-name="Ellipse 1304"
                cx="332.93"
                cy="333.18"
                rx="332.93"
                ry="333.18"
                fill="#51b2ba"
              />
            </g>
            <g id="Group_5761" data-name="Group 5761" transform="translate(275.6 271.095)">
              <ellipse
                id="Ellipse_1305"
                data-name="Ellipse 1305"
                cx="219.21"
                cy="224.086"
                rx="219.21"
                ry="224.086"
                fill="#7ac2c8"
              />
            </g>
            <g id="Group_5762" data-name="Group 5762" transform="translate(362.905 363.178)">
              <ellipse
                id="Ellipse_1306"
                data-name="Ellipse 1306"
                cx="131.904"
                cy="132.003"
                rx="131.904"
                ry="132.003"
                transform="translate(0 0)"
                fill="#9dd6da"
              />
            </g>
            <g id="Group_5763" data-name="Group 5763" transform="translate(414.321 414.632)">
              <ellipse
                id="Ellipse_1307"
                data-name="Ellipse 1307"
                cx="80.488"
                cy="80.549"
                rx="80.488"
                ry="80.549"
                fill="#dff0f1"
              />
            </g>
            <g id="Group_5764" data-name="Group 5764" transform="translate(442.132 442.464)">
              <ellipse
                id="Ellipse_1308"
                data-name="Ellipse 1308"
                cx="52.677"
                cy="52.717"
                rx="52.677"
                ry="52.717"
                transform="translate(0 0)"
                fill="#fff"
              />
            </g>
            <g id="Group_5765" data-name="Group 5765" transform="translate(451.931 452.27)">
              <ellipse
                id="Ellipse_1309"
                data-name="Ellipse 1309"
                cx="42.879"
                cy="42.911"
                rx="42.879"
                ry="42.911"
                fill="#fff"
              />
            </g>
            <g
              id="Current_Location"
              data-name="Current Location"
              transform="translate(478.24 470.876)"
            >
              <path
                id="Path_3775"
                data-name="Path 3775"
                d="M2969.106,6989.084a1.89,1.89,0,0,1-1.262-.659,69.045,69.045,0,0,1-12-14.358,24.806,24.806,0,0,1-3.525-8.726c-.085-.516-.13-1.036-.179-1.588l0-.029c-.013-.221-.035-.449-.063-.749v-.022a17.1,17.1,0,0,1,17.052-16.8,17,17,0,0,1,16.82,15.08,17.418,17.418,0,0,1-1.931,9.674,47.552,47.552,0,0,1-7.712,11.289c-1.517,1.7-3.134,3.342-4.7,4.93l-.02.02-.128.133c-.377.386-.754.768-1.131,1.149A1.763,1.763,0,0,1,2969.106,6989.084Zm-.373-40.1a13.423,13.423,0,0,0-9.979,4.6,13.139,13.139,0,0,0-3.634,10.955,19.209,19.209,0,0,0,2.675,7.183,56.267,56.267,0,0,0,8.072,10.625c.677.717,1.387,1.375,2.136,2.074l.085.078.073.067c.268.245.541.5.817.76l.227.213.223-.219a80.506,80.506,0,0,0,7.486-8.425,39.406,39.406,0,0,0,4.833-7.892,13.278,13.278,0,0,0,1.122-8.761c-1.325-5.616-4.712-9.21-10.065-10.684A15.547,15.547,0,0,0,2968.733,6948.984Z"
                transform="translate(-2952.127 -6946.203)"
                fill="#18a3ad"
              />
              <path
                id="Path_3774"
                data-name="Path 3774"
                d="M2968.739,6960.045l-.005-.178a31.187,31.187,0,0,1-11.773-2.18,9.989,9.989,0,0,1-3.564-2.367,4.318,4.318,0,0,1,.036-6.241,11.861,11.861,0,0,1,4.785-2.84,1.478,1.478,0,0,1,.485-.089,1.456,1.456,0,0,1,.5,2.776c-.209.095-.427.19-.636.279l-.114.05-.076.033a12.581,12.581,0,0,0-1.37.651,7.31,7.31,0,0,0-1.569,1.233,1.359,1.359,0,0,0,0,2.091,7.208,7.208,0,0,0,2.671,1.792,22.875,22.875,0,0,0,6.7,1.683,35.285,35.285,0,0,0,9.981-.219,17.494,17.494,0,0,0,6.721-2.283,6.209,6.209,0,0,0,1.134-.964,1.407,1.407,0,0,0,.022-2.113,7.589,7.589,0,0,0-2.866-1.873c-.113-.051-.238-.1-.417-.17s-.347-.135-.531-.224a1.355,1.355,0,0,1-.739-1.764,1.387,1.387,0,0,1,1.3-.972,1.6,1.6,0,0,1,.44.069,9.445,9.445,0,0,1,1.192.453,10.8,10.8,0,0,1,3.61,2.385,4.332,4.332,0,0,1,0,6.287,12.651,12.651,0,0,1-5.66,3.114,34.9,34.9,0,0,1-10.253,1.4v.178Z"
                transform="translate(-2952.081 -6910.792)"
                fill="#18a3ad"
              />
              <path
                id="Path_3773"
                data-name="Path 3773"
                d="M2958.777,6959.5h-.027a6.716,6.716,0,0,1-6.676-6.66,6.566,6.566,0,0,1,1.918-4.694,6.672,6.672,0,0,1,4.738-2h.022a6.675,6.675,0,0,1,.023,13.35Zm-.032-10.472a3.794,3.794,0,0,0,0,7.588h.018a3.794,3.794,0,0,0,3.783-3.6l0-.222A3.8,3.8,0,0,0,2958.745,6949.028Z"
                transform="translate(-2941.792 -6935.901)"
                fill="#ffcd05"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ModalMissingPostalCodeHeaderIcon;
