import { ensureTransaction } from './data';

export const PROCESS_NAME_ONE_FOR_ALL = 'additional-addons-process';
/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const TRANSITION_ADDITIONAL_REQUEST = 'transition/additional-request';
export const TRANSITION_ADDITIONAL_CONFIRM = 'transition/additional-confirm';
export const TRANSITION_ADDITIONAL_CONFIRM_PAYMENT = 'transition/additional-confirm-payment';
export const TRANSITION_ADDITIONAL_ACCEPT = 'transition/additional-accept';
export const TRANSITION_ADDITIONAL_EXPIRE = 'transition/additional-expire';
export const TRANSITION_ADDITIONAL_DECLINE = 'transition/additional-decline';
export const TRANSITION_ADDITIONAL_WITHDRAW = 'transition/additional-withdraw';
export const TRANSITION_ADDITIONAL_WITHDRAW_BY_ADMIN = 'transition/additional-withdraw-by-admin';
export const TRANSITION_ADDITIONAL_COMPLETE = 'transition/additional-complete';

export const TRANSITION_ADDITIONAL_PROVIDER_CANCEL = 'transition/additional-provider-cancel';
export const TRANSITION_ADDITIONAL_CUSTOMER_CANCEL = 'transition/additional-customer-cancel';
export const TRANSITION_ADDITIONAL_ADMIN_CANCEL = 'transition/additional-admin-cancel';
export const TRANSITION_ADDITIONAL_ADMIN_REFUND = 'transition/additional-admin-refund';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_ADDITIONAL_INITIAL = 'initial';
const STATE_ADDITIONAL_PENDING_PAYMENT = 'additional-pending-payment';
const STATE_ADDITIONAL_NEED_PROVIDER_ACTION = 'additional-need-provider-action';
const STATE_ADDITIONAL_PREAUTHORIZED = 'additional-preauthorized';
const STATE_ADDITIONAL_DELIVERED = 'additional-delivered';
const STATE_ADDITIONAL_DECLINED = 'additional-declined';
const STATE_ADDITIONAL_WAITING_FOR_REFUND = 'additional-waiting-for-refund';
const STATE_ADDITIONAL_CANCELLED = 'additional-cancelled';

const stateDescription = {
  id: 'additional-addons-process',
  initial: STATE_ADDITIONAL_INITIAL,

  // States
  states: {
    [STATE_ADDITIONAL_INITIAL]: {
      on: {
        [TRANSITION_ADDITIONAL_REQUEST]: STATE_ADDITIONAL_PENDING_PAYMENT,
      },
    },
    [STATE_ADDITIONAL_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_ADDITIONAL_CONFIRM]: STATE_ADDITIONAL_PREAUTHORIZED,
        [TRANSITION_ADDITIONAL_CONFIRM_PAYMENT]: STATE_ADDITIONAL_NEED_PROVIDER_ACTION,
      },
    },
    [STATE_ADDITIONAL_NEED_PROVIDER_ACTION]: {
      on: {
        [TRANSITION_ADDITIONAL_ACCEPT]: STATE_ADDITIONAL_PREAUTHORIZED,
        [TRANSITION_ADDITIONAL_EXPIRE]: STATE_ADDITIONAL_DECLINED,
        [TRANSITION_ADDITIONAL_DECLINE]: STATE_ADDITIONAL_DECLINED,
        [TRANSITION_ADDITIONAL_WITHDRAW]: STATE_ADDITIONAL_DECLINED,
        [TRANSITION_ADDITIONAL_WITHDRAW_BY_ADMIN]: STATE_ADDITIONAL_DECLINED,
      },
    },
    [STATE_ADDITIONAL_PREAUTHORIZED]: {
      on: {
        [TRANSITION_ADDITIONAL_COMPLETE]: STATE_ADDITIONAL_DELIVERED,
        [TRANSITION_ADDITIONAL_PROVIDER_CANCEL]: STATE_ADDITIONAL_CANCELLED,
        [TRANSITION_ADDITIONAL_CUSTOMER_CANCEL]: STATE_ADDITIONAL_WAITING_FOR_REFUND,
        [TRANSITION_ADDITIONAL_ADMIN_CANCEL]: STATE_ADDITIONAL_CANCELLED,
      },
    },
    [STATE_ADDITIONAL_WAITING_FOR_REFUND]: {
      on: {
        [TRANSITION_ADDITIONAL_ADMIN_REFUND]: STATE_ADDITIONAL_CANCELLED,
      },
    },
    [STATE_ADDITIONAL_DECLINED]: {},
    [STATE_ADDITIONAL_CANCELLED]: {},
    [STATE_ADDITIONAL_DELIVERED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;
