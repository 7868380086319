const setFieldTouched = (args = [], state) => {
  const [name, touched] = args;
  const field = state.fields[name];
  if (field) {
    field.touched = !!touched;
  }
};

export const getAllPaths = obj => {
  const results = [];
  const getPath = (obj, path) => {
    path = path || '';
    for (let i in obj) {
      if (typeof obj[i] === 'object') {
        if (path !== '') getPath(obj[i], `${path}.${i}`);
        else getPath(obj[i], `${i}`);
      } else {
        if (path) results.push(`${path}.${i}`);
        else results.push(`${i}`);
      }
    }
  };
  getPath(obj);
  return results;
};

export default setFieldTouched;
