exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Discussion_messageDate__22O_s {\n  color: #bbb;\n  display: block;\n  text-align: center;\n  margin-right: 1rem;\n  font-size: 0.8rem;\n}\n\n.Discussion_messageText__gdClg {\n  background-color: #eee;\n  padding: 1rem;\n  margin: 0 1rem;\n}\n\n.Discussion_messageAuthor__2hzSe {\n  color: #666;\n  display: block;\n  text-align: right;\n  margin-right: 1rem;\n}\n\n.Discussion_sendMessageForm__8R9q_ {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  background-color: #eee;\n  padding: 1rem;\n}\n\n.Discussion_sendMessageInput__10wAl {\n  display: inline-block;\n  width: calc(100% - 6rem);\n  font-family: sans-serif;\n  padding: 0.5rem;\n  border-right: none;\n  background-color: #fff;\n}\n\n.Discussion_sendMessageButton__3_sQV {\n  display: inline-block;\n  width: 6rem;\n  margin: 0;\n  background-color: #aaa;\n  color: #fff;\n  border: 1px solid #aaa\n}\n\n.Discussion_sendMessageButton__3_sQV:hover,\n  .Discussion_sendMessageButton__3_sQV:active {\n    background-color: #999;\n    border-color: #999;\n  }\n", ""]);

// Exports
exports.locals = {
	"messageDate": "Discussion_messageDate__22O_s",
	"messageText": "Discussion_messageText__gdClg",
	"messageAuthor": "Discussion_messageAuthor__2hzSe",
	"sendMessageForm": "Discussion_sendMessageForm__8R9q_",
	"sendMessageInput": "Discussion_sendMessageInput__10wAl",
	"sendMessageButton": "Discussion_sendMessageButton__3_sQV"
};