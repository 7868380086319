import React from 'react';
import { bool } from 'prop-types';
import get from 'lodash/get';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  CANCEL_BEFORE_72_HOURS,
  CANCEL_WITHIN_72_HOURS,
} from '../../../util/longTermRentalHelpers';

import css from '../BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemLongTermProviderMakeAfterCancellation = props => {
  const { transaction, intl, isProvider, isCancelled } = props;
  let cancellationFine = get(transaction, 'attributes.metadata.penaltyData.penaltyAmount', 0);
  if (!isProvider || !isCancelled || cancellationFine) {
    return null;
  }

  const providerMakeAfterCancellationLabel = (
    <FormattedMessage id={'BookingBreakdownLongTerm.providerMakeAfterCancellationLabel'} />
  );

  const payoutTotal = transaction.attributes.payoutTotal;
  const refundCustomer = get(transaction, 'attributes.metadata.needRefund', 0);
  const dailyRateCancelData = get(transaction, 'attributes.metadata.dailyRateCancelData');
  const commissionObj2Months = get(transaction, 'attributes.protectedData.commissionObj');
  const cancelData = get(transaction, 'attributes.metadata.cancelData', {});
  let longTermHostWillMake = get(transaction, 'attributes.metadata.longTermHostWillMake', 0);
  const hostReceiveFromFine = get(cancelData, 'hostReceiveFromFine', 0);

  const isCancelBeforeTripStart = [CANCEL_BEFORE_72_HOURS, CANCEL_WITHIN_72_HOURS].includes(
    cancelData.cancellationType
  );

  const paymentTwoMonths = isCancelled
    ? commissionObj2Months.note.baseTripPrice
    : payoutTotal.amount;

  longTermHostWillMake = longTermHostWillMake ? longTermHostWillMake : paymentTwoMonths;
  let hostWillMakeAfterCancellation = isCancelBeforeTripStart
    ? hostReceiveFromFine
    : (dailyRateCancelData && dailyRateCancelData.note.baseTripPrice) ||
      longTermHostWillMake - refundCustomer;

  hostWillMakeAfterCancellation = new Money(hostWillMakeAfterCancellation, payoutTotal.currency);
  const formattedTotalPrice = formatMoney(intl, hostWillMakeAfterCancellation);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{providerMakeAfterCancellationLabel}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemLongTermProviderMakeAfterCancellation.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemLongTermProviderMakeAfterCancellation;
