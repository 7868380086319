import get from 'lodash/get';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userDisplayNameAsString } from '../../util/data';
import { txIsExpiredLTF } from '../../util/transactionLongTermFirst';
import { txDontShowPrivateInfo, txIsExpired } from '../../util/transaction';
import { Avatar42, Avatar48 } from '../Avatar/Avatar';
// import phoneIcon from '../../assets/phone-solid.svg';
import css from './PartnerInfo.css';
import { format } from '../FieldPhoneNumberInput/fiFormatter';
import imageSource from '../../util/imageSource';

const getCustomerPhoneNumber = transaction => {
  return (
    get(transaction, 'attributes.protectedData.guestPhoneNumber', null) ||
    get(transaction, 'attributes.protectedData.customerPhoneNumber', null)
  );
};

const getHostPhoneNumber = transaction => {
  if (
    transaction &&
    transaction.customer &&
    transaction.customer.attributes &&
    transaction.customer.attributes.profile &&
    transaction.customer.attributes.profile.publicData &&
    transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      'confirmed'
  ) {
    return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
  }
  return null;
};

const PartnerInfo = ({ otherUser, currentUserRole, transaction, isMobileLayout, userTypeText }) => {
  const isCustomer = currentUserRole === 'customer';
  const isProvider = currentUserRole === 'provider';

  const isShowPartnerInfo =
    !txIsExpired(transaction) &&
    !txDontShowPrivateInfo(transaction) &&
    !txIsExpiredLTF(transaction);

  const otherPhoneNumber = isCustomer
    ? getHostPhoneNumber(transaction)
    : getCustomerPhoneNumber(transaction);
  const listingRegistrationNumber = get(
    transaction,
    'listing.attributes.publicData.license_plate_number',
    null
  );

  const formatPhoneNumber = phoneNumber => {
    const phoneCode = phoneNumber.slice(0, 3);
    if (phoneCode === '+61' || phoneCode === '+65') {
      const removePhoneCode = phoneNumber.substring(3);
      const updatedPhoneNumber = format(removePhoneCode);
      return updatedPhoneNumber;
    }
    return phoneNumber;
  };

  return (
    <div className={css.partnerInfo}>
      <div className={css.avatarinfoContainer}>
        <div className={css.avatarWrapperProviderDesktop}>
          {isMobileLayout ? (
            <Avatar42 user={otherUser} className={css.avatarDesktop} />
          ) : (
            <Avatar48 user={otherUser} className={css.avatarDesktop} />
          )}
        </div>
        <div className={css.userNameContainer}>
          <p className={css.userTypeText}>
            {userTypeText ? (
              userTypeText
            ) : isCustomer ? (
              <FormattedMessage id="TransactionPanel.yourHost" />
            ) : (
              <FormattedMessage id="TransactionPanel.yourDriver" />
            )}
          </p>
          <div className={css.userNameText}>{userDisplayNameAsString(otherUser)}</div>
        </div>
      </div>

      {isShowPartnerInfo ? (
        <div className={css.contactContainer}>
          <img src={imageSource?.phoneIcon} alt="telephone" />
          {otherPhoneNumber && (
            <a className={css.userTelephone} href={`tel:${otherPhoneNumber}`}>
              {formatPhoneNumber(otherPhoneNumber)}
            </a>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PartnerInfo;
