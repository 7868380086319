import React from 'react';
import { InlineWidget } from 'react-calendly';
import { LayoutWrapperMain } from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import css from './CalendlyPage.css';
import config from '../../config';

const CalendlyPage = props => {
  const { intl, location = {} } = props;
  const queryMaybe = location.search ? parse(location.search) : {};
  console.log('PREFILL DATA ', props, queryMaybe);
  const { email, firstName, lastName, listingId } = queryMaybe;

  // prettier-ignore
  return (
        <LayoutWrapperMain className={css.staticPageWrapper}>
              <InlineWidget
                prefill={queryMaybe}
                styles={{
                  position: 'relative',
                  minWidth: '320px',
                  height:'720px'
                }}
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: false,
                  hideGdprBanner: true,
                  primaryColor: 'fecd2a',
                  textColor: '0d6786'
                }}
                url={config.calendlyWebPageUrl} />
        </LayoutWrapperMain>
  );
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => {};

const LandingPage = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(CalendlyPage);

export default LandingPage;
