import React, { useEffect, useRef, useState } from 'react';

const LoadingImage = ({ colors }) => {
  const [color1 = '#d1d3d4', color2 = '#d1d3d4', color3 = '#d1d3d4'] = colors;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="231.961"
      height={150}
      viewBox="0 0 231.961 150"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="231.961" height={150} fill="none" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Clip_2"
            data-name="Clip 2"
            d="M0,0H175.961V88.833H0Z"
            transform="translate(0 0.003)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Clip_5"
            data-name="Clip 5"
            d="M0,0H100.7V46H0Z"
            transform="translate(0.161 0.003)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width={150}
            height={150}
            transform="translate(43 242)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Repeat_Grid_3" data-name="Repeat Grid 3" clipPath="url(#clip-path)">
        <g id="Group_379" data-name="Group 379" transform="translate(-43 -192)">
          <g id="Group_7" data-name="Group 7" transform="translate(99 225)">
            <g id="Group_3" data-name="Group 3">
              <path
                id="Clip_2-2"
                data-name="Clip 2"
                d="M0,0H175.961V88.833H0Z"
                transform="translate(0 0.003)"
                fill="none"
              />
              <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path-2)">
                <path
                  id="Fill_1"
                  data-name="Fill 1"
                  d="M169.173,45.127c-7.9-11.618-20.193-18.505-37.59-21.054-2.86-.419-4.391-.744-4.824-1.359a83.556,83.556,0,0,0-7.583-9.363A39.562,39.562,0,0,0,93.946.909,124.848,124.848,0,0,0,66.53.623C45.9,2.885,33.874,11.79,29.663,15.513c-8.982,7.941-13.182,13.97-16.726,20.579a73.6,73.6,0,0,0-3.712,8.263c-.464,1.179-.873,2.219-1.239,2.968-.116.092-.249.194-.323.251a16.363,16.363,0,0,0-5.089,5.9C.624,57.344.163,65.279.068,67.618-.2,74.21.14,80.809,4.4,84.429l.309.262.348.208a34.637,34.637,0,0,0,16.6,3.934c1.249,0,2.553-.06,3.906-.2l3.981-.4a25.45,25.45,0,0,1-2.417-9.471l-2.522.252a27.381,27.381,0,0,1-14.13-2.186c-.262-.56-1.01-2.725-.763-8.824.191-4.716.92-9.077,1.481-10.191a7.028,7.028,0,0,1,2.348-2.582,9.082,9.082,0,0,0,2.765-2.986,40.851,40.851,0,0,0,1.907-4.374,65.088,65.088,0,0,1,3.229-7.216c3.052-5.693,6.7-10.917,14.61-17.906,3.521-3.113,13.642-10.566,31.529-12.526a115.283,115.283,0,0,1,25.285.283,30.166,30.166,0,0,1,19.255,9.436,74.374,74.374,0,0,1,6.747,8.335c2.9,4.118,7.371,4.773,11.315,5.351,14.776,2.165,24.628,7.543,31.007,16.928,3.582,5.271,5.387,11.04,5.084,16.246-.1,1.747-.507,4.15-1.859,5.68-1.619,1.833-4.583,2.393-7.764,2.93h-17.7c.052.65.081,1.3.081,1.96a25.919,25.919,0,0,1-1.165,7.7h19.588l.4-.067c4.386-.737,9.844-1.654,13.794-6.126,2.553-2.89,3.988-6.766,4.265-11.519.427-7.322-1.967-15.222-6.74-22.245"
                  transform="translate(0 0.003)"
                  fill="#d1d3d4"
                />
              </g>
            </g>
            <g id="Group_6" data-name="Group 6" transform="translate(33 55)">
              <path
                id="Clip_5-2"
                data-name="Clip 5"
                d="M0,0H100.7V46H0Z"
                transform="translate(0.161 0.003)"
                fill="none"
              />
              <g id="Group_6-2" data-name="Group 6" clipPath="url(#clip-path-3)">
                <path
                  id="Fill_4"
                  data-name="Fill 4"
                  d="M89.632,3.625C82.75-.549,72.923-.737,65.181,3.156c-4.1,2.064-8.2,5.526-12.533,10.584l-.1.117.1.118c6,7.213,6,7.213,6.088,7.217l.092,0,.06-.071C63.819,15.252,69.721,8.985,77.358,8.985a14.119,14.119,0,0,1,14.2,14,14.12,14.12,0,0,1-14.2,14A14.311,14.311,0,0,1,72.115,36c-6.846-2.682-12.008-8.361-16.309-13.474C50.8,16.575,45.774,10.83,39.391,5.987,38.648,5.422,37.864,4.9,37.107,4.4l-.648-.431A23.461,23.461,0,0,0,23.346,0,23.207,23.207,0,0,0,0,23.011,22.861,22.861,0,0,0,11.072,42.372c6.765,4.1,16.26,4.336,24.189.6,4.089-1.927,8.274-5.432,12.795-10.715l.1-.117-.1-.118c-6-7.212-6-7.212-6.088-7.216l-.092,0-.06.071c-4.932,5.874-10.835,12.142-18.472,12.142a14.12,14.12,0,0,1-14.2-14,14.12,14.12,0,0,1,14.2-14A14.316,14.316,0,0,1,28.589,10c6.131,2.4,10.943,7.283,14.843,11.742q1.243,1.421,2.478,2.86A125.559,125.559,0,0,0,57.44,36.812l.148.132a38.572,38.572,0,0,0,6.657,5.084A23.465,23.465,0,0,0,77.358,46,23.207,23.207,0,0,0,100.7,22.986,22.861,22.861,0,0,0,89.632,3.625"
                  transform="translate(0.161 0.003)"
                  fill={color3}
                />
              </g>
            </g>
          </g>
          <path
            id="Fill_1-2"
            data-name="Fill 1"
            d="M169.173,45.127c-7.9-11.618-20.193-18.505-37.59-21.054-2.86-.419-4.391-.744-4.824-1.359a83.556,83.556,0,0,0-7.583-9.363A39.562,39.562,0,0,0,93.946.909,124.848,124.848,0,0,0,66.53.623C45.9,2.885,33.874,11.79,29.663,15.513c-8.982,7.941-13.182,13.97-16.726,20.579a73.6,73.6,0,0,0-3.712,8.263c-.464,1.179-.873,2.219-1.239,2.968-.116.092-.249.194-.323.251a16.363,16.363,0,0,0-5.089,5.9C.624,57.344.163,65.279.068,67.618-.2,74.21.14,80.809,4.4,84.429l.309.262.348.208a34.637,34.637,0,0,0,16.6,3.934c1.249,0,2.553-.06,3.906-.2l3.981-.4a25.45,25.45,0,0,1-2.417-9.471l-2.522.252a27.381,27.381,0,0,1-14.13-2.186c-.262-.56-1.01-2.725-.763-8.824.191-4.716.92-9.077,1.481-10.191a7.028,7.028,0,0,1,2.348-2.582,9.082,9.082,0,0,0,2.765-2.986,40.851,40.851,0,0,0,1.907-4.374,65.088,65.088,0,0,1,3.229-7.216c3.052-5.693,6.7-10.917,14.61-17.906,3.521-3.113,13.642-10.566,31.529-12.526a115.283,115.283,0,0,1,25.285.283,30.166,30.166,0,0,1,19.255,9.436,74.374,74.374,0,0,1,6.747,8.335c2.9,4.118,7.371,4.773,11.315,5.351,14.776,2.165,24.628,7.543,31.007,16.928,3.582,5.271,5.387,11.04,5.084,16.246-.1,1.747-.507,4.15-1.859,5.68-1.619,1.833-4.583,2.393-7.764,2.93h-17.7c.052.65.081,1.3.081,1.96a25.919,25.919,0,0,1-1.165,7.7h19.588l.4-.067c4.386-.737,9.844-1.654,13.794-6.126,2.553-2.89,3.988-6.766,4.265-11.519.427-7.322-1.967-15.222-6.74-22.245"
            transform="translate(99 225.003)"
            fill={color2}
          />
          <g
            id="Mask_Group_1"
            data-name="Mask Group 1"
            transform="translate(0 -50)"
            clipPath="url(#clip-path-4)"
          >
            <path
              id="Fill_1-3"
              data-name="Fill 1"
              d="M169.173,45.127c-7.9-11.618-20.193-18.505-37.59-21.054-2.86-.419-4.391-.744-4.824-1.359a83.556,83.556,0,0,0-7.583-9.363A39.562,39.562,0,0,0,93.946.909,124.848,124.848,0,0,0,66.53.623C45.9,2.885,33.874,11.79,29.663,15.513c-8.982,7.941-13.182,13.97-16.726,20.579a73.6,73.6,0,0,0-3.712,8.263c-.464,1.179-.873,2.219-1.239,2.968-.116.092-.249.194-.323.251a16.363,16.363,0,0,0-5.089,5.9C.624,57.344.163,65.279.068,67.618-.2,74.21.14,80.809,4.4,84.429l.309.262.348.208a34.637,34.637,0,0,0,16.6,3.934c1.249,0,2.553-.06,3.906-.2l3.981-.4a25.45,25.45,0,0,1-2.417-9.471l-2.522.252a27.381,27.381,0,0,1-14.13-2.186c-.262-.56-1.01-2.725-.763-8.824.191-4.716.92-9.077,1.481-10.191a7.028,7.028,0,0,1,2.348-2.582,9.082,9.082,0,0,0,2.765-2.986,40.851,40.851,0,0,0,1.907-4.374,65.088,65.088,0,0,1,3.229-7.216c3.052-5.693,6.7-10.917,14.61-17.906,3.521-3.113,13.642-10.566,31.529-12.526a115.283,115.283,0,0,1,25.285.283,30.166,30.166,0,0,1,19.255,9.436,74.374,74.374,0,0,1,6.747,8.335c2.9,4.118,7.371,4.773,11.315,5.351,14.776,2.165,24.628,7.543,31.007,16.928,3.582,5.271,5.387,11.04,5.084,16.246-.1,1.747-.507,4.15-1.859,5.68-1.619,1.833-4.583,2.393-7.764,2.93h-17.7c.052.65.081,1.3.081,1.96a25.919,25.919,0,0,1-1.165,7.7h19.588l.4-.067c4.386-.737,9.844-1.654,13.794-6.126,2.553-2.89,3.988-6.766,4.265-11.519.427-7.322-1.967-15.222-6.74-22.245"
              transform="translate(99 275.003)"
              fill={color1}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Loading = props => {
  const { duration = 500, rootClassName } = props;
  const [index, setIndex] = useState(0);
  const keepInterval = useRef(null);

  useEffect(() => {
    keepInterval.current = setInterval(() => {
      setIndex(prev => prev + 1);
    }, duration);
    return () => {
      clearInterval(keepInterval.current);
    };
  }, []);

  let colors = [];
  if (index % 4 === 1) colors = ['#026786', '#d1d3d4', '#d1d3d4'];
  else if (index % 4 === 2) colors = ['#026786', '#026786', '#d1d3d4'];
  else if (index % 4 === 3) colors = ['#026786', '#026786', '#ffcd05'];

  return (
    <div className={rootClassName}>
      <LoadingImage colors={colors} />
    </div>
  );
};

export default Loading;
