import { get } from 'lodash';
import {
  STRIPE_ACCOUNT_RESTRICTED_SOON_STATUS,
  STRIPE_ACCOUNT_RESTRICTED_STATUS,
} from '../constants/stripe.constants';

// ================ Action types ================ //

export const SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED =
  'app/user/SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED';
export const CLOSE_ACCOUNT_RESTRICTED_ALERT = 'app/user/CLOSE_ACCOUNT_RESTRICTED_ALERT';

const initialState = {
  currentUserStripeAccountRestricted: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED:
      return { ...state, currentUserStripeAccountRestricted: payload };
    case CLOSE_ACCOUNT_RESTRICTED_ALERT:
      return { ...state, currentUserStripeAccountRestricted: false };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const $currentUserStripeAccountRestricted = state => {
  return state.alert.currentUserStripeAccountRestricted;
};

// ================ Action creators ================ //

export const setCurrentUserStripeAccountRestricted = value => ({
  type: SET_CURRENT_USER_STRIPE_ACCOUNT_RESTRICTED,
  payload: value,
});

export const closeAccountRestrictedAlert = () => ({
  type: CLOSE_ACCOUNT_RESTRICTED_ALERT,
});

const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// ================ Thunks ================ //

export const checkUserStripeAccountStatus = isAuthenticated => async (dispatch, state, sdk) => {
  if (!isAuthenticated) {
    return;
  }

  try {
    const res = await sdk.currentUser.show();
    console.log('Response USER ', res);

    const status = get(
      res,
      'data.data.attributes.profile.metadata.stripeConnectedAccountData.accountStatus'
    );

    console.log(
      'Stripe accoint data anf status',
      get(res, 'data.data.attributes.profile.metadata.stripeConnectedAccountData')
    );

    if (status) {
      const liveListingsRes = await sdk.ownListings.query();
      console.log('Adding the data fixes', liveListingsRes);

      const liveListings = liveListingsRes.data.data.filter(
        listing =>
          get(listing, 'attributes.state') === 'published' ||
          get(listing, 'attributes.state') === 'pendingApproval'
      );

      console.log(
        'Listing length',
        liveListings,
        [STRIPE_ACCOUNT_RESTRICTED_STATUS, STRIPE_ACCOUNT_RESTRICTED_SOON_STATUS].includes(status)
      );

      const isRestricted =
        [STRIPE_ACCOUNT_RESTRICTED_STATUS, STRIPE_ACCOUNT_RESTRICTED_SOON_STATUS].includes(
          status
        ) && liveListings.length >= 1;

      dispatch(setCurrentUserStripeAccountRestricted(isRestricted));
    }
  } catch (error) {
    console.error('Error checking user Stripe account status:', error);
    // Optionally dispatch an error action or handle the error appropriately
  }
};

export const closeStripeAccountRestrictedAlert = () => dispatch => {
  dispatch(closeAccountRestrictedAlert());
};
