import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_HOMEPAGE,
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';

// import SectionImages from './SectionImages';
import css from './GrabHostYourCarLandingPage.css';
import SectionHelp from './SectionHelp';
import SectionStories from './SectionStories';
import GrabIntroBanner from './GrabIntroBanner';
import WhyHostWithDrivelah from './WhyHostWithDrivelah';
import HostEarningCalculator from './HostEarningCalculator';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const MAX_MOBILE_SCREEN_WIDTH = 991;

export const GrabHostYourCarLandingPageComponent = props => {
  const { intl, scrollingDisabled, viewport } = props;
  const location = useLocation();
  const history = useHistory();
  const _handleSignup = e => {
    e.preventDefault();
    const { search } = location;
    const { code } = parse(search);
    initiateListACarEventFromTab({
      props,
      eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    });
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
          }
        : {
            pathname: url,
          }
    );
  };
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'Host your car with Drive mate!' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id:
      'Is your car sitting idle? Make your car work for you. Now you can list your car with Drive mate and earn profits from Grab rentals. So just sit back and make money lah!',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const onPushGTMEvent = (event, buttonId) => {
    pushGTMBrowseEvent({
      props,
      event,
      buttonId,
    });
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, []);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {
    console.log('Error', error);
  }

  const onUseRentalCalculator = () => {
    pushGTMBrowseEvent({
      props,
      event: EVENT_BROWSE_USEDRENTALCAL,
    });
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage="SeoLandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <GrabIntroBanner
            handleSignupRedirect={e => _handleSignup(e)}
            isMobileLayout={isMobileLayout}
          />
          <WhyHostWithDrivelah
            isMobileLayout={isMobileLayout}
            handleSignupRedirect={e => _handleSignup(e)}
          />
          <HostEarningCalculator
            onUseRentalCalculator={onUseRentalCalculator}
            onListYourCar={e => _handleSignup(e)}
            listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
          />
          <SectionStories />
          <SectionHelp />
          {/* <SeoLandingRentalCategories /> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, shape } = PropTypes;

GrabHostYourCarLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const GrabHostYourCarLandingPage = compose(
  withViewport,
  injectIntl
)(GrabHostYourCarLandingPageComponent);

export default GrabHostYourCarLandingPage;
