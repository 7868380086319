import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { EditListingInstructionsForm } from '../../forms';

import css from './EditListingInstructionsPanel.css';

const EditListingInstructionsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    submitButtonId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const title = <FormattedMessage id="EditListingInstructionsPanel.panelTitle" />;
  const panelDescription = <FormattedMessage id="EditListingInstructionsPanel.panelDescription" />;

  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <div className={css.titleSection}>
        <h1 className={css.title}>{title}</h1>
        <p>{panelDescription}</p>
      </div>
      <EditListingInstructionsForm
        className={css.form}
        publicData={publicData}
        initialValues={{
          parkingInstructions: publicData.parkingInstructions,
          pickupDropoffInstructions: publicData.pickupDropoffInstructions,
          carGuide: publicData.carGuide,
        }}
        onSubmit={values => {
          const {
            parkingInstructions = '',
            pickupDropoffInstructions = '',
            carGuide = '',
          } = values;

          const updateValues = {
            publicData: {
              parkingInstructions,
              pickupDropoffInstructions,
              carGuide,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        submitButtonId={submitButtonId}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingInstructionsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingInstructionsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

export default EditListingInstructionsPanel;
