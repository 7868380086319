import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { uploadFiles } from '../../util/fileUpload';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, IconSpinner, Modal, PrimaryButton } from '../../components';

import css from './SectionUploadCarStatus.css';

const ACCEPT_TYPE = 'image/*';

/**
 * isPickUp: boolean
 * Show that modal is pickup modal
 * isShowUpload: boolean
 * Show that transaction is show upload or not
 */

class UploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      index: 0,
    };
    this.uploaded = [];
  }

  uploadFirstFile = ({ file, index }, currentUser) => {
    if (file) {
      const { id } = currentUser;
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('userId', id.uuid);
      formData.append('tripPhotos', true);

      return uploadFiles(formData)
        .then(response => {
          if (response.status !== 200) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(data => {
          this.props.setData({ fileUrl: data.directLink });
          this.uploaded[index] = data;
          return data;
        });
    }
  };

  uploadOtherFiles = (files = [], currentUser) => {
    const uploadSingleFile = () => {
      const fileData = files.pop();
      if (!fileData) return Promise.resolve({});
      const { id } = currentUser;
      const { file, index } = fileData;
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('userId', id.uuid);
      formData.append('tripPhotos', true);
      return uploadFiles(formData)
        .then(response => {
          if (response.status !== 200) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(data => {
          this.uploaded[index] = data;
          return uploadSingleFile();
        });
    };

    return Promise.all([
      uploadSingleFile(),
      uploadSingleFile(),
      uploadSingleFile(),
      uploadSingleFile(),
      uploadSingleFile(),
    ]);
  };

  handleUploadFiles = (files = [], currentUser) => {
    if (files.length) {
      this.setState({
        isUploading: true,
      });
      return this.uploadFirstFile(files[0], currentUser)
        .then(() => this.uploadOtherFiles(files.slice(1, files.length), currentUser))
        .then(() => {
          this.setState({
            isUploading: false,
          });
        });
    }
    return Promise.resolve({});
  };

  handleAddPhoto = () => {
    const data = {
      fileUrl: this.props.fileUrl,
      note: this.props.note,
      timestamp: new Date().getTime(),
      isCustomer: this.props.isCustomer,
      isPickUp: this.props.isPickUp,
    };
    this.props.handleAddPhoto(data);
    if (this.state.index + 1 === this.props.files.length) {
      this.props.handleAddPhoto(data);
      this.inputElement.value = null;
      this.props.setData({ fileUrl: null, note: '', file: null });
      this.setState({ index: 0 });
    } else {
      const nextImgFile = this.props.files[this.state.index + 1];
      const nextImg = URL.createObjectURL(nextImgFile);
      this.props.setData({
        fileUrl:
          this.uploaded[this.state.index + 1] && this.uploaded[this.state.index + 1].directLink,
        note: '',
        file: nextImg,
      });
      this.setState({ index: this.state.index + 1 });
    }
  };

  handleRemovePhoto = timestamp => {
    this.props.handleRemovePhoto(timestamp);
  };

  handleClickUpload = () => {
    this.inputElement.click();
  };

  handleUploadPhoto = file => {
    const { currentUser } = this.props;

    if (!file) {
      return;
    }
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('userId', currentUser && currentUser.id && currentUser.id.uuid);
    data.append('tripPhotos', true);
    this.setState({
      isUploading: true,
    });

    uploadFiles(data)
      .then(response => {
        const code = response.status;
        if (code !== 200) {
          return null;
        }
        return response.json();
      })
      .then(jsonResponse => {
        if (!jsonResponse.url) {
          this.setState({
            isUploading: false,
          });
        } else {
          this.props.setData({ fileUrl: jsonResponse.directLink });
          this.setState({
            isUploading: false,
          });
        }
      })
      .catch(e => {
        this.setState({
          isUploading: false,
        });
      });
  };

  handleClickActionButton = id => {
    document.querySelector(`#${id}`).click();
    this.props.handleCloseModal();
  };

  render() {
    const {
      isShowUpload,
      isCustomer,
      isPickUp,
      onManageDisableScrolling,
      setData,
      handleCloseModal,
      isOpenModal,
      files,
      file,
      fileUrl,
      note,
      tripPhotos,
      existedPhotos,
      inputOdometerInvalid,
    } = this.props;

    const titleModal = <FormattedMessage id="SectionUploadCarStatus.titleModal" />;

    const titleYourPhoto = <FormattedMessage id="SectionUploadCarStatus.yourCarStatusPhoto" />;

    const titleCustomerPhoto = (
      <FormattedMessage id="SectionUploadCarStatus.customerCarStatusPhoto" />
    );
    const titleProviderPhoto = (
      <FormattedMessage id="SectionUploadCarStatus.providerCarStatusPhoto" />
    );
    const titleOtherPhoto = isCustomer ? titleProviderPhoto : titleCustomerPhoto;

    const uploadBoxText = <FormattedMessage id="SectionUploadCarStatus.uploadBoxText" />;
    const uploadBoxFormat = <FormattedMessage id="SectionUploadCarStatus.uploadBoxFormat" />;

    const uploadTextLabel = <FormattedMessage id="SectionUploadCarStatus.uploadTextLabel" />;

    const addPhotoBtn = <FormattedMessage id="SectionUploadCarStatus.addPhotoBtn" />;
    const disableAddBtn = !(file && fileUrl);

    const customerTripPhotos = existedPhotos.filter(photo => !!photo.isCustomer);
    const providerTripPhotos = existedPhotos.filter(photo => !photo.isCustomer);
    const showYourPhotos = isCustomer ? customerTripPhotos : providerTripPhotos;
    const hasJustUploadedTripPhotos = tripPhotos.filter(photo =>
      isPickUp ? !!photo.isPickUp : !photo.isPickUp
    );

    const yourPhotos = [...showYourPhotos, ...(isShowUpload ? hasJustUploadedTripPhotos : [])];
    const otherPhotos = [...(isCustomer ? providerTripPhotos : customerTripPhotos)];
    const existActionButton = document.querySelector('#actionTransitButton');

    return (
      <Modal
        id="modalUpload"
        onManageDisableScrolling={onManageDisableScrolling}
        isOpen={isOpenModal}
        onClose={() => {
          handleCloseModal();
        }}
      >
        <div className={css.modalContent}>
          <h2 className={css.modalTitle}>{titleModal}</h2>
          <div className={css.yourPhotos}>
            {isShowUpload && (
              <div className={css.uploadContainer}>
                <div className={css.uploadBox} onClick={this.handleClickUpload}>
                  <div className={css.uploadBoxInner}>
                    {file && <img src={file} className={css.imageFromFileWrapper} />}
                    {file && this.state.isUploading && (
                      <div className={css.uploadLoading}>
                        <IconSpinner />
                      </div>
                    )}
                    {!file && (
                      <div className={css.uploadBoxContent}>
                        <div className={css.uploadBoxText}>{uploadBoxText}</div>
                        <div className={css.uploadBoxFormat}>{uploadBoxFormat}</div>
                      </div>
                    )}
                  </div>
                </div>
                <input
                  accept={ACCEPT_TYPE}
                  className={css.inputField}
                  type="file"
                  name="uploadPhotoStatus"
                  id="uploadPhotoStatus"
                  multiple={true}
                  onChange={e => {
                    if (e.target.files[0]) {
                      // this.handleUploadPhoto(e.target.files[0])
                      const files = Array.from(e.target.files).map((file, index) => ({
                        index,
                        file,
                      }));
                      this.handleUploadFiles(files, this.props.currentUser);
                      setData({
                        file: URL.createObjectURL(e.target.files[0]),
                        files: e.target.files,
                      });
                    }
                  }}
                  ref={input => (this.inputElement = input)}
                />
                <div className={css.uploadInputNote}>
                  <label className={css.inputTextLabel}>{uploadTextLabel}</label>
                  <input
                    type="text"
                    className={css.inputText}
                    id="noteCarPhotoStatus"
                    name="noteCarPhotoStatus"
                    onChange={e => setData({ note: e.target.value })}
                    value={note}
                    ref={input => (this.inputNote = input)}
                  />
                  <Button
                    type="button"
                    inProgress={this.state.isUploading}
                    disabled={this.state.isUploading || disableAddBtn}
                    onClick={() => this.handleAddPhoto()}
                    className={css.addPhotoBtn}
                  >
                    {addPhotoBtn}
                    {files && files.length > 0 ? ' ' : ''}
                    {files && files.length > 0 ? `${this.state.index + 1}/${files.length}` : ''}
                  </Button>
                  {file && !this.state.isUploading && (
                    <div className={css.successUpload}>
                      <FormattedMessage id="SectionUploadCarStatus.successfullyUploaded" />
                    </div>
                  )}
                </div>
              </div>
            )}
            {isShowUpload && (
              <PrimaryButton
                className={css.actionTransitButton}
                onClick={() => this.handleClickActionButton('actionTransitButton')}
                disabled={
                  !existActionButton ||
                  inputOdometerInvalid ||
                  (hasJustUploadedTripPhotos.length === 0 && isCustomer)
                }
              >
                {isPickUp && isCustomer && <FormattedMessage id="SectionUploadCarStatus.pickUp" />}
                {isPickUp && !isCustomer && (
                  <FormattedMessage id="SectionUploadCarStatus.confirmPickUp" />
                )}
                {!isPickUp && isCustomer && (
                  <FormattedMessage id="SectionUploadCarStatus.dropOff" />
                )}
                {!isPickUp && !isCustomer && (
                  <FormattedMessage id="SectionUploadCarStatus.confirmDropOff" />
                )}
              </PrimaryButton>
            )}
            <h3 className={css.photosTitle}>{titleYourPhoto}</h3>
            <div className={css.photosContainer}>
              {yourPhotos.length === 0 && (
                <span className={css.noTripPhotos}>
                  <FormattedMessage id="SectionUploadCarStatus.noTripPhotos" />
                </span>
              )}
              {yourPhotos.map(photo => (
                <div className={css.photoWrapper} key={photo.timestamp}>
                  <div className={css.photoWrapperInner}>
                    {photo.isJustUploaded && (
                      <div
                        className={css.removePhotoBtn}
                        onClick={() => this.handleRemovePhoto(photo.timestamp)}
                      >
                        X
                      </div>
                    )}
                    <img
                      src={photo.fileUrl}
                      className={css.tripPhoto}
                      onClick={() => window.open(photo.fileUrl, '_blank')}
                    />
                  </div>
                  <div className={css.photoTime}>
                    {moment(new Date(photo.timestamp)).format('LLL')}
                  </div>
                  <div className={css.photoNote}>{photo.note}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={css.otherPhotos}>
            <h3 className={css.photosTitle}>{titleOtherPhoto}</h3>
            <div className={css.photosContainer}>
              {otherPhotos.length === 0 && (
                <span className={css.noTripPhotos}>
                  <FormattedMessage id="SectionUploadCarStatus.noTripPhotos" />
                </span>
              )}
              {otherPhotos.map(photo => (
                <div className={css.photoWrapper} key={photo.timestamp}>
                  <div className={css.photoWrapperInner}>
                    <img
                      src={photo.fileUrl}
                      className={css.tripPhoto}
                      onClick={() => window.open(photo.fileUrl, '_blank')}
                    />
                  </div>
                  <div className={css.photoTime}>
                    {moment(new Date(photo.timestamp)).format('LLL')}
                  </div>
                  <div className={css.photoNote}>{photo.note}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

class SectionUploadCarStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      contentModal: null,
      files: [],
      file: null,
      fileUrl: '',
      note: '',
    };
  }

  handleUploadPhoto = data => {
    this.props.setTripPhotos([...this.props.tripPhotos, { ...data }]);
  };

  handleRemovePhoto = timestamp => {
    const newTripPhotos = this.props.tripPhotos.filter(photo => photo.timestamp !== timestamp);
    this.props.setTripPhotos([...newTripPhotos]);
  };

  handleOpenModal = () => {
    this.setState({
      isOpenModal: true,
    });
  };

  render() {
    const {
      rootClassName,
      className,
      isCustomer,
      isPickUp,
      isShowUpload,
      intl,
      onManageDisableScrolling,
      currentUser,
      tripPhotos,
      existedPhotos,
      inputOdometerInvalid,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const uploadPhotoText = (
      <FormattedMessage id="SectionUploadCarStatus.uploadPhotoText" values={{ newline: <br /> }} />
    );

    const title = <FormattedMessage id="SectionUploadCarStatus.title" />;

    return (
      <div className={classes}>
        <h2 className={css.title}>{title}</h2>
        <div
          id={'openCarStatusModal'}
          className={css.placeholderPhoto}
          onClick={() => this.handleOpenModal()}
        >
          {uploadPhotoText}
        </div>
        <UploadModal
          isShowUpload={isShowUpload}
          isCustomer={isCustomer}
          isPickUp={isPickUp}
          onManageDisableScrolling={onManageDisableScrolling}
          setData={data => this.setState(data)}
          isOpenModal={this.state.isOpenModal}
          handleCloseModal={() => this.setState({ isOpenModal: false })}
          files={this.state.files}
          file={this.state.file}
          fileUrl={this.state.fileUrl}
          note={this.state.note}
          handleAddPhoto={this.handleUploadPhoto}
          handleRemovePhoto={this.handleRemovePhoto}
          currentUser={currentUser}
          tripPhotos={tripPhotos.map(item => {
            return { ...item, isJustUploaded: true };
          })}
          existedPhotos={existedPhotos}
          inputOdometerInvalid={inputOdometerInvalid}
        />
      </div>
    );
  }
}

SectionUploadCarStatus.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionUploadCarStatus.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionUploadCarStatus;
