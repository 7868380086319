const CLOUDINARY_BASE_URL = 'https://dv0eqz2t0y9gj.cloudfront.net';

const pdfSource = {
    InsurancePanel: {
        insurancePdf: `${CLOUDINARY_BASE_URL}/drivemate/pdf/InsurancePanel/insurancePdf.pdf`,
        insurancePdfOldCar: `${CLOUDINARY_BASE_URL}/drivemate/pdf/InsurancePanel/insurancePdf3rdParty.pdf`
    },
    InsurancePanelNewLongTerm: {
        insurancePdf: `${CLOUDINARY_BASE_URL}/drivemate/pdf/InsurancePanelNewLongTerm/insurancePdf.pdf`
    },
    StripeBankAccountTokenInputField: {
        pdfFile: `${CLOUDINARY_BASE_URL}/drivemate/pdf/StripeBankAccountTokenInputField/Branch_code_updated.pdf`
    }
}

export default pdfSource