import { get } from 'lodash';
import {
  AVAILABILITY,
  DESCRIPTION,
  EVENT_LISTACAR_COMPLETED_AVAILABILITY,
  EVENT_LISTACAR_COMPLETED_CARDETAILS,
  EVENT_LISTACAR_COMPLETED_CARFEATURES,
  EVENT_LISTACAR_COMPLETED_CARRULES,
  EVENT_LISTACAR_COMPLETED_INSURANCE,
  EVENT_LISTACAR_COMPLETED_LOCATION,
  EVENT_LISTACAR_COMPLETED_NEXT_LEVEL,
  EVENT_LISTACAR_COMPLETED_ONBOARDING,
  EVENT_LISTACAR_COMPLETED_PICTURES,
  EVENT_LISTACAR_COMPLETED_PRICING,
  EVENT_LISTACAR_COMPLETED_SUBSCRIPTION,
  FEATURES,
  INSURANCE,
  LISTACAR_ABOUT_BUTTON_ID,
  LISTACAR_AVAILABILITY_BUTTON_ID,
  LISTACAR_CAR_RULES_BUTTON_ID,
  LISTACAR_FEATURES_BUTTON_ID,
  LISTACAR_INSURANCE_BUTTON_ID,
  LISTACAR_LOCATION_BUTTON_ID,
  LISTACAR_NEXT_LEVEL_BUTTON_ID,
  LISTACAR_ONBOARDING_CALL_BUTTON_ID,
  LISTACAR_PICTURES_BUTTON_ID,
  LISTACAR_PRICING_BUTTON_ID,
  LISTACAR_SUBSCRIPTION_BUTTON_ID,
  LOCATION,
  NEXT_LEVEL,
  ONBOARDING_CALL,
  PHOTOS,
  POLICY,
  PRICING,
  SUBSCRIPTION,
} from './gtmConstants';
import * as translationEN from '../../translations/en.json';
import {
  getCurrentUser,
  getUserInformationGTM,
  setUserInformationGTM,
} from '../browserStorageHelper';
import { drivelahApiGet } from '../apiHelper';
import { getPUSHTELLResultPage } from '../emailNotify';

export const createRawPropertiesForGTM = ({
  props,
  pageName,
  button,
  listing,
  transaction = {},
  trip = {},
  search = {},
  pageURL,
}) => {
  const { currentUser, listing: listingProp, location = {} } = props;
  const page = {
    url: pageURL ? pageURL : location.pathname,
    name: pageName ? pageName : document.title,
  };
  const listingGTM =
    (get(listing, 'id') && listing) ||
    (get(listingProp, 'id') && listingProp) ||
    get(transaction, 'listing');
  const properties = {
    ui: {
      page,
      button,
    },
    currentUser: currentUser || getCurrentUser(),
    listing: listingGTM,
    transaction,
    trip,
    search,
  };
  return properties;
};

export const createRawPropertiesForGTMPromoLandingPage = ({
  props,
  pageName,
  button,
  listing,
  transaction = {},
  trip = {},
  search = {},
  pageURL,
}) => {
  const { currentUser = null, listing: listingProp, location = {} } = props || {};
  const page = {
    url: pageURL ? pageURL : location.pathname,
    name: pageName ? pageName : document.title,
  };
  const listingGTM =
    (get(listing, 'id') && listing) ||
    (get(listingProp, 'id') && listingProp) ||
    get(transaction, 'listing');
  const properties = {
    ui: {
      page,
      button,
    },
    currentUser: currentUser || getCurrentUser(),
    listing: listingGTM,
    transaction,
    trip,
    search,
  };
  return properties;
};

const parseEmptyInfoNull = data => {
  if (data === undefined) {
    return null;
  }
  return data;
};

const createButtonObject = ({ postition, submitButton, text, buttonId }) => {
  const { top, left } = postition || {};
  const { innerText } = submitButton || {};
  const buttonProperties = {
    text: text || innerText,
    id: buttonId,
    position: {
      left: left,
      top: top,
    },
  };
  return buttonProperties;
};

export const createButtonProperties = button => {
  const { buttonId, text } = button;
  const submitButton = document.getElementById(buttonId);
  const postition = submitButton && submitButton.getBoundingClientRect();
  return createButtonObject({ postition, submitButton, text, buttonId });
};

export const createUIProperties = ui => {
  const { page, button } = ui || {};
  const buttonProperties = button ? createButtonProperties(button) : null;
  return {
    page: page,
    button: buttonProperties,
  };
};

export const createPropertiesForDuplicatedButton = button => {
  const { buttonId: buttonName, text } = button;
  const submitButtonArray = document.getElementsByName(buttonName);
  let submitButton;

  for (let i = 0; i < submitButtonArray.length; i++) {
    if (submitButtonArray[i].offsetWidth > 0) {
      submitButton = submitButtonArray[i];
      break;
    }
  }
  const postition = submitButton && submitButton.getBoundingClientRect();
  return createButtonObject({ postition, submitButton, text, buttonId: buttonName });
};

export const createUIPropertiesForDuplicatedButton = ui => {
  const { page, button } = ui || {};
  const buttonProperties = button ? createPropertiesForDuplicatedButton(button) : null;
  return {
    page: page,
    button: buttonProperties,
  };
};

const getUserInformation = userId => {
  const userInfo = getUserInformationGTM(`USER_INFO_${userId}`);
  return userInfo;
};

const createUserDataObject = data => {
  const { attributes = {} } = data || {};
  const { profile } = attributes || {};
  const { protectedData = {}, metadata = {} } = profile || {};
  const {
    intercomUserStat = {},
    smoveUser,
    smoveUserType,
    smoveEmailSent,
    smoveType3,
    smoveWelcomeEmailOpened,
  } = metadata || {};
  const userId = get(data, 'id.uuid', null);
  const firstName = get(attributes, 'profile.firstName', null);
  const lastName = get(attributes, 'profile.lastName', null);
  const userEmail = get(attributes, 'email', null);
  const { blockNo = '', building = '', city = '', country = '' } = protectedData || {};
  const streetName = get(protectedData, 'location.selectedPlace.address', '');
  const dateOfBirth = protectedData.dateOfBirth;
  const dateOfBirthDate = dateOfBirth
    ? new Date(dateOfBirth.year, dateOfBirth.month, dateOfBirth.day).toISOString()
    : null;
  const newVariant = getPUSHTELLResultPage();
  const userInfo = {
    firstname: firstName || null,
    userEmail: userEmail || null,
    smoveUser: smoveUser || null,
    smoveUserType: smoveUserType || null,
    smoveEmailSent: smoveEmailSent || null,
    smoveType3: smoveType3 || null,
    smoveWelcomeEmailOpened: smoveWelcomeEmailOpened || null,
    AttractivenessScoreExperiment: newVariant,
    lastname: lastName || null,
    fullname: `${firstName} ${lastName}`,
    stripeID: get(data, 'stripeAccount.attributes.stripeAccountId', null),
    flexID: userId,
    hoststatus: parseEmptyInfoNull(intercomUserStat.host_verification_status),
    gueststatus: parseEmptyInfoNull(intercomUserStat.guest_verification_status),
    age: parseEmptyInfoNull(protectedData.age),
    address: `${blockNo} ${streetName} ${building} ${city} ${country}`,
    postalcode: parseEmptyInfoNull(protectedData.postalCode),
    postalDistrict: parseEmptyInfoNull(protectedData.postalDistrict),
    city: parseEmptyInfoNull(protectedData.city),
    country: parseEmptyInfoNull(protectedData.country),
    dateofbirth: dateOfBirthDate,
    photo: data.profileImage ? true : false,
    gender: parseEmptyInfoNull(protectedData.gender),
    phone: parseEmptyInfoNull(protectedData.phoneNumber),
    phoneverified: parseEmptyInfoNull(protectedData.phoneNumberVerified),
    residencyno: parseEmptyInfoNull(protectedData.residencyNumber),
    nricuploaded: intercomUserStat.nric_fin_status ? true : false,
    bookingacceptancerate: parseEmptyInfoNull(intercomUserStat.booking_acceptance_rate),
    responserate: parseEmptyInfoNull(intercomUserStat.booking_response_rate),
    signedupdate: parseEmptyInfoNull(attributes.createdAt),
    approvaldatehost: parseEmptyInfoNull(intercomUserStat.host_verified_date),
    approvaldateguest: parseEmptyInfoNull(intercomUserStat.guest_verified_date),
    status: attributes.banned ? 'banned' : 'opened',
    UTM_source: null,
    UTM_medium: null,
    UTM_campaign: null,
    CPA: null,
    UTM_content: null,
    unsubscribedemail: null,
    appinstalls: null,
    successful_trips_guest_days: parseEmptyInfoNull(
      intercomUserStat.number_of_trips_day_completed_as_guest
    ),
    successful_trips_host: parseEmptyInfoNull(intercomUserStat.number_of_trips_success_as_host),
    successful_trips_host_days: parseEmptyInfoNull(
      intercomUserStat.number_of_trips_day_completed_as_host
    ),
    responsetime: parseEmptyInfoNull(intercomUserStat.average_booking_responded_time_as_host),
    bookingsaccepted_as_host: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_accepted_as_host
    ),
    bookingsaccepted_as_host_days: null,
    bookingaccepted_as_guest: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_accepted_as_guest
    ),
    bookingaccepted_as_guest_days: null,
    bookingsdeclined_as_host: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_declined_as_host
    ),
    bookingsdeclined_as_host_days: null,
    bookingdeclined_as_guest: null,
    bookingdeclined_as_guest_days: null,
    bookingsexpired_as_host: null,
    bookingsexpired_as_host_days: null,
    bookingsexpired_as_guest: null,
    bookingsexpired_as_guest_days: null,
    enquiriesreceived: parseEmptyInfoNull(intercomUserStat.number_of_enquiries_received),
    enquiriessent: parseEmptyInfoNull(intercomUserStat.enquiries_sent),
    messagessent_host: null,
    messagessent_guest: null,
    triphostcancel_host: null,
    triphostcancel_host_days: null,
    triphostcancel_guest: null,
    triphostcancel_guest_days: null,
    tripguestcancel_guest: null,
    tripguestcancel_guest_days: null,
    tripguestcancel_host: null,
    tripguestcancel_host_days: null,
    bookingfailed_guest: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_payment_failed_as_guest
    ),
    bookingfailed_guest_days: null,
    district: null,
    bookingsent_guest: parseEmptyInfoNull(intercomUserStat.booking_requests_sent),
    bookingrecd_host: parseEmptyInfoNull(intercomUserStat.booking_requests_received),
    email_verified: intercomUserStat.email_verification_status ? true : false,
    published_listings: parseEmptyInfoNull(intercomUserStat.number_of_published_listings),
    live_listings: parseEmptyInfoNull(intercomUserStat.number_of_live_listings),
    pending_listings: parseEmptyInfoNull(intercomUserStat.number_of_pending_listings),
    draft_listings: parseEmptyInfoNull(intercomUserStat.number_of_draft_listings),
    image: data.profileImage ? true : false,
    ID: userId ? true : false,
    Driving_license: intercomUserStat.driving_license_status ? true : false,
    referralcodeused: parseEmptyInfoNull(protectedData.referralCode),
    uniquereferralcode: userId,
    credits: parseEmptyInfoNull(metadata.creditBalance),
    intercomtags: intercomUserStat ? true : false,
    fbprofile: null,
    instaprofile: null,
    twitterprofile: null,
    linkedinprofile: null,
    browser: null,
    websessions: null,
    firstseen: null,
    lastseen: null,
    lastheardfrom: null,
    lastopenedemail: null,
    averagerating: null,
    numberofreviews: null,
  };
  if (userId) {
    setUserInformationGTM(`USER_INFO_${userId}`, userInfo);
  }
  return userInfo;
};

export const createUserHostGuestProperties = async (
  currentUser,
  transaction,
  userDiffActionTaker
) => {
  if (!currentUser) {
    return {};
  }
  const userId = currentUser.id && currentUser.id.uuid;
  const verificationKey = get(currentUser, 'attributes.profile.privateData.userVerificationKey');
  const transactionId = transaction.id && transaction.id.uuid;
  let customerId = get(transaction, 'customer.id.uuid');
  const providerId = get(transaction, 'provider.id.uuid');
  let customerInformation = getUserInformation(customerId);
  let providerInformation = getUserInformation(providerId);
  let userIsCustomer =
    (customerId === userId && !userDiffActionTaker) ||
    (customerId !== userId && userDiffActionTaker);

  if (customerInformation && providerInformation) {
    return {
      host: providerInformation,
      guest: customerInformation,
      user: userIsCustomer ? customerInformation : providerInformation,
    };
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/gtm/transaction-user?id=${userId}&uvk=${verificationKey}&transactionId=${transactionId}`
  );

  if (response.ok) {
    const jsonData = await response.json();
    const { data = {} } = jsonData || {};
    const { host = {}, guest = {} } = data || {};
    customerInformation = createUserDataObject(guest);
    providerInformation = createUserDataObject(host);
    customerId = guest.id && guest.id.uuid;
    userIsCustomer =
      (customerId === userId && !userDiffActionTaker) ||
      (customerId !== userId && userDiffActionTaker);
    return {
      host: providerInformation,
      guest: customerInformation,
      user: userIsCustomer ? customerInformation : providerInformation,
    };
  } else {
    console.error(`Error: ${response.status}`);
    return {};
  }
};

export const createHostAndGuestWithoutTransaction = async (currentUser, otherUser) => {
  if (!currentUser || !otherUser) {
    return {};
  }
  const userId = currentUser.id && currentUser.id.uuid;
  const otherUserId = otherUser.id && otherUser.id.uuid;
  const verificationKey = get(currentUser, 'attributes.profile.privateData.userVerificationKey');

  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/gtm/user-host-guest-info?id=${userId}&uvk=${verificationKey}&otherUserId=${otherUserId}`
  );

  if (response.ok) {
    const jsonData = await response.json();
    const { data } = jsonData || {};
    const { user = {}, otherUser = {} } = data || {};
    const currentUserInformation = createUserDataObject(user);
    const otherUserInformation = createUserDataObject(otherUser);

    return {
      user: currentUserInformation,
      otherUser: otherUserInformation,
    };
  } else {
    console.error(`Error: ${response.status}`);
    return {};
  }
};

export const createExperimentDataProperties = async experimentData => {
  if (!experimentData) {
    return {};
  }
  let data = {};
  const experimentName = experimentData.experimentName;
  const variantName = experimentData.variantName;
  let experimentNameUser = 'AttractivenessScoreExperiment' + variantName;

  switch (experimentName) {
    case 'Attractiveness Score Experiment':
      data[experimentNameUser] = variantName;
      return data;
    case 'Home Page':
      data[experimentNameUser] = variantName;
      return data;
    default:
      return {};
  }
};

export const createUserProperties = async currentUser => {
  if (!currentUser) {
    return {};
  }
  const userId = currentUser.id && currentUser.id.uuid;
  const verificationKey = get(currentUser, 'attributes.profile.privateData.userVerificationKey');
  const userInformation = getUserInformation(userId);
  if (userInformation) {
    return {
      user: userInformation,
    };
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/gtm/user?id=${userId}&uvk=${verificationKey}`
    );

    if (response.ok) {
      const jsonData = await response.json();
      const { data } = jsonData || {};
      return {
        user: createUserDataObject(data),
      };
    } else {
      console.error(`Error: ${response.status}`);
      return {};
    }
  } catch (error) {
    console.error(`Error: ${error && error.message ? error.message : error}`);
    return {};
  }
};

export const createListingProperties = listing => {
  if (!listing) {
    return null;
  }
  const { attributes = {}, id = {} } = listing;
  const { publicData = {}, metadata = {}, privateData = {} } = attributes || {};
  const { pricing = {} } = publicData || {};

  return {
    id: id && id.uuid,
    title: attributes.title,
    age: publicData.ageOfCar,
    create_date:
      attributes.createdAt instanceof Date
        ? attributes.createdAt.toISOString()
        : attributes.createdAt,
    approval_date: metadata.liveAt || null,
    status: attributes.state,
    brand_name: publicData.brandName,
    can_drive_to_malaysia: publicData.canDriveToMalaysia,
    model_name: publicData.modelName,
    insurance_type: publicData.insurance,
    company_owned: publicData.companyOwned ? publicData.companyOwned : 'no',
    date_of_registration: publicData.dateOfRegistration,
    kms_driven: publicData.drivenKm,
    engine_capacity: publicData.engineCapacity,
    fuel_type: publicData.fuelType,
    is_drivelah_go: !!metadata.isDrivelahGo,
    is_instantbooking: !!publicData.instantBooking,
    is_PHV: !!publicData.isPHV,
    delivery: publicData.delivery,
    registrationnumber: null,
    mileage_per_day: publicData.millagePerDay,
    chasis_number: privateData.chasisNumber,
    pics: null,
    is_tracking_device:
      privateData.trackingDeviceId && privateData.trackingDeviceId !== 'ADMIN FILL INFORMATION HERE'
        ? true
        : false,
    tracking_device_id: privateData.trackingDeviceId,
    slick_web_id: privateData.slickWebhookId,
    license_plate: publicData.license_plate_number,
    category: publicData.category,
    vehicle_type: publicData.vehicleType,
    successful_trips: publicData.numberTripDone,
    sucessful_trips_days: null,
    views: publicData.views,
    search_results: publicData.searchResults,
    bookings_received: null,
    bookings_received_days: null,
    booking_accepted: null,
    booking_accepted_days: null,
    trip_cancelled_host: null,
    trip_cancelled_host_days: null,
    trip_cancelled_guest: null,
    trip_cancelled_guest_days: null,
    enquiries_recvd: null,
    booking_declined: null,
    booking_declined_days: null,
    last30day_calupdated: null,
    last60day_calupdated: null,
    regularprice: attributes.price && attributes.price.amount,
    peakprice: pricing.peakPrice && pricing.peakPrice.amount,
    weeklydiscount: pricing.discount && pricing.discount.week,
    monthlydiscount: pricing.discount && pricing.discount.month,
    reviews: metadata.reviews && metadata.reviews.averageRating,
    location: publicData.location && publicData.location.address,
  };
};

const createBookingFeesAndPromo = transaction => {
  const { attributes = {} } = transaction;
  const { lineItems = [] } = attributes || {};
  const price = {};
  lineItems.forEach(lineItem => {
    if (lineItem.code === 'line-item/customer-commission') {
      price.tripFees = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/units') {
      price.tripPrice = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/customer-promo') {
      price.totalDiscount = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/young-customer-commission') {
      price.youngDriverFees = lineItem.lineTotal.amount;
    }
  });
  return price;
};

export const createBookingProperties = (transaction = {}, listing) => {
  if (!transaction) {
    return {};
  }
  const bookingFeesAndPromo = createBookingFeesAndPromo(transaction);
  const { booking = {}, attributes: transactionAttributes = {} } = transaction;
  const { attributes = {}, id = {} } = booking || {};
  const { protectedData = {}, payinTotal = {} } = transactionAttributes || {};
  const { code } = protectedData || {};
  const tripDays = get(protectedData, 'days.regular') + get(protectedData, 'days.peak');
  const isDrivelahGo = get(listing, 'attributes.metadata.isDrivelahGo');
  const isInstantBooking = get(listing, 'attributes.publicData.instantBooking');
  const isDelivery = get(listing, 'attributes.publicData.delivery');
  const isExcessReduction = get(transaction, 'attributes.protectedData.isExcessReduction');
  const bookingProperties = {
    pickup_date:
      attributes.start && attributes.start instanceof Date
        ? attributes.start.toISOString()
        : attributes.start,
    pickup_time:
      attributes.displayStart && attributes.displayStart instanceof Date
        ? attributes.displayStart.toISOString()
        : attributes.displayStart,
    dropoff_date:
      attributes.end && attributes.end instanceof Date
        ? attributes.end.toISOString()
        : attributes.end,
    dropoff_time:
      attributes.displayEnd && attributes.displayEnd instanceof Date
        ? attributes.displayEnd.toISOString()
        : attributes.displayEnd,
    promo_credits: code ? false : true,
    guestcredits_value: code ? null : parseEmptyInfoNull(bookingFeesAndPromo.totalDiscount),
    guestpromo_value: code ? parseEmptyInfoNull(bookingFeesAndPromo.totalDiscount) : null,
    totaldiscount: code
      ? protectedData.discount
      : parseEmptyInfoNull(bookingFeesAndPromo.totalDiscount),
    promocode: parseEmptyInfoNull(code),
    tripprice: bookingFeesAndPromo.tripPrice,
    tripfees: bookingFeesAndPromo.tripFees,
    youngdriverfees: parseEmptyInfoNull(bookingFeesAndPromo.youngDriverFees),
    totalprice: payinTotal && payinTotal.amount,
    trip_days: tripDays,
    trip_id: id.uuid,
    instant_booking: !!isInstantBooking,
    DLGo: !!isDrivelahGo,
    vasDelivery: isDelivery ? true : false,
    vas_excess: isExcessReduction ? true : false,
  };
  return bookingProperties;
};

const revertGeolocation = bounds => {
  if (!bounds || !bounds.ne || !bounds.sw) {
    return null;
  }
  const lat = (bounds.ne.lat + bounds.sw.lat) / 2;
  const lng = (bounds.ne.lng + bounds.sw.lng) / 2;
  const options = { params: { latlng: `${lat},${lng}` } };
  const url = 'google/coordinate-to-address';
  return drivelahApiGet(url, options);
};

export const createSearchProperties = async (searchParams, result) => {
  if (!searchParams) {
    return {};
  }
  const { totalItems, totalPages } = result || {};
  const {
    dates,
    keywords,
    meta_isDrivelahGo,
    price,
    pub_brandName,
    pub_canDriveToMalaysia,
    pub_category,
    pub_fuelType,
    pub_delivery,
    pub_isPHV,
    pub_keyFeatures,
    pub_peopleNumberMax,
    pub_transmissions,
    sort,
    locationName,
    hours,
    bounds,
  } = searchParams || {};

  const [startDate, endDate] = (dates && dates.split(',')) || [];
  const [startHour, endHour] = (hours && hours.split(',')) || [];
  const usedSort =
    sort &&
    !(sort.includes('pub_instantBookingSorting') && sort.includes('meta_isDrivelahGoSorting'));
  let location = locationName;
  if (locationName && locationName.toLowerCase() !== 'current location') {
    location = locationName;
  } else {
    const response = await revertGeolocation(bounds);
    location = get(response, 'data.address', null);
  }
  return {
    location,
    pickupdate: startDate,
    pickuptime: startHour,
    dropoffdate: endDate,
    dropofftime: endHour,
    car_category_filter: !!pub_category,
    car_category_value: pub_category,
    price_filter: !!price,
    price_value: price,
    keyword_filter: !!keywords,
    keyword_value: keywords,
    dl_go_filter: !!meta_isDrivelahGo,
    brand_filter: !!pub_brandName,
    brand_value: pub_brandName,
    features_filter: !!pub_keyFeatures,
    features_value: pub_keyFeatures,
    numberofpeople_filter: !!pub_peopleNumberMax,
    numberofpeople_value: pub_peopleNumberMax,
    transmission_filter: !!pub_transmissions,
    transmission_value: pub_transmissions,
    PHV_filter: !!pub_isPHV,
    delivery_filter: !!pub_delivery,
    PHV_value: pub_isPHV,
    malaysia_filter: !!pub_canDriveToMalaysia,
    malaysia_value: pub_canDriveToMalaysia,
    fuel_filter: !!pub_fuelType,
    fuel_value: pub_fuelType,
    numberofresults: totalItems,
    numberofpagesseen: totalPages,
    used_sort: usedSort,
  };
};

export const getEventAndButtonFromTab = tab => {
  switch (tab) {
    case LOCATION:
      return [
        EVENT_LISTACAR_COMPLETED_LOCATION,
        LISTACAR_LOCATION_BUTTON_ID,
        translationEN['EditListingWizard.saveNewLocation'],
      ];
    case DESCRIPTION:
      return [
        EVENT_LISTACAR_COMPLETED_CARDETAILS,
        LISTACAR_ABOUT_BUTTON_ID,
        translationEN['EditListingWizard.saveNewDescription'],
      ];
    case FEATURES:
      return [
        EVENT_LISTACAR_COMPLETED_CARFEATURES,
        LISTACAR_FEATURES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewFeatures'],
      ];
    case POLICY:
      return [
        EVENT_LISTACAR_COMPLETED_CARRULES,
        LISTACAR_CAR_RULES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPolicies'],
      ];
    case PRICING:
      return [
        EVENT_LISTACAR_COMPLETED_PRICING,
        LISTACAR_PRICING_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPricing'],
      ];
    case PHOTOS:
      return [
        EVENT_LISTACAR_COMPLETED_PICTURES,
        LISTACAR_PICTURES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPhotos'],
      ];
    case INSURANCE:
      return [
        EVENT_LISTACAR_COMPLETED_INSURANCE,
        LISTACAR_INSURANCE_BUTTON_ID,
        translationEN['EditListingWizard.saveNewInsurance'],
      ];
    case AVAILABILITY:
      return [
        EVENT_LISTACAR_COMPLETED_AVAILABILITY,
        LISTACAR_AVAILABILITY_BUTTON_ID,
        translationEN['EditListingWizard.saveNewAvailability'],
      ];
    case SUBSCRIPTION:
      return [
        EVENT_LISTACAR_COMPLETED_SUBSCRIPTION,
        LISTACAR_SUBSCRIPTION_BUTTON_ID,
        translationEN['EditListingWizard.saveNewSubscription'],
      ];
    case ONBOARDING_CALL:
      return [
        EVENT_LISTACAR_COMPLETED_ONBOARDING,
        LISTACAR_ONBOARDING_CALL_BUTTON_ID,
        translationEN['EditListingWizard.saveNewOnboardingCall'],
      ];
    case NEXT_LEVEL:
      return [
        EVENT_LISTACAR_COMPLETED_NEXT_LEVEL,
        LISTACAR_NEXT_LEVEL_BUTTON_ID,
        translationEN['EditListingWizard.saveNewNextLevel'],
      ];
    default:
      return [];
  }
};

const createTripFees = transaction => {
  const { attributes = {} } = transaction;
  const { lineItems = [] } = attributes || {};
  const price = {
    totalPrice: get(attributes, 'payinTotal.amount'),
  };
  lineItems.forEach(lineItem => {
    if (lineItem.code === 'line-item/customer-commission') {
      price.tripFees = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/young-customer-commission') {
      price.youngDriverFees = lineItem.lineTotal.amount;
    }
  });
  return price;
};

export const createTripProperties = transaction => {
  if (!transaction) {
    return {};
  }
  const { booking = {}, attributes: transactionAttributes = {}, listing } = transaction;
  const { attributes = {}, id = {} } = booking || {};
  const { protectedData = {} } = transactionAttributes || {};
  const tripDays = get(protectedData, 'days.regular') + get(protectedData, 'days.peak');
  const hostWillEarn = get(protectedData, 'hostWillEarn.amount');
  const tripFees = createTripFees(transaction);
  const tripProperties = {
    pickup_date:
      attributes.start && attributes.start instanceof Date
        ? attributes.start.toISOString()
        : attributes.start,
    pickup_time:
      attributes.displayStart && attributes.displayStart instanceof Date
        ? attributes.displayStart.toISOString()
        : attributes.displayStart,
    dropoff_date:
      attributes.end && attributes.end instanceof Date
        ? attributes.end.toISOString()
        : attributes.end,
    dropoff_time:
      attributes.displayEnd && attributes.displayEnd instanceof Date
        ? attributes.displayEnd.toISOString()
        : attributes.displayEnd,
    booking_trip_id: id.uuid,
    noofdays: tripDays,
    host_earnings: hostWillEarn,
    tracking_device_status: null,
    host_credits: null,
    tripfees: tripFees.tripFees,
    youngdriverfees: tripFees.youngDriverFees,
    totalprice: tripFees.totalPrice,
    trip_days: tripDays,
    vas_excess: !!get(transaction, 'attributes.protectedData.isExcessReduction'),
    vasDelivery: !!get(listing, 'attributes.publicData.delivery'),
    instant_booking: !!get(listing, 'attributes.publicData.instantBooking'),
    DLGo: !!get(listing, 'attributes.metadata.isDrivelahGo'),
  };
  return tripProperties;
};
