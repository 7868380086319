import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { composeValidators, nonEmptyArray } from '../../util/validators';
import { AddImages, ValidationError } from '../../components';

import css from './EditListingPhotosForm.css';

const ACCEPT_IMAGES = 'image/*';

export class EditListingPhotosPicturesGalleryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false, isModalOpen: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(file, index) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  openModal = e => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  changeModalVisiblity = value => {
    this.setState({
      isModalOpen: value,
    });
  };

  render() {
    const {
      image,
      intl,
      onRemoveImage,
      chooseImageText,
      imageUploadRequested,
      onImageUploadHandler,
      imageRequiredMessage,
      uploadImageFailed,
      form,
      index,
      imageAngle,
      placeHolderImage,
      removeSelectedImage,
      isImageUploadInprogress,
    } = this.props;

    const reUploadText = (
      <span className={css.chooseImageText}>
        <span className={css.chooseImage}>Change image</span>
      </span>
    );
    // console.log("image", image)
    return (
      <div className={classNames(css.column, css.column6)}>
        <div className={css.customInputLabel}>{imageAngle}</div>
        <div className={css.imagePlaceHolder}>
          <div className={css.imageThumb}>
            <img src={placeHolderImage} alt="" />
          </div>
          <AddImages
            className={css.imagesField}
            image={image}
            index={index}
            thumbnailClassName={css.thumbnail}
            placeHolderImage={placeHolderImage}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            isImageUploadInprogress={isImageUploadInprogress}
            onRemoveImage={onRemoveImage}
            removeSelectedImage={removeSelectedImage}
          >
            {index > 3 ? (
              ''
            ) : (
              <>
                <Field
                  id={`addImage${index}`}
                  name={`addImage${index}`}
                  accept={ACCEPT_IMAGES}
                  form={null}
                  label={image ? reUploadText : chooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                >
                  {fieldprops => {
                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`addImage`, file);
                      form.blur(`addImage`);
                      onImageUploadHandler(file, e.target.name);
                    };
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={css.addImageWrapper}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} />
                          )}
                          <label htmlFor={name} className={css.addImage}>
                            {label}
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </>
            )}
          </AddImages>
        </div>
        {uploadImageFailed}
        {/* <p className={css.tip}>
                    <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
                  </p> */}
      </div>
    );
  }
}

EditListingPhotosPicturesGalleryComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosPicturesGalleryComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  image: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPhotosPicturesGalleryComponent);
