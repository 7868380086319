import imageSource from '../../util/imageSource';

export const TOP_BRANDS = {
  generateRoute: key => {
    return `/car-rental/top-car-rental-brands/${key}-cars-for-rent`;
  },
  values: [
    { key: 'audi', label: 'Audi', image: imageSource.seoLandingPage.cars.audi },
    { key: 'bmw', label: 'BMW', image: imageSource.seoLandingPage.cars.bmw },
    { key: 'honda', label: 'Honda', image: imageSource.seoLandingPage.cars.honda },
    { key: 'hyundai', label: 'Hyundai', image: imageSource.seoLandingPage.cars.hyundai },
    {
      key: 'mercedes-benz',
      label: 'Mercedes Benz',
      image: imageSource.seoLandingPage.cars.mercedez,
    },
    { key: 'mini', label: 'Mini', image: imageSource.seoLandingPage.cars.mini },
    { key: 'mitsubishi', label: 'Mitsubishi', image: imageSource.seoLandingPage.cars.mitsubishi },
    { key: 'porsche', label: 'Porsche', image: imageSource.seoLandingPage.cars.porsche },
    { key: 'toyota', label: 'Toyota', image: imageSource.seoLandingPage.cars.toyota },
    { key: 'volvo', label: 'Volvo', image: imageSource.seoLandingPage.cars.volvo },
    { key: 'mazda', label: 'Mazda', image: imageSource.seoLandingPage.cars.mazda },
  ],
};

export const TOP_LOCATIONS = {
  generateRoute: key => {
    return `/car-rental/singapore-locations/car-rental-in-${key}`;
  },
  values: [
    {
      key: 'bukit-batok',
      label: 'Bukit Batok',
      image: imageSource.seoLandingPage.location.bukitBatok,
    },
    {
      key: 'choa-chu-kang',
      label: 'Choa Chu Kang',
      image: imageSource.seoLandingPage.location.choaChuKang,
    },
    { key: 'punggol', label: 'Punggol', image: imageSource.seoLandingPage.location.punggol },
    { key: 'serangoon', label: 'Serangoon', image: imageSource.seoLandingPage.location.serangoon },
    { key: 'tampines', label: 'Tampines', image: imageSource.seoLandingPage.location.tampines },
    { key: 'woodlands', label: 'Woodlands', image: imageSource.seoLandingPage.location.woodlands },
    { key: 'jurong', label: 'Jurong', image: imageSource.seoLandingPage.location.jurong },
    { key: 'ang-mo-kio', label: 'Ang Mo Kio', image: imageSource.seoLandingPage.location.angMoKoi },
    {
      key: 'bukit-panjang',
      label: 'Bukit Panjang',
      image: imageSource.seoLandingPage.location.bukitPanjang,
    },
    { key: 'sengkang', label: 'Sengkang', image: imageSource.seoLandingPage.location.sengkang },
    { key: 'sembawang', label: 'Sembawang', image: imageSource.seoLandingPage.location.sembawang },
    { key: 'hougang', label: 'Hougang', image: imageSource.seoLandingPage.location.hougang },
    { key: 'yishun', label: 'Yishun', image: imageSource.seoLandingPage.location.yishun },
  ],
};

export const TOP_CATEGORIES = {
  generateRoute: key => {
    return `/car-rental/car-rental-by-segment/${key}-cars-for-rent`;
  },
  values: [
    { key: 'compact', label: 'Compact', image: imageSource.seoLandingPage.segment.compact },
    {
      key: 'standard-sedan',
      label: 'Standard Sedan',
      image: imageSource.seoLandingPage.segment.standardSedan,
    },
    {
      key: 'standard-suv',
      label: 'Standard SUV',
      image: imageSource.seoLandingPage.segment.standardSuv,
    },
    {
      key: 'luxury-sedan',
      label: 'Luxury Sedans',
      image: imageSource.seoLandingPage.segment.luxurySedan,
    },
    { key: 'luxury-suv', label: 'Luxury SUV', image: imageSource.seoLandingPage.segment.luxurySuv },
  ],
};
