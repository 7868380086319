import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { saveAddressDetails } from './AddressDetailsPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { AddressDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './AddressDetailsPage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS } from '../../util/conversions/conversionsConstants';
import { saveContactDetails } from '../ContactDetailsPage/ContactDetailsPage.duck';

const { LatLng } = sdkTypes;

export const AddressDetailsPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    onSubmitAddressDetailsForm,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,

    connectCalendarTabVisibility,
    fetchCalendarVisibility,
  } = props;

  useEffect(() => {
    fetchCalendarVisibility();
  }, []);

  const dispatch = useDispatch();
  const formSubmitRef = useRef(null);
  const contactDetailsFormSubmitRef = useRef(null);
  const [validAddress, setValidAddress] = useState(false);

  let tabs = [...config.custom.tabs];
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  tabs = tabs
    .map(tab => ({ ...tab }))
    .filter(tab => {
      if (!(ensuredCurrentUser && ensureHostVerified(ensuredCurrentUser))) {
        if (tab.linkProps.name === 'MonthlyStatementPage') {
          return false;
        }
      }
      if (tab.linkProps.name === 'SyncCalenderPage') {
        return Boolean(connectCalendarTabVisibility);
      }
      return true;
    });
  const currentTab = tabs.find(tab => tab.linkProps.name === 'AddressDetailsPage');
  if (currentTab) {
    currentTab.selected = true;
  }

  const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
  console.log('LOG:  ----------------------------------------------------------------------------');
  console.log('LOG:  ~ file: AddressDetailsPage.js ~ line 47 ~ protectedData', protectedData);
  console.log('LOG:  ----------------------------------------------------------------------------');
  const {
    location,
    unitNumber,
    city,
    state,
    postalCode,
    streetNumber,
    suburb,
    dateOfBirth,
  } = protectedData;
  const { selectedPlace } = location || {};
  const { address, origin } = selectedPlace || {};
  const initialValues = {
    dateOfBirth,
    location: {
      search: address,
      selectedPlace: {
        address,
        streetNumber,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    unitNumber,
    streetNumber,
    suburb,
    city,
    state,
    postalCode,
  };

  const onAddressDetailsBtnClick = () => {
    console.log('I am address submmit START ADDRESS DETAILS PAGE');

    if (formSubmitRef.current) {
      console.log('I enter in if formSubmitRef');
      formSubmitRef.current();
    }

    if (contactDetailsFormSubmitRef.current) {
      console.log('I enter in if contactDetailsFormSubmitRef');
      contactDetailsFormSubmitRef.current();
    }

    console.log('I am address submmit END ADDRESS DETAILS PAGE');
  };

  const addressInfoForm = ensuredCurrentUser.id ? (
    <AddressDetailsForm
      className={css.form}
      initialValues={initialValues}
      currentUser={currentUser}
      inProgress={saveAddressDetailsInProgress}
      ready={saveAddressDetailsSuccess}
      saveAddressDetailsError={saveAddressDetailsError}
      setSubmitRef={formSubmitRef}
      contactDetailsFormSubmitRef={contactDetailsFormSubmitRef}
      dispatchingContactForm={values => dispatch(saveContactDetails(values))}
      onSubmit={values => {
        console.log('LOG:  ---------------------------------------------------------------');
        console.log('LOG:  ~ file: AddressDetailsPage.js ~ line 147 ~ values', values);
        console.log('LOG:  ---------------------------------------------------------------');
        const {
          location,
          unitNumber,
          city,
          state,
          postalCode,
          streetNumber,
          suburb,
          dateOfBirth,
        } = values;
        const {
          selectedPlace: { address, origin },
        } = location;
        const updatedValues = {
          dateOfBirth,
          location: {
            search: address,
            selectedPlace: {
              address,
              streetNumber,
              origin: new LatLng(origin.lat, origin.lng),
            },
          },
          unitNumber,
          streetNumber,
          suburb,
          state,
          city,
          postalCode,
        };
        dispatch(saveAddressDetails(updatedValues));
        // onSubmitAddressDetailsForm(updatedValues);
        const properties = createRawPropertiesForGTM({
          props,
        });
        createConversionEvents(
          properties,
          EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS,
          'click'
        );
      }}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'AddressDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="AddressDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="AddressDetailsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={ensuredCurrentUser} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              {/* <FormattedMessage id="AddressDetailsPage.heading" /> */}
            </h1>
            {addressInfoForm}
            <button onClick={onAddressDetailsBtnClick} className={css.buttonContainer}>
              Submit Personal Details
            </button>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

AddressDetailsPageComponent.defaultProps = {
  currentUser: null,
  saveAddressDetailsSuccess: false,
  saveAddressDetailsError: false,
};

const { bool } = PropTypes;

AddressDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const {
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
  } = state.AddressDetailsPage;
  const { connectCalendarTabVisibility } = state.tabPanels;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitAddressDetailsForm: values => dispatch(saveAddressDetails(values)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});

const AddressDetailsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AddressDetailsPageComponent);

AddressDetailsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default AddressDetailsPage;
