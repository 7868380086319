import React, { memo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ImageCarousel, Modal, ResponsiveImage } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import imageSource from '../../util/imageSource';

const SectionImagesComponent = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    onOpenSharingModal,
    viewPhotoButtonId,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const shareButton = listing.id ? (
    <button className={css.shareButton} onClick={onOpenSharingModal}>
      <img className={css.logo} src={imageSource.ShareIcon} />
      <FormattedMessage id="ListingPage.shareButton" />
    </button>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button
      id={viewPhotoButtonId}
      className={css.viewPhotos}
      onClick={e => handleViewPhotosClick(e, true)}
    >
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={[
              'landscape-crop',
              'landscape-crop2x',
              'landscape-crop4x',
              'landscape-crop6x',
            ]}
          />
          <div className={css.bottomActions}>
            {shareButton}
            {viewPhotosButton}
          </div>
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

const SectionImages = memo(
  props => {
    return <SectionImagesComponent {...props} />;
  },
  (prev, next) => {
    return isEqual(
      pick(prev, ['title', 'listing', 'isOwnListing', 'editParams', 'imageCarouselOpen']),
      pick(next, ['title', 'listing', 'isOwnListing', 'editParams', 'imageCarouselOpen'])
    );
  }
);

export default SectionImages;
