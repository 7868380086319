import { UTM_LOCAL_STORAGE_KEY } from './utmConstants';

/**
 * Parse utm labels from the search parameters.
 * @param {URLSearchParams} searchParams Search parameters.
 * @returns {null|Object} Returns available utm labels as object or null if object is empty.
 */
const utmParamsFromUrl = searchParams => {
  const params = {
    ...(searchParams.get('utm_source') && { utm_source: searchParams.get('utm_source') }),
    ...(searchParams.get('utm_medium') && { utm_medium: searchParams.get('utm_medium') }),
    ...(searchParams.get('utm_campaign') && { utm_campaign: searchParams.get('utm_campaign') }),
    ...(searchParams.get('utm_content') && { utm_content: searchParams.get('utm_content') }),
    ...(searchParams.get('utm_name') && { utm_name: searchParams.get('utm_name') }),
    ...(searchParams.get('utm_term') && { utm_term: searchParams.get('utm_term') }),
  };
  return Object.keys(params).length ? params : null;
};

/**
 * Save utm params to the localStorage.
 * @param {URLSearchParams} searchParams Search parameters.
 */
export const saveUtmParams = searchParams => {
  const params = utmParamsFromUrl(searchParams);
  if (params) {
    window.localStorage.setItem(UTM_LOCAL_STORAGE_KEY, JSON.stringify(params));
  }
};

/**
 * Get utm parameters from the localStorage as object.
 * @returns {null|Object}
 */
export const getUtmParams = () => {
  const paramsStr = window.localStorage.getItem(UTM_LOCAL_STORAGE_KEY);
  if (paramsStr) {
    return JSON.parse(paramsStr);
  }
  return null;
};

export const clearUtmParams = () => {
  window.localStorage.removeItem(UTM_LOCAL_STORAGE_KEY);
};
