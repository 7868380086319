import React, { memo } from 'react';
import BLandingCss from './BLandingPage.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

export const Channels = () => (
  <div className={BLandingCss.channelOuter}>
    <div className={BLandingCss.channelsGridRow}>
      <a
        href="https://www.straitstimes.com/singapore/transport/car-not-used-much-rent-it-out-for-cash"
        className={BLandingCss.channelItem}
        target="_blank"
      >
        <LazyImage
          objectFit={'contain'}
          className={BLandingCss.channelItemImage}
          src={imageSource.seoLandingPage.theStraitsTime}
          alt="THE STRAITS TIMES"
        />
      </a>
      <a
        href="https://www.facebook.com/drivemate20/videos/drive-lah-featured-on-channel-news-asia/991990264536564/"
        target="_blank"
        className={BLandingCss.channelItem}
      >
        <LazyImage
          objectFit={'contain'}
          className={BLandingCss.channelItemImage}
          src={imageSource.seoLandingPage.cna}
          alt="CNA"
        />
      </a>
      <a
        href="https://vulcanpost.com/679151/p2p-car-sharing-drive-lah-singapore/"
        target="_blank"
        className={BLandingCss.channelItem}
      >
        <LazyImage
          objectFit={'contain'}
          className={BLandingCss.channelItemImage}
          src={imageSource.seoLandingPage.vulcanPost}
          alt="Vulcan Post"
        />
      </a>
      <a
        href="https://www.zaobao.com.sg/news/singapore/story20191116-1005643"
        className={BLandingCss.channelItem}
        target="_blank"
      >
        <LazyImage
          objectFit={'contain'}
          className={BLandingCss.channelItemImage}
          src={imageSource.seoLandingPage.zaobao}
          alt="ZAOBAO"
        />
      </a>
    </div>
  </div>
);

const ChannelsListings = memo(({ isMobileLayout }) => {
  return (
    <>
      {isMobileLayout && (
        <div className={BLandingCss.insuranceListingWrapper}>
          <div style={{ flex: 1, maxWidth: 174 }}>
            <div className={BLandingCss.insuranceListingText}>Insurance provided by</div>
            <LazyImage
              imgHeight={'auto'}
              imgWidth={'auto'}
              className={BLandingCss.insuranceListingImage}
              src={imageSource.landingPage.insurance}
              alt="Insurance Marine"
            />
          </div>
          <div style={{ flex: '0 0 40%', maxWidth: '40%', padding: '0 0 0 15px' }}>
            <div style={{ marginBottom: 10 }}>
              <div className={BLandingCss.insuranceListingText}>Insurance provided by</div>
              <LazyImage
                imgHeight={'auto'}
                imgWidth={'auto'}
                className={BLandingCss.insuranceListingImage}
                src={imageSource.landingPage.insurance}
                alt="Tokio Marine"
              />
            </div>
            <div>
              <div className={BLandingCss.insuranceListingText}>Approved by</div>
              <LazyImage
                imgHeight={'auto'}
                imgWidth={'auto'}
                className={BLandingCss.approvedByCompanyLogo}
                src={imageSource.landingPage.landTransport}
                alt="Land Transport"
              />
            </div>
          </div>
        </div>
      )}

      <div className={BLandingCss.channelsListings}>
        <Channels />
      </div>
    </>
  );
});

export default ChannelsListings;
