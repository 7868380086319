import React from 'react';
import classNames from 'classnames';

import css from './InsurancePolicy.css';

const InsurancePolicy = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        Liberty Insurance
        <br />
        Full policy
      </div>
      <div className={css.time}>Last updated: October 5th, 2017</div>
      <div className={css.paragraphTitle}>1 Title</div>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
      <div className={css.paragraphTitle}>1.1 Title</div>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
      <div className={css.paragraphTitle}>1.2 Title</div>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
      <div className={css.paragraphTitle}>1.3 Title</div>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
      <div className={css.paragraphTitle}>2 Title</div>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
      <div className={css.paragraphTitle}>2.1 Title</div>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
      <div className={css.paragraphTitle}>2.3 Title</div>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
      <p className={css.paragraph}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero id neque auctor
        tincidunt. Morbi in sapien ac odio vulputate posuere at et turpis. Curabitur tortor sapien,
        tempor in porttitor et, consectetur quis odio. Integer interdum a arcu ut scelerisque.
        Vivamus mollis massa eget nisi auctor lacinia. Proin bibendum nisi nisl, sit amet porta urna
        aliquam vel. Suspendisse tempus scelerisque velit, id bibendum lectus. Aenean velit nulla,
        dignissim sed faucibus at, porttitor vitae nulla. Aliquam tincidunt dapibus pretium.
        Maecenas sed dui tristique, pellentesque ante sed, facilisis metus. Phasellus pulvinar lorem
        ut tempus efficitur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed vitae
        efficitur arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos.
      </p>
    </div>
  );
};

export default InsurancePolicy;
