import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import get from 'lodash/get';
import { FieldCheckbox, Form, PrimaryButton, SavedCardDetails } from '../../components';
import config from '../../config';
import { ensurePaymentMethodCard } from '../../util/data';
import css from './CardVerificationForm.css';
import classNames from 'classnames';
import tripCss from './../../components/TripPanel/TripPanel.css';

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src:
        'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    borderBottom: 'solid 2px red',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;
  console.log('Stripe Error: ', stripeError);

  if (!code || !type) {
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${code}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

const OneTimePaymentWithCardElement = props => {
  const { formId, handleStripeElementRef, hasCardError, error, label } = props;
  return (
    <React.Fragment>
      <div className={css.card} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      {label ? (
        <div className={css.saveForLaterUse}>
          <FieldCheckbox
            className={css.saveForLaterUseCheckbox}
            textClassName={css.saveForLaterUseLabel}
            id="replaceCurrentCard"
            name="replaceCurrentCard"
            label={label}
            useSuccessColor
          />
        </div>
      ) : null}
      {
        // <div className={css.smallText}>
        //   This payment method will be used for applicable monthly subscription fees
        // </div>
      }
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    currentUser,
    hasCardError,
    error,
    paymentMethod,
    intl,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'PaymentDepositForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        currentUser={currentUser}
        onChange={changePaymentMethod}
      />
      {/* {paymentMethod !== 'replaceCard' ? (
        <div className={css.smallText}>
          This payment method will be used for applicable monthly subscription fees
        </div>
      ) : null} */}
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
        />
      ) : null}
    </React.Fragment>
  );
};

const CardVerificationForm = props => {
  const {
    onSubmit,
    inProgress,
    initiateOrderError,
    defaultPaymentMethod,
    currentUser,
    intl,
    handleGoBack,
    transactInModal,
    onOpenTermAndConditions,
    isDonationPaymentSuccessful,
    CardError,
    setCardError,
  } = props;

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [state, setState] = useState({});
  const [selectAmount, setSelectedAmount] = useState(1);
  const cardContainerRef = useRef(null);
  const cardRef = useRef(null);
  const stripeRef = useRef(null);
  const [isInProgress, setisInProgress] = useState(inProgress);

  useEffect(() => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      const { hasHandledCardPayment, defaultPaymentMethod } = props;
      stripeRef.current = window.Stripe(config.stripe.publishableKey);

      // if (!(hasHandledCardPayment || defaultPaymentMethod )) {
      //   this.initializeStripeElement();
      // }
    }
    return () => {
      if (cardRef.current) {
        cardRef.current.removeEventListener('change', handleCardValueChange);
        cardRef.current.unmount();
      }
    };
  }, []);

  useEffect(() => {
    if (CardError) {
      console.log('Catch test');
      setState(prevState => {
        return {
          ...prevState,
          error: CardError ? stripeErrorTranslation(intl, CardError.error) : null,
        };
      });
    }
  }, [CardError]);

  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
  const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
  const selectedPaymentMethod = getPaymentMethod(paymentMethod, showPaymentMethodSelector);
  console.log('Error Test: ', initiateOrderError);
  const showOnetimePaymentFields = ['onetimeCardPayment', 'replaceCard'].includes(
    selectedPaymentMethod
  );

  const optionOneLabel1 = <FormattedHTMLMessage id={'CardVerificationForm.option1'} />;

  const handleCardValueChange = event => {
    const { intl } = props;
    const { error, complete } = event;

    setState(prevState => {
      return {
        ...prevState,
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
    setCardError(null);

    setisInProgress(false);
  };

  const setupStripe = () => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey && !stripeRef.current) {
      stripeRef.current = window.Stripe(config.stripe.publishableKey);
    }
  };

  const initializeStripeElement = element => {
    if (!cardRef.current) {
      const elements = stripeRef.current.elements(stripeElementsOptions);
      cardRef.current = elements.create('card', { style: cardStyles });
      cardRef.current.mount(element || cardContainerRef.current);
      cardRef.current.addEventListener('change', handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (cardRef.current) {
          if (window.innerWidth < 1024) {
            cardRef.current.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            cardRef.current.update({ style: { base: { fontSize: '24px', lineHeight: '29px' } } });
          }
        }
      });
    }
  };

  const handleStripeElementRef = el => {
    cardContainerRef.current = el;
    // setupStripe();
    if (stripeRef.current && el) {
      initializeStripeElement(el);
    }
  };

  const handleSubmit = values => {
    setisInProgress(true);
    onSubmit({
      ...values,
      card: cardRef.current,
      stripe: stripeRef.current,
      paymentMethod: selectedPaymentMethod,
    });
  };

  const initialValues = {
    replaceCurrentCard: true,
    totalAmount: '1',
    name: get(currentUser, 'attributes.profile.displayName', ''),
  };

  const verifyTitle = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedHTMLMessage id={'CardVerificationForm.title'} />
      </p>
    </div>
  );

  const verifySuccessTitle = <FormattedHTMLMessage id={'CardVerificationForm.success.title'} />;
  const verifySuccessDescription = (
    <FormattedHTMLMessage id={'CardVerificationForm.success.description'} />
  );

  // const donationFormTitle = isDonationPaymentSuccessful ? verifySuccessTitle : verifyTitle;
  // const donationFormDescription = isDonationPaymentSuccessful
  //   ? verifySuccessDescription
  //   : verifyDescription;

  const formClasses = transactInModal ? classNames(css.form, tripCss.modalForm) : css.form;
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit} className={formClasses}>
            <FormSpy
              subscription={{ values: true, active: true }}
              onChange={({ values, active }) => {
                console.log('values', values.totalAmount);
                if (values.totalAmount === '1') {
                  setSelectedAmount(1);
                }
              }}
            />
            <div className={tripCss.titleContainer}>
              <h2 className={tripCss.listingTitle}>{verifyTitle}</h2>
              <div className={css.donationFormWrapper}>
                {!isDonationPaymentSuccessful && currentUser ? (
                  <Fragment>
                    {showPaymentMethodSelector ? (
                      <PaymentMethodSelector
                        currentUser={currentUser}
                        defaultPaymentMethod={ensuredDefaultPaymentMethod}
                        changePaymentMethod={v => setPaymentMethod(v)}
                        handleStripeElementRef={handleStripeElementRef}
                        paymentMethod={selectedPaymentMethod}
                        intl={intl}
                        formId="paymentDepositForm"
                      />
                    ) : (
                      <React.Fragment>
                        <p className={css.verificationIndication}>
                          <FormattedMessage id="StripePaymentForm.paymentHeading" />
                        </p>
                        <OneTimePaymentWithCardElement
                          handleStripeElementRef={handleStripeElementRef}
                          intl={intl}
                          formId="paymentDepositForm"
                        />
                      </React.Fragment>
                    )}
                    {CardError && <div className={css.error}>{state.error}</div>}
                    {
                      <div className={css.info}>
                        <p>
                          <FormattedMessage id="PaymentDepositForm.info" />
                        </p>
                      </div>
                    }
                  </Fragment>
                ) : null}
                {!isDonationPaymentSuccessful && (
                  <div className={css.continueVerificationButton}>
                    <PrimaryButton
                      type="submit"
                      inProgress={!CardError && isInProgress}
                      disabled={CardError || (showOnetimePaymentFields && !state.cardValueValid)}
                    >
                      <FormattedMessage id="VerificationStatusIndication.completeVerification" />
                    </PrimaryButton>
                  </div>
                )}
              </div>
            </div>
            {/* {initiateOrderError && (
              <div className={css.error}>
                <FormattedMessage id="PaymentDepositForm.error" />
              </div>
            )} */}
          </Form>
        );
      }}
    />
  );
};

export default CardVerificationForm;
