import React from 'react';
import css from '../EditListingInsurancePanel.css';
import imageSource from '../../../util/imageSource';
const InfoItem = ({ number, description }) => (
  <div className={css.infoItem}>
    <p className={css.numberText}>{number}.</p>
    <div>
      <p className={css.descriptionText}>{description}</p>
    </div>
  </div>
);

const infoItems = [
  { number: '1', description: 'Comprehensive insurance throughout Australia.' },
  {
    number: '2',
    description: 'Covers vehicle damage due to accident, fire, storm, or malicious damage.',
  },
  { number: '3', description: 'Covers theft of the vehicle.' },
  {
    number: '4',
    description: 'The policy applies only to the approved driver during the period of rental.',
  },
];

export const Drivemateprotection = () => {
  return (
    <>
      <h1 className={`${css.title} ${css.boderBottom}`}>Drive mate protection</h1>
      <div className={css.infoList}>
        {infoItems.map(item => (
          <InfoItem key={item.number} number={item.number} description={item.description} />
        ))}
      </div>

      <div className={css.contentWrapper}>
        <p className={css.headerText}>Our insurance partners</p>
        <div className={`${css.modalLogoWrapper} ${css.modalLogo}`}>
          <img src={imageSource.insurancePanel.gtLogo} alt="GT Insurance Logo" />
          <img src={imageSource.insurancePanel.allianz} alt="Allianz Insurance Logo" />
        </div>
      </div>
    </>
  );
};
