import React, { memo } from 'react';
import css from './NewGuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';

const AboutDrivelah = memo(() => {
  return (
    <div className={css.aboutDrivelahSection}>
      <Container>
        <Row justifyContent="center">
          <Column large={6} small={12}>
            <h5 className={css.heading}>About Drive mate</h5>
            <p className={css.desc}>
              {/* We’re all mates here. Don’t grab a ride. Get a car! */}
              Welcome to Drive mate, where we believe in building a community of mates who share
              their cars for a hassle-free and affordable car rental experience.
            </p>
            <p className={css.desc}>
              {/* Drive mate allows customers to borrow cars from neighbours offering up their vehicles on our secure, all-in-one rental app. */}
              Unlike traditional ride-sharing, we offer a unique solution that gives you the freedom
              to rent on your terms. Say goodbye to the frustrations of typical car rental
              processes. Our all-in-one app simplifies the entire journey, allowing you to browse
              and choose from our top rented vehicles in your neighborhood.
            </p>
            <p className={css.desc}>
              {/* Forget ride-share. Get your own wheels sorted and secured for as little as $4/hour or $30/day without the frustrations of typical car rental rigmarole */}
              Drive mate is here to make car rental simple, affordable, and neighborly.
            </p>
            <p className={css.desc}>
              {/* Simply check out our top rented vehicles in your neighbourhood, create an account using the Drive mate app, then go pay your neighbour a visit and pick up the car! */}
            </p>
            {/* <p>This is why we came with this unique platform - Drive mate.</p> */}
          </Column>
        </Row>
        {/* <Row justifyContent="center">
          <Column large={12} small={12}>
            <div className={classNames(css.sectionWithBackground, css.featuredOnSection)}>
              <h5 className={css.heading}>Featured on</h5>
              <div className={css.flexContent}>
                <div>
                  <img src={Times} alt="" />
                </div>
                <div>
                  <img src={Channel} alt="" />
                </div>
                <div>
                  <img src={Vulcan} alt="" />
                </div>
                <div>
                  <img src={Zaobao} alt="" />
                </div>
              </div>
            </div>
          </Column>
        </Row> */}
      </Container>
    </div>
  );
});

export default AboutDrivelah;
