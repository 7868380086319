import React from 'react';
import css from './ListingPageGo.css';

import goLogo from '../../../assets/logos/GoLogo.png';
import goLogo2x from '../../../assets/logos/GoLogo@2x.png';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { IconReviewStar, InlineTextButton } from '../../../components';
import { ensureListing } from '../../../util/data';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    authorDisplayName,
    showContactUser,
    onContactUser,
    currentListing,
    shouldShowPricePerMonth,
    isLongTerm,
    isDisinfected,
  } = props;

  const unitTranslationKey =
    shouldShowPricePerMonth || isLongTerm ? 'ListingPage.perMonth' : 'ListingPage.perDay';

  const averageReview = (averageReviews, doneTrips) => {
    if (!averageReviews || averageReviews === 'N/A') {
      if (!doneTrips) {
        return null;
      }
      return (
        <div className={css.averageContainer}>
          <p>
            <strong>Trips:</strong> {doneTrips}
          </p>
        </div>
      );
    }
    return (
      <div className={css.averageContainer}>
        <div>
          <IconReviewStar rootClassName={css.star} />
          {averageReviews}
        </div>
        <p className={css.doneTrips}>
          | <strong>Trips:</strong> {doneTrips}
        </p>
      </div>
    );
  };

  const isDisinfectedLabel = isDisinfected ? (
    <div class={css.wrapperLabelDisinfected}>
      <div class={css.numberDays}>
        <div className={css.number}> 60 </div>
        <div className={css.day}> days</div>
      </div>
      <div class={css.textLabel}>
        <span> Disinfected </span>
      </div>
    </div>
  ) : null;

  const { author } = ensureListing(currentListing);

  return (
    <div className={css.sectionHeading}>
      <div className={css.logoAndPriceWrapper}>
        <img srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogo} />
        <div className={css.desktopPriceContainer}>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.desktopPerUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
      </div>
      <div className={css.nameWrapper}>
        <div className={css.authorName}>
          <FormattedHTMLMessage
            id="ListingPageGo.SectionHeading.authorName"
            values={{ authorDisplayName }}
          />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
          {isDisinfectedLabel}
        </div>
        <div className={css.listingName}>{richTitle}</div>
        {author && author.id
          ? averageReview(author.attributes.averageReviews, currentListing.attributes.doneTrips)
          : null}
      </div>
    </div>
  );
};

export default SectionHeading;
