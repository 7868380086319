import React, { Suspense } from 'react';
import { LoadingScreen } from '../components';

const LoadingFallback = () => <LoadingScreen />;

const withSuspense = LazyComponent => {
  return props => (
    <Suspense fallback={<LoadingFallback />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default withSuspense;
