import {
  getTransitions,
  getTransitionsToStateFn,
  hasPassedTransition,
  txLastTransition,
} from './transactionLongTermFirst';

export const PROCESS_NAME_LTR_MIDDLE = 'long-term-rental-middle-booking';
export const TRANSITION_TYPE_LTM_CONFIRM_PAYMENT = 'long-term-middle-confirm-payment';
/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const TRANSITION_LTM_REQUEST_PAYMENT_NORMAL_COMMERCIAL =
  'transition/long-term-middle-request-payment-normal-commercial';
export const TRANSITION_LTM_REQUEST_PAYMENT_NORMAL_PRIVATE =
  'transition/long-term-middle-request-payment-normal-private';
export const TRANSITION_LTM_COMPLETE = 'transition/long-term-middle-complete';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_LTM_CREATE_PAYMENT_INTENT =
  'transition/long-term-middle-create-payment-intent';
export const TRANSITION_LTM_CONFIRM_PAYMENT = 'transition/long-term-middle-confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_LTM_DECLINE = 'transition/long-term-middle-decline';
export const TRANSITION_LTM_EXPIRE_PAYMENT = 'transition/long-term-middle-expire-payment';

// Admin can also cancel the transition.
export const TRANSITION_LTM_CUSTOMER_CANCEL = 'transition/long-term-middle-customer-cancel';
export const TRANSITION_LTM_ADMIN_NEED_CANCEL = 'transition/long-term-middle-admin-need-cancel';
export const TRANSITION_LTM_ADMIN_CANCEL_REFUND = 'transition/admin-cancel-refund';
export const TRANSITION_LTM_ADMIN_CANCEL_NON_REFUND = 'transition/admin-cancel-non-refund';
export const TRANSITION_LTM_ADMIN_REFUND = 'transition/admin-refund';

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_DECLINE = 'decline';
const STATE_CREATED_PAYMENT = 'created-payment';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_COMPLETED = 'completed';
const STATE_ADMIN_REVIEW_NEEDED = 'admin-review-needed';
const STATE_WAITING_FOR_REFUND = 'waiting-for-refund';
const STATE_CANCELLED = 'cancelled';

const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'long-term-rental-middle-booking',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_LTM_REQUEST_PAYMENT_NORMAL_COMMERCIAL]: STATE_PENDING_PAYMENT,
        [TRANSITION_LTM_REQUEST_PAYMENT_NORMAL_PRIVATE]: STATE_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_LTM_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_LTM_CREATE_PAYMENT_INTENT]: STATE_CREATED_PAYMENT,
        [TRANSITION_LTM_DECLINE]: STATE_DECLINE,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_CREATED_PAYMENT]: {
      on: {
        [TRANSITION_LTM_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_LTM_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_LTM_CUSTOMER_CANCEL]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTM_ADMIN_NEED_CANCEL]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_ADMIN_REVIEW_NEEDED]: {
      on: {
        [TRANSITION_LTM_ADMIN_CANCEL_NON_REFUND]: STATE_CANCELLED,
        [TRANSITION_LTM_ADMIN_CANCEL_REFUND]: STATE_WAITING_FOR_REFUND,
      },
    },
    [STATE_WAITING_FOR_REFUND]: {
      on: {
        [TRANSITION_LTM_ADMIN_REFUND]: STATE_CANCELLED,
      },
    },
    [STATE_CANCELLED]: {},
    [STATE_COMPLETED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS_LTM = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txIsDeclinedLTM = tx =>
  getTransitionsToState(STATE_DECLINE).includes(txLastTransition(tx));

export const txIsPaymentPendingLTM = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpiredLTM = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequestedLTM = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsCompletedLTM = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsCreatedPaymentIntentLTM = tx =>
  getTransitionsToState(STATE_CREATED_PAYMENT).includes(txLastTransition(tx));

export const txIsAdminReviewNeededLTM = tx =>
  getTransitionsToState(STATE_ADMIN_REVIEW_NEEDED).includes(txLastTransition(tx));

export const txIsWaitingForRefundLTM = tx =>
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx));

export const txIsRefundedLTM = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTM_ADMIN_REFUND;
};

export const txIsCancelledLTM = tx =>
  getTransitionsToState(STATE_ADMIN_REVIEW_NEEDED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx));

export const txIsCancelledTransactionLTM = tx =>
  txIsCancelledLTM(tx) || txIsPaymentExpiredLTM(tx) || txIsDeclinedLTM(tx);
// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransitionLTM = transition => {
  return [
    TRANSITION_LTM_COMPLETE,
    TRANSITION_LTM_CONFIRM_PAYMENT,
    TRANSITION_LTM_EXPIRE_PAYMENT,
    TRANSITION_LTM_CUSTOMER_CANCEL,
    TRANSITION_LTM_ADMIN_NEED_CANCEL,
    TRANSITION_LTM_ADMIN_CANCEL_REFUND,
    TRANSITION_LTM_ADMIN_CANCEL_NON_REFUND,
    TRANSITION_LTM_ADMIN_REFUND,
  ].includes(transition);
};

export const txIsNormalCommercial = tx =>
  hasPassedTransition(TRANSITION_LTM_REQUEST_PAYMENT_NORMAL_COMMERCIAL, tx);
export const txIsNormalPrivate = tx =>
  hasPassedTransition(TRANSITION_LTM_REQUEST_PAYMENT_NORMAL_PRIVATE, tx);
export const txCancelByCustomerLTM = tx => hasPassedTransition(TRANSITION_LTM_CUSTOMER_CANCEL, tx);
