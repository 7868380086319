import get from 'lodash/get';
import { types as sdkTypes } from '../../util/sdkLoader';

import { getMarketplaceEntities, getTxById } from '../../ducks/marketplaceData.duck';
import { REQUEST_STATUS__PENDING } from '../../constants/other';
import * as editTripHelpers from './EditTripPage.helpers';
import { getHoursDiff } from './EditTripPage.helpers';
import * as dateHelpers from '../../util/dates';
import * as selectors from './EditTripPage.selectors';
import moment from 'moment';

const { UUID } = sdkTypes;

export const $bookingTxRef = state => {
  return state.EditTripPage.transactionRef;
};

export const $bookingTx = state => {
  const txRef = $bookingTxRef(state);
  return txRef ? getTxById(state, txRef.id.uuid) : null;
};

export const $tripBookingDates = state => {
  const bookingTx = $bookingTx(state);
  return {
    start: get(bookingTx, 'booking.attributes.displayStart', null),
    end: get(bookingTx, 'booking.attributes.displayEnd', null),
  };
};

export const $tripDates = state => {
  return state.EditTripPage.tripDates;
};

/**
 * Get update booking dates from state.
 * @param {Object} state
 * @returns {{start: (moment|null), end: (moment|null)}}
 */
export const $updateBookingDates = state => {
  return state.EditTripPage.updateBookingDates;
};

/**
 * Get hours diff.
 * @param state
 * @returns {{rhDiff: number, phDiff: number}}
 */
export const $hoursDiff = state => {
  const ubDates = $updateBookingDates(state);
  const tripDates = $tripDates(state);
  const bookingTx = $bookingTx(state);
  return getHoursDiff(
    tripDates.start,
    tripDates.end,
    ubDates.start,
    ubDates.end,
    get(bookingTx, 'listing.attributes.publicData.pricing')
  );
};

export const $isInvalidBookingTime = (state, listingTimeZone) => {
  const ubDates = $updateBookingDates(state);
  const bDates = $tripDates(state);
  return (
    moment(ubDates.start)
      .tz(listingTimeZone)
      .isBefore(moment().tz(listingTimeZone)) &&
    moment(bDates.start)
      .tz(listingTimeZone)
      .isAfter(moment().tz(listingTimeZone))
  );
};

export const $isInvalidInstantBookingStartTime = (state, listingTimeZone) => {
  const ubDates = $updateBookingDates(state);
  const hoursFrom12am = moment(ubDates.start)
    .tz(listingTimeZone)
    .diff(
      moment(ubDates.start)
        .tz(listingTimeZone)
        .clone()
        .startOf('day'),
      'minutes'
    );

  return hoursFrom12am >= 0 && hoursFrom12am <= 7 * 60;
};

export const $isInvalidInstantBookingEndTime = (state, listingTimeZone) => {
  const ubDates = $updateBookingDates(state);
  const tz = 'Australia/Sydney';

  const hoursFrom12am = moment(ubDates.end)
    .tz(listingTimeZone)
    .diff(
      moment(ubDates.end)
        .tz(listingTimeZone)
        .clone()
        .startOf('day'),
      'minutes'
    );

  return hoursFrom12am >= 0 && hoursFrom12am <= 7 * 60;
};

export const $isSameTripAndUpdateDates = state => {
  const tripDates = $tripDates(state);
  const updateDates = $updateBookingDates(state);
  return (
    tripDates.start &&
    tripDates.end &&
    updateDates.start &&
    updateDates.end &&
    dateHelpers.isSameDateTime(tripDates.start, updateDates.start) &&
    dateHelpers.isSameDateTime(tripDates.end, updateDates.end)
  );
};

export const calculateUpdateBookingDays = (rhDiff, phDiff, hoursDiff) => {
  let regular = 0;
  let peak = 0;
  if (rhDiff + phDiff <= 12) {
    if (rhDiff > phDiff) {
      regular = 1;
    } else {
      peak = 1;
    }
  } else {
    const fullHours = hoursDiff.regularHoursCount + hoursDiff.peakHoursCount;
    const fullDays = Math.ceil(fullHours / 24);
    if (fullHours % 24 > 0) {
      regular = Math.ceil(hoursDiff.regularHoursCount / 24);
      peak = Math.ceil(hoursDiff.peakHoursCount / 24);
      if (regular + peak > fullDays) {
        regular = Math.round(hoursDiff.regularHoursCount / 24);
        peak = Math.round(hoursDiff.peakHoursCount / 24);
      }
    } else {
      regular = Math.round(hoursDiff.regularHoursCount / 24);
      peak = Math.round(hoursDiff.peakHoursCount / 24);
    }
  }
  return { regular, peak };
};

export const $shouldMakeEstimateRequest = state => {
  const bookingTx = selectors.$bookingTx(state);
  const hoursDiff = $hoursDiff(state);

  if (!bookingTx) {
    return false;
  }

  const { days, pricing } = bookingTx.attributes.protectedData || {};
  const { regular, peak } = calculateUpdateBookingDays(
    hoursDiff.rhDiff,
    hoursDiff.phDiff,
    hoursDiff
  );
  const initialRegularPayment = days.regular * pricing.regularPrice.amount;
  const initialPeakPayment = days.peak * pricing.peakPrice.amount;
  const bPricing = initialRegularPayment + initialPeakPayment;
  const ubPricing = regular * pricing.regularPrice.amount + peak * pricing.peakPrice.amount;
  const isSameDates = $isSameTripAndUpdateDates(state);
  const ubDurationDays = Math.ceil(hoursDiff.ubDuration / 24);
  const bDurationDays = Math.ceil(hoursDiff.bDuration / 24);

  if (ubPricing < bPricing) {
    return false;
  }

  if (hoursDiff.bDuration > 12 && ubDurationDays <= bDurationDays) {
    return false;
  }

  return !isSameDates && (hoursDiff.phDiff > 0 || hoursDiff.rhDiff > 0);
};

export const $isShowEstimatedBreakdown = state => {
  const estimatedTx = $estimatedTx(state);
  // const shouldRequest = $shouldMakeEstimateRequest(state);
  return estimatedTx && !estimatedTx.attributes.protectedData.hideBreakdown;
};

export const $estimatedTx = state => {
  return state.EditTripPage.estimatedTx;
};

export const $isEstimatedTxPending = state => {
  return state.EditTripPage.estimatedTxRequestStatus === REQUEST_STATUS__PENDING;
};

export const $isUpdateBookingInitiatePending = state => {
  return state.EditTripPage.updateBookingInitiateRequestStatus === REQUEST_STATUS__PENDING;
};

export const $isAcceptUpdateBookingPending = state => {
  return state.EditTripPage.acceptUpdateBookingRequestStatus === REQUEST_STATUS__PENDING;
};

export const $isCancelUpdateBookingPending = state => {
  return state.EditTripPage.cancelUpdateBookingRequestStatus === REQUEST_STATUS__PENDING;
};

export const $estimatedTxError = state => {
  return state.EditTripPage.estimatedTxError;
};

export const $updateBookingInitiateError = state => {
  return state.EditTripPage.updateBookingInitiateError;
};

export const $updateBookingFormError = state => {
  const estimatedTxError = $estimatedTxError(state);
  const updateBookingInitiateError = $updateBookingInitiateError(state);
  const hoursDiff = $hoursDiff(state);

  const [currentTransaction] = getMarketplaceEntities(
    state,
    state.EditTripPage.transactionRef ? [state.EditTripPage.transactionRef] : []
  );
  const isInstantBooking = get(
    currentTransaction,
    'listing.attributes.publicData.instantBooking',
    false
  );
  const listingTimeZone = get(
    currentTransaction,
    'listing.attributes.publicData.listingTimezone',
    'Australia/Sydney'
  );

  const isInvalidStartTime = $isInvalidInstantBookingStartTime(state, listingTimeZone);
  const isInvalidEndTime = $isInvalidInstantBookingEndTime(state, listingTimeZone);
  const invalidBookingTime = $isInvalidBookingTime(state, listingTimeZone);

  let error = estimatedTxError || updateBookingInitiateError;

  if (isInvalidStartTime && isInstantBooking) {
    error = `Instant booking cars can not be picked up from 12am to 7am (car's owner timezone).`;
  } else if (isInvalidEndTime && isInstantBooking) {
    error = `Instant booking cars can not be dropped off from 12am to 7am (car's owner timezone).`;
  } else if (hoursDiff.invalidDiff) {
    error = 'Trip modification requires longer or equal duration compared to original trip.';
  } else if (invalidBookingTime) {
    error = 'Invalid start or end time';
  }
  return error;
};

export const $isSubmitButtonDisable = state => {
  const isEstimateTxPending = $isEstimatedTxPending(state);
  const isUpdateBookingInitiatePending = $isUpdateBookingInitiatePending(state);
  const isSameDates = $isSameTripAndUpdateDates(state);
  const formError = $updateBookingFormError(state);
  return isEstimateTxPending || isUpdateBookingInitiatePending || isSameDates || formError;
};

export const $isSubmitButtonInProgress = state => {
  const isEstimateTxPending = $isEstimatedTxPending(state);
  const isUpdateBookingInitiatePending = $isUpdateBookingInitiatePending(state);
  return isEstimateTxPending || isUpdateBookingInitiatePending;
};

export const $lastBookingUpdateByTxId = (state, id) => {
  if (!id) {
    return null;
  }
  const bookingTx = getTxById(state, id);
  const bookingUpdates = editTripHelpers.getBookingUpdates(bookingTx);
  return bookingUpdates.length ? bookingUpdates[bookingUpdates.length - 1] : null;
};

export const $bookingUpdateTxsByParentTxId = (state, id) => {
  if (!id) {
    return [];
  }
  const bookingTx = getTxById(state, id);
  const updateTxsRefs = editTripHelpers
    .getBookingUpdatesTxsIds(bookingTx)
    .map(txId => ({ type: 'transaction', id: new UUID(txId) }));
  return getMarketplaceEntities(state, updateTxsRefs);
};

export const $cancelAllUpdateBookingTxsInProgress = state => {
  return state.EditTripPage.cancelAllUpdateBookingTxsRequestStatus === REQUEST_STATUS__PENDING;
};

export const $fetchLastUpdateBookingTxInProgress = state => {
  return state.EditTripPage.fetchLastUpdateBookingTxRequestStatus === REQUEST_STATUS__PENDING;
};
