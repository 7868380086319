import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const TermsOfServicePageComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div style={{ color: '#00a3ad' }}>
        <h1>
          <strong>Terms Of Service</strong>
        </h1>
        {/*<p className={css.lastUpdated}>Last updated: 5th February 2019</p> */}
        <div>
          <h2>1. Definitions</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              "Drive Mate" means Drive Lah Australia Pty Ltd trading as Drive Mate, ABN 19 640 421
              496, its successors and assigns or any person acting on behalf of and with the
              authority of Drive lah Australia Pty Ltd.
            </li>
            <li>
              “Customer” means the person/s or any person acting on behalf of and with the authority
              of the Customer requesting Drive Mate to provide the Services as specified in any
              proposal, quotation, order, invoice or other documentation, and:
              <ol className={css.alphabetListing}>
                <li>
                  if there is more than one Customer, is a reference to each Customer jointly and
                  severally; and
                </li>
                <li>
                  if the Customer is a part of a Trust, shall be bound in their capacity as a
                  trustee; and
                </li>
                <li>
                  includes the Customer’s executors, administrators, successors and permitted
                  assigns.
                </li>
              </ol>
            </li>
            <li>
              "Confidential Information” means information of a confidential nature whether oral,
              written or in electronic form including, but
              not limited to, this Contract, either party’s intellectual property, operational
              information, know-how, trade secrets, financial
              and commercial affairs, contracts, client information (including but not limited
              to, <b>"Personal Information"</b> such as: name,
              address, D.O.B, occupation, driver’s license details, electronic contact (email,
              Facebook or Twitter details), medical
              insurance details or next of kin and other contact information (where applicable),
              previous credit applications, credit history)
              and pricing details.
            </li>
            <li>
              "Services" means all Services or Services supplied by Drive Mate to the Customer at
              the Customer’s request from time to time (where the context so permits the terms
              ‘Services’ or ‘Services’ shall be interchangeable for the other).
            </li>
            <li>
              “Price” means the Price payable (plus any GST where applicable) for the Services as
              agreed between Drive Mate and the Customer in accordance with clause 5 below.
            </li>
            <li>
              "GST" means Services and Services Tax (GST) as defined within the "A New Tax System
              (Services and Services Tax) Act 1999".
            </li>
          </ol>
        </div>
        <div>
          <h2>2. Acceptance</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              The Customer is taken to have exclusively accepted and is immediately bound, jointly
              and severally, by these terms and conditions if the Customer places an order for or
              accepts delivery of the Services
            </li>
            <li>
              These terms and conditions may only be amended with the consent of both parties in
              writing, and shall prevail to the extent of any inconsistency with any other document
              or contract between the Customer and Drive Mate.
            </li>
            <li>
              Any advice, recommendation, information, assistance or service provided by Drive Mate
              in relation to Services and Services supplied is given in good faith, is based on
              Drive Mate’s own knowledge and experience and shall be accepted without liability on
              the part of Drive Mate and it shall be the responsibility of the Customer to confirm
              the accuracy and reliability of the same in light of the use to which the Customer
              makes or intends to make of the Services or Services
            </li>
            <li>
              The Customer acknowledges that the supply of Services on credit shall not take effect
              until the Customer has completed a
              credit application with Drive Mate and it has been approved with a credit limit
              established for the account.
            </li>
            <li>
              In the event that the supply of Services request exceeds the Customers credit limit
              and/or the account exceeds the payment
              terms, Drive Mate reserves the right to refuse delivery.
            </li>
            <li>
              The Customer acknowledges and accepts that the supply of Services for accepted orders
              may be subject to availability and
              if, for any reason, Services are not or cease to be available, Drive Mate reserves the
              right to vary the Price with alternative
              Services as per clause 3.2.
            </li>
            <li>
              Electronic signatures shall be deemed to be accepted by either party providing that
              the parties have complied with Section
              14 of the Electronic Transactions (Western Australia) Act 2001 or any other applicable
              provisions of that Act or any Regulations referred to in that Act.
            </li>
          </ol>
        </div>

        <div>
          <h2>3. Pricing</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              Prices quoted for the supply of goods and services exclude GST and any other taxes or
              duties imposed on or in relation to the goods and services. Any such GST and other
              taxes or duties are additionally at the Customer’s account.
            </li>
            <li>
              At Drive Mate’s sole discretion, the Price shall be either:
              <ol className={css.alphabetListing}>
                <li>
                  as indicated on the booking app provided by Drive Mate to the Customer; or
                </li>
                <li>
                  Drive Mate’s quoted price (subject to clause 5.3) which will be valid for the
                  period stated in the quotation, app, or otherwise for a period of seven (7) days.
                </li>
              </ol>
            </li>
            <li>
              If the Customer requests any variation to the Agreement, the seller may increase the
              price to account for the variation.
            </li>
            <li>
              Where there is any change in the costs incurred by Drive Mate in relation to goods or
              services, Drive Mate may vary its price to take into account of any such change, by
              notifying the Customer.
            </li>
            <li>
              At Drive Mate’s sole discretion, a non-refundable deposit may be required.
            </li>
          </ol>
        </div>


        <div>
          <h2>4. Payment</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              The time for payment for the Services is of the essence.
            </li>
            <li>
              At Drive Mate’s sole discretion, the Price shall be either:
              <ol className={css.alphabetListing}>
                <li>
                  on completion of the Services;
                </li>
                <li>
                  within seven (7) days in which a statement is via the platforms supplied through
                  the app;
                </li>
                <li>
                  the date specified on any invoice, app or other form as being the date for
                  payment; or
                </li>
                <li>
                  failing any notice to the contrary, the date which is seven (7) days following the
                  date of any invoice given to the Customer by Drive Mate
                </li>
              </ol>
            </li>
            <li>
              Payment made by credit card will incur a 2% surcharge.
            </li>
            <li>
              The Customer shall not be entitled to set off against, or deduct from the Price, any
              sums owed or claimed to be owed to the Customer by Drive Mate nor to withhold payment
              of any invoice because part of that invoice is in dispute.
            </li>
            <li>
              Prices quoted for supply of goods & services exclude GST and any other taxes or duties
              imposed on or in relation to the goods & services. In addition to the Price the
              Customer must pay to Drive Mate an amount equal to any GST Drive Matemust pay for any
              supply by Drive Mate under this or any other contract for the sale of the Services.
              The Customer must pay GST, without deduction or set off of any other amounts, at the
              same time and on the same basis as the Customer pays the Price. In addition, the
              Customer must pay any other taxes and duties that may be applicable in addition to the
              Price except where they are expressly included in the Price.
            </li>
            <li>
              Payment terms may be revoked or amended at Drive Mate’s sole discretion, immediately
              upon giving the Customer written notice.
            </li>
          </ol>
        </div>

        <div>
          <h2>5. Personal Property Securities Act 2009 ("PPSA")</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              In this clause financing statement, financing change statement, security agreement,
              and security interest has the meaning given to it by the PPSA.
            </li>
            <li>
              Upon assenting to these terms and conditions in writing the Customer acknowledges and
              agrees that these terms and conditions constitute a security agreement for the
              purposes of the PPSA and creates a security interest in all Services and/or collateral
              (account) – being a monetary obligation of the Customer to Drive Mate for Services –
              that have previously been supplied and that will be supplied in the future by Drive
              Mate to the Customer.
            </li>
            <li>
              The Customer undertakes to:
              <ol className={css.alphabetListing}>
                <li>
                  promptly sign any further documents and/or provide any further information (such
                  information to be complete, accurate and up-to-date in all respects) which Drive
                  Mate may reasonably require to;
                  <ol className={css.romanListing}>
                    <li>
                      register a financing statement or financing change statement in relation to a
                      security interest on the Personal Property Securities Register;
                    </li>
                    <li>
                      register any other document required to be registered by the PPSA; or
                    </li>
                    <li>
                      correct a defect in a statement referred to in clause 5.3(a)(i) or 5.3(a)(ii);
                    </li>
                  </ol>
                </li>
                <li>
                  indemnify, and upon demand reimburse, Drive Mate for all expenses incurred in
                  registering a financing statement or financing change statement on the Personal
                  Property Securities Register established by the PPSA or releasing any Services
                  charged thereby;
                </li>
                <li>
                  not register a financing change statement in respect of a security interest
                  without the prior written consent of Drive Mate
                </li>
                <li>
                  not register, or permit to be registered, a financing statement or a financing
                  change statement in relation to the Services and/or collateral (account) in favour
                  of a third party without the prior written consent of Drive Mate;
                </li>
                <li>
                  immediately advise Drive Mate of any material change in its business practices of
                  selling the Services which would result in a change in the nature of proceeds
                  derived from such sales.
                </li>
              </ol>
            </li>
            <li>
              The Drive Mate and the Customer agree that sections 96, 115 and 125 of the PPSA do not
              apply to thesecurity agreement created by these terms and conditions.
            </li>
            <li>
              The Customer waives their rights to receive notices under sections 95, 118, 121(4),
              130, 132(3)(d) and 132(4) of the PPSA.
            </li>
            <li>
              The Customer waives their rights as a grantor and/or a debtor under sections 142 and
              143 of the PPSA.
            </li>
            <li>
              Unless otherwise agreed to in writing by Drive Mate, the Customer waives their right
              to receive a verification statement
              in accordance with section 157 of the PPSA.
            </li>
            <li>
              The Customer must unconditionally ratify any actions taken by Drive Mate under clauses
              5.3 to 5.5.
            </li>
            <li>
              Subject to any express provisions to the contrary (including those contained in this
              clause 5) nothing in these terms and conditions is intended to have the effect of
              contracting out of any of the provisions of the PPSA.
            </li>
          </ol>
        </div>


        <div>
          <h2>6. Collateral & Assignment</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              The Customer hereby charges all its right, title and interest in the property or
              properties referred to in the Customer’s Credit Application and also any property or
              properties that it owns currently or may acquire in the future solely or jointly or
              have or become to have a beneficial interest in, in favour of Drive Mate, with the due
              and punctual observance and performance of all the obligations of the Customer. The
              Customer indemnifies Drive Mate against all expenses and legal costs (on a
              solicitor/own client basis) for preparing, lodging and removing any caveat.
            </li>
            <li>
              The Customer hereby acknowledges that Drive Mate may at its discretion register and
              lodge a caveat(s) on such property or properties in respect of the interests conferred
              on it under clause 9.1. Such registration of a caveat by Drive Mate over the
              Customer’s property or properties must not be challenged by the Customer in any way
              whatsoever, and the Customer agrees not to take any steps in filing a “lapsing notice”
              via the Land Titles Office to have the caveat removed, until such time that the
              Customer has paid all monies owing by it to Drive Mate as claimed from time to time.
            </li>
          </ol>
        </div>


        <div>
          <h2>7. Defects, Warranties and Returns, Competition and Consumer Act 2010 (CCA)</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              The Customer must inspect the Vehicle on arrival and must immediately notify Drive
              Mate in writing of any evident defect/damage, or failure to comply with the
              description or as outlined in the Hire Terms of Use. The Customer must notify any
              other alleged defect in the Vehicle as soon as reasonably possible after any such
              defect becomes evident. Upon such notification the Customer must allow Drive Mate to
              inspect the Vehicle.
            </li>
            <li>
              Under applicable State, Territory and Commonwealth Law (including, without limitation
              the CCA), certain statutory implied guarantees and warranties (including, without
              limitation the statutory guarantees under the CCA) may be implied into these terms and
              conditions <b>(Non-Excluded Guarantees)</b>.
            </li>
            <li>
              Drive Mate acknowledges that nothing in these terms and conditions purports to modify
              or exclude the Non-Excluded Guarantees.
            </li>
            <li>
              If the Customer is a consumer within the meaning of the CCA, Drive Mate’s liability is
              limited to the extent permitted by section 64A of Schedule 2.
            </li>
            <li>
              If Drive Mate is required to replace the Services under this clause or the CCA, but is
              unable to do so, Drive Mate may refund any money the Customer has paid for the
              Services.
            </li>
          </ol>
        </div>

        <div>
          <h2>8. Default and Consequences of Default</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              If the Customer defaults in payment by the due date of any amount payable to Drive
              Mate, then all money which would become payable by the Customer to Drive Mate at a
              later date on any account, becomes immediately due and payable without the requirement
              of any notice to the Customer, and Drive Mate may, without prejudice to any of its
              other accrued or contingent rights:
              <ol className={css.alphabetListing}>
                <li>
                  Interest on overdue invoices shall accrue daily from the date when payment becomes
                  due, until the date of payment, at a rate of two and a half percent (2.5%) per
                  calendar month (and at Drive Mate’s sole discretion such interest shall compound
                  monthly at such a rate) after as well as before any judgment;
                </li>
                <li>
                  charge the Customer a late payment administration fee equal to 10% of the invoice
                  to a maximum of $200 plus GST;
                </li>
                <li>
                  charge the customer for, and the Customer must indemnify Drive Mate from, all
                  costs and expenses (including without limitation all legal costs and expenses)
                  incurred by it resulting from the default or in taking action to enforce
                  compliance with the Agreement or to recover any goods;
                </li>
                <li>
                  cease or suspend supply of any further goods or services to the Customer
                </li>
                <li>
                  by written notice to the Customer, terminate any uncompleted contract with the
                  Customer.
                </li>
              </ol>
            </li>
            <li>
              Clauses 8.1(d) and 8.1(e) may also be relied upon, at Drive Mates option:
              <ol className={css.alphabetListing}>
                <li>
                  where the Customer is a natural person and becomes bankrupt or enters into any
                  scheme of arrangement or anyassignment or composition with or for the benefit of
                  his or her creditors or any class of his or her creditors generally; or
                </li>
                <li>
                  where the Customer is a corporation and, it enters into any scheme of arrangement
                  or any assignment or composition with or for the benefit of its creditors or any
                  class of its creditors generally, or has a liquidator, administrator, receiver or
                  manager or similar functionary appointed in respect of its assets, or any action
                  is taken for, or with the view to, the liquidation (including provisional
                  liquidation), winding up or dissolution without winding up of the Customer.
                </li>
              </ol>
            </li>
          </ol>
        </div>


        <div>
          <h2>9. Cancellation</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              Without prejudice to any other remedies Drive Mate may have, if at any time the
              Customer is in breach of any obligation (including those relating to payment) under
              these terms and conditions Drive Mate may suspend or terminate the supply of Services
              to the Customer. The Drive Mate will not be liable to the Customer for any loss or
              damage the Customer suffers because Drive Mate has exercised its rights under this
              clause.
            </li>
            <li>
              The Drive Mate may cancel any contract to which these terms and conditions apply or
              cancel delivery of Services at any time before the Services are delivered by giving
              written notice to the Customer. On giving such notice Drive Mate shall repay to the
              Customer any money paid by the Customer for the Services. The Drive Mate shall not be
              liable for any loss or damage whatsoever arising from such cancellation.
            </li>
            <li>
              In the event that the Customer cancels delivery of Services the Customer shall be
              liable for any and all loss incurred (whether direct or indirect) by Drive Mate as a
              direct result of the cancellation (including, but not limited to, any loss of
              profits).
            </li>
            <li>
              All cancellation terms and conditions are outlined in the Hire Terms of Use that are
              supplied with this document.
            </li>
          </ol>
        </div>


        <div>
          <h2>10. Change In Control</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              The Customer shall give the supplier not less than fourteen (14) days prior written
              notice of any proposed change of ownership of the Customer and/or any other change in
              the Customer’s details (including but not limited to, changes in the Customer’s name,
              address, contact phone or, change of trustees, or business practice). The Customer
              shall be liable for any loss incurred by the supplier as a result of the Customer’s
              failure to comply with this clause.
            </li>
          </ol>
        </div>


        <div>
          <h2>11. Privacy</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              All emails, documents, images or other recorded information held or used by the
              Contractor is Personal Information, as defined and referred to in clause 11.2, and
              therefore considered Confidential Information. The Contractor acknowledges its
              obligation in relation to the handling, use, disclosure and processing of Personal
              Information pursuant to the Privacy Act 1988 (“the Act”) including the Part IIIC of
              the Act being Privacy Amendment (Notifiable Data Breaches) Act 2017 (NDB) and any
              statutory requirements, where relevant in a European Economic Area (“EEA”), under the
              EU Data Privacy Laws (including the General Data Protection Regulation “GDPR”)
              (collectively, “EU Data Privacy Laws”). The Contractor acknowledges that in the event
              it becomes aware of any data breaches and/or disclosure of the Clients Personal
              Information, held by the Contractor that may result in serious harm to the Client, the
              Contractor will notify the Client in accordance with the Act and/or the GDPR. Any
              release of such Personal Information must be in accordance with the Act and the GDPR
              (where relevant) and must be approved by the Client by written consent, unless subject
              to an operation of law.
            </li>
            <li>
              The Customer agrees for Drive Mate to obtain from a credit reporting body (CRB) a
              credit report containing personal credit information (e.g. name, address, D.O.B,
              occupation, previous credit applications, credit history) about the Customer in
              relation to credit provided by Drive Mate.
            </li>
            <li>
              The Customer agrees that Drive Mate may exchange information about the Customer with
              those credit providers and with related body corporates for the following purposes:
              <ol className={css.alphabetListing}>
                <li>
                  to assess an application by the Customer; and/or
                </li>
                <li>
                  to notify other credit providers of a default by the Customer; and/or
                </li>
                <li>
                  to exchange information with other credit providers as to the status of this
                  credit account, where the Customer is in default with other credit providers;
                  and/or
                </li>
                <li>
                  to assess the creditworthiness of the Customer including the Customer’s repayment
                  history in the preceding two (2) years.
                </li>
              </ol>
            </li>
            <li>
              The Customer consents to Drive Mate being given a consumer credit report to collect
              overdue payment in relation to credit that has been supplied.
            </li>
            <li>
              The Customer agrees that personal credit information provided may be used and retained
              by Drive Mate for the following purposes (and for other agreed purposes or required
              by):
              <ol className={css.alphabetListing}>
                <li>
                  the provision of Services; and/or
                </li>
                <li>
                  analysing, verifying and/or checking the Customer’s credit, payment and/or status
                  in relation to the provision of Services; and/or
                </li>
                <li>
                  processing of any payment instructions, direct debit facilities and/or credit
                  facilities requested by the Customer; and/or
                </li>
                <li>
                  enabling the collection of amounts outstanding in relation to the Services.
                </li>
              </ol>
            </li>
            <li>
              The Drive Mate may give information about the Customer to a CRB for the following
              purposes:
              <ol className={css.alphabetListing}>
                <li>
                  to obtain a consumer credit report; allow the CRB to create or maintain a credit
                  information file about the Customer including credit history.
                </li>
              </ol>
            </li>
            <li>
              The information given to the CRB may include:
              <ol className={css.alphabetListing}>
                <li>
                  personal information as outlined in 11.1 above;
                </li>
                <li>
                  name of the credit provider and that Drive Mate is a current credit provider to
                  the Customer;
                </li>
                <li>
                  whether the credit provider is a licensee;
                </li>
                <li>
                  type of consumer credit;
                </li>
                <li>
                  details concerning the Customer’s application for credit or commercial credit
                  (e.g. date of commencement/termination of the credit account and the amount
                  requested);
                </li>
                <li>
                  advice of consumer credit defaults, overdue accounts, loan repayments or
                  outstanding monies which are overdue by more than sixty (60) days and for which
                  written notice for request of payment has been made and debt recovery action
                  commenced or alternatively that the Customer no longer has any overdue accounts
                  and Drive Mate has been paid or otherwise discharged and all details surrounding
                  that discharge (e.g. dates of payments);
                </li>
                <li>
                  information that, in the opinion of Drive Mate, the Customer has committed a
                  serious credit infringement;
                </li>
                <li>
                  advice that the amount of the Customer’s overdue payment is equal to or more than
                  one hundred and fifty dollars ($150).
                </li>
              </ol>
            </li>
            <li>
              The Customer shall have the right to request (by e-mail) from Drive Mate:
              <ol className={css.alphabetListing}>
                <li>
                  a copy of the information about the Customer retained by Drive Mate and the right
                  to request that Drive Mate correct any incorrect information; and
                </li>
                <li>
                  that Drive Mate does not disclose any personal information about the Customer for
                  the purpose of direct marketing.
                </li>
              </ol>
            </li>
            <li>
              The Drive Mate will destroy personal information upon the Customer’s request (by
              e-mail) or if it is no longer required unless it is required in order to fulfill the
              obligations of this contract or is required to be maintained and/or stored in
              accordance with the law.
            </li>
            <li>
              The Customer can make a privacy complaint by contacting Drive Mate via e-mail. The
              Drive Mate will respond to that complaint within seven (7) days of receipt and will
              take all reasonable steps to make a decision as to the complaint within thirty (30)
              days of receipt of the complaint. In the event that the Customer is not satisfied with
              the resolution provided, the Customer can make a complaint to the Information
              Commissioner at <a href='https://www.oaic.gov.au' target='_blank' style={{
              color: 'rgb(0, 163, 173)',
            }}>www.oaic.gov.au</a>
            </li>
          </ol>
        </div>


        <div>
          <h2>12. Trusts</h2>
        </div>
        <div className={css.padLeft}>
          <ul>
            <li>
              If the Customer at any time upon or subsequent to entering in to the Contract is
              acting in the capacity of trustee of any trust (“Trust”) then whether or not Drive Lah
              Australia Pty Ltd trading as Drive Mate may have notice of the Trust, the Customer
              covenants with Drive Lah Australia Pty Ltd trading as Drive Mate as follows:
              <ol className={css.alphabetListing}>
                <li>
                  the Contract extends to all rights of indemnity which the Customer now or
                  subsequently may have against the Trust and the trust fund;
                </li>
                <li>
                  the Customer has full and complete power and authority under the Trust to enter
                  into the Contract and the provisions of the Trust do not purport to exclude or
                  take away the right of indemnity of the Customer against the Trust or the trust
                  fund. The Customer will not release the right of indemnity or commit any breach of
                  trust or be a party to any other action which might prejudice that right of
                  indemnity.
                </li>
                <li>
                  the Customer will not without consent in writing of Drive Lah Australia Pty Ltd
                  trading as Drive Mate will not unreasonably withhold consent), cause, permit, or
                  suffer to happen any of the following events;
                  <ol className={css.romanListing}>
                    <li>
                      the removal, replacement or retirement of the Customer as trustee of the
                      Trust;
                    </li>
                    <li>
                      any alteration to or variation of the terms of the Trust;
                    </li>
                    <li>
                      any advancement or distribution of capital of the Trust; or
                    </li>
                    <li>
                      any resettlement of the trust property.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ul>
        </div>

        
        <div>
          <h2>13. Ownership, use and return of Enabling Equipment.</h2>
        </div>
        <div className={css.padLeft}>
          <ul>
            <li>
                This clause 13 applies to Customers who are "Hosts" as defined in the  <a href='/rental-agreement' target='_blank' style={{
              color: 'rgb(0, 163, 173)',
            }}>Rental 
                Agreement</a> 
              <ol className={css.alphabetListing}>
                <li>
                Drive Mate may supply or otherwise make available to Hosts equipment to facilitate Customers' vehicles registered on the Platform participating in the Services ("Enabling Equipment"), 
                which may include equipment for which Customer has transferred ownership to Drive Mate.
                </li>
                <li>
                The Enabling Equipment may include, amongst other things, one or more of 
                <ol className={css.romanListing}>
                    <li>
                      an Otoplug and associated equipment
                    </li>
                    <li>
                    lockbox and window bracket(s)
                    </li>
                    <li>
                    primary GPS and
                    </li>
                    <li>
                    secondary GPS.
                    </li>
                  </ol>
                </li>
                <li>
                Drive Mate will either arrange for installation of the Enabling Equipment in Customers' vehicles which are registered on the Platform or send the Enabling Equipment to Customers for installation by the Customer or their chosen installer.
                </li>
                <li>
                  Any Enabling Equipment supplied or otherwise made available by Drivemate to a Customer remains Drive Mate’s property at all times.
                </li>
                <li>
                Each Customer must:
                  not, and must not allow any other person to tamper with, interfere with, or remove any part of any Enabling Equipment that is installed in a Customer's vehicle;
                  immediately inform Drive Mate if any part any Enabling Equipment is lost, stolen, damaged, breaks or ceases working properly;
                  remove and return to Drive Mate any Enabling Equipment that is in Customer's possession before selling or transferring the Customer's vehicle to another person, if the Customer's agreement with Drive Mate is terminated or otherwise where Drive Mate reasonably requests the Customer to return it.
                </li>
              </ol>
            </li>
          </ul>
        </div>

        <div>
          <h2>14. Service of Notices</h2>
        </div>
        <div className={css.padLeft}>
          <ul>
            <li>
              Any written notice given under this Contract shall be deemed to have been given and
              received:
              <ol className={css.alphabetListing}>
                <li>
                  by handing the notice to the other party, in person;
                </li>
                <li>
                  by leaving it at the address of the other party as stated in this Contract;
                </li>
                <li>
                  by sending it by registered post to the address of the other party as stated in
                  this Contract;
                </li>
                <li>
                  if sent by facsimile transmission to the fax number of the other party as stated
                  in this Contract (if any), on receipt of confirmation of the transmission;
                </li>
                <li>
                  if sent by email to the other party’s last known email address.
                </li>
                <li>
                  Any notice that is posted shall be deemed to have been served, unless the contrary
                  is shown, at the time when by the ordinary course of post, the notice would have
                  been delivered.
                </li>
              </ol>
            </li>
          </ul>
        </div>


        <div>
          <h2>15. Electronic Payments Act</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              Electronic signatures shall be deemed to be accepted by either party providing that
              the parties have complied with Section 9 of the Electronic Transactions Act 2000
              (NSW), the Electronic Communications Act 2000 (SA), the Electronic Transactions Act
              2001 (ACT), the Electronic Transactions (Victoria) Act 2000, the Electronic
              Transactions (Northern Territory) Act 2000, Section 14 of the Electronic Transactions
              (Queensland) Act 2001, Section 7 of the Electronic Transactions Act 2000 (TAS),
              Section 10 of the Electronic Transactions Act 2011 (WA), (whichever is applicable), or
              any other applicable provisions of that Act or any Regulations referred to in that
              Act.
            </li>
          </ol>
        </div>

        <div>
          <h2>16. General</h2>
        </div>
        <div className={css.padLeft}>
          <ol className={css.romanListing}>
            <li>
              The failure by either party to enforce any provision of these terms and conditions
              shall not be treated as a waiver of that provision, nor shall it affect that party’s
              right to subsequently enforce that provision. If any provision of these terms and
              conditions shall be invalid, void, illegal or unenforceable the validity, existence,
              legality and enforceability of the remaining provisions shall not be affected,
              prejudiced or impaired.
            </li>
            <li>
              These terms and conditions and any contract to which they apply shall be governed by
              the laws of Victoria, the state in which Drive Mate has its principal place of
              business and are subject to the jurisdiction of the courts in Melbourne.
            </li>
            <li>
              Subject to clause 9, Drive Mate shall be under no liability whatsoever to the Customer
              for any indirect and/or consequential loss and/or expense (including loss of profit)
              suffered by the Customer arising out of a breach by Drive Mate of these terms and
              conditions (alternatively Drive Mate’s liability shall be limited to damages which
              under no circumstances shall exceed the Price of the Services).
            </li>
            <li>
              The Drive Mate may licence and/or assign all or any part of its rights and/or
              obligations under this contract without the Customer’s consent.
            </li>
            <li>
              The Customer cannot licence or assign without the written approval of Drive Mate.
            </li>
            <li>
              The Drive Mate may elect to subcontract out any part of the Services but shall not be
              relieved from any liability or obligation under this contract by so doing.
              Furthermore, the Customer agrees and understands that they have no authority to give
              any instruction to any of Drive Mate’s subcontractors without the authority of Drive
              Mate.
            </li>
            <li>
              The Customer agrees that Drive Mate may amend these terms and conditions by notifying
              the Customer in writing. These changes shall be deemed to take effect from the date on
              which the Customer accepts such changes, or otherwise at such time as the Customer
              makes a further request for Drive Mate to provide Services to the Customer.
            </li>
            <li>
              Neither party shall be liable for any default due to any act of God, war, terrorism,
              strike, lockout, industrial action, fire, flood, storm or other event beyond the
              reasonable control of either party.
            </li>
            <li>
              Both parties warrant that they have the power to enter into this contract and have
              obtained all necessary authorisations to allow them to do so, they are not insolvent
              and that this contract creates binding and valid legal obligations on them.
            </li>
          </ol>
        </div>

        <br />
        <br />
        <p>
          <span style={{ color: 'rgb(0, 163, 173)', fontWeight: 700 }}>
            Our privacy policy was last updated on 22th September 2023.{' '}
          </span>
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

TermsOfServicePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const TermsOfService = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(TermsOfServicePageComponent);

export default TermsOfService;
