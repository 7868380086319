import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './HowItWorkPage.css';

const HIWHeroSection = props => {
  const { isAuthenticated, onLoginOrSignupClick } = props;

  return (
    <section className={css.heroSection}>
      <div className={css.heroImage}></div>
      <div className={css.joinUsNowContainer}>
        <div className={css.joinUsNow}>
          <div className={css.joinUsNowText}>
            <h1 className={css.bolderText}>
              <strong>
                <FormattedMessage id={'HowItWorkPage.joinUsNowText1'} />
              </strong>
              <FormattedMessage id={'HowItWorkPage.joinUsNowText2'} />
            </h1>
          </div>
          {!isAuthenticated && (
            <NamedLink
              name="SignupPage"
              className={css.joinUsNowButton}
              onClick={onLoginOrSignupClick}
            >
              <FormattedMessage id="HowItWorkPage.joinUsNowButton" />
            </NamedLink>
          )}
        </div>
      </div>
    </section>
  );
};

export default HIWHeroSection;
