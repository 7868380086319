import React from 'react';
import css from './LandingPageGoHost.css';

import goLogo from '../../assets/logos/GoLogo.png';
import goLogo2x from '../../assets/logos/GoLogo@2x.png';
import { FormattedMessage } from 'react-intl';

const SectionBenefitsHosting = () => {
  return (
    <div className={css.sectionBenefitsHosting}>
      <div className={css.sectionBenefitsHostingContentWrapper}>
        <img srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogo} />
        <div className={css.title}>
          <FormattedMessage id="LandingPageGoHost.SectionBenefitsHosting.title" />
        </div>

        <div className={css.benefites}>
          <div className={css.benefite}>
            <div className={css.title}>
              <FormattedMessage id="LandingPageGoHost.SectionBenefitsHosting.benifites.moreControlTitle" />
            </div>
            <div className={css.description}>
              <FormattedMessage id="LandingPageGoHost.SectionBenefitsHosting.benifites.moreControlDescription" />
            </div>
          </div>
          <div className={css.benefite}>
            <div className={css.title}>
              <FormattedMessage id="LandingPageGoHost.SectionBenefitsHosting.benifites.moreSecurityTitle" />
            </div>
            <div className={css.description}>
              <FormattedMessage id="LandingPageGoHost.SectionBenefitsHosting.benifites.moreSecurityDescription" />
            </div>
          </div>
          <div className={css.benefite}>
            <div className={css.title}>
              <FormattedMessage id="LandingPageGoHost.SectionBenefitsHosting.benifites.moreEfficienctTitle" />
            </div>
            <div className={css.description}>
              <FormattedMessage id="LandingPageGoHost.SectionBenefitsHosting.benifites.moreEfficienctDescription" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionBenefitsHosting;
