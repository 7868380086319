import classNames from 'classnames';
import React from 'react';
import BLandingCss from './BLandingPage.css';
import { NamedLink } from '../../components';
import css from '../LandingPageNew/LandingPageNew.css';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const CheckMark = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-8.925 4.236L13.01 6.3a.516.516 0 000-.73l-.73-.73a.516.516 0 00-.73 0L6.71 9.681l-2.26-2.26a.516.516 0 00-.73 0l-.73.73a.516.516 0 000 .73l3.355 3.355a.516.516 0 00.73 0z"
        fill="#ffcd05"
      />
    </svg>
  );
};
const FlexPlusSectionBanner = () => {
  return (
    <div
      className={classNames(
        // BLandingCss.column12,
        // BLandingCss.gridBox,
        BLandingCss.gridHorizontal
      )}
      style={{ width: '100%', cursor: 'pointer' }}
    >
      <a href="/subscription">
        <div className={BLandingCss.gridBoxInner}>
          <div className={BLandingCss.gridThumb}>
            <LazyImage
              srcSet={`${imageSource.bLanding.flexPlusBanner} 1x, ${imageSource.bLanding.flexPlusBanner2x} 2x`}
              alt=""
            />
            <LazyImage objectFit={'none'}
              className={BLandingCss.overlayImage}
              // src={flexPlusLogo}
              srcSet={`${imageSource.bLanding.flexPlusLogo} 1x, ${imageSource.bLanding.flexPlusLogo2x} 2x`}
              alt=""
              style={{
                width: '183px',
                height: '63px',
                top: '38%',
                left: '24%',
              }}
            />
          </div>
          <div className={BLandingCss.gridContent}>
            <span className={BLandingCss.title}>Next level of car ownership</span>
            <div className="flexible-rental">
              <p>
                Flexible monthly car rentals. Access to subsidised rates. Only with{' '}
                <span>
                  <LazyImage src={imageSource.bLanding.flexIconInText} alt="" />
                </span>
              </p>
            </div>
            <div className={css.contentBox}>
              <div className={css.list} style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={imageSource.drivemateSection.CheckIcon}
                  alt=""
                  style={{ width: '16px', height: '16px' }}
                />
                <p style={{ margin: 0, marginLeft: '8px' }}>No commitments. Cancel anytime*</p>
              </div>
              <div
                className={css.list}
                style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
              >
                <img
                  src={imageSource.drivemateSection.CheckIcon}
                  alt=""
                  style={{ width: '16px', height: '16px' }}
                />
                <p style={{ margin: 0, marginLeft: '8px' }}>Most Affordable</p>
              </div>
              <div
                className={css.list}
                style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
              >
                <img
                  src={imageSource.drivemateSection.CheckIcon}
                  alt=""
                  style={{ width: '16px', height: '16px' }}
                />
                <p style={{ margin: 0, marginLeft: '8px' }}>
                  Share your subscription to access subsidised rates
                </p>
              </div>
            </div>
            <div className={BLandingCss.btnWrapper}>
              <NamedLink name="LandingPageFlex" className={BLandingCss.findMoreButton}>
                Find out more
              </NamedLink>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
export default FlexPlusSectionBanner;
