import React, { useEffect, useState } from 'react';
import * as validators from '../../util/validators';
import { FieldPhoneNumberInput, FieldSelect, FieldTextInput } from '../../components';
import classNames from 'classnames';
import { SignupPhoneExperiment } from '../../util/constants';
import { EVENT_SIGNUP_PHONE_CASE_A, EVENT_SIGNUP_PHONE_CASE_B } from '../../util/gtm/gtmConstants';
import { pushGTMSignupForm } from '../../util/gtm/gtmHelpers';
import config from '../../config';
import css from './SignupForm.css';

const SignUpField2Step = props => {
  const { intl, formId, values, touched, errors, experiment } = props;
  const [selected, setSelected] = useState('+61');
  const [isPhoneCodeSelectReadOnly, setIsPhoneCodeSelectReadOnly] = useState(false);

  useEffect(() => {
    if (config && config.custom && config.custom.phoneCode.length) {
      let country =
        config.maps &&
        config.maps.search &&
        config.maps.search.countryLimit &&
        config.maps.search.countryLimit.length &&
        config.maps.search.countryLimit[0];
      if (country) {
        config.custom.phoneCode.every(function(element) {
          if (element.cc === country) {
            setSelected(element.key);
            return false;
          } else {
            return true;
          }
        });
      }
    }

    // Send phone experiment event based on case A or B
    // if (experiment && experiment === SignupPhoneExperiment.A) {
    //   pushGTMSignupForm({}, EVENT_SIGNUP_PHONE_CASE_A);
    // } else if (experiment && experiment === SignupPhoneExperiment.B) {
    //   pushGTMSignupForm({}, EVENT_SIGNUP_PHONE_CASE_B);
    // }
    // Check if it's prod, disable phone code change if it is
    if (config.isProd) {
      setIsPhoneCodeSelectReadOnly(true);
    }
  }, []);

  const phonePlaceholder = intl.formatMessage({
    id: 'ContactDetailsForm.phonePlaceholder',
  });
  const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });
  const codeLabel = intl.formatMessage({
    id: 'SignupForm.codeLabel',
  });
  const required = validators.required(intl.formatMessage({ id: 'SignupForm.doRequired' }));
  const phoneRequired = validators.required(
    intl.formatMessage({ id: 'ConfirmSignupForm.phoneRequired' })
  );
  const roleRequired = validators.required(
    intl.formatMessage({ id: 'ConfirmSignupForm.roleRequired' })
  );

  return (
    <div>
      {/* {
        experiment === SignupPhoneExperiment.A && <div className={css.phoneNumberFieldWrapper}>
          <label className={css.fieldLabel}>{phoneLabel}</label>
          <div className={css.phoneNumberWrapper}>
            <div>
              <FieldSelect
                id='phoneCode'
                name='phoneCode'
                className={css.phoneCodeSelect}
                defaultValue={selected}
                validate={required}
                // disabled={isPhoneCodeSelectReadOnly} //Disabled since we are already setting default value of phone
              >
                <option value={''} key={'_default_'}>+00</option>
                {config.custom.phoneCode.map((option, index) => (
                  <option value={option.key} key={option.key}>
                    {option.key}
                  </option>
                ))}
              </FieldSelect>
            </div>
            <FieldPhoneNumberInput
              className={classNames(css.formInput, css.noMargin, css.phoneNumberField)}
              rootClassName={css.phoneNumberField}
              name='phoneNumber'
              id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
              placeholder={phonePlaceholder}
              disabled={!values.phoneCode}
              validate={phoneRequired}
            />
          </div>
        </div>
      } */}

      <label className={css.fieldLabel}>What will you be using Drive mate primarily for</label>
      <FieldSelect
        id="role"
        name="role"
        className={css.roleSelect}
        validate={roleRequired}
        defaultValue="renter"
      >
        <option value="" selected={true} disabled={true}>
          {intl.formatMessage({ id: 'SignupForm.rolePlaceholder' })}
        </option>
        {config.custom.roles.map((option, index) => (
          <option value={option.key} key={option.key}>
            {option.label}
          </option>
        ))}
      </FieldSelect>
      <label className={css.fieldLabel}>{codeLabel}</label>
      <FieldTextInput
        className={classNames(css.formInput, css.noMargin)}
        type="text"
        id={formId ? `${formId}.code` : 'code'}
        name="code"
        label={codeLabel}
        placeholder={''}
      />
    </div>
  );
};

SignUpField2Step.defaultProps = {};

SignUpField2Step.propTypes = {};

export default SignUpField2Step;
