/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import Decimal from 'decimal.js';
import isEqual from 'lodash/isEqual';
import { types as sdkTypes } from '../../util/sdkLoader';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
  LINE_ITEMS,
  propTypes,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
} from '../../util/types';
import Explanation from './Explaination';
import { get } from 'lodash';
import moment from 'moment-timezone';
import css from './BookingBreakdown.css';

function getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr
    .map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
}

const LineItemUnknownItemsMaybe = memo(
  props => {
    const { transaction, intl, isTripDetailsPage } = props;
    const ubStart = get(
      transaction,
      'transaction.attributes.protectedData.afterUpdateDates.start',
      null
    );
    const ubEnd = get(
      transaction,
      'transaction.attributes.protectedData.afterUpdateDates.end',
      null
    );
    const { displayStart: start, displayEnd: end } = get(transaction, 'booking.attributes', {
      displayStart: null,
      displayEnd: null,
    });
    const originDiffInHours = start && end && moment(end).diff(moment(start), 'minutes') / 60;
    const diffInHours = ubStart && ubEnd && moment(ubEnd).diff(moment(ubStart), 'hours');
    const quantities =
      diffInHours && (diffInHours > 12 ? Math.ceil(diffInHours / 24) : diffInHours);

    const initialPaymentLineItem =
      transaction &&
      transaction.attributes.lineItems.find(
        item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
      );

    // resolve unknown non-reversal line items
    const rawItems =
      transaction &&
      transaction.attributes.lineItems.filter(item =>
        initialPaymentLineItem && !isTripDetailsPage
          ? [
              LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
              LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
              LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
              LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
              LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
              LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
              LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
              LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
              LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
              LINE_ITEM_INITIAL_PAYMENT,
              ...LINE_ITEMS,
            ].indexOf(item.code) === -1 && !item.reversal
          : [LINE_ITEM_INITIAL_PAYMENT, LINE_ITEM_INITIAL_PAYMENT_PROVIDER, ...LINE_ITEMS].indexOf(
              item.code
            ) === -1 && !item.reversal
      );

    const items = getUnique(rawItems, 'code');

    if (!items) return null;

    const { hourlyBooking, hourDuration } = transaction.attributes.protectedData || {};

    const unitMaybe = hourlyBooking && hourDuration <= 6 ? 'hours' : 'days';
    return items.length > 0 ? (
      <React.Fragment>
        {items.map((item, i) => {
          const label = humanizeLineItemCode(item.code);
          console.log('Trip Price text', label);
          const formattedTotal = formatMoney(intl, item.lineTotal, 1);
          const hasQuantity = !!item.quantity;
          const unitQuantity = hasQuantity
            ? item.quantity instanceof Decimal
              ? item.quantity.toString()
              : item.quantity
            : 0;
          const explainationMaybe =
            item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS ? (
              <Explanation text="Peak day pricing is for select high demand days set by the host." />
            ) : null;
          console.log(
            'XXXXXXX',
            unitMaybe,
            formatMoney(intl, item.unitPrice, 1),
            unitQuantity,
            item
          );
          return (
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>
                {label}{' '}
                {hasQuantity ? (
                  <FormattedMessage
                    id={`LineItemUnknownItemsMaybe.${unitMaybe}.quantity`}
                    values={{
                      unitPrice: formatMoney(intl, item.unitPrice, 1),
                      quantity: unitQuantity,
                    }}
                  />
                ) : null}
                {explainationMaybe}
              </span>

              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          );
        })}
      </React.Fragment>
    ) : null;
  },
  (prev, next) => isEqual(prev.transaction, next.transaction)
);

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
