import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../components';
import { ModalVideo } from './SectionGuideToDisinfectMobile';
import css from './CovidPage.css';
import imageSource from '../../util/imageSource';

const SectionHowToApplyViroQ = props => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={css.sectionHowToApplyViroQ}>
      <div className={css.sectionHowToApplyViroQWrapper}>
        <div className={css.title}>
          <FormattedMessage id="LandingPageCovid.SectionHowToApplyViroQ.title" />
        </div>
        <div className={css.stepWrapper}>
          <div className={css.stepLeft}>
            <div className={css.listStep}>
              <FormattedMessage id="LandingPageCovid.SectionHowToApplyViroQ.step1" />
            </div>
            <div className={css.listStep}>
              <FormattedMessage id="LandingPageCovid.SectionHowToApplyViroQ.step2" />
            </div>
            <div className={css.listStep}>
              <FormattedMessage id="LandingPageCovid.SectionHowToApplyViroQ.step3" />
            </div>
          </div>
          <div className={css.stepRight}>
            <div className={css.listStep}>
              <FormattedMessage id="LandingPageCovid.SectionHowToApplyViroQ.step4" />
            </div>
            <div className={css.listStep}>
              <FormattedMessage id="LandingPageCovid.SectionHowToApplyViroQ.step5" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.viroQProduct}>
        <div className={css.viroQProductTitle}>
          <FormattedMessage id="LandingPageCovid.SectionHowToApplyViroQ.viroQProductTitle" />
        </div>
        <div className={css.videoWrapper}>
          <div onClick={() => setIsOpen(true)} className={css.video}>
            <img className={css.videoLogo} src={imageSource.landingPageCovid.viroQVideoThumbnail} />
          </div>
        </div>
      </div>

      <Modal
        id="videoLandingpage"
        {...props}
        containerClassName={css.modalContainer}
        closeButtonClassName={css.modalCloseButton}
        contentClassName={css.contentModal}
        isOpen={isOpen}
        onManageDisableScrolling={props.onManageDisableScrolling}
        onClose={() => {
          const videoIframe = document.querySelector('#videoIframe');
          if (videoIframe) {
            const iframeSrc = videoIframe.src;
            videoIframe.src = iframeSrc;
          }
          setIsOpen(false);
        }}
      >
        <ModalVideo />
      </Modal>
    </div>
  );
};

export default SectionHowToApplyViroQ;
