import React, { memo } from 'react';
import css from './HostPromoLandingPage.css';
import imageSource from '../../util/imageSource';

const SectionInsurance = memo(() => {
  return (
    <div className={css.insuranceBlock}>
      <div className={css.landingContainer}>
        <div className={css.landingRow}>
          <div className={css.column6}>
            <div className={css.blockTitle}>Insurance? Sorted.</div>
            <p>
              Your car is automatically covered with comprehensive insurance from during trips. Your
              car's original insurance and your no-claim bonus do not get impacted
            </p>
            <a href="#" title="Scope out our policy here!">
              Scope out our policy here!{' '}
              <span>
                <img src={imageSource.hostLanding.arrowRight} alt="" />
              </span>
            </a>
            {/* <div className={css.sectionProvider}>
              <div>
                <div className={css.providerTitle}>Insurance provided by</div>
                <img src={insurance} className={css.insuranceImage} />
              </div>
            </div> */}
          </div>
          <div className={css.column6}>
            <div className={css.blockTitle}>Know Who’s Behind the Wheel</div>
            <p>Drivelah is fully approved by the Land Transport Authority.</p>
            <p>
              Locals driving your car have all been subjected to Drive mate’s comprehensive
              screening process. This includes licensing and driver history checks ensuring only
              genuine borrowers with immaculate records can access your vehicle.
            </p>
            {/* <div className={css.sectionProvider}>
              <div>
                <div className={css.providerTitle}>Approved by</div>
                <img src={landTransport} className={css.landTransport} />
              </div>
            </div> */}
          </div>
          <div className={css.column6}>
            <div className={css.blockTitle}>Superior Security</div>
            <p>
              Our proprietary technology enhances the safety and convenience of sharing your car
              with guests. Our exclusive high-tech in-app security system provides keyless access,
              telemetry data monitoring and remote immobilisation features you won't find offered
              anywhere else.
            </p>
            {/* <div className={css.sectionProvider}>
              <div>
                <div className={css.providerTitle}>Approved by</div>
                <img src={landTransport} className={css.landTransport} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
});

export default SectionInsurance;
