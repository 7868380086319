import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_PROCESSING_FEE_ADDONS,
} from '../../util/types';
import Explanation from './Explaination';
import classNames from 'classnames';
import css from './BookingBreakdown.css';

const LineItemProcessingFee = ({
  transaction,
  isCustomer,
  intl,
  isAddons,
  isTripDetailsPage,
  isExplanationBreakdown,
}) => {
  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item =>
      (isAddons
        ? item.code === LINE_ITEM_PROCESSING_FEE_ADDONS
        : item.code === LINE_ITEM_PROCESSING_FEE) && !item.reversal
  );

  const initialPaymentLineItem =
    transaction &&
    transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
    );

  if (isCustomer && initialPaymentLineItem && !isTripDetailsPage && !isExplanationBreakdown) {
    return null;
  }

  if (!isCustomer || !customerCommissionLineItem) {
    return null;
  }

  const commission = customerCommissionLineItem.lineTotal;

  const formattedCommission = commission ? formatMoney(intl, commission, 2) : null;

  return formattedCommission ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.processingFee" />
        <Explanation text="Credit/debit card processing fees." />
      </span>
      <span
        className={classNames(css.itemValue, { [css.explanationValue]: isExplanationBreakdown })}
      >
        {formattedCommission}
      </span>
    </div>
  ) : null;
};

export default LineItemProcessingFee;
