import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { drivelahApiPost } from '../../util/apiHelper';
import { getCurrentUser } from '../../util/browserStorageHelper';
import { storableError } from '../../util/errors';
import {
  denormalisedEntities,
  denormalisedResponseEntities,
  updatedEntities,
} from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { txIsEnquired } from '../../util/transaction';
import { fetchUpdateBookingTxs } from '../EditTripPage/EditTripPage.duck';

const apiUrl = config.apiUrl;
const { UUID, Money } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TripPageDetails/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TripPageDetails/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TripPageDetails/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TripPageDetails/FETCH_TRANSACTION_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TripPageDetails/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TripPageDetails/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TripPageDetails/FETCH_TIME_SLOTS_ERROR';

export const FETCH_TX_PHONE_NUMBER_REQUEST = 'app/TripPageDetails/FETCH_TX_PHONE_NUMBER_REQUEST';
export const FETCH_TX_PHONE_NUMBER_SUCCESS = 'app/TripPageDetails/FETCH_TX_PHONE_NUMBER_SUCCESS';
export const FETCH_TX_PHONE_NUMBER_FAIL = 'app/TripPageDetails/FETCH_TX_PHONE_NUMBER_FAIL';

export const ADD_CHILD_LONG_TERM_TRANSACTIONS =
  'app/TripPageDetails/ADD_CHILD_LONG_TERM_TRANSACTIONS';
export const ADD_NEXT_CHILD_TRANSACTION = 'app/TripPageDetails/ADD_NEXT_CHILD_TRANSACTION';
export const ADD_CURRENT_CHILD_TRANSACTION = 'app/TripPageDetails/ADD_CURRENT_CHILD_TRANSACTION';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextLongTermTransaction: null,
  childLongTermTransactions: [],
  currentChildLongTermTransaction: null,
  transaction: null,
};

export default function tripPageDetailsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case ADD_CHILD_LONG_TERM_TRANSACTIONS:
      return { ...state, childLongTermTransactions: payload };
    case ADD_NEXT_CHILD_TRANSACTION:
      return { ...state, nextLongTermTransaction: payload };
    case ADD_CURRENT_CHILD_TRANSACTION:
      return { ...state, currentChildLongTermTransaction: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
const fetchTimeSlotsError = e => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: e,
});

const addChildLongTermTransactions = childLongTermTransactions => ({
  type: ADD_CHILD_LONG_TERM_TRANSACTIONS,
  payload: childLongTermTransactions,
});
const addNextLongTermTransaction = nextLongTermTransaction => ({
  type: ADD_NEXT_CHILD_TRANSACTION,
  payload: nextLongTermTransaction,
});
const addCurrentChildLongTermTransaction = currentChildLongTermTransaction => ({
  type: ADD_CURRENT_CHILD_TRANSACTION,
  payload: currentChildLongTermTransaction,
});

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'customer.attributes.protectedData',
          'customer.attributes.profile.protectedData',
          'customer.attributes.profile.publicData',
          'provider',
          'provider.profileImage',
          'provider.attributes.protectedData',
          'provider.attributes.profile.protectedData',
          'provider.attributes.profile.publicData',
          'listing',
          'listing.images',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(async response => {
      txResponse = response;
      dispatch(fetchUpdateBookingTxs(txResponse.data.data));
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
      if (isLongTerm) {
        const { currentUser } = getState().user;
        const cachedUser = getCurrentUser();
        const userId = (cachedUser && cachedUser.id.uuid) || (currentUser && currentUser.id.uuid);
        const childTransactionIds = get(transaction, 'attributes.metadata.childTransactionIds');
        const getBunchTransactionURL = 'transactions/retrieve-bunch-transactions';
        const data = {
          userId,
          transactionIds: childTransactionIds,
        };
        const childTransactionsRes = await drivelahApiPost(getBunchTransactionURL, data);
        const transactionParent = get(txResponse, 'data.data');
        const childTransactions = get(childTransactionsRes, 'data.data');
        dispatch(addChildLongTermTransactions(childTransactions));
        const nextTransactionId = get(transactionParent, 'attributes.metadata.nextTransaction');
        const currentChildTransactionId = get(
          transactionParent,
          'attributes.metadata.currentChildTransaction'
        );
        const nextTransaction = childTransactions.find(
          child => child.id.uuid === nextTransactionId
        );
        const currentChildTransaction = childTransactions.find(
          child => child.id.uuid === currentChildTransactionId
        );
        dispatch(addNextLongTermTransaction(nextTransaction));
        dispatch(addCurrentChildLongTermTransaction(currentChildTransaction));
      }
      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        if (txRole === 'customer') {
          return sdk.listings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        } else {
          return sdk.ownListings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        }
      } else {
        return response;
      }
    })
    .then(response => {
      const finalTxResponse = txResponse;

      dispatch(addMarketplaceEntities(finalTxResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(finalTxResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().AddOnsPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([dispatch(fetchTransaction(txId, txRole))]);
};
