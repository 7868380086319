/**
 * Export reducers from ducks modules of different containers (i.e. default export)
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */
import AddressDetailsPage from './AddressDetailsPage/AddressDetailsPage.duck';
import AddOnsPage from './AddOnsPage/AddOnsPage.duck';
import CheckoutPage from './CheckoutPage/CheckoutPage.duck';
import ContactDetailsPage from './ContactDetailsPage/ContactDetailsPage.duck';
import UCSDeclarationPage from './UCSDeclarationPage/UCSDeclarationPage.duck';
import BYODHasExistingListing from '../../src/ducks/byod.duck';
import EditListingPage from './EditListingPage/EditListingPage.duck';
import InboxPage from './InboxPage/InboxPage.duck';
import ListingPage from './ListingPage/ListingPage.duck';
import ManageListingsPage from './ManageListingsPage/ManageListingsPage.duck';
import PasswordChangePage from './PasswordChangePage/PasswordChangePage.duck';
import PasswordRecoveryPage from './PasswordRecoveryPage/PasswordRecoveryPage.duck';
import PasswordResetPage from './PasswordResetPage/PasswordResetPage.duck';
import PaymentMethodsPage from './PaymentMethodsPage/PaymentMethodsPage.duck';
import RefundableDepositPage from './RefundableDepositPage/RefundableDepositPage.duck';
import ProfilePage from './ProfilePage/ProfilePage.duck';
import ProfileSettingsPage from './ProfileSettingsPage/ProfileSettingsPage.duck';
import SearchPage from './SearchPage/SearchPage.duck';
import StripePayoutPage from './StripePayoutPage/StripePayoutPage.duck';
import TransactionPage from './TransactionPage/TransactionPage.duck';
import TripDetailsPage from './TripDetailsPage/TripDetailsPage.duck';
import CheckEligibilityPage from './CheckEligibilityPage/CheckEligibilityPage.duck';
// import ListingPageGo from './ListingPageGo/ListingPageGo.duck';
import InstantBookingLandingPage from './InstantBookingLandingPage/InstantBookingLandingPage.duck';
import ReferAHostPage from './ReferAHostPage/ReferAHostPage.duck';
import CovidPage from './CovidPage/CovidPage.duck';
import TripDonatePage from './TripDonatePage/TripDonatePage.duck';
import CardVerificationPage from './CardVerificationPage/CardVerificationPage.duck';
import VaccineCredits from './VaccineCredits/VaccineCredits.duck';
import Topbar from './TopbarContainer/Topbar.duck';
import EditTripPage from './EditTripPage/EditTripPage.reducer';

export {
  EditTripPage,
  CheckoutPage,
  ContactDetailsPage,
  EditListingPage,
  InboxPage,
  ListingPage,
  ManageListingsPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PaymentMethodsPage,
  RefundableDepositPage,
  ProfilePage,
  ProfileSettingsPage,
  SearchPage,
  StripePayoutPage,
  TransactionPage,
  TripDetailsPage,
  CheckEligibilityPage,
  // ListingPageGo,
  AddressDetailsPage,
  UCSDeclarationPage,
  BYODHasExistingListing,
  InstantBookingLandingPage,
  ReferAHostPage,
  CovidPage,
  TripDonatePage,
  CardVerificationPage,
  VaccineCredits,
  AddOnsPage,
  Topbar,
};
