import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionDownloadApp.css';
import imageSource from '../../util/imageSource';

const SectionDownloadApp = props => {
  return (
    <div className={css.root}>
      <div className={css.textWrapper}>
        <div className={css.title}>
          <FormattedMessage id="SectionDownloadApp.title" />
        </div>
        <div className={css.subtitle}>
          <FormattedMessage id="SectionDownloadApp.subtitle" />
        </div>
        <div className={css.buttonsWrapper}>
          <img className={css.appButton} src={imageSource.landingPage.chplay} />
          <img className={css.appButton} src={imageSource.landingPage.appstore} />
        </div>
      </div>
    </div>
  );
};

SectionDownloadApp.defaultProps = {};

SectionDownloadApp.propTypes = {};

export default SectionDownloadApp;
