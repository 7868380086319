import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '..';
import config from '../../config';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './MonthlyStatementPage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { drivelahApiGet } from '../../util/apiHelper';
import classNames from 'classnames';

export const MonthlyStatementPageComponent = props => {
  const { currentUser, scrollingDisabled, intl, connectCalendarTabVisibility } = props;

  const openInNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const user = ensureCurrentUser(currentUser);

  const [statements, setMonthlyStatement] = useState({});
  const [selectedYear, setSelectedYear] = useState('');
  const [loader, setLoader] = useState(false);

  const pdfDownlaod = path => {
    try {
      // const hostId = "6125b397-4646-48ce-ba46-91e8f31a4c7d";
      const hostId = user.id.uuid;

      let fileName = path.substring(path.lastIndexOf('/') + 1);
      drivelahApiGet(`upload/get-monthly-summary-url?hostId=${hostId}&fileName=${path}`).then(
        async res => {
          const urlName = res.data.data;
          openInNewTab(urlName);
          // const url = urlName.split('?')[0];
          // const resp = await axios.get(urlName);
          // console.log('resp.data.data----->', resp.data);
          // const fileURL = URL.createObjectURL(new Blob([resp.data]));
          // console.log('fileURL', fileURL);
          // const link = document.createElement('a');
          // link.href = fileURL;
          // link.setAttribute('download', fileName);
          // document.body.appendChild(link);
          // link.click();
        }
      );
    } catch (e) {
      console.log('e', e);
    }
  };

  const fetchUserMonthlyStatment = () => {
    setLoader(true);
    try {
      // const hostId = "6125b397-4646-48ce-ba46-91e8f31a4c7d";
      const hostId = user.id.uuid;
      drivelahApiGet(`upload/get-monthly-summary?hostId=${hostId}`).then(res => {
        console.log('Res', res);
        if (res && res.data && res.data.data) {
          setMonthlyStatement(res.data.data);
          if (res.data.data && Object.keys(res.data.data).length) {
            setSelectedYear(Object.keys(res.data.data)[0]);
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
        // setLoader(false);
      });
    } catch (e) {
      console.log('e', e);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchUserMonthlyStatment();
  }, [currentUser]);

  let tabs = [...config.custom.tabs];

  tabs = tabs
    .map(tab => ({ ...tab }))
    .filter(tab => {
      if (!(user && ensureHostVerified(user))) {
        if (tab.linkProps.name === 'MonthlyStatementPage') {
          return false;
        }
      }
      if (tab.linkProps.name === 'SyncCalenderPage') {
        return Boolean(connectCalendarTabVisibility);
      }
      return true;
    });
  const currentTab = tabs.find(tab => tab.linkProps.name === 'MonthlyStatementPage');
  if (currentTab) {
    currentTab.selected = true;
  }

  const yearsLabel = intl.formatMessage({
    id: '"MonthlyStatementPage.years',
  });
  const monthlyStatementsPlaceHolder = intl.formatMessage({
    id: 'MonthlyStatementPage.yearsPlaceholder',
  });

  const handleChange = year => {
    setSelectedYear(year);
  };

  const getPdfDownloadSection = () => {
    if (!selectedYear) {
      return '';
    }
    const yearsPdf = statements[selectedYear];
    const allMonth = Object.keys(yearsPdf);

    const allDownLoadCard =
      allMonth && allMonth.length > 0 ? (
        allMonth.map(month => {
          const pdfPath = statements[selectedYear][month];
          return (
            <div className={classNames(css.column, css.column6)}>
              <div className={css.downloadPdf} onClick={() => pdfDownlaod(pdfPath)}>
                {month} {selectedYear}
              </div>
            </div>
          );
        })
      ) : (
        <p>No statements available yet</p>
      );
    console.log('All Years cars', allDownLoadCard);
    return allDownLoadCard;
  };

  const title = intl.formatMessage({ id: 'MonthlyStatementPage.title' });
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="MonthlyStatementPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="MonthlyStatementPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={user} />
        <LayoutWrapperMain>
          <div className={css.pageContent}>
            <div className={css.titleSection}>
              <h1 className={css.title}>Monthly statements</h1>
            </div>
            <div className={css.mainContentWrapper}>
              <p>
                All the monthly statements for your earnings and expenses can be found here. Click
                on the month to download the statement.
              </p>
              <div className={css.displayInlineContainer}>
                {loader ? (
                  <div className={classNames(css.column, css.column12)}>
                    {' '}
                    <div className={css.loader} />{' '}
                  </div>
                ) : (
                  <div className={classNames(css.column, css.column6)}>
                    {statements && Object.keys(statements).length ? (
                      <select
                        value={selectedYear}
                        onChange={event => handleChange(event.target.value)}
                        className={classNames(css.peopleContainer, css.newInput, css.dropDownInput)}
                        name="years"
                        id="years"
                        label={yearsLabel}
                      >
                        <option disabled value="">
                          {monthlyStatementsPlaceHolder}
                        </option>
                        {Object.keys(statements).map(year => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    ) : (
                      ''
                    )}
                  </div>
                )}
                <div className={classNames(css.column, css.column12, css.downloadWrapper)}>
                  <div className={classNames(css.displayInlineContainer)}>
                    {selectedYear ? getPdfDownloadSection() : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

MonthlyStatementPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

MonthlyStatementPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});

const MonthlyStatementPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MonthlyStatementPageComponent);

MonthlyStatementPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default MonthlyStatementPage;
