/**
 * Import reducers from shared ducks modules (default export)
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */

import Auth from './Auth.duck';
import Campaign from './Campaign.duck';
import EmailVerification from './EmailVerification.duck';
import FlashNotification from './FlashNotification.duck';
import LocationFilter from './LocationFilter.duck';
import marketplaceData from './marketplaceData.duck';
import paymentMethods from './paymentMethods.duck';
import Routing from './Routing.duck';
import stripe from './stripe.duck';
import stripeConnectAccount from './stripeConnectAccount.duck';
import subscriptionPanel from './subscriptionPanel.duck';
import tabPanels from './TabPanels.duck';
import UI from './UI.duck';
import user from './user.duck';
import transactions from './Tansactions.duck';
import alert from './alert.duck';
import AdminActionsUpdateBooking from '../modules/admin-actions/update-booking/reducer';

export {
  Auth,
  EmailVerification,
  FlashNotification,
  LocationFilter,
  Routing,
  UI,
  marketplaceData,
  paymentMethods,
  stripe,
  stripeConnectAccount,
  user,
  Campaign,
  subscriptionPanel,
  tabPanels,
  transactions,
  alert,
  AdminActionsUpdateBooking,
};
