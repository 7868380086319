import React, { memo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconReviewStar, InlineTextButton } from '../../components';

import css from './ListingPage.css';
import { ensureListing } from '../../util/data';
import isEqual from 'lodash/isEqual';

const SectionHeading = memo(
  props => {
    const {
      priceTitle,
      formattedPrice,
      richTitle,
      category,
      hostLink,
      showContactUser,
      onContactUser,
      currentListing,
      isInstantBookingListing,
      shouldShowPricePerMonth = false,
      isLongTerm,
      isDisinfected,
    } = props;

    const { reviews = {} } = currentListing.attributes.metadata;

    const unitTranslationKey =
      shouldShowPricePerMonth || isLongTerm ? 'ListingPage.perMonth' : 'ListingPage.perDay';

    const averageReview = (averageReviews, doneTrips) => {
      if (!averageReviews || averageReviews === 'N/A') {
        if (!doneTrips) {
          return null;
        }
        return (
          <div className={css.averageContainer}>
            <p>
              <strong>Trips:</strong> {doneTrips}
            </p>
          </div>
        );
      }
      return (
        <div className={css.averageContainer}>
          <div>
            <IconReviewStar rootClassName={css.star} />
            {averageReviews}
          </div>
          <p className={css.doneTrips}>
            | <strong>Trips:</strong> {doneTrips}
          </p>
        </div>
      );
    };
    const { author } = ensureListing(currentListing);

    const instantBookingLabel =
      isInstantBookingListing && !shouldShowPricePerMonth && !isLongTerm ? (
        <div className={css.instantBooking}>
          <FormattedMessage id={'ListingPage.instantBookingLabel'} />
        </div>
      ) : null;

    const isDisinfectedLabel = isDisinfected ? (
      <div class={css.wrapperLabelDisinfected}>
        <div class={css.numberDays}>
          <div className={css.number}> 60 </div>
          <div className={css.day}> days</div>
        </div>
        <div class={css.textLabel}>
          <span> Disinfected </span>
        </div>
      </div>
    ) : null;

    return (
      <div className={css.sectionHeading}>
        <div className={css.desktopPriceContainer}>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.desktopPerUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        <div className={css.heading}>
          <div className={css.labelGroup}>
            {instantBookingLabel}
            {isDisinfectedLabel}
          </div>
          <h1 className={css.title}>{richTitle}</h1>
          <div className={css.author}>
            {category}
            <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
            {showContactUser ? (
              <span className={css.contactWrapper}>
                <span className={css.separator}>•</span>
                <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                  <FormattedMessage id="ListingPage.contactUser" />
                </InlineTextButton>
              </span>
            ) : null}
            {author && author.id
              ? averageReview(
                  reviews.averageRating,
                  currentListing.attributes.publicData.numberTripDone
                )
              : null}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => {
    return isEqual(prev, next);
  }
);

export default SectionHeading;
