import React, { memo } from 'react';
import css from './NewGuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import { emailFormatValid, required } from '../../util/validators';
import imageSource from '../../util/imageSource';
import LazyImage from '../../components/LazyImage/LazyImage';

const GetApp = memo(props => {
  const { intl } = props;
  const emailRequiredMessage = intl.formatMessage({
    id: 'SignupForm.emailRequired',
  });
  const emailRequired = required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });
  const emailValid = emailFormatValid(emailInvalidMessage);
  return (
    <Container>
      <Row>
        <Column large={12}>
          <div className={css.getAppSection}>
            <Row>
              <Column large={6} small={12} className={css.detailContainer}>
                <Row className={css.sectionTitle}>
                  <Column large={12} small={12}>
                    <h5 className={css.whiteHeading}>
                      Get the Drive mate App{' '}
                      {/* <p className={css.whitePara}>Australia's largest car rental app</p> */}
                      <p className={css.whitePara}>
                        Download Australia’s fastest growing car sharing and rental app, with over
                        20,000+ rapidly increasing user base.
                      </p>
                    </h5>
                  </Column>
                </Row>
                {/* <Row className={css.sectionTitle}>
                  <Column large={12} small={12}>
                    <FinalForm
                      {...props}
                      render={({ handleSubmit }) => {
                        return (
                          <div className={css.root}>
                            <div className={css.field}>
                              <FieldTextInput
                                type="text"
                                name="phonenumber"
                                autoComplete="phonenumber"
                                placeholder="Enter Mobile Number"
                                label=""
                                validate={composeValidators(emailRequired, emailValid)}
                                className={css.input}
                                rootClassName={css.inputWrapper}
                                id="phonenumber"
                              />
                              <SecondaryButton onClick={handleSubmit} className={css.submitBtn}>
                                Get App Link
                              </SecondaryButton>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </Column>
                </Row> */}

                <Row>
                  <Column large={12} small={12}>
                    {/* <div className={css.infoCard}>
                      <img src={Tag} />
                      <span className={css.whitePara}>
                        Use code <strong className={css.bold}>WELCOMEDRIVE</strong> and get upto{' '}
                        <strong className={css.bold}>$10 off</strong> on your first car rental
                        booking
                      </span>
                    </div> */}
                    <div className={css.moreWays}>
                      <p className={css.whitePara}>
                        {/* Available on both iOS and Android, download on your phone now. */}
                        Click the links below to download the app.
                      </p>
                      <ul className={css.badgeListing}>
                        <li className={css.heroContentText}>
                          <a
                            target="_blank"
                            href="https://apps.apple.com/au/app/drive-mate-share-rent-cars/id1534972421"
                            className={css.appButton}
                          >
                            <LazyImage src={imageSource.landingPageNew.appStoreBadge} alt="" />
                          </a>
                        </li>
                        <li className={css.heroContentText}>
                          <a
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.drivelah.drivemate"
                            className={css.appButton}
                          >
                            <LazyImage src={imageSource.landingPageNew.googlePlayBadge} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Column>
                </Row>
              </Column>
              <Column large={6} small={12}>
                <div className={css.rightImage}>
                  <LazyImage src={imageSource.landingPageNew.personHoldingPhone} alt="" />
                </div>
              </Column>
            </Row>
          </div>
        </Column>
      </Row>
    </Container>
  );
});

export default GetApp;
