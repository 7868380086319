import React, { Component } from 'react';
import { ModalInsurancePolicy } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BAHIntroSection.css';

class BAHIntroSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenInsuranceModal: false,
    };
  }

  handleOpenInsuranceModal = () => this.setState({ isOpenInsuranceModal: true });
  handleCloseInsuranceModal = () => this.setState({ open: false });

  render() {
    const { onManageDisableScrolling } = this.props;
    return (
      <section className={css.introSection}>
        <div className={css.textBoxs}>
          <div className={css.textBox}>
            <div className={css.title}>Peace of Mind Protection</div>
            <div className={css.description}>
              <FormattedMessage
                id="BecomeAHostPage.introDescriptionDM1"
                values={{ newline: <br /> }}
              />
            </div>
          </div>
          <div className={css.textBox}>
            <div className={css.title}>
              <FormattedMessage
                id="BecomeAHostPage.introTitleDM2"
                values={{
                  newline: (
                    <span className={css.newline}>
                      <br />
                    </span>
                  ),
                }}
              />
            </div>
            <div className={css.description}>
              <FormattedMessage
                id="BecomeAHostPage.introDescriptionDM2"
                values={{ newline: <br /> }}
              />
            </div>
          </div>
          <div className={css.textBox}>
            <div className={css.title}>
              <FormattedMessage
                id="BecomeAHostPage.introTitleDM3"
                values={{
                  newline: (
                    <span className={css.newline}>
                      <br />
                    </span>
                  ),
                }}
              />
            </div>
            <div className={css.description}>
              <FormattedMessage
                id="BecomeAHostPage.introDescriptionDM3"
                values={{ newline: <br /> }}
              />
            </div>
          </div>
        </div>
        <ModalInsurancePolicy
          isOpen={this.state.isOpenInsuranceModal}
          onClose={this.handleCloseInsuranceModal}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </section>
    );
  }
}

export default BAHIntroSection;
