import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';
import config from '../../config';

import css from './EditListingFeaturesPanel.css';

const FEATURES_NAME = 'keyFeatures';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    submitButtonId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const keyFeatures = publicData && publicData.keyFeatures;
  const transmissions = publicData && publicData.transmissions;
  const fuelType = publicData && publicData.fuelType;
  const drivenKm = publicData && publicData.drivenKm;
  const peopleNumber =
    publicData && publicData.peopleNumber
      ? Array.isArray(publicData.peopleNumber)
        ? publicData.peopleNumber
        : [1, publicData.peopleNumber]
      : [1, 5];
  const peopleNumberMax = peopleNumber[1];
  const engineStart = (publicData && publicData.engineStart) || '';
  const lockboxData = (publicData && publicData.lockbox) || 'false';
  const lockbox = (lockboxData && lockboxData.toString()) || 'false';
  const initialValues = {
    keyFeatures,
    fuelType,
    drivenKm,
    transmissions: transmissions ? transmissions[0] : null,
    peopleNumberMax,
    engineStart,
    lockbox,
  };

  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <div className={css.titleSection}>
        <h1 className={css.title}>Features</h1>
        <p>
          Enter the features of your car. Most of the time these features are the deciding factor
          when renting the car.
        </p>
      </div>
      <EditListingFeaturesForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            transmissions = '',
            keyFeatures = [],
            fuelType = '',
            drivenKm = '',
            peopleNumberMax,
            engineStart = '',
            lockbox,
          } = values;
          const lockBoxFlag = lockbox === 'true' ? true : lockbox === 'false' ? false : false;
          console.log('lockBoxFlag', lockBoxFlag);
          // if(peopleNumber) {
          //   if (Array.isArray(peopleNumber)) {
          //     peopleNumberMax = peopleNumber[1];
          //   } else {
          //     peopleNumberMax = peopleNumber;
          //   }
          // }
          const updatedValues = {
            publicData: {
              transmissions: [transmissions],
              keyFeatures,
              fuelType,
              drivenKm,
              peopleNumber: [1, parseInt(peopleNumberMax)],
              peopleNumberMin: 1,
              peopleNumberMax,
              isUsePetrol: !!config.custom.petrolFuelTypes.find(op => op.key === fuelType),
              engineStart,
              // lockbox: lockBoxFlag,
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        fuelTypes={config.custom.typeOfFuel}
        transmissionType={config.custom.transmissions}
        drivenKm={config.custom.drivenKm}
        peopleNumberMax={initialValues.peopleNumberMax}
        submitButtonId={submitButtonId}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

export default EditListingFeaturesPanel;
