import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { memo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { daysBetween, displayDateForUser } from '../../util/dates';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, LINE_ITEM_UNITS, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import { txIsAwaitUpdateBookingBeforePickUp } from '../../util/transaction';

const BookingPeriod = props => {
  const { isSingleDay, startDate, endDate, timeZone } = props;
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  if (isSingleDay) {
    return <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />;
  }

  return (
    <FormattedMessage
      id="BookingBreakdown.bookingPeriod"
      values={{
        bookingStart: (
          <span className={css.nowrap}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
        newLine: <br />,
        bookingEnd: (
          <span className={css.nowrap}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
      }}
    />
  );
};

const LineItemBookingPeriod = memo(
  props => {
    const { booking, unitType, timezone, transaction } = props;
    if (!booking) return null;

    const { start: startDate, end: endDateRaw, displayStart, displayEnd } = booking.attributes;

    const updatedBookingEnd =
      transaction.attributes.protectedData &&
      transaction.attributes.protectedData.childTransaction &&
      transaction.attributes.protectedData.childTransaction.bookingEnd
        ? moment(transaction.attributes.protectedData.childTransaction.bookingEnd).toDate()
        : null;
    const updatedBookingStart =
      transaction.attributes.protectedData &&
      transaction.attributes.protectedData.childTransaction &&
      transaction.attributes.protectedData.childTransaction.bookingStart
        ? moment(transaction.attributes.protectedData.childTransaction.bookingStart).toDate()
        : null;

    const localStartDate = displayDateForUser(
      startDate,
      transaction.attributes.protectedData &&
        transaction.attributes.protectedData.transactionTimezone
    );
    const localEndDateRaw = displayDateForUser(
      endDateRaw,
      transaction.attributes.protectedData &&
        transaction.attributes.protectedData.transactionTimezone
    );

    const realStartDate = moment(displayStart).toDate();
    const realEndDate = moment(displayEnd).toDate();

    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const isUnits = unitType === LINE_ITEM_UNITS;

    const dayCount = daysBetween(localStartDate, localEndDateRaw);
    const isSingleDay = !isNightly && !isUnits && dayCount === 1;
    const endDay = isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

    return (
      <div className={css.lineItem}>
        <span className={css.bookingPeriodText}>
          <BookingPeriod
            isSingleDay={isSingleDay}
            startDate={realStartDate}
            endDate={realEndDate}
            timeZone={timezone}
          />
        </span>
        {(txIsAwaitUpdateBookingBeforePickUp(transaction) ||
          txIsAwaitUpdateBookingBeforePickUp(transaction)) && (
          <p className={css.bookingUpdatedPeriodText}>
            <span>Updated Dates</span>
            <p className={css.bookingUpdatedPeriodText}>
              <BookingPeriod
                isSingleDay={isSingleDay}
                startDate={updatedBookingStart}
                endDate={updatedBookingEnd}
                timeZone={timezone}
              />
            </p>
          </p>
        )}
      </div>
    );
  },
  (prev, next) => isEqual(prev.booking, next.booking)
);

LineItemBookingPeriod.propTypes = {
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
};

export default LineItemBookingPeriod;
