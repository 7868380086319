import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAGWhyDriveLah.css';
import { ExternalLink, ModalInsurancePolicy } from '../../components';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

class BAGWhyDriveLah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenInsuranceModal: false,
    };
  }

  handleOpenInsuranceModal = () => this.setState({ isOpenInsuranceModal: true });
  handleCloseInsuranceModal = () => this.setState({ isOpenInsuranceModal: false });

  render() {
    const { onManageDisableScrolling } = this.props;
    return (
      <section className={css.whyDriveLahSection}>
        <div className={css.textBox}>
          <h4 className={css.title}>
            <FormattedMessage
              id="BecomeAGuestPage.whyDriveLahTitle"
              values={{
                newline: (
                  <span className={css.newlineMobile}>
                    <br />
                  </span>
                ),
              }}
            />
          </h4>
          <div className={css.subBoxs}>
            <div className={css.subBoxs1}>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Mate’s Rates</div>
                <div className={css.subBoxDesc}>
                  Keep more of your moola with car hire that’s up to 40% cheaper than most Aussie
                  rental companies​
                </div>
              </div>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Most flexible and convenient</div>
                <div className={css.subBoxDesc}>
                  Rent cars on an hourly, daily or even a monthly basis with virtually limitless
                  rental periods to suit all guests and lifestyles.​
                </div>
              </div>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Savvy Insurance Structure</div>
                <div className={css.subBoxDesc}>
                  Comprehensively protected from the moment you first sit in the driver’s seat,
                  until the car has been returned.
                </div>
                {/* <img src={insuranceLogo} className={css.insuranceLogo} /> */}
                <ExternalLink
                  className={css.btnPolicy}
                  href={`https://help.${DOMAIN_HELP_URL}/kb/guide/en/insurance-policy-gZrp4S8YGc/Steps/755720`}
                >
                  <FormattedMessage id="BecomeAGuestPage.ourInsurance" />
                </ExternalLink>
              </div>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Unmatched Support Network</div>
                <div className={css.subBoxDesc}>
                  We provide 24/7 support during your rental period. Give us a buzz and we’ll be
                  more than happy to have to chat!​ ​ ​
                </div>
              </div>
              <div className={css.subBox}>
                <div className={css.subBoxTitle}>Super-convenient Keyless Access Technology</div>
                <div className={css.subBoxDesc}>
                  Pickup the car using advanced in-app technology. Zero contact with hosts or keys
                  to worry about keeping track of.
                </div>
              </div>
            </div>
            {/* <div className={css.subBoxs2}>

            </div> */}
          </div>
        </div>
        <ModalInsurancePolicy
          isOpen={this.state.isOpenInsuranceModal}
          onClose={this.handleCloseInsuranceModal}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </section>
    );
  }
}

export default BAGWhyDriveLah;
