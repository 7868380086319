import React from 'react';
import css from './LoginModal.css';
import Modal from '../Modal/Modal';
import { FormattedMessage } from 'react-intl';
import { LoginGoForm } from '../../forms';
import { NamedLink } from '..';

const LoginModal = props => {
  const {
    isOpen,
    onClose,
    onManageDisableScrolling,
    handleSubmit,
    loginError,
    loginInProgress,
    isByod,
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      closeButtonMessage=" "
      onClose={onClose}
      containerClassName={css.container}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.title}>
        {isByod ? (
          <FormattedMessage id="LoginModal.ByodTitle" />
        ) : (
          <FormattedMessage id="LoginModal.title" />
        )}
      </div>
      {isOpen && (
        <LoginGoForm onSubmit={handleSubmit} inProgress={loginInProgress} isByod={isByod} />
      )}

      {loginError && (
        <div className={css.error}>
          <FormattedMessage id="AuthenticationPage.loginFailed" />
        </div>
      )}
      <div className={css.title}>
        <FormattedMessage id="LoginModal.dontHaveAccount" />
      </div>
      {!isByod && (
        <div className={css.description}>
          <FormattedMessage id="LoginModal.listYourCarForFree" />
        </div>
      )}

      <NamedLink className={css.listYourCar} name="NewListingPage">
        {isByod ? (
          <FormattedMessage id="LoginModal.listYourCarByod" />
        ) : (
          <FormattedMessage id="LoginModal.listYourCar" />
        )}
      </NamedLink>
    </Modal>
  );
};

export default LoginModal;
