import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FieldTextInput, Form, NamedLink, PrimaryButton } from '../../components';
import * as validators from '../../util/validators';
import facebookLogo from '../../assets/facebook.png';
import googleLogo from '../../assets/google_logo.png';
import appleLogo from '../../assets/apple-logo.svg';
import AppleSignin from 'react-apple-signin-auth';
import EyeIcon from '../../assets/eye-solid.svg';
import config from '../../config';
import { gSend, GTAG_ACTIONS, GTAG_METHODS } from '../../util/gtag';

import css from './LoginForm.css';

const LoginFormComponent = props => {
  const [showPassword, setShowPassword] = useState(false);
  const showHidePassword = () => setShowPassword(!showPassword);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          google,
          facebook,
          invalid,
          handleChange,
          location,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'LoginForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'LoginForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'LoginForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'LoginForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'LoginForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'LoginForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'LoginForm.passwordRequired',
        });
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const passwordRecoveryLink = (
          <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
            <FormattedMessage id="LoginForm.forgotPassword" />
          </NamedLink>
        );

        const getLocationState = location
          ? {
              state: location.state,
            }
          : {};

        return (
          <>
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <FieldTextInput
                  className={css.formInput}
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <div className={css.inputContainer}>
                  <span className={css.eyeIcon} onClick={showHidePassword}>
                    <img src={EyeIcon} alt="Alt text" />
                  </span>
                  <FieldTextInput
                    className={classNames(css.password, css.formInput)}
                    type={showPassword ? 'text' : 'password'}
                    id={formId ? `${formId}.password` : 'password'}
                    name="password"
                    autoComplete="current-password"
                    label={passwordLabel}
                    placeholder={passwordPlaceholder}
                    validate={passwordRequired}
                  />
                </div>
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  className={css.loginButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="LoginForm.logIn" />
                </PrimaryButton>
                <p className={css.bottomWrapperText}>
                  <span className={css.recoveryLinkInfo}>{passwordRecoveryLink}</span>
                </p>
              </div>
            </Form>
            <div className={css.loginWith}>
              <span className={css.text}>
                <h3>
                  <span>or log in using</span>
                </h3>
              </span>
              <div className={classNames(css.loginUsing, css.facebook)}>
                <a onClick={facebook}>
                  <span>
                    <img src={facebookLogo} alt={'facebook logo'} />
                  </span>
                  <span className={classNames(css.btnText, css.facebook)}>
                    Sign in with Facebook
                  </span>
                </a>
              </div>
              <div className={classNames(css.loginUsing, css.google, css.googleBorder)}>
                <a onClick={google}>
                  <span>
                    <img src={googleLogo} alt={'google logo'} />
                  </span>
                  <span className={classNames(css.btnText, css.google)}>Sign in with Google</span>
                </a>
              </div>
              <AppleSignin
                authOptions={config.appleAuthOptions}
                uiType="dark"
                className="apple-auth-btn"
                noDefaultStyle={false}
                buttonExtraChildren="Continue with Apple"
                skipScript={false}
                onSuccess={_response => {
                  gSend(GTAG_ACTIONS.ACTION_LOGIN, { method: GTAG_METHODS.APPLE_LOGIN });
                }}
                render={props => (
                  <div className={classNames(css.loginUsing, css.apple)} {...props}>
                    <a>
                      <span>
                        <img src={appleLogo} alt={'apple logo'} />
                      </span>
                      <span className={classNames(css.btnText, css.apple)}>Sign in with Apple</span>
                    </a>
                  </div>
                )}
              />
              <div className={css.signupButton}>
                New to Drive mate?
                <NamedLink
                  name="SignupPage"
                  onClick={() => handleChange(false)}
                  to={getLocationState}
                >
                  <a onClick={() => handleChange(false)}> Sign up</a>
                </NamedLink>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
