import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Modal } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import css from './ModalMissingPostalCode.css';
import ModalMissingPostalCodeContent from './ModalMissingPostalCodeContent';
import { getAddressDetailsByBrowser } from '../../util/googleMaps';

const ModalMissingPostalCode = ({
  rootClassName,
  className,
  history,
  containerClassName,
  currentUser,
  modalServicesProps,
  onManageDisableScrolling,
  isVisible: isAnyOtherModalVisible,
  ...rest
}) => {
  const [postalAddress, setPostalAddress] = useState({});
  const [isBrowserLocationAllowed, setIsBrowserLocationAllowed] = useState({ value: false });
  const [isExecutionInProgress, setIsExecutionInProgress] = useState(false);
  const [isPostalCodeExists, setIsPostalCodeExists] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    doManageProxyReloadCount();
  }, []);

  useEffect(() => {
    onBrowserExecuteComplete();
  }, [isBrowserLocationAllowed]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const code =
        currentUser.attributes &&
        currentUser.attributes.profile &&
        currentUser.attributes.profile.protectedData &&
        currentUser.attributes.profile.protectedData.locationProxy &&
        currentUser.attributes.profile.protectedData.locationProxy.selectedPlace &&
        currentUser.attributes.profile.protectedData.locationProxy.selectedPlace.postalCode
          ? currentUser.attributes.profile.protectedData.locationProxy.selectedPlace.postalCode
          : '';

      setIsPostalCodeExists(!!code);
      localStorage.setItem('locationProxyPostalCodeExists', !!code);
      // We will try to fetch the address when locationProxy postal code is not exists
      if (!code) {
        onBrowserExecute();
      }
    }
  }, [currentUser]);

  /**
   * Check if user is logged in and location is home page
   * @returns {boolean}
   */
  const isUserLoggedInAndOnHomePage = () => {
    const URL = process.env.REACT_APP_CANONICAL_ROOT_URL;
    return (
      currentUser &&
      currentUser.id &&
      (window.location.href === URL ||
        window.location.href === `${URL}/` ||
        window.location.href === 'http://localhost:3000' ||
        window.location.href === 'http://localhost:3000/')
    );
  };

  const doManageProxyReloadCount = () => {
    // Check if user is exists and we are on home page.
    if (!isUserLoggedInAndOnHomePage()) {
      console.log('doManageProxyReloadCount: no user found, returning...');
      return;
    }

    let createdAt = null; // user createdAt date and time
    if (currentUser && currentUser.attributes && currentUser.attributes.createdAt) {
      createdAt = moment(currentUser.attributes.createdAt);
    }
    if (!createdAt) {
      console.log('doManageProxyReloadCount: createdAt date is required!');
      return;
    } // createdAt date is required
    if (onCleanup()) return; // Check and clean the interval for location proxy if exists
    const now = moment(new Date()); // current date and time
    const duration = moment.duration(now.diff(createdAt));
    const hours = duration.asHours();
    const count = localStorage.getItem('proxyReloadCount');
    const reloadCount = count ? parseInt(count, 10) : 0;
    // Check if it's the 2nd reload, and any other model should not be visible and user signup duration should be more then 2 hours and getIsLocationProxyTimerComplete should check if timer is complete or not started, in both condition, it will return true
    if (
      reloadCount >= 2 &&
      !isAnyOtherModalVisible &&
      hours >= 2 &&
      getIsLocationProxyTimerComplete()
    ) {
      console.log('Performing action on 2nd browser reload');
      // Perform your desired action here
      setIsModalOpen(true);
      localStorage.setItem('proxyReloadCount', 0); // reset reload count once modal is visible
      localStorage.removeItem('locationProxyTimerComplete');
      locationProxyTimerStarter();
      return;
    } else if (localStorage.getItem('locationProxyStartTimer')) {
      locationProxyTimerStarter();
    }

    // Increment the reload count and store it in local storage
    const updatedCount = reloadCount + 1;
    localStorage.setItem('proxyReloadCount', updatedCount.toString());
    console.log('doManageProxyReloadCount -> proxyReloadCount: ', updatedCount);
  };

  // Start the timer when the user visits the site
  const locationProxyTimerStarter = () => {
    // Check if the timer is already running
    let intervalId = localStorage.getItem('locationProxyTimerID');
    intervalId = intervalId ? parseInt(intervalId, 10) : 0;
    clearInterval(intervalId); // handle previous browser is closed condition.

    // We will set the start timer only if it's not in localStorage
    if (
      !localStorage.getItem('locationProxyStartTimer') &&
      !localStorage.getItem('locationProxyTimerComplete')
    ) {
      const startTime = new Date(); // Set the current time
      localStorage.setItem('locationProxyStartTimer', startTime); // Store the start time in localStorage
    }
    // Start the interval to check if one hour has passed
    const newIntervalId = setInterval(() => {
      locationProxyCheckTimer();
      // Clear the interval if one hour has passed
      if (!localStorage.getItem('locationProxyStartTimer')) {
        let lsIntervalId = localStorage.getItem('locationProxyTimerID');
        lsIntervalId = lsIntervalId ? parseInt(lsIntervalId, 10) : 0;
        clearInterval(lsIntervalId);
        localStorage.removeItem('locationProxyTimerID');
      }
      onCleanup();
    }, 60000); // Check every minute (in ms) (you can adjust the interval as needed)
    localStorage.setItem('locationProxyTimerID', newIntervalId);
  };

  const onCleanup = () => {
    console.log(
      'onCleanup locationProxyPostalCodeExists:',
      localStorage.getItem('locationProxyPostalCodeExists')
    );
    if (
      localStorage.getItem('locationProxyPostalCodeExists') &&
      localStorage.getItem('locationProxyPostalCodeExists') === 'true'
    ) {
      // Clean up the localStorage if postal code exits
      let intervalId = localStorage.getItem('locationProxyTimerID');
      intervalId = intervalId ? parseInt(intervalId, 10) : 0;
      clearInterval(intervalId);
      window.localStorage.removeItem('proxyReloadCount');
      window.localStorage.removeItem('locationProxyStartTimer');
      window.localStorage.removeItem('locationProxyTimerComplete');
      window.localStorage.removeItem('locationProxyTimerID');
      console.log('doManageProxyReloadCount: clean localStorage!');
      return true;
    }
    return false;
  };

  // Call this function after one hour
  const setIsLocationProxyTimerComplete = () => {
    if (!localStorage.getItem('locationProxyTimerComplete')) {
      localStorage.setItem('locationProxyTimerComplete', true);
    } else {
      console.log('setIsLocationProxyTimerComplete: already completed!');
    }
  };

  // Call this function after one hour
  const getIsLocationProxyTimerComplete = () => {
    // First Time
    if (
      !localStorage.getItem('locationProxyTimerComplete') &&
      !localStorage.getItem('locationProxyStartTimer')
    ) {
      return true;
    }
    // Timer is completed, it's time to show modal
    else if (
      localStorage.getItem('locationProxyTimerComplete') &&
      !localStorage.getItem('locationProxyStartTimer')
    ) {
      localStorage.removeItem('locationProxyTimerComplete');
      return true;
    }
    // Return false as timer is still running
    else if (
      !localStorage.getItem('locationProxyTimerComplete') &&
      localStorage.getItem('locationProxyStartTimer')
    ) {
      return false;
    } else {
      console.log(
        'getIsLocationProxyTimerComplete: Unknown condition, please verify the code flow once'
      );
    }
  };

  // Check if the timer has reached one hour
  const locationProxyCheckTimer = () => {
    // Get the start time from localStorage
    const startTime = new Date(localStorage.getItem('locationProxyStartTimer'));
    // If there is no start time, the timer hasn't started or has been reset
    if (!startTime) {
      return;
    }
    // Calculate the elapsed time in minutes
    const currentTime = new Date();
    const elapsedTime = Math.floor((currentTime - startTime) / 1000 / 60);
    // Check if one hour has passed
    if (elapsedTime >= 60) {
      // Reset the timer by removing the stored values from localStorage
      localStorage.removeItem('locationProxyStartTimer');
      setIsLocationProxyTimerComplete(); // Call the function after one hour
    }
    console.log('locationProxyCheckTimer -> elapsedTime:', elapsedTime);
  };

  const setTrueForBrowser = () => {
    setIsBrowserLocationAllowed({ value: true });
  };

  const setFalseForBrowser = () => {
    setIsBrowserLocationAllowed({ value: false });
  };

  const onBrowserExecuteComplete = () => {
    console.log(
      'onBrowserExecuteComplete -> isBrowserLocationAllowed: ',
      isBrowserLocationAllowed,
      ', isPostalCodeExists: ',
      isPostalCodeExists
    );
    if (isBrowserLocationAllowed && isBrowserLocationAllowed.value && !isPostalCodeExists) {
      if (
        !postalAddress ||
        !postalAddress.formattedAddress ||
        !postalAddress.formattedAddress.selectedPlace ||
        !postalAddress.formattedAddress.selectedPlace.postalCode
      ) {
        console.log(
          'onBrowserExecuteComplete: not able to find postalCode from google maps api result.'
        );
        return;
      }

      if (modalServicesProps && modalServicesProps.onSendPostalAddress) {
        window.localStorage.removeItem('proxyReloadCount');
        onSaveLocationProxy(postalAddress.formattedAddress);
      } else {
        console.log('onBrowserExecuteComplete -> if -> else');
      }
    } else if (isBrowserLocationAllowed && !isBrowserLocationAllowed.value && !isPostalCodeExists) {
      console.log('onBrowserExecuteComplete -> else');
    }
  };

  const onSaveLocationProxy = formattedAddress => {
    setIsExecutionInProgress(true);
    if (
      formattedAddress &&
      formattedAddress.selectedPlace &&
      formattedAddress.selectedPlace.postalCode
    ) {
      modalServicesProps.onSendPostalAddress(
        formattedAddress,
        () => {
          setIsExecutionInProgress(false);
        },
        error => {
          console.error(error);
          setIsExecutionInProgress(false);
        }
      );
    }
  };

  const onBrowserExecute = () => {
    if (isExecutionInProgress) return;
    if (!isUserLoggedInAndOnHomePage()) {
      console.log('onBrowserExecute: no user found, returning...');
      return;
    }
    getAddressDetailsByBrowser()
      .then(postalAddress => {
        setPostalAddress(postalAddress);
        setTrueForBrowser();
      })
      .catch(error => {
        setFalseForBrowser();
        console.error(error);
      });
  };

  const getModal = () => {
    const user = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);
    let content = null;

    if (user && user.id) {
      content = (
        <ModalMissingPostalCodeContent
          onSubmit={postalCodeObject => {
            if (
              postalCodeObject &&
              postalCodeObject.postalCode &&
              postalCodeObject.postalCode.length >= 4
            ) {
              const formattedAddress = {
                selectedPlace: {
                  postalCode: postalCodeObject.postalCode || '',
                },
              };
              window.localStorage.removeItem('proxyReloadCount');
              onSaveLocationProxy(formattedAddress);
            } else {
              console.log('invalid:', postalCodeObject.postalCode);
            }
          }}
          className={classes}
          user={user}
          currentUser={currentUser}
          isExecutionInProgress={isExecutionInProgress}
        />
      );
    }

    const closeButtonMessage = (
      <FormattedMessage id="ModalMissingPostalCode.closeMissingPostalCodeReminder" />
    );

    return (
      <>
        <Modal
          className={css.modalContainer}
          closeButtonClassName={css.closeButtonClassName}
          id="ModalMissingPostalCode"
          containerClassName={containerClassName}
          scrollLayerClassName={css.scrollLayer}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onManageDisableScrolling={onManageDisableScrolling || (() => null)}
          closeButtonMessage={closeButtonMessage || ''}
        >
          {content}
        </Modal>
      </>
    );
  };

  return <>{isPostalCodeExists ? null : getModal()}</>;
};

ModalMissingPostalCode.defaultProps = {};

ModalMissingPostalCode.propTypes = {};

ModalMissingPostalCode.displayName = 'ModalMissingPostalCode';

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

export default compose(connect(mapStateToProps, {}), injectIntl)(ModalMissingPostalCode);
