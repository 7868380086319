import { useState } from 'react';

export function useModalState(initialState) {
  const [isOpenModal, setOpenModal] = useState(initialState);

  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  return [isOpenModal, openModal, closeModal];
}
