import React, { Component } from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionAskAndAnswer.css';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

class SectionAskAndAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexCurrentBox: -1,
    };
  }

  handleClickMore = index => {
    this.setState({
      indexCurrentBox: index,
    });
  };

  handleClickLess = () => {
    this.setState({
      indexCurrentBox: -1,
    });
  };

  render() {
    const { rootClassName, className } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const titleSection = <FormattedMessage id="SectionAskAndAnswer.title" />;
    const questionList1 = [
      {
        question: <FormattedMessage id="SectionAskAndAnswer.question1" />,
        shortAnswer: <FormattedMessage id="SectionAskAndAnswer.shortAnswer1" />,
        answer: <FormattedMessage id="SectionAskAndAnswer.answer1" />,
        index: 1,
      },
      {
        question: <FormattedMessage id="SectionAskAndAnswer.question2" />,
        shortAnswer: <FormattedMessage id="SectionAskAndAnswer.shortAnswer2" />,
        answer: (
          <span>
            We carry stringent checks on guests who rent on Drive mate through their NRIC/FIN and
            driving licence. Though highly unlikely, in the event of any unfortunate incident, your
            car is comprehensively insured through Drive mate protection including the following:
            <ul className={css.listInContent}>
              <li>Death or injury to third party(unlimited)</li>
              <li>Damage to third party property(up to S$5,000,000)</li>
              <li>
                Loss or damage to your vehicle from accident causes (up to market value at the time
                of loss or damage)
              </li>
              <li>
                Loss of damage by fire or theft (up to market value at the time of loss or damage){' '}
              </li>
            </ul>
          </span>
        ),
        index: 2,
      },
    ];

    const questionList2 = [
      {
        question: <FormattedMessage id="SectionAskAndAnswer.question3" />,
        shortAnswer: <FormattedMessage id="SectionAskAndAnswer.shortAnswer3" />,
        answer: <FormattedMessage id="SectionAskAndAnswer.answer3" />,
        index: 3,
      },
      {
        question: <FormattedMessage id="SectionAskAndAnswer.question4" />,
        shortAnswer: <FormattedMessage id="SectionAskAndAnswer.shortAnswer4" />,
        answer: (
          <FormattedMessage
            id="SectionAskAndAnswer.answer4"
            values={{
              link: (
                <a
                  href="https://sso.agc.gov.sg/SL/RTA1961-S660-2019?DocDate=20191002"
                  target="_blank"
                  className={css.link}
                >
                  here
                </a>
              ),
            }}
          />
        ),
        index: 4,
      },
    ];

    const renderQuestionList = questionList => {
      return questionList.map(item => (
        <div className={css.box} key={item.index}>
          <div className={css.boxTitle}>{item.question}</div>
          <div className={css.boxContent}>
            {this.state.indexCurrentBox === item.index ? item.answer : item.shortAnswer}
            {this.state.indexCurrentBox === item.index ? (
              <span onClick={() => this.handleClickLess()} className={css.moreAnswer}>
                <FormattedMessage id="SectionAskAndAnswer.lessAnswer" />
              </span>
            ) : (
              <span onClick={() => this.handleClickMore(item.index)} className={css.lessAnswer}>
                <FormattedMessage id="SectionAskAndAnswer.moreAnswer" />
              </span>
            )}
          </div>
        </div>
      ));
    };

    return (
      <div className={classes}>
        <h2 className={css.title}>{titleSection}</h2>
        <div className={css.boxList}>{renderQuestionList(questionList1)}</div>
        <div className={css.boxList}>{renderQuestionList(questionList2)}</div>
        <div className={css.moreQuestion}>
          <ExternalLink href={`https://help.${DOMAIN_HELP_URL}/faq`}>
            <FormattedMessage id="SectionAskAndAnswer.moreQuestion" />
          </ExternalLink>
        </div>
      </div>
    );
  }
}

SectionAskAndAnswer.defaultProps = {};

SectionAskAndAnswer.propTypes = {};

export default SectionAskAndAnswer;
