exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* .outerContainer {\n  border: solid 2px black;\n} */\n\n.VerificationSteps_currentStepHeader__2qc0z {\n  /* border: solid 2px blue; */\n  padding: 0px 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center\n}\n\n@media (max-width: 767px) {\n\n.VerificationSteps_currentStepHeader__2qc0z {\n    flex-direction: column-reverse\n}\n  }\n\n.VerificationSteps_currentStepName__1yfC7 {\n  /* border: solid 2px red; */\n  font-size: 24px;\n  color: #026786;\n  font-weight: 700 !important;\n}\n\n/* .currentStepIndicator {\n  border: solid 2px green;\n} */\n\n.VerificationSteps_separatorHR__6jDuT {\n  border: 1px solid #e6e6e6;\n  width: calc(100% - 40px);\n}\n\n.VerificationSteps_currentStepContent__2Q6jd {\n  /* border: solid 2px violet; */\n  padding: 0px 20px;\n}\n\n.VerificationSteps_personalDetailsContainer__eMGj4 {\n  border: solid 2px black;\n}\n\n.VerificationSteps_buttonContainer__1NCz_ {\n  /* border: solid 2px black; */\n  width: -moz-fit-content;\n  width: fit-content;\n  margin: 10% 0px 20px 20px;\n  border-radius: 5px;\n  font-size: 18px;\n  font-weight: 700;\n  color: #026786;\n  background-color: #ffcd05;\n}\n\n.VerificationSteps_phoneNumberError__3IsKK {\n  font-size: 16px;\n  color: red;\n  font-weight: 500 !important;\n  line-height: 18px;\n  margin: 3px auto;\n}\n", ""]);

// Exports
exports.locals = {
	"currentStepHeader": "VerificationSteps_currentStepHeader__2qc0z",
	"currentStepName": "VerificationSteps_currentStepName__1yfC7",
	"separatorHR": "VerificationSteps_separatorHR__6jDuT",
	"currentStepContent": "VerificationSteps_currentStepContent__2Q6jd",
	"personalDetailsContainer": "VerificationSteps_personalDetailsContainer__eMGj4",
	"buttonContainer": "VerificationSteps_buttonContainer__1NCz_",
	"phoneNumberError": "VerificationSteps_phoneNumberError__3IsKK"
};