import React, { memo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './NewGuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';

const ListYourCar = memo(() => {
  return (
    <div className={classNames(css.sectionWithBackground, css.listYourCarSection)}>
      <Container>
        <Row>
          <Column large={6} small={12}>
            <div className={classNames(css.contentBox, css.rentCar)}>
              <h3 className={css.whiteHeading}>Rent a car</h3>
              <p className={css.whitePara}>
                Down the street or across the country, find the perfect vehicle for your next
                adventure.
              </p>
              <div className={css.actionBtn}>
                <NamedLink name="BecomeAGuestPage" className={classNames(css.btn, css.btnPrimary)}>
                  <FormattedMessage id="LandingPageNew.GetStarted" className={css.driveBtn} />
                </NamedLink>
              </div>
            </div>
          </Column>
          <Column large={6} small={12}>
            <div className={classNames(css.contentBox, css.listCar)}>
              <h3 className={css.whiteHeading}>List your car</h3>
              <p className={css.whitePara}>
                Accelerate your entrepreneurship and start building a small car sharing business on
                Drive mate.
              </p>
              <div className={css.actionBtn}>
                <NamedLink name="BecomeAHostPage" className={classNames(css.btn, css.btnPrimary)}>
                  <FormattedMessage id="LandingPageNew.GetStarted" className={css.driveBtn} />
                </NamedLink>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
});

export default ListYourCar;
