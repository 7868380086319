import React, { useEffect, useRef, useState } from 'react';
import BLandingCss from './BLandingPage.css';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import searchIcon from '../../assets/searcIcon.png';
import LazyImage from '../../components/LazyImage/LazyImage';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

const datas = [
  {
    question: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.one.question" />,
    answer: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.one.answer" />,
  },
  {
    question: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.two.question" />,
    answer: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.two.answer" />,
  },
  {
    question: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.three.question" />,
    answer: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.three.answer" />,
  },
  {
    question: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.four.question" />,
    answer: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.four.answer" />,
  },
];

const QuestionAnswer = ({ question, answer, onClick, showAnswer, ...rest }) => {
  return (
    <div className={BLandingCss.questionAnswerBLanding} {...rest}>
      <div className={BLandingCss.questionBLanding} onClick={onClick}>
        {question}
      </div>
      <div
        className={classNames(BLandingCss.answerBLanding, {
          [BLandingCss.showAnswerBLanding]: showAnswer,
        })}
      >
        {answer}
      </div>
    </div>
  );
};

const SectionHelpBLanding = ({ data }) => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const inputRef = useRef(null);

  const handleClickQuestion = i => {
    if (openQuestion === i) setOpenQuestion(null);
    else setOpenQuestion(i);
  };

  useEffect(() => {
    inputRef.current.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        window.open(`https://help.${DOMAIN_HELP_URL}/kb/en/?q=${e.target.value}`);
      }
    });
  }, []);

  return (
    <div className={BLandingCss.sectionHelpBLanding}>
      <div className={BLandingCss.sectionHelpTitleBLanding}>
        <FormattedMessage id="LandingPage.SectionHelp.title" />
      </div>
      <div className={BLandingCss.searchHelpBLanding}>
        <LazyImage src={searchIcon} className={BLandingCss.searchIconBLanding} />
        <input
          className={BLandingCss.searchHelpInputBLanding}
          placeholder="Eg, Who can be a host?"
          ref={inputRef}
        />
      </div>
      <div className={BLandingCss.helpQuestionsBLanding}>
        {(data || datas).map((d, index) => (
          <QuestionAnswer
            {...d}
            key={index}
            index={index}
            showAnswer={openQuestion === index}
            onClick={() => handleClickQuestion(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionHelpBLanding;
