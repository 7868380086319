import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import BLandingCss from './TrustBoxSlider.css';

const TRUSTPILOT_REVIEW_URL = process.env.REACT_APP_TRUSTPILOT_REVIEW_URL;

const TrustBoxSliderElement = ({ trustBoxRef }) => (
  <div className={BLandingCss.sectionTestimonials}>
    <div className={BLandingCss.sectionTestimonialsTitleWrapper}>
      <div className={BLandingCss.sectionTestimonialsTitle}>{'Stories from\nour users'}</div>
      <div className={BLandingCss.sectionTestimonialsDescription}>
        They love renting and sharing with Drive mate
      </div>
    </div>
    <div className={BLandingCss.sliderWrapper}>
      <div
        ref={trustBoxRef}
        class="trustpilot-widget"
        data-locale="en-US"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="5f9cc167ce6f0b000158c1aa"
        data-style-height="240px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en"
        data-font-family="Open Sans"
        data-text-color="#4A4A4A"
      >
        <a href={TRUSTPILOT_REVIEW_URL} target="_blank" rel="noopener">
          Trustpilot
        </a>
      </div>
      <p className={BLandingCss.desc}>
        The reviews are from Drive Mate, our brand name in Singapore
      </p>
    </div>
  </div>
);

class TrustBoxSliderComponent extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
    this.state = {
      isTrustBoxLoaded: false,
    };
  }

  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
      this.setState({
        isTrustBoxLoaded: true,
      });
    }
  }

  render() {
    return this.state.isTrustBoxLoaded && <TrustBoxSliderElement trustBoxRef={this.trustBoxRef} />;
  }
}

const TrustBoxSlider = injectIntl(TrustBoxSliderComponent);
export default TrustBoxSlider;
