import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import config from '../../config';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import classNames from 'classnames';
import {
  Footer,
  InsuranceDisclaimer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SectionAskAndAnswer,
  SectionFindACard,
  SectionHero,
  SectionLocations,
  SectionPartners,
  SectionReadyToGo,
  SectionUsers,
  SectionVideo,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import css from './LandingPageH.css';
import { applyCampaign } from '../../ducks/Campaign.duck';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    viewport,
    location,
    scrollingDisabled,
    turnoffPromoteBar,
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onApplyCampaign,
    applyError,
    applyInProgress,
    applySuccess,
    onLoginOrSignupClick,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              currentUser={currentUser}
              className={css.hero}
              history={history}
              isHost={true}
              location={location}
              viewport={viewport}
              isAuthenticated={isAuthenticated}
              onApplyCampaign={onApplyCampaign}
              applyInProgress={applyInProgress}
              applySuccess={applySuccess}
              applyError={applyError}
            />
          </div>
          <ul className={css.sections}>
            <li>
              <div>
                <SectionVideo onManageDisableScrolling={onManageDisableScrolling} />
              </div>
            </li>
            <li>
              <div>
                <SectionUsers intl={intl} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionLocations viewport={viewport} />
              </div>
            </li>
            <li id="sectionPartners">
              <div className={css.sectionPartnersContainer}>
                <SectionPartners intl={intl} />
              </div>
            </li>
            <li>
              <div className={classNames(css.section, css.findACardSection)}>
                <SectionFindACard
                  className={css.form}
                  onSubmit={() => null}
                  inProgress={false}
                  titleId="SectionFindACard.title"
                  subtitleId="SectionFindACard.subtitle"
                />
              </div>
            </li>
            <li>
              <div className={css.section}>
                <SectionReadyToGo
                  isAuthenticated={isAuthenticated}
                  onLoginOrSignupClick={onLoginOrSignupClick}
                />
              </div>
            </li>
            <li>
              <div className={css.section}>
                <SectionAskAndAnswer />
              </div>
            </li>
            <InsuranceDisclaimer />
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { applyInProgress, applyError, applySuccess } = state.Campaign;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    applyInProgress,
    applyError,
    applySuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onApplyCampaign: (email, userType) => dispatch(applyCampaign({ email, userType })),
  onLoginOrSignupClick: () => dispatch(loginOrSignupClick()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
