import React, { Component } from 'react';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAHFAQ.css';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

class BAHFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: -1,
    };
  }

  openTab = index => {
    this.setState({
      tabOpen: index !== this.state.tabOpen ? index : -1,
    });
  };

  render() {
    const { getToCalculatorSection } = this.props;
    return (
      <section className={css.faqSection}>
        <div className={css.innerWrapper}>
          <h5 className={css.title}>
            <FormattedMessage id="BecomeAHostPage.faqTitle" values={{ newline: <br /> }} />
          </h5>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(0)}>
                How much can I earn as a host?
              </div>
              {this.state.tabOpen === 0 && (
                <div className={css.answer}>
                  It depends on the number of days you Rent out your vehicle and the price you set.
                  On average, people who rent out once a week will likely earn around $5000 per car
                  per year.{' '}
                  <span className={css.linkToCalcSection} onClick={getToCalculatorSection}>
                    Try out earnings estimation tool here
                  </span>
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(1)}>
                Is it safe to rent out my car to a stranger?
              </div>
              {this.state.tabOpen === 1 && (
                <div className={css.answer}>
                  Yes. At Drive mate. Your peace of mind is of utmost importance to us. We have a
                  robust driver screening process including ID, driving licence, driving history and
                  credit checks. Finally, you are in control as the decision to accept a booking is
                  always yours.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(2)}>
                How much does it cost to list a car?
              </div>
              {this.state.tabOpen === 2 && (
                <div className={css.answer}>
                  There is no charge for listing your car on Drive mate. It is free for hosts to
                  list & share their car. Drive mate charges the guest for the trip and not the
                  host.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(3)}>
                Why should I share my car?
              </div>
              {this.state.tabOpen === 3 && (
                <div className={css.answer}>
                  Car owner face multiple costs such as insurance, depreciation, rego and CTP,
                  irrespective of usage. By sharing your vehicle on Drive mate, you earn additional
                  income to offset and hopefully eclipse these costs. Plus, car sharing positively
                  impacts our environment by reducing numbers of cars on the road.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(4)}>
                What protection do I have against car damage?
              </div>
              {this.state.tabOpen === 4 && (
                <div className={css.answer}>
                  At Drive mate, we have got you covered fully. Our insurance cover has been
                  designed to protect you and the renter comprehensively during the rental period.
                  There is a $35 million cover for any 3rd party damages as well.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(5)}>
                Is it compulsory to rent out if I sign up my car?
              </div>
              {this.state.tabOpen === 5 && (
                <div className={css.answer}>
                  No. As a car owner, it is your decision whether to accept or reject a booking
                  request. You set the price and you decide who rents your car.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(6)}>
                I am already sharing my car on another platform. Why should I use Drive mate?
              </div>
              {this.state.tabOpen === 6 && (
                <div className={css.answer}>
                  You can continue sharing on multiple platforms. With Drive mate, you earn 20%
                  higher than other similar platforms{' '}
                  <span className={css.linkToCalcSection}>
                    <ExternalLink
                      href={`https://help.${DOMAIN_HELP_URL}/kb/en`}
                      className={css.link}
                    >
                      (See here for detailed comparison)
                    </ExternalLink>
                  </span>
                  . We take only half of the cut of similar platforms. With our exclusive keyless
                  in-app technology and advanced telemetry data monitoring, you are not bothered
                  with lockboxes and other security hassles. Finally, you are fully covered via our
                  Australia based insurance partner with no impact on your own insurance or no claim
                  bonus.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(7)}>
                What happens if my car is in the workshop and can't be rented out?
              </div>
              {this.state.tabOpen === 7 && (
                <div className={css.answer}>
                  If the car is in the workshop for repairs which are needed because of a Drive mate
                  trip, then the host will be compensated for loss of use. The host will be
                  reimbursed $25 for every day their car is in the workshop.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(8)}>
                How can guests access my car on a Drive mate trip?
              </div>
              {this.state.tabOpen === 8 && (
                <div className={css.answer}>
                  As a host, we would recommend you to go for the Best mates plan i.e. keyless
                  access technology. With this, guests can access the car with their phone via the
                  Drive mate app. No physical key handover, no lockboxes. Convenience at it’s best.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={css.seperateLine}></div>
      </section>
    );
  }
}

export default BAHFAQ;
