import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';

import css from './ListingPage.css';
import { parse } from '../../util/urlHelpers';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(
        pick(this.props, ['geolocation', 'publicData', 'distanceToUser', 'location']),
        pick(nextProps, ['geolocation', 'publicData', 'distanceToUser', 'location'])
      ) || !isEqual(this.state, nextState)
    );
  }

  render() {
    const {
      className,
      rootClassName,
      geolocation,
      publicData,
      listingId,
      distanceToUser,
      location,
      onEnableTheMap,
    } = this.props;
    const searchParams = parse(location.search);
    const { origin } = searchParams || {};
    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const suburbLocationData = publicData && publicData.location;
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    let suburbLocationString = '';
    if (
      suburbLocationData &&
      (suburbLocationData.suburb || suburbLocationData.state || suburbLocationData.postalCode)
    ) {
      suburbLocationString = `${suburbLocationData.suburb ? suburbLocationData.suburb + ', ' : ''}${
        suburbLocationData.state ? suburbLocationData.state + ', ' : ''
      }${suburbLocationData.postalCode ? suburbLocationData.postalCode : ''}`;
    }

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} maxZoom={16} />;
    return (
      <div className={classes}>
        <div>
          <h2 className={css.locationTitle}>
            <FormattedMessage
              id={origin ? 'ListingPage.locationToYourSearchTitle' : 'ListingPage.locationTitle'}
            />
          </h2>
          {suburbLocationString && (
            <div className={css.distanceCarToYourLocation}>{suburbLocationString}</div>
          )}
          <div className={css.distanceCarToYourLocation}>
            {distanceToUser.error ? (
              <FormattedMessage id={distanceToUser.error} />
            ) : (
              `${distanceToUser.distance}km`
            )}
          </div>
        </div>

        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
              if (this.state.isStatic) {
                onEnableTheMap();
              }
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
  onEnableTheMap: func,
};

export default SectionMapMaybe;
